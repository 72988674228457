import React from 'react'
import { Rate } from 'antd'
import SVGStarIcon from '../../icons/SVG/SVGStarIcon'
import Div from '../Div/Div'
import './MyDefaultRate.scss'

const RenderItem = ({ type, index }) => {
  return (
    <Div className='item'>
      {type === 'star' ? <SVGStarIcon pointerEvents={'none'} /> : 10 - index}
    </Div>
  )
}

const MyDefaultRate = ({ type, onChange, value, disabledMargin }) => {
  return (
    <Rate
      className={`my_default_rate ${disabledMargin ? 'disabled_margin' : ''}`}
      defaultValue={type === 'star' ? 2 : 3}
      character={({ index = 0 }) => <RenderItem type={type} index={index} />}
      count={type === 'star' ? 5 : 10}
      onChange={onChange}
      allowClear={false}
      value={value}
    />
  )
}

export default MyDefaultRate
