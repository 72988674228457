import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { Form } from 'antd'
import MyDefaultPageLayout from '../../../../components/MyDefaultPageLayout/MyDefaultPageLayout'
import MyDefaultBlock from '../../../../components/MyDefaultBlock/MyDefaultBlock'
import { useLayout } from '../../../../layout/LayoutProvider/LayoutProvider'
import { getText, getTextServerError } from '../../../../lang'
import MyDefaultBlockHeaderAdditionalButton from '../../../../components/MyDefaultBlock/MyDefaultBlockHeaderAdditionalButton'
import MatForm from '../../../../components/Form/MatForm'
import organizationActions from '../../../../store/modules/organizationActions'
import { useOrganizationLayout } from '../../organizationProvider/OrganizationProvider'
import utilsLocalizations from '../../../../utils/utilsLocalizations'
import { notifyError, notifySuccess } from '../../../../utils/Notify'
import LoadingSpinner from '../../../../components/LoadingSpinner'
import { CANCEL_BTN, SUBMIT_BTN } from '../../../../devOptions'
import CustomAskConsentMessagesItem from './CustomAskConsentMessagesItem'

const CustomAskConsentMessages = () => {
  const { setPageTitle, setFooterButtons, setLoading } = useLayout()
  const {
    selectedOrganization: organization,
    setSelectedOrganization: setOrganization,
  } = useOrganizationLayout()

  const customConstentActionsRef = useRef()
  const [form] = Form.useForm()

  const consentMessageList = Form.useWatch(['consentMessage'], form)

  useEffect(() => {
    setPageTitle(getText('TEXT_CUSTOM_ASK_CONSENT_MESSAGES'))
    setFooterButtons([
      {
        type: CANCEL_BTN,
        action: () => fill(),
      },
      {
        type: SUBMIT_BTN,
      },
    ])
  }, [])

  const fill = useCallback(async () => {
    const result = await organizationActions.getCustomMessageConfigs(
      organization._id
    )
    if (result.success) {
      form.setFieldsValue({
        consentMessage: result.data.consentMessage,
      })
    } else {
      notifyError(getTextServerError(result.message))
    }
  }, [])

  useEffect(() => {
    fill()
  }, [fill])

  const listVariables = useMemo(() => {
    return [
      {
        label: 'customer_first_name',
        value: '{{customer_first_name}}',
      },
      {
        label: 'customer_last_name',
        value: '{{customer_last_name}}',
      },
      {
        label: 'location_name',
        value: '{{location_name}}',
      },
    ]
  }, [])

  const onFinish = async (values) => {
    setLoading(true)
    const result = await organizationActions.saveCustomMessageConfigs(
      values,
      organization._id
    )
    if (result.success) {
      notifySuccess(
        !organization?._id
          ? getText('TEXT_ORGANIZATION_WAS_CREATED_SUCCESSFULLY')
          : getText('TEXT_ORGANIZATION_WAS_SAVED_SUCCESSFULLY')
      )
      setOrganization(result.data)
    } else {
      notifyError(utilsLocalizations.localizedServerError(result.errMsg))
    }
    setLoading(false)
  }

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields.length > 0) {
      notifyError(errorInfo.errorFields[0].errors[0])
    }
  }

  if (!organization?._id) {
    return <LoadingSpinner />
  }

  return (
    <MyDefaultPageLayout disableHeader>
      <MyDefaultBlock
        title={getText('TEXT_CUSTOM_ASK_CONSENT_MESSAGES')}
        additionalHeader={
          consentMessageList?.length < 5 && (
            <MyDefaultBlockHeaderAdditionalButton
              buttonText={getText('WORD_ADD_NEW')}
              onClick={() => customConstentActionsRef?.current?.add('')}
            />
          )
        }
      >
        <MatForm form={form} onFinishFailed={onFinishFailed} onFinish={onFinish}>
          <Form.List name={['consentMessage']}>
            {(fields, { add, remove }) => {
              customConstentActionsRef.current = { add, remove }
              return fields.map((field, index) => {
                return (
                  <CustomAskConsentMessagesItem
                    key={index}
                    consentMessageList={consentMessageList}
                    field={field}
                    fields={fields}
                    index={index}
                    languages={organization?.languages}
                    listVariables={listVariables}
                    remove={remove}
                    form={form}
                  />
                )
              })
            }}
          </Form.List>
        </MatForm>
      </MyDefaultBlock>
    </MyDefaultPageLayout>
  )
}

export default CustomAskConsentMessages
