import React from 'react'
import { Flex } from 'antd'
import SVGIntegratedCTASMSIcon from '../../../../icons/SVG/SVGIntegratedCTASMSIcon'

const CtaPreview = ({
  title,
  color,
  height,
  width,
  borderRadius,
  textColor = '#fff',
  fontSize,
  isInitialTextMessageBold,
}) => {
  return (
    <Flex
      align='center'
      justify='center'
      gap={3}
      style={{
        margin: '0 auto',
        backgroundColor: color,
        color: textColor,
        height: `${height}px`,
        width: `${width}`,
        borderRadius: `${borderRadius}px`,
        fontSize: `${fontSize}px`,
        fontWeight: isInitialTextMessageBold ? 'bold' : 'normal',
      }}
    >
      {<SVGIntegratedCTASMSIcon color={textColor} />}
      {title}
    </Flex>
  )
}

export default CtaPreview
