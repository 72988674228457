import {
  fetchFromUrlPOSTAsync,
  fetchFromUrlGETAsync,
  fetchFromUrlPUTAsync,
  fetchFromUrlDELETEAsync,
} from '../../utils/UrlHelper'

const urlWelcomeViews = '/chat-welcome-views'

const fs = {
  getAllList: async (page, perPage, locationId) => {
    const urlParams = [
      { key: 'locationId', value: locationId },
      { key: 'page', value: page },
      { key: 'perPage', value: perPage },
    ]

    const result = await fetchFromUrlGETAsync(urlWelcomeViews, urlParams)
    if (result.success) {
      result.max = result.data.total
      result.data = result.data.welcome_views
    }
    return result
  },

  insert: async (obj) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    const result = await fetchFromUrlPOSTAsync(urlWelcomeViews, urlParams)
    return result
  },

  update: async (obj, id) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    const result = await fetchFromUrlPUTAsync(`${urlWelcomeViews}/${id}`, urlParams)
    return result
  },

  delete: async (id) => {
    const result = await fetchFromUrlDELETEAsync(`${urlWelcomeViews}/${id}`, [])
    return result
  },
}

const loc = {}

const welcomeActions = Object.assign(fs, loc)

export default welcomeActions
