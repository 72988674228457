import React from 'react'
import MicrosoftAuth from 'react-microsoft-oauth'
import authActions from '../../store/modules/authActions'
import { getText } from '../../lang'
import { siteUrl } from '../../utils/UrlHelper'
import ConfirmPopup from '../ConfirmPopup'
import MyDefaultButton from '../MyDefaultButton/MyDefaultButton'

const MicrosoftAuthAPI = (props) => {
  const { onSuccess, disabled, isConnected } = props

  const authSuccessCallback = async (code) => {
    //TODO Replase below to use location selected from dropdown
    // let locationId = '5cde80a8a4ed7d4aa68c6aca';
    let result = await authActions.authMicrosoft(code, props.idLocation)
    if (result.success) {
      onSuccess && onSuccess('login')
    }
  }

  const responseLogout = async (data) => {
    let result = await authActions.authLogOutMicrosoft(props.idLocation)
    if (result.success) {
      onSuccess && onSuccess('logout')
    }
  }

  return disabled ? (
    <MyDefaultButton
      buttonText={getText('TEXT_CONNECT_OFFICE_365')}
      disabled={disabled}
      typeButton={props.typeButton || 'microsoft'}
      htmlType={'button'}
    />
  ) : isConnected ? (
    <ConfirmPopup
      title={getText(
        'TEXT_ARE_YOU_SURE_YOU_WANT_TO_DISCONNECT_YOUR_OUTLOOK_ACCOUNT'
      )}
      onConfirm={responseLogout}
      okText={getText('WORD_DISCONNECT')}
      cancelText={getText('ACTION_CANCEL')}
      trigger={
        <MyDefaultButton
          buttonText={getText('TEXT_DISCONNECT_OFFICE_365')}
          disabled={disabled}
          typeButton={props.typeButton || 'microsoft'}
          htmlType={'button'}
        />
      }
    />
  ) : (
    <MicrosoftAuth
      clientId={process.env.REACT_APP_MICROSOFT_CLIENT_ID}
      scope={'https://outlook.office.com/calendars.readwrite offline_access'}
      redirectUri={siteUrl}
      authSuccessCallback={authSuccessCallback}
      authErrorCallback={(error) => console.log(error)}
      responseType='code'
      children={
        <MyDefaultButton
          buttonText={getText('TEXT_CONNECT_OFFICE_365')}
          disabled={disabled}
          typeButton={props.typeButton || 'microsoft'}
          htmlType={'button'}
        />
      }
    />
  )
}

export default MicrosoftAuthAPI
