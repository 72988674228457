import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Divider, Flex, Form, Space } from 'antd'
import { useSelector } from 'react-redux'
import { debounce } from 'lodash'
import { getText, getTextServerError } from '../../../../lang'
import MatForm from '../../../../components/Form/MatForm'
import { notifyError, notifySuccess } from '../../../../utils/Notify'
import LoadingSpinner from '../../../../components/LoadingSpinner'
import organizationActions from '../../../../store/modules/organizationActions'
import authActions from '../../../../store/modules/authActions'
import { GET_DATA_FETCH_SIZE, getUserListWithAssignedUsers } from '../../../../utils'
import userActions from '../../../../store/modules/userActions'
import { getUsersListIds } from '../../../broadcast/CreateCampaign/CreateSegment/utils'
import {
  isSuperAdminSelector,
  locationSelector,
  menuOpenSelector,
  userSelector,
} from '../../../../store/selectors/selectors'
import { useLayout } from '../../../../layout/LayoutProvider/LayoutProvider'
import { useOrganizationLayout } from '../../organizationProvider/OrganizationProvider'
import { ASSIGNED_USERS, CANCEL_BTN, SUBMIT_BTN } from '../../../../devOptions'
import MyDefaultPageLayout from '../../../../components/MyDefaultPageLayout/MyDefaultPageLayout'
import MyDefaultBlock from '../../../../components/MyDefaultBlock/MyDefaultBlock'
import Div from '../../../../components/Div/Div'
import useDeviceCheck from '../../../../utils/useDeviceCheck'
import MyDefaultInputText from '../../../../components/MyDefaultInputText/MyDefaultInputText'
import MyDefaultSwitch from '../../../../components/MyDefaultSwitch/MyDefaultSwitch'
import MyDefaultBlockCollapse from '../../../../components/MyDefaultBlockCollapse/MyDefaultBlockCollapse'
import MyDefaultSelect from '../../../../components/MyDefaultSelect/MyDefaultSelect'
import MyDefaultTextarea from '../../../../components/MyDefaultTextarea/MyDefaultTextarea'
import MyDefaultInsidePageTabs from '../../../../components/MyDefaultInsidePageTabs/MyDefaultInsidePageTabs'
import SuperhumanIntentConfiguration from './SuperhumanIntentConfiguration'
import SuperhumanTagUsersSwitch from './SuperhumanTagUsersSwitch'
import SuperhumanCommunicationChannels from './SuperhumanCommunicationChannels'
import SuperhumanConversationPreview from './SuperhumanConversationPreview'
import SuperhumanAIAutoReply from './SuperhumanAIAutoReply'
import './Superhuman.scss'

const Superhuman = () => {
  const { selectedOrganization: organization } = useOrganizationLayout()
  const { setPageTitle, setLoading, setFooterButtons } = useLayout()
  const { isPageDrawerSize } = useDeviceCheck()

  const user = useSelector(userSelector)
  const location = useSelector(locationSelector)
  const isMenuOpen = useSelector(menuOpenSelector)
  const isSuperAdmin = useSelector(isSuperAdminSelector)

  const [loadingData, setLoadingData] = useState(false)
  const [page, setPage] = useState(0)
  const [assignSearchKeyword, setAssignSearchKeyword] = useState('')
  const [usersList, setUsersList] = useState({
    list: [],
    total: 0,
  })

  const [form] = Form.useForm()

  const enabledSuperhuman = Form.useWatch('enabled', form)
  const allowAdminsToEditSuperhuman =
    organization?.restrictions?.allowAdminsToEditSuperhuman
  const roleAdminAndAllowAdminsToEditSuperhuman = useMemo(() => {
    return !user.isSuperAdmin && !allowAdminsToEditSuperhuman
  }, [user.isSuperAdmin, allowAdminsToEditSuperhuman])

  const intentsList = useMemo(
    () => [
      {
        label: getText('WORD_APPOINTMENT.ALREADY_BOUGHT'),
        value: 'appointment.already_bought',
      },
      {
        label: getText('WORD_APPOINTMENT.ALREADY_CANCELLED_APPOINTMENT'),
        value: 'appointment.already_cancelled_appointment',
      },
      {
        label: getText('WORD_APPOINTMENT.ALREADY_SCHEDULED'),
        value: 'appointment.already_scheduled',
      },
      {
        label: getText('WORD_APPOINTMENT.CANCEL_APPOINTMENT'),
        value: 'appointment.cancel_appointment',
      },
      {
        label: getText('WORD_APPOINTMENT.SERVICE_APPOINTMENT'),
        value: 'appointment.service_appointment',
      },
      { label: getText('WORD_APPROVAL'), value: 'approval' },
      { label: getText('WORD_GENERAL_AVAILABILITY'), value: 'availability' },
      {
        label: getText('WORD_AVAILABILITY.NOT_SURE_OF_VEHICLE'),
        value: 'availability.not_sure_of_vehicle',
      },
      {
        label: getText('WORD_AVAILABILITY.UPDATE_ON_VEHICLE'),
        value: 'availability.update_on_vehicle',
      },
      { label: getText('WORD_APPOINTMENT_CONFIRMATION'), value: 'confirmed' },
      { label: getText('WORD_DEALER_FEE'), value: 'dealer_fee' },
      { label: getText('WORD_DOWN_PAYMENT'), value: 'down_payment' },
      { label: getText('WORD_GENERAL_FINANCE'), value: 'finance' },
      { label: getText('WORD_FINANCE.BAD_CREDIT'), value: 'finance.bad_credit' },
      { label: getText('WORD_FINANCE.INSURANCE'), value: 'finance.insurance' },
      {
        label: getText('WORD_FINANCE.REQUIRED_DOCUMENTS'),
        value: 'finance.required_documents',
      },
      { label: getText('WORD_HISTORY_REPORT'), value: 'history_report' },
      { label: getText('WORD_HOLD_TRANSFER'), value: 'hold_transfer' },
      {
        label: getText('WORD_HOURS_AND_LOCATION.LOCATION'),
        value: 'hours_and_location.location',
      },
      {
        label: getText('WORD_HOURS_AND_LOCATION.WORKING_HOURS'),
        value: 'hours_and_location.working_hours',
      },
      { label: getText('WORD_NEGOTIATIONS'), value: 'negotiations' },
      { label: getText('WORD_PAYMENT'), value: 'payment' },
      { label: getText('WORD_PRICE'), value: 'price' },
      {
        label: getText('WORD_PROCESS.ALREADY_DISCUSSED'),
        value: 'process.already_discussed',
      },
      { label: getText('WORD_PROCESS.CALLBACK'), value: 'process.callback' },
      {
        label: getText('WORD_PROCESS.CUSTOMER_HAS_QUERIES'),
        value: 'process.customer_has_queries',
      },
      {
        label: getText('WORD_PROCESS.IN_PLACE_WITH_REPRESENTATIVE'),
        value: 'process.in_place_with_representative',
      },
      {
        label: getText('WORD_PROCESS.NOT_INTERESTED'),
        value: 'process.not_interested',
      },
      {
        label: getText('WORD_PROCESS.WHO_DO_I_SPEAK_TO'),
        value: 'process.who_do_I_speak_to',
      },
      { label: getText('WORD_PROCESS.WHO_IS_THIS'), value: 'process.who_is_this' },
      { label: getText('WORD_REAPPOINTMENT'), value: 'reappointment' },
      { label: getText('WORD_SHIPPING'), value: 'shipping' },
      { label: getText('WORD_TRADES'), value: 'trades' },
      { label: getText('WORD_VEHICLE_OPTIONS'), value: 'vehicle_options' },
      {
        label: getText('WORD_VEHICLE_OPTIONS.PICTURES'),
        value: 'vehicle_options.pictures',
      },
      {
        label: getText('WORD_VEHICLE_RECOMMENDATIONS'),
        value: 'vehicle_recommendations',
      },
      { label: getText('WORD_GENERAL_APPOINTMENT_QUERIES'), value: 'appointment' },
      {
        label: getText('WORD_FINANCE.ONLINE_PROCESSING'),
        value: 'finance.online_processing',
      },
      {
        label: getText('WORD_FINANCE.CREDIT_SCORE'),
        value: 'finance.credit_score',
      },
      {
        label: getText('WORD_FINANCE.ALREADY_SUBMITTED_APPLICATION'),
        value: 'finance.already_submitted_application',
      },
      {
        label: getText('WORD_TIRE_ROTATION'),
        value: 'tire_rotation',
      },
      {
        label: getText('WORD_PARTS_AVAILABILITY'),
        value: 'parts_availability',
      },
      {
        label: getText('WORD_PART_PRICE'),
        value: 'part_price',
      },
      {
        label: getText('WORD_CAR_WASH'),
        value: 'car_wash',
      },
      {
        label: getText('WORD_OIL_CHANGE'),
        value: 'oil_change',
      },
    ],
    []
  )

  useEffect(() => {
    setFooterButtons([
      {
        type: CANCEL_BTN,
        action: () => {
          getSuperConfigData()
        },
      },
      {
        type: SUBMIT_BTN,
      },
    ])
    setPageTitle(getText('AI_BOT_SUPERHUMAN'))
  }, [])

  const intentsSelection = Form.useWatch(['customized_intents'], form)

  const searchUsers = useCallback(async () => {
    setLoadingData(true)
    const result = await userActions.getUsersForAssignUnassign(
      page,
      GET_DATA_FETCH_SIZE,
      organization?._id,
      assignSearchKeyword
    )
    if (result.success) {
      const newListUsers =
        page > 0 ? [...usersList.list, ...result.data] : result.data
      setUsersList({
        list: getUsersListIds(newListUsers),
        total: result.max,
      })
    }
    setLoadingData(false)
  }, [assignSearchKeyword, page])

  useEffect(() => {
    searchUsers()
  }, [searchUsers])

  function customizeUsers(users) {
    users.forEach((user) => {
      user.label = `${user.firstName} ${user.lastName}`
      user.value = user?._id
    })
  }

  const userListWithAssignedUsers = useMemo(
    () => getUserListWithAssignedUsers(usersList.list),
    [usersList.list]
  )

  const getSuperConfigData = useCallback(async () => {
    const result = await organizationActions.getSuperhumanConfig(organization?._id)
    if (result.success) {
      customizeUsers(
        result.data.customized_intents.flatMap(
          (item) => item?.tagging_configuration?.users_to_tag
        )
      )
      customizeUsers(result.data.tagging_configuration.for_chat_ai.users_to_tag)
      customizeUsers(
        result.data.tagging_configuration.tag_users_for_every_message.users_to_tag
      )
      if (
        result.data?.tagging_configuration?.tag_users_for_every_message
          ?.tag_assigned_users
      ) {
        result.data.tagging_configuration.tag_users_for_every_message.users_to_tag.unshift(
          ASSIGNED_USERS
        )
      }
      customizeUsers(
        result.data.tagging_configuration.for_unassigned_conversations.users_to_tag
      )
      customizeUsers(
        result.data.tagging_configuration.tag_for_appointment_actions.users_to_tag
      )
      if (
        result.data?.tagging_configuration?.tag_for_appointment_actions
          ?.tag_assigned_users
      ) {
        result.data.tagging_configuration.tag_for_appointment_actions.users_to_tag.unshift(
          ASSIGNED_USERS
        )
      }
      for (const intent of result.data.customized_intents) {
        if (intent?.tagging_configuration?.tag_assigned_users) {
          intent?.tagging_configuration?.users_to_tag.unshift(ASSIGNED_USERS)
        }
      }
      form.setFieldsValue({ ...result.data })
    }
  }, [])

  useEffect(() => {
    getSuperConfigData()
  }, [getSuperConfigData])

  const superhumanMessageSwitch = Form.useWatch(
    ['tagging_configuration', 'tag_users_for_every_message', 'enabled'],
    form
  )

  const debouncedChange = useCallback(
    debounce((options) => setAssignSearchKeyword(options), 1000),
    []
  )

  const formatData = (values) => {
    return {
      superhumanConfig: {
        ...values,
        customized_intents: !intentsSelection.length
          ? values.customized_intents
          : values.customized_intents.map((item) => {
              return {
                ...item,
                tagging_configuration: {
                  ...item?.tagging_configuration,
                  tag_assigned_users:
                    item.tagging_configuration.users_to_tag.includes(ASSIGNED_USERS),
                  users_to_tag: item.tagging_configuration.users_to_tag
                    .filter((us) => us !== ASSIGNED_USERS)
                    .map((item2) => {
                      if (item2._id) {
                        return item2._id
                      } else {
                        return item2
                      }
                    }),
                },
              }
            }),
        tagging_configuration: {
          ...values.tagging_configuration,
          tag_users_for_every_message: {
            tag_assigned_users:
              superhumanMessageSwitch &&
              values.tagging_configuration.tag_users_for_every_message.users_to_tag.includes(
                ASSIGNED_USERS
              ),
            enabled:
              values.tagging_configuration.tag_users_for_every_message.enabled,
            users_to_tag: superhumanMessageSwitch
              ? values.tagging_configuration.tag_users_for_every_message.users_to_tag
                  .filter((us) => us !== ASSIGNED_USERS)
                  .map((item) => {
                    if (item._id) {
                      return item._id
                    } else {
                      return item
                    }
                  })
              : [],
          },
          for_chat_ai: {
            users_to_tag: (
              values.tagging_configuration?.for_chat_ai?.users_to_tag || []
            ).map((item) => {
              if (item._id) {
                return item._id
              } else {
                return item
              }
            }),
          },
          tag_for_appointment_actions: {
            tag_assigned_users:
              values.tagging_configuration.tag_for_appointment_actions.users_to_tag.includes(
                ASSIGNED_USERS
              ),
            users_to_tag:
              values.tagging_configuration.tag_for_appointment_actions.users_to_tag
                .filter((us) => us !== ASSIGNED_USERS)
                .map((item) => {
                  if (item._id) {
                    return item._id
                  } else {
                    return item
                  }
                }),
          },
          for_unassigned_conversations: {
            users_to_tag:
              values.tagging_configuration.for_unassigned_conversations.users_to_tag.map(
                (item) => {
                  if (item._id) {
                    return item._id
                  } else {
                    return item
                  }
                }
              ),
          },
        },
      },
    }
  }

  const onFinish = async (values) => {
    setLoading(true)
    const obj = formatData(values)
    const result = await organizationActions.updateSuperhumanConfig(
      organization?._id,
      obj
    )
    if (result.success) {
      notifySuccess(
        !organization?._id
          ? getText('TEXT_ORGANIZATION_WAS_CREATED_SUCCESSFULLY')
          : getText('TEXT_ORGANIZATION_WAS_SAVED_SUCCESSFULLY')
      )
      organization.superhumanConfig.enabled = result.data.enabled
      const currentOrganization = authActions.getOrganization()
      if (organization?._id === currentOrganization._id) {
        authActions.setOrganization(organization)
      }
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
    setLoading(false)
  }

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields.length > 0) {
      notifyError(errorInfo.errorFields[0].errors[0])
    }
  }
  if (!organization?._id) {
    return <LoadingSpinner />
  }

  const showErrorMessage = (inputName) => {
    return getText('ERRORS_INPUT_CONVERSATIONAI').replace('[name]', inputName)
  }

  return (
    <MyDefaultPageLayout disableHeader>
      <MatForm form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <Flex
          gap={20}
          align='start'
          vertical={isPageDrawerSize}
          className={isMenuOpen ? 'superhuman_menu_opened' : ''}
        >
          <Div style={{ maxWidth: '625px', width: '100%' }}>
            <MyDefaultBlock>
              <MyDefaultSwitch
                isForm
                name={'enabled'}
                title={getText('WORD_SUPERUMAN_AI_IS').replace(
                  '[state]',
                  getText(
                    enabledSuperhuman ? 'WORD_ACTIVE' : 'WORD_INACTIVE'
                  ).toLowerCase()
                )}
                isFirst
                titleStyle={{ fontSize: 14 }}
                noText
                noBorder
                disablePadding
                disabled={!isSuperAdmin}
              />
            </MyDefaultBlock>
            {Boolean(user.isSuperAdmin || user.isAdmin) && (
              <MyDefaultBlockCollapse
                enableMarginTop
                defaultActiveKey={['ai_general_settings']}
                items={[
                  {
                    key: 'ai_general_settings',
                    label: getText('WORD_AI_GENERAL_SETTINGS'),
                    children: (
                      <React.Fragment>
                        <Space
                          size={12}
                          direction={'vertical'}
                          style={{ width: '100%' }}
                        >
                          <SuperhumanAIAutoReply
                            name={['working_hours', 'in_range', 'delay_minutes']}
                            switchName={['working_hours', 'in_range', 'enabled']}
                            title={getText('WORD_OPENING_HOURS')}
                            enabledSuperhuman={
                              enabledSuperhuman &&
                              !roleAdminAndAllowAdminsToEditSuperhuman
                            }
                          />
                          <SuperhumanAIAutoReply
                            name={['working_hours', 'off_range', 'delay_minutes']}
                            switchName={['working_hours', 'off_range', 'enabled']}
                            title={getText('WORD_AFTER_HOURS')}
                            enabledSuperhuman={
                              enabledSuperhuman &&
                              !roleAdminAndAllowAdminsToEditSuperhuman
                            }
                          />
                        </Space>
                        <Divider
                          plain
                          style={{
                            margin: '16px 0 12px',
                            borderBlockStart: '1px solid var(--borderLineColor)',
                          }}
                        />
                        <SuperhumanCommunicationChannels
                          disabled={roleAdminAndAllowAdminsToEditSuperhuman}
                        />
                        <Divider
                          plain
                          style={{
                            margin: '8px 0 12px',
                            borderBlockStart: '1px solid var(--borderLineColor)',
                          }}
                        />
                        {!Boolean(user.isSuperAdmin) && (
                          <MyDefaultInputText
                            isForm
                            isLast={Boolean(user.isSuperAdmin)}
                            name={['options', 'agent_role']}
                            label={getText('WORD_AGENT_ROLE')}
                            maxLength={50}
                            message={showErrorMessage(getText('WORD_AGENT_ROLE'))}
                            required
                            disabled={roleAdminAndAllowAdminsToEditSuperhuman}
                          />
                        )}
                        {Boolean(user.isSuperAdmin && user.isAdmin) && (
                          <React.Fragment>
                            <MyDefaultSwitch
                              isForm
                              isFirst
                              titleStyle={{ fontSize: 14 }}
                              name={['options', 'inventory_enabled']}
                              noText
                              title={getText(
                                'TEXT_IF_THE_INVENTORY_INTEGRATIONS_IS_ENABLED'
                              )}
                            />
                            <MyDefaultSelect
                              isForm
                              isFirst
                              name={['options', 'company_type']}
                              label={getText('WORD_COMPANY_TYPE')}
                              placeholder={getText('WORD_COMPANY_TYPE')}
                              message={showErrorMessage(
                                getText('WORD_COMPANY_TYPE')
                              )}
                              required
                              options={[
                                {
                                  label: getText('LABEL_CAR_DEALERSHIP'),
                                  value: 'car dealership',
                                },
                                {
                                  label: getText('LABEL_SOLAR_PANEL_COMPANY'),
                                  value: 'solar panel company',
                                },
                              ]}
                            />
                            <MyDefaultInputText
                              isForm
                              isLast={!Boolean(user.isSuperAdmin)}
                              name={['options', 'agent_role']}
                              label={getText('WORD_AGENT_ROLE')}
                              maxLength={50}
                              message={showErrorMessage(getText('WORD_AGENT_ROLE'))}
                              required
                            />
                          </React.Fragment>
                        )}
                        <MyDefaultInputText
                          isForm
                          isLast={Boolean(user.isSuperAdmin || user.isAdmin)}
                          name={['options', 'agent_name']}
                          label={getText('WORD_AGENT_NAME')}
                          maxLength={50}
                          disabled={roleAdminAndAllowAdminsToEditSuperhuman}
                        />
                      </React.Fragment>
                    ),
                  },
                ]}
              />
            )}
            <MyDefaultBlock enableMarginTop>
              <MyDefaultInsidePageTabs
                isFirst
                items={[
                  {
                    key: 'general',
                    label: getText('WORD_GENERAL_INSTRUCTIONS'),
                    forceRender: true,
                    children: (
                      <MyDefaultTextarea
                        isForm
                        name={['instructions', 'general']}
                        label={''}
                        placeholder={getText('TEXT_TYPE_YOUR_INSTRUCTIONS')}
                        maxLength={25000}
                        autoSize={{ minRows: 5, maxRows: 20 }}
                        showCount
                        disabled={roleAdminAndAllowAdminsToEditSuperhuman}
                      />
                    ),
                  },
                  {
                    key: 'human',
                    label: getText('WORD_HUMAN'),
                    forceRender: true,
                    children: (
                      <MyDefaultTextarea
                        isForm
                        name={['instructions', 'human']}
                        label={''}
                        placeholder={getText('TEXT_TYPE_YOUR_INSTRUCTIONS')}
                        maxLength={25000}
                        autoSize={{ minRows: 5, maxRows: 20 }}
                        showCount
                        disabled={roleAdminAndAllowAdminsToEditSuperhuman}
                      />
                    ),
                  },
                  {
                    key: 'promotions',
                    label: getText('WORD_PROMOTIONS'),
                    forceRender: true,
                    children: (
                      <MyDefaultTextarea
                        isForm
                        name={['instructions', 'promotions']}
                        label={''}
                        placeholder={getText('TEXT_TYPE_YOUR_INSTRUCTIONS')}
                        maxLength={25000}
                        autoSize={{ minRows: 5, maxRows: 20 }}
                        showCount
                        disabled={roleAdminAndAllowAdminsToEditSuperhuman}
                      />
                    ),
                  },
                ]}
              />
            </MyDefaultBlock>
            {Boolean(user.isSuperAdmin || user.isAdmin) && (
              <React.Fragment>
                <MyDefaultBlockCollapse
                  enableMarginTop
                  firstItemSwitch
                  defaultActiveKey={['ai_general_tagging_conf']}
                  items={[
                    {
                      key: 'ai_general_tagging_conf',
                      label: getText('TEXT_GENERAL_TAGGING_CONFIGURATION'),
                      children: (
                        <React.Fragment>
                          {Boolean(isSuperAdmin) && (
                            <React.Fragment>
                              <MyDefaultSelect
                                isForm
                                name={[
                                  'tagging_configuration',
                                  'for_chat_ai',
                                  'users_to_tag',
                                ]}
                                label={getText(
                                  'TEXT_USERS_TO_TAG_FOR_CHAT_SESSIONS'
                                )}
                                placeholder={getText('ACTION_SELECT_USER')}
                                message={getText('ERR_MSG_TAG_WORKFLOW')}
                                mode='multiple'
                                showSearch={true}
                                options={usersList.list || []}
                                onSearch={debouncedChange}
                                onBlur={() => {
                                  setAssignSearchKeyword('')
                                }}
                                onChange={() => {
                                  setAssignSearchKeyword('')
                                }}
                                onLoadMore={() => {
                                  if (
                                    usersList.list.length < usersList.total &&
                                    !loadingData
                                  ) {
                                    setPage(page + 1)
                                  }
                                }}
                              />
                              <Divider
                                plain
                                style={{
                                  margin: '16px 0 12px',
                                  borderBlockStart:
                                    '1px solid var(--borderLineColor)',
                                }}
                              />
                            </React.Fragment>
                          )}
                          <MyDefaultSwitch
                            isForm
                            isFirst
                            titleStyle={{ fontSize: 14 }}
                            name={[
                              'tagging_configuration',
                              'tag_users_for_every_message',
                              'enabled',
                            ]}
                            noText
                            title={getText(
                              'TEXT_ALLOW_TAGGING_USERS_FOR_EVERY_SUPERHUMAN_MESSAGE'
                            )}
                            disabled={roleAdminAndAllowAdminsToEditSuperhuman}
                          />
                          {superhumanMessageSwitch && (
                            <MyDefaultSelect
                              isForm
                              isFirst
                              name={[
                                'tagging_configuration',
                                'tag_users_for_every_message',
                                'users_to_tag',
                              ]}
                              mode='multiple'
                              label={''}
                              loading={loadingData}
                              placeholder={getText('ACTION_SELECT_USER')}
                              message={getText(
                                'TEXT_PLEASE_CHOOSE_AT_LEAST_ONE_USER'
                              )}
                              required
                              showSearch={true}
                              options={userListWithAssignedUsers || []}
                              customizedColorsObj={{
                                assigned_users: {
                                  backgroundColor: 'var(--mainColor)',
                                  textColor: 'var(--white)',
                                },
                              }}
                              onSearch={debouncedChange}
                              onBlur={() => {
                                setAssignSearchKeyword('')
                              }}
                              onChange={() => {
                                setAssignSearchKeyword('')
                              }}
                              onLoadMore={() => {
                                if (
                                  usersList.list.length < usersList.total &&
                                  !loadingData
                                ) {
                                  setPage(page + 1)
                                }
                              }}
                              disabled={roleAdminAndAllowAdminsToEditSuperhuman}
                            />
                          )}
                          <Divider
                            plain
                            style={{
                              margin: '16px 0',
                              borderBlockStart: '1px solid var(--borderLineColor)',
                            }}
                          />
                          <MyDefaultSelect
                            isForm
                            isFirst
                            name={[
                              'tagging_configuration',
                              'for_unassigned_conversations',
                              'users_to_tag',
                            ]}
                            label={getText(
                              'TEXT_USERS_TO_TAG_FOR_UNASSIGNED_CONVERSATIONS'
                            )}
                            placeholder={getText('ACTION_SELECT_USER')}
                            message={getText('ERR_MSG_TAG_WORKFLOW')}
                            mode='multiple'
                            showSearch={true}
                            options={usersList.list || []}
                            onSearch={debouncedChange}
                            onBlur={() => {
                              setAssignSearchKeyword('')
                            }}
                            onChange={() => {
                              setAssignSearchKeyword('')
                            }}
                            onLoadMore={() => {
                              if (
                                usersList.list.length < usersList.total &&
                                !loadingData
                              ) {
                                setPage(page + 1)
                              }
                            }}
                            disabled={roleAdminAndAllowAdminsToEditSuperhuman}
                          />
                          <MyDefaultSelect
                            isForm
                            name={[
                              'tagging_configuration',
                              'tag_for_appointment_actions',
                              'users_to_tag',
                            ]}
                            label={getText(
                              'TEXT_USERS_TAG_WHEN_APPOINTMENTS_ARE_CREATED_EDITED_CANCELLED'
                            )}
                            placeholder={getText('ACTION_SELECT_USER')}
                            message={getText('ERR_MSG_TAG_WORKFLOW')}
                            mode='multiple'
                            showSearch={true}
                            options={userListWithAssignedUsers || []}
                            customizedColorsObj={{
                              assigned_users: {
                                backgroundColor: 'var(--mainColor)',
                                textColor: 'var(--white)',
                              },
                            }}
                            onSearch={debouncedChange}
                            onBlur={() => {
                              setAssignSearchKeyword('')
                            }}
                            onChange={() => {
                              setAssignSearchKeyword('')
                            }}
                            onLoadMore={() => {
                              if (
                                usersList.list.length < usersList.total &&
                                !loadingData
                              ) {
                                setPage(page + 1)
                              }
                            }}
                            disabled={roleAdminAndAllowAdminsToEditSuperhuman}
                          />
                          <Divider
                            plain
                            style={{
                              margin: '16px 0',
                              borderBlockStart: '1px solid var(--borderLineColor)',
                            }}
                          />
                          <SuperhumanTagUsersSwitch
                            disabled={roleAdminAndAllowAdminsToEditSuperhuman}
                          />
                        </React.Fragment>
                      ),
                    },
                  ]}
                />
                <MyDefaultBlockCollapse
                  enableMarginTop
                  defaultActiveKey={['ai_intent_conf']}
                  items={[
                    {
                      key: 'ai_intent_conf',
                      label: getText('TEXT_INTENT_CONFIGURATION'),
                      children: (
                        <Form.List name={'customized_intents'}>
                          {(fields, { add, remove }) => (
                            <React.Fragment>
                              <MyDefaultSelect
                                isFirst
                                fullWidth
                                label={''}
                                showSearch={true}
                                placeholder={getText('LABEL_SELECT_INTENTS')}
                                mode='multiple'
                                options={intentsList}
                                onClear={() => {
                                  form.setFieldsValue({
                                    customized_intents: [],
                                  })
                                }}
                                value={
                                  intentsSelection && intentsSelection.length
                                    ? intentsSelection.map((item) => item.intent_id)
                                    : []
                                }
                                onSelectItem={(item) => {
                                  add({
                                    instructions: '',
                                    intent_id: item.value,
                                    is_excluded: false,
                                    tagging_configuration: {
                                      users_to_tag: [],
                                    },
                                  })
                                }}
                                onDeselect={(val) => {
                                  const findIndex = intentsSelection.findIndex(
                                    (item) => item.intent_id === val
                                  )
                                  remove(findIndex)
                                }}
                                disabled={roleAdminAndAllowAdminsToEditSuperhuman}
                              />
                              {fields.map((field, index) => {
                                const isCustomized_intents =
                                  form.getFieldsValue().customized_intents &&
                                  form.getFieldsValue().customized_intents[
                                    field.name
                                  ]
                                let intentName
                                if (isCustomized_intents) {
                                  intentName = intentsList.find((findItem) => {
                                    return (
                                      findItem.value ===
                                      isCustomized_intents.intent_id
                                    )
                                  })
                                }
                                return (
                                  <SuperhumanIntentConfiguration
                                    key={index}
                                    form={form}
                                    field={field}
                                    intentName={intentName}
                                    usersList={userListWithAssignedUsers || []}
                                    loading={loadingData}
                                    onSearch={debouncedChange}
                                    onBlur={() => {
                                      setAssignSearchKeyword('')
                                    }}
                                    onChange={() => {
                                      setAssignSearchKeyword('')
                                    }}
                                    onLoadMore={() => {
                                      if (
                                        usersList.list.length < usersList.total &&
                                        !loadingData
                                      ) {
                                        setPage(page + 1)
                                      }
                                    }}
                                    onRemove={() => {
                                      remove(field.name)
                                    }}
                                    disabled={
                                      roleAdminAndAllowAdminsToEditSuperhuman
                                    }
                                  />
                                )
                              })}
                            </React.Fragment>
                          )}
                        </Form.List>
                      ),
                    },
                  ]}
                />
              </React.Fragment>
            )}
          </Div>
          <MyDefaultBlock position={'sticky'} top={0} maxWidth={430}>
            <SuperhumanConversationPreview
              organizationId={organization?._id}
              locationId={location?.id}
              getFormValues={() => {
                const data = formatData(form.getFieldsValue(true))
                delete data.superhumanConfig.updatedAt
                delete data.superhumanConfig.updatedBy
                return data.superhumanConfig
              }}
            />
          </MyDefaultBlock>
        </Flex>
      </MatForm>
    </MyDefaultPageLayout>
  )
}

export default Superhuman
