import React, { useState } from 'react'
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import MyDefaultTableList from '../MyDefaultTableList/MyDefaultTableList'

const Row = (props) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } =
    useSortable({
      id: props['data-row-key'],
    })
  const style = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    cursor: 'grab',
    ...(isDragging
      ? {
          position: 'relative',
          zIndex: 9999,
        }
      : {}),
  }
  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />
  )
}
const MyDefaultDraggableTableList = ({
  pageStart,
  loading,
  dataWithKeys,
  getScrollParent,
  onLoadMore,
  totalDataCount,
  columns,
  onFinishDrag,
}) => {
  const [isDragging, setIsDragging] = useState(false)

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 1,
      },
    })
  )
  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      if (active.id !== over?.id) {
        const activeIndex = dataWithKeys.findIndex((i) => i.key === active?.id)
        const overIndex = dataWithKeys.findIndex((i) => i.key === over?.id)
        onFinishDrag && onFinishDrag(arrayMove(dataWithKeys, activeIndex, overIndex))
      }
    }
    setIsDragging(false)
  }
  return (
    <DndContext
      sensors={sensors}
      modifiers={[restrictToVerticalAxis]}
      onDragEnd={onDragEnd}
      onDragStart={() => {
        setIsDragging(true)
      }}
    >
      <SortableContext
        items={dataWithKeys.map((i) => i.key)}
        strategy={verticalListSortingStrategy}
      >
        <MyDefaultTableList
          components={{
            body: {
              row: Row,
            },
          }}
          rowKey='key'
          columns={columns}
          dataWithKeys={dataWithKeys}
          pageStart={pageStart}
          loading={loading}
          getScrollParent={getScrollParent}
          onLoadMore={onLoadMore}
          totalDataCount={totalDataCount}
          isDragging={isDragging}
        />
      </SortableContext>
    </DndContext>
  )
}
export default MyDefaultDraggableTableList
