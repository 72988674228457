import React, { useEffect, useMemo } from 'react'
import { debounce } from 'lodash'
import MyDefaultModal from '../../../../../components/MyDefaultModal/MyDefaultModal'
import SVGIntegrationModalSettings from '../../../../../icons/SVG/SVGIntegrationModalSettings'
import { getText } from '../../../../../lang'
import MatForm from '../../../../../components/Form/MatForm'
import {
  ACTIVIX,
  DEALER_PEAK,
  DEALER_SOCKET,
  ELEADS,
  MOMENTUM,
  ONE_EIGHTY,
  PBS,
  TEKION,
  VIN_SOLUTION,
} from '../../../../../config/constants'
import MyDefaultSelect from '../../../../../components/MyDefaultSelect/MyDefaultSelect'
import MyDefaultInputNumber from '../../../../../components/MyDefaultInputNumber/MyDefaultInputNumber'
import MyDefaultInputText from '../../../../../components/MyDefaultInputText/MyDefaultInputText'
import MyDefaultTextarea from '../../../../../components/MyDefaultTextarea/MyDefaultTextarea'
import Div from '../../../../../components/Div/Div'

const leadsCategoriesToIntegrateOptions = [
  {
    value: 'sales',
    label: 'Sales',
  },
  {
    value: 'service',
    label: 'Service',
  },
]

const CrmModal = ({
  loading,
  visible,
  setVisible,
  form,
  onFinish,
  onFinishFailed,
  location,
  hideSubmit,
  selectedCrmType,
  setSelectedCrmType,
  selectTypeOptions,
  setVinUsers,
  getVinUsers,
  getMomentumDealer,
  crmOptions,
  setCrmOptions,
  vinUsers,
  handleScrollUsers,
  dealerSocketValidator,
  dealStatusesToIntegrateList,
}) => {
  const additionalConfigsOptions = useMemo(
    () => [
      {
        value: 1,
        label: getText('TEXT_LIVE_SYNC'),
      },
      {
        value: 2,
        label: getText('TEXT_SMART_SYNC'),
      },
      {
        value: 3,
        label: getText('TEXT_COMPREHENSIVE_SYNC'),
      },
    ],
    []
  )

  useEffect(() => {
    form.setFieldsValue({
      name: location.name,
      address: location.address,
      email: location.email,
      crmType: location.crmType ? location.crmType : 'none',
      crmConfigs: {
        activix: {
          account_id:
            location.crmConfigs &&
            location.crmConfigs.activix &&
            location.crmConfigs.activix.account_id,
          api_key:
            location.crmConfigs &&
            location.crmConfigs.activix &&
            location.crmConfigs.activix.api_key,
          matadorUserId:
            location.crmConfigs &&
            location.crmConfigs.activix &&
            location.crmConfigs.activix.matadorUserId,
        },
        vinSolution: {
          dealerId:
            location.crmConfigs &&
            location.crmConfigs.vinSolution &&
            location.crmConfigs.vinSolution.dealerId,
          userId:
            location.crmConfigs &&
            location.crmConfigs.vinSolution &&
            location.crmConfigs.vinSolution.userId
              ? location.crmConfigs.vinSolution.user.UserId
              : '',
          isSourceMatador:
            location.crmConfigs &&
            location.crmConfigs.vinSolution &&
            location.crmConfigs.vinSolution.isSourceMatador,
          user:
            location.crmConfigs &&
            location.crmConfigs.vinSolution &&
            location.crmConfigs.vinSolution.user,
        },
        momentum: {
          clientId: location?.crmConfigs?.momentum?.clientId,
          dealerName: location?.crmConfigs?.momentum?.dealerName || '',
          dealerUUID: location?.crmConfigs?.momentum?.dealerUUID,
          employees: crmOptions?.crmConfigs?.momentum?.employees || [],
          matadorUserId: crmOptions?.crmConfigs?.momentum?.matadorUserId || '',
        },
        eLeads: {
          subscriptionId:
            (location.crmConfigs &&
              location.crmConfigs.eLeads &&
              location.crmConfigs.eLeads.subscriptionId &&
              location.crmConfigs.eLeads.subscriptionId
                .replace(/[0-9a-wy-z]/gi, 'x')
                .slice(0, -4) +
                location.crmConfigs.eLeads.subscriptionId.slice(-4)) ||
            '',
        },
        dealerSocket: {
          dealerNumber:
            location.crmConfigs &&
            location.crmConfigs.dealerSocket &&
            location.crmConfigs.dealerSocket.dealerNumber,

          leadsCategoriesToIntegrate:
            location.crmConfigs &&
            location.crmConfigs.dealerSocket &&
            location.crmConfigs.dealerSocket.leadsCategoriesToIntegrate,
        },
        dealerPeak: {
          dealerGroupID:
            location.crmConfigs &&
            location.crmConfigs.dealerPeak &&
            location.crmConfigs.dealerPeak.dealerGroupID,
        },
        oneEighty: {
          dealerId:
            location.crmConfigs &&
            location.crmConfigs.oneEighty &&
            location.crmConfigs.oneEighty.dealerId,
          quoteId:
            location.crmConfigs &&
            location.crmConfigs.oneEighty &&
            location.crmConfigs.oneEighty.quoteId,
        },
        pbs: {
          serialNumber:
            location.crmConfigs &&
            location.crmConfigs.pbs &&
            location.crmConfigs.pbs.serialNumber,

          availableDealsStatuses:
            location.crmConfigs &&
            location.crmConfigs.pbs &&
            location.crmConfigs.pbs.availableDealsStatuses,

          dealStatusesToIntegrate:
            location.crmConfigs &&
            location.crmConfigs.pbs &&
            location.crmConfigs.pbs.dealStatusesToIntegrate,
        },
        tekion: {
          dealerId:
            location.crmConfigs &&
            location.crmConfigs.tekion &&
            location.crmConfigs.tekion.dealerId,
          clientId:
            location.crmConfigs &&
            location.crmConfigs.tekion &&
            location.crmConfigs.tekion.clientId,
        },
      },
      additionalConfigs: {
        crmSynchroFlow:
          location.additionalConfigs && location.additionalConfigs.crmSynchroFlow,
      },
    })
  }, [visible])

  return (
    <MyDefaultModal
      trigger={<SVGIntegrationModalSettings />}
      title={getText('WORD_CONNECT_TO_CRM')}
      confirmLoading={loading}
      forceRender={false}
      visible={visible}
      onTrrigerClick={() => {
        setVisible(true)
        form.resetFields()
      }}
      onCancel={() => {
        setVisible(false)
        form.resetFields()
      }}
      hideSubmit={hideSubmit}
      okText={getText('ACTION_SAVE')}
    >
      <MatForm form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <MyDefaultSelect
          isForm
          label={getText('WORD_CRM_TYPE')}
          name={'crmType'}
          options={selectTypeOptions}
          onChangeItem={(_, typeObj) => {
            setSelectedCrmType(typeObj)
          }}
          getPopupContainer={() => document.body}
        />
        {selectedCrmType.value === VIN_SOLUTION && (
          <React.Fragment>
            <MyDefaultInputNumber
              isForm
              fullWidth
              label={getText('WORD_DEALER_ID')}
              name={['crmConfigs', 'vinSolution', 'dealerId']}
              onChange={debounce((e) => {
                setVinUsers({ users: [], maxUsers: 0 })
                getVinUsers(e.target.value)
              }, 1000)}
              required
              errorMessage={getText('TEXT_PLEASE_INPUT_DEALER_ID')}
              isEnterDisabled
            />
            {Boolean(
              crmOptions.crmConfigs &&
                crmOptions.crmConfigs.vinSolution &&
                crmOptions.crmConfigs.vinSolution.dealerId &&
                vinUsers.users.length
            ) ? (
              <MyDefaultSelect
                isForm
                label={getText('WORD_USER')}
                name={['crmConfigs', 'vinSolution', 'user', 'FullName']}
                options={vinUsers.users.map((user) => ({
                  value: user.UserId,
                  label: user.FullName,
                }))}
                onChange={(userId) => {
                  const userObj = vinUsers.users.find(
                    (user) => user.UserId === userId
                  )

                  form.setFieldsValue({
                    crmConfigs: {
                      ...setCrmOptions.crmConfigs,
                      vinSolution: {
                        ...crmOptions.crmConfigs.vinSolution,
                        userId: userObj.UserId,
                        user: userObj,
                      },
                    },
                  })
                  setCrmOptions({
                    crmConfigs: {
                      ...setCrmOptions.crmConfigs,
                      vinSolution: {
                        ...crmOptions.crmConfigs.vinSolution,
                        userId: userObj.UserId,
                        user: userObj,
                      },
                    },
                  })
                }}
                showSearch={false}
                onLoadMore={handleScrollUsers}
              />
            ) : null}
          </React.Fragment>
        )}
        {selectedCrmType.value === MOMENTUM && (
          <React.Fragment>
            <MyDefaultInputText
              isForm
              fullWidth
              label={getText('WORD_CLIENT_ID')}
              name={['crmConfigs', 'momentum', 'clientId']}
              onChange={debounce((e) => {
                getMomentumDealer(e.target.value)
              }, 1000)}
              required
              errorMessage={getText('TEXT_PLEASE_INPUT_CLIENT_ID')}
              isEnterDisabled
            />
            {Boolean(crmOptions?.crmConfigs?.momentum?.dealerName) && (
              <MyDefaultInputText
                isForm
                disabled
                fullWidth
                label={getText('WORD_DEALER_NAME')}
                name={['crmConfigs', 'momentum', 'dealerName']}
                required
                isEnterDisabled
              />
            )}
            {Boolean(crmOptions?.crmConfigs?.momentum?.dealerUUID) && (
              <MyDefaultInputText
                isForm
                disabled
                fullWidth
                label={'dealer UUID'}
                name={['crmConfigs', 'momentum', 'dealerUUID']}
                required
                isEnterDisabled
              />
            )}
            {Boolean(crmOptions?.crmConfigs?.momentum?.employees) && (
              <MyDefaultSelect
                isForm
                isLast
                fullWidth
                options={crmOptions?.crmConfigs?.momentum?.employees}
                label={getText('WORD_MATADOR_USER_ID')}
                name={['crmConfigs', 'momentum', 'matadorUserId']}
                required
              />
            )}
          </React.Fragment>
        )}
        {selectedCrmType.value === ELEADS && (
          <MyDefaultInputText
            isForm
            label={getText('WORD_SUBSCRIPTION_ID')}
            name={['crmConfigs', 'eLeads', 'subscriptionId']}
            required
            isEnterDisabled
            isLast
          />
        )}
        {selectedCrmType.value === DEALER_SOCKET && (
          <React.Fragment>
            <MyDefaultInputText
              isForm
              label={getText('WORD_DEALER_NUMBER')}
              name={['crmConfigs', 'dealerSocket', 'dealerNumber']}
              required
              isEnterDisabled
              validator={dealerSocketValidator}
            />
            <MyDefaultSelect
              isForm
              label={getText('WORD_OPPORTUNITIES')}
              name={['crmConfigs', 'dealerSocket', 'leadsCategoriesToIntegrate']}
              options={leadsCategoriesToIntegrateOptions}
              mode='multiple'
              required
            />
          </React.Fragment>
        )}
        {selectedCrmType.value === DEALER_PEAK && (
          <MyDefaultInputText
            isForm
            label={getText('WORD_DEALER_GROUP_ID')}
            name={['crmConfigs', 'dealerPeak', 'dealerGroupID']}
            required
            isEnterDisabled
            isLast
          />
        )}
        {selectedCrmType.value === ONE_EIGHTY && (
          <React.Fragment>
            <MyDefaultInputText
              isForm
              label={getText('WORD_DEALER_ID')}
              name={['crmConfigs', 'oneEighty', 'dealerId']}
              required
              isEnterDisabled
            />
            <MyDefaultInputText
              isForm
              label={getText('WORD_QUOTE_ID')}
              name={['crmConfigs', 'oneEighty', 'quoteId']}
              required
              isEnterDisabled
            />
          </React.Fragment>
        )}
        {selectedCrmType.value === ACTIVIX && (
          <React.Fragment>
            <MyDefaultInputText
              isForm
              label={getText('WORD_ACCOUNT_ID')}
              name={['crmConfigs', 'activix', 'account_id']}
              required
              isEnterDisabled
            />
            <MyDefaultTextarea
              isForm
              label={getText('WORD_API_KEY')}
              name={['crmConfigs', 'activix', 'api_key']}
              required
              isEnterDisabled
              autoSize={{ minRows: 2, maxRows: 2 }}
            />
            <MyDefaultTextarea
              isForm
              label={getText('WORD_MATADOR_USER_ID')}
              name={['crmConfigs', 'activix', 'matadorUserId']}
              autoSize={{ minRows: 2, maxRows: 2 }}
              required
              isEnterDisabled
              isLast
            />
          </React.Fragment>
        )}
        {selectedCrmType.value === TEKION && (
          <React.Fragment>
            <MyDefaultInputText
              isForm
              label={getText('WORD_DEALER_ID')}
              name={['crmConfigs', 'tekion', 'dealerId']}
              required
              isEnterDisabled
            />
            <MyDefaultTextarea
              isForm
              label={getText('WORD_CLIENT_ID')}
              name={['crmConfigs', 'tekion', 'clientId']}
              required
              autoSize={{ minRows: 2, maxRows: 2 }}
              isEnterDisabled
              isLast
            />
          </React.Fragment>
        )}
        {selectedCrmType.value === PBS && (
          <React.Fragment>
            <MyDefaultInputText
              isForm
              label={getText('WORD_SERIAL_NUMBER')}
              name={['crmConfigs', 'pbs', 'serialNumber']}
              required
              isEnterDisabled
            />
            <MyDefaultSelect
              isForm
              label={getText('WORD_AVAILABLE_STATUSES')}
              name={['crmConfigs', 'pbs', 'availableDealsStatuses']}
              options={[]}
              hidden
              mode='multiple'
              disabled
            />
            <MyDefaultSelect
              isForm
              label={getText('WORD_STATUSES_FOR_INTEGRATES')}
              name={['crmConfigs', 'pbs', 'dealStatusesToIntegrate']}
              options={
                dealStatusesToIntegrateList && dealStatusesToIntegrateList.length
                  ? dealStatusesToIntegrateList.map((item) => {
                      return { value: item, label: item }
                    })
                  : []
              }
              mode='multiple'
            />
          </React.Fragment>
        )}
        {[PBS, DEALER_SOCKET, VIN_SOLUTION].includes(selectedCrmType.value) && (
          <MyDefaultSelect
            isForm
            label={getText('TEXT_CRM_SYCHRONIZATION_FLOW')}
            tooltipText={
              <Div className='crm_select_tooltip_wrapper'>
                <p className='crm_select_tooltip_title'>{`${getText('TEXT_LIVE_SYNC')}:`}</p>
                <p className='crm_select_tooltip_description'>{`${getText('TEXT_LIVE_SYNC_DESCRIPTION')}`}</p>
                <p className='crm_select_tooltip_title'>{`${getText('TEXT_SMART_SYNC')}:`}</p>
                <p className='crm_select_tooltip_description'>{`${getText('TEXT_SMART_SYNC_DESCRIPTION')}`}</p>
                <p className='crm_select_tooltip_title'>{`${getText('TEXT_COMPREHENSIVE_SYNC')}:`}</p>
                <p className='crm_select_tooltip_description'>{`${getText('TEXT_COMPREHENSIVE_SYNC_DESCRIPTION')}`}</p>
              </Div>
            }
            name={['additionalConfigs', 'crmSynchroFlow']}
            options={additionalConfigsOptions}
            isLast
          />
        )}
      </MatForm>
    </MyDefaultModal>
  )
}

export default CrmModal
