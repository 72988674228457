import React from 'react'
import { Flex } from 'antd'
import MyDefaultRequiredStar from '../MyDefaultRequiredStar/MyDefaultRequiredStar'

const MyDefaultBlockSubtitle = ({
  subtitle,
  rightContent,
  fontSize = 14,
  disableMargin,
  color,
  onClick,
  required,
}) => {
  return (
    <Flex
      className={'my_default_block_subtitle'}
      align='center'
      justify='space-between'
      style={{
        fontSize: fontSize,
        marginBottom: disableMargin ? 0 : 12,
        color: color,
        cursor: onClick ? 'pointer' : 'default',
      }}
      onClick={onClick}
    >
      <Flex gap={5}>
        {subtitle}

        {Boolean(required) && <MyDefaultRequiredStar />}
      </Flex>
      {rightContent && rightContent}
    </Flex>
  )
}

export default MyDefaultBlockSubtitle
