import React, { useEffect, useState } from 'react'
import authActions from '../../../store/modules/authActions'
import { getText } from '../../../lang'
import SVGUpdatePasswordModal from '../../../icons/SVG/SVGUpdatePasswordModal'
import MyDefaultPurpleModal from '../MyDefaultPurpleModal/MyDefaultPurpleModal'
import ProfileChangePassword from '../../../views/profile/ProfileChangePassword'
import eventBus from '../../../utils/eventBus'
import { notifySuccess } from '../../../utils/Notify'

const MyDefaultModalUpdatePassword = () => {
  const [actions, setActions] = useState('hidden')
  const [isSubmit, setIsSubmit] = useState(false)
  const [data, setData] = useState({})
  const [isLoading, setIsLoading] = useState({
    isPostponed: false,
    isSubmit: false,
  })

  useEffect(() => {
    eventBus.on('SHOW_UPDATE_PASSWORD_MODAL', (content) => {
      if (Boolean(content)) {
        setActions('default')
        setData(content)
      }
    })

    return () => {
      eventBus.removeAllListeners('SHOW_UPDATE_PASSWORD_MODAL')
    }
  }, [])

  const handleOnCancel = async () => {
    if (actions === 'remindMeLater') {
      setIsLoading({ isPostponed: false })
      const result = await authActions.postponePassword(data?._user_id)
      if (result.success) {
        notifySuccess(
          getText('TEXT_PROFILE_CHANGES_SAVED_SUCCESSFULLY', data?.userLanguage)
        )
        setActions('hidden')
        setData({})
      }
      setIsLoading({ isPostponed: false })
    } else {
      setIsSubmit(false)
      setActions('remindMeLater')
    }
  }

  const handleOnUpdtate = () => {
    if (actions === 'remindMeLater') {
      setActions('default')
    } else if (actions === 'default') {
      setActions('updatePassword')
      setTimeout(() => {
        setIsSubmit(true)
      }, 1000)
    } else if (actions === 'updatePassword') {
      setIsLoading({ isSubmit: true })
    }
  }

  return (
    <MyDefaultPurpleModal
      title={getText('TEXT_TIME_TO_UPDATE_YOUR_PASSWORD')}
      modalIcon={<SVGUpdatePasswordModal />}
      visible={actions !== 'hidden'}
      confirmLoading={isLoading.isSubmit}
      cancelLoading={isLoading.isPostponed}
      okText={getText(
        actions === 'remindMeLater' ? 'ACTION_BACK' : 'ACTION_UPDATE',
        data?.userLanguage
      )}
      cancelText={getText(
        actions === 'remindMeLater'
          ? 'TEXT_SKIP_FOR_NOW'
          : actions === 'updatePassword'
            ? 'ACTION_BACK'
            : 'TEXT_REMIND_ME_LATER',
        data?.userLanguage
      )}
      onCancelBtn={handleOnCancel}
      submitHtmlType={isSubmit ? 'submit' : 'button'}
      formName={'popupFormSubmit'}
      onOk={handleOnUpdtate}
      hideCancel={data?.isUpdateRequired}
    >
      {actions === 'updatePassword' ? (
        <ProfileChangePassword
          popupUpdate
          onSavePopup={(isLoadingFalse) => {
            if (isLoadingFalse) {
              setIsLoading({ isSubmit: false })
            } else {
              setIsLoading({ isSubmit: false })
              setData({})
              setActions('hidden')
            }
          }}
        />
      ) : (
        <p>
          {' '}
          {getText(
            actions === 'remindMeLater'
              ? 'TEXT_THIS_ROUTINE_SECURITY_UPDATE_YOU_CAN_SKIP_NOW_ONE_WEEK_UPDATING_WILL_BE_REQUIRED'
              : 'TEXT_NOTHING_WORRY_ABOUT_STANDARD_SECURITY_PRACTICE_CHANGE_YOUR_PASSWORD_PLEASE_LOGOUT_FIRST',
            data?.userLanguage
          )}{' '}
        </p>
      )}
    </MyDefaultPurpleModal>
  )
}

export default MyDefaultModalUpdatePassword
