import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form } from 'antd'
import { useSelector } from 'react-redux'
import { getText, getTextServerError } from '../../../../../lang'
import {
  ACTIVIX,
  DEALER_PEAK,
  DEALER_SOCKET,
  ELEADS,
  MOMENTUM,
  PBS,
  TEKION,
  VIN_SOLUTION,
} from '../../../../../config/constants'
import locationActions from '../../../../../store/modules/locationActions'
import authActions from '../../../../../store/modules/authActions'
import { notifyError, notifySuccess } from '../../../../../utils/Notify'
import { dealerSocketValidator } from '../../../../../utils'
import { userSelector } from '../../../../../store/selectors/selectors'
import utilObject from '../../../../../utils/utilObject'
import MyDefaultIntegrationBlockFooter from '../../../../../components/MyDefaultIntegrationBlock/MyDefaultIntegrationBlockFooter/MyDefaultIntegrationBlockFooter'
import CrmModal from './CrmModal'

const PER_PAGE = 30

const CrmTypeSelector = (props) => {
  const { location, isNewLocation, setLocationObj } = props

  const user = useSelector(userSelector)

  const noneCRM = { value: 'none', label: getText('WORD_NONE') }

  const selectTypeOptions = [
    noneCRM,
    { value: VIN_SOLUTION, label: 'VinSolutions' },
    { value: ELEADS, label: 'Elead' },
    { value: DEALER_SOCKET, label: 'DealerSocket' },
    { value: DEALER_PEAK, label: 'DealerPeak' },
    // { value: ONE_EIGHTY, name: 'One Eighty' }, // We are disabling 1-80 for now. Maybe will use in future
    { value: ACTIVIX, label: 'Activix' },
    { value: PBS, label: 'PBS' },
    { value: TEKION, label: 'Tekion' },
    { value: MOMENTUM, label: 'Momentum' },
  ]

  const [form] = Form.useForm()
  const navigate = useNavigate()

  const [visible, setVisible] = useState(false)
  const [selectedCrmType, setSelectedCrmType] = useState({})
  const [selectedCrmTypeAfterSaving, setSelectedCrmTypeAfterSaving] = useState({})
  const [crmOptions, setCrmOptions] = useState({})
  const [whoConnected, setWhoConnected] = useState({})
  const [vinUsers, setVinUsers] = useState({
    users: [],
    maxUsers: 0,
  })
  const [loading, setLoading] = useState(false)

  const isFirstRendRef = useRef(true)

  useEffect(() => {
    const currentCrm = selectTypeOptions.find(
      (type) => type.value === location.crmType
    )

    setSelectedCrmType(currentCrm || noneCRM)
    setSelectedCrmTypeAfterSaving(currentCrm || noneCRM)

    setWhoConnected({
      connectedBy: location.crmConfigs && location.crmConfigs.connectedBy,
      connectDate: location.crmConfigs && location.crmConfigs.connectDate,
    })

    setCrmOptions({
      crmConfigs: {
        vinSolution: location.crmConfigs && location.crmConfigs.vinSolution,
        momentum: location.crmConfigs && location.crmConfigs.momentum,
        eLeads: location.crmConfigs && location.crmConfigs.eLeads,
        dealerSocket: location.crmConfigs && location.crmConfigs.dealerSocket,
        dealerPeak: location.crmConfigs && location.crmConfigs.dealerPeak,
        oneEighty: location.crmConfigs && location.crmConfigs.oneEighty,
        activix: location.crmConfigs && location.crmConfigs.activix,
        pbs: location.crmConfigs && location.crmConfigs.pbs,
        tekion: location.crmConfigs && location.crmConfigs.tekion,
        connectedBy: location.crmConfigs && location.crmConfigs.connectedBy,
        connectDate: location.crmConfigs && location.crmConfigs.connectDate,
      },
    })
  }, [])

  useEffect(() => {
    if (selectedCrmType.value === VIN_SOLUTION) {
      if (
        crmOptions.crmConfigs &&
        crmOptions.crmConfigs.vinSolution &&
        crmOptions.crmConfigs.vinSolution.dealerId
      ) {
        getVinUsers(crmOptions.crmConfigs.vinSolution.dealerId)
      }
    }
  }, [selectedCrmType])

  useEffect(() => {
    if (!utilObject.isEmpty(crmOptions)) {
      if (selectedCrmType.value === MOMENTUM) {
        if (
          crmOptions.crmConfigs &&
          crmOptions.crmConfigs.momentum &&
          crmOptions.crmConfigs.momentum.clientId &&
          isFirstRendRef?.current
        ) {
          getMomentumDealer(crmOptions.crmConfigs.momentum.clientId)
        }
      }
      isFirstRendRef.current = false
    }
  }, [selectedCrmType, crmOptions])

  const getVinUsers = async (dealerId) => {
    const result = await locationActions.getVinSolutionUsers(location.id, {
      dealerId,
      page: 1,
      perPage: PER_PAGE,
    })
    if (result.success) {
      crmOptions.crmConfigs.vinSolution.dealerId = dealerId
      setCrmOptions(crmOptions)
      setVinUsers({
        users: result.data.vinsUsers || [],
        maxUsers: result.data.total_items,
      })
    }
  }

  const getMomentumDealer = async (clientId) => {
    const result = await locationActions.getMomentumDealer(clientId)
    if (result.success) {
      const matadorUserId = crmOptions?.crmConfigs?.momentum?.matadorUserId || ''
      crmOptions.crmConfigs.momentum = {
        clientId: clientId,
        dealerUUID: result.data.dealerUUID,
        dealerName: result.data.dealerName,
        employees: result.data.employees?.map((employee) => ({
          label: `${employee?.firstName || ''} ${employee?.lastName || ''}`,
          value: employee?.employeeApiID,
        })),
      }

      if (matadorUserId) {
        const find = result.data?.employees?.find((employee) => {
          return employee.employeeApiID === matadorUserId
        })

        if (find) {
          crmOptions.crmConfigs.momentum.matadorUserId = find.employeeApiID
        }
      }
      form.setFieldValue(['crmConfigs', 'momentum'], crmOptions.crmConfigs.momentum)
      setCrmOptions({ ...crmOptions })
    }
  }

  const handleScrollUsers = async () => {
    const dealerId = crmOptions.crmConfigs.vinSolution.dealerId
    const page = Math.floor(vinUsers.users.length / PER_PAGE + 1)
    if (vinUsers.maxUsers <= vinUsers.users.length) {
      return
    }
    const result = await locationActions.getVinSolutionUsers(location.id, {
      dealerId,
      page,
      perPage: PER_PAGE,
    })
    if (result.success) {
      setVinUsers({
        ...vinUsers,
        users: [...vinUsers.users, ...result.data.vinsUsers],
      })
    }
  }

  const handeSuccessLocationUpdateResponse = (updatedLocation) => {
    notifySuccess(
      isNewLocation
        ? getText('TEXT_LOCATION_WAS_CREATED_SUCCESSFULLY')
        : getText('TEXT_LOCATION_WAS_UPDATED_SUCCESSFULLY')
    )
    // TODO need testing here
    let loc = authActions.getLocation()

    if (location.facebookConnection && location.facebookConnection._id) {
      updatedLocation.facebookConnection = location.facebookConnection
    }

    if (location.featuresConnectingData) {
      updatedLocation.featuresConnectingData = location.featuresConnectingData
    }

    if (updatedLocation._id === (loc && loc._id) || !loc || loc._id === ' ') {
      authActions.setLocation(updatedLocation)
    }

    const crmTypeObject = selectTypeOptions.find(
      (e) => e.value === updatedLocation.crmType
    )

    // TODO do we need both seters?
    setSelectedCrmType(crmTypeObject || noneCRM)
    setSelectedCrmTypeAfterSaving(crmTypeObject || noneCRM)

    if (updatedLocation.crmType === 'none') {
      setWhoConnected({})
    } else {
      setWhoConnected({
        connectedBy:
          updatedLocation.crmConfigs && updatedLocation.crmConfigs.connectedBy,
        connectDate:
          updatedLocation.crmConfigs && updatedLocation.crmConfigs.connectDate,
      })
    }

    const newConfigs = { ...updatedLocation.crmConfigs }
    if (
      (selectedCrmType === MOMENTUM || selectedCrmType?.value === MOMENTUM) &&
      crmOptions?.crmConfigs?.momentum?.employees
    ) {
      newConfigs.momentum.employees = crmOptions.crmConfigs.momentum.employees
      setCrmOptions({ crmConfigs: newConfigs })
    }

    setLocationObj(updatedLocation)
    setVisible(false)
    if (isNewLocation) {
      navigate(`/location/settings/${updatedLocation._id}/edit`, {
        state: { locationObj: updatedLocation },
      })
    }
  }

  const onFinish = async (values) => {
    setLoading(true)
    const allValues = form.getFieldsValue(true)

    const obj = {
      crmType: allValues.crmType,
      additionalConfigs: {
        crmSynchroFlow: allValues.additionalConfigs.crmSynchroFlow,
      },
      crmConfigs: {},
    }
    const selectedCRM = allValues.crmType
    if (selectedCRM !== 'none') {
      obj.crmConfigs[selectedCRM] = allValues.crmConfigs[selectedCRM]

      if (selectedCRM === MOMENTUM && obj.crmConfigs.momentum?.employees) {
        delete obj.crmConfigs.momentum.employees
      }
    }

    if (selectedCRM === VIN_SOLUTION) {
      delete allValues.crmConfigs.vinSolution.leadSourceId
    }

    let result = {}

    if (!isNewLocation) {
      result = await locationActions.saveCRMIntegrationConfigs(obj, location?._id)
    }

    if (result.success) {
      handeSuccessLocationUpdateResponse(result.data)
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
    setLoading(false)
  }

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields.length > 0) {
      notifyError(errorInfo.errorFields[0].errors[0])
    }
  }

  const dealStatusesToIntegrateList = Form.useWatch(
    ['crmConfigs', 'pbs', 'availableDealsStatuses'],
    form
  )

  return (
    <React.Fragment>
      <MyDefaultIntegrationBlockFooter
        enabled={selectedCrmTypeAfterSaving.value !== 'none'}
        connectedBy={whoConnected.connectedBy}
        account={
          selectedCrmTypeAfterSaving.value !== 'none'
            ? selectedCrmTypeAfterSaving.label
            : ''
        }
        accountType={getText('WORD_CONNECTED_CRM')}
        customButton={
          <CrmModal
            visible={visible}
            setVisible={setVisible}
            loading={loading}
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            location={location}
            hideSubmit={!user.isSuperAdmin}
            selectedCrmType={selectedCrmType}
            setSelectedCrmType={setSelectedCrmType}
            selectTypeOptions={selectTypeOptions}
            setVinUsers={setVinUsers}
            getVinUsers={getVinUsers}
            getMomentumDealer={getMomentumDealer}
            crmOptions={crmOptions}
            setCrmOptions={setCrmOptions}
            vinUsers={vinUsers}
            handleScrollUsers={handleScrollUsers}
            dealerSocketValidator={dealerSocketValidator}
            dealStatusesToIntegrateList={dealStatusesToIntegrateList}
          />
        }
      />
    </React.Fragment>
  )
}

export default CrmTypeSelector
