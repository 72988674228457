import axios from 'axios'
import { createBrowserHistory } from 'history'
import _ from 'lodash'
import { getText } from '../lang'
import { store } from '../store'
import authActions from '../store/modules/authActions'
import eventBus from './eventBus'
import refreshToken from './refreshToken'
import { getIPWhiteListError, moveToLoginPage } from '.'

let ipErrorNotified = false

let prefix = 'http'
let socketPrefix = 'ws'
if (['PROD', 'STAGE', 'RELEASE'].includes(process.env.REACT_APP_ENV)) {
  prefix = 'https'
  socketPrefix = 'wss'
}
let BASE_URL = `${prefix}://${process.env.REACT_APP_DOMAIN}/api/v1`
let BASE_URL_SCHEDULE = `${prefix}://${process.env.REACT_APP_LAMBDA_DOMAIN}/api/v2`
let SOCKET_URL = `${socketPrefix}://${process.env.REACT_APP_SOCKET_DOMAIN}`

export const axiosInstance = axios.create()
axiosInstance.defaults.timeout = 30000

axiosInstance.interceptors.response.use(
  refreshToken.onFulfilled,
  refreshToken.onRejected
)

export const siteUrl = window.location.origin

export const history = createBrowserHistory({ forceRefresh: true })

export function redirect(path, state) {
  const isMobileApp = authActions.getIsMobileApp()
  if (isMobileApp) {
    history.push(path, history.search, state)
    history.go()
  } else {
    window.history.pushState(state, null, path)
    window.dispatchEvent(new Event('popstate'))
  }
}

export function redirectPSS(path, search, state) {
  let oo = {
    pathname: path,
  }
  if (search) {
    oo.search = search
  }
  if (state) {
    oo.state = state
  }
  history.push(oo)
}

export function urlServer(path = '') {
  return BASE_URL + path
}

export function urlServerV2(path = '') {
  return BASE_URL_SCHEDULE + path
}

export function urlSocket(path = '') {
  return SOCKET_URL + path
}

export function urlImgServer(path) {
  return 'https://wingage.io/' + path
}

export async function fetchGETAsync(url, parameters = []) {
  return await fetchWithoutToken('GET', url, parameters)
}

export async function fetchV2FromUrlPOSTAsync(url, parameters) {
  return await fetchASV2('POST', url, parameters)
}

export async function fetchFromUrlPOSTAsync(
  url,
  parameters,
  increaseTimeOut = false
) {
  return fetchAS('POST', url, parameters, increaseTimeOut)
}

export async function fetchV2FromUrlGETAsync(url, parameters = []) {
  return await fetchASV2('GET', url, parameters)
}

export async function fetchFromUrlGETAsync(
  url,
  parameters = [],
  increaseTimeOut = false
) {
  return await fetchAS('GET', url, parameters, increaseTimeOut)
}

export async function fetchFromUrlPUTAsync(url, parameters) {
  return await fetchAS('PUT', url, parameters)
}

export async function fetchFromUrlPATCHAsync(
  url,
  parameters,
  increaseTimeOut = false
) {
  return await fetchAS('PATCH', url, parameters, increaseTimeOut)
}

export async function fetchV2FromUrlPATCHAsync(url, parameters) {
  return await fetchASV2('PATCH', url, parameters)
}

export async function fetchFromUrlDELETEAsync(url, parameters) {
  return await fetchAS('DELETE', url, parameters)
}

export async function fetchV2FromUrlDELETEAsync(url, parameters) {
  return await fetchASV2('DELETE', url, parameters)
}

export async function fetchAS(
  method,
  url,
  parameters = [],
  increaseTimeOut = false
) {
  const timeout = increaseTimeOut || 30000
  let fetchUrl = urlServer(url)
  let toSend = {}
  parameters.forEach((par) => {
    toSend[par.key] = par.value
  })

  const storeToken = store.getState().authReducer.accessToken
  let body = undefined
  let params = undefined

  if (
    method === 'POST' ||
    method === 'PUT' ||
    method === 'PATCH' ||
    method === 'DELETE'
  ) {
    body = JSON.stringify(toSend)
  } else if (method === 'GET') {
    params = toSend
  }

  const config = {
    url: fetchUrl,
    data: body,
    params,
    method,
    timeout: timeout,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + storeToken.accessToken,
      'X-Socket-Id': sessionStorage.getItem('socketId') || '',
    },
  }

  try {
    const response = await axiosInstance.request(config)
    const obj = {
      success: response && response.status >= 200 && response.status < 300,
    }
    const data = response && response.data

    if (obj.success) {
      obj.data = data
    } else {
      obj.errCode = data && data.code
      obj.errMsg = data && data.message
      if (data && data.errors) {
        obj.errors = data.errors
      }
    }

    if (obj.errCode === 401) {
      moveToLoginPage(redirect, true)
    }
    return obj
  } catch (err) {
    if (_.get(err.response, 'data.code') === 401) {
      moveToLoginPage(redirect, true)
    }
    if (!window.location.href.includes('/login')) {
      getIPWhiteListError(err.message, ipErrorNotified)
    }
    if (err._user_id) {
      eventBus.emit('SHOW_MODAL', err)
      return {
        success: false,
        errMsg: 'termsOfService',
      }
    }
    return {
      success: false,
      errMsg: _.get(
        err.response,
        'data.message',
        err.message,
        getText('ERR_SERVER_NOT_CONNECTED')
      ),
      errCode: err.code || null,
    }
  }
}

export async function fetchWithoutToken(method, url, parameters = []) {
  const urlServer = (URLL) => {
    return URLL
  }

  let fetchUrl = urlServer(url)

  let toSend = {}
  parameters.forEach((par) => {
    toSend[par.key] = par.value
  })

  // const storeToken = store.getState().authReducer.accessToken

  try {
    let body = undefined
    let params = undefined

    if (
      method === 'POST' ||
      method === 'PUT' ||
      method === 'PATCH' ||
      method === 'DELETE'
    ) {
      body = JSON.stringify(toSend)
    } else if (method === 'GET') {
      params = toSend
    }

    const config = {
      url: fetchUrl,
      data: body,
      params,
      method,
      timeout: 30000,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Socket-Id': sessionStorage.getItem('socketId') || '',
      },
    }

    const response = await axiosInstance.request(config)

    const obj = {
      success: response.status >= 200 && response.status < 300,
    }

    let resJson
    try {
      resJson = response.data

      if (obj.success) {
        obj.data = resJson.DATA
        // obj = resJson;
      } else {
        obj.errCode = resJson.code
        obj.errMsg = resJson.message
        if (resJson.errors) {
          obj.errors = resJson.errors
        }
      }
    } catch (err) {}

    if (obj.errCode === 401) {
      moveToLoginPage(redirect, true)
    }

    return obj
  } catch (err) {
    console.error(err)
    if (_.get(err.response, 'data.code') === 401) {
      moveToLoginPage(redirect, true)
    }
    return {
      success: false,
      errMsg: _.get(
        err.response,
        'data.message',
        getText('ERR_SERVER_NOT_CONNECTED')
      ),
    }
  }
}

export async function fetchASV2(method, url, parameters = []) {
  let fetchUrl = urlServerV2(url)
  let toSend = {}
  parameters.forEach((par) => {
    toSend[par.key] = par.value
  })

  const storeToken = store.getState().authReducer.accessToken

  try {
    let body = undefined
    let params = undefined

    if (
      method === 'POST' ||
      method === 'PUT' ||
      method === 'PATCH' ||
      method === 'DELETE'
    ) {
      body = JSON.stringify(toSend)
    } else if (method === 'GET') {
      params = toSend
    }

    const config = {
      url: fetchUrl,
      data: body,
      params,
      method,
      timeout: 30000,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + storeToken.accessToken,
      },
    }

    const response = await axiosInstance.request(config)

    const obj = {
      success: response.status >= 200 && response.status < 300,
    }

    let resJson
    try {
      resJson = response.data

      if (obj.success) {
        obj.data = resJson
      } else {
        obj.errCode = resJson.code
        obj.errMsg = resJson.message
        if (resJson.errors) {
          obj.errors = resJson.errors
        }
      }
    } catch (err) {}

    if (obj.errCode === 401) {
      moveToLoginPage(redirect, true)
    }

    return obj
  } catch (err) {
    if (_.get(err.response, 'data.code') === 401) {
      moveToLoginPage(redirect, true)
    }
    return {
      success: false,
      errMsg: _.get(
        'data.message',
        err.response,
        err.message,
        getText('ERR_SERVER_NOT_CONNECTED')
      ),
    }
  }
}

export async function uploadAndFetchPOStFile(url, obj, withoutAPI) {
  const storeToken = store.getState().authReducer.accessToken
  const config = {
    headers: {
      Authorization: `Bearer ${storeToken.accessToken}`,
      'Content-Type': 'multipart/form-data',
    },
  }

  const data = new FormData()
  Object.keys(obj).forEach((key) => {
    if (key === 'image' || key === 'file' || typeof obj[key] !== 'object') {
      data.append(key, obj[key])
    } else {
      // prevent FormData.append from converting object to string
      data.append(key, JSON.stringify(obj[key]))
    }
  })
  try {
    let fetchUrl = withoutAPI ? url : urlServer(url)
    let res = await axios.post(fetchUrl, data, config)
    return { success: res.status >= 200 && res.status < 300, data: res.data }
  } catch (err) {
    console.error(err)
    if (_.get(err.response, 'data.code') === 401) {
      moveToLoginPage(redirect, true)
    }
    return {
      success: false,
      errMsg: _.get(
        err.response,
        'data.message',
        getText('ERR_SERVER_NOT_CONNECTED')
      ),
    }
  }
}

export async function uploadAndFetchGETFile(url, obj, withoutAPI) {
  const storeToken = store.getState().authReducer.accessToken
  const config = {
    headers: {
      Authorization: `Bearer ${storeToken.accessToken}`,
      'Content-Type': 'multipart/form-data',
    },
    params: obj,
  }

  try {
    let fetchUrl = withoutAPI ? url : urlServer(url)
    let res = await axios.get(fetchUrl, config)
    return { success: res.status >= 200 && res.status < 300, data: res.data }
  } catch (err) {
    console.error(err)
    if (_.get(err.response, 'data.code') === 401) {
      moveToLoginPage(redirect, true)
    }
    return {
      success: false,
      errMsg: _.get(
        err.response,
        'data.message',
        getText('ERR_SERVER_NOT_CONNECTED')
      ),
    }
  }
}
