import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = () => (
  <svg
    width='36'
    height='36'
    viewBox='0 0 36 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect width='36' height='36' rx='8' fill='#5B66EA' fillOpacity='0.05' />
    <rect
      x='0.5'
      y='0.5'
      width='35'
      height='35'
      rx='7.5'
      stroke='#5B66EA'
      strokeOpacity='0.2'
    />
    <path
      d='M16 24.6668H20C23.3333 24.6668 24.6666 23.3335 24.6666 20.0002V16.0002C24.6666 12.6668 23.3333 11.3335 20 11.3335H16C12.6666 11.3335 11.3333 12.6668 11.3333 16.0002V20.0002C11.3333 23.3335 12.6666 24.6668 16 24.6668Z'
      stroke='#5B66EA'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M20.5 16H15.5'
      stroke='#5B66EA'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M20.5 20H15.5'
      stroke='#5B66EA'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

const SVGTermsAndConditionsIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGTermsAndConditionsIcon
