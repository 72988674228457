import React from 'react'
import { Flex } from 'antd'
import { LocationProvider } from '../locationProvider/LocationProvider'
import useDeviceCheck from '../../../utils/useDeviceCheck'
import LocationMenu from './LocationMenu'

const LocationNavigation = ({ children }) => {
  const { isTabletPortraitOrMobile } = useDeviceCheck()
  return (
    <Flex vertical={isTabletPortraitOrMobile} style={{ height: '100%' }}>
      <LocationMenu />
      <LocationProvider children={children} />
    </Flex>
  )
}

export default LocationNavigation
