import React, { useEffect, useState } from 'react'
import { Flex } from 'antd'
import CopyToClipboard from 'react-copy-to-clipboard'
import { getText } from '../../lang'
import SVGCopyIcon from '../../icons/SVG/SVGCopyIcon'
import Div from '../Div/Div'
import MyDefaultRow from '../MyDefaultRow/MyDefaultRow'
import MatBlockWithLabel from '../MatBlockWithLabel'
import InputTextarea from '../InputTextarea'
import { notifySuccess } from '../../utils/Notify'
import utilDate from '../../utils/utilsDate'
import './AIChatDetails.scss'
import LoadingSpinner from '../LoadingSpinner'
import { CONNECT_URL } from '../../config'

const currentUrl = window.location.href + `#matador-dashboard-preview`

const AIChatDetails = ({ item }) => {
  const [dataSent, setDataSent] = useState(false)

  useEffect(() => {
    const handleMessage = (event) => {
      if (event?.data === 'matador_connect_is_loaded') {
        sendDataToIframe()

        setTimeout(() => {
          sendDataToIframe()
        }, 2000)
      }
    }

    window.addEventListener('message', handleMessage)
    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [])

  const sendDataToIframe = () => {
    setTimeout(() => {
      setDataSent(true)
    }, 1000)
    const iframe = document.getElementById('matador-ai-chat-iframe')
    if (!iframe) return
    iframe.contentWindow.postMessage(
      {
        type: 'MATADOR_AI_CHATLOAD_PREFILLED_DATA',
        payload: item,
      },
      '*'
    )
  }

  const getTags = () => {
    const translationMap = {
      Sales: 'WORD_SALES',
      Sold: 'WORD_SOLD',
      Service: 'WORD_SERVICE',
      Servicing: 'WORD_SERVICING',
      'New Finance': 'NEW_FINANCE',
      'Existing Finance': 'EXISTING_FINANCE',
      Parts: 'WORD_PARTS',
      'Bad Credit': 'WORD_FINANCE.BAD_CREDIT',
    }

    return (
      item?.tags?.map((tag) => getText(translationMap[tag] || tag))?.join(', ') ||
      'N/A'
    )
  }

  const getOutcome = () => {
    const translationMap = {
      'Appointment Set': 'APPOINTMENT_SET',
      'Converted Shopper': 'CONVERTED_SHOPPER',
      'Completed Conversation': 'COMPLETED_CONVERSATION',
      'Appointment Booked': 'APPOINTMENT_BOOKED',
    }

    return getText(translationMap[item?.outcome] || item?.outcome) || 'N/A'
  }

  return (
    <Flex className='ai_chat_list_item'>
      <Div className='ai_chat_list_item--left'>
        <MatBlockWithLabel black label={getText('TRANSCRIPT')}>
          <iframe
            id='matador-ai-chat-iframe'
            title='ChatIframe'
            src={`${CONNECT_URL}/chat_widget/theme=default/${item._token}/en?url=${currentUrl}?language=en`}
            frameBorder='0'
            width='100%'
            height='100%'
            style={{ height: dataSent ? '95%' : '0%' }}
            allow='geolocation'
          />
          {!dataSent && (
            <>
              {' '}
              <LoadingSpinner />{' '}
              <div style={{ height: dataSent ? '0%' : '100%' }}></div>
            </>
          )}
        </MatBlockWithLabel>
      </Div>
      <Div className='ai_chat_list_item--right'>
        <Div className={'ai_chat_list_item--right-list-info'}>
          <MyDefaultRow
            leftSide={getText('WORD_DATE')}
            rightSide={utilDate.getLocalizedFullDate(item?.createdAt, 'MMM D, YYYY')}
          />
          <MyDefaultRow
            leftSide={getText('WORD_TIME')}
            rightSide={utilDate.getLocalizedFullDate(item?.createdAt, 'h:mm a')}
          />
          <MyDefaultRow leftSide={getText('WORD_TYPE')} rightSide={getTags()} />
          <MyDefaultRow
            leftSide={getText('WORD_OUTCOME')}
            rightSide={getOutcome()}
          />
        </Div>
        <MatBlockWithLabel
          black
          label={getText('WORD_SUMMARY')}
          className={'ai_chat_list_item--right-copy-part'}
        >
          <InputTextarea value={item?.summary || ''} autoSize={false} disabled />
          {Boolean(item?.summary) && (
            <CopyToClipboard
              text={item.summary}
              onCopy={() => notifySuccess(getText('WORD_COPIED'))}
            >
              <SVGCopyIcon className={'copy_icon'} />
            </CopyToClipboard>
          )}
        </MatBlockWithLabel>
      </Div>
    </Flex>
  )
}

export default AIChatDetails
