import React, { useEffect, useRef, useState } from 'react'
import { Flex } from 'antd'
import dayjs from 'dayjs'
import { useNavigate, useParams } from 'react-router-dom'
import greetingsActions from '../../../../../store/modules/greetingsActions'
import { getText, getTextServerError } from '../../../../../lang'
import { BUTTON, TABLE_DEFAULT_ROW_NUMBER } from '../../../../../devOptions'
import { useLayout } from '../../../../../layout/LayoutProvider/LayoutProvider'
import { notifyError, notifySuccess } from '../../../../../utils/Notify'
import MyDefaultPageLayout from '../../../../../components/MyDefaultPageLayout/MyDefaultPageLayout'
import MyDefaultTableHeader from '../../../../../components/MyDefaultTable/MyDefaultTableHeader/MyDefaultTableHeader'
import MyDefaultTableTitle from '../../../../../components/MyDefaultTable/MyDefaultTableTitle/MyDefaultTableTitle'
import MyDefaultTableText from '../../../../../components/MyDefaultTable/MyDefaultTableText/MyDefaultTableText'
import authActions from '../../../../../store/modules/authActions'
import MyDefaultTableSwitch from '../../../../../components/MyDefaultTable/MyDefaultTableSwitch/MyDefaultTableSwitch'
import SVGMyDefaultEditIcon from '../../../../../icons/SVG/SVGMyDefaultEditIcon'
import ConfirmPopup from '../../../../../components/ConfirmPopup'
import SVGMyDefaultDeleteIcon from '../../../../../icons/SVG/SVGMyDefaultDeleteIcon'
import MyDefaultTableList from '../../../../../components/MyDefaultTable/MyDefaultTableList/MyDefaultTableList'

const Greetings = () => {
  const { _id } = useParams()
  const navigate = useNavigate()
  const { setPageTitle } = useLayout()

  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [data, setData] = useState({
    list: [],
    total: 0,
  })

  const layoutRef = useRef(null)

  useEffect(() => {
    setPageTitle(getText('WORD_GREETINGS'))
  }, [])

  const getGreetings = async () => {
    setLoading(true)
    const options = { _location_id: _id }
    const result = await greetingsActions.getPageList(
      page,
      TABLE_DEFAULT_ROW_NUMBER,
      '',
      options
    )
    if (result.success) {
      const newData = page === 0 ? result.data : [...data.list, ...result.data]
      setData({
        list: newData,
        total: result.max,
      })
    }
    setLoading(false)
  }

  useEffect(() => {
    getGreetings()
  }, [page])

  const handleGreetingsSwitch = async (checked, item) => {
    const param = {
      enabled: checked,
    }
    const result = await greetingsActions.updatePartial(param, item._id)
    if (result.success) {
      notifySuccess(getText('SUCCESS_GREETING_UPDATE'))
      setData((ov) => {
        const newData = ov.list.map((it) => {
          if (it._id === item._id) {
            it.enabled = checked
          }
          return it
        })
        return {
          list: [...newData],
          total: ov.total,
        }
      })
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
  }

  const handleSetDefault = async (item) => {
    const param = {
      isDefault: true,
    }
    const result = await greetingsActions.setAsDefault(param, item._id)
    if (result.success) {
      notifySuccess(getText('SUCCESS_GREETING_SET_DEFAULT'))
      setData((ov) => {
        const newData = ov.list.map((it) => {
          if (it._id === item._id) {
            it.isDefault = true
          } else {
            it.isDefault = false
          }
          return it
        })
        return {
          list: [...newData],
          total: ov.total,
        }
      })
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
  }

  return (
    <MyDefaultPageLayout
      ref={layoutRef}
      isAdvanced
      headerProps={{
        right: [
          {
            type: BUTTON,
            title: getText('ACTION_ADD_GREETING'),
            onClick: () =>
              navigate(`/location/settings/${_id}/livechat-greetings/add`),
          },
        ],
      }}
    >
      <MyDefaultTableHeader
        title={getText('WORD_TOTAL_GREETINGS')}
        disableRight
        totalCount={data?.total}
      />
      <MyDefaultTableList
        pageStart={page}
        loading={loading}
        data={data.list || []}
        getScrollParent={() => layoutRef?.current}
        onLoadMore={() => {
          if (!loading && data?.list?.length < data?.total) {
            setLoading(true)
            setPage((ov) => ov + 1)
          }
        }}
        totalDataCount={data?.total || 0}
        columns={[
          {
            title: getText('LABEL_GREETING_NAME'),
            dataIndex: 'name',
            render: (_, { name }) => {
              return <MyDefaultTableTitle title={name || ''} />
            },
          },
          {
            title: getText('WORD_UPDATED_AT'),
            dataIndex: 'updatedAt',
            render: (_, { updatedAt }) => {
              return (
                <MyDefaultTableText
                  text={dayjs(updatedAt)
                    .locale(authActions.getLanguage())
                    .format('D MMM, YYYY hh:mm a')}
                />
              )
            },
          },
          {
            title: getText('WORD_STATUS'),
            dataIndex: 'status',
            render: (_, item) => {
              return (
                <MyDefaultTableSwitch
                  value={item.enabled}
                  text={getText(item.enabled ? 'WORD_ACTIVE' : 'WORD_INACTIVE')}
                  onChange={(value) => handleGreetingsSwitch(value, item)}
                />
              )
            },
          },
          {
            title: '',
            dataIndex: 'actions',
            width: 200,
            render: (_, item) => {
              return (
                <Flex align='center' gap={20} justify='end'>
                  {item.isDefault ? (
                    <MyDefaultTableText
                      text={getText('WORD_DEFAULT')}
                      color={'var(--green)'}
                    />
                  ) : (
                    <ConfirmPopup
                      placement={'topRight'}
                      title={getText('CONFIRMATION_SET_DEFAULT_GREETING')}
                      cancelText={getText('ACTION_CANCEL')}
                      okText={getText('TEXT_SET_AS_DEFAULT')}
                      onConfirm={() => {
                        handleSetDefault(item)
                      }}
                      trigger={
                        <MyDefaultTableText
                          text={getText('TEXT_SET_AS_DEFAULT')}
                          color={item.enabled ? 'var(--mainColor)' : 'var(--black)'}
                          cursor={'pointer'}
                        />
                      }
                    />
                  )}
                  <SVGMyDefaultEditIcon
                    onClick={() =>
                      navigate(`/location/settings/${_id}/livechat-greetings/edit`, {
                        state: { greetingData: item },
                      })
                    }
                  />
                  <ConfirmPopup
                    title={getText(
                      'TEXT_ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_SEQUENCE'
                    )}
                    placement={'topRight'}
                    onConfirm={async () => {
                      const result = await greetingsActions.delete(item._id)
                      if (result.success) {
                        notifySuccess(getText('SUCCESS_GREETING_DELETE'))
                        setData((ov) => {
                          const newData = ov.list.filter(
                            (temp) => item._id !== temp._id
                          )
                          return {
                            total: ov.total,
                            list: [...newData],
                          }
                        })
                      } else {
                        notifyError(getTextServerError(result.errMsg))
                      }
                    }}
                    okText={getText('WORD_YES')}
                    cancelText={getText('WORD_NO')}
                    trigger={<SVGMyDefaultDeleteIcon />}
                  />
                </Flex>
              )
            },
          },
        ]}
      />
    </MyDefaultPageLayout>
  )
}

export default Greetings
