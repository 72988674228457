import React from 'react'
import './MyDefaultRow.scss'
import MyFlex from '../MyFlex/MyFlex'

const MyDefaultRow = ({
  leftSide,
  rightSide,
  gap,
  fitContent,
  width,
  className,
  justify,
  direction,
}) => {
  return (
    <MyFlex
      align='center'
      gap={gap}
      style={{ width: fitContent ? 'fit-content' : width }}
      justify={justify || 'space-between'}
      className={`my_default_row ${className || ''}`}
      direction={direction}
    >
      <span>{leftSide}</span>
      <span>{rightSide}</span>
    </MyFlex>
  )
}

export default MyDefaultRow
