import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = () => (
  <svg
    id='Group_1809'
    dataName='Group 1809'
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 20.855 20.855'
  >
    <circle
      id='Ellipse_93'
      dataName='Ellipse 93'
      cx='10.428'
      cy='10.428'
      r='10.428'
      fill='#3b5998'
    />
    <path
      id='Path_932'
      dataName='Path 932'
      d='M43.833,28.554H41.972V35.37H39.153V28.554H37.812v-2.4h1.341v-1.55A2.644,2.644,0,0,1,42,21.763l2.088.009V24.1H42.57a.574.574,0,0,0-.6.653v1.41h2.107Z'
      transform='translate(-30.783 -17.718)'
      fill='#f4f7fc'
    />
  </svg>
)

const SVGLocationFacebookIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGLocationFacebookIcon
