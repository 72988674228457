import { useEffect, useRef } from 'react'

const useAutoResizeText = (maxSize = 24, minSize = 12) => {
  const ref = useRef(null)

  useEffect(() => {
    const resizeText = () => {
      if (!ref.current) return

      let fontSize = maxSize
      ref.current.style.fontSize = `${fontSize}px`

      while (
        (ref.current.scrollWidth > ref.current.clientWidth ||
          ref.current.scrollHeight > ref.current.clientHeight) &&
        fontSize > minSize
      ) {
        fontSize--
        ref.current.style.fontSize = `${fontSize}px`
      }
    }

    resizeText()
    window.addEventListener('resize', resizeText)
    return () => window.removeEventListener('resize', resizeText)
  }, [maxSize, minSize])

  return ref
}

export default useAutoResizeText
