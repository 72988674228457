const utilObject = {
  isEmpty: (obj) => {
    if (typeof obj === 'object') {
      return Object.keys(obj).length === 0
    }
    if (Array.isArray(obj)) {
      return obj.length === 0
    }
    return true
  },
  stringToBoolean: (str) => {
    if (str === 'true') return true
    if (str === 'false') return false
    if (str === undefined) return false
  },
}

export default utilObject
