import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  idOrganizationSelector,
  locationSelector,
} from '../../../../store/selectors/selectors'
import { getText } from '../../../../lang'
import { formatDateTime, voiceAIStatus } from '../../../../utils'
import SVGDetailsIcon from '../../../../icons/SVG/SVGDetailsIcon'
import MatTable from '../../../../components/MatTable'
import { CUSTOM, ORG, TABLE_DEFAULT_ROW_HEIGHT } from '../../../../devOptions'
import DashboardTags from '../../DashboardComponents/DashboardTags/DashboardTags'
import Div from '../../../../components/MatDiv/Div'
import utilNumber from '../../../../utils/utilNumber'
import { DashboardContext } from '../../Dashboard'
import statActions from '../../../../store/modules/statActions'
import { notifyError } from '../../../../utils/Notify'
import VoiceAIDetailsModal from '../../../../components/VoiceAIDetails/VoiceAIDetailsModal'
import SVGDashVoiceAiNotSuccess from '../../../../icons/SVG/SVGDashVoiceAiNotSuccess'
import SVGDashVoiceAiSuccess from '../../../../icons/SVG/SVGDashVoiceAiSuccess'
import SVGDashVoiceAiActiveSMS from '../../../../icons/SVG/SVGDashVoiceAiActiveSMS'
import ListLoadMore from '../../../../components/ListLoadMore'

const VoiceAIList = () => {
  const {
    selectedRange,
    selectedDataType,
    selectCustomRange,
    dashboardScrollBarRef,
  } = useContext(DashboardContext)

  const _organization_id = useSelector(idOrganizationSelector)
  const location = useSelector(locationSelector)
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [visible, setVisible] = useState(false)
  const [page, setPage] = useState(0)
  const [tableList, setTableList] = useState({
    list: [],
    total: 0,
  })
  const [selectedListItem, setSelectedListItem] = useState('')

  const fill = useCallback(async () => {
    if (selectedRange === CUSTOM && !selectCustomRange) {
      // Prevent API call if custom range is not selected
      return
    }
    setLoading(true)
    let locationId = selectedDataType === ORG ? '' : location?._id
    const result = await statActions.getVoiceAIListStatsDashboard(
      selectedRange,
      locationId,
      selectCustomRange.start_date,
      selectCustomRange.end_date,
      page,
      TABLE_DEFAULT_ROW_HEIGHT,
      _organization_id
    )
    if (result.success) {
      const newData =
        page === 0
          ? result?.data?.calls
          : [...tableList.list, ...result?.data?.calls]

      setTableList({
        list: newData.map((item, index) => ({
          ...item,
          key: index,
        })),
        total: result.data.total_items,
      })
    } else {
      notifyError(result.errMsg ? result.errMsg : getText('ERR_GENERAL'))
    }
    setLoading(false)
  }, [location?._id, selectedRange, selectedDataType, selectCustomRange, page])

  useEffect(() => {
    fill()
  }, [fill])

  const columns = [
    {
      title: getText('WORD_SUCCESS'),
      dataIndex: 'success',
      key: 'success',
      render: (_, { success }) => {
        return (
          <Div style={{ width: '40px' }}>
            {success ? <SVGDashVoiceAiSuccess /> : <SVGDashVoiceAiNotSuccess />}
          </Div>
        )
      },
    },
    {
      title: getText('WORD_ACTIVE_SMS'),
      dataIndex: 'isActiveSms',
      key: 'isActiveSms',
      render: (_, { isActiveSms, _conversation_id }) => {
        return (
          <Div style={{ width: '70px' }}>
            {isActiveSms ? (
              <SVGDashVoiceAiActiveSMS
                onClick={() => {
                  navigate('/messages', {
                    state: {
                      conv: {
                        _id: _conversation_id || '',
                      },
                    },
                  })
                }}
              />
            ) : null}
          </Div>
        )
      },
    },
    {
      title: getText('WORD_OUTCOME'),
      dataIndex: 'user_type',
      key: 'user_type',
      render: (_, item) => {
        const outCome = voiceAIStatus(item)
        return <DashboardTags text={outCome?.title} color={outCome?.color} />
      },
    },
    {
      title: getText('WORD_CALLER'),
      dataIndex: 'caller',
      key: 'caller',
      render: (_, { customer }) => {
        return (
          <Div style={{ width: '120px' }}>
            {customer?.fullName ||
              customer?.firstName ||
              customer?.nameSpecifiedByCustomer ||
              customer?.phone}
          </Div>
        )
      },
    },
    {
      title: getText('WORD_TYPE'),
      dataIndex: 'type',
      key: 'type',
      render: (_, { reason }) => {
        return reason || 'N/A'
      },
    },
    {
      title: getText('WORD_DATE'),
      dataIndex: 'date',
      key: 'date',
      render: (_, { createdAt }) => {
        return createdAt ? formatDateTime(createdAt) : 'N/A'
      },
    },
    {
      title: getText('WORD_DURATION'),
      dataIndex: 'duration',
      key: 'duration',
      render: (_, { durationSeconds }) => {
        return (
          <Div style={{ minWidth: 100 }}>
            {durationSeconds
              ? `${Math.floor(durationSeconds / 60)}:${String(Math.floor(durationSeconds % 60)).padStart(2, '0')} min`
              : '-'}
          </Div>
        )
      },
    },
    {
      title: getText('WORD_ESTIMATED_GROSS'),
      dataIndex: 'estimated_gross',
      key: 'estimated_gross',
      render: (_, { value }) => {
        return (
          <Div style={{ minWidth: 100 }}>
            {utilNumber.getNumberWithDollars(value || 0)}
          </Div>
        )
      },
    },
    {
      title: getText('WORD_DETAILS'),
      dataIndex: 'details',
      key: 'details',
      render: () => {
        return (
          <Div style={{ minWidth: 50 }}>
            <SVGDetailsIcon onClick={() => setVisible(true)} />
          </Div>
        )
      },
    },
  ]

  return (
    <React.Fragment>
      <ListLoadMore
        loading={loading}
        totalDataCount={tableList.total}
        onLoadMore={() => {
          if (!loading && tableList.list.length < tableList.total) {
            setLoading(true)
            setPage((page) => page + 1)
          }
        }}
        pageStart={0}
        getScrollParent={() => dashboardScrollBarRef?.current || window}
        data={tableList.list}
        customList={
          <MatTable
            columns={columns}
            loading={loading}
            dataSource={tableList.list}
            withoutPagination
            onRow={(record) => {
              return {
                onClick: () => {
                  setSelectedListItem(record)
                },
              }
            }}
            sticky={{
              offsetHeader: -10,
            }}
            tableLayout={'auto'}
            scroll={{
              x: 600,
            }}
          />
        }
      />

      <VoiceAIDetailsModal
        visible={visible}
        setVisible={setVisible}
        item={selectedListItem}
        isCallerInfoClickable
      />
    </React.Fragment>
  )
}

export default VoiceAIList
