import React, { memo, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import userActions from '../../store/modules/userActions'
import { idOrganizationSelector } from '../../store/selectors/selectors'
import { getText } from '../../lang'
import { GET_DATA_FETCH_SIZE } from '../../utils'
import { getUsersListIds } from '../../views/broadcast/CreateCampaign/CreateSegment/utils'
import { useUsers } from '../../providers/UsersProvider'
import MyDefaultSelect from './MyDefaultSelect'

const MyDefaultSelectUsers = ({
  isForm,
  color = 'grey',
  label,
  dropdownClassName,
  dropdownStyle,
  style,
  onSelectItem,
  value,
  onDropdownVisibleChange,
  placeholder,
  mode,
  required,
  name,
  labelInValue,
  message,
  isLast,
}) => {
  const context = useUsers()
  const _idOrganization = useSelector(idOrganizationSelector)

  const [listDataLocal, setListDataLocal] = useState({ maxUsers: 0, list: [] })
  const [pageLocal, setPageLocal] = useState(0)
  const [assignSearchKeywordLocal, setassignSearchKeywordLocal] = useState('')

  const listData = context ? context.listData : listDataLocal
  const setListDataHandler = context ? context.setListData : setListDataLocal

  const page = context ? context.page : pageLocal
  const setPageHandler = context ? context.setPage : setPageLocal

  const assignSearchKeyword = context
    ? context.assignSearchKeyword
    : assignSearchKeywordLocal
  const setAssignSearchKeywordHandler = context
    ? context.setAssignSearchKeyword
    : setassignSearchKeywordLocal

  const fill = useCallback(async () => {
    if (context) return
    const result = await userActions.getUsersForAssignUnassign(
      page,
      GET_DATA_FETCH_SIZE,
      _idOrganization,
      assignSearchKeyword
    )
    if (result.success) {
      const newListUsers =
        page > 0 ? [...listData.list, ...result.data] : result.data
      let stateValue = {
        users: getUsersListIds(newListUsers),
        maxUsers: result.max,
      }
      setListDataHandler({
        list: stateValue.users,
        max: stateValue.maxUsers,
      })
    }
  }, [assignSearchKeyword, page])

  useEffect(() => {
    fill()
  }, [fill])

  const debouncedChange = useCallback((options) => {
    setPageHandler(0)
    setAssignSearchKeywordHandler(options)
  }, [])

  return (
    <MyDefaultSelect
      isForm={isForm}
      color={color}
      label={label}
      dropdownClassName={dropdownClassName}
      dropdownStyle={dropdownStyle}
      style={style}
      mode={mode}
      required={required}
      labelInValue={labelInValue}
      name={name}
      isLast={isLast}
      options={listData.list}
      onLoadMore={() => {
        if (listData.list.length < listData.max) {
          setPageHandler(page + 1)
        }
      }}
      message={message || getText('WORD_PLEASE_SELECT_USER')}
      showSearch={true}
      onSearch={debouncedChange}
      placeholder={placeholder || getText('ACTION_SELECT_USER')}
      onSelectItem={onSelectItem}
      value={value}
      onDropdownVisibleChange={(e) => {
        if (!e) setAssignSearchKeywordHandler('')
        onDropdownVisibleChange && onDropdownVisibleChange(e)
      }}
      onClear={() => {
        setAssignSearchKeywordHandler('')
        setPageHandler(0)
      }}
    />
  )
}

export default memo(MyDefaultSelectUsers)
