import React, { memo, useMemo, useState } from 'react'
import Slider from 'react-slick'
import { Flex } from 'antd'
import { useSelector } from 'react-redux'
import SVGArrowLeftIcon from '../../../../../../icons/SVG/SVGArrowLeftIcon'
import SVGArrowRightIcon from '../../../../../../icons/SVG/SVGArrowRightIcon'
import { getText } from '../../../../../../lang'
import { vehicleOfInterestSelector } from '../../../../../../store/selectors/selectors'
import SVGOwnedCarsIcon from '../../../../../../icons/SVG/SVGOwnedCarsIcon'
import MyDefaultTagBlock from '../../../../../../components/MyDefaultTagBlock/MyDefaultTagBlock'
import SVGMyDefaultEditIcon from '../../../../../../icons/SVG/SVGMyDefaultEditIcon'
import utilObject from '../../../../../../utils/utilObject'
import authActions from '../../../../../../store/modules/authActions'
import SVGCrossIcon from '../../../../../../icons/SVG/SVGCrossIcon'
import Div from '../../../../../../components/Div/Div'
import MessageTabInfoOwnedCarsModal from './MessageTabInfoOwnedCarsModal'

const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
  <button
    {...props}
    className={
      'slick-next slick-arrow' +
      (currentSlide === slideCount - 1 ? ' slick-disabled' : '')
    }
    type='button'
  >
    <SVGArrowRightIcon />
  </button>
)
const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
  <button
    {...props}
    className={
      'slick-prev slick-arrow' + (currentSlide === 0 ? ' slick-disabled' : '')
    }
    type='button'
  >
    <SVGArrowLeftIcon />
  </button>
)

const MessageTabRightOwnedCars = ({ _receiver_id, convIsNew }) => {
  let vehicleOfInterest = useSelector(vehicleOfInterestSelector)

  const vehicleOfInterestList = useMemo(() => {
    return vehicleOfInterest?.data ? [...(vehicleOfInterest.data.service || [])] : []
  }, [vehicleOfInterest])

  const [visible, setVisible] = useState(false)
  const [selectedOwnedCar, setSelectedOwnedCar] = useState(false)

  const groupSize = 1
  const settings = {
    dots: false,
    infinite: vehicleOfInterestList?.length > 1 ? true : false,
    arrows: vehicleOfInterestList?.length > 1 ? true : false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <SlickArrowRight />,
    nextArrow: <SlickArrowLeft />,
  }

  return (
    <React.Fragment>
      {!utilObject.isEmpty(vehicleOfInterestList) ? (
        <Div
          className='tab-info-interests-slider'
          style={{
            borderTop: 'none',
          }}
        >
          <Slider {...settings}>
            {vehicleOfInterestList
              .map((obj, index) => {
                return (
                  <Flex
                    key={index}
                    align='start'
                    className='tab-info-icon-with-text'
                  >
                    <SVGOwnedCarsIcon />
                    <Div className='tab-info-touchpoints'>
                      <p className='drop-menu-label'>
                        {getText('WORD_OWNED_VEHICLE')}
                      </p>
                      <React.Fragment>
                        <Flex align='center' className='vehicle-name' gap={5}>
                          {obj.title}
                          <SVGMyDefaultEditIcon
                            width={16}
                            height={16}
                            onClick={() => {
                              setVisible(true)
                              setSelectedOwnedCar(obj)
                            }}
                          />
                          <MyDefaultTagBlock
                            title={getText(
                              obj.salesStep === 'Available'
                                ? 'WORD_IN_USE'
                                : 'ACTION_ARCHIVE'
                            )}
                            disableMargin
                            titleColor='var(--thridyTextColor)'
                            backgroundColor={
                              obj.salesStep === 'Available'
                                ? 'var(--greenLight)'
                                : '#FFF8DE'
                            }
                            whiteSpace='nowrap'
                          />
                        </Flex>
                        <Div className='vehicle-id'>{obj.vin}</Div>
                        {obj.mileage && (
                          <Div className='vehicle-id'>{obj.mileage}</Div>
                        )}
                      </React.Fragment>
                    </Div>
                  </Flex>
                )
              })
              .reduce((r, element, index) => {
                index % groupSize === 0 && r.push([])
                r[r.length - 1].push(element)
                return r
              }, [])
              .map((rowContent, index) => {
                return (
                  <div key={index} className='slider-row'>
                    {rowContent}
                  </div>
                )
              })}
          </Slider>
          <Flex
            align='center'
            justify='center'
            className='tab_info_add_serivce'
            onClick={() => {
              setVisible(true)
              setSelectedOwnedCar('')
            }}
          >
            <SVGCrossIcon />
          </Flex>
        </Div>
      ) : (
        <Div
          className='tab-info-interests-slider'
          style={{
            borderTop: 'none',
          }}
        >
          <Flex align='start' className='tab-info-icon-with-text'>
            <SVGOwnedCarsIcon />
            <Div className='tab-info-touchpoints'>
              <p className='drop-menu-label'>{getText('WORD_OWNED_VEHICLE')}</p>
            </Div>
          </Flex>
          <Flex
            align='center'
            justify='center'
            className='tab_info_add_serivce'
            style={{ bottom: '30%' }}
            onClick={() => {
              setVisible(true)
              setSelectedOwnedCar('')
            }}
          >
            <SVGCrossIcon />
          </Flex>
        </Div>
      )}
      <MessageTabInfoOwnedCarsModal
        visible={visible}
        setVisible={setVisible}
        _receiver_id={_receiver_id}
        obj={vehicleOfInterest}
        item={selectedOwnedCar}
        onSave={(data, isEdit) => {
          const updatedService = isEdit
            ? vehicleOfInterest.data.service.map((item) =>
                item.id === data.id ? data : item
              )
            : [
                data,
                ...(vehicleOfInterest?.data?.service?.length && !convIsNew
                  ? vehicleOfInterest.data.service
                  : []),
              ]

          authActions.setVehicleOfInterest({
            ...vehicleOfInterest,
            data: { ...vehicleOfInterest.data, service: updatedService },
          })
        }}
        convIsNew={convIsNew}
      />
    </React.Fragment>
  )
}

export default memo(MessageTabRightOwnedCars)
