import React, { memo } from 'react'
import { Radio } from 'antd'
import MyDefaultFormItem from '../MyDefaultFormItem/MyDefaultFormItem'
import './MyDefaultRadioButton.scss'

const MyDefaultRadioButton = ({
  className,
  value,
  disabled,
  onChange,
  isForm,
  required = false,
  name,
  label,
  hasFeedback,
  formStyle,
  initialValue,
  isLast,
  isFirst,
  optionType,
  buttonStyle,
  size,
  radioOptions,
  type,
  disableMargins,
}) => {
  return isForm ? (
    <MyDefaultFormItem
      label={label}
      name={name}
      hasFeedback={hasFeedback}
      rules={[
        {
          required: required,
          message: 'error radio button',
          // message: props.errorMessage ? props.errorMessage : `Please input ${props.placeholder ? props.placeholder : props.label}!`,
        },
      ]}
      style={formStyle}
      initialValue={initialValue}
      isLast={isLast}
      isFirst={isFirst}
      disableMargins={disableMargins}
    >
      <Radio.Group
        className={`my_default_radio_button ${className || ''}`}
        onChange={onChange}
        value={value}
        optionType={optionType}
        buttonStyle={buttonStyle}
        size={size}
        disabled={disabled}
        options={type === 'circle' ? radioOptions : null}
      >
        {type !== 'circle' &&
          radioOptions.map((option) => {
            return (
              <Radio.Button
                disabled={option.disabled}
                value={option.value}
                key={option.value}
              >
                {option.icon}
                <span>{option.label}</span>
              </Radio.Button>
            )
          })}
      </Radio.Group>
    </MyDefaultFormItem>
  ) : (
    <Radio.Group
      className={`my_default_radio_button ${className || ''}`}
      onChange={(e) => {
        onChange && onChange(e.target.value)
      }}
      value={value}
      optionType={optionType}
      buttonStyle={buttonStyle}
      size={size}
      disabled={disabled}
      options={type === 'circle' ? radioOptions : null}
    >
      {type !== 'circle' &&
        radioOptions.map((option) => {
          return (
            <Radio.Button
              disabled={option.disabled}
              value={option.value}
              key={option.value}
            >
              {option.icon}
              <span>{option.label}</span>
            </Radio.Button>
          )
        })}
    </Radio.Group>
  )
}

export default memo(MyDefaultRadioButton)
