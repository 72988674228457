import React, { useState, useEffect, useMemo } from 'react'
import { Flex, Form } from 'antd'
import { useSelector } from 'react-redux'
import { notifyError, notifySuccess } from '../../../../utils/Notify'
import authActions from '../../../../store/modules/authActions'
import locationActions from '../../../../store/modules/locationActions'
import { getText, getTextServerError } from '../../../../lang'
import MatForm from '../../../../components/Form/MatForm'
import MyDefaultPageLayout from '../../../../components/MyDefaultPageLayout/MyDefaultPageLayout.js'
import LoadingSpinner from '../../../../components/LoadingSpinner/index.js'
import MyDefaultBlock from '../../../../components/MyDefaultBlock/MyDefaultBlock.js'
import MyDefaultSelect from '../../../../components/MyDefaultSelect/MyDefaultSelect.js'
import useDeviceCheck from '../../../../utils/useDeviceCheck.js'
import Div from '../../../../components/Div/Div.js'
import MyDefaultLabelWithTooltip from '../../../../components/MyDefaultFormItem/MyDefaultLabelWithTooltip/MyDefaultLabelWithTooltip.js'
import { useLayout } from '../../../../layout/LayoutProvider/LayoutProvider.js'
import MyDefaultInputText from '../../../../components/MyDefaultInputText/MyDefaultInputText.js'
import { useLocationLayout } from '../../locationProvider/LocationProvider.js'
import MyDefaultRate from '../../../../components/MyDefaultRate/MyDefaultRate.js'
import { CANCEL_BTN, IMAGE, SUBMIT_BTN } from '../../../../devOptions.js'
import {
  convertNumberTo5PointSystemValue,
  MEDIATYPES_ONLY_IMAGE,
} from '../../../../utils/index.js'
import { organizationSelector } from '../../../../store/selectors/selectors.js'
import MyDefaultUploadWrapperBig from '../../../../components/MyDefaultUpload/MyDefaultUploadWrapperBig/MyDefaultUploadWrapperBig.js'
import MyDefaultDraggable from '../../../../components/MyDefaultDraggable/MyDefaultDraggable.js'
import MyDefaultColorPicker from '../../../../components/MyDefaultColorPicker/MyDefaultColorPicker.js'
import ReviewInvitePanel from './ReviewInvitePanel'

const MAX_TOTAL_URLS = 8
const DEFAULT_RATING_PAGE_TEXTS = {
  five_points: {
    en: `On a scale of 1 to 5 stars, how likely are you to recommend our business to a friend or colleague?`,
    fr: `Sur une échelle de 1 à 5 étoiles, quelle est la probabilité que vous recommandiez notre entreprise à un ami ou à un collègue ?`,
    sp: `En una escala de 1 a 5 estrellas, ¿qué probabilidad hay de que recomiende nuestra empresa a un amigo o colega?`,
  },
  ten_points: {
    en: `On a scale of 1 to 10, how likely are you to recommend our business to a friend or colleague?`,
    fr: `Sur une échelle de 1 à 10, quelle est la probabilité que vous recommandiez notre entreprise à un ami ou à un collègue ?`,
    sp: `En una escala del 1 al 10, ¿qué probabilidad hay de que recomiende nuestra empresa a un amigo o colega?`,
  },
}

const LocationReviewInvite = () => {
  const { setFooterButtons, setLoading, setPageTitle } = useLayout()
  const { locationObj, setLocationObj } = useLocationLayout()
  const { isPageDrawerSize } = useDeviceCheck()
  const organization = useSelector(organizationSelector)

  const [imagesUrl, setImagesUrl] = useState(
    organization?.languages.reduce((acc, lang) => {
      acc[lang] = {
        file: '',
        loading: false,
      }
      return acc
    }, {})
  )

  const [minRedirectScore, setminRedirectScore] = useState(
    locationObj?.minRedirectScore
      ? locationObj?.is5StarReviewEnabled
        ? 5 -
          convertNumberTo5PointSystemValue(true, locationObj.minRedirectScore) +
          1
        : 10 - locationObj.minRedirectScore + 1
      : 0
  )
  const [form] = Form.useForm()
  const reviewInviteItems = useMemo(() => {
    return [
      {
        title: getText('WORD_REVIEW_SMS_TEMPLATE_TITLE'),
        formName: 'msg',
      },
      {
        title: getText('WORD_REVIEW_EMAIL_TEMPLATE_TITLE'),
        formName: 'reviewInviteMsg',
        formNameSubject: 'reviewInviteLocatonMsg',
      },
      {
        title: getText('WORD_REVIEW_RATING_PAGE_TITLE'),
        formName: 'reviewInviteRatingPage',
      },
      {
        title: getText('WORD_REVIEW_POSITIVE_FEEDBACK_PAGE_TITLE'),
        formName: 'reviewInvitePositivePage',
      },
      {
        title: getText('WORD_REVIEW_POSITIVE_NEGATIVE_PAGE_TITLE'),
        formName: 'reviewInviteNegativePage',
      },
    ]
  }, [])

  useEffect(() => {
    setFooterButtons([
      {
        type: CANCEL_BTN,
      },
      {
        type: SUBMIT_BTN,
      },
    ])
    setPageTitle(getText('ACTION_REVIEW_INVITE'))
  }, [])

  useEffect(() => {
    setImagesUrl(
      organization?.languages.reduce((acc, lang) => {
        acc[lang] = {
          file: (locationObj && locationObj[`pic_${lang}`]) || '',
          loading: false,
        }
        return acc
      }, {})
    )
  }, [locationObj])

  const onFinish = async (values) => {
    setLoading(true)
    const { urls, msg, ...rest } = form.getFieldsValue(true)
    const payload = {
      ...rest,
      msg_en: msg.en,
      msg_fr: msg.fr,
      msg_sp: msg.sp,
      custom_urls: [],
      is5StarReviewEnabled: rest.invite_type === 'five_points',
      minRedirectScore:
        rest.invite_type === 'ten_points'
          ? 10 - minRedirectScore + 1
          : 5 - minRedirectScore + 1,
    }
    delete payload.invite_type
    if (!isValid(urls)) {
      setLoading(false)
      return
    }

    urls.forEach((urlObj, index) => {
      if (!urlObj.name || !urlObj.url) return

      urlObj.weight = urls.length - index
      if (urlObj.name === 'Google') {
        payload.url_google = {
          url: urlObj.url,
          weight: urlObj.weight,
        }
      } else if (urlObj.name === 'Facebook') {
        payload.url_fb = {
          url: urlObj.url,
          weight: urlObj.weight,
        }
      } else if (urlObj.name === 'Yelp') {
        payload.url_yelp = {
          url: urlObj.url,
          weight: urlObj.weight,
        }
      } else {
        const customUrl = {
          name: urlObj.name,
          url: urlObj.url,
          weight: urlObj.weight,
          color: urlObj.color,
        }
        payload.custom_urls.push(customUrl)
      }
    })
    const result = await locationActions.saveLocationReviewInvite(
      payload,
      locationObj.id
    )

    if (result.success) {
      notifySuccess(getText('TEXT_LOCATION_WAS_UPDATED_SUCCESSFULLY'))
      let loc = authActions.getLocation()
      setLocationObj(result.data)
      if (result.data._id === (loc && loc._id)) {
        authActions.setLocation(result.data)
      }
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
    setLoading(false)
  }

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields.length > 0) {
      notifyError(errorInfo.errorFields[0].errors[0])
    }
  }

  const getInitialUrls = () => {
    let finalUrls = locationObj.urls ? [...locationObj.urls] : []

    const currentUrlsLength = finalUrls.length
    for (let i = 0; i < MAX_TOTAL_URLS - currentUrlsLength; i++) {
      finalUrls.push({
        name: '',
        url: '',
        color: '#000000',
        weight: MAX_TOTAL_URLS - currentUrlsLength - i,
      })
    }
    return finalUrls
  }

  const isEditable = (name) => {
    return name !== 'Facebook' && name !== 'Google' && name !== 'Yelp'
  }

  const isValid = (urls) => {
    const isValidUrl = urls.some(
      (obj) =>
        obj.url && !(obj.url.startsWith('http://') || obj.url.startsWith('https://'))
    )
    const isUrlHasName = urls.some((obj) => obj.url && obj.name === '')
    if (isValidUrl) {
      notifyError(getText('ERR_INVALID_URLS'))
      return false
    }
    if (isUrlHasName) {
      notifyError(getText('ERR_MISSING_CUSTOM_URL_NAME'))
      return false
    }

    return true
  }

  const handleReorder = (e) => {
    if (e.destination) {
      if (e.source.index === e.destination.index) {
        return
      }
      const from = e.source.index
      const to = e.destination.index

      const urlsClone = form.getFieldValue('urls')
      const [removed] = urlsClone.splice(from, 1)
      urlsClone.splice(to, 0, removed)

      form.setFieldsValue({
        urls: urlsClone,
      })
    }
  }

  const handleUploadImage = (file, lang, index) => {
    if (file.status !== 'done') return
    form.setFieldsValue({
      [`pic_${lang}`]: file.url,
    })
    setImagesUrl((ov) => {
      ov[lang] = {
        file: file.url,
        loading: false,
      }
      return { ...ov }
    })
  }

  const handleDeleteImage = (lang) => {
    form.setFieldsValue({
      [`pic_${lang}`]: '',
    })
    setImagesUrl((ov) => {
      ov[lang] = {
        file: '',
        loading: false,
      }
      return { ...ov }
    })
  }

  const handleStartUpload = (lang) => {
    setImagesUrl((ov) => {
      ov[lang] = {
        file: '',
        loading: true,
      }
      return { ...ov }
    })
  }

  const listVariablesReviews = [
    {
      label: 'customer_first_name',
      value: '{{customer_first_name}}',
    },
    {
      label: 'customer_last_name',
      value: '{{customer_last_name}}',
    },
    {
      label: 'location_name',
      value: '{{location_name}}',
    },
    {
      label: 'organization_name',
      value: '{{organization_name}}',
    },
  ]

  Form.useWatch('urls', form)
  const invite_type = Form.useWatch('invite_type', form)

  if (!locationObj._id) {
    return <LoadingSpinner />
  }

  return (
    <MyDefaultPageLayout disableHeader>
      <MatForm
        fullWidth
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{
          msg: {
            en: locationObj.msg_en || '',
            fr: locationObj.msg_fr || '',
            sp: locationObj.msg_sp || '',
          },
          reviewInviteMsg: {
            en: locationObj.reviewInviteMsg.en || '',
            fr: locationObj.reviewInviteMsg.fr || '',
            sp: locationObj.reviewInviteMsg.sp || '',
          },
          reviewInviteLocatonMsg: {
            en: locationObj.reviewInviteLocatonMsg.en || '',
            fr: locationObj.reviewInviteLocatonMsg.fr || '',
            sp: locationObj.reviewInviteLocatonMsg.sp || '',
          },
          reviewInviteRatingPage: {
            en: locationObj.reviewInviteRatingPage.en || '',
            fr: locationObj.reviewInviteRatingPage.fr || '',
            sp: locationObj.reviewInviteRatingPage.sp || '',
          },
          reviewInvitePositivePage: {
            en: locationObj.reviewInvitePositivePage.en || '',
            fr: locationObj.reviewInvitePositivePage.fr || '',
            sp: locationObj.reviewInvitePositivePage.sp || '',
          },
          reviewInviteNegativePage: {
            en: locationObj.reviewInviteNegativePage.en || '',
            fr: locationObj.reviewInviteNegativePage.fr || '',
            sp: locationObj.reviewInviteNegativePage.sp || '',
          },
          pic_en: locationObj.pic_en || '',
          pic_fr: locationObj.pic_fr || '',
          pic_sp: locationObj.pic_sp || '',
          invite_type: locationObj?.is5StarReviewEnabled
            ? 'five_points'
            : 'ten_points',
          urls: getInitialUrls(),
        }}
      >
        <Flex gap={20} align='start' vertical={isPageDrawerSize}>
          <Flex gap={20} vertical>
            <MyDefaultBlock fullWidth>
              <MyDefaultSelect
                isForm
                label={`${getText('TEXT_REVIEW_INVITE_TYPE')}:`}
                name={'invite_type'}
                options={[
                  { value: 'five_points', label: getText('TEXT_FIVE_STARS') },
                  {
                    value: 'ten_points',
                    label: getText('TEXT_NPS_SCORE_10_POINTS'),
                  },
                ]}
                onChange={(val) => {
                  if (val === 'five_points') {
                    setminRedirectScore((ov) => {
                      return Math.floor((ov + 1) / 2)
                    })
                  } else {
                    setminRedirectScore((ov) => {
                      return ov * 2 - 1
                    })
                  }
                  form.setFieldsValue({
                    reviewInviteRatingPage: DEFAULT_RATING_PAGE_TEXTS[val],
                  })
                }}
              />
              <MyDefaultLabelWithTooltip
                label={getText('TEXT_REVIEW_POSTING_THRESHOLD')}
                tooltipText={getText(
                  'TEXT_SETS_THE_MINIMUM_RATING_REQUIRED_BEFORE_CUSTOMERS_CAN_POST_THEIR_REVIEW_ON_EXTERNAL_PLATFORMS'
                )}
              />
              <MyDefaultRate
                type={Boolean(invite_type === 'ten_points') ? 'points' : 'star'}
                onChange={(val) => {
                  setminRedirectScore(val)
                }}
                value={minRedirectScore}
              />
            </MyDefaultBlock>

            {reviewInviteItems.map((item) => {
              return (
                <MyDefaultBlock key={item.formName} fullWidth title={item.title}>
                  <ReviewInvitePanel
                    languages={
                      organization?.defaultLanguage !== organization?.languages?.[0]
                        ? organization?.languages?.reverse()
                        : organization?.languages
                    }
                    variables={listVariablesReviews}
                    formName={item.formName}
                    formNameSubject={item.formNameSubject}
                    form={form}
                  />
                </MyDefaultBlock>
              )
            })}
          </Flex>
          <MyDefaultBlock title={getText('WORD_REVIEW_IMAGE_TITLE')}>
            <Flex gap={20}>
              {organization?.languages?.map((lang, index) => {
                const imageUrl = imagesUrl[lang]?.file
                const loading = imagesUrl[lang]?.loading
                return (
                  <Flex vertical key={lang} style={{ width: '100%' }}>
                    <MyDefaultInputText
                      isForm
                      fullWidth
                      label={getText(`WORD_REVIEW_IMAGE_${lang.toUpperCase()}`)}
                      name={`pic_${lang}`}
                      disabled
                    />
                    <MyDefaultUploadWrapperBig
                      mediaTypes={MEDIATYPES_ONLY_IMAGE}
                      listType={'text'}
                      mediaCount={1}
                      beforeUploadProps
                      loading={loading}
                      errMsg={getText('ERROR_MESSAGE_ONLY_IMAGE_ALLOWED')}
                      onUpload={(file) => handleUploadImage(file, lang, index)}
                      onDelete={() => handleDeleteImage(lang)}
                      onStartUpload={() => handleStartUpload(lang)}
                      showUploadList={false}
                      uploadedItemObj={
                        imageUrl
                          ? {
                              type: IMAGE,
                              url: imageUrl,
                            }
                          : null
                      }
                    />
                  </Flex>
                )
              })}
            </Flex>
            <Form.List name={'urls'}>
              {(fields) => (
                <MyDefaultDraggable
                  data={fields}
                  customOnChange={handleReorder}
                  renderItem={(item, index) => {
                    const urlObj = form.getFieldValue('urls')[item.name]
                    return (
                      <React.Fragment>
                        <MyDefaultInputText
                          isForm
                          fullWidth
                          color={'white'}
                          disableMargins
                          name={[item.name, 'name']}
                          disabled={!isEditable(urlObj.name)}
                          required={isEditable(urlObj.name) && urlObj.url}
                          errorMessage={getText('ERR_MISSING_CUSTOM_URL_NAME')}
                        />
                        <MyDefaultInputText
                          isForm
                          fullWidth
                          color={'white'}
                          disableMargins
                          name={[item.name, 'url']}
                          placeholder='https://'
                          allowClear={false}
                        />
                        {isEditable(urlObj.name) ? (
                          <MyDefaultColorPicker
                            showText={false}
                            value={urlObj.color}
                            onChangeColor={(newColor) => {
                              const urlsUpdated = form.getFieldValue('urls')
                              urlsUpdated[index].color = newColor
                              form.setFieldsValue({
                                urls: urlsUpdated,
                              })
                            }}
                          />
                        ) : (
                          <Div style={{ minWidth: 40, height: 40 }} />
                        )}
                      </React.Fragment>
                    )
                  }}
                />
              )}
            </Form.List>
          </MyDefaultBlock>
        </Flex>
      </MatForm>
    </MyDefaultPageLayout>
  )
}

export default LocationReviewInvite
