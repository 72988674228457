import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import organizationActions from '../../../store/modules/organizationActions'
import authActions from '../../../store/modules/authActions'
import { getText, getTextServerError } from '../../../lang'
import { checkFormatPhoneNumber, formatDate, formatTime } from '../../../utils'
import MyDefaultBlockRow from '../../../components/MyDefaultBlock/MyDefaultBlockRow'
import { notifyError, notifySuccess } from '../../../utils/Notify'
import MyDefaultBlockHeader from '../../../components/MyDefaultBlock/MyDefaultBlockHeader'

const OrganizationListItem = ({ item }) => {
  const navigate = useNavigate()

  const [aiBotEnable, setAiBotEnable] = useState(item.enable_auto_reply)
  const [broadcasEnable, setBroadcasEnable] = useState(item.enable_broadcast)
  const [paymentsEnable, setPaymentsEnable] = useState(item.enable_payments)

  const user = authActions.getUserData()

  const handleOnChangeAiBoth = async () => {
    if (!user.isSuperAdmin) {
      notifyError(
        getText('ERROR_MESSAGE_YOU_DO_NOT_HAVE_ACCESS_TO_ENABLE_THIS_FEATURE')
      )
      return
    }
    const obj = {
      enable_auto_reply: !item.enable_auto_reply,
    }
    let result = await organizationActions.setAutoReply(obj, item._id)
    if (result.success) {
      setAiBotEnable(!aiBotEnable)
      notifySuccess(getText('NTF_SUCCESS_AI_BOT_WAS_CHANGED_SUCCESSFULLY'))
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
  }

  const handleOnChangeBroadcasting = async () => {
    if (!user.isSuperAdmin) {
      notifyError(
        getText('ERROR_MESSAGE_YOU_DO_NOT_HAVE_ACCESS_TO_ENABLE_THIS_FEATURE')
      )
      return
    }
    const obj = {
      enable_broadcast: !item.enable_broadcast,
    }
    let result = await organizationActions.setBroadcasting(obj, item._id)
    if (result.success) {
      setBroadcasEnable(!broadcasEnable)
      notifySuccess(getText('NTF_SUCCESS_BROADCASTING_WAS_CHANGED_SUCCESSFULLY'))
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
  }

  const handleOnChangePayments = async () => {
    if (!user.isSuperAdmin && !user.accessibleOrganizations.length) {
      notifyError(
        getText('ERROR_MESSAGE_YOU_DO_NOT_HAVE_ACCESS_TO_ENABLE_THIS_FEATURE')
      )
      return
    }

    const obj = {
      enable_payments: !item.enable_payments,
    }
    let result = await organizationActions.setSwitchPaymentsStatus(obj, item._id)
    if (result.success) {
      if (!obj.enable_payments) {
        await organizationActions.disconnectPayment(item._id)
      }
      setPaymentsEnable(!paymentsEnable)
      notifySuccess(getText('NTF_SUCCESS_PAYMENT_WAS_CHANGED_SUCCESSFULLY'))
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
  }

  useEffect(() => {
    setAiBotEnable(item.enable_auto_reply)
    setBroadcasEnable(item.enable_broadcast)
  }, [item])

  return (
    <React.Fragment>
      <MyDefaultBlockHeader
        label={item.name}
        onClick={() => {
          navigate(`/organization/settings/${item._id}/general`, {
            state: item,
          })
        }}
      />
      <MyDefaultBlockRow
        label={getText('WORD_DATE_CREATED')}
        value={`${formatDate(item.createdAt)} ${formatTime(item.createdAt)}`}
      />
      <MyDefaultBlockRow
        label={getText('WORD_PHONE')}
        value={checkFormatPhoneNumber(item.twilio_number)}
      />
      <MyDefaultBlockRow label={getText('WORD_ID')} value={item._id} />
      <MyDefaultBlockRow
        label={getText('TITLE_DRIP_SEQUENCE')}
        value={aiBotEnable}
        isSwitch
        onChange={handleOnChangeAiBoth}
      />
      <MyDefaultBlockRow
        label={getText('WORD_BROADCASTING')}
        value={broadcasEnable}
        isSwitch
        onChange={handleOnChangeBroadcasting}
      />
      <MyDefaultBlockRow
        label={getText('WORD_PAYMENTS')}
        value={paymentsEnable}
        isSwitch
        onChange={handleOnChangePayments}
        isLast
      />
    </React.Fragment>
  )
}

export default OrganizationListItem
