import React from 'react'
import SVGCouponPhoneIcon from '../../../../../../icons/SVG/SVGCouponPhoneIcon'
import SVGDrawerBigLogo from '../../../../../../icons/SVG/SVGDrawerBigLogo'
import SVGSwipeIcon from '../../../../../../icons/SVG/SVGSwipeIcon'
import { getText } from '../../../../../../lang'
import SVGLoginLogo from '../../../../../../icons/SVG/SVGLoginLogo'
import Div from '../../../../../../components/Div/Div'
import './CouponPreview.scss'

const CouponPreview = ({
  uploadFile,
  termsLink,
  color,
  theme,
  title,
  language,
  description,
  buttonTitle,
}) => {
  return (
    <Div className='phone_icon_wrapper'>
      <Div className='phone_icon'>
        <SVGCouponPhoneIcon />
        <Div
          className={`phone_under_nav_wrapper`}
          style={{ backgroundColor: color.primary }}
        >
          <Div className='nav_header_border'></Div>
          <Div className='logo_item'>
            {uploadFile && uploadFile.uploadImage ? (
              <img src={uploadFile.uploadImage} alt='upload_image' />
            ) : theme === 'dark' ? (
              <SVGDrawerBigLogo width={137} height={27.045} />
            ) : (
              <SVGLoginLogo width={137} height={27.045} />
            )}
          </Div>
          <Div className='title_item'>
            <Div className='title' style={{ color: color.title }}>
              {title[language]}
            </Div>
          </Div>
          <Div className='note_item'>
            <Div className='note' style={{ color: color.description }}>
              {description[language]}
            </Div>
          </Div>
          <Div className='button_item'>
            <Div
              className='swipe_to_start_button'
              style={{ backgroundColor: color.buttonBackground }}
            >
              <Div className='icon_wrapper'>
                <SVGSwipeIcon color={color.buttonBackground} />
              </Div>
              <Div className='text' style={{ color: color.buttonBackground }}>
                <Div className='note' style={{ color: color.button }}>
                  {buttonTitle[language]}
                </Div>
              </Div>
            </Div>
          </Div>
          <Div className='nav_footer'>
            <Div className='footer_first_item'>
              {getText('WORD_BY')}

              <a
                href='https://matador.ai/'
                target='_blank'
                rel='noopener noreferrer'
                className={`app_name${theme === 'dark' ? '_dark' : ''}`}
              >
                {theme === 'dark' ? (
                  <SVGDrawerBigLogo />
                ) : (
                  <SVGLoginLogo width={70.21} height={16.24} />
                )}
              </a>
            </Div>
            <Div className='footer_last_item'>
              <React.Fragment>
                {getText('USE_AS_SUBJECT_TO')}
                <a
                  className='normal_text'
                  target='_blank'
                  rel='noopener noreferrer'
                  href={termsLink || 'https://matador.ai/'}
                >
                  {getText('TERMS')}
                </a>
              </React.Fragment>{' '}
              {getText('TEXT_MESSAGE_FEES_MAY_APPLY')}
            </Div>
          </Div>
        </Div>
      </Div>
    </Div>
  )
}

export default CouponPreview
