import React from 'react'
import { Flex } from 'antd'
import SVGWidgetPositionRight from '../../icons/SVG/SVGWidgetPositionRight'
import SVGWidgetPositionLeft from '../../icons/SVG/SVGWidgetPositionLeft'
import SVGTextPositionTop from '../../icons/SVG/SVGTextPositionTop'
import SVGTextPositionCenter from '../../icons/SVG/SVGTextPositionCenter'
import Div from '../Div/Div'

const WidgetButton = ({ isSelected, onClick, children }) => (
  <Div
    style={{
      cursor: 'pointer',
      padding: 5,
      borderRadius: 8,
      border: `1px solid ${isSelected ? '#5B66EA' : 'var(--borderLineColor)'}`,
    }}
    onClick={onClick}
  >
    {children}
  </Div>
)

const RenderIcon = ({ size, position }) => {
  let icon =
    position === 'left' ? <SVGWidgetPositionLeft /> : <SVGWidgetPositionRight />

  if (position === 'left') {
    if (size === '3') {
      icon = <SVGTextPositionCenter />
    }
  } else {
    if (size === '3') {
      icon = <SVGTextPositionTop />
    }
  }

  return icon
}

const MyDefaultWidgetPosition = ({
  widgetPosition,
  setWidgetPosition,
  marginBottom,
  size = '6',
}) => {
  return (
    <Flex gap={20} style={{ marginTop: 10, marginBottom: marginBottom }}>
      <WidgetButton
        isSelected={widgetPosition === 'right'}
        onClick={() => setWidgetPosition('right')}
      >
        <RenderIcon size={size} />
      </WidgetButton>
      <WidgetButton
        isSelected={widgetPosition === 'left'}
        onClick={() => setWidgetPosition('left')}
      >
        <RenderIcon size={size} position={'left'} />
      </WidgetButton>
    </Flex>
  )
}

export default MyDefaultWidgetPosition
