import React, { useEffect, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { userSelector } from '../../../store/selectors/selectors'
import { getText } from '../../../lang'
import SVGOrgTemplateIcon from '../../../icons/SVG/SVGOrgTemplateIcon'
import SVGOrgOpeningHoursIcon from '../../../icons/SVG/SVGOrgOpeningHoursIcon'
import SVGOrgRestricionsIcon from '../../../icons/SVG/SVGOrgRestricionsIcon'
import SVGOrgGeneralIcon from '../../../icons/SVG/SVGOrgGeneralIcon'
import SVGOrgTagsIcon from '../../../icons/SVG/SVGOrgTagsIcon'
import SVGOrgWorkflowIcon from '../../../icons/SVG/SVGOrgWorkflowIcon'
import SVGOrgTouchpointIcon from '../../../icons/SVG/SVGOrgTouchpointIcon'
import SVGOrgIPWhiteListIcon from '../../../icons/SVG/SVGOrgIPWhiteListIcon'
import SVGOrgConversationAIIcon from '../../../icons/SVG/SVGOrgConversationAIIcon'
import SVGSettingsTransferIcon from '../../../icons/SVG/SVGSettingsTransferIcon'
import SVGOrgSequenceIcon from '../../../icons/SVG/SVGOrgSequenceIcon'
import SVGOrgCustomAskConsMessage from '../../../icons/SVG/SVGOrgCustomAskConsMessage'
import SVGVoiceAiIcon from '../../../icons/SVG/SVGVoiceAiIcon'
import { SUPER_ADMIN_FAVORITE_EMAILS } from '../../../devOptions'
import useDeviceCheck from '../../../utils/useDeviceCheck'
import MyDefaultInsidePageLeftPanel from '../../../components/MyDefaultInsidePagePanelLayout/MyDefaultInsidePageLeftPanel/MyDefaultInsidePageLeftPanel'
import MyDefaultDropdown from '../../../components/MyDefaultDropdown/MyDefaultDropdown'
import MyDefaultAutoResizingText from '../../../components/MyDefaultAutoResizingText/MyDefaultAutoResizingText'
import Div from '../../../components/Div/Div'

const copySettingAllowEmail = [
  ...SUPER_ADMIN_FAVORITE_EMAILS,
  'jack@delosdigital.com.au',
  'jeff.woolcock@gmail.com',
]

const baseUrl = '/organization/settings'

const OrganizationMenu = ({ id }) => {
  const { isTabletPortraitOrMobile } = useDeviceCheck()
  const user = useSelector(userSelector)

  const [selectedRoute, setSelectedRoute] = useState([])

  const dropMenuUrlPaths = useMemo(() => {
    return [
      `${baseUrl}/${id}/templates/email/list`,
      `${baseUrl}/${id}/templates/email/create`,
      `${baseUrl}/${id}/templates/email/edit`,
      `${baseUrl}/${id}/templates/sms/list`,
      `${baseUrl}/${id}/templates/sms/create`,
      `${baseUrl}/${id}/templates/sms/edit`,
    ]
  }, [id])

  const dropMenuUrlPathsVoiceAI = useMemo(() => {
    return [
      `${baseUrl}/${id}/voice_ai/inbound_old`,
      `${baseUrl}/${id}/voice_ai/inbound`,
      `${baseUrl}/${id}/voice_ai/outbound`,
    ]
  }, [id])

  const subMenuTemplates = [
    {
      key: `${baseUrl}/${id}/templates/sms/list`,
      label: getText('WORD_SMS_TEMPLATES'),
    },
    {
      key: `${baseUrl}/${id}/templates/email/list`,
      label: getText('WORD_EMAIL_TEMPLATES'),
    },
  ]

  const subMenuVoiceAI = [
    {
      key: `${baseUrl}/${id}/voice_ai/inbound_old`,
      label: `${getText('WORD_INBOUND')} V1`,
    },
    {
      key: `${baseUrl}/${id}/voice_ai/inbound`,
      label: `${getText('WORD_INBOUND')} V2`,
    },
    {
      key: `${baseUrl}/${id}/voice_ai/outbound`,
      label: getText('WORD_OUTBOUND'),
    },
  ]

  const RespSubMenuTemplates = () => {
    const items = [
      {
        key: `${baseUrl}/${id}/templates/sms/list`,
        label: (
          <Div
            onClick={() => {
              navigate(`${baseUrl}/${id}/templates/sms/list`)
              setSelectedRoute(`${baseUrl}/${id}/templates/sms/list`)
            }}
          >
            {getText('WORD_SMS_TEMPLATES')}
          </Div>
        ),
      },
      {
        key: `${baseUrl}/${id}/templates/email/list`,
        label: (
          <Div
            onClick={() => {
              navigate(`${baseUrl}/${id}/templates/email/list`)
              setSelectedRoute(`${baseUrl}/${id}/templates/email/list`)
            }}
          >
            {getText('WORD_EMAIL_TEMPLATES')}
          </Div>
        ),
      },
    ]

    return (
      <MyDefaultDropdown items={items} placement={'bottom'}>
        <SVGOrgTemplateIcon />
      </MyDefaultDropdown>
    )
  }

  const RespSubMenuVoiceAI = () => {
    const items = [
      {
        key: `${baseUrl}/${id}/voice_ai/inbound_old`,
        label: (
          <Div
            onClick={() => {
              navigate(`${baseUrl}/${id}/voice_ai/inbound_old`)
              setSelectedRoute(`${baseUrl}/${id}/voice_ai/inbound_old`)
            }}
          >
            {getText('WORD_INBOUND_VOICE_AI')} (Old)
          </Div>
        ),
      },
      {
        key: `${baseUrl}/${id}/voice_ai/inbound`,
        label: (
          <Div
            onClick={() => {
              navigate(`${baseUrl}/${id}/voice_ai/inbound`)
              setSelectedRoute(`${baseUrl}/${id}/voice_ai/inbound`)
            }}
          >
            {getText('WORD_INBOUND_VOICE_AI')}
          </Div>
        ),
      },
      {
        key: `${baseUrl}/${id}/voice_ai/outbound`,
        label: (
          <Div
            onClick={() => {
              navigate(`${baseUrl}/${id}/voice_ai/outbound`)
              setSelectedRoute(`${baseUrl}/${id}/voice_ai/outbound`)
            }}
          >
            {getText('WORD_OUTBOUND_VOICE_AI')}
          </Div>
        ),
      },
    ]

    return (
      <MyDefaultDropdown items={items} placement={'bottom'}>
        <SVGVoiceAiIcon />
      </MyDefaultDropdown>
    )
  }

  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (id) {
      let newSelectedMenu = location.pathname
      if (
        location.pathname.includes('/dripsequence/edit') ||
        location.pathname.includes('/dripsequence/create')
      ) {
        newSelectedMenu = `${baseUrl}/${id}/dripsequence/list`
      } else if (
        location.pathname.includes('/dripsequence/template/edit') ||
        location.pathname.includes('/dripsequence/template/create')
      ) {
        newSelectedMenu = `${baseUrl}/${id}/dripsequence/template/list`
      } else if (
        location.pathname.includes('/template/sms/edit') ||
        location.pathname.includes('/template/sms/create')
      ) {
        newSelectedMenu = `${baseUrl}/${id}/templates/sms/list`
      } else if (
        location.pathname.includes('/template/email/edit') ||
        location.pathname.includes('/template/email/create')
      ) {
        newSelectedMenu = `${baseUrl}/${id}/templates/email/list`
      } else if (
        location.pathname.includes('/workflow/create') ||
        location.pathname.includes('/workflow/edit')
      ) {
        newSelectedMenu = `${baseUrl}/${id}/workflow/list`
      } else if (location.pathname.includes('voice_ai/inbound_old')) {
        newSelectedMenu = `${baseUrl}/${id}/voice_ai/inbound_old`
      } else if (location.pathname.includes('voice_ai/inbound')) {
        newSelectedMenu = `${baseUrl}/${id}/voice_ai/inbound`
      } else if (location.pathname.includes('voice_ai/outbound')) {
        newSelectedMenu = `${baseUrl}/${id}/voice_ai/outbound`
      }
      setSelectedRoute(newSelectedMenu)
    }
  }, [location.pathname])

  const isSuperAdminAndAdmin = user.isSuperAdmin || user.isAdmin
  const onlySuperAdmin = user.isSuperAdmin && user.isAdmin

  const isCSTeamEmail = useMemo(
    () => SUPER_ADMIN_FAVORITE_EMAILS.includes(user.email),
    [user?.email]
  )
  const canCopySettings = useMemo(
    () => copySettingAllowEmail.includes(user.email),
    [user?.email]
  )
  const canSeeVoiceAI = useMemo(
    () => SUPER_ADMIN_FAVORITE_EMAILS.includes(user.email),
    [user?.email]
  )

  const items = useMemo(() => {
    return [
      isSuperAdminAndAdmin && {
        key: `${baseUrl}/${id}/general`,
        label: getText('WORD_GENERAL'),
        icon: <SVGOrgGeneralIcon />,
      },
      isSuperAdminAndAdmin && {
        key: `${baseUrl}/${id}/tags`,
        label: getText('WORD_TAGS'),
        icon: <SVGOrgTagsIcon />,
      },
      isSuperAdminAndAdmin && {
        key: `${baseUrl}/${id}/dripsequence/list`,
        label: getText('TITLE_DRIP_SEQUENCE'),
        icon: <SVGOrgSequenceIcon />,
      },
      isCSTeamEmail &&
        onlySuperAdmin && {
          key: `${baseUrl}/${id}/dripsequence/template/list`,
          label: getText('WORD_DRIP_SEQUENCE_TEMPLATES'),
          icon: <SVGOrgTemplateIcon />,
        },
      isSuperAdminAndAdmin && {
        key: `${baseUrl}/${id}/touchpoints`,
        label: getText('WORD_TOUCHPOINTS'),
        icon: <SVGOrgTouchpointIcon />,
      },
      {
        key: `${baseUrl}/${id}/workflow/list`,
        label: getText('WORD_WORKFLOWS'),
        icon: <SVGOrgWorkflowIcon />,
      },
      isSuperAdminAndAdmin && {
        key: `${baseUrl}/${id}/restrictions`,
        label: getText('WORD_SETTINGS_AND_PERMISSIONS'),
        icon: <SVGOrgRestricionsIcon />,
      },
      isSuperAdminAndAdmin && {
        key: `${baseUrl}/${id}/superhuman`,
        label: getText('AI_BOT_SUPERHUMAN'),
        icon: <SVGOrgConversationAIIcon />,
      },
      isSuperAdminAndAdmin &&
        (isTabletPortraitOrMobile
          ? {
              icon: <RespSubMenuTemplates />,
              label: getText('WORD_USER_TEMPLATES'),
              key: 'templates',
              className: dropMenuUrlPaths.includes(selectedRoute) ? 'active' : '',
            }
          : {
              key: `templates`,
              label: getText('WORD_USER_TEMPLATES'),
              icon: <SVGOrgTemplateIcon />,
              children: subMenuTemplates,
            }),
      isSuperAdminAndAdmin && {
        key: `${baseUrl}/${id}/opening_hours`,
        label: getText('WORD_OPENING_HOURS'),
        icon: <SVGOrgOpeningHoursIcon />,
      },
      onlySuperAdmin && {
        key: `${baseUrl}/${id}/ip_whitelist`,
        label: getText('WORD_IP_WHITELIST'),
        icon: <SVGOrgIPWhiteListIcon />,
      },
      Boolean(onlySuperAdmin && canCopySettings) && {
        key: `${baseUrl}/${id}/settings_transfer`,
        label: getText('WORD_SETTINGS_TRANSFER'),
        icon: <SVGSettingsTransferIcon />,
      },
      Boolean(isSuperAdminAndAdmin && canSeeVoiceAI) &&
        (isTabletPortraitOrMobile
          ? {
              icon: <RespSubMenuVoiceAI />,
              label: getText('WORD_VOICE_AI'),
              key: 'voice_ai',
              className: dropMenuUrlPathsVoiceAI.includes(selectedRoute)
                ? 'active'
                : '',
            }
          : {
              key: `voice_ai`,
              label: getText('WORD_VOICE_AI'),
              icon: <SVGVoiceAiIcon />,
              children: subMenuVoiceAI,
            }),
      Boolean(isSuperAdminAndAdmin) && {
        key: `/organization/settings/${id}/custom_ask_consent_messages`,
        label: (
          <MyDefaultAutoResizingText
            max={16}
            min={10}
            text={getText('TEXT_CUSTOM_ASK_CONSENT_MESSAGES')}
          />
        ),
        icon: <SVGOrgCustomAskConsMessage />,
      },
    ]
  }, [
    isSuperAdminAndAdmin,
    onlySuperAdmin,
    canCopySettings,
    dropMenuUrlPaths,
    selectedRoute,
    isTabletPortraitOrMobile,
    subMenuTemplates,
    isCSTeamEmail,
    canSeeVoiceAI,
  ])

  const handleClick = (e) => {
    if (e.key === 'templates') {
      return
    }
    if (e.key === 'voice_ai') {
      return
    }
    navigate(e.key)
    setSelectedRoute(e.key)
  }

  return (
    <MyDefaultInsidePageLeftPanel
      onClick={handleClick}
      selectedRoute={selectedRoute}
      navigationMenuIsOnTop={isTabletPortraitOrMobile}
      items={items}
    />
  )
}

export default OrganizationMenu
