import React, { useMemo } from 'react'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Flex, Form, List, message, Tooltip } from 'antd'
import { getText } from '../../lang'
import SVGMyDefaultDeleteIcon from '../../icons/SVG/SVGMyDefaultDeleteIcon'
import MyDefaultBlockTitle from '../MyDefaultBlock/MyDefaultBlockTitle'
import MyDefaultSelect from '../MyDefaultSelect/MyDefaultSelect'
import MyDefaultInputText from '../MyDefaultInputText/MyDefaultInputText'
import MyDefaultBlockHeaderAdditionalButton from '../MyDefaultBlock/MyDefaultBlockHeaderAdditionalButton'
import useDeviceCheck from '../../utils/useDeviceCheck'
import './MyDefaultUrlConditions.scss'

const MyDefaultUrlConditions = ({ hideAction = false, urls, setUrls }) => {
  const [form] = Form.useForm()
  const { isMobile } = useDeviceCheck()

  const TitleTooltip = useMemo(() => {
    const tooltipText = (
      <div>
        <p>{getText('TEXT_CONTENT_VISIBILITY_IS_DYNAMICALLY_CONTROLLED')}</p>
        <br />
        <p>
          <b>{getText('TEXT_SHOW')}:</b>{' '}
          {getText(
            'TEXT_OVERRIDES_HIDE_CONDITIONS_ENSURING_CONTENT_IS_VISIBLE_WHEN'
          )}
        </p>
        <p>
          <b>{getText('TEXT_HIDE')}:</b>{' '}
          {getText('TEXT_MAKES_CONTENT_INVISIBLE_WHEN_ITS_CONDITIONS_MATCH')}
        </p>
        <p>
          <b>{getText('TEXT_PRIORITIZATION')}:</b>{' '}
          {getText('TEXT_WHEN_BOTH_SHOW_AND_HIDE_CONDITIONS_MATCH_SHOW')}
        </p>
        <br />
        <p>
          <b>{getText('TEXT_CONTAINS')}:</b> {getText('TEXT_INCLUDES_SPECIFIC_TEXT')}
        </p>
        <p>
          <b>{getText('TEXT_STARTS_WITH')}:</b>{' '}
          {getText('TEXT_BEGINS_WITH_SPECIFIC_TEXT')}
        </p>
        <p>
          <b>{getText('TEXT_MATCHES')}:</b>{' '}
          {getText('TEXT_EXACTLY_MATCHES_SPECIFIC_TEXT')}
        </p>
        <p>
          <b>{getText('TEXT_ENDS_WITH')}:</b>{' '}
          {getText('TEXT_ENDS_WITH_SPECIFIC_TEXT')}
        </p>
        <p>{getText('TEXT_PROTOCOL_ARE_IGNORED_IN_COMPARISONS')}</p>
      </div>
    )
    return (
      <Tooltip title={tooltipText} overlayStyle={{ width: 300 }}>
        <InfoCircleOutlined />
      </Tooltip>
    )
  }, [])

  const actionOptions = useMemo(() => {
    return [
      { label: getText('TEXT_SHOW'), value: 'show' },
      { label: getText('TEXT_HIDE'), value: 'hide' },
    ]
  }, [])

  const conditionOptions = useMemo(() => {
    return [
      { label: getText('TEXT_CONTAINS'), value: 'contains' },
      { label: getText('TEXT_STARTS_WITH'), value: 'starts_with' },
      { label: getText('TEXT_MATCHES'), value: 'matches' },
      { label: getText('TEXT_ENDS_WITH'), value: 'ends_with' },
    ]
  }, [])

  const conditionExists = (newCondition) => {
    return urls.some(
      (condition) =>
        condition.condition === newCondition.condition &&
        condition.link === newCondition.link
    )
  }

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        if (conditionExists(values)) {
          message.error('This condition has already been added.')
          return
        }
        values.action = hideAction ? 'show' : values.action
        setUrls([...urls, values])
        form.resetFields()
      })
      .catch((info) => {})
  }

  const removeCondition = (index) => {
    const newConditions = urls.filter((_, idx) => idx !== index)
    setUrls(newConditions)
  }

  const displayConditionLabel = (condition) => {
    let item = conditionOptions.find((x) => x.value === condition.condition)

    return `Condition: ${item.label}`
  }

  const renderConditionDescription = (item) => {
    return (
      <div>
        <span>
          Action: <b>{item.action}</b>
        </span>
        <br />
        <span>
          Link: <span style={{ color: '#0075ff', opacity: 0.8 }}>{item.link}</span>
        </span>
      </div>
    )
  }

  return (
    <React.Fragment>
      <MyDefaultBlockTitle
        title={
          <Flex align={'center'} gap={5}>
            {getText('TEXT_URL_TARGETING')}
            {TitleTooltip}
          </Flex>
        }
        additionalHeader={
          <MyDefaultBlockHeaderAdditionalButton
            buttonText={getText('TEXT_ADD_CONDITION')}
            onClick={handleSubmit}
          />
        }
      />
      <Form
        form={form}
        initialValues={{
          action: hideAction ? 'show' : null,
          condition: null,
          link: null,
        }}
        layout='vertical'
      >
        <Flex gap={isMobile ? 0 : 20} vertical={isMobile}>
          {hideAction ? null : (
            <MyDefaultSelect
              fullWidth
              isForm
              label={getText('GA_ACTION')}
              placeholder={getText('GA_ACTION')}
              name='action'
              options={actionOptions}
              required
            />
          )}
          <MyDefaultSelect
            fullWidth
            isForm
            label='Condition'
            name='condition'
            placeholder={'Condition'}
            options={conditionOptions}
            required
          />
        </Flex>
        <MyDefaultInputText
          isForm
          label={getText('WORD_LINK')}
          name='link'
          required
          errorMessage={getText('ERR_VALIDATION_REQUIRED')}
        />
      </Form>

      {urls && urls.length > 0 && (
        <React.Fragment>
          <MyDefaultBlockTitle title={'Added Conditions'} />
          <List
            itemLayout='horizontal'
            dataSource={urls}
            renderItem={(item, index) => (
              <List.Item
                style={{ paddingBottom: urls.length - 1 === index ? 0 : 12 }}
                actions={[
                  <SVGMyDefaultDeleteIcon onClick={() => removeCondition(index)} />,
                ]}
              >
                <List.Item.Meta
                  title={displayConditionLabel(item)}
                  description={<div>{renderConditionDescription(item)}</div>}
                  style={{ wordBreak: 'break-word' }}
                />
              </List.Item>
            )}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default MyDefaultUrlConditions
