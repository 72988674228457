import React from 'react'
import { Flex } from 'antd'
import Div from '../../../../../../components/Div/Div'
import MyDefaultColorPicker from '../../../../../../components/MyDefaultColorPicker/MyDefaultColorPicker'
import SVGSwipeIcon from '../../../../../../icons/SVG/SVGSwipeIcon'
import WidgetTextarea from './WidgetTextarea'

const WidgetButton = ({
  buttonBackgroundColor,
  value,
  onChange,
  onChangeColor,
  onTextChangeColor,
  textColor,
}) => {
  return (
    <Div className='widget_button_wrapper'>
      <Flex
        align='center'
        className='widget_button_wrapper--swipe'
        style={{ backgroundColor: buttonBackgroundColor }}
        gap={10}
      >
        <SVGSwipeIcon color={buttonBackgroundColor} />
        <WidgetTextarea
          isInput
          value={value}
          onChange={onChange}
          onChangeColor={onTextChangeColor}
          color={textColor}
        />
      </Flex>
      <MyDefaultColorPicker
        size='small'
        showText={false}
        value={buttonBackgroundColor}
        onChangeColor={onChangeColor}
      />
    </Div>
  )
}

export default WidgetButton
