import React from 'react'
import { Flex } from 'antd'

const MyFlex = (props) => {
  return (
    <Flex
      {...props}
      style={{ ...props.style, ...{ flexDirection: props.direction } }}
    >
      {props.children}
    </Flex>
  )
}

export default MyFlex
