import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Divider, Flex, Form } from 'antd'
import locationActions from '../../../../../store/modules/locationActions'
import { getText, getTextServerError } from '../../../../../lang'
import { getLongName } from '../../../../../utils'
import { notifyError, notifySuccess } from '../../../../../utils/Notify'
import { APP_URL } from '../../../../../config'
import MatForm from '../../../../../components/Form/MatForm'
import SelectDelay from '../../../../../components/api/SelectDelay'
import LoadingSpinner from '../../../../../components/LoadingSpinner'
import { useLayout } from '../../../../../layout/LayoutProvider/LayoutProvider'
import { organizationSelector } from '../../../../../store/selectors/selectors'
import { useLocationLayout } from '../../../locationProvider/LocationProvider'
import { CANCEL_BTN, SUBMIT_BTN } from '../../../../../devOptions'
import MyDefaultPageLayout from '../../../../../components/MyDefaultPageLayout/MyDefaultPageLayout'
import useDeviceCheck from '../../../../../utils/useDeviceCheck'
import MyDefaultBlock from '../../../../../components/MyDefaultBlock/MyDefaultBlock'
import MyDefaultSwitch from '../../../../../components/MyDefaultSwitch/MyDefaultSwitch'
import MyDefaultBlockSubtitle from '../../../../../components/MyDefaultBlock/MyDefaultBlockSubtitle'
import MyDefaultInsidePageTabs from '../../../../../components/MyDefaultInsidePageTabs/MyDefaultInsidePageTabs'
import MyDefaultTextarea from '../../../../../components/MyDefaultTextarea/MyDefaultTextarea'
import MyDefaultInputText from '../../../../../components/MyDefaultInputText/MyDefaultInputText'
import MyDefaultCopyTextarea from '../../../../../components/MyDefaultCopyTextarea/MyDefaultCopyTextarea'
import MyDefaultUrlConditions from '../../../../../components/MyDefaultUrlConditions/MyDefaultUrlConditions'
import MyDefaultWidgetPosition from '../../../../../components/MyDefaultWidgetPosition/MyDefaultWidgetPosition'
import CouponPreview from './couponPreview/CouponPreview'
import WidgetPreview from './widgetPreview/WidgetPreview'

const LocationsCoupon = () => {
  const { locationObj } = useLocationLayout()
  const { setFooterButtons, setLoading, setPageTitle } = useLayout()
  const organization = useSelector(organizationSelector)
  const { isPageDrawerSize, isMobile } = useDeviceCheck()

  const [form] = Form.useForm()

  const [isLoading, setIsLoading] = useState(true)
  const [couponData, setCouponData] = useState([])

  const [uploadFile, setUploadFile] = useState({
    fileUploadPage: true,
    uploadImage: '',
  })
  const [selectedLanguage, setSelectedLanguage] = useState('')

  const [title, setTitle] = useState({
    en: '',
    fr: '',
    sp: '',
  })
  const [buttonTitle, setButtonTitle] = useState({
    en: '',
    fr: '',
    sp: '',
  })
  const [description, setDescription] = useState({
    en: '',
    fr: '',
    sp: '',
  })
  const [color, setColor] = useState({
    title: '',
    description: '',
    button: '',
    primary: '',
    buttonBackground: '',
  })

  const [showOnDesktop, setShowOnDesktop] = useState()
  const [widgetPosition, setWidgetPosition] = useState()
  const [urls, setUrls] = useState([])

  useEffect(() => {
    setFooterButtons([
      {
        type: CANCEL_BTN,
      },
      {
        type: SUBMIT_BTN,
      },
    ])
    setPageTitle('Coupon')
  }, [])

  useEffect(() => {
    const fill = async () => {
      let result = await locationActions.getCoupon(locationObj?._id)
      for (let index = 0; index < organization?.languages?.length; index++) {
        const element = organization?.languages?.[index]
        if (element === organization?.defaultLanguage) {
          setSelectedLanguage(index)
        }
      }

      if (result.success) {
        setCouponData(result.data)
        setIsLoading(false)
      }
    }

    if (locationObj?._id) {
      fill()
    }
  }, [locationObj, locationObj?._id])

  const checkIfFreshInstance = () => {
    return (
      !couponData ||
      !couponData.title ||
      (couponData.title &&
        couponData.title.length === 0 &&
        (!couponData.description ||
          (couponData.description && couponData.description.length === 0)))
    )
  }

  useEffect(() => {
    const isFreshInstance = checkIfFreshInstance()

    setUploadFile({ uploadImage: couponData.logoImageUrl })
    setColor({
      button: couponData.buttonColor,
      description: couponData.descriptionColor,
      title: couponData.titleColor,
      primary: couponData.primaryBackgroundColor,
      buttonBackground: couponData.backgroundColor,
    })

    if (isFreshInstance) {
      setUploadFile({ uploadImage: couponData.defaultImageUrl })
    }

    if (couponData?.title?.length) {
      const objTitles = {
        en: '',
        fr: '',
        sp: '',
      }
      couponData.title.forEach((item) => {
        objTitles[item.language] = item.text
        return item
      })

      setTitle(objTitles)
    } else if (isFreshInstance) {
      setTitle({
        en: getText('TEXT_PROMOTION_TITLE', 'en'),
        fr: getText('TEXT_PROMOTION_TITLE', 'fr'),
        sp: getText('TEXT_PROMOTION_TITLE', 'sp'),
      })
    }

    if (couponData?.description?.length) {
      const objDescriptions = {
        en: '',
        fr: '',
        sp: '',
      }
      couponData.description.forEach((item) => {
        objDescriptions[item.language] = item.text
        return item
      })
      setDescription(objDescriptions)
    } else if (isFreshInstance) {
      setDescription({
        en: getText('TEXT_PROMOTION_TEXT', 'en'),
        fr: getText('TEXT_PROMOTION_TEXT', 'fr'),
        sp: getText('TEXT_PROMOTION_TEXT', 'sp'),
      })
    }

    if (couponData?.buttonTexts?.length) {
      const objButtonTexts = {
        en: '',
        fr: '',
        sp: '',
      }
      couponData.buttonTexts.forEach((item) => {
        objButtonTexts[item.language] = item.text
        return item
      })
      setButtonTitle(objButtonTexts)
    } else if (isFreshInstance) {
      setButtonTitle({
        en: getText('TEXT_PROMOTION_BUTTON', 'en'),
        fr: getText('TEXT_PROMOTION_BUTTON', 'fr'),
        sp: getText('TEXT_PROMOTION_BUTTON', 'sp'),
      })
    }

    let list = couponData.smsTemplate

    // For Manual Default Text
    if (isFreshInstance) {
      list = organization?.languages?.map((tmp) => {
        return {
          language: tmp,
          text: getText('TEXT_PROMOTION_TEMPLATE', tmp),
        }
      })
    } else {
      list = organization?.languages?.map((tmp) => {
        let find =
          couponData &&
          couponData.smsTemplate &&
          couponData.smsTemplate.find((txt) => {
            return txt.language === tmp
          })
        return find
          ? find
          : {
              language: tmp,
              text: undefined,
            }
      })
    }

    setShowOnDesktop(couponData.showOnDesktop)
    setWidgetPosition(couponData.widgetPosition)

    if (couponData && couponData.conditions) {
      setUrls(couponData.conditions.url)
    }

    const initialValues = {
      logoImageUrl: couponData.logoImageUrl,
      appearanceDelaySeconds: couponData.appearanceDelaySeconds,
      primaryBackgroundColor: couponData.primaryBackgroundColor,
      showOncePerDayOption: couponData.showOncePerDayOption,
      showOnMobile: couponData.showOnMobile,
      showOnDesktop: couponData.showOnDesktop,
      widgetPosition: couponData.widgetPosition,
      backgroundColor: couponData.backgroundColor,
      buttonColor: couponData.buttonColor,
      titleColor: couponData.titleColor,
      descriptionColor: couponData.descriptionColor,
      termsConditionsLink: couponData.termsConditionsLink,
      smsTemplate: list,
    }
    form.setFieldsValue(initialValues)
  }, [couponData])

  const onFinish = async (values) => {
    setLoading(true)
    delete values.numberValue
    delete values.text

    const obj = {
      ...values,
      logoImageUrl: uploadFile.uploadImage,
      backgroundColor: color.buttonBackground,
      buttonColor: color.button,
      titleColor: color.title,
      descriptionColor: color.description,
      primaryBackgroundColor: color.primary,
      widgetPosition: widgetPosition,
      conditions: {
        url: urls,
      },
      buttonTexts: Object.keys(buttonTitle).reduce((acc, lang) => {
        if (buttonTitle[lang]) {
          acc.push({ language: lang, text: buttonTitle[lang] })
        }
        return acc
      }, []),
      description: Object.keys(description).reduce((acc, lang) => {
        if (description[lang]) {
          acc.push({ language: lang, text: description[lang] })
        }
        return acc
      }, []),
      title: Object.keys(title).reduce((acc, lang) => {
        if (title[lang]) {
          acc.push({ language: lang, text: title[lang] })
        }
        return acc
      }, []),
    }

    let result = await locationActions.saveCoupon(obj, locationObj?._id)
    if (result.success) {
      notifySuccess(getText('TEXT_YOUR_COUPON_SAVED_SUCCESSFULLY'))
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
    setLoading(false)
  }

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields.length > 0) {
      notifyError(errorInfo.errorFields[0].errors[0])
    }
  }

  const getCode = () => {
    let defaultCode = `<!-- Start of Matador Coupon Script --> 
    <script>
      (function(d,t) {
        var g=d.createElement(t),s=d.getElementsByTagName(t)[0];
        g.src=src="${APP_URL}/_coupon.js";
        g.async = true;
        s.parentNode.insertBefore(g,s);
        g.onload=function(){
            matadorCouponWidget("${locationObj?._id}");
        }
      })(document,"script");
    </script>
    <!-- End of Matador Coupon Script -->`

    return defaultCode
  }

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <MyDefaultPageLayout disableHeader>
      <Flex gap={20} align='start' vertical={isPageDrawerSize}>
        <MatForm
          maxWidth
          fullWidth
          form={form}
          formName='formCreateCoupon'
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <MyDefaultBlock fullWidth>
            <SelectDelay
              isForm
              name='appearanceDelaySeconds'
              label={getText('LABEL_POPUP_DELAY')}
              location={locationObj}
              required={false}
            />
            <MyDefaultSwitch
              noText
              isFirst
              isForm
              isLast
              name='showOncePerDayOption'
              titleStyle={{ fontSize: 14 }}
              title={getText('TEXT_SHOW_ONCE_PER_DAY_TO_EACH_USER')}
            />
            <Divider style={{ margin: '12px 0 20px 0' }} />
            <MyDefaultBlockSubtitle subtitle={getText('WORD_VISIBILTY')} />
            <MyDefaultSwitch
              noText
              isFirst
              isForm
              name='showOnMobile'
              titleStyle={{ fontSize: 14 }}
              fullWidth={isMobile}
              title={getText('LABEL_SHOW_WIDGET_MOBILE')}
            />
            <MyDefaultSwitch
              noText
              isForm
              isLast
              name='showOnDesktop'
              titleStyle={{ fontSize: 14 }}
              fullWidth={isMobile}
              title={getText('LABEL_SHOW_WIDGET_DESKTOP')}
              onChangeSwitch={(value) => setShowOnDesktop(value)}
            />
            {showOnDesktop && (
              <MyDefaultWidgetPosition
                widgetPosition={widgetPosition}
                setWidgetPosition={setWidgetPosition}
              />
            )}
            <Divider style={{ margin: '12px 0 20px 0' }} />
            <MyDefaultInsidePageTabs
              isFirst
              onChange={(index) => {
                setSelectedLanguage(+index)
              }}
              items={organization?.languages?.map((lng, index) => {
                return {
                  label: getLongName(lng),
                  key: index,
                  forceRender: true,
                  children: (
                    <React.Fragment>
                      <WidgetPreview
                        language={lng}
                        uploadFile={uploadFile}
                        setUploadFile={setUploadFile}
                        title={title}
                        description={description}
                        buttonTitle={buttonTitle}
                        setTitle={setTitle}
                        setButtonTitle={setButtonTitle}
                        setDescription={setDescription}
                        color={color}
                        setColor={setColor}
                      />
                      <Form.List name={'smsTemplate'}>
                        {(languages) => {
                          return languages.map((field, index) => {
                            return index !== selectedLanguage ? null : (
                              <MyDefaultTextarea
                                key={index}
                                isForm
                                name={[field.name, 'text']}
                                placeholder={getText('TEXT_TYPE_SMS_TEMPLATE')}
                                label={getText('TEXT_SMS_TEMPLATE')}
                                autoSize={{
                                  minRows: 2,
                                  maxRows: 3,
                                }}
                              />
                            )
                          })
                        }}
                      </Form.List>
                    </React.Fragment>
                  ),
                }
              })}
            />
            <MyDefaultInputText
              isForm
              isLast
              name='termsConditionsLink'
              label={getText('WORD_TERMS_LINK')}
              required
            />
          </MyDefaultBlock>
          <MyDefaultBlock fullWidth enableMarginTop>
            <MyDefaultCopyTextarea
              copiedText={getCode()}
              label={getText('WORD_WIDGET_CODE')}
            />
          </MyDefaultBlock>
          <MyDefaultBlock fullWidth enableMarginTop>
            <MyDefaultUrlConditions urls={urls} setUrls={setUrls} />
          </MyDefaultBlock>
        </MatForm>
        <MyDefaultBlock position={'sticky'} top={0} fullWidth>
          <CouponPreview
            uploadFile={uploadFile}
            title={title}
            language={organization?.languages?.[selectedLanguage]}
            color={color}
            description={description}
            buttonTitle={buttonTitle}
          />
        </MyDefaultBlock>
      </Flex>
    </MyDefaultPageLayout>
  )
}

export default LocationsCoupon
