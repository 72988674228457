import React, { useState, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form } from 'antd'
import { getText, getTextServerError } from '../../../../../lang'
import { XTIME } from '../../../../../config/constants'
import authActions from '../../../../../store/modules/authActions'
import { notifyError, notifySuccess } from '../../../../../utils/Notify'
import MyDefaultIntegrationBlockFooter from '../../../../../components/MyDefaultIntegrationBlock/MyDefaultIntegrationBlockFooter/MyDefaultIntegrationBlockFooter'
import xrmActions from '../../../../../store/modules/xrmActions'
import XrmModal from './XrmModal'

const XrmTypeSelector = (props) => {
  const { location, isNewLocation, setLocationObj } = props

  const noneXRM = { value: 'none', label: getText('WORD_NONE') }

  const selectTypeOptions = useMemo(
    () => [noneXRM, { value: XTIME, label: XTIME }],
    []
  )

  const [form] = Form.useForm()
  const navigate = useNavigate()

  const [visible, setVisible] = useState(false)
  const [selectedXrmType, setselectedXrmType] = useState({})
  const [whoConnected, setWhoConnected] = useState({})
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const currentXrm = selectTypeOptions.find(
      (type) => type.value === location.xrmType
    )

    setselectedXrmType(currentXrm || noneXRM)

    setWhoConnected({
      connectedBy: location.xrmConfigs && location.xrmConfigs.connectedBy,
      connectDate: location.xrmConfigs && location.xrmConfigs.connectDate,
    })
  }, [])

  const handeSuccessLocationUpdateResponse = (updatedLocation) => {
    notifySuccess(
      isNewLocation
        ? getText('TEXT_LOCATION_WAS_CREATED_SUCCESSFULLY')
        : getText('TEXT_LOCATION_WAS_UPDATED_SUCCESSFULLY')
    )
    let loc = authActions.getLocation()

    if (updatedLocation._id === (loc && loc._id) || !loc || loc._id === ' ') {
      authActions.setLocation(updatedLocation)
    }

    const xrmTypeObject = selectTypeOptions.find(
      (e) => e.value === updatedLocation.xrmType
    )

    setselectedXrmType(xrmTypeObject || noneXRM)

    setWhoConnected({
      connectedBy:
        updatedLocation.xrmConfigs && updatedLocation.xrmConfigs.connectedBy,
      connectDate:
        updatedLocation.xrmConfigs && updatedLocation.xrmConfigs.connectDate,
    })

    setLocationObj(updatedLocation)
    setVisible(false)
    if (isNewLocation) {
      navigate(`/location/settings/${updatedLocation._id}/edit`, {
        state: { locationObj: updatedLocation },
      })
    }
  }

  const onFinish = async (values) => {
    setLoading(true)
    let obj = { xrmConfigs: { xTime: { dealerCode: '' } }, xrmType: 'none' }

    if (values.xrmType !== 'none') {
      obj = values
    }

    const result = await xrmActions.saveXrm(obj, location._id)

    if (result.success) {
      handeSuccessLocationUpdateResponse(result.data)
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
    setLoading(false)
  }

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields.length > 0) {
      notifyError(errorInfo.errorFields[0].errors[0])
    }
  }

  return (
    <React.Fragment>
      <MyDefaultIntegrationBlockFooter
        enabled={selectedXrmType.value !== 'none'}
        connectedBy={whoConnected.connectedBy}
        account={selectedXrmType.value !== 'none' ? selectedXrmType.label : ''}
        accountType={getText('WORD_CONNECTED_XRM')}
        customButton={
          <XrmModal
            visible={visible}
            setVisible={setVisible}
            form={form}
            selectTypeOptions={selectTypeOptions}
            selectedXrmType={selectedXrmType}
            setselectedXrmType={setselectedXrmType}
            location={location}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            loading={loading}
          />
        }
      />
    </React.Fragment>
  )
}

export default XrmTypeSelector
