import React, { useState, useEffect } from 'react'

const SwipeableComponent = ({ children, onSwipeDown, disableClose }) => {
  const [startY, setStartY] = useState(0)
  const [isSwiping, setIsSwiping] = useState(false)

  useEffect(() => {
    const handleTouchStart = (e) => {
      if (disableClose) return
      setStartY(e.touches[0].clientY)
      setIsSwiping(true)
    }

    const handleTouchMove = (e) => {
      if (!isSwiping || disableClose) return

      const currentY = e.touches[0].clientY
      if (currentY - startY > 50) {
        onSwipeDown()
        setIsSwiping(false)
      }
    }

    const handleTouchEnd = () => {
      setIsSwiping(false)
    }

    window.addEventListener('touchstart', handleTouchStart)
    window.addEventListener('touchmove', handleTouchMove)
    window.addEventListener('touchend', handleTouchEnd)

    return () => {
      window.removeEventListener('touchstart', handleTouchStart)
      window.removeEventListener('touchmove', handleTouchMove)
      window.removeEventListener('touchend', handleTouchEnd)
    }
  }, [startY, isSwiping, onSwipeDown, disableClose])

  return <div className='swipeable-container'>{children}</div>
}

export default SwipeableComponent
