import React, { useCallback, useEffect, useReducer, useState } from 'react'
import { Form, Collapse } from 'antd'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import userActions from '../../../store/modules/userActions'
import authActions from '../../../store/modules/authActions'
import organizationActions from '../../../store/modules/organizationActions'
import { getText, getTextServerError } from '../../../lang'
import {
  getUserRole,
  isDealerSocketConnected,
  moveToLoginPage,
} from '../../../utils'
import SVGArrowCollapse from '../../../icons/SVG/SVGArrowCollapse'
import SVGUserProfile from '../../../icons/SVG/SVGUserProfile'
import SVGRoleAccess from '../../../icons/SVG/SVGRoleAccess'
import SVGNotification from '../../../icons/SVG/SVGNotification'
import SVGLanguageNew from '../../../icons/SVG/SVGLanguageNew'
import { getSessionId } from '../../../utils/sessionStorage'
import MatForm from '../../../components/Form/MatForm'
import MatModal from '../../../components/MatModal'
import { notifyError, notifySuccess } from '../../../utils/Notify'
import InputFormTextSecondary from '../../../components/Form/InputFormText/InputFormTextSecondary'
import { transformData } from '../../../components/api/SelectTreeFormOrganizationLocation'
import SwitchForm from '../../../components/Form/SwitchForm'
import RoleAccessSection from './RoleAccessSection'
import PersonalInformationSection from './PersonalInformationSection'
import NotificationSection from './NotificationSection'
import LanguageSection from './LanguageSection'

const reducerUser = (state, action) => {
  switch (action.type) {
    case 'CHANGE':
      return { ...state, [action.name]: action.payload }
    case 'SET_ROLE':
      return {
        ...state,
        isManager: action.payload === 'isManager',
        isSupportAgent: action.payload === 'isSupportAgent',
        isAdmin: action.payload === 'isAdmin',
        isSuperAdmin: action.payload === 'isSuperAdmin',
      }
    default:
      break
  }
}

const UserModal = (props) => {
  const { userItem = '', onSave, user, organization, visible, setVisible } = props
  const navigate = useNavigate()

  const [saving, setSaving] = useState(false)
  const [userState, dispatch] = useReducer(reducerUser, {
    phone: userItem ? userItem.phone : '',
    email: userItem ? userItem.email : '',
    isManager: userItem ? userItem.isManager : false,
    isAdmin: userItem ? userItem.isAdmin : false,
    isSuperAdmin: userItem ? userItem.isSuperAdmin : false,
    isSupportAgent: userItem ? userItem.isSupportAgent : false,
    _location_id: userItem ? userItem._location_id : user.location?._id,
    accessibleOrganizations: userItem
      ? userItem.accessibleOrganizations.map((org) => {
          return Object.assign(org, {
            value: org._id,
            label: org.name,
          })
        })
      : [],
    language: userItem ? userItem.language : organization?.defaultLanguage || 'en',
  })

  const [treeData, setTreeData] = useState([])
  const [loadingData, setLoadingData] = useState(false)
  const [perPage] = useState(100)

  const [form] = Form.useForm()

  function createObject(obj = [], array) {
    if (typeof obj === 'object') {
      obj = obj.list
    }
    const result = {}
    for (const item of obj) {
      const id = item.id

      if (Array.isArray(array) && array.includes(id)) {
        result[id] = []
      } else {
        for (const location of item.children) {
          const orgId = location._organization_id
          const childId = location.id
          if (Array.isArray(array) && array.includes(childId)) {
            if (!result[orgId]) {
              result[orgId] = []
            }
            result[orgId].push(location.id)
          }
        }
      }
    }
    return result
  }

  const arrayToObjectNotification = (array) => {
    const result = {}
    for (const item of array) {
      result[item] = []
    }
    return result
  }

  let selectedUser = userItem || user

  const defaultaccessibleOrganizations = {
    id: user.organization.id,
    _id: user.organization?._id,
    label: user.organization.name,
    name: user.organization.name,
    value: user.organization?._id,
  }

  const fill = useCallback(async () => {
    setLoadingData(true)
    const gettingAccessibleOrganizations = userState.accessibleOrganizations.length
      ? userState.accessibleOrganizations
      : [defaultaccessibleOrganizations]
    const accessibleOrganizations =
      (gettingAccessibleOrganizations &&
        gettingAccessibleOrganizations.length &&
        gettingAccessibleOrganizations.map((item) => item._id)) ||
      []
    let arr = []
    let total = 0
    let page = 0
    const getData = async () => {
      const result = await organizationActions.getOrganizationsLocationsList(
        page,
        perPage,
        '',
        {
          enabled: true,
        },
        userState.isSuperAdmin ? false : accessibleOrganizations,
        getUserRole(
          selectedUser.isSuperAdmin,
          selectedUser.isAdmin,
          selectedUser.isManager,
          selectedUser.isSupportAgent
        ),
        selectedUser._location_id
      )
      if (result.success) {
        const list = transformData(result.data, true)
        arr = page === 0 ? list : [...arr, ...list]
        total = result.max
        if (arr.length < result.max) {
          page++
          await getData()
        }
        if (userState._location_id) {
          let currentSelectedLocOrgExist = false
          for (const item of arr) {
            const find = item?.children?.find((i) => i.id === userState._location_id)
            if (find) {
              currentSelectedLocOrgExist = true
              break
            }
          }

          if (!currentSelectedLocOrgExist) {
            dispatch({
              type: 'CHANGE',
              name: '_location_id',
              payload: '',
            })
          }
        }
      }
    }
    await getData()
    setTreeData({
      list: arr,
      total: total,
    })
    setLoadingData(false)
  }, [
    userState.accessibleOrganizations,
    selectedUser.isSuperAdmin,
    selectedUser.isAdmin,
    selectedUser.isManager,
    selectedUser.isSupportAgent,
    selectedUser._location_id,
    userState.isSuperAdmin,
  ])

  useEffect(() => {
    fill()
  }, [fill])

  const onFinish = async (values) => {
    if (!getSelectedRole()) {
      return notifyError(getText('ERR_NO_SELECTED_ROLE'))
    }
    if (!values.phone && !values.email) {
      return notifyError(getText('ERR_NO_PHONE_OR_EMAIL'))
    }

    const allValues = form.getFieldsValue(true)
    const notificationsObjToSend = JSON.parse(
      JSON.stringify(allValues.notifications)
    )

    setSaving(true)
    let result
    values.crmInfo = {
      dealerSocket: {
        username: values.dsUsername || '',
      },
    }
    delete values.dsUsername

    const accessibleOrganizations = values.accessibleOrganizations
      ? values.accessibleOrganizations
      : userItem
        ? userItem.accessibleOrganizations
        : []

    const accessibleOrganizationsIdsArray = accessibleOrganizations.map((ids) => {
      if (ids._id) {
        return ids._id
      }
      return ids
    })

    if (allValues.notifications.unreadMessage.enabled) {
      const changedData = createObject(
        treeData,
        allValues.notifications.unreadMessage.organizations
      )
      notificationsObjToSend.unreadMessage.organizations = changedData
    } else {
      notificationsObjToSend.unreadMessage.organizations = {}
    }

    if (allValues.notifications.assignToConversation.enabled) {
      const changedData = arrayToObjectNotification(
        allValues.notifications.assignToConversation.organizations
      )
      notificationsObjToSend.assignToConversation.organizations = changedData
    } else {
      notificationsObjToSend.assignToConversation.organizations = {}
    }

    if (allValues.notifications.unassignFromConversation.enabled) {
      const changedData = createObject(
        treeData,
        allValues.notifications.unassignFromConversation.organizations
      )
      notificationsObjToSend.unassignFromConversation.organizations = changedData
    } else {
      notificationsObjToSend.unassignFromConversation.organizations = {}
    }

    if (allValues.notifications.paymentStatus.enabled) {
      const changedData = arrayToObjectNotification(
        allValues.notifications.paymentStatus.organizations
      )
      notificationsObjToSend.paymentStatus.organizations = changedData
    } else {
      notificationsObjToSend.paymentStatus.organizations = {}
    }

    if (allValues.notifications.unhappyCustomer.enabled) {
      const changedData = createObject(
        treeData,
        allValues.notifications.unhappyCustomer.organizations
      )
      notificationsObjToSend.unhappyCustomer.organizations = changedData
    } else {
      notificationsObjToSend.unhappyCustomer.organizations = {}
    }

    if (allValues.notifications.taggedInConversation.enabled) {
      const changedData = arrayToObjectNotification(
        allValues.notifications.taggedInConversation.organizations
      )
      notificationsObjToSend.taggedInConversation.organizations = changedData
    } else {
      notificationsObjToSend.taggedInConversation.organizations = {}
    }

    const obj = {
      ...values,
      phone: values.phone === '1' ? '' : values.phone,
      accessibleOrganizations: accessibleOrganizationsIdsArray,
      enabled: userItem ? values.enabled : true,
      isAdmin: userState.isSuperAdmin || userState.isAdmin,
      isManager: userState.isManager,
      isSupportAgent: userState.isSupportAgent,
      isSuperAdmin: userState.isSuperAdmin,
      language: userState.language,
      notifications: notificationsObjToSend,
    }

    if (userState._location_id) {
      obj._location_id = userState._location_id
    }

    if (
      userItem.notifications &&
      userItem.notifications.devices &&
      userItem.notifications.devices.length > 0
    ) {
      obj.notifications.devices = userItem.notifications.devices
    }

    if (userItem && userItem._id) {
      obj.sessionId = getSessionId()
      result = await userActions.saveUpdate(obj, userItem._id)
    } else {
      result = await userActions.saveNew(obj)
    }

    if (result && result.success) {
      const { user: savedUser, token } = result.data
      notifySuccess(
        !userItem
          ? getText('NTF_USER_WAS_CREATED_SUCCESSFULLY')
          : getText('NTF_USER_WAS_SAVED_SUCCESSFULLY')
      )

      if (userItem && userItem._id === user?._id) {
        authActions.setUserData(savedUser)
        if (savedUser.isSupportAgent || !savedUser.enabled) {
          moveToLoginPage(navigate)
          localStorage.clear()
        }
      }

      if (token) {
        authActions.storeAuthData(savedUser, token, authActions.getUnreadMessages())
      }
      setSaving(false)
      setVisible(false)
      onSave && onSave(userItem._id)
    } else {
      if (result.errCode === 409 && result.errors) {
        let errStack = []
        result.errors.forEach((errObj) => {
          errObj.messages.forEach((message) => {
            errStack.push(result.errMsg + ': ' + message)
          })
        })
        notifyError(errStack.length > 0 ? errStack.join('. ') : result.errMsg)
      } else {
        notifyError(getTextServerError(result.errMsg))
      }
      setSaving(false)
    }
  }

  const onFinishFailed = (errorInfo) => {
    if (!userState.phone && !userState.email) {
      return notifyError(getText('ERR_NO_PHONE_OR_EMAIL'))
    }
    if (errorInfo.errorFields.length > 0) {
      notifyError(getTextServerError(errorInfo.errorFields[0].errors[0]))
    }
  }

  const getRolesOptions = () => {
    const roles = [
      {
        label: getText('WORD_ROLE_SUPER_ADMIN'),
        value: 'isSuperAdmin',
      },
      {
        label: getText('WORD_ROLE_ADMIN'),
        value: 'isAdmin',
      },
      {
        label: getText('WORD_ROLE_MANAGER'),
        value: 'isManager',
      },
      {
        label: getText('WORD_ROLE_SUPPORT'),
        value: 'isSupportAgent',
      },
    ]
    if (user.isSuperAdmin) {
      return roles
    } else if (user.isAdmin && !user.isSuperAdmin) {
      return roles.splice(1)
    } else if (user.isManager) {
      return roles.splice(2)
    }
    return roles.splice(3)
  }

  const getLanguages = () => {
    const languages = [
      {
        value: 'en',
        label: getText('TEXT_ENGLISH'),
      },
      {
        value: 'fr',
        label: getText('TEXT_FRENCH'),
      },
    ]
    return languages
  }

  const handleMethodTypeChange = (type) => {
    const notifications = form.getFieldValue('notifications')

    ;[
      'unreadMessage',
      'assignToConversation',
      'unassignFromConversation',
      'paymentStatus',
      'integrationDisconnected',
      'unhappyCustomer',
      'taggedInConversation',
    ].forEach((notificationName) => {
      if (
        notifications[notificationName].enabled &&
        notifications[notificationName].method.includes(type)
      ) {
        form.setFieldsValue({
          notifications: {
            ...notifications,
            [notificationName]: {
              ...notifications[notificationName],
              method: notifications[notificationName].method.filter(
                (method) => method !== type
              ),
            },
          },
        })
      }
    })
  }

  const getSelectedRole = () => {
    return ['isSuperAdmin', 'isAdmin', 'isManager', 'isSupportAgent'].find(
      (role) => userState[role]
    )
  }

  const getSelectedLanguage = () => {
    return userState['language'] || 'en'
  }

  const handleAccessibleOrganizationsChange = (orgIds, item) => {
    dispatch({
      type: 'CHANGE',
      name: 'accessibleOrganizations',
      payload: item,
    })
  }

  const getNotificationOrganization = (org, field, fromError = false) => {
    if (_.isEmpty(org)) {
      if (fromError) {
        form.setFieldValue(['notifications', field, 'organizations'], [])
      }
      return []
    } else {
      const resultArr = []
      for (const key in org) {
        if (org[key].length === 0) {
          resultArr.push(key)
        } else {
          resultArr.push(...org[key])
        }
      }
      if (fromError) {
        form.setFieldValue(['notifications', field, 'organizations'], resultArr)
      }
      return resultArr
    }
  }

  const items = [
    {
      key: '1',
      label: (
        <>
          <SVGUserProfile style={{ marginRight: 10 }} />
          {getText('WORD_PERSONAL_INFORMATION')}
        </>
      ),
      children: (
        <PersonalInformationSection
          organization={organization}
          handleMethodTypeChange={handleMethodTypeChange}
          form={form}
          dispatch={dispatch}
        />
      ),
    },
    {
      key: '2',
      label: (
        <>
          <SVGRoleAccess style={{ marginRight: 10 }} />
          {getText('WORD_ROLE_ACCESS')}
        </>
      ),
      children: (
        <RoleAccessSection
          dispatch={dispatch}
          userItem={userItem}
          user={user}
          handleAccessibleOrganizationsChange={handleAccessibleOrganizationsChange}
          userState={userState}
          getSelectedRole={getSelectedRole}
          getRolesOptions={getRolesOptions}
          treeData={treeData.list || []}
          loadingData={loadingData}
        />
      ),
    },
    {
      key: '4',
      label: (
        <>
          <SVGLanguageNew style={{ marginRight: 10 }} />
          {getText('WORD_LANGUAGE')}
        </>
      ),
      children: (
        <LanguageSection
          dispatch={dispatch}
          getSelectedLanguage={getSelectedLanguage}
          getRolesOptions={getLanguages}
        />
      ),
    },
    {
      key: '3',
      label: (
        <>
          <SVGNotification style={{ marginRight: 10 }} />
          {getText('WORD_NOTIFICATIONS')}
        </>
      ),
      children: (
        <NotificationSection
          isNew={!Boolean(userItem)}
          form={form}
          userState={userState}
          organization={organization}
          userId={userItem._id}
          treeData={treeData.list || []}
        />
      ),
    },
  ]

  return (
    <MatModal
      noTrigger
      title={
        userItem
          ? getText('WORD_EDIT_USER') + ' ' + userItem.fullName
          : getText('ACTION_CREATE_USER')
      }
      formName={userItem ? 'formSaveUser_' + userItem._id : 'formSaveUser'}
      confirmLoading={saving}
      visible={visible}
      forceRender={false}
      onCancel={() => {
        form.resetFields()
        dispatch({ type: 'SET_ROLE', payload: '' })
        setVisible(false)
      }}
      okText={getText('ACTION_SAVE')}
      className='users-page-edit-modal'
    >
      <MatForm
        form={form}
        name={userItem ? 'formSaveUser_' + userItem._id : 'formSaveUser'}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{
          firstName: userItem ? userItem.firstName : '',
          lastName: userItem ? userItem.lastName : '',
          phone: userItem ? userItem.phone : '',
          email: userItem ? userItem.email : '',
          accessibleOrganizations: userItem
            ? userItem.accessibleOrganizations.map((org) => {
                return Object.assign(org, {
                  value: org._id,
                  label: org.name,
                })
              })
            : [],
          canDisableUsers: userItem ? userItem.canDisableUsers : false,
          enabled: userItem ? userItem.enabled : true,
          notifications: {
            unreadMessage: {
              enabled: userItem
                ? userItem.notifications.unreadMessage.enabled
                : false,
              method:
                userItem && userItem.notifications.unreadMessage.enabled
                  ? userItem.notifications.unreadMessage.method
                  : [],
              period: userItem ? userItem.notifications.unreadMessage.period : 1,
              organizations: userItem
                ? getNotificationOrganization(
                    userItem.notifications.unreadMessage.organizations,
                    'unreadMessage'
                  )
                : [],
            },
            unassignFromConversation: {
              enabled: userItem
                ? userItem.notifications.unassignFromConversation.enabled
                : false,
              method:
                userItem && userItem.notifications.unassignFromConversation.enabled
                  ? userItem.notifications.unassignFromConversation.method
                  : [],
              period: userItem
                ? userItem.notifications.unassignFromConversation.period
                : 1,
              organizations: userItem
                ? getNotificationOrganization(
                    userItem.notifications.unassignFromConversation.organizations,
                    'unassignFromConversation'
                  )
                : [],
            },
            assignToConversation: {
              enabled: userItem
                ? userItem.notifications.assignToConversation.enabled
                : false,
              method:
                userItem && userItem.notifications.assignToConversation.enabled
                  ? userItem.notifications.assignToConversation.method
                  : [],
              organizations: userItem
                ? getNotificationOrganization(
                    userItem.notifications.assignToConversation.organizations,
                    'assignToConversation'
                  )
                : [],
            },
            paymentStatus: {
              enabled: userItem
                ? userItem.notifications.paymentStatus.enabled
                : false,
              method:
                userItem && userItem.notifications.paymentStatus.enabled
                  ? userItem.notifications.paymentStatus.method
                  : [],
              organizations: userItem
                ? getNotificationOrganization(
                    userItem.notifications.paymentStatus.organizations,
                    'paymentStatus'
                  )
                : [],
            },
            integrationDisconnected: {
              enabled: userItem
                ? userItem.notifications.integrationDisconnected.enabled
                : false,
            },
            unhappyCustomer: {
              enabled: userItem
                ? userItem.notifications.unhappyCustomer.enabled
                : false,
              organizations: userItem
                ? getNotificationOrganization(
                    userItem.notifications.unhappyCustomer.organizations,
                    'unhappyCustomer'
                  )
                : [],
            },
            doNotDisturb: userItem ? userItem.notifications.doNotDisturb : false,
            taggedInConversation: {
              enabled: userItem
                ? userItem?.notifications?.taggedInConversation?.enabled
                : true,
              method:
                userItem && userItem.notifications.taggedInConversation.enabled
                  ? userItem.notifications.taggedInConversation.method
                  : [],
              organizations: userItem
                ? getNotificationOrganization(
                    userItem.notifications.taggedInConversation.organizations,
                    'taggedInConversation'
                  )
                : [],
            },
          },
          restrictions: {
            allowReceiveNewsletters: userItem
              ? userItem.restrictions?.allowReceiveNewsletters
              : false,
          },
          dsUsername: userItem ? userItem.crmInfo.dealerSocket.username : '',
        }}
      >
        {userItem &&
          userItem.canDisableUsers &&
          userState.isSuperAdmin &&
          !userItem.accessibleOrganizations.length && (
            <SwitchForm
              name='canDisableUsers'
              text={getText('WORD_CAN_DISABLE_SUPER_ADMINS')}
            />
          )}
        {userItem && !userState.isSuperAdmin && (
          <SwitchForm name='enabled' text={getText('WORD_ENABLED')} />
        )}
        <Collapse
          ghost
          expandIconPosition='end'
          className='notification-collapse-wrapper'
          expandIcon={({ isActive }) => (
            <div className='arrow-wrapper'>
              <SVGArrowCollapse rotate={isActive ? 0 : 180} />
            </div>
          )}
          items={items}
          defaultActiveKey={['1', '2', '3']}
        />
        {isDealerSocketConnected(userItem && userItem.location) && (
          <InputFormTextSecondary
            name='dsUsername'
            label={getText('WORD_DEALERSOCKET_USERNAME')}
            formClassName='dealerSocket-input'
          />
        )}
      </MatForm>
    </MatModal>
  )
}

export default UserModal
