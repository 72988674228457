import { createContext, useContext, useEffect, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { GET_DATA_FETCH_SIZE } from '../utils'
import { idOrganizationSelector } from '../store/selectors/selectors'
import userActions from '../store/modules/userActions'
import { getUsersListIds } from '../views/broadcast/CreateCampaign/CreateSegment/utils'

const UsersContext = createContext(null) // Default to null

export const UsersProvider = ({ children }) => {
  const _idOrganization = useSelector(idOrganizationSelector)

  const [listData, setListData] = useState({ maxUsers: 0, list: [] })
  const [page, setPage] = useState(0)
  const [assignSearchKeyword, setAssignSearchKeyword] = useState('')
  const [loading, setLoading] = useState(false)

  const fill = useCallback(async () => {
    if (loading) return
    setLoading(true)
    const result = await userActions.getUsersForAssignUnassign(
      page,
      GET_DATA_FETCH_SIZE,
      _idOrganization,
      assignSearchKeyword
    )
    if (result.success) {
      const newListUsers =
        page > 0 ? [...listData.list, ...result.data] : result.data
      let stateValue = {
        users: getUsersListIds(newListUsers),
        maxUsers: result.max,
      }
      setListData({
        list: stateValue.users,
        max: stateValue.maxUsers,
      })
    }
    setLoading(false)
  }, [assignSearchKeyword, page])

  useEffect(() => {
    fill()
  }, [fill])

  return (
    <UsersContext.Provider
      value={{
        listData,
        setPage,
        setListData,
        page,
        setAssignSearchKeyword,
        assignSearchKeyword,
      }}
    >
      {children}
    </UsersContext.Provider>
  )
}

export const useUsers = () => useContext(UsersContext)
