import React from 'react'
import 'react-phone-input-2/lib/style.css'
import { Flex } from 'antd'
import Div from '../../../../../../components/Div/Div'
import MyDefaultPhoneInput from '../../../../../../components/MyDefaultPhoneInput/MyDefaultPhoneInput'
import './IntentPreview.scss'

const IntentPreview = ({
  uploadFile,
  title,
  language,
  color,
  description,
  buttonTitle,
  header,
  termsLink,
  defaultImageUrl,
}) => {
  return (
    <Flex
      style={{
        backgroundColor: color.primary,
      }}
      justify='space-between'
    >
      <Flex vertical justify='center' className='intent_preview_left'>
        <p
          className='header'
          style={{
            color: color.header,
          }}
        >
          {header[language] || ''}
        </p>
        <p
          className='title'
          style={{
            color: color.title,
          }}
        >
          {title[language] || ''}
        </p>
        <p
          className='description'
          style={{
            color: color.description,
          }}
        >
          {description[language] || ''}
        </p>

        <MyDefaultPhoneInput />
        <button
          style={{
            background: color.buttonBackground,
            color: color.button,
          }}
          className='button'
        >
          {buttonTitle[language] || ''}
        </button>
        <p className='terms'>
          I agree to receive news, updates &amp; other promotional information from
          Matador, and understand that I can withdraw it at any time.
          <a href={termsLink || 'https://matador.ai/'}>Privacy Policy</a>
        </p>
        <Flex align='center' className={'copyright'} justify='center'>
          <p>Powered By</p>
          <img src={defaultImageUrl} alt='logo' />
        </Flex>
      </Flex>
      {uploadFile?.uploadImage && (
        <Div
          className={'intent_preview_right'}
          style={{ backgroundColor: color.buttonBackground }}
        >
          <img src={uploadFile.uploadImage} alt='cars' />
        </Div>
      )}
    </Flex>
  )
}

export default IntentPreview
