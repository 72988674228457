import React from 'react'
import { Flex } from 'antd'
import { getText } from '../../lang'
import MyDefaultBlockSubtitle from '../MyDefaultBlock/MyDefaultBlockSubtitle'
import MyDefaultInputNumber from '../MyDefaultInputNumber/MyDefaultInputNumber'
import MyDefaultSelect from '../MyDefaultSelect/MyDefaultSelect'
import './MyDefaultSendFollowUp.scss'

const MyDefaultSendFollowUp = ({
  title = getText('TEXT_SEND_FOLLOWUP'),
  beforeText,
  isForm = true,
  options,
  afterText,
  inputName,
  selectName,
  hideNumber,
  inputInitialValue,
  selectInitialValue,
  onChangeInput,
  onChangeSelect,
  min,
  max,
  minMessage,
  maxMessage,
  errorMessage,
  disableMargin = true,
  vertical = false,
  disableInput,
  disableSelect,
  hideSelect,
}) => {
  return (
    <React.Fragment>
      <MyDefaultBlockSubtitle subtitle={title} />
      <Flex
        align='center'
        gap={8}
        className='my_default_send_follow_up'
        style={{ marginBottom: disableMargin ? 0 : 24 }}
        vertical={vertical}
      >
        <span className='my_default_send_follow_up--text'>{beforeText}</span>
        <Flex align='center' gap={8}>
          {Boolean(!hideNumber) && (
            <MyDefaultInputNumber
              required
              isForm={isForm}
              name={inputName}
              disabled={disableInput}
              initialValue={inputInitialValue}
              onChange={onChangeInput}
              min={min}
              max={max}
              minLength={min}
              maxLength={max}
              maxMessage={maxMessage}
              minMessage={minMessage}
              errorMessage={errorMessage}
            />
          )}
          {Boolean(!hideSelect) && (
            <MyDefaultSelect
              required
              isForm={isForm}
              options={options}
              label={''}
              disabled={disableSelect}
              name={selectName}
              initialValue={selectInitialValue}
              onChange={onChangeSelect}
            />
          )}
        </Flex>
        <span className='my_default_send_follow_up--text'>{afterText}</span>
      </Flex>
    </React.Fragment>
  )
}

export default MyDefaultSendFollowUp
