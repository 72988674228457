import React from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Flex, Image } from 'antd'
import { MEDIATYPES_ONLY_IMAGE } from '../../../utils'
import SVGMyDefaultEditIcon from '../../../icons/SVG/SVGMyDefaultEditIcon'
import MyDefaultUpload from '../MyDefaultUpload'
import LoadingSpinner from '../../LoadingSpinner'
import './MyDefaultUploadAvatar.scss'

const MyDefaultUploadAvatar = ({
  listType = 'picture-circle',
  wrapperSize = 'big',
  enableMarginTop,
  disabled,
  loading,
  color,
  uploadedItemObj,
  ...restProps
}) => {
  return (
    <MyDefaultUpload
      {...restProps}
      listType={listType}
      mediaTypes={MEDIATYPES_ONLY_IMAGE}
      className={`my_default_upload_circle_wrapper ${wrapperSize} ${color || ''} ${enableMarginTop ? 'margin_top' : ''}`}
      disabled={disabled || loading}
    >
      {loading ? (
        <LoadingSpinner withoutHeight />
      ) : uploadedItemObj?.url ? (
        <Flex className='my_default_upload_circle_wrapper--image_item'>
          <Image src={uploadedItemObj?.url} preview={false} />
          <Flex align='center' justify='center' className='pencil'>
            <SVGMyDefaultEditIcon />
          </Flex>
        </Flex>
      ) : (
        <PlusOutlined />
      )}
    </MyDefaultUpload>
  )
}

export default MyDefaultUploadAvatar
