import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { notifyError, notifySuccess } from '../../../../utils/Notify'
import { getText, getTextServerError } from '../../../../lang'
import MyDefaultBlockHeader from '../../../../components/MyDefaultBlock/MyDefaultBlockHeader'
import MyDefaultBlockRow from '../../../../components/MyDefaultBlock/MyDefaultBlockRow'
import { OUTBOUND } from '../../../../devOptions'
import voiceAIActions from '../../../../store/modules/voiceAIActions'
import VoiceAIBlockRowRight from './VoiceAIBlockRowRight'

const VoiceAIListItem = ({ item, organization, type }) => {
  const navigate = useNavigate()

  const [sendToCRMEnabled, setsendToCRMEnabled] = useState(item.sendToCRM)
  const [callTransfersEnabled, setcallTransfersEnabled] = useState(
    item.callTransfersEnabled
  )

  const handleChangeSendToCrm = async (val) => {
    setsendToCRMEnabled(val)
    const result = await voiceAIActions.updateVapiAssistant(
      {
        sendToCRM: val,
      },
      item._id,
      type
    )
    if (result.success) {
      notifySuccess(getText('TEXT_ASSISTANT_WAS_UPDATED'))
    } else {
      setsendToCRMEnabled(!val)
      notifyError(getTextServerError(result.errMsg))
    }
  }

  const handleChangeCallTransfers = async (val) => {
    setcallTransfersEnabled(val)
    const result = await voiceAIActions.updateVapiAssistant(
      {
        callTransfersEnabled: val,
      },
      item._id,
      type
    )
    if (result.success) {
      notifySuccess(getText('TEXT_ASSISTANT_WAS_UPDATED'))
    } else {
      setcallTransfersEnabled(!val)
      notifyError(getTextServerError(result.errMsg))
    }
  }

  useEffect(() => {
    setsendToCRMEnabled(item.sendToCRM)
    setcallTransfersEnabled(item.callTransfersEnabled)
  }, [item])

  return (
    <React.Fragment>
      <MyDefaultBlockHeader
        label={item.assistantName}
        onClick={() => {
          navigate(
            `/organization/settings/${organization?._id}/voice_ai/${type}/edit`,
            {
              state: { ...item, type: type },
            }
          )
        }}
      />
      <MyDefaultBlockRow
        label={getText('WORD_STATUS')}
        value={
          <VoiceAIBlockRowRight isActive={item.enabled} isNoCalls={item.isNoCalls} />
        }
      />
      <MyDefaultBlockRow
        label={getText('WORD_AI_NUMBER')}
        value={item.phoneNumber}
      />
      {type !== OUTBOUND && (
        <MyDefaultBlockRow
          label={getText('TEXT_CONNECTED_LOCATION')}
          value={item?.location?.name || ''}
        />
      )}
      <MyDefaultBlockRow
        label={getText('FORWARD_NEW_LEADS_TO_CRM')}
        value={sendToCRMEnabled}
        isSwitch
        onChange={handleChangeSendToCrm}
        isLast={type === OUTBOUND}
      />
      {type !== OUTBOUND && (
        <MyDefaultBlockRow
          label={getText('WORD_CALL_TRANSFERS')}
          value={callTransfersEnabled}
          isSwitch
          onChange={handleChangeCallTransfers}
          isLast
        />
      )}
    </React.Fragment>
  )
}

export default VoiceAIListItem
