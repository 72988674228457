// import langData from './en';
// import langData from './fr';

import authActions from '../store/modules/authActions'

export const LANGS = [
  'en',
  'fr',
  // "sp",
]

export function getText(textKey, lang) {
  // const userLang = 'fr'
  const userLang = authActions.getLanguage()

  if (!lang) {
    lang = userLang
  }
  const langData = module.require(`./${lang}`).default
  let str = langData[textKey]

  // Insert server call here (optional)

  if (str) {
    return str
  } else {
    console.log(
      `getText : %c${textKey} doesnt exist in ${userLang}.js`,
      'color: red'
    )
    return '<' + textKey + '>'
  }
}

export function getTextServerError(errMSG, param) {
  if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith('child "twilio_number" fails because'.trim().toLowerCase())
  ) {
    return 'The Phone field should contain 11 digits.'
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith('child "email" fails because'.trim().toLowerCase())
  ) {
    return 'This email address is invalid'
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith('child "firstName" fails because'.trim().toLowerCase())
  ) {
    return 'First and Last names are required.'
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith('child "lastName" fails because'.trim().toLowerCase())
  ) {
    return 'First and Last names are required.'
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith(
        'child "termsLink" fails because ["termsLink" must be a valid uri]'
          .trim()
          .toLowerCase()
      )
  ) {
    return 'This Terms Link is invalid.'
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith(
        '"value" must contain at least one of [phone, email]'.trim().toLowerCase()
      )
  ) {
    return 'Please enter a phone number or an email.'
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith(
        '"hild "id" fails because ["id" with value "props.item._id" fails to match the required pattern: /^[a-fA-F0-9]{24}$/]'
          .trim()
          .toLowerCase()
      )
  ) {
    return 'Payment refund failed'
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith('Touchpoint with this name exists'.trim().toLowerCase())
  ) {
    return 'Cannot save changes. Touchpoint with this name exists'
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith(
        'Validation error: default language. doesn`t include in organization languages.'
          .trim()
          .toLowerCase()
      )
  ) {
    return "The default language is not included in the organization's list of languages"
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith(
        'child "defaultLanguage" fails because ["defaultLanguage" is not allowed to be empty]'
          .trim()
          .toLowerCase()
      )
  ) {
    return getText('MSG_PLEASE_SELECT_DEFAULT_LANGUAGE')
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith(
        'child "notificationsPeriod" fails because ["notificationsPeriod" must be larger than or equal to 15]'
          .trim()
          .toLowerCase()
      )
  ) {
    return 'Notifications time must be larger than or equal to 15 minutes'
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith(
        'child "newPassword" fails because ["newPassword" length must be at least 8 characters long]'
          .trim()
          .toLowerCase()
      )
  ) {
    return 'New password length must be at least 8 characters long'
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith(
        'child "name" fails because ["name" is not allowed to be empty]'
          .trim()
          .toLocaleLowerCase()
      )
  ) {
    return param ? 'Please enter tag name' : 'Name of broadcast cannot be empty'
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith('Validation error: Template with name'.trim().toLocaleLowerCase())
  ) {
    return 'Template with such a name already exists'
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith(
        'E11000 duplicate key error collection: wingage.organizations index:'
          .trim()
          .toLocaleLowerCase()
      )
  ) {
    return 'The organization with the same name already exists'
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith(
        'child "imageUrl" fails because ["imageUrl" must be a valid uri]'
          .trim()
          .toLocaleLowerCase()
      )
  ) {
    return 'The Image Link is invalid.'
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith(
        'child "videoUrl" fails because ["videoUrl" must be a valid uri]'
          .trim()
          .toLocaleLowerCase()
      )
  ) {
    return 'The Video Link is invalid.'
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith(
        'child "url" fails because ["url" must be a valid uri]'
          .trim()
          .toLocaleLowerCase()
      )
  ) {
    return 'The Url Link is invalid.'
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith(
        'child "imageUrl" fails because ["imageUrl" is not allowed to be empty]'
          .trim()
          .toLocaleLowerCase()
      )
  ) {
    return "The Image Link can't be an empty string."
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith(
        'child "URL" fails because ["URL" is not allowed to be empty]'
          .trim()
          .toLocaleLowerCase()
      )
  ) {
    return "The URL field can't be an empty string"
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith(
        'child "startDate" fails because ["startDate" must be a number of milliseconds or valid date string]'
          .trim()
          .toLocaleLowerCase()
      )
  ) {
    return 'You must pick a start date'
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith(
        'child "endDate" fails because ["endDate" must be a number of milliseconds or valid date string]'
          .trim()
          .toLocaleLowerCase()
      )
  ) {
    return 'You must pick an end date'
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith('because ["sp" is not allowed]'.trim().toLocaleLowerCase())
  ) {
    return 'Spanish Language is not allowed'
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith('because ["fr" is not allowed]'.trim().toLocaleLowerCase())
  ) {
    return 'French Language is not allowed'
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith('because ["en" is not allowed]'.trim().toLocaleLowerCase())
  ) {
    return 'English Language is not allowed'
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith(
        'child "newTags" fails because ["newTags" at position 0 fails because [child "name" fails because ["name" is not allowed to be empty]]]'
          .trim()
          .toLocaleLowerCase()
      )
  ) {
    return 'Please fill tag name'
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith(
        'child "languages" fails because ["languages" must contain at least 2 items]'
          .trim()
          .toLocaleLowerCase()
      )
  ) {
    return getText('ERR_NO_ORGANIZATION_LANGUAGE_REQUIRED')
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith(
        'child "scheduleAt" fails because ["scheduleAt" must be greater than'
          .trim()
          .toLocaleLowerCase()
      )
  ) {
    return 'Please, set up the correct time for the scheduled message'
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith(
        'child "appearanceDelaySeconds" fails because ["appearanceDelaySeconds" must be a number]'
          .trim()
          .toLocaleLowerCase()
      )
  ) {
    return 'Please select a delay for the Coupon!'
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith(
        'child "title" fails because ["title" must contain at least 1 items]'
          .trim()
          .toLocaleLowerCase()
      )
  ) {
    return 'Please input the title for Coupon!'
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith(
        'child "description" fails because ["description" must contain at least 1 items]'
          .trim()
          .toLocaleLowerCase()
      )
  ) {
    return 'Please input the description for Coupon!'
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith(
        'child "buttonTexts" fails because ["buttonTexts" must contain at least 1 items]'
          .trim()
          .toLocaleLowerCase()
      )
  ) {
    return 'Please input text for the button!'
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith(
        'child "notifyAt" fails because ["notifyAt" must be greater than'
          .trim()
          .toLocaleLowerCase()
      )
  ) {
    return getText('TEXT_IT_IS_NOT_POSSIBLE_TO_CREATE_REMINDER')
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.includes('Should have only allowed tags'.toLocaleLowerCase())
  ) {
    return getText('ERROR_USE_ONLY_DYNAMIC_TAGS_FROM_THE_LIST_OF_SUGGESTED_ONES')
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith(
        "'auto_reply_items.0.minimumTimeBetweenMessages.value' cannot be less than 3"
          .trim()
          .toLocaleLowerCase()
      )
  ) {
    return 'Please select as minimum 3 days'
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith(
        'child "vinSolution" fails because [child "dealerId" fails because ["dealerId" must be a number]]'
          .trim()
          .toLocaleLowerCase()
      )
  ) {
    return 'Please input the DEALER ID'
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith(
        'child "vinSolution" fails because [child "userId" fails because ["userId" must be a number]]'
          .trim()
          .toLocaleLowerCase()
      )
  ) {
    return 'Please input the USER'
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith(
        'child "eLeads" fails because ["dealerId" is not allowed, "userId" is not allowed, "user" is not allowed, "isSourceMatador" is not allowed]'
          .trim()
          .toLocaleLowerCase()
      )
  ) {
    return 'Please input the SUBSCRIPTION ID'
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith(
        'child "pbs" fails because ["dealerId" is not allowed, "quoteId" is not allowed]'
          .trim()
          .toLocaleLowerCase()
      )
  ) {
    return 'Please input the SERIAL NUMBER'
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith(
        'child "dealerSocket" fails because [child "dealerNumber" fails because ["dealerNumber" is required]]'
          .trim()
          .toLocaleLowerCase()
      )
  ) {
    return 'Please input the DEALER NUMBER'
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith(
        'child "oneEighty" fails because [child "dealerId" fails because ["dealerId" must be a string]]'
          .trim()
          .toLocaleLowerCase()
      )
  ) {
    return 'Please input the DEALER ID'
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith(
        'child "oneEighty" fails because ["oneEighty" must be an object]]'
          .trim()
          .toLocaleLowerCase()
      )
  ) {
    return 'Please input DEALER ID and QUOTE ID'
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith(
        'child "pbs" fails because [child "serialNumber" fails because ["serialNumber" is not allowed to be empty]]'
          .trim()
          .toLocaleLowerCase()
      )
  ) {
    return 'Please input the SERIAL NUMBER'
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith(
        'child "activix" fails because [child "account_id" fails because ["account_id" is not allowed to be empty]]'
          .trim()
          .toLocaleLowerCase()
      )
  ) {
    return 'Please input the ACCOUNT ID'
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith(
        'child "activix" fails because [child "api_key" fails because ["api_key" is not allowed to be empty]]'
          .trim()
          .toLocaleLowerCase()
      )
  ) {
    return 'Please input the API KEY'
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith(
        'child "languages" fails because ["languages" must contain less than or equal to 2 items]'
          .trim()
          .toLocaleLowerCase()
      )
  ) {
    return getText('ERR_NO_ORGANIZATION_LANGUAGE_REQUIRED')
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith(
        'child "messagingServiceSid" fails because ["messagingServiceSid" with value'
          .trim()
          .toLocaleLowerCase()
      )
  ) {
    return `The requested resource ${param || ''} was not found`
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith('Validation error. Sequence with name'.trim().toLocaleLowerCase())
  ) {
    return `Sequence with name ${param || ''} already exists`
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith(
        'child "message" fails because ["message" length must be less than or equal to 1600 characters long]'
          .trim()
          .toLocaleLowerCase()
      )
  ) {
    return `Message text length must be less than or equal to 1600 characters long`
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith(
        'child "businessInfo" fails because ["businessInfo" must be an object]'
          .trim()
          .toLocaleLowerCase()
      )
  ) {
    return `Please select business`
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith('Cannot read properties of undefined'.trim().toLocaleLowerCase())
  ) {
    return `Network connection lost. Please check your internet connection and try again.`
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith('Undefined is not an object'.trim().toLocaleLowerCase())
  ) {
    return `Network connection lost. Please check your internet connection and try again.`
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith(
        'child "subject" fails because ["subject" is not allowed to be empty]'
          .trim()
          .toLocaleLowerCase()
      )
  ) {
    return getText('TEXT_SUBJECT_REQUIRED_PLEASE_ENTER_SUBJECT_FOR_YOUR_EMAIL')
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith(
        'child "subject" fails because ["subject" length must be at least 10 characters long]'
          .trim()
          .toLocaleLowerCase()
      )
  ) {
    return `"Subject" length must be at least 10 characters long`
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith(
        'child "message" fails because ["message" Should have only allowed tags'
          .trim()
          .toLocaleLowerCase()
      )
  ) {
    return getText('ERROR_USE_ONLY_DYNAMIC_TAGS_FROM_THE_LIST_OF_SUGGESTED_ONES')
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith("'email' is not a valid email".trim().toLocaleLowerCase())
  ) {
    return `Entered email is not valid`
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith(
        'child "referenceNumber" fails because ["referenceNumber" length must be less than or equal to 22 characters long]'
          .trim()
          .toLocaleLowerCase()
      )
  ) {
    return `Reference number may not be longer than 22 characters`
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith('Location not found for this PageID'.trim().toLocaleLowerCase())
  ) {
    return `Please complete integration setup before using FB/IG messenger`
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith(
        'child "newTags" fails because ["newTags" at position'
          .trim()
          .toLocaleLowerCase()
      )
  ) {
    return `Please enter tag name`
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith(
        'child "textMeButton" fails because [child "templateText"'
          .trim()
          .toLocaleLowerCase()
      )
  ) {
    return `Text Template is required for both languages`
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith("'email' is required".trim().toLocaleLowerCase())
  ) {
    return `Please input phone or email`
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith(
        'child "message" fails because ["message" length must be less than or equal to 1400 characters long]'
          .trim()
          .toLocaleLowerCase()
      )
  ) {
    return `Message length must be less than or equal to 1400 characters`
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith(
        'child "from" fails because ["from" is not allowed to be empty]'
          .trim()
          .toLocaleLowerCase()
      )
  ) {
    return `Please enter working hours in format H: MM (P/A)M`
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith(
        'child "to" fails because ["to" is not allowed to be empty]'
          .trim()
          .toLocaleLowerCase()
      )
  ) {
    return `Please enter working hours in format H: MM (P/A)M`
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith(
        'child "ips" fails because ["ips" at position 1 fails because ["1" must be a valid ip address of one of the following versions [ipv4, ipv6]'
          .trim()
          .toLocaleLowerCase()
      )
  ) {
    return `Please enter valid IP address of the following versions [ipv4, ipv6]`
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith(
        'child "superhumanConfig" fails because [child "options" fails because [child "agent_name" fails because'
          .trim()
          .toLocaleLowerCase()
      )
  ) {
    return `Agent Name can’t be empty`
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith(
        'child "superhumanConfig" fails because [child "options" fails because [child "agent_role"'
          .trim()
          .toLocaleLowerCase()
      )
  ) {
    return getText('AGENT_ROLE_CANT_BE_EMPTY')
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith('Tag names should not be duplicated'.trim().toLocaleLowerCase())
  ) {
    return getText('TEXT_TAG_NAMES_SHOULD_NOT_BE_DUPLICATED')
  } else if (
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith(
        'child "media" fails because ["media" at position 0 fails because [child "mediaContentType" fails because ["mediaContentType" must be one of [image/jpeg, image/png, image/gif, application/pdf, image/heic]]]]'
          .trim()
          .toLocaleLowerCase()
      ) ||
    errMSG
      ?.trim()
      ?.toLowerCase()
      ?.startsWith(
        'child "media" fails because ["media" at position 0 fails because [child "mediaContentType" fails because ["mediaContentType" must be one of [image/jpeg, image/png, image/gif, application/pdf, image/heic, text/vcard, text/x-vcard, text/vcf, image/heif]]]]'
          .trim()
          .toLocaleLowerCase()
      )
  ) {
    return getText('TEXT_UNSUPPORTED_FILE_FORMAT')
  }
  return errMSG ? errMSG : getText('ERR_GENERAL')
}
