import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Divider, Flex, Form } from 'antd'
import locationActions from '../../../../../store/modules/locationActions'
import { getText, getTextServerError } from '../../../../../lang'
import { notifyError, notifySuccess } from '../../../../../utils/Notify'
import { APP_URL } from '../../../../../config'
import MatForm from '../../../../../components/Form/MatForm'
import SelectTrigger from '../../../../../components/api/SelectTrigger'
import SelectTheme from '../../../../../components/api/SelectThemeLightOnly'
import { useLayout } from '../../../../../layout/LayoutProvider/LayoutProvider'
import { organizationSelector } from '../../../../../store/selectors/selectors'
import LoadingSpinner from '../../../../../components/LoadingSpinner'
import { getLongName } from '../../../../../utils'
import { CANCEL_BTN, SUBMIT_BTN } from '../../../../../devOptions'
import { useLocationLayout } from '../../../locationProvider/LocationProvider'
import MyDefaultPageLayout from '../../../../../components/MyDefaultPageLayout/MyDefaultPageLayout'
import useDeviceCheck from '../../../../../utils/useDeviceCheck'
import MyDefaultBlock from '../../../../../components/MyDefaultBlock/MyDefaultBlock'
import MyDefaultSwitch from '../../../../../components/MyDefaultSwitch/MyDefaultSwitch'
import MyDefaultInsidePageTabs from '../../../../../components/MyDefaultInsidePageTabs/MyDefaultInsidePageTabs'
import MyDefaultInputText from '../../../../../components/MyDefaultInputText/MyDefaultInputText'
import WidgetPreview from '../mobileCoupon/widgetPreview/WidgetPreview'
import MyDefaultCopyTextarea from '../../../../../components/MyDefaultCopyTextarea/MyDefaultCopyTextarea'
import MyDefaultUrlConditions from '../../../../../components/MyDefaultUrlConditions/MyDefaultUrlConditions'
import IntentPreview from './intentPreview/IntentPreview.js'

const LocationsExitIntent = () => {
  const { locationObj } = useLocationLayout()
  const { setFooterButtons, setLoading, setPageTitle } = useLayout()

  const organization = useSelector(organizationSelector)
  const { isPageDrawerSize, isTabletPortraitOrMobile } = useDeviceCheck()

  const [form] = Form.useForm()

  const [fieldsLang, setFieldsLang] = useState(organization?.defaultLanguage || 'en')
  const [isLoading, setIsLoading] = useState(true)
  const [popupData, setPopupData] = useState([])
  const [header, setHeader] = useState({
    en: '',
    fr: '',
    sp: '',
  })
  const [title, setTitle] = useState({
    en: '',
    fr: '',
    sp: '',
  })
  const [description, setDescription] = useState({
    en: '',
    fr: '',
    sp: '',
  })
  const [buttonTitle, setButtonTitle] = useState({
    en: '',
    fr: '',
    sp: '',
  })
  const [color, setColor] = useState({
    title: '',
    description: '',
    button: '',
    primary: '',
    buttonBackground: '',
    header: '',
  })
  const [termsConditionsLink, setTermsConditionsLink] = useState('')
  const [uploadFile, setUploadFile] = useState({
    fileUploadPage: true,
    uploadImage: '',
  })
  const [defaultImageUrl, setDefaultImageUrl] = useState('')
  const [urls, setUrls] = useState([])

  useEffect(() => {
    setFooterButtons([
      {
        type: CANCEL_BTN,
      },
      {
        type: SUBMIT_BTN,
      },
    ])
    setPageTitle(getText('WORD_EXIT_INTENT'))
  }, [])

  useEffect(() => {
    const fill = async () => {
      let result = await locationActions.getPopup(locationObj._id)
      if (result.success) {
        setPopupData(result.data)
        setIsLoading(false)
      }
    }
    if (locationObj && locationObj._id) {
      fill()
    }
  }, [locationObj, locationObj._id])

  useEffect(() => {
    setColor({
      button: popupData.buttonTextColor,
      description: popupData.descriptionColor,
      title: popupData.titleColor,
      primary: popupData.backgroundColor,
      buttonBackground: popupData.buttonColor,
      header: popupData.headerColor,
    })
    setDefaultImageUrl(popupData.defaultImageUrl)
    setTermsConditionsLink(popupData.termsConditionsLink)
    if (popupData && popupData.conditions && popupData.conditions.url) {
      setUrls(popupData.conditions.url)
    }
    setUploadFile({
      fileUploadPage: true,
      uploadImage: popupData.photo,
    })
    if (popupData?.header) {
      const objHeaders = {
        en: '',
        fr: '',
        sp: '',
      }
      popupData.header.forEach((item) => {
        objHeaders[item.language] = item.text
        return item
      })

      setHeader(objHeaders)
    }
    if (popupData?.title) {
      const objTitles = {
        en: '',
        fr: '',
        sp: '',
      }
      popupData.title.forEach((item) => {
        objTitles[item.language] = item.text
        return item
      })

      setTitle(objTitles)
    }
    if (popupData?.description) {
      const objDescriptions = {
        en: '',
        fr: '',
        sp: '',
      }
      popupData.description.forEach((item) => {
        objDescriptions[item.language] = item.text
        return item
      })
      setDescription(objDescriptions)
    }
    if (popupData?.buttonTexts) {
      const objButtonTexts = {
        en: '',
        fr: '',
        sp: '',
      }
      popupData.buttonTexts.forEach((item) => {
        objButtonTexts[item.language] = item.text
        return item
      })
      setButtonTitle(objButtonTexts)
    }

    const initialValues = {
      photo: popupData.photo,
      theme: 'light',
      showOncePerDayOption: popupData.showOncePerDayOption,
      backgroundColor: popupData.backgroundColor,
      buttonTextColor: popupData.buttonTextColor,
      buttonColor: popupData.buttonColor,
      titleColor: popupData.titleColor,
      headerColor: popupData.headerColor,
      descriptionColor: popupData.descriptionColor,
      termsConditionsLink: popupData.termsConditionsLink,
      popupTrigger: popupData.popupTrigger,
      status: popupData.status === 'DISABLED' ? false : true,
    }
    form.setFieldsValue(initialValues)
  }, [popupData])

  const onFinish = async (values) => {
    setLoading(true)
    delete values.numberValue
    delete values.text

    const obj = {
      ...values,
      photo: uploadFile.uploadImage || null,
      buttonTextColor: color.button,
      backgroundColor: color.primary,
      buttonColor: color.buttonBackground,
      descriptionColor: color.description,
      headerColor: color.header,
      titleColor: color.title,
      buttonTexts: Object.keys(buttonTitle).reduce((acc, lang) => {
        if (buttonTitle[lang]) {
          acc.push({ language: lang, text: buttonTitle[lang] })
        }
        return acc
      }, []),
      conditions: {
        url: urls,
      },
      description: Object.keys(description).reduce((acc, lang) => {
        if (description[lang]) {
          acc.push({ language: lang, text: description[lang] })
        }
        return acc
      }, []),
      header: Object.keys(header).reduce((acc, lang) => {
        if (header[lang]) {
          acc.push({ language: lang, text: header[lang] })
        }
        return acc
      }, []),
      title: Object.keys(title).reduce((acc, lang) => {
        if (title[lang]) {
          acc.push({ language: lang, text: title[lang] })
        }
        return acc
      }, []),
      status: values.status ? 'ENABLED' : 'DISABLED',
    }

    let result = await locationActions.savePopup(obj, locationObj._id)
    if (result.success) {
      notifySuccess(getText('TEXT_YOUR_POPUP_SAVED_SUCCESSFULLY'))
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
    setLoading(false)
  }

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields.length > 0) {
      notifyError(errorInfo.errorFields[0].errors[0])
    }
  }

  const getCode = () => {
    let defaultCode = `<!-- Start of Matador Exit Intent Script --> 
<script>
  (function(d,t) {
    var g=d.createElement(t),s=d.getElementsByTagName(t)[0];
    g.src=src="${APP_URL}/_coupon.js";
    g.async = true;
    s.parentNode.insertBefore(g,s);
    g.onload=function(){
        matadorCouponWidget("${locationObj._id}");
    }
  })(document,"script");
</script>
<!-- End of Matador Exit Intent Script -->
`

    return defaultCode
  }

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <MyDefaultPageLayout disableHeader>
      <Flex gap={20} align='start' vertical={isPageDrawerSize}>
        <MatForm
          maxWidth
          fullWidth
          form={form}
          formName='formCreateCoupon'
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <MyDefaultBlock fullWidth>
            <Flex
              gap={isTabletPortraitOrMobile ? 0 : 20}
              vertical={isTabletPortraitOrMobile}
            >
              <SelectTrigger
                isForm
                fullWidth
                name='popupTrigger'
                label={getText('LABEL_POPUP_TRIGGER')}
                location={locationObj}
              />
              <SelectTheme
                isForm
                fullWidth
                name='theme'
                label={getText('LABEL_THEME')}
                required
              />
            </Flex>
            <MyDefaultSwitch
              noText
              isFirst
              isForm
              name='status'
              titleStyle={{ fontSize: 14 }}
              title={getText('WORD_TURN_ON')}
            />
            <MyDefaultSwitch
              noText
              isForm
              isLast
              name='showOncePerDayOption'
              titleStyle={{ fontSize: 14 }}
              title={getText('TEXT_SHOW_ONCE_PER_DAY_TO_EACH_USER')}
            />
            <Divider style={{ margin: '12px 0 20px 0' }} />
            <MyDefaultInsidePageTabs
              isFirst
              onChange={(index) => {
                setFieldsLang(organization.languages[index])
              }}
              items={organization?.languages?.map((lng, index) => {
                return {
                  label: getLongName(lng),
                  key: index,
                  forceRender: true,
                  children: (
                    <WidgetPreview
                      language={lng}
                      uploadFile={uploadFile}
                      setUploadFile={setUploadFile}
                      title={title}
                      description={description}
                      buttonTitle={buttonTitle}
                      setTitle={setTitle}
                      setButtonTitle={setButtonTitle}
                      setDescription={setDescription}
                      color={color}
                      setColor={setColor}
                      header={header}
                      setHeader={setHeader}
                    />
                  ),
                }
              })}
            />
            <MyDefaultInputText
              isForm
              isLast
              name='termsConditionsLink'
              label={getText('WORD_TERMS_LINK')}
              required
            />
          </MyDefaultBlock>
          <MyDefaultBlock fullWidth enableMarginTop>
            <MyDefaultCopyTextarea
              copiedText={getCode()}
              label={getText('WORD_WIDGET_CODE')}
            />
          </MyDefaultBlock>
          <MyDefaultBlock fullWidth enableMarginTop>
            <MyDefaultUrlConditions urls={urls} setUrls={setUrls} />
          </MyDefaultBlock>
        </MatForm>
        <MyDefaultBlock position={'sticky'} top={0} fullWidth>
          <IntentPreview
            uploadFile={uploadFile}
            title={title}
            language={fieldsLang}
            color={color}
            description={description}
            buttonTitle={buttonTitle}
            header={header}
            defaultImageUrl={defaultImageUrl}
            termsLink={termsConditionsLink}
          />
        </MyDefaultBlock>
      </Flex>
    </MyDefaultPageLayout>
  )
}

export default LocationsExitIntent
