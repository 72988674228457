import React from 'react'
import { Flex, Tag } from 'antd'
import { getText } from '../../../../../lang'
import MatButton from '../../../../../components/MatButton'
import ConfirmPopup from '../../../../../components/ConfirmPopup'
import useDeviceCheck from '../../../../../utils/useDeviceCheck'
import './CenterMessageSuperhumanSnooze.scss'

const CenterMessageSuperhumanSnooze = ({ disabled, onSnooz, isExtension }) => {
  const { isMobile } = useDeviceCheck()

  return (
    <Flex
      justify={'space-between'}
      align='center'
      className='superhuman_snoozed_block_wrapper'
    >
      <Flex align={isMobile ? 'flex-start' : 'center'} vertical={isMobile}>
        <Tag className='title-tag'>{getText('AI_BOT_SUPERHUMAN')}</Tag>
        {Boolean(!isMobile && !isExtension) &&
          getText('TEXT_TAKE_PART_CONVERSATION')}
      </Flex>
      <ConfirmPopup
        title={getText('MSG_SNOOZE_SUPERHUMAN')}
        placement={'topRight'}
        onCancel={() => {}}
        onConfirm={() => {
          onSnooz && onSnooz()
        }}
        okText={getText('WORD_YES')}
        cancelText={getText('WORD_NO')}
        trigger={
          <MatButton
            className='snooze-tag'
            disabled={disabled}
            buttonText={getText('TEXT_SKIP_NEXT_SCHEDULED_SUPER_HUMAN_MESSAGE')}
            onClick={(e) => {
              e.stopPropagation()
            }}
          />
        }
      />
    </Flex>
  )
}

export default CenterMessageSuperhumanSnooze
