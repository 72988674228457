import React, { memo } from 'react'
import MessageTabRightSelectLanguage from './MessageTabRightSelectLanguage'
import MessageTabRightSelectTags from './MessageTabRightSelectTags'
import MessageRightSelectLocations from './MessageRightSelectLocations'
import MessageTabRightPostPurchase from './MessageTabRightPostPurchase'
import MessageTabRightVehicleInterest from './MessageTabRightVehicleInterest'
import MessageTabRightOwnedCars from './MessageTabInfoOwnedCars/MessageTabRightOwnedCars'
import './MessageTabInfo.scss'

const MessageTabInfo = ({
  conversation,
  onSaveReciver,
  refreshConversation,
  onSaveTags,
}) => {
  return (
    <div className='message-tab-pane-wrapper'>
      <MessageRightSelectLocations
        conversation={conversation}
        onSelectItem={refreshConversation}
      />
      <MessageTabRightPostPurchase conversation={conversation} />
      <MessageTabRightVehicleInterest />
      <MessageTabRightOwnedCars
        _receiver_id={conversation._receiver_id}
        convIsNew={conversation?.isNew}
      />
      <MessageTabRightSelectTags
        conversation={conversation}
        onSaveTags={onSaveTags}
      />
      {Boolean(conversation.receiver) && (
        <MessageTabRightSelectLanguage
          conversation={conversation}
          onSaveReciver={onSaveReciver}
        />
      )}
    </div>
  )
}

export default memo(MessageTabInfo)
