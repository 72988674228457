import React, { useRef, useState, useCallback, useEffect } from 'react'
import { useMixpanel } from 'react-mixpanel-browser'
import { useSelector } from 'react-redux'
import { Flex } from 'antd'
import {
  accessTokenSelector,
  isMobileAppSelector,
} from '../../../store/selectors/selectors'
import userActions from '../../../store/modules/userActions'
import appActions from '../../../store/modules/appActions'
import authActions from '../../../store/modules/authActions'
import { getText } from '../../../lang'
import SVGScrollDownIcon from '../../../icons/SVG/SVGScrollDownIcon'
import SVGTermsAndConditionsIcon from '../../../icons/SVG/SVGTermsAndConditionsIcon'
import Div from '../../Div/Div'
import MyDefaultCheckbox from '../../MyDefaultCheckbox/MyDefaultCheckbox'
import { notifyError, notifySuccess } from '../../../utils/Notify'
import eventBus from '../../../utils/eventBus'
import utilObject from '../../../utils/utilObject'
import {
  loginSuccess,
  setReduxAfterLogin,
} from '../../../views/profile/login/loginUtils'
import { redirect } from '../../../utils/UrlHelper'
import MyDefaultPurpleModal from '../MyDefaultPurpleModal/MyDefaultPurpleModal'
import purify from '../../../utils/utilPurify'
import './MyDefaultModalTermsAndConditions.scss'

const MyDefaultModalTermsAndConditions = () => {
  const userLogin = useSelector(accessTokenSelector)
  const isUserLoggedIn = !Boolean(userLogin) || utilObject.isEmpty(userLogin)
  const isMobileApp = useSelector(isMobileAppSelector)

  const mixpanel = useMixpanel()

  const [actions, setActions] = useState('hidden')
  const [acceptance, setAcceptance] = useState({ checked: false, canAccept: false })
  const [isLoading, setIsLoading] = useState({
    isPostponed: false,
    isSubmit: false,
  })
  const [data, setData] = useState({})

  const termsRef = useRef(null)

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault()
      event.returnValue = ''
    }

    if (!utilObject.isEmpty(data)) {
      window.addEventListener('beforeunload', handleBeforeUnload)
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [data])

  useEffect(() => {
    eventBus.on('SHOW_MODAL', (content) => {
      if (Boolean(content)) {
        setActions('default')
        setData(content)
      }
    })

    return () => {
      eventBus.removeAllListeners('SHOW_MODAL')
    }
  }, [])

  const handleScroll = useCallback(() => {
    if (termsRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = termsRef.current
      setAcceptance((ov) => ({
        checked: ov.checked || scrollTop + clientHeight >= scrollHeight - 5,
        canAccept: ov.canAccept || scrollTop + clientHeight >= scrollHeight - 5,
      }))
    }
  }, [])

  const onSuccess = async (isPostponed) => {
    let result
    setIsLoading(isPostponed ? { isPostponed: true } : { isSubmit: true })
    if (Boolean(isPostponed)) {
      result = await userActions.postponeCurrentTermsAndConditions(
        data._terms_conditions_id,
        data._user_id
      )
    } else {
      result = await userActions.submitCurrentTermsAndConditions(
        data._terms_conditions_id,
        data._user_id
      )
    }
    if (result.success) {
      notifySuccess(
        getText('TEXT_PROFILE_CHANGES_SAVED_SUCCESSFULLY', data?.userLanguage)
      )

      const resetFields = () => {
        setData({})
        setActions('hidden')
        setAcceptance({ checked: false, canAccept: false })
      }

      if (isUserLoggedIn) {
        setIsLoading({ isSubmit: false })
        let version = localStorage.getItem('version')
        if (!Boolean(version)) {
          const { data } = await appActions.getVersion()
          localStorage.setItem('version', JSON.stringify(data))
        }
        setReduxAfterLogin(result.data, mixpanel)
        const resultData = loginSuccess(
          result.data,
          null,
          isMobileApp,
          window?.history?.state?.usr?.method === 'email',
          window?.history?.state?.usr?.loginInputValues
        )
        if (typeof resultData === 'object') {
          redirect(resultData.path, resultData.state)
        } else {
          redirect(resultData)
        }
        resetFields()
        return
      } else {
        resetFields()
        authActions.setUserData(result.data.user)
      }
    } else {
      notifyError(result.errMsg)
    }
    setIsLoading(isPostponed ? { isPostponed: false } : { isSubmit: false })
  }

  const handleCancel = async () => {
    if (actions === 'remindMeLater') {
      await onSuccess(true)
    } else {
      setActions('remindMeLater')
    }
  }

  const handleOk = async () => {
    if (actions === 'remindMeLater') {
      setActions('default')
    } else {
      await onSuccess()
    }
  }

  return (
    <MyDefaultPurpleModal
      title={getText(
        'TEXT_WE_HAVE_UPDATED_OUR_TERMS_CONDITIONS',
        data?.userLanguage
      )}
      modalIcon={<SVGTermsAndConditionsIcon />}
      confirmLoading={isLoading.isSubmit}
      cancelLoading={isLoading.isPostponed}
      visible={actions !== 'hidden'}
      okText={getText(
        actions === 'remindMeLater' ? 'ACTION_BACK' : 'TEXT_ACCEPT_LOGIN',
        data?.userLanguage
      )}
      cancelText={getText(
        actions === 'remindMeLater' ? 'TEXT_SKIP_FOR_NOW' : 'TEXT_REMIND_ME_LATER',
        data?.userLanguage
      )}
      submitButtonDisabled={actions !== 'remindMeLater' && !acceptance.checked}
      hideCancel={data.isAcceptanceRequired}
      submitHtmlType={'button'}
      onCancelBtn={handleCancel}
      onOk={handleOk}
    >
      <Flex vertical align='center' gap={24} style={{ width: '100%' }}>
        {actions === 'remindMeLater' ? (
          <Flex vertical justify='center'>
            <p>
              {getText(
                'TEXT_TERSM_AND_CONDITIONS_REMIND_ME_LATER',
                data?.userLanguage
              )}
            </p>
          </Flex>
        ) : (
          <React.Fragment>
            <Flex vertical justify='center'>
              <p>
                {getText(
                  'TEXT_WE_HAVE_MADE_SOME_CHANGES_TO_OUR_TERMS_CONDITIONS',
                  data?.userLanguage
                )}
              </p>
              <p>
                {getText(
                  'TEXT_PLEASE_REVIEW_THEM_STAY_INFORMED_THANK_YOU_FOR_BEING_MATADOR_CUSTOMER',
                  data?.userLanguage
                )}
              </p>
            </Flex>
            <Flex vertical style={{ width: '100%' }}>
              <Flex align='center' justify='end' className='warning'>
                {getText(
                  'TEXT_PLEASE_SCROLL_DOWN_TO_ACCEPT_TERMS_CONDITIONS',
                  data?.userLanguage
                )}
              </Flex>
              <Div
                onScroll={handleScroll}
                ref={termsRef}
                className='my_default_modal_terms_and_conditions_textarea'
                dangerouslySetInnerHTML={{ __html: purify(data?.content) }}
              />
              <Flex
                align='center'
                gap={5}
                justify='end'
                className='scroll_down'
                onClick={() => {
                  termsRef.current.scrollTop = termsRef.current.scrollHeight
                }}
              >
                {getText('WORD_SCROLL_DOWN', data?.userLanguage)}
                <SVGScrollDownIcon />
              </Flex>
            </Flex>
            <MyDefaultCheckbox
              disabled={!acceptance.canAccept}
              value={acceptance.checked}
              title={getText(
                'TEXT_I_ACCEPT_THE_TERMS_AND_CONDITIONS',
                data?.userLanguage
              )}
              onChange={(e) =>
                setAcceptance((ov) => ({ ...ov, checked: e.target.checked }))
              }
            />
          </React.Fragment>
        )}
      </Flex>
    </MyDefaultPurpleModal>
  )
}

export default MyDefaultModalTermsAndConditions
