import React from 'react'
import SVGSelectArrow from '../../icons/SVG/SVGSelectArrow'
import Div from '../Div/Div'

const MyDefaultCustomArrow = ({ isOpen }) => {
  return (
    <Div className={'my_default_custom_arrow'}>
      <SVGSelectArrow
        style={{
          pointerEvents: 'none',
          transform: isOpen ? 'rotate(-180deg)' : 'rotate(0deg)',
        }}
      />
    </Div>
  )
}

export default MyDefaultCustomArrow
