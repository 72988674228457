import React, { useEffect, useState } from 'react'
import { Form } from 'antd'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getText, getTextServerError } from '../../../../../lang'
import {
  getLongName,
  MEDIATYPES_ONLY_IMAGE,
  MEDIATYPES_ONLY_VIDEO,
} from '../../../../../utils'
import { notifyError, notifySuccess } from '../../../../../utils/Notify'
import storyActions from '../../../../../store/modules/storyActions'
import { organizationSelector } from '../../../../../store/selectors/selectors'
import { useLayout } from '../../../../../layout/LayoutProvider/LayoutProvider'
import { CANCEL_BTN, IMAGE, SUBMIT_BTN, VIDEO } from '../../../../../devOptions'
import MyDefaultPageLayout from '../../../../../components/MyDefaultPageLayout/MyDefaultPageLayout'
import MyDefaultBlock from '../../../../../components/MyDefaultBlock/MyDefaultBlock'
import MyDefaultInsidePageTabs from '../../../../../components/MyDefaultInsidePageTabs/MyDefaultInsidePageTabs'
import MyDefaultInputText from '../../../../../components/MyDefaultInputText/MyDefaultInputText'
import MatForm from '../../../../../components/Form/MatForm'
import MyDefaultUploadWrapperBig from '../../../../../components/MyDefaultUpload/MyDefaultUploadWrapperBig/MyDefaultUploadWrapperBig'
import MyDefaultBlockSubtitle from '../../../../../components/MyDefaultBlock/MyDefaultBlockSubtitle'

const AddEditStory = ({ isNew }) => {
  const { setFooterButtons, setLoading } = useLayout()
  const organization = useSelector(organizationSelector)
  const { _id } = useParams()
  const location = useLocation()
  const navigate = useNavigate()

  const item = location && location.state ? location.state : ''
  const storiesEditItem = !isNew || !item ? item : {}

  useEffect(() => {
    if (!item && location.pathname.includes('edit')) {
      navigate(`/location/settings/${_id}/livechat-stories`)
    }
  }, [location.pathname])

  const [videoUpload, setVideoUpload] = useState({
    file: storiesEditItem.videoUrl || '',
  })

  const [imageUpload, setImageUpload] = useState({
    file: storiesEditItem.imageUrl || '',
  })

  const [thumbnailUpload, setThumbnailUpload] = useState({
    file: storiesEditItem.thumbnailUrl || '',
  })

  const [form] = Form.useForm()

  useEffect(() => {
    setFooterButtons([
      {
        type: CANCEL_BTN,
      },
      {
        type: SUBMIT_BTN,
      },
    ])
  }, [])

  const handleChange = (file, name) => {
    if (file.status !== 'done') return
    switch (name) {
      case 'thumbnail':
        setThumbnailUpload({
          file: file.mediaUrl,
        })
        break
      case 'video':
        setVideoUpload({
          file: file.mediaUrl,
        })
        break
      case 'image':
        setImageUpload({
          file: file.mediaUrl,
        })
        break
      default:
        break
    }
  }

  const onFinish = async (values) => {
    setLoading(true)
    let result

    if (!thumbnailUpload.file) {
      notifyError(getText('ERROR_THUMBNAIL_URL_IS_REQUIRED'))
      setLoading(false)
      return false
    }

    if (!imageUpload.file && !videoUpload.file) {
      notifyError(getText('ERROR_YOU_NEED_TO_PUT_AT_LEAST_IMAGE_OR_VIDEO_URL'))
      setLoading(false)
      return false
    }

    if (imageUpload.file && videoUpload.file) {
      notifyError(
        getText('ERROR_YOU_CANT_UPLOAD_BOTH_VIDEO_AND_IMAGE_REMOVE_ONE_THEM')
      )
      setLoading(false)
      return false
    }

    const obj = {
      locationId: _id,
      title: values.title,
      status: 'ACTIVE',
      thumbnailUrl: thumbnailUpload.file,
    }

    if (imageUpload.file) {
      obj.imageUrl = imageUpload.file
    }

    if (videoUpload.file) {
      obj.videoUrl = videoUpload.file
    }

    if (isNew) {
      result = await storyActions.insert(obj)
    } else {
      result = await storyActions.update(obj, item.id)
    }

    if (result.success) {
      notifySuccess(getText('MSG_STORY_ITEM_SAVED_SUCCESSFULLY'))
      navigate(`/location/settings/${_id}/livechat-stories`)
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
    setLoading(false)
  }

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields.length > 0) {
      notifyError(errorInfo.errorFields[0].errors[0])
    }
  }

  const handleStartUpload = (name) => {
    switch (name) {
      case 'thumbnail':
        setThumbnailUpload({ ...thumbnailUpload, loading: true })
        break
      case 'video':
        setVideoUpload({
          ...videoUpload,
          loading: true,
        })
        break
      case 'image':
        setImageUpload({
          ...imageUpload,
          loading: true,
        })
        break
      default:
        break
    }
  }

  const handleDelete = (name) => {
    switch (name) {
      case 'thumbnail':
        setThumbnailUpload({
          file: '',
        })
        break
      case 'video':
        setVideoUpload({
          file: '',
        })
        break
      case 'image':
        setImageUpload({
          file: '',
        })
        break
      default:
        break
    }
  }

  return (
    <MyDefaultPageLayout disableHeader>
      <MyDefaultBlock
        title={getText(isNew ? 'WORD_ADD_NEW_STORY' : 'WORD_EDIT_STORY')}
      >
        <MatForm
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{
            title: organization.languages.map((lng, index) => {
              return {
                text: !item ? '' : storiesEditItem.title[index].text,
                language: lng,
              }
            }),
          }}
        >
          <MyDefaultInsidePageTabs
            isFirst
            items={organization?.languages?.map((lng, index) => {
              return {
                label: getLongName(lng),
                key: index,
                forceRender: true,
                children: (
                  <React.Fragment>
                    <MyDefaultInputText
                      hidden
                      isForm
                      name={['title', index, 'language']}
                    />
                    <MyDefaultInputText
                      isForm
                      placeholder={getText('TEXT_ENTER_STORY_TITLE')}
                      label={getText('WORD_TITLE')}
                      name={['title', index, 'text']}
                      required
                      errorMessage={getText('ERROR_PLEASE_INPUT_STORY_LANG').replace(
                        '[language]',
                        getLongName(lng)
                      )}
                    />
                    <MyDefaultBlockSubtitle subtitle={getText('WORD_THUMBNAIL')} />
                    <MyDefaultUploadWrapperBig
                      listType={'text'}
                      isPreview
                      mediaCount={1}
                      size={20}
                      onStartUpload={() => handleStartUpload('thumbnail')}
                      showUploadList={false}
                      loading={thumbnailUpload.loading}
                      onUpload={(file) => handleChange(file, 'thumbnail')}
                      beforeUploadProps
                      mediaTypes={MEDIATYPES_ONLY_IMAGE}
                      subtitle={getText('STORY_THUMBNAIL_NOTE')}
                      onDelete={() => handleDelete('thumbnail')}
                      uploadedItemObj={
                        thumbnailUpload?.file
                          ? {
                              type: IMAGE,
                              url: thumbnailUpload.file,
                            }
                          : null
                      }
                      modalTitle={`${getText('ACTION_EDIT')} ${getText('WORD_THUMBNAIL')}`}
                    />
                    <MyDefaultBlockSubtitle subtitle={getText('WORD_IMAGE')} />
                    <MyDefaultUploadWrapperBig
                      listType={'text'}
                      isPreview
                      mediaCount={1}
                      size={20}
                      onStartUpload={() => handleStartUpload('image')}
                      showUploadList={false}
                      loading={imageUpload.loading}
                      onUpload={(file) => handleChange(file, 'image')}
                      beforeUploadProps
                      mediaTypes={MEDIATYPES_ONLY_IMAGE}
                      subtitle={getText('STORY_IMAGE_NOTE')}
                      onDelete={() => handleDelete('image')}
                      uploadedItemObj={
                        imageUpload?.file
                          ? {
                              type: IMAGE,
                              url: imageUpload.file,
                            }
                          : null
                      }
                      modalTitle={`${getText('ACTION_EDIT')} ${getText('WORD_IMAGE')}`}
                    />
                    <MyDefaultBlockSubtitle subtitle={getText('WORD_VIDEO')} />
                    <MyDefaultUploadWrapperBig
                      listType={'text'}
                      mediaCount={1}
                      videoSize={20}
                      storiesVideos
                      onStartUpload={() => handleStartUpload('video')}
                      showUploadList={false}
                      loading={videoUpload.loading}
                      onUpload={(file) => handleChange(file, 'video')}
                      beforeUploadProps
                      mediaTypes={MEDIATYPES_ONLY_VIDEO}
                      subtitle={getText('STORY_VIDEO_NOTE')}
                      onDelete={() => handleDelete('video')}
                      uploadedItemObj={
                        videoUpload?.file
                          ? {
                              showPreview: true,
                              type: VIDEO,
                              url: videoUpload.file,
                            }
                          : null
                      }
                      modalTitle={`${getText('ACTION_EDIT')} ${getText('WORD_VIDEO')}`}
                      disableMargins
                    />
                  </React.Fragment>
                ),
              }
            })}
          />
        </MatForm>
      </MyDefaultBlock>
    </MyDefaultPageLayout>
  )
}

export default AddEditStory
