import React, { forwardRef } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { Skeleton } from 'antd'
import MyDefaultBlock from '../MyDefaultBlock/MyDefaultBlock'
import Div from '../Div/Div'
import MyDefaultEmpty from '../MyDefaultEmpty/MyDefaultEmpty'
import './MyDefaultGridList.scss'

const MyDefaultGridList = forwardRef(
  (
    {
      loading,
      initialLoad,
      pageStart,
      onLoadMore,
      isReverse,
      data,
      totalDataCount,
      inifiniteScrollClass,
      getScrollParent,
      height,
      containerClassName,
      renderItem,
      emptyTitle,
      emptyButtonTitle,
      onEmptyClick,
    },
    ref
  ) => {
    return (
      <InfiniteScroll
        ref={ref}
        initialLoad={initialLoad}
        pageStart={pageStart}
        loadMore={onLoadMore}
        isReverse={isReverse}
        hasMore={!loading && data?.length < totalDataCount}
        useWindow={false}
        threshold={150}
        className={`my_default_infinite_scroll ${inifiniteScrollClass || ''}`}
        getScrollParent={getScrollParent}
        height={height}
      >
        {Boolean(loading) ? (
          <Div className={`my_default_grid_container ${containerClassName || ''}`}>
            {Array.from({ length: data?.length || 20 }, (_, index) => index).map(
              (item, index) => (
                <MyDefaultBlock
                  title={item?.blockTitle}
                  backgroundColor={item?.blockBackgroundColor}
                  key={index}
                >
                  <Skeleton loading={loading} active>
                    {renderItem(item, index)}
                  </Skeleton>
                  <Skeleton loading={loading} active>
                    {renderItem(item, index)}
                  </Skeleton>
                </MyDefaultBlock>
              )
            )}
          </Div>
        ) : Boolean(data?.length) ? (
          <Div className={`my_default_grid_container ${containerClassName || ''}`}>
            {data.map((item, index) => {
              return (
                <MyDefaultBlock
                  title={item?.blockTitle}
                  backgroundColor={item?.blockBackgroundColor}
                  key={index}
                >
                  {renderItem(item, index)}
                </MyDefaultBlock>
              )
            })}
          </Div>
        ) : (
          !Boolean(loading) && (
            <MyDefaultEmpty
              title={emptyTitle}
              buttonText={emptyButtonTitle}
              onClick={onEmptyClick}
            />
          )
        )}
      </InfiniteScroll>
    )
  }
)

export default MyDefaultGridList
