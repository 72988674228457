import React, { useCallback, useEffect, useRef, useState } from 'react'
import { debounce } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import locationActions from '../../../store/modules/locationActions'
import {
  organizationSelector,
  userSelector,
} from '../../../store/selectors/selectors'
import { getText } from '../../../lang'
import { TABLE_DEFAULT_ROW_NUMBER } from '../../../devOptions'
import { useLayout } from '../../../layout/LayoutProvider/LayoutProvider'
import MyDefaultPageLayout from '../../../components/MyDefaultPageLayout/MyDefaultPageLayout'
import MyDefaultGridList from '../../../components/MyDefaultGridList/MyDefaultGridList'
import LocationListItem from './LocationListItem'
import './LocationList.scss'

const LocationList = () => {
  const { setPageTitle } = useLayout()
  const navigate = useNavigate()

  const organization = useSelector(organizationSelector)
  const user = useSelector(userSelector)

  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState({ list: [], total: 0 })
  const [page, setPage] = useState(0)
  const [selectedStatus, setSelectedStatus] = useState(true)
  const [searchList, setSearchList] = useState('')
  const [search, setSearch] = useState('')

  const layoutRef = useRef(null)

  useEffect(() => {
    setPageTitle(getText('TITLE_LOCATION_LIST'))
  }, [])

  const getLocations = async () => {
    setIsLoading(true)
    const result = await locationActions.getPageList(
      page,
      TABLE_DEFAULT_ROW_NUMBER,
      searchList,
      {},
      selectedStatus
    )
    if (result.success) {
      const newData = page === 0 ? result.data : [...data.list, ...result.data]
      setData({
        list: newData,
        total: result.max,
      })
    }
    setIsLoading(false)
  }

  useEffect(() => {
    getLocations()
  }, [page, selectedStatus, searchList, organization?._id])

  const handleChangeStatusFilter = (value) => {
    const fill = async () => {
      if (value === 'all') {
        setSelectedStatus('all')
      } else {
        setSelectedStatus(value)
      }
    }
    fill()
  }

  const handleSearchFilter = (value) => {
    setIsLoading(true)
    setSearch(value)
    debouncedChange(value)
  }

  const debouncedChange = useCallback(
    debounce((options) => {
      setPage(0)
      setSearchList(options)
    }, 500),
    []
  )

  return (
    <MyDefaultPageLayout
      ref={layoutRef}
      disableHeader={!search && !data?.list?.length && !isLoading}
      headerProps={{
        options: [
          { label: getText('WORD_ALL'), value: 'all' },
          { label: getText('WORD_ACTIVE'), value: true },
          { label: getText('WORD_INACTIVE'), value: false },
        ],
        buttonText: user?.isSuperAdmin && getText('ACTION_CREATE_LOCATION'),
        searchValue: search,
        onSearchChange: (value) => handleSearchFilter(value),
        onSelectChange: (options) => handleChangeStatusFilter(options),
        selectValue: selectedStatus,
        onClick: () => navigate('/location/settings/new'),
      }}
    >
      <MyDefaultGridList
        pageStart={page}
        loading={isLoading}
        data={data.list || []}
        renderItem={(item, index) => {
          return (
            <LocationListItem
              key={index}
              item={item}
              languages={organization.languages}
            />
          )
        }}
        onLoadMore={() => {
          if (!isLoading && data?.list?.length < data?.total) {
            setIsLoading(true)
            setPage((ov) => ov + 1)
          }
        }}
        totalDataCount={data.total || 0}
        getScrollParent={() => layoutRef?.current}
        emptyTitle={
          search
            ? getText('TEXT_NO_RESULTS_FOUND')
            : getText('TEXT_YOU_CURRENTLY_HAVE_NO_CREATED_LOCATIONS')
        }
        emptyButtonTitle={!search && getText('ACTION_CREATE_LOCATION')}
        onEmptyClick={() => navigate('/location/settings/new')}
      />
    </MyDefaultPageLayout>
  )
}

export default LocationList
