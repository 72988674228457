import React from 'react'
import { getText } from '../../../../../../lang'
import Div from '../../../../../../components/Div/Div'
import MyDefaultColorPicker from '../../../../../../components/MyDefaultColorPicker/MyDefaultColorPicker'
import { MEDIATYPES_ONLY_IMAGE } from '../../../../../../utils'
import MyDefaultUploadWrapperBig from '../../../../../../components/MyDefaultUpload/MyDefaultUploadWrapperBig/MyDefaultUploadWrapperBig'
import { IMAGE } from '../../../../../../devOptions'
import WidgetTextarea from './WidgetTextarea'
import WidgetButton from './WidgetButton'
import './WidgetPreview.scss'

const WidgetPreview = ({
  language,
  uploadFile,
  setUploadFile,
  title,
  description,
  buttonTitle,
  setTitle,
  setButtonTitle,
  setDescription,
  color,
  setColor,
  header,
  setHeader,
}) => {
  const getUploadedItemObj = () => {
    const obj = { type: '', url: '' }

    if (uploadFile.uploadImage) {
      obj.type = IMAGE
      obj.url = uploadFile.uploadImage
    }

    return obj
  }

  const uploadedItemObj = getUploadedItemObj()

  return (
    <Div
      className={`coupoon_widget_wrapper`}
      style={{ backgroundColor: color.primary }}
    >
      <MyDefaultColorPicker
        showText={false}
        value={color.primary}
        onChangeColor={(newColor) => {
          setColor((ov) => ({
            ...ov,
            primary: newColor,
          }))
        }}
      />
      <MyDefaultUploadWrapperBig
        listType={'text'}
        onUpload={(media) => {
          setUploadFile({
            fileUploadPage: true,
            uploadImage: media.url,
            uploadImageFile: media.file,
          })
        }}
        wrapperSize={'small'}
        color={'white'}
        enableMarginTop
        mediaCount={1}
        beforeUploadProps
        showUploadList={false}
        errMsg={getText('TEXT_FILE_UPLOAD_ERROR')}
        mediaTypes={MEDIATYPES_ONLY_IMAGE}
        uploadedItemObj={uploadedItemObj}
        onDelete={() =>
          setUploadFile({
            fileUploadPage: true,
            uploadImage: '',
          })
        }
      />
      {header && (
        <WidgetTextarea
          rows='2'
          color={color.header}
          fontSize={14}
          value={header[language]}
          onChange={(e) => {
            setHeader((ov) => ({ ...ov, [language]: e.target.value }))
          }}
          onChangeColor={(color) => {
            setColor((ov) => ({
              ...ov,
              header: color,
            }))
          }}
        />
      )}
      <WidgetTextarea
        rows='2'
        enableMargin={Boolean(header)}
        color={color.title}
        fontSize={22}
        value={title[language]}
        onChange={(e) => {
          setTitle((ov) => ({ ...ov, [language]: e.target.value }))
        }}
        onChangeColor={(color) => {
          setColor((ov) => ({
            ...ov,
            title: color,
          }))
        }}
      />
      <WidgetTextarea
        rows='5'
        enableMargin
        color={color.description}
        fontSize={14}
        value={description[language]}
        onChange={(e) => {
          setDescription((ov) => ({ ...ov, [language]: e.target.value }))
        }}
        onChangeColor={(color) => {
          setColor((ov) => ({
            ...ov,
            description: color,
          }))
        }}
      />
      <WidgetButton
        value={buttonTitle[language]}
        buttonBackgroundColor={color.buttonBackground}
        onChange={(e) => {
          setButtonTitle((ov) => ({ ...ov, [language]: e.target.value }))
        }}
        onChangeColor={(color) => {
          setColor((ov) => ({
            ...ov,
            buttonBackground: color,
          }))
        }}
        onTextChangeColor={(color) => {
          setColor((ov) => ({
            ...ov,
            button: color,
          }))
        }}
        textColor={color.button}
      />
    </Div>
  )
}

export default WidgetPreview
