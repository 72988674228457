import React from 'react'
import { getText } from '../../lang'
import { OUTBOUND } from '../../devOptions'
import MatModal from '../MatModal'
import VoiceAIDetails from './VoiceAIDetails'
import VoiceAITranscription from './VoiceAITranscription'

const VoiceAIDetailsModal = ({
  visible,
  setVisible,
  item,
  isCallerInfoClickable,
  type,
  transcript,
  vogentCallRef,
  muted,
  callStatus,
  vogentAudioConn,
  onConnectAudio,
}) => {
  return (
    <MatModal
      noTrigger
      hideFooter
      visible={visible}
      onCancel={() => setVisible(false)}
      title={`${getText('WORD_CALL_DETAILS')} ${item?.assistant?.assistantName || ''}`}
      destroyOnClose={true}
      width={'100%'}
      rootClassName={'voice_ai_list_modal'}
    >
      {type === OUTBOUND && item?.status === 'in-progress' ? (
        <VoiceAITranscription
          transcript={transcript}
          vogentCallRef={vogentCallRef}
          muted={muted}
          callStatus={callStatus}
          vogentAudioConn={vogentAudioConn}
          onConnectAudio={onConnectAudio}
        />
      ) : (
        <VoiceAIDetails
          item={item}
          isCallerInfoClickable={isCallerInfoClickable}
          type={type}
        />
      )}
    </MatModal>
  )
}

export default VoiceAIDetailsModal
