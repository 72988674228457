import React, { useEffect, useRef, useState } from 'react'
import { Flex } from 'antd'
import dayjs from 'dayjs'
import { useNavigate, useParams } from 'react-router-dom'
import { getText, getTextServerError } from '../../../../../lang'
import { notifyError, notifySuccess } from '../../../../../utils/Notify'
import storyActions from '../../../../../store/modules/storyActions'
import { BUTTON, TABLE_DEFAULT_ROW_NUMBER } from '../../../../../devOptions'
import { useLocationLayout } from '../../../locationProvider/LocationProvider'
import MyDefaultDraggableTableList from '../../../../../components/MyDefaultTable/MyDefaultDraggableTableList/MyDefaultDraggableTableList'
import MyDefaultPageLayout from '../../../../../components/MyDefaultPageLayout/MyDefaultPageLayout'
import ConfirmPopup from '../../../../../components/ConfirmPopup'
import SVGMyDefaultEditIcon from '../../../../../icons/SVG/SVGMyDefaultEditIcon'
import SVGMyDefaultDeleteIcon from '../../../../../icons/SVG/SVGMyDefaultDeleteIcon'
import MyDefaultTableText from '../../../../../components/MyDefaultTable/MyDefaultTableText/MyDefaultTableText'
import authActions from '../../../../../store/modules/authActions'
import MyDefaultTableTitle from '../../../../../components/MyDefaultTable/MyDefaultTableTitle/MyDefaultTableTitle'
import MyDefaultTableSwitch from '../../../../../components/MyDefaultTable/MyDefaultTableSwitch/MyDefaultTableSwitch'
import MyDefaultImage from '../../../../../components/MyDefaultImage/MyDefaultImage'
import { useLayout } from '../../../../../layout/LayoutProvider/LayoutProvider'

const ListStories = () => {
  const { locationObj } = useLocationLayout()
  const { setPageTitle } = useLayout()
  const { _id } = useParams()
  const navigate = useNavigate()

  const [page, setPage] = useState(0)
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({
    list: [],
    total: 0,
  })

  const layoutRef = useRef(null)

  const getData = async () => {
    setLoading(true)
    let result = await storyActions.getAllList(
      page + 1,
      TABLE_DEFAULT_ROW_NUMBER,
      locationObj._id
    )
    if (result.success) {
      const newData = page === 0 ? result.data : [...data.list, ...result.data]
      setData({
        list: newData.map((item, index) => ({
          ...item,
          key: index + 1,
        })),
        total: result.max,
      })
    }
    setLoading(false)
  }

  useEffect(() => {
    setPageTitle(getText('WORD_STORIES'))
  }, [])

  useEffect(() => {
    if (locationObj._id) {
      getData()
    }
  }, [page, locationObj._id])

  const handleStorySwitch = async (checked, item) => {
    const data = {
      locationId: locationObj._id,
      title: item.title,
      status: checked ? 'ACTIVE' : 'DEACTIVATED',
      imageUrl: item.imageUrl,
      thumbnailUrl: item.thumbnailUrl,
      videoUrl: item.videoUrl,
    }

    const result = await storyActions.update(data, item.id)
    if (!result.success) {
      notifyError(getTextServerError(result.errMsg))
      return
    }
    notifySuccess(getText('MSG_STORY_WAS_EDITED_SUCCESSFULLY'))
    setData((ov) => {
      const newData = ov.list.map((it) => {
        if (it._id === item._id) {
          it.status = checked ? 'ACTIVE' : 'DEACTIVATED'
        }
        return it
      })
      return {
        list: [...newData],
        total: ov.total,
      }
    })
  }

  const updateList = async (newList) => {
    const oldState = data.list
    setData((prev) => {
      return {
        total: prev.total,
        list: newList,
      }
    })
    const storiesIds = newList.map((item) => item.id)
    const result = await storyActions.reorderStories(locationObj._id, storiesIds)
    if (!result.success) {
      notifyError(getTextServerError(result.errMsg))
      setData((prev) => {
        return {
          total: prev.total,
          list: oldState,
        }
      })
      return
    }
    notifySuccess(getText('MSG_STORIES_WERE_REORDERED_SUCCESSFULLY'))
  }

  return (
    <MyDefaultPageLayout
      ref={layoutRef}
      isAdvanced
      headerProps={{
        right: [
          {
            type: BUTTON,
            title: getText('WORD_ADD_NEW_STORY'),
            disabled: data.total >= 4,
            tooltipTitle:
              data.total >= 4 ? getText('TEXT_YOU_CAN_CREATE_ONLY_4_STORIES') : null,
            onClick: () =>
              navigate(`/location/settings/${_id}/livechat-stories/create`),
          },
        ],
      }}
    >
      <MyDefaultDraggableTableList
        pageStart={page}
        loading={loading}
        dataWithKeys={data.list || []}
        getScrollParent={() => layoutRef?.current}
        onLoadMore={() => {
          if (!loading && data?.list?.length < data?.total) {
            setLoading(true)
            setPage((ov) => ov + 1)
          }
        }}
        onFinishDrag={(data) => {
          updateList(data)
        }}
        totalDataCount={data?.total || 0}
        columns={[
          {
            title: getText('LABEL_STORY_TITLE'),
            dataIndex: 'name',
            render: (_, { title, thumbnailUrl }) => {
              return (
                <Flex align='center' gap={10}>
                  {thumbnailUrl && (
                    <MyDefaultImage
                      src={thumbnailUrl}
                      width={74}
                      height={50}
                      disableMargin
                    />
                  )}
                  <MyDefaultTableTitle title={title?.[0]?.text || ''} />
                </Flex>
              )
            },
          },
          {
            title: getText('WORD_UPDATED_AT'),
            dataIndex: 'updatedAt',
            render: (_, { updatedAt }) => {
              return (
                <MyDefaultTableText
                  text={dayjs(updatedAt)
                    .locale(authActions.getLanguage())
                    .format('D MMM, YYYY hh:mm a')}
                />
              )
            },
          },
          {
            title: getText('WORD_STATUS'),
            dataIndex: 'status',
            render: (_, item) => {
              return (
                <MyDefaultTableSwitch
                  value={item.status === 'ACTIVE' ? true : false}
                  text={getText(
                    item.status === 'ACTIVE' ? 'WORD_ACTIVE' : 'WORD_INACTIVE'
                  )}
                  onChange={(value) => handleStorySwitch(value, item)}
                />
              )
            },
          },
          {
            title: '',
            dataIndex: 'actions',
            width: 60,
            render: (_, item) => {
              return (
                <Flex align='center' gap={20} justify='end'>
                  <SVGMyDefaultEditIcon
                    onClick={() =>
                      navigate(`/location/settings/${_id}/livechat-stories/edit`, {
                        state: item,
                      })
                    }
                  />
                  <ConfirmPopup
                    title={getText(
                      'MSG_ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_STORIES_TYPE'
                    ).replace(
                      '[storiesType]',
                      getText('WORD_STORIES').toLowerCase()
                    )}
                    placement={'topRight'}
                    onConfirm={async () => {
                      const result = await storyActions.delete(item.id)
                      if (result.success) {
                        notifySuccess(getText('MSG_STORY_WAS_DELETED_SUCCESSFULLY'))
                        setData((ov) => {
                          const newData = ov.list.filter(
                            (temp) => item._id !== temp._id
                          )
                          return {
                            total: ov.total,
                            list: [...newData],
                          }
                        })
                      } else {
                        notifyError(getTextServerError(result.errMsg))
                      }
                    }}
                    okText={getText('WORD_YES')}
                    cancelText={getText('WORD_NO')}
                    trigger={<SVGMyDefaultDeleteIcon />}
                  />
                </Flex>
              )
            },
          },
        ]}
      />
    </MyDefaultPageLayout>
  )
}

export default ListStories
