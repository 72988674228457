import { fetchFromUrlGETAsync, fetchFromUrlPUTAsync } from '../../utils/UrlHelper'

const urlSaveXrm = '/locations/{location_id}/xrm-integration'
const urlVehicleServices = '/crm/xtime/vehicle-services'
const urlGetAvailableServiceTime = '/crm/xtime/available-service-time'

const fs = {
  saveXrm: async (obj, locationId) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })

    const result = await fetchFromUrlPUTAsync(
      urlSaveXrm.replace('{location_id}', locationId),
      urlParams
    )
    return result
  },
  getVehicleServices: async (obj) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })

    const result = await fetchFromUrlGETAsync(urlVehicleServices, urlParams)
    return result
  },
  getAvailableServiceTime: async (obj) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })

    const result = await fetchFromUrlGETAsync(urlGetAvailableServiceTime, urlParams)
    return result
  },
}

const loc = {}

const xrmActions = Object.assign(fs, loc)

export default xrmActions
