import React from 'react'
import { Image } from 'antd'
import './MyDefaultImage.scss'

const MyDefaultImage = ({ width, src, height, disableMargin }) => {
  return (
    <Image
      rootClassName={`my_default_image ${disableMargin ? 'disableMargin' : ''}`}
      width={width}
      src={src}
      height={height}
    />
  )
}

export default MyDefaultImage
