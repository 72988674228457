import * as React from 'react'

function SVGTextPositionCenter(props) {
  return (
    <svg
      width='28'
      height='28'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect x='0.5' y='0.5' width='35' height='35' rx='3.5' fill='white' />
      <rect x='0.5' y='0.5' width='35' height='35' rx='3.5' stroke={'white'} />
      <path
        d='M20 4.5H16C15.1716 4.5 14.5 5.17157 14.5 6V10C14.5 10.8284 15.1716 11.5 16 11.5H20C20.8284 11.5 21.5 10.8284 21.5 10V6C21.5 5.17157 20.8284 4.5 20 4.5Z'
        stroke='#E5E9F2'
      />
      <path
        d='M20 14.5H16C15.1716 14.5 14.5 15.1716 14.5 16V20C14.5 20.8284 15.1716 21.5 16 21.5H20C20.8284 21.5 21.5 20.8284 21.5 20V16C21.5 15.1716 20.8284 14.5 20 14.5Z'
        fill='#5B66EA'
        stroke='#5B66EA'
      />
      <path
        d='M20 24.5H16C15.1716 24.5 14.5 25.1716 14.5 26V30C14.5 30.8284 15.1716 31.5 16 31.5H20C20.8284 31.5 21.5 30.8284 21.5 30V26C21.5 25.1716 20.8284 24.5 20 24.5Z'
        stroke='#E5E9F2'
      />
    </svg>
  )
}

export default SVGTextPositionCenter
