import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { CheckOutlined, LoadingOutlined } from '@ant-design/icons'
import { debounce } from 'lodash'
import { userSelector } from '../../../store/selectors/selectors'
import userActions from '../../../store/modules/userActions'
import { getText } from '../../../lang'
import { GET_DATA_FETCH_SIZE } from '../../../utils'
import MyDefaultDropdown from '../MyDefaultDropdown'
import Div from '../../Div/Div'
import MyDefaultCustomArrow from '../../MyDefaultSelect/MyDefaultCustomArrow'
import './MyDefaultDropdownAssignedUser.scss'
import MyDefaultSearchInput from '../../MyDefaultSearchInput/MyDefaultSearchInput'

const MyDefaultDropdownAssignedUser = ({
  assignedUsers,
  handleUnassignUser,
  handleAssignUser,
  color = 'grey',
  isLast,
}) => {
  const user = useSelector(userSelector)

  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState({
    list: [],
    max: 0,
  })
  const [page, setPage] = useState(0)
  const [searchKeyword, setSearchKeyword] = useState('')
  const [isOpen, setisOpen] = useState(false)

  const assignedUsersObj = useMemo(() => {
    return Object.fromEntries(assignedUsers.map((item) => [item._id, true]))
  }, [assignedUsers?.length])

  useEffect(() => {
    const getUsersList = async () => {
      setIsLoading(true)
      if (user.isAdmin || user.isManager) {
        const result = await userActions.getUsersForAssignUnassign(
          page,
          GET_DATA_FETCH_SIZE,
          user._organization_id,
          searchKeyword
        )

        if (result.success) {
          const newData = page === 0 ? result.data : [...data.list, ...result.data]
          setData({
            list: newData,
            total: result.max,
          })
        }
      }
      setIsLoading(false)
    }
    getUsersList()
  }, [page, searchKeyword])

  const debouncedChange = useCallback(
    debounce((options) => {
      setPage(0)
      setSearchKeyword(options)
    }, 500),
    []
  )

  const items = [
    {
      label: (
        <MyDefaultSearchInput
          fullWidth
          placeholder={getText('TEXT_USERS_SEARCH')}
          onChange={debouncedChange}
        />
      ),
      disabled: true,
      key: 'search',
    },
    {
      label: getText('WORD_ASSIGNED_USERS') + ` ( ${assignedUsers.length} )`,
      key: 'assigned_users',
      type: 'group',
      children: assignedUsers.map((item, index) => {
        return {
          key: item._id || index,
          label: item.fullName,
          onClick: () => handleUnassignUser(item),
          extra: <CheckOutlined style={{ color: 'var(--mainColor)' }} />,
        }
      }),
    },
    {
      label: getText('WORD_USERS'),
      key: 'other_users',
      type: 'group',
      children: data.list.map((item, index) => {
        if (assignedUsersObj[item._id]) return null
        return {
          key: item._id || index,
          label: item.fullName,
          onClick: () => handleAssignUser(item),
        }
      }),
    },
  ]

  return (
    <MyDefaultDropdown
      items={items}
      disabled={isLoading}
      overlayClassName='my_default_assign_user_dropdown'
      dropdownRender={(menu) => {
        return (
          <Div
            className='my_default_dropdown_custom_render'
            onScroll={(e) => {
              const { scrollHeight, scrollTop, clientHeight } = e.currentTarget
              if (scrollHeight - Math.ceil(scrollTop) <= clientHeight) {
                if (data.list.length < data.total && !isLoading) {
                  setPage((prevPage) => prevPage + 1)
                }
              }
            }}
          >
            {React.cloneElement(menu)}
          </Div>
        )
      }}
      onOpenChange={(value) => setisOpen(value)}
    >
      <Div
        className={`my_default_select ${color} ${!isLast ? 'enable_margin' : ''}`}
        style={{ cursor: 'pointer', position: 'relative' }}
      >
        <Div className={'ant-select-selector'}>
          {isLoading ? (
            <LoadingOutlined style={{ fontSize: 12 }} />
          ) : assignedUsers.length > 0 ? (
            <Div className={'ant-select-selection-item'}>
              {getText('WORD_ASSIGNED_USERS') + ` ( ${assignedUsers.length} )`}
            </Div>
          ) : (
            <Div className={'ant-select-selection-placeholder'}>
              {getText('ACTION_SELECT_USER')}
            </Div>
          )}
        </Div>
        <MyDefaultCustomArrow isOpen={isOpen} />
      </Div>
    </MyDefaultDropdown>
  )
}

export default MyDefaultDropdownAssignedUser
