import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 20.855 20.855'
  >
    <g id='iconfinder_social-26_1591841' transform='translate(-5 -5)'>
      <circle
        id='Ellipse_97'
        dataName='Ellipse 97'
        cx='10.428'
        cy='10.428'
        r='10.428'
        transform='translate(5 5)'
        fill='#cb2727'
      />
      <g id='Group_1066' dataName='Group 1066' transform='translate(11.652 10.393)'>
        <path
          id='Path_955'
          dataName='Path 955'
          d='M36.4,50.146a.406.406,0,0,0,.287-.393.436.436,0,0,0-.261-.444l-.467-.193a11.169,11.169,0,0,0-1.751-.7.345.345,0,0,0-.309.179,3.682,3.682,0,0,0-.174,1.635,1.333,1.333,0,0,0,.1.422.349.349,0,0,0,.3.2,9.6,9.6,0,0,0,1.616-.5Z'
          transform='translate(-33.705 -43.75)'
          fill='#f4f7fc'
        />
        <path
          id='Path_956'
          dataName='Path 956'
          d='M42.18,58.434a.425.425,0,0,0-.5.114s-.326.393-.327.393a11.477,11.477,0,0,0-1.195,1.477.316.316,0,0,0-.017.146.388.388,0,0,0,.09.206,3.451,3.451,0,0,0,1.9.711.353.353,0,0,0,.282-.223,11.043,11.043,0,0,0,.033-1.717s0-.7,0-.709A.41.41,0,0,0,42.18,58.434Z'
          transform='translate(-38.651 -51.419)'
          fill='#f4f7fc'
        />
        <path
          id='Path_957'
          dataName='Path 957'
          d='M53.63,43.785l-.009.014a4.944,4.944,0,0,1,.5-.135,11.8,11.8,0,0,0,1.821-.482.356.356,0,0,0,.145-.33.074.074,0,0,0,0-.014,3.564,3.564,0,0,0-1.128-1.7.345.345,0,0,0-.359.014,10.744,10.744,0,0,0-1.018,1.366l-.414.571a.411.411,0,0,0,0,.488A.424.424,0,0,0,53.63,43.785Z'
          transform='translate(-48.598 -38.127)'
          fill='#f4f7fc'
        />
        <path
          id='Path_958'
          dataName='Path 958'
          d='M39.947,28.543a.36.36,0,0,0-.273-.254,4.673,4.673,0,0,0-2.385.681.37.37,0,0,0-.105.345c.047.1,2.09,3.366,2.09,3.366.3.5.549.42.629.395s.325-.1.3-.683C40.171,31.713,39.97,28.684,39.947,28.543Z'
          transform='translate(-36.37 -28.273)'
          fill='#f4f7fc'
        />
        <path
          id='Path_959'
          dataName='Path 959'
          d='M54.789,56.642s-.652-.219-.66-.223a.4.4,0,0,0-.459.148.441.441,0,0,0-.044.516l.263.434a12.568,12.568,0,0,0,1.014,1.61.336.336,0,0,0,.353.038,3.5,3.5,0,0,0,1.252-1.618.361.361,0,0,0-.12-.342A10.86,10.86,0,0,0,54.789,56.642Z'
          transform='translate(-48.96 -49.878)'
          fill='#f4f7fc'
        />
      </g>
    </g>
  </svg>
)

const SVGLocationYelpIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGLocationYelpIcon
