import React, { useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { getText } from '../../lang'
import Div from '../Div/Div'
import MyDefaultButton from '../MyDefaultButton/MyDefaultButton'
import MyDefaultTextarea from '../MyDefaultTextarea/MyDefaultTextarea'
import './MyDefaultCopyTextarea.scss'
import MyDefaultBlockSubtitle from '../MyDefaultBlock/MyDefaultBlockSubtitle'

const MyDefaultCopyTextarea = ({
  onCopy,
  copiedText,
  disabled = true,
  autoSize = 'none',
  label,
  enableMargin,
}) => {
  const [currentlyCopied, setCurrentlyCopied] = useState(false)

  return (
    <Div
      className={`my_default_copy_textarea ${enableMargin ? 'enable_margin' : ''}`}
    >
      <MyDefaultBlockSubtitle subtitle={label} />
      <MyDefaultTextarea
        disabled={disabled}
        value={copiedText}
        autoSize={autoSize}
      />
      <CopyToClipboard
        text={copiedText}
        onCopy={() => {
          setCurrentlyCopied(true)
          onCopy?.()
        }}
      >
        <MyDefaultButton
          size='small'
          htmlType='button'
          buttonText={
            currentlyCopied ? getText('WORD_COPIED') : getText('WORD_COPY')
          }
        />
      </CopyToClipboard>
    </Div>
  )
}

export default MyDefaultCopyTextarea
