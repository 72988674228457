import React from 'react'
import { useParams } from 'react-router-dom'
import { Flex } from 'antd'
import useDeviceCheck from '../../../utils/useDeviceCheck'
import { OrganizationProvider } from '../organizationProvider/OrganizationProvider'
import OrganizationMenu from './OrganizationMenu'

const OrganizationNavigation = ({ children }) => {
  const { _id } = useParams()
  const { isTabletPortraitOrMobile } = useDeviceCheck()
  return (
    <Flex vertical={isTabletPortraitOrMobile} style={{ height: '100%' }}>
      <OrganizationMenu id={_id} />
      <OrganizationProvider _id={_id} children={children} />
    </Flex>
  )
}

export default OrganizationNavigation
