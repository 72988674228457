import React from 'react'
import { useSelector } from 'react-redux'
import { Flex, Form } from 'antd'
import { getLongName } from '../../../../../utils'
import { getText } from '../../../../../lang'
import MyDefaultInsidePageTabs from '../../../../../components/MyDefaultInsidePageTabs/MyDefaultInsidePageTabs'
import MyDefaultDraggable from '../../../../../components/MyDefaultDraggable/MyDefaultDraggable'
import MyDefaultTextarea from '../../../../../components/MyDefaultTextarea/MyDefaultTextarea'
import MyDefaultTreeSelectLocationOrganization from '../../../../../components/MyDefaultTreeSelect/MyDefaultTreeSelectLocationOrganization/MyDefaultTreeSelectLocationOrganization'
import { organizationSelector } from '../../../../../store/selectors/selectors'
import MyDefaultBlockTitle from '../../../../../components/MyDefaultBlock/MyDefaultBlockTitle'
import MyDefaultBlockSubtitle from '../../../../../components/MyDefaultBlock/MyDefaultBlockSubtitle'

const LocationsOptions = ({ field, form }) => {
  const organization = useSelector(organizationSelector)
  const step = Form.useWatch(['steps', field.name], form)

  const getLocationIndex = (id) => {
    if (id) return step?.locations?.findIndex((loc) => loc.locationId === id)
  }

  const handleChangeSelect = (e, list) => {
    const oldLocations = e
    const obj = {}

    oldLocations.forEach((loc) => {
      obj[loc.value] = loc
    })

    for (const org of list) {
      for (const location of org?.children || []) {
        if (obj[location.id] || obj[location._organization_id]) {
          const locationCustomName = step?.locations?.find(
            (loc) => loc.locationId === location.id
          )?.locationCustomName

          obj[location.id] = {
            locationId: location.id,
            organizationId: location._organization_id,
            locationCustomName:
              locationCustomName ||
              organization.languages.reduce((acc, lang) => {
                acc[lang] = ''
                return acc
              }, {}),
            location: {
              _id: location.id,
              name: location.title,
            },
            organization: {
              _id: org.id,
              name: org.title,
            },
            value: location.id,
            label: location.title,
          }
        }
      }
      if (obj[org.id]) delete obj[org.id]
    }
    form.setFieldValue(['steps', field.name, 'locations'], Object.values(obj))
  }

  return (
    <React.Fragment>
      <MyDefaultTreeSelectLocationOrganization
        isForm
        name={[field.name, 'locations']}
        label={getText('TEXT_CHOOSE_SEVERAL_LOCATIONS_AND_AFTERWARD')}
        labelInValue
        placeholder={getText('WORD_SELECT_LOCATIONS')}
        required
        message={`Step ${field.name + 1}: Please select at least 1 location`}
        customOnChange={handleChangeSelect}
        isLast={!step?.locations?.length}
      />
      {Boolean(step?.locations?.length) && (
        <MyDefaultInsidePageTabs
          items={organization.languages.map((lng, yIndex) => {
            return {
              label: getLongName(lng),
              key: yIndex,
              forceRender: true,
              children: (
                <MyDefaultDraggable
                  data={step?.locations || []}
                  getCustomKey={(item) => item?.location?._id}
                  onChange={(result) => {
                    form.setFieldValue(['steps', field.name, 'locations'], result)
                  }}
                  renderItem={(item, index) => {
                    return (
                      <Flex style={{ width: '100%' }} vertical key={index} gap={10}>
                        <Flex vertical>
                          <MyDefaultBlockTitle title={item?.location?.name || ''} />
                          <MyDefaultBlockSubtitle
                            subtitle={item?.organization?.name || ''}
                          />
                        </Flex>
                        <MyDefaultTextarea
                          isForm
                          label='Custom Name'
                          color={'white'}
                          isLast
                          name={[
                            field.name,
                            'locations',
                            getLocationIndex(item?.location?._id),
                            'locationCustomName',
                            lng,
                          ]}
                          message={getText('ERR_VALIDATION_REQUIRED')}
                        />
                      </Flex>
                    )
                  }}
                />
              ),
            }
          })}
        />
      )}
    </React.Fragment>
  )
}

export default LocationsOptions
