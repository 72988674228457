import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { useMixpanel } from 'react-mixpanel-browser'
import { Flex } from 'antd'
import ConfirmPopup from '../../../../../components/ConfirmPopup'
import { getText, getTextServerError } from '../../../../../lang'
import customerActions from '../../../../../store/modules/customerActions'
import {
  formatDateAppoinment,
  formatDateTimeAppoinment,
  formatTimeAppoinment,
  setMixpanel,
} from '../../../../../utils'
import { notifyError, notifySuccess } from '../../../../../utils/Notify'
import utilDate, { FORMAT_DATE } from '../../../../../utils/utilsDate'
import UtcTimezoneMessage from '../../../../../components/api/UtcTimezoneMessage'
import { EMAIL, SMS } from '../../../../../devOptions'
import {
  locationSelector,
  organizationSelector,
  userSelector,
  vehicleOfInterestSelector,
} from '../../../../../store/selectors/selectors'
import voiceAIActions from '../../../../../store/modules/voiceAIActions'
import MyDefaultCheckbox from '../../../../../components/MyDefaultCheckbox/MyDefaultCheckbox'
import MyDefaultSelect from '../../../../../components/MyDefaultSelect/MyDefaultSelect'
import MyDefaultSelectLocationByLocation from '../../../../../components/MyDefaultSelect/MyDefaultSelectLocationByLocation'
import MyDefaultDatePicker from '../../../../../components/MyDefaultDatePicker/MyDefaultDatePicker'
import MyDefaultCustomTimePicker from '../../../../../components/MyDefaultOrganizationHours/MyDefaultCustomTimePicker/MyDefaultCustomTimePicker'
import MyDefaultButton from '../../../../../components/MyDefaultButton/MyDefaultButton'
import MyDefaultBlockSubtitle from '../../../../../components/MyDefaultBlock/MyDefaultBlockSubtitle'
import useDeviceCheck from '../../../../../utils/useDeviceCheck'
import MessageTabInfoOwnedCarsModal from '../MessageTabInfo/MessageTabInfoOwnedCars/MessageTabInfoOwnedCarsModal'
import authActions from '../../../../../store/modules/authActions'
import { XTIME } from '../../../../../config/constants'
import xrmActions from '../../../../../store/modules/xrmActions'
import utilVehicle from '../../../../../utils/utilVehicle'
import SVGTimePickerIcon from '../../../../../icons/SVG/SVGTimePickerIcon'
import { SALES, SERVICE } from '../../../utils/constants'

const RenderBookingEdit = ({
  item,
  onCancel,
  onRemove,
  onConfirm,
  placement,
  conversation,
  handleOnDelete,
  fromAppointment,
  overlayClassName,
  onCloseMobileDesk,
  msgChangeEmailSMSType,
  onConfirmMessageTabBooking,
  includeVoiceAIConfirmationBlock,
  isVertical,
  onModalChange,
}) => {
  const mixpanel = useMixpanel()
  const { isMobile } = useDeviceCheck()

  let user = useSelector(userSelector)
  let location = useSelector(locationSelector)
  let vehicleOfInterest = useSelector(vehicleOfInterestSelector)
  const organization = useSelector(organizationSelector)
  const organizationTimeZone = organization.timezone

  const [currentTimeSplit, setCurrentTimeSplit] = useState(
    utilDate.getDateDaysJSUTC().add(5, 'minutes').format('hh:mm:A')
  )
  const [dateTime, setDateTime] = useState('')
  const [saving, setsaving] = useState(false)
  const [savingDelete, setsavingDelete] = useState(false)
  const [calendarDate, setCalendarDate] = useState(utilDate.getDateByNewDateUTC())
  const [isUsingXtime, setisUsingXtime] = useState(false)
  const [vehicleAvailableServiceList, setvehicleAvailableServiceList] = useState([])
  const [appointmentsTimesList, setappointmentsTimesList] = useState([])
  const [isXtimeAppointmentFinalised, setisXtimeAppointmentFinalised] =
    useState(false)
  const [selectedLocation, setselectedLocation] = useState('')
  const [assistantsList, setassistantsList] = useState([])
  const [createOutgoingVoiceAICall, setcreateOutgoingVoiceAICall] = useState(false)
  const [selectedAssistant, setselectedAssistant] = useState(null)
  const [vehiclesInfo, setVehiclesInfo] = useState({
    customerInterestedIn: SALES,
    vehicle: '',
  })
  const [visible, setVisible] = useState(false)

  const [hoursValue, minutesValue, timePeriod] = currentTimeSplit?.split(':') || ''

  const addAppointmentEndRef = useRef(null)

  const vehicleOfInterestList = useMemo(() => {
    return vehicleOfInterest?.data
      ? [...(vehicleOfInterest.data.service || [])]?.filter(
          (item) => item.salesStep === 'Available'
        )
      : []
  }, [vehicleOfInterest])

  useEffect(() => {
    let obj = {}
    if (item) {
      obj = {
        _id: item._location_id ? item._location_id : location?._id,
        name: item.eventName ? item.eventName.substring(15) : location.name,
      }
    } else {
      obj = {
        _id: conversation._location_id ? conversation._location_id : location?._id,
        name: conversation._location_id ? ' ' : location.name,
      }
    }
    setselectedLocation(obj)
  }, [conversation?._location_id])

  const scrollToBottom = () => {
    addAppointmentEndRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    scrollToBottom()
    if (item) {
      let date = utilDate.getDateByDayJS(item.eventStart, 'MMM DD, YYYY')
      setCurrentTimeSplit(utilDate.getDayJSDate(item.eventStart).format('hh:mm:A'))
      setCalendarDate(date)
    } else {
      var currentDate = new Date()
      currentDate.setMinutes(currentDate.getMinutes() + 5)
      let newDateObj = utilDate.getDateByNewDateUTC(currentDate)
      setCalendarDate(newDateObj)
    }
  }, [item])

  useEffect(() => {
    const getAssistants = async () => {
      const result = await voiceAIActions.getAssistantsList({
        idOrganization: organization._id,
        assistantType: 'appointment_confirmation',
      })
      if (result.success) {
        setassistantsList(result.data.vogentAssistants)
      }
    }

    if (user.isSuperAdmin) {
      getAssistants()
    }
  }, [])

  const getVehicleServices = async (vehicle) => {
    const obj = {
      locationId: selectedLocation._id,
      vin: vehicle?.vin,
      year: vehicle?.year,
      make: vehicle?.make,
      model: vehicle?.model,
    }

    const result = await xrmActions.getVehicleServices(obj)
    if (result.success) {
      setvehicleAvailableServiceList(
        result.data.map((service) => {
          return { ...service, label: service.serviceName, value: service.opcode }
        })
      )
    }
  }

  const getAvailableAppointmentsTime = async () => {
    if (calendarDate) {
      setappointmentsTimesList([])

      const date = utilDate.getCurrentDateByDayJS(calendarDate, 'YYYY-MM-DD')

      const obj = {
        locationId: selectedLocation._id,
        vin: vehiclesInfo?.vehicle?.vin,
        year: vehiclesInfo?.vehicle?.year,
        make: vehiclesInfo?.vehicle?.make,
        opcode: vehiclesInfo?.typeOfService?.opcode,
        model: vehiclesInfo?.vehicle?.model,
        serviceName: vehiclesInfo?.typeOfService?.serviceName,
        start: date,
        end: date,
      }

      const result = await xrmActions.getAvailableServiceTime(obj)
      if (result.success && result.data?.availabilities?.length) {
        let availableDates = []

        for (const appointmentDate of result.data.availabilities) {
          const convertedDate = dayjs(appointmentDate.appointmentDateTimeLocal).tz(
            organizationTimeZone
          )

          const convertedDateTime = convertedDate.format('hh:mm A')

          if (!organization.restrictions.allowAppointmentsOutsideWorkingHours) {
            const day = convertedDate.format('dddd')

            if (!organization.workingHours[day]?.isOpen) continue

            const startTime = organization.workingHours[day]?.from
            const endTime = organization.workingHours[day]?.to

            const isInBetween =
              dayjs(convertedDateTime, 'hh:mm A').isAfter(
                dayjs(startTime, 'hh:mm A')
              ) &&
              dayjs(convertedDateTime, 'hh:mm A').isBefore(dayjs(endTime, 'hh:mm A'))

            if (!isInBetween) continue
          }

          availableDates.push({
            label: convertedDateTime,
            value: convertedDateTime,
          })
        }

        if (availableDates.length) {
          setappointmentsTimesList(availableDates)
          setDateTime(availableDates[0]?.value)
          setisXtimeAppointmentFinalised(true)
        } else {
          setDateTime(null)
          notifyError(
            getText(
              'TEXT_NO_AVAILABLE_SLOTS_FOR_THE_DAY_YOU_PICKED_PLEASE_TRY_ANOTHER_DATE'
            )
          )
        }
      } else {
        setDateTime(null)
        notifyError(
          getText(
            'TEXT_NO_AVAILABLE_SLOTS_FOR_THE_DAY_YOU_PICKED_PLEASE_TRY_ANOTHER_DATE'
          )
        )
      }
    }
  }

  useEffect(() => {
    if (
      vehiclesInfo?.vehicle &&
      vehiclesInfo?.typeOfService &&
      vehiclesInfo?.customerInterestedIn === SERVICE &&
      isUsingXtime &&
      selectedLocation?.xrmType === XTIME &&
      calendarDate
    ) {
      getAvailableAppointmentsTime()
    }
  }, [selectedLocation, vehiclesInfo, isUsingXtime, calendarDate])

  const handleSave = async () => {
    setsaving(true)
    let obj = item
    if (!obj) {
      obj = {}
    }
    obj.createOutgoingVoiceAICall = createOutgoingVoiceAICall
    if (createOutgoingVoiceAICall) {
      if (assistantsList.length > 1) {
        if (!selectedAssistant) {
          notifyError('Please select AI agent')
          setsaving(false)
          return
        }
        obj.assistant_id = selectedAssistant
      } else {
        obj.assistant_id = assistantsList[0]._id
      }
    }
    const convertedDate = utilDate.getCurrentDateByDayJS(calendarDate, FORMAT_DATE)
    obj.date = new Date(`${convertedDate.replace(/-/g, '/')} ${dateTime}`)
    if (selectedLocation._id) {
      let result
      if (conversation.isNew) {
        obj.conversationId = conversation._id
      }
      if (!item || !item._id) {
        if (vehiclesInfo.customerInterestedIn) {
          obj.customerInterestedIn = vehiclesInfo.customerInterestedIn
        }
        if (vehiclesInfo.vehicle) {
          obj.vehicleId = vehiclesInfo.vehicle._id
          if (conversation.isNew) {
            delete vehiclesInfo.vehicle.label
            delete vehiclesInfo.vehicle.value
            conversation.receiver.vehicle = vehiclesInfo
            obj.data = conversation.receiver
          }
        }
        if (vehiclesInfo.customerInterestedIn) {
          obj.customerInterestedIn = vehiclesInfo.customerInterestedIn
        }
        if (conversation.kind === SMS || conversation.isNew) {
          if (msgChangeEmailSMSType === EMAIL) {
            obj.customerId = conversation._receiver_id
          } else {
            let phone = conversation.receiver.phone
            phone = phone.replace('+', '')
            obj.phone = phone
          }
        } else {
          obj.messengerId = conversation.receiver.messengerId
        }

        if (isUsingXtime) {
          obj.serviceInfo = {
            crmServiceId: vehiclesInfo.typeOfService.opcode,
            originalServiceName: vehiclesInfo.typeOfService.serviceName,
          }
          obj.xrmType = XTIME
        }

        result = await customerActions.sendCalendarInvitation(
          obj,
          selectedLocation._id,
          conversation
        )
      } else {
        result = await customerActions.updateCalendarInvitation(
          obj,
          fromAppointment ? obj._customer_id : conversation.receiver._id,
          fromAppointment ? obj._location_id : selectedLocation._id
        )
      }
      if (result.success) {
        let mmsg = item
          ? getText('NTF_SUCCESS_SEND_BOOKING_INVITATION')
          : getText('NTF_SUCCESS_SEND_BOOKING_INVITATION_NEW')

        notifySuccess(
          mmsg
            .replace('[Customer]', conversation?.receiver?.fullName || 'customer')
            .replace('[date]', formatDateAppoinment(obj.date))
            .replace('[time]', formatTimeAppoinment(obj.date))
        )

        if (!fromAppointment) {
          onConfirmMessageTabBooking?.()
          setsaving(false)
        } else {
          setsaving(false)
          onConfirm && onConfirm(result.data)
        }
        onRemove && onRemove()
        onCloseMobileDesk && onCloseMobileDesk()
        const { createdAt, fullName, organization, location, _id: userId } = user
        setMixpanel(
          mixpanel,
          'Calendar invite sent',
          createdAt,
          fullName,
          organization.name,
          location.name,
          null,
          organization?._id,
          location?._id,
          userId
        )
      } else {
        notifyError(getTextServerError(result.errMsg))
        setsaving(false)
      }
    }
  }

  useEffect(() => {
    if (visible) {
      onModalChange?.(visible)
    } else {
      setTimeout(() => {
        onModalChange?.(visible)
      }, 500)
    }
  }, [visible])

  return (
    <Flex vertical gap={16} ref={addAppointmentEndRef}>
      <MyDefaultSelectLocationByLocation
        label={getText('WORD_LOCATION')}
        idLocation={selectedLocation._id}
        onGetItem={async (item) => {
          setselectedLocation(item)
          if (item?.xrmType === XTIME) {
            setVehiclesInfo({
              ...vehiclesInfo,
              customerInterestedIn: SERVICE,
            })
          } else {
            setisUsingXtime(false)
          }
        }}
        onSelectItem={async (item) => {
          setselectedLocation(item)
        }}
        disabled={item}
      />
      {Boolean(selectedLocation?.xrmType === XTIME && user.isSuperAdmin) && (
        <MyDefaultCheckbox
          value={isUsingXtime}
          title={getText('TEXT_USE_XTIME')}
          onChange={(e) => {
            setisUsingXtime(e.target.checked)
            if (e.target.checked) {
              setDateTime(null)
              setVehiclesInfo({
                ...vehiclesInfo,
                customerInterestedIn: SERVICE,
              })
            }
          }}
        />
      )}

      {Boolean(assistantsList?.length && includeVoiceAIConfirmationBlock) && (
        <Flex vertical gap={16}>
          <MyDefaultCheckbox
            title={getText('TEXT_VOICE_AI_APPOINTMENT_CONFIRMATION')}
            onChange={() => {
              setcreateOutgoingVoiceAICall((ov) => !ov)
            }}
            value={createOutgoingVoiceAICall}
          />
          {Boolean(assistantsList?.length > 1 && createOutgoingVoiceAICall) && (
            <MyDefaultSelect
              isFirst
              options={assistantsList.map((assistant) => {
                return { label: assistant.assistantName, value: assistant._id }
              })}
              value={selectedAssistant}
              onChange={setselectedAssistant}
            />
          )}
        </Flex>
      )}
      {!Boolean(item) && (
        <React.Fragment>
          <MyDefaultSelect
            label={getText('WORD_TYPE')}
            fullWidth
            required={isUsingXtime}
            value={vehiclesInfo.customerInterestedIn}
            disabled={isUsingXtime}
            options={[
              {
                label: getText('WORD_SALES'),
                value: SALES,
              },
              {
                label: getText('WORD_SERVICE'),
                value: SERVICE,
              },
            ]}
            onChange={(value) => {
              setVehiclesInfo({
                ...vehiclesInfo,
                vehicle: value === SALES ? '' : vehiclesInfo.vehicle,
                customerInterestedIn: value,
              })
            }}
            onClear={() => {
              setVehiclesInfo({
                ...vehiclesInfo,
                customerInterestedIn: '',
              })
            }}
          />
          {vehiclesInfo.customerInterestedIn === SERVICE && (
            <React.Fragment>
              <MyDefaultSelect
                fullWidth
                onClear={() => {
                  setVehiclesInfo({
                    ...vehiclesInfo,
                    vehicle: '',
                  })
                }}
                required={isUsingXtime}
                label={getText('WORD_SELECT_A_VEHICLE')}
                options={
                  vehicleOfInterestList?.map((obj) => {
                    return {
                      ...obj,
                      label: utilVehicle.getVehicleLabel(obj),
                      value: obj._id,
                    }
                  }) || []
                }
                onChangeItem={(_, item) => {
                  setVehiclesInfo({
                    ...vehiclesInfo,
                    vehicle: item,
                    typeOfService: isUsingXtime ? '' : vehiclesInfo.typeOfService,
                  })
                  getVehicleServices(item)
                  if (isUsingXtime) {
                    setDateTime(null)
                  }
                }}
                dropdownRender={(menu, index) => {
                  return (
                    <React.Fragment key={index}>
                      {menu}
                      <Flex
                        align='center'
                        style={{
                          color: 'var(--mainColor)',
                          padding: '10px',
                          fontWeight: 500,
                        }}
                        onClick={() => {
                          setVisible(true)
                        }}
                      >
                        + {getText('WORD_ADD_NEW_VEHICLE')}
                      </Flex>
                    </React.Fragment>
                  )
                }}
              />
              {Boolean(isUsingXtime && vehiclesInfo?.vehicle) && (
                <MyDefaultSelect
                  fullWidth
                  required={isUsingXtime}
                  label={getText('TEXT_TYPE_OF_SERVICE')}
                  options={vehicleAvailableServiceList}
                  onChangeItem={(_, item) => {
                    setVehiclesInfo({
                      ...vehiclesInfo,
                      typeOfService: item,
                    })
                    if (isUsingXtime) {
                      setDateTime(null)
                    }
                  }}
                  value={vehiclesInfo?.typeOfService?.opcode}
                />
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
      <Flex gap={8} vertical={isMobile || isVertical}>
        <Flex vertical>
          <MyDefaultBlockSubtitle subtitle={getText('WORD_DATE')} />
          <MyDefaultDatePicker
            allowClear={false}
            minDate={utilDate.endDateByDayJS()}
            value={utilDate.getDateByDayJS(calendarDate, 'MMM DD, YYYY')}
            format={'MMM DD, YYYY'}
            onChange={(date) => {
              setCalendarDate(date)
            }}
          />
        </Flex>
        <Flex vertical>
          <MyDefaultBlockSubtitle subtitle={getText('WORD_TIME')} />
          {Boolean(isUsingXtime) ? (
            <MyDefaultSelect
              selectStyle={{ minWidth: 166 }}
              options={appointmentsTimesList}
              suffixIcon={<SVGTimePickerIcon />}
              value={dateTime}
              onChange={(val) => {
                setDateTime(val)
              }}
            />
          ) : (
            <MyDefaultCustomTimePicker
              fullWidth={isVertical}
              hourValueInit={hoursValue}
              minuteValueInit={minutesValue}
              systemHourInit={timePeriod}
              index={1}
              onChange={(val) => {
                setDateTime(val)
              }}
            />
          )}
        </Flex>
      </Flex>
      <UtcTimezoneMessage title={getText('TEXT_APPOINTMENT_TIME_ZONE')} />
      <Flex gap={12} style={{ marginTop: 8 }}>
        {fromAppointment ? (
          <ConfirmPopup
            className={overlayClassName}
            title={getText('TEXT_ARE_YOU_SURE_YOU_WANT_TO_CANCEL_THIS_APPOINTMENT')}
            okText={getText('WORD_YES')}
            cancelText={getText('WORD_NO')}
            onConfirm={async () => {
              setsavingDelete(true)
              if (item) {
                let result = await customerActions.deleteCalendarInvitation(
                  item._id,
                  item._customer_id,
                  item._location_id
                )
                if (result.success) {
                  handleOnDelete && handleOnDelete(item)
                  notifySuccess(
                    getText('NTF_SUCCESS_DELETED_BOOKING_INVITATION')
                      .replace(
                        '[Customer]',
                        item.customer && item.customer.fullName
                          ? item.customer.fullName
                          : getText('WORD_CUSTOMER').toLowerCase()
                      )
                      .replace('[date]', formatDateTimeAppoinment(item.eventStart))
                  )
                } else {
                  notifyError(result.errMsg)
                }
              }
              setsavingDelete(false)
            }}
            placement={placement || 'leftTop'}
            trigger={
              <MyDefaultButton
                loading={savingDelete}
                buttonText={getText('ACTION_DELETE')}
                typeButton={'cancel'}
                fullWidth
              />
            }
          />
        ) : (
          <MyDefaultButton
            onClick={() => {
              onCancel && onCancel()
            }}
            disabled={saving}
            buttonText={getText('ACTION_CANCEL')}
            typeButton={'cancel'}
            fullWidth
          />
        )}
        {item ? (
          <ConfirmPopup
            className={overlayClassName}
            title={getText('MSG_UPDATE_BOOKING_INVITATION')}
            onConfirm={handleSave}
            onCancel={() => {
              onCancel && onCancel()
            }}
            okText={getText('WORD_YES')}
            cancelText={getText('WORD_NO')}
            placement={placement || 'leftTop'}
            trigger={
              <MyDefaultButton
                loading={saving}
                buttonText={getText('ACTION_UPDATE')}
                fullWidth
              />
            }
          />
        ) : (
          <ConfirmPopup
            title={`${getText('TEXT_ARE_YOU_SURE_THIS_APPOINTMENT_IS_FOR')} ${
              selectedLocation.name || ' '
            }?`}
            onConfirm={handleSave}
            okText={getText('WORD_YES')}
            cancelText={getText('WORD_NO')}
            trigger={
              <MyDefaultButton
                loading={saving}
                buttonText={getText('ACTION_SEND')}
                fullWidth
                disabled={isUsingXtime && !isXtimeAppointmentFinalised}
              />
            }
          />
        )}
      </Flex>
      {!Boolean(item) && (
        <MessageTabInfoOwnedCarsModal
          visible={visible}
          setVisible={setVisible}
          _receiver_id={conversation?._receiver_id}
          obj={vehicleOfInterest}
          item={''}
          isAvailable
          onSave={(data) => {
            const updatedService = [
              data,
              ...(vehicleOfInterest?.data?.service?.length && !conversation?.isNew
                ? vehicleOfInterest.data.service
                : []),
            ]
            authActions.setVehicleOfInterest({
              ...vehicleOfInterest,
              data: { service: updatedService },
            })
          }}
          convIsNew={conversation?.isNew}
        />
      )}
    </Flex>
  )
}

export default memo(RenderBookingEdit)
