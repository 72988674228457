import React from 'react'
import { Form } from 'antd'
import FinalViewOptions from './FinalViewOptions'
import LocationsOptions from './LocationsOptions'
import CustomQuestionSelector from './CustomQuestionSelector'
import QuestionsLocationsSelector from './QuestionsLocationsSelector'

const StepOptions = ({ field, form }) => {
  const step = Form.useWatch(['steps', field.name], form)

  return (
    <React.Fragment>
      {step?.type === 'Locations' && <LocationsOptions field={field} form={form} />}
      {step?.type === 'Final View' && <FinalViewOptions field={field} />}
      {step?.type === 'Custom Questions' && (
        <CustomQuestionSelector field={field} form={form} />
      )}
      {step?.type === 'Questions -> Locations' && (
        <QuestionsLocationsSelector field={field} form={form} />
      )}
    </React.Fragment>
  )
}

export default StepOptions
