import React, { memo, useEffect, useMemo, useState } from 'react'
import { Empty, Flex, List } from 'antd'
import { useSelector } from 'react-redux'
import SVGCloseRound from '../../../../../icons/SVG/SVGCloseRound'
import { getText } from '../../../../../lang'
import customerActions from '../../../../../store/modules/customerActions'
import {
  formatDateTimeAppoinment,
  getCustomerPhoneInvlid,
} from '../../../../../utils'
import { notifyError, notifySuccess } from '../../../../../utils/Notify'
import MatButton from '../../../../../components/MatButton'
import { RED, YELLOW } from '../../../../../devOptions'
import SVGMyDefaultEditIcon from '../../../../../icons/SVG/SVGMyDefaultEditIcon'
import ConfirmPopup from '../../../../../components/ConfirmPopup'
import {
  userSelector,
  vehicleOfInterestSelector,
} from '../../../../../store/selectors/selectors'
import MyDefaultRow from '../../../../../components/MyDefaultRow/MyDefaultRow'
import { EMAIL } from '../../../utils/constants'
import useDeviceCheck from '../../../../../utils/useDeviceCheck'
import RenderBookingEdit from './RenderBookingEdit'

const MessageTabBooking = ({
  conversation,
  isAwaitingConsent,
  msgChangeEmailSMSType,
}) => {
  const { isMobile } = useDeviceCheck()

  let vehicleOfInterest = useSelector(vehicleOfInterestSelector)

  const vehicleOfInterestList = useMemo(() => {
    return vehicleOfInterest?.data ? [...(vehicleOfInterest.data.service || [])] : []
  }, [vehicleOfInterest])

  let user = useSelector(userSelector)

  const [listBooking, setlistBooking] = useState([])
  const [loading, setloading] = useState(false)
  const [editingIndex, setEditingIndex] = useState(false)

  let receiver = conversation.receiver

  useEffect(() => {
    setloading(true)
    if (conversation && receiver) {
      setlistBooking(receiver.appointments)
    }
    setloading(false)
  }, [conversation._id, receiver])

  useEffect(() => {
    setEditingIndex(false)
  }, [conversation._id])

  const handleOnDelete = async (item) => {
    if (item) {
      let result = await customerActions.deleteCalendarInvitation(
        item._id,
        receiver._id,
        item._location_id
      )
      if (result.success) {
        notifySuccess(
          getText('NTF_SUCCESS_DELETED_BOOKING_INVITATION')
            .replace(
              '[Customer]',
              receiver.fullName || getText('WORD_CUSTOMER').toLowerCase()
            )
            .replace('[date]', formatDateTimeAppoinment(item.eventStart))
        )
        setlistBooking(result.data.appointments)
      } else {
        notifyError(result.errMsg)
      }
    }
  }

  const isCustomerRedStatus = conversation?.receiver?.status?.state === RED
  const isCustomerYellowStatusAndRestricted =
    conversation?.receiver?.status?.state === YELLOW &&
    !user.organization.restrictions?.allowTextingCustomersInPendingStatus
  const isCustomerPhoneNumberValid =
    (conversation.receiver &&
      conversation.receiver.phoneStatus &&
      !conversation.receiver.phoneStatus.valid) ||
    false

  const isCustomerPhoneTemporaryBlocked = Boolean(
    getCustomerPhoneInvlid(conversation)
  )

  return (
    <div className='message-tab-pane-wrapper'>
      <List
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={getText('WORD_NO_DATA')}
            />
          ),
        }}
        loading={loading}
        dataSource={listBooking}
        header={
          <div className='tab-note-header'>
            {getText('WORD_APPOINTMENTS_DATE')}
            {
              <MatButton
                disabled={
                  isCustomerPhoneNumberValid ||
                  isCustomerRedStatus ||
                  isCustomerYellowStatusAndRestricted ||
                  conversation.is_archived ||
                  isCustomerPhoneTemporaryBlocked ||
                  isAwaitingConsent ||
                  (conversation?.isNew && msgChangeEmailSMSType === EMAIL)
                }
                onClick={() => {
                  setEditingIndex('new')
                }}
                tooltip={isAwaitingConsent}
                tooltipTitle={getText(
                  'TEXT_YOU_CANT_TEXT_CUSTOMERS_WHILE_AWAITING_CONSENT'
                )}
                buttonText={`+ ${getText('WORD_ADD_NEW')}`}
              />
            }
          </div>
        }
        footer={
          editingIndex === 'new' ? (
            <RenderBookingEdit
              item={null}
              placement={isMobile && 'topLeft'}
              conversation={conversation}
              msgChangeEmailSMSType={msgChangeEmailSMSType}
              onCancel={() => {
                setEditingIndex(false)
              }}
              onConfirmMessageTabBooking={() => {
                setEditingIndex(false)
              }}
              includeVoiceAIConfirmationBlock
              isVertical
            />
          ) : null
        }
        renderItem={(item, index) => {
          const findVehicle =
            item._vehicle_id &&
            vehicleOfInterestList.find((vehicle) => vehicle._id === item._vehicle_id)
          let isDeleted = item.action === 'deleted'
          return (
            <List.Item
              key={item && item._id ? item._id : index}
              className='note-list-item'
            >
              {editingIndex === index ? (
                <RenderBookingEdit
                  placement={isMobile && 'topLeft'}
                  item={item}
                  conversation={conversation}
                  msgChangeEmailSMSType={msgChangeEmailSMSType}
                  onCancel={() => {
                    setEditingIndex(false)
                  }}
                  onConfirmMessageTabBooking={() => {
                    setEditingIndex(false)
                  }}
                  includeVoiceAIConfirmationBlock
                  isVertical
                />
              ) : (
                <React.Fragment>
                  <span className='appointment_info'>
                    {formatDateTimeAppoinment(item.eventStart)}
                    {item.customerInterestedIn && (
                      <MyDefaultRow
                        leftSide={getText(
                          item.customerInterestedIn === 'sales'
                            ? 'WORD_SALES'
                            : 'WORD_SERVICE'
                        )}
                        rightSide={`${getText('WORD_TYPE')}:`}
                        justify={'flex-end'}
                        direction={'row-reverse'}
                        gap={5}
                      />
                    )}
                    {findVehicle && (
                      <MyDefaultRow
                        gap={5}
                        leftSide={`${findVehicle.make} ${findVehicle.model} ${!findVehicle.make && !findVehicle.modal ? findVehicle.vin : ''}`}
                        rightSide={`${getText('WORD_VEHICLE')}:`}
                        justify={'flex-end'}
                        direction={'row-reverse'}
                      />
                    )}
                  </span>
                  {isDeleted ? (
                    <div className='canceled-label'>{getText('WORD_CANCELED')}</div>
                  ) : (
                    Boolean(!conversation.is_archived && !isAwaitingConsent) && (
                      <Flex align='center' gap={5}>
                        {Boolean(!item?.xrmAppointmentId) && (
                          <SVGMyDefaultEditIcon
                            onClick={() => {
                              setEditingIndex(index)
                            }}
                          />
                        )}
                        <ConfirmPopup
                          title={getText(
                            'TEXT_ARE_YOU_SURE_YOU_WANT_TO_CANCEL_THIS_APPOINTMENT'
                          )}
                          onConfirm={() => {
                            handleOnDelete(item)
                          }}
                          okText={getText('WORD_YES')}
                          cancelText={getText('WORD_NO')}
                          placement='leftTop'
                          trigger={<SVGCloseRound />}
                        />
                      </Flex>
                    )
                  )}
                </React.Fragment>
              )}
            </List.Item>
          )
        }}
      />
    </div>
  )
}
export default memo(MessageTabBooking)
