import { useState, useEffect } from 'react'

export const useLiveTranscript = ({ vogentCall, skip = false }) => {
  const [transcript, setTranscript] = useState(null)

  useEffect(() => {
    if (!vogentCall || skip) return

    const cancel = vogentCall.monitorTranscript((transcript) => {
      setTranscript(transcript)
    })

    return () => {
      cancel()
    }
  }, [vogentCall, setTranscript, skip])

  return {
    transcript: transcript,
  }
}
