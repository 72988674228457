import React from 'react'
import { Flex } from 'antd'
import MyDefaultBlockTitle from '../../../../components/MyDefaultBlock/MyDefaultBlockTitle'
import { getText } from '../../../../lang'
import MyDefaultInputText from '../../../../components/MyDefaultInputText/MyDefaultInputText'
import MyDefaultColorPicker from '../../../../components/MyDefaultColorPicker/MyDefaultColorPicker'

const ButtonStylesPicker = ({
  title,
  inputName,
  onChangeInput,
  backgroundColorValue,
  onChangeBackgroundColor,
  colorValue,
  onChangeColor,
}) => {
  return (
    <React.Fragment>
      <MyDefaultBlockTitle title={title} />
      <MyDefaultInputText
        isForm
        label={getText('WORD_TITLE')}
        name={inputName}
        required
        onChange={onChangeInput}
      />
      <Flex align='center' gap={50}>
        <MyDefaultColorPicker
          label={getText('LABEL_BACKGROUND_COLOR')}
          value={backgroundColorValue}
          onChangeColor={onChangeBackgroundColor}
        />
        <MyDefaultColorPicker
          label={getText('LABEL_TEXT_COLOR')}
          value={colorValue}
          onChangeColor={onChangeColor}
        />
      </Flex>
    </React.Fragment>
  )
}

export default ButtonStylesPicker
