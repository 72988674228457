import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Select } from 'antd'
import MyDefaultSelect from '../../../../../components/MyDefaultSelect/MyDefaultSelect'
import { userSelector } from '../../../../../store/selectors/selectors'
import organizationActions from '../../../../../store/modules/organizationActions'
import { getUserRole } from '../../../../../utils'
import { transformData } from '../../../../../components/MyDefaultTreeSelect/MyDefaultTreeSelectLocationOrganization/MyDefaultTreeSelectLocationOrganization'

const CustomQuestionSelector = ({ field }) => {
  const user = useSelector(userSelector)

  const [data, setdata] = useState({ total: 0, list: [] })
  const [isLoading, setisLoading] = useState(true)

  useEffect(() => {
    const fill = async () => {
      let arr = []
      let total = 0
      let page = 0

      const getData = async () => {
        const result = await organizationActions.getOrganizationsLocationsList(
          page,
          100,
          '',
          {
            enabled: true,
          },
          false,
          getUserRole(
            user.isSuperAdmin,
            user.isAdmin,
            user.isManager,
            user.isSupportAgent
          ),
          user._location_id,
          true
        )

        if (result.success) {
          const list = transformData(result.data)
          arr = page === 0 ? list : [...arr, ...list]
          total = result.max
          if (arr.length < result.max) {
            page++
            await getData()
          }
        }
        setisLoading(false)
      }
      await getData()
      setdata({
        list: arr,
        total: total,
      })
    }
    fill()
  }, [])

  return (
    <MyDefaultSelect
      isForm
      name={[field.name, 'customQuestions']}
      customOption
      mode={'multiple'}
      loading={isLoading}
      required
      message={`Step ${field.name + 1}: Please select at least 1 question`}
    >
      {data.list.map((organization) =>
        organization.children.map((location) =>
          location.questions.map((question) => {
            return (
              <Select.Option
                key={question._id}
                value={JSON.stringify({
                  organizationId: question?._organization_id,
                  locationId: question?._location_id,
                  questionId: question?._id,
                  organizationName: organization?.title,
                  locationName: location?.title,
                  question: question?.question.en,
                })}
              >
                {`${organization.title} -  ${location.title} - ${question.question.en}`}
              </Select.Option>
            )
          })
        )
      )}
    </MyDefaultSelect>
  )
}

export default CustomQuestionSelector
