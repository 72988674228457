import React, { useEffect, useState } from 'react'
import MyDefaultModal from '../../../../../components/MyDefaultModal/MyDefaultModal'
import SVGIntegrationModalSettings from '../../../../../icons/SVG/SVGIntegrationModalSettings'
import { getText } from '../../../../../lang'
import MatForm from '../../../../../components/Form/MatForm'
import { XTIME } from '../../../../../config/constants'
import MyDefaultSelect from '../../../../../components/MyDefaultSelect/MyDefaultSelect'
import MyDefaultInputText from '../../../../../components/MyDefaultInputText/MyDefaultInputText'

const XrmModal = ({
  loading,
  visible,
  setVisible,
  form,
  onFinish,
  onFinishFailed,
  location,
  hideSubmit,
  selectedXrmType,
  selectTypeOptions,
}) => {
  const [selectedXrmTypeLoc, setselectedXrmTypeLoc] = useState({})

  useEffect(() => {
    setselectedXrmTypeLoc(selectedXrmType)

    form.setFieldsValue({
      xrmType: location?.xrmType || 'none',
      xrmConfigs: {
        [XTIME]: {
          dealerCode: location.xrmConfigs?.xTime?.dealerCode || '',
        },
      },
    })
  }, [visible, selectedXrmType])

  return (
    <MyDefaultModal
      trigger={<SVGIntegrationModalSettings />}
      title={getText('TEXT_CONNECT_TO_SERVICE_SCHEDULER')}
      confirmLoading={loading}
      forceRender={false}
      visible={visible}
      onTrrigerClick={() => {
        setVisible(true)
        form.resetFields()
      }}
      onCancel={() => {
        setVisible(false)
        form.resetFields()
      }}
      hideSubmit={hideSubmit}
      okText={getText('ACTION_SAVE')}
    >
      <MatForm form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <MyDefaultSelect
          isForm
          label={getText('WORD_XRM_TYPE')}
          name={'xrmType'}
          options={selectTypeOptions}
          onChangeItem={(_, typeObj) => {
            setselectedXrmTypeLoc(typeObj)
          }}
          getPopupContainer={() => document.body}
        />

        {selectedXrmTypeLoc.value === XTIME && (
          <MyDefaultInputText
            isForm
            fullWidth
            label={getText('WORD_DEALER_ID')}
            name={['xrmConfigs', 'xTime', 'dealerCode']}
            required
            errorMessage={getText('TEXT_PLEASE_INPUT_DEALER_ID')}
            isEnterDisabled
            maxLength={25}
          />
        )}
      </MatForm>
    </MyDefaultModal>
  )
}

export default XrmModal
