import React, { memo } from 'react'
import { Tooltip } from 'antd'
import { useSelector } from 'react-redux'
import MatButton from '../../../../../components/MatButton'
import UploadFiles from '../../../../../components/UploadFiles'
import SVGMessageAtachNew from '../../../../../icons/SVG/SVGMessageAtachNew'
import { myWebViewPost } from '../../../../../utils'
import authActions from '../../../../../store/modules/authActions'
import { EMAIL } from '../../../../../devOptions'
import { getText } from '../../../../../lang'
import { isMobileAppSelector } from '../../../../../store/selectors/selectors'
import './MessageUploadFile.scss'

const UploadFileMessage = (props) => {
  const {
    setVideoUpload,
    videoUpload,
    setuploadFiles,
    uploadFiles,
    setVideoStatusUpload,
    msgChangeEmailSMSType,
    conversation,
    videoStatusUpload,
    isCustomerRedStatus,
    isCustomerYellowStatusAndRestricted,
    isCustomerPhoneNumberValid,
    setFocusOverInput,
    onClose,
    mobileDeskSize,
    uploadingStatus,
    setUploadingStatus,
  } = props

  const isMobileApp = useSelector(isMobileAppSelector)

  return isMobileApp ? (
    <MatButton
      loading={videoStatusUpload === 'uploading' || uploadingStatus === 'uploading'}
      icon={<SVGMessageAtachNew />}
      buttonText={mobileDeskSize ? getText('WORD_ATTACHMENT') : ''}
      className={`icon-size only-stroke mobile-upload ${
        uploadFiles.length || videoUpload.length ? 'active' : ''
      }`}
      disabled={
        msgChangeEmailSMSType === EMAIL ||
        isCustomerPhoneNumberValid ||
        isCustomerRedStatus ||
        isCustomerYellowStatusAndRestricted ||
        uploadFiles.length >= 10 ||
        conversation.kind === 'gbm'
      }
      onClick={() =>
        myWebViewPost(
          {
            accessToken: authActions.getTokenData().accessToken,
            targetId: conversation._id,
            isNew: conversation.isNew,
            mediaUploadsLimit:
              10 - uploadFiles.length > 0 ? 10 - uploadFiles.length : 0,
          },
          'UPLOAD_BUTTON_CLICKED'
        )
      }
    />
  ) : (
    <UploadFiles
      id={'msg_upload'}
      onStartUpload={(file) => {
        setUploadingStatus(file.status)
        setuploadFiles((oldList) => {
          oldList.push(file)
          return oldList
        })
      }}
      onUpload={(url, file = null, id = null) => {
        setFocusOverInput(true)
        if (url.mediaContentType.includes('video')) {
          setVideoUpload([...videoUpload, url])
        } else {
          let list = uploadFiles.map((obj) => {
            if (obj._id === id) {
              obj.mediaUrl = url
              obj.mediaContentType = file.type
              obj.status = 'done'
            }
            setUploadingStatus(obj.status)
            return obj
          })
          setuploadFiles(list)
        }
        onClose && onClose(false)
      }}
      onChangeStatus={(val) => {
        setVideoStatusUpload(val)
      }}
      accept={
        '.jpeg,.jpg,.png,.pdf,.gif,.HEIC,.heic,.HEIF,.heif,.mp4,.mov,.avi,.webm,.xls, .vcf, .xlsx'
      }
      beforeUploadProps
      mediaData={uploadFiles}
      listype={'text'}
      showUploadList={false}
      disabled={
        msgChangeEmailSMSType === EMAIL ||
        isCustomerPhoneNumberValid ||
        isCustomerRedStatus ||
        isCustomerYellowStatusAndRestricted ||
        (conversation && conversation.isNew) ||
        conversation.kind === 'gbm'
      }
      fromMessagePage
      conversation={conversation}
      targerType={'conversation'}
      targetId={conversation._id}
      customType={[{ size: 1, type: 'application/pdf' }]}
    >
      <Tooltip
        overlayStyle={{ zIndex: mobileDeskSize ? '-1000' : '10001' }}
        destroyTooltipOnHide={true}
        title={getText(
          conversation?.isNew
            ? 'TEXT_PLEASE_SEND_A_FIRST_TEXT_MESSAGE_BEFORE_INCLUDING_ANY_ATTACHMENTS'
            : 'WORD_ATTACHMENT'
        )}
      >
        <MatButton
          loading={
            videoStatusUpload === 'uploading' || uploadingStatus === 'uploading'
          }
          icon={<SVGMessageAtachNew />}
          className={`icon-size only-stroke ${
            uploadFiles.length || videoUpload.length ? 'active' : ''
          }`}
          buttonText={mobileDeskSize ? getText('WORD_ATTACHMENT') : ''}
          disabled={
            msgChangeEmailSMSType === EMAIL ||
            isCustomerPhoneNumberValid ||
            isCustomerRedStatus ||
            isCustomerYellowStatusAndRestricted ||
            conversation.kind === 'gbm'
          }
        />
      </Tooltip>
    </UploadFiles>
  )
}

export default memo(UploadFileMessage)
