import React from 'react'
import { Badge, Flex } from 'antd'
import MyDefaultModal from '../../../../../components/MyDefaultModal/MyDefaultModal'
import SVGIntegrationModalSettings from '../../../../../icons/SVG/SVGIntegrationModalSettings'
import { getText } from '../../../../../lang'
import Div from '../../../../../components/Div/Div'
import MyDefaultSelect from '../../../../../components/MyDefaultSelect/MyDefaultSelect'
import FacebookPageForms from './FacebookForms'

const FacebookIntegrationModal = ({
  selectedPage,
  visible,
  setVisible,
  selectedAccountType,
  isLoading,
  accountTypeOptions,
  setselectedAccountType,
  account,
  fbData,
  handleSelectPage,
  setSelectedPage,
  facebookConnection,
  locationId,
}) => {
  return (
    <MyDefaultModal
      trigger={
        <Badge dot={selectedPage.id === ''} size='default'>
          <SVGIntegrationModalSettings />
          {selectedPage.id === '' && (
            <Div
              style={{
                position: 'absolute',
                fontSize: 10,
                whiteSpace: 'nowrap',
                right: 0,
                color: 'var(--red)',
              }}
            >
              {getText('TEXT_PLEASE_FINISH_INTEGRATION_SETUP')}
            </Div>
          )}
        </Badge>
      }
      title={getText('ACTION_INTEGRATE_FB_INSTAGRAM_PAGE')}
      forceRender={false}
      visible={visible}
      onTrrigerClick={() => {
        setVisible(true)
      }}
      onCancel={() => setVisible(false)}
      hideFooter
      className='facebook-integration-modal'
      destroyOnClose={false}
    >
      {account.isConnected && (
        <Flex vertical gap={24}>
          <MyDefaultSelect
            value={selectedAccountType}
            loading={isLoading}
            options={accountTypeOptions}
            getPopupContainer={() => document.body}
            onSelect={(accType) => {
              setselectedAccountType(accType)
            }}
          />
          {Boolean(selectedAccountType) && (
            <MyDefaultSelect
              value={
                fbData.pages.length === 0
                  ? getText(
                      'TEXT_DURING_INTEGRATION_SETUP_NO_FB_PAGES_WERE_SELECTED'
                    )
                  : Boolean(selectedPage.name)
                    ? selectedPage.name
                    : getText('TEXT_SELECT_FACEBOOK_PAGE')
              }
              className={`select-fb-page ${
                !Boolean(selectedPage.name) ? 'no-value' : ''
              }`}
              options={fbData.pages.map((page) => ({
                label: page.name,
                value: page.id,
              }))}
              customOption
              loading={isLoading}
              disabled={fbData.pages.length === 0}
              onSelect={handleSelectPage}
              onClear={() => {
                setSelectedPage({})
              }}
            />
          )}
          {selectedPage.id && fbData.pages.length > 0 && (
            <FacebookPageForms
              account={account}
              leadForms={facebookConnection.leadForms}
              locationId={locationId}
              selectedPage={selectedPage}
            />
          )}
        </Flex>
      )}
    </MyDefaultModal>
  )
}

export default FacebookIntegrationModal
