import React, { useCallback, useEffect, useMemo } from 'react'
import { CONNECT_URL } from '../../../../../config'

import './Preview.scss'

const encodeCurrentUrl = encodeURI(window.location.href)

const ThemePreview = ({ settings, language = 'en', locationId, theme }) => {
  const getLanguage = useMemo(
    () => (language === 'es' ? 'sp' : language),
    [language]
  )

  const sendPreviewSettings = useCallback(() => {
    const getData = () => {
      return {
        ...settings,
        texts: settings.texts[language],
      }
    }

    const iframe = document.getElementById(`iframe-preview-${theme}`)
    if (!iframe) {
      return
    }
    iframe.contentWindow.postMessage(getData(), '*')
  }, [settings, theme, language])

  useEffect(() => {
    sendPreviewSettings()
  }, [sendPreviewSettings])

  if (!settings || !locationId) {
    return null
  }

  const onIframeLoad = () => {
    setTimeout(() => {
      sendPreviewSettings()
    }, 2000)
  }

  return (
    <iframe
      title='Matador Connect Preview'
      id={`iframe-preview-${theme === 'default' ? 'default' : 'light'}`}
      height='100%'
      width='100%'
      frameBorder='none'
      onLoad={onIframeLoad}
      src={`${CONNECT_URL}/chat_widget/theme=${theme === 'default' ? 'default' : 'light'}/${locationId}/${getLanguage}?url=${encodeCurrentUrl}&connect-preview-mode`}
      style={{ position: 'relative', display: 'block' }}
      allow='geolocation'
    />
  )
}

export default ThemePreview
