import React from 'react'
import { Flex } from 'antd'
import { getText } from '../../lang'
import VoiceAICallControls from './VoiceAICallControls'

const VoiceAITranscription = ({
  transcript,
  vogentCallRef,
  muted,
  callStatus,
  vogentAudioConn,
  onConnectAudio,
}) => {
  return transcript ? (
    <React.Fragment>
      {callStatus.status === 'in-progress' ? (
        <VoiceAICallControls
          vogentAudioConn={vogentAudioConn}
          connectAudio={onConnectAudio}
          callStatus={callStatus.status}
          vogentCall={vogentCallRef.current}
          muted={muted}
        />
      ) : callStatus.status === 'completed' ? (
        <span style={{ textAlign: 'center', fontSize: 16 }}>
          {getText('WORD_COMPLETED')}
        </span>
      ) : null}

      <Flex vertical gap={10}>
        {transcript.map((t) => (
          <Flex vertical gap={0}>
            <p style={{ color: 'var(--thridyTextColor)' }}>{t.speaker}</p>
            <span style={{ fontWeight: 400 }}>{t.text}</span>
          </Flex>
        ))}
      </Flex>
    </React.Fragment>
  ) : (
    <Flex align={'center'} justify={'center'}>
      {getText('TEXT_NO_TRANSCRIPT_YET')}
    </Flex>
  )
}

export default VoiceAITranscription
