import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = () => (
  <svg
    width='22'
    height='22'
    viewBox='0 0 22 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g id='Iconly/Light/Chat'>
      <g id='vuesax/linear/notification-bing'>
        <g id='notification-bing'>
          <path
            id='Vector'
            d='M11 5.90332V8.95582'
            stroke='#0E0E0E'
            strokeWidth='1.5'
            strokeMiterlimit='10'
            strokeLinecap='round'
          />
          <path
            id='Vector_2'
            d='M11.018 1.83325C7.64466 1.83325 4.913 4.56492 4.913 7.93825V9.86325C4.913 10.4866 4.65633 11.4216 4.3355 11.9533L3.17133 13.8966C2.45633 15.0974 2.95133 16.4358 4.27133 16.8758C8.653 18.3333 13.3922 18.3333 17.7738 16.8758C19.0113 16.4633 19.543 15.0149 18.8738 13.8966L17.7097 11.9533C17.3888 11.4216 17.1322 10.4774 17.1322 9.86325V7.93825C17.123 4.58325 14.373 1.83325 11.018 1.83325Z'
            stroke='#0E0E0E'
            strokeWidth='1.5'
            strokeMiterlimit='10'
            strokeLinecap='round'
          />
          <path
            id='Vector_3'
            d='M14.0523 17.2517C14.0523 18.9292 12.6773 20.3042 10.9998 20.3042C10.1656 20.3042 9.3956 19.9559 8.8456 19.4059C8.2956 18.8559 7.94727 18.0859 7.94727 17.2517'
            stroke='#0E0E0E'
            strokeWidth='1.5'
            strokeMiterlimit='10'
          />
        </g>
      </g>
    </g>
  </svg>
)

const SVGSideNavigationNotafSettings = (props) => (
  <Icon component={SVGIcon} {...props} />
)

export default SVGSideNavigationNotafSettings
