import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = () => {
  return (
    <svg
      width='15'
      height='14'
      viewBox='0 0 15 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask
        id='mask0_27999_22023'
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='15'
        height='14'
      >
        <path d='M0.5 0H14.5V14H0.5V0Z' fill='white' />
      </mask>
      <g mask='url(#mask0_27999_22023)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M13.4482 8.78702C14.8506 7.993 14.8506 6.00879 13.4482 5.21395L6.28445 1.15557C4.88119 0.360726 3.125 1.35366 3.125 2.94251V11.0593C3.125 12.6481 4.88119 13.6411 6.28445 12.8454L13.4482 8.78702Z'
          fill='#48AF80'
        />
      </g>
    </svg>
  )
}

const SVGPlayIconWithoutBg = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGPlayIconWithoutBg
