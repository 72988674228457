import React from 'react'
import Div from '../../../../../../components/Div/Div'
import MyDefaultColorPicker from '../../../../../../components/MyDefaultColorPicker/MyDefaultColorPicker'

const WidgetTextarea = ({
  value,
  color,
  fontSize,
  onChange,
  onChangeColor,
  rows = '4',
  enableMargin,
  isInput,
}) => {
  return (
    <Div
      className={`widget_textarea_wrapper ${enableMargin ? 'enable_margin' : ''}`}
    >
      <Div className='my_custom_textarea'>
        {isInput ? (
          <input
            value={value}
            onChange={(e) => {
              onChange?.(e)
            }}
            style={{ color: color }}
          />
        ) : (
          <textarea
            value={value}
            style={{ color: color, fontSize: fontSize }}
            rows={rows}
            onChange={(e) => {
              onChange?.(e)
            }}
          />
        )}
      </Div>
      <MyDefaultColorPicker
        size='small'
        showText={false}
        value={color}
        onChangeColor={(newColor) => {
          onChangeColor?.(newColor)
        }}
      />
    </Div>
  )
}

export default WidgetTextarea
