import React, { useEffect, useState } from 'react'
import { Form, Modal } from 'antd'
import MatForm from '../../../../components/Form/MatForm'
import { getText, getTextServerError } from '../../../../lang'
import { replaceWords } from '../../../../utils'
import { notifyError, notifySuccess } from '../../../../utils/Notify'
import organizationActions from '../../../../store/modules/organizationActions'
import MyDefaultPageLayout from '../../../../components/MyDefaultPageLayout/MyDefaultPageLayout'
import MyDefaultBlock from '../../../../components/MyDefaultBlock/MyDefaultBlock'
import MyDefaultSelect from '../../../../components/MyDefaultSelect/MyDefaultSelect'
import MyDefaultSelectOrganization from '../../../../components/MyDefaultSelect/MyDefaultSelectOrganization'
import { useLayout } from '../../../../layout/LayoutProvider/LayoutProvider'
import { CANCEL_BTN, SUBMIT_BTN } from '../../../../devOptions'
import MyDefaultSelectSequences from '../../../../components/MyDefaultSelect/MyDefaultSelectSequences'
import './SettingsTransfer.scss'

const SettingsTransfer = () => {
  const { setPageTitle, setFooterButtons } = useLayout()

  const [selectSeveralSequences, setSelectSeveralSequences] = useState(false)

  const [form] = Form.useForm()

  const organization_id = Form.useWatch('fromOrg', form)

  useEffect(() => {
    setFooterButtons([
      {
        type: CANCEL_BTN,
        action: () => {
          form.resetFields()
        },
      },
      {
        type: SUBMIT_BTN,
        title: getText('WORD_COPY'),
      },
    ])

    setPageTitle(getText('WORD_SETTINGS_TRANSFER'))
  }, [])

  const onFinish = async (values) => {
    if (values.fromOrg.value === values.toOrg.value) {
      notifyError(getText('WORD_PLEASE_SELECT_DIFFERENT_ORGANIZATIONS'))
      return
    }
    Modal.confirm({
      title: getText('WORD_SETTINGS_TRANSFER'),
      content: (
        <React.Fragment>
          {replaceWords(getText('CONFIRMATION_COPY_ORG_SETTINGS_TITLE'), {
            fromOrg: values?.fromOrg?.label || '',
            settingName: getText(
              values.settings === 'dripSequence'
                ? 'WORD_DRIP_SEQUENCE'
                : 'WORD_TEMPLATES'
            ),
          })}
          <br />
          <br />
          {replaceWords(
            getText(
              selectSeveralSequences
                ? 'CONFIRMATION_COPY_ORG_TEMPLATES_DESCRIPTION'
                : 'CONFIRMATION_COPY_ORG_SETTINGS_DESCRIPTION'
            ),
            {
              toOrg: values?.toOrg?.label || '',
              settingName: getText(
                values.settings === 'dripSequence'
                  ? 'WORD_DRIP_SEQUENCE'
                  : 'WORD_TEMPLATES'
              ),
            }
          )}
        </React.Fragment>
      ),
      icon: null,
      okText: getText('WORD_COPY'),
      cancelText: getText('ACTION_CANCEL'),
      onOk: async () => {
        const settings = {}
        if (values.settings === 'shouldCopySeveral') {
          settings['dripSequence'] = {
            shouldCopySeveral: true,
            dripSequenceIds: values.dripSequenceIds,
          }
        } else {
          settings[values.settings] = { shouldCopy: true }
        }
        const obj = {
          settings: settings,
          toOrg: values.toOrg.value,
          fromOrg: values.fromOrg.value,
        }
        const result = await organizationActions.transferSettings(obj)
        if (result.success) {
          notifySuccess(getText('WORD_SETTINGS_TRANSFERED_SUCCESSFULLY'))
        } else {
          notifyError(result.errMsg)
        }
      },
      centered: true,
      okButtonProps: {
        type: 'primary',
        className: 'mat-btn mat-btn-primary',
      },
      className: 'confirm-alert-wrapper',
      cancelButtonProps: {
        type: 'white',
        className: 'mat-btn mat-btn-white',
      },
    })
  }

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields.length > 0) {
      notifyError(getTextServerError(errorInfo.errorFields[0].errors[0]))
    }
  }

  return (
    <MyDefaultPageLayout disableHeader>
      <MyDefaultBlock title={getText('WORD_SETTINGS_TRANSFER')}>
        <MatForm form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <MyDefaultSelectOrganization
            isForm
            name={'fromOrg'}
            label={getText('WORD_SELECT_SOURCE_ORGANIZATION_COPY_FROM')}
            required
            labelInValue
          />
          <MyDefaultSelect
            isForm
            name={'settings'}
            label={getText('WORD_SELECT_SETTINGS_TO_COPY')}
            placeholder={getText('WORD_SELECT_SETTING')}
            required
            message={getText('WORD_PLEASE_SELECT_SETTING')}
            options={[
              { label: getText('WORD_SEQUENCE'), value: 'dripSequence' },
              { label: getText('WORD_TEMPLATES'), value: 'templates' },
              {
                label: getText('TEXT_COPY_SEVERAL_SEQUENCES'),
                value: 'shouldCopySeveral',
              },
            ]}
            onChange={(value) => {
              if (value === 'shouldCopySeveral') {
                setSelectSeveralSequences(true)
              } else {
                setSelectSeveralSequences(false)
              }
            }}
          />
          {selectSeveralSequences && (
            <MyDefaultSelectSequences
              isForm
              name={'dripSequenceIds'}
              label={getText('WORD_SELECT_SEQUENCE_TO_COPY')}
              placeholder={getText('LABEL_SELECTED_SEQUENCES')}
              required
              message={getText('LABEL_SELECTED_SEQUENCES')}
              organization_id={organization_id?.value}
            />
          )}
          <MyDefaultSelectOrganization
            isForm
            name={'toOrg'}
            label={getText('WORD_SELECT_DESTINATION_ORGANIZATION_COPY_TO')}
            required
            isLast
            labelInValue
          />
        </MatForm>
      </MyDefaultBlock>
    </MyDefaultPageLayout>
  )
}

export default SettingsTransfer
