import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Divider, Flex, Form } from 'antd'
import { organizationSelector } from '../../../../../store/selectors/selectors'
import { useLocationLayout } from '../../../locationProvider/LocationProvider'
import LoadingSpinner from '../../../../../components/LoadingSpinner'
import MyDefaultPageLayout from '../../../../../components/MyDefaultPageLayout/MyDefaultPageLayout'
import { useLayout } from '../../../../../layout/LayoutProvider/LayoutProvider'
import { CANCEL_BTN, SUBMIT_BTN } from '../../../../../devOptions'
import useDeviceCheck from '../../../../../utils/useDeviceCheck'
import MyDefaultBlock from '../../../../../components/MyDefaultBlock/MyDefaultBlock'
import { getText, getTextServerError } from '../../../../../lang'
import MyDefaultBlockHeaderAdditionalButton from '../../../../../components/MyDefaultBlock/MyDefaultBlockHeaderAdditionalButton'
import MyDefaultSwitch from '../../../../../components/MyDefaultSwitch/MyDefaultSwitch'
import MatForm from '../../../../../components/Form/MatForm'
import { notifyError, notifySuccess } from '../../../../../utils/Notify'
import connectFlowActions from '../../../../../store/modules/connectFlowActions'
import MyDefaultCollapse from '../../../../../components/MyDefaultCollapse/MyDefaultCollapse'
import locationActions from '../../../../../store/modules/locationActions'
import SVGMyDefaultDeleteIcon from '../../../../../icons/SVG/SVGMyDefaultDeleteIcon'
import MyDefaultBlockTitle from '../../../../../components/MyDefaultBlock/MyDefaultBlockTitle'
import MyDefaultSelect from '../../../../../components/MyDefaultSelect/MyDefaultSelect'
import StepHeader from './StepHeader'
import StepOptions from './StepOptions'
import FlowPreview from './FlowPreview'

const FlowDesignerScreen = () => {
  const { locationObj } = useLocationLayout()
  const { isPageDrawerSize } = useDeviceCheck()
  const { setFooterButtons, setLoading, setPageTitle } = useLayout()

  const organization = useSelector(organizationSelector)

  const [isLoading, setIsLoading] = useState(true)

  const stepActions = useRef(null)

  const [form] = Form.useForm()

  const steps = Form.useWatch('steps', form)

  const getTypes = useMemo(() => {
    return [
      { value: null, label: getText('WORD_SELECT_TYPE') },
      { value: 'Locations', label: 'Locations' },
      { value: 'Questions -> Locations', label: 'Questions -> Locations' },
      { value: 'Custom Questions', label: 'Custom Questions' },
      { value: 'Final View', label: 'Final View' },
    ]
  }, [])

  useEffect(() => {
    setFooterButtons([
      {
        type: CANCEL_BTN,
      },
      {
        type: SUBMIT_BTN,
      },
    ])
    setPageTitle(getText('WORD_FLOW_DESIGNER'))
  }, [])

  const getLocationsByQuestion = async (question) => {
    const result = await locationActions.getLocationsByQuestion({
      text: question,
      language: 'en',
    })

    if (result.success) {
      return result?.data.questions
    } else {
      return []
    }
  }

  const initialDataTransformer = async (result) => {
    for (const step of result?.data?.steps || []) {
      if (step.type === 'Locations') {
        for (const location of step?.locations || []) {
          location.value = location.location._id
          location.label = location.location.name
        }
      }
      if (step.type === 'Custom Questions') {
        for (const question of step?.customQuestions || []) {
          question.value = JSON.stringify({
            organizationId: question.organizationId,
            locationId: question.locationId,
            questionId: question.questionId,
            organizationName: question.organization.name,
            locationName: question.location.name,
            question: question.question.question.en,
          })
        }
      }
      if (step.type === 'Questions -> Locations') {
        for (const question of step?.questionsAndLocations || []) {
          question.value = JSON.stringify({
            organizationId: question.organizationId,
            locationId: question.locationId,
            questionId: question.questionId,
            organizationName: question.organization.name,
            locationName: question.location.name,
            question: question.question.question.en,
          })

          for (const location of question.locations || []) {
            location.value = JSON.stringify({
              questionId: question.questionId,
              organizationId: location.organizationId,
              locationId: location.locationId,
              name: location.location.name,
            })
          }

          question.selectedLocations = question.locations

          question.locations = await getLocationsByQuestion(
            question?.question?.question?.en
          )
        }
      }
    }

    return result
  }

  useEffect(() => {
    const fill = async () => {
      setIsLoading(true)
      let result = await connectFlowActions.getSettings(locationObj._id)
      if (result.success) {
        const finalResult = await initialDataTransformer(result)
        form.setFieldsValue(finalResult?.data)
      }
      setIsLoading(false)
    }
    if (locationObj?._id) {
      fill()
    }
  }, [locationObj?._id])

  const onFinish = async (values) => {
    setLoading(true)

    const obj = {
      enabled: values.enabled,
      steps: values.steps.map((step) => {
        return {
          description: step.description,
          showBackAction: step.showBackAction,
          title: step.title,
          type: step.type,
          ...(step.type === 'Final View' && { finalView: step.finalView }),

          ...(step.type === 'Custom Questions' && {
            customQuestions: step.customQuestions.map((question) => {
              const item =
                typeof question === 'string' ? JSON.parse(question) : question
              return {
                locationId: item.locationId,
                organizationId: item.organizationId,
                questionId: item.questionId,
              }
            }),
          }),

          ...(step.type === 'Locations' && {
            locations: step.locations.map((loc) => {
              const item = typeof loc === 'string' ? JSON.parse(loc) : loc
              return {
                locationCustomName: item.locationCustomName,
                locationId: item.locationId,
                organizationId: item.organizationId,
              }
            }),
          }),

          ...(step.type === 'Questions -> Locations' && {
            questionsAndLocations: step.questionsAndLocations.map(
              (questionAndLocation) => {
                const item =
                  typeof questionAndLocation === 'string'
                    ? JSON.parse(questionAndLocation)
                    : questionAndLocation
                return {
                  language: item.language || organization.defaultLanguage || 'en',
                  locationId: item.locationId,
                  locations: item.selectedLocations.map((loc) => {
                    const locItem = typeof loc === 'string' ? JSON.parse(loc) : loc
                    return {
                      locationCustomName: locItem.locationCustomName,
                      locationId: locItem.locationId,
                      organizationId: locItem.organizationId,
                      questionId: locItem.questionId,
                    }
                  }),
                  organizationId: item.organizationId,
                  questionId: item.questionId,
                }
              }
            ),
          }),
        }
      }),
    }

    let result
    try {
      result = await connectFlowActions.update(obj, locationObj._id)
      if (!result.success) {
        notifyError(getTextServerError(result.errMsg))
        return
      }
      notifySuccess(getText('TEXT_MATADOR_CONNECT_SAVED_SUCCESSFULLY'))
    } catch (error) {
      notifyError(getTextServerError(result.errMsg))
    }

    setLoading(false)
  }

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields.length > 0) {
      notifyError(errorInfo.errorFields[0].errors[0])
    }
  }

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <MyDefaultPageLayout disableHeader>
      <Flex gap={20} align='start' vertical={isPageDrawerSize}>
        <MatForm
          maxWidth
          fullWidth
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <MyDefaultBlock
            fullWidth
            title={getText('WORD_FLOW_DESIGNER')}
            additionalHeader={
              <MyDefaultBlockHeaderAdditionalButton
                buttonText={getText('WORD_ADD_STEP')}
                onClick={() => {
                  stepActions?.current?.add({
                    showBackAction: false,
                    title: '',
                    description: '',
                  })
                }}
              />
            }
          >
            <MyDefaultSwitch
              isForm
              name={'enabled'}
              noText
              isLast
              titleStyle={{ fontSize: 14 }}
              title={getText('TEXT_ENABLE_CUSTOM_MATADOR_CONNECT')}
            />
            {Boolean(steps?.length) && (
              <Divider style={{ margin: '12px 0 20px 0' }} />
            )}
            <Form.List name='steps'>
              {(fields, { add, remove }) => {
                stepActions.current = {
                  add,
                  remove,
                }
                return (
                  <Flex vertical gap={5}>
                    {fields.map((field, index) => {
                      return (
                        <MyDefaultCollapse
                          key={index}
                          items={[
                            {
                              key: 1,
                              label: `Step ${index + 1}`,
                              extra: (
                                <SVGMyDefaultDeleteIcon
                                  onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    remove(index)
                                  }}
                                />
                              ),
                              children: (
                                <React.Fragment>
                                  <StepHeader field={field} />
                                  <MyDefaultBlockTitle
                                    title={'Main Content Settings'}
                                  />
                                  <MyDefaultSelect
                                    isForm
                                    name={[field.name, 'type']}
                                    label={getText('WORD_TYPE')}
                                    options={getTypes}
                                    required
                                    message={`Step ${index + 1}: Please select type for Main Consent Settings`}
                                  />
                                  <StepOptions field={field} form={form} />
                                </React.Fragment>
                              ),
                            },
                          ]}
                        />
                      )
                    })}
                  </Flex>
                )
              }}
            </Form.List>
          </MyDefaultBlock>
        </MatForm>
        <MyDefaultBlock position={'sticky'} top={0} fullWidth={isPageDrawerSize}>
          <FlowPreview token={locationObj?.chatSettings?._token} steps={steps} />
        </MyDefaultBlock>
      </Flex>
    </MyDefaultPageLayout>
  )
}

export default FlowDesignerScreen
