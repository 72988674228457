import React, { useState, useEffect, useCallback, useRef } from 'react'
import { debounce } from 'lodash'
import organizationActions from '../../../store/modules/organizationActions'
import { getText } from '../../../lang'
import { TABLE_DEFAULT_ROW_NUMBER } from '../../../devOptions'
import CreateOrganization from '../create/CreateOrganization'
import MyDefaultPageLayout from '../../../components/MyDefaultPageLayout/MyDefaultPageLayout'
import { useLayout } from '../../../layout/LayoutProvider/LayoutProvider'
import MyDefaultGridList from '../../../components/MyDefaultGridList/MyDefaultGridList'
import OrganizationListItem from './OrganizationListItem'
import './OrganizationList.scss'

const OrganizationsList = () => {
  const { setPageTitle } = useLayout()

  const [data, setData] = useState({
    list: [],
    total: 0,
  })
  const [isLoading, setIsLoading] = useState(true)
  const [selectedStatus, setSelectedStatus] = useState(true)
  const [enableObj, setEnableObj] = useState({ enabled: selectedStatus })
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState('')
  const [searchList, setSearchList] = useState('')

  const createOrgRef = useRef(null)
  const layoutRef = useRef(null)

  useEffect(() => {
    setPageTitle(getText('WORD_ORGANIZATIONS'))
  }, [])

  const handleChangeStatusFilter = (value) => {
    setSelectedStatus(value)
    const fill = async () => {
      if (value === 'all') {
        setEnableObj({})
      } else {
        setEnableObj({ enabled: value })
      }
    }
    fill()
  }
  const getOrganizationsList = async () => {
    setIsLoading(true)
    let result = await organizationActions.getPageList(
      page,
      TABLE_DEFAULT_ROW_NUMBER,
      searchList,
      enableObj
    )
    if (result.success) {
      const newData = page === 0 ? result.data : [...data.list, ...result.data]
      setData({
        list: newData,
        total: result.max,
      })
    }
    setIsLoading(false)
  }

  useEffect(() => {
    getOrganizationsList()
  }, [selectedStatus, searchList, page])

  const handleSearchFilter = (value) => {
    setIsLoading(true)
    setSearch(value)
    debouncedChange(value)
  }

  const debouncedChange = useCallback(
    debounce((options) => {
      setPage(0)
      setSearchList(options)
    }, 500),
    []
  )

  const isFiltered = search

  return (
    <MyDefaultPageLayout
      ref={layoutRef}
      disableHeader={!isFiltered && !data?.list?.length && !isLoading}
      headerProps={{
        options: [
          { label: getText('WORD_ALL'), value: 'all' },
          { label: getText('WORD_ACTIVE'), value: true },
          { label: getText('WORD_INACTIVE'), value: false },
        ],
        buttonText: getText('WORD_CREATE_ORGANIZATON'),
        searchValue: search,
        onSearchChange: (value) => handleSearchFilter(value),
        onSelectChange: (options) => handleChangeStatusFilter(options),
        selectValue: selectedStatus,
        onClick: () => createOrgRef?.current?.show(),
      }}
    >
      <MyDefaultGridList
        pageStart={page}
        loading={isLoading}
        data={data.list || []}
        renderItem={(item, index) => {
          return <OrganizationListItem item={item} key={index + '_' + item._id} />
        }}
        onLoadMore={() => {
          if (!isLoading && data?.list?.length < data?.total) {
            setIsLoading(true)
            setPage((ov) => ov + 1)
          }
        }}
        totalDataCount={data.total || 0}
        getScrollParent={() => layoutRef?.current}
        emptyTitle={
          isFiltered
            ? getText('TEXT_NO_RESULTS_FOUND')
            : getText('TEXT_YOU_CURRENTLY_HAVE_NO_CREATED_ORGANIZATIONS')
        }
        emptyButtonTitle={!isFiltered && getText('WORD_CREATE_ORGANIZATON')}
        onEmptyClick={() => createOrgRef?.current?.show()}
      />
      <CreateOrganization
        ref={createOrgRef}
        onSave={async () => {
          await getOrganizationsList()
        }}
      />
    </MyDefaultPageLayout>
  )
}

export default OrganizationsList
