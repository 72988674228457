import React from 'react'
import FacebookLogin from 'react-facebook-login'
import { getText } from '../../../../../lang'
import SVGFacebook from '../../../../../icons/SVG/SVGFacebook'
import MyDefaultButton from '../../../../../components/MyDefaultButton/MyDefaultButton'

const FIELDS = 'name,email'
const SCOPE =
  // 'pages_show_list,pages_messaging,pages_manage_metadata,pages_read_engagement,leads_retrieval,ads_management,pages_manage_ads,public_profile'
  'pages_show_list,business_management,pages_messaging,pages_manage_metadata,pages_read_engagement,leads_retrieval,ads_management,pages_manage_ads,public_profile,instagram_basic,instagram_manage_messages'

const AuthButtons = ({ isConnected, handleLogout, handleLoginResult }) => {
  return (
    <React.Fragment>
      {/* Using styles because we should have access to window.FB */}
      {isConnected && (
        <MyDefaultButton
          style={{ display: !isConnected && 'none' }}
          onClick={handleLogout}
          htmlType='button'
          typeButton={'facebook'}
          buttonText={getText('ACTION_LOGOUT')}
        />
      )}
      {Boolean(!isConnected) && (
        <FacebookLogin
          appId={process.env.REACT_APP_FACEBOOK_APP_ID}
          autoLoad={false}
          fields={FIELDS}
          scope={SCOPE}
          cookie={true}
          version='18.0'
          callback={handleLoginResult}
          icon={<SVGFacebook color='#4c69ba' />}
          textButton={getText('ACTION_LOGIN_WITH_FB')}
          buttonStyle={{
            display: isConnected && getText('WORD_NONE'),
          }}
        />
      )}
    </React.Fragment>
  )
}

export default AuthButtons
