import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = () => (
  <svg
    width='22'
    height='22'
    viewBox='0 0 22 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_30471_167231)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11 0.6875C16.6955 0.6875 21.3125 5.30454 21.3125 11C21.3125 16.6955 16.6954 21.3125 11 21.3125C5.30458 21.3125 0.6875 16.6955 0.6875 11C0.6875 5.30454 5.30454 0.6875 11 0.6875ZM10.7011 5.02257H9.1911C7.80686 5.02257 6.67429 6.1551 6.67429 7.53943V8.7349H9.64996V7.90995C9.64996 7.27005 10.1011 6.73045 10.7011 6.59347V5.02257ZM15.3257 8.7349V7.53943C15.3257 6.1551 14.1931 5.02257 12.8089 5.02257H11.2988V6.59347C11.8989 6.73045 12.35 7.27005 12.35 7.90995V8.7349H15.3257ZM11 7.15756C10.5862 7.15756 10.2476 7.4962 10.2476 7.90995V9.88333C10.2476 10.2971 10.5862 10.6356 11 10.6356C11.4137 10.6356 11.7523 10.2971 11.7523 9.88333V7.90995C11.7523 7.4962 11.4138 7.15756 11 7.15756ZM11 16.9774C13.3791 16.9774 15.3257 15.0307 15.3257 12.6517V9.33268H12.35V9.88341C12.35 10.626 11.7425 11.2335 11 11.2335C10.2574 11.2335 9.64992 10.626 9.64992 9.88341V9.33268H6.67425V12.6517C6.67425 15.0307 8.62082 16.9774 11 16.9774Z'
        fill='#5B66EA'
      />
    </g>
    <defs>
      <clipPath id='clip0_30471_167231'>
        <rect width='22' height='22' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

const SVGScrollDownIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGScrollDownIcon
