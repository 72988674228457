import React from 'react'
import { Divider } from 'antd'
import { useSelector } from 'react-redux'
import { getLongName } from '../../../../../utils'
import { getText } from '../../../../../lang'
import MyDefaultSwitch from '../../../../../components/MyDefaultSwitch/MyDefaultSwitch'
import MyDefaultBlockTitle from '../../../../../components/MyDefaultBlock/MyDefaultBlockTitle'
import MyDefaultInsidePageTabs from '../../../../../components/MyDefaultInsidePageTabs/MyDefaultInsidePageTabs'
import MyDefaultTextarea from '../../../../../components/MyDefaultTextarea/MyDefaultTextarea'
import { organizationSelector } from '../../../../../store/selectors/selectors'

const StepHeader = ({ field }) => {
  const organization = useSelector(organizationSelector)

  return (
    <React.Fragment>
      <MyDefaultBlockTitle title={'Header Settings'} disableMargin />
      <MyDefaultSwitch
        isForm
        name={[field.name, 'showBackAction']}
        noText
        isLast
        titleStyle={{ fontSize: 14 }}
        title={'Show Back Action'}
      />
      <Divider style={{ margin: '12px 0 20px 0' }} />
      <MyDefaultInsidePageTabs
        items={organization?.languages.map((lng, index) => {
          return {
            label: getLongName(lng),
            key: index,
            forceRender: true,
            children: (
              <React.Fragment>
                <MyDefaultTextarea
                  isForm
                  label={getText('LABEL_TITLE')}
                  name={[field.name, 'title', lng]}
                  message={getText('ERR_VALIDATION_REQUIRED')}
                />

                <MyDefaultTextarea
                  isForm
                  label={getText('LABEL_DESCRIPTION')}
                  name={[field.name, 'description', lng]}
                  message={getText('ERR_VALIDATION_REQUIRED')}
                />
              </React.Fragment>
            ),
          }
        })}
      />
      <Divider style={{ margin: '0 0 20px 0' }} />
    </React.Fragment>
  )
}

export default StepHeader
