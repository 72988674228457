import React from 'react'
import MyDefaultSwitch from '../../MyDefaultSwitch/MyDefaultSwitch'
import './MyDefaultTableSwitch.scss'

const MyDefaultTableSwitch = ({
  value,
  text,
  onChange,
  noText = true,
  isDefault,
  onSetAsDefaultClick,
}) => {
  return (
    <MyDefaultSwitch
      justify={'start'}
      noText={noText}
      isDefault={isDefault}
      isFirst
      isLast
      value={value}
      textAfterSwitch={text}
      onChangeSwitch={onChange}
      onSetAsDefaultClick={onSetAsDefaultClick}
      className={'my_default_table_switch'}
    />
  )
}

export default MyDefaultTableSwitch
