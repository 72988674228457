import React, { memo, useCallback, useRef, useState } from 'react'
import { motion } from 'framer-motion'
import { Flex } from 'antd'
import { VogentCall } from '@vogent/vogent-web-client'
import { formatDateTime, voiceAIOutboundStatus } from '../../../../../utils'
import { getText, getTextServerError } from '../../../../../lang'
import { MESSAGE_BLOCK_ANIMATIONS } from '../../../utils/messageUtils'
import MyDefaultBlock from '../../../../../components/MyDefaultBlock/MyDefaultBlock'
import Div from '../../../../../components/Div/Div'
import MyDefaultTagBlock from '../../../../../components/MyDefaultTagBlock/MyDefaultTagBlock'
import SVGLinkIcon from '../../../../../icons/SVG/SVGLinkIcon'
import VoiceAIDetailsModal from '../../../../../components/VoiceAIDetails/VoiceAIDetailsModal'
import voiceAIActions from '../../../../../store/modules/voiceAIActions'
import { notifyError } from '../../../../../utils/Notify'
import { OUTBOUND_VOICE_AI } from '../../../utils/constants'
import SVGPlayIconWithoutBg from '../../../../../icons/SVG/SVGPlayIconWithoutBg'
import { INBOUND, OUTBOUND } from '../../../../../devOptions'
import { useLiveTranscript } from '../../../../../utils/useLiveTranscript'
import purify from '../../../../../utils/utilPurify'
import './CenterMessageItem.scss'

const LONG_TEXT_LENGTH = 150
const TEXT_LENGTH_AFTER_CUT = 100
const baseUrl = 'https://api.getelto.com'

const CenterVoiceAIBlock = (props) => {
  const { message, index, icon, conversation } = props

  const messageBody = message?.body || ''

  const [isTextCut, setisTextCut] = useState(messageBody.length > LONG_TEXT_LENGTH)
  const [visible, setvisible] = useState(false)
  const [voiceAiCallItem, setvoiceAiCallItem] = useState({})
  const [callStatus, _setCallStatus] = useState({})
  const [muted, setMuted] = useState(false)
  const [vogentAudioConn, setVogentAudioConn] = useState(null)

  const callStatusRef = useRef(callStatus)
  const vogentCallRef = useRef(null)
  const setCallStatus = useCallback(
    (cdata) => {
      callStatusRef.current = cdata
      _setCallStatus(cdata)
    },
    [_setCallStatus]
  )

  const fill = async () => {
    if (message?.additionalInfo?.voiceAICallId) {
      const result = await voiceAIActions.getCallDetailsById(
        message?.additionalInfo?.voiceAICallId
      )
      if (result.success) {
        setvoiceAiCallItem(result.data)
      } else {
        notifyError(getTextServerError(result.errMsg))
      }
    } else {
      if (message.type === OUTBOUND_VOICE_AI) {
        if (message.status === 'in-progress') {
          const call = new VogentCall(
            {
              sessionId: message.sessionId,
              dialId: message.dialId,
              token: message.dialToken,
            },
            {
              baseUrl,
            }
          )

          call.on('status', (s) => {
            setCallStatus({
              ...callStatusRef.current,
              status: s,
            })
          })

          await call.start()

          if (!callStatusRef.current?.status) {
            setCallStatus({
              ...callStatusRef.current,
              status: 'in-progress',
            })
          }

          vogentCallRef.current = call
        }
        setvoiceAiCallItem(message)
      }
    }
  }

  const { transcript } = useLiveTranscript({
    vogentCall: vogentCallRef.current,
    skip: !callStatus,
  })

  const connectAudio = async (call, liveListen = false) => {
    await call.connectAudio(liveListen).then((conn) => {
      setVogentAudioConn(conn)
      conn.on('mute', (m) => {
        setMuted(m)
      })

      conn.on('disconnect', () => {
        setVogentAudioConn(null)
      })
    })
  }

  return (
    <motion.div
      key={index}
      className='center_message_item_wrapper'
      {...MESSAGE_BLOCK_ANIMATIONS}
      layout
    >
      <MyDefaultBlock>
        <Div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            maxWidth: 328,
            position: 'relative',
          }}
        >
          <Div className='icon-wrapper'>{icon}</Div>
          <Div className='center_message_item_block'>
            <div className='center_message_item_block--body-text'>
              <Flex justify='center' align='center' gap={8}>
                {getText(
                  message.type === OUTBOUND_VOICE_AI
                    ? 'WORD_OUTBOUND_VOICE_AI'
                    : 'WORD_INBOUND_VOICE_AI'
                )}
                {(message?.additionalInfo?.createdByName ||
                  message?.assistant?.assistantName) && (
                  <MyDefaultTagBlock
                    title={
                      message?.additionalInfo?.createdByName ||
                      message?.assistant?.assistantName
                    }
                    disableMargin
                  />
                )}
              </Flex>
              {message.type === OUTBOUND_VOICE_AI && (
                <Flex
                  justify='center'
                  align='center'
                  gap={8}
                  style={{ marginTop: 8 }}
                >
                  {getText('WORD_STATUS')}:
                  <MyDefaultTagBlock
                    title={
                      <Flex gap={5} align='center'>
                        {message.status === 'in-progress' && (
                          <SVGPlayIconWithoutBg />
                        )}
                        {voiceAIOutboundStatus(message.status)?.title}
                      </Flex>
                    }
                    titleColor={voiceAIOutboundStatus(message.status)?.color}
                    backgroundColor={
                      voiceAIOutboundStatus(message.status)?.backgroundColor
                    }
                    disableMargin
                  />
                </Flex>
              )}
              <i
                dangerouslySetInnerHTML={{
                  __html: purify(
                    message.type === OUTBOUND_VOICE_AI
                      ? voiceAIOutboundStatus(message.status)?.subtitle
                      : isTextCut
                        ? `${messageBody.slice(0, TEXT_LENGTH_AFTER_CUT).trimEnd()}...`
                        : messageBody
                  ),
                }}
              />
              {Boolean(messageBody?.length > LONG_TEXT_LENGTH) && (
                <span
                  style={{
                    color: 'var(--mainColor)',
                    textDecoration: 'underline',
                    fontSize: 12,
                    fontWeight: 700,
                    cursor: 'pointer',
                    marginLeft: 5,
                  }}
                  onClick={() => {
                    setisTextCut((ov) => !ov)
                  }}
                >
                  {getText(isTextCut ? 'TEXT_MORE_DETAILS' : 'TEXT_LESS_DETAILS')}
                </span>
              )}
            </div>
          </Div>
          <Div className='date'>{formatDateTime(message?.createdAt)}</Div>
          {!Boolean(
            message.type === OUTBOUND_VOICE_AI && message.status === 'created'
          ) && (
            <SVGLinkIcon
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
              }}
              onClick={() => {
                fill()
                setvisible(true)
              }}
            />
          )}
        </Div>
        <VoiceAIDetailsModal
          visible={visible}
          setVisible={setvisible}
          type={message.type === OUTBOUND_VOICE_AI ? OUTBOUND : INBOUND}
          transcript={transcript}
          vogentCallRef={vogentCallRef}
          muted={muted}
          callStatus={callStatus}
          vogentAudioConn={vogentAudioConn}
          onConnectAudio={() => {
            if (vogentCallRef.current) {
              connectAudio(vogentCallRef.current, true)
            }
          }}
          item={{
            ...voiceAiCallItem,
            assistant: {
              assistantName:
                message?.additionalInfo?.createdByName ||
                message?.assistant?.assistantName,
            },
            customer: {
              fullName: conversation?.receiver?.fullName,
              phone: conversation?.receiver?.phone,
              status: conversation?.receiver?.status,
            },
          }}
        />
      </MyDefaultBlock>
    </motion.div>
  )
}

export default memo(CenterVoiceAIBlock)
