import React from 'react'

const SVGAudioBackwardIcon = (props) => {
  return (
    <svg
      width='18'
      height='19'
      viewBox='0 0 18 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={props.onClick}
      style={{ cursor: props.onClick ? 'pointer' : 'default' }}
    >
      <path
        d='M11.97 8.62256H9.67499L9.10498 10.34H10.8225C11.4525 10.34 11.97 10.8501 11.97 11.4876C11.97 12.1176 11.46 12.635 10.8225 12.635H9.10498'
        stroke={props.color || 'white'}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.15503 12.6275V8.62256L6.03003 9.87505'
        stroke={props.color || 'white'}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.51514 3.85248L9.00012 2'
        stroke={props.color || 'white'}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.68251 6.35005C2.85001 7.46005 2.33252 8.83255 2.33252 10.3326C2.33252 14.015 5.31753 17.0001 9.00003 17.0001C12.6825 17.0001 15.6675 14.015 15.6675 10.3326C15.6675 6.65005 12.6825 3.66504 9.00003 3.66504C8.49003 3.66504 7.99505 3.73258 7.51505 3.84508'
        stroke={props.color || 'white'}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default SVGAudioBackwardIcon
