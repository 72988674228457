import React, { useEffect, useState } from 'react'
import { Tooltip } from 'antd'
import SVGMatadorShieldRiskIcon from '../../../../icons/SVG/SVGMatadorShieldRiskIcon'
import { getText } from '../../../../lang'
import { uploadAndFetchGETFile } from '../../../../utils/UrlHelper'
import './MessageCustomerRisk.scss'

const CustomerRisk = (props) => {
  const { receiver } = props
  const [data, setData] = useState('')

  useEffect(() => {
    const fill = async () => {
      const result = await uploadAndFetchGETFile(
        `https://api.automations.matador.ai/v1/shield/${receiver && receiver.phone}`,
        {},
        true
      )
      if (result.success) {
        setData(result.data)
      } else {
        setData('')
      }
    }
    fill()
  }, [receiver._id])

  useEffect(() => {
    if (data && data.isBlacklisted) {
      const element = document.querySelector(
        '.extension-profile-info-wrapper .message_center_panel'
      )
      if (element) {
        element.classList.add('matador-shield-for-extension')
      }
    }
  }, [data])

  const backgroundColor =
    data && data.description === 'federal-dnc' ? '#deeff5' : '#E05D4C'
  const color = data && data.description === 'federal-dnc' ? '#2372a1' : '#fff'

  return !data
    ? null
    : data.isBlacklisted && (
        <div className='matador-shield-wrapper'>
          <Tooltip
            color={backgroundColor}
            title={`This user has been flagged under ${data.description} by Matador Shield.`}
            placement='bottomLeft'
            overlayInnerStyle={{
              borderRadius: '8px',
              fontSize: '12px',
              color: color,
            }}
          >
            <div
              className='customer-risk-wrapper'
              style={{
                backgroundColor: backgroundColor,
                color: color,
              }}
            >
              <SVGMatadorShieldRiskIcon color={color} />
              {getText('WORD_HIGH_RISK')}
            </div>
          </Tooltip>
        </div>
      )
}

export default CustomerRisk
