import React, { memo, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import SVGContactTags from '../../../../../icons/SVG/SVGContactTags'
import SVGCrossIcon from '../../../../../icons/SVG/SVGCrossIcon'
import { getText } from '../../../../../lang'
import messageActions from '../../../../../store/modules/messageActions'
import { notifyError, notifySuccess } from '../../../../../utils/Notify'
import MatDropMenu from '../../../../../components/MatDropMenu'
import MatRow from '../../../../../components/MatRow'
import tagsActions from '../../../../../store/modules/tagsActions'
import { useSocket } from '../../../../../utils/socket'
import { organizationSelector } from '../../../../../store/selectors/selectors'

function hexToRgbA(hex) {
  let c
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('')
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = '0x' + c.join('')
    return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',0.1)'
  }
  throw new Error('Bad Hex')
}

const MessageTabRightSelectTags = (props) => {
  const { conversation, onSaveTags } = props

  const organization = useSelector(organizationSelector)

  const [listTagsConv, setListTagsConv] = useState([])
  const [listTagsOrgOptions, setlistTagsOrgOptions] = useState({
    data: [],
    totalCount: 0,
  })
  const [page, setpage] = useState(0)
  const [isLoading, setisLoading] = useState(true)

  const fill = async () => {
    const result = await tagsActions.getTagsList(page, 50)
    if (result.success) {
      const modifiedData = result.data.map((oo) => {
        let selected =
          conversation.tags_items &&
          conversation.tags_items.find((tag) => {
            return oo._id === tag._id
          })
        return { value: oo._id, label: oo.name, ...oo, checked: selected }
      })
      setlistTagsOrgOptions({
        data:
          page === 0 ? modifiedData : [...listTagsOrgOptions.data, ...modifiedData],
        totalCount: result.max,
      })
    }
    setisLoading(false)
  }

  const handleUpdateList = (data) => {
    setisLoading(true)
    const newList = listTagsOrgOptions.data.map((tag) => {
      if (tag._id === data._id) {
        return {
          ...tag,
          value: data._id,
          label: data.name,
          name: data.name,
          key: data._id,
        }
      }
      return tag
    })
    setlistTagsOrgOptions({
      data: [...newList],
      totalCount: listTagsOrgOptions.totalCount,
    })
    setisLoading(false)
  }

  useSocket(
    `private-organization=${organization?._id}`,
    'TAG_UPDATED',
    handleUpdateList,
    !!organization?._id
  )

  useSocket(
    `private-organization=${organization?._id}`,
    'TAG_CREATED',
    () => {
      if (page === 0) {
        fill()
      } else {
        setpage(0)
      }
    },
    !!organization?._id
  )

  useSocket(
    `private-organization=${organization?._id}`,
    'TAG_DELETED',
    (data) => {
      const newList = listTagsOrgOptions.data.filter((tag) => tag._id !== data._id)
      setlistTagsOrgOptions({
        data: [...newList],
        totalCount: listTagsOrgOptions.totalCount,
      })
    },
    !!organization?._id
  )

  useEffect(() => {
    if (conversation) {
      fill()
    }
  }, [conversation?.tags_items?.length, page])

  useEffect(() => {
    if (conversation.tags_items) {
      setListTagsConv(conversation.tags_items)
      setpage(0)
    } else {
      setListTagsConv([])
      setpage(0)
    }
  }, [conversation, conversation.tags_items])

  const handleOnChange = async (e, item) => {
    let selected = listTagsConv.find((tag) => {
      return item._id === tag._id
    })
    if (conversation.isNew) {
      if (selected) {
        conversation.tags = conversation.tags.filter((oo) => {
          return oo._id !== item._id
        })
      } else {
        conversation.tags.push(item)
      }
      onSaveTags && onSaveTags(conversation.tags)
    } else {
      let result = false
      if (selected) {
        result = await messageActions.removeTagToConversation(
          conversation._id,
          item._id
        )
        if (result.success) {
          notifySuccess(getText('TEXT_TAG_WAS_REMOVED_SUCCESSFULLY'))
        }
      } else {
        result = await messageActions.addTagToConversation(
          conversation._id,
          item._id
        )
        if (result.success) {
          notifySuccess(getText('TEXT_TAG_SAVED_SUCCESSFULLY'))
        }
      }
      if (result.success) {
        onSaveTags && onSaveTags(result.data.tags_items)
      } else {
        notifyError(result.errMsg)
      }
    }
  }

  return (
    <MatRow className='tab-info-item-wrapper'>
      <MatRow flexStart className='tab-info-icon-with-text'>
        <SVGContactTags />
        <p className='drop-menu-label drop-menu-label-second'>
          {getText('WORD_TAGS')}
        </p>
        <div className='tab-info-touchpoints'>
          <div className='border-general-width'>
            {listTagsConv.map((tag, index) => {
              return (
                <div
                  style={{
                    backgroundColor: hexToRgbA(tag.color),
                  }}
                  key={index}
                  className='border-item'
                >
                  <div
                    className='tag-point'
                    style={{
                      backgroundColor: tag.color,
                    }}
                  ></div>
                  <span>{tag.name}</span>
                </div>
              )
            })}
            <MatDropMenu
              overlayClassName='drop-menu-auto-detect-language-dropdown'
              options={listTagsOrgOptions?.data || []}
              onChange={handleOnChange}
              showArrow={false}
              overlayStyle={{ overflowY: 'hidden' }}
              className={'tab-info-add-tag'}
              trigger={<SVGCrossIcon />}
              autoAdjustOverflow={true}
              getPopupContainer={() =>
                document.querySelector('.message_right_panel--top-side')
              }
              placement={'bottomRight'}
              dropdownRender={(menu) => {
                return (
                  <div
                    style={{ height: 210, overflowY: 'auto' }}
                    // className='my-assing-drop-overlay-wrapper'
                    onScroll={(e) => {
                      if (
                        e.currentTarget.scrollHeight -
                          Math.ceil(e.currentTarget.scrollTop) <=
                          e.currentTarget.clientHeight &&
                        !isLoading &&
                        listTagsOrgOptions.totalCount >
                          listTagsOrgOptions.data.length
                      ) {
                        setisLoading(true)
                        setpage((ov) => ov + 1)
                      }
                    }}
                  >
                    {React.cloneElement(menu)}
                  </div>
                )
              }}
            />
          </div>
        </div>
      </MatRow>
    </MatRow>
  )
}

export default memo(MessageTabRightSelectTags)
