import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = () => (
  <svg
    width='37'
    height='36'
    viewBox='0 0 37 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect x='0.5' width='36' height='36' rx='8' fill='#5B66EA' fillOpacity='0.05' />
    <rect
      x='1'
      y='0.5'
      width='35'
      height='35'
      rx='7.5'
      stroke='#5B66EA'
      strokeOpacity='0.2'
    />
    <path
      d='M17.4933 11.4867L14.1666 12.74C13.4 13.0267 12.7733 13.9333 12.7733 14.7467V19.7C12.7733 20.4867 13.2933 21.52 13.9266 21.9933L16.7933 24.1333C17.7333 24.84 19.28 24.84 20.22 24.1333L23.0866 21.9933C23.72 21.52 24.24 20.4867 24.24 19.7V14.7467C24.24 13.9267 23.6133 13.02 22.8466 12.7333L19.52 11.4867C18.9533 11.28 18.0466 11.28 17.4933 11.4867Z'
      stroke='#5B66EA'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M18.5 18.3334C19.2364 18.3334 19.8334 17.7364 19.8334 17C19.8334 16.2636 19.2364 15.6667 18.5 15.6667C17.7636 15.6667 17.1667 16.2636 17.1667 17C17.1667 17.7364 17.7636 18.3334 18.5 18.3334Z'
      stroke='#5B66EA'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M18.5 18.3333V20.3333'
      stroke='#5B66EA'
      strokeWidth='1.5'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

const SVGUpdatePasswordModal = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGUpdatePasswordModal
