import React, { useEffect, useState } from 'react'
import { Divider, Flex } from 'antd'
import { useSelector } from 'react-redux'
import { getText } from '../../../../../lang'
import authActions from '../../../../../store/modules/authActions'
import locationActions from '../../../../../store/modules/locationActions'
import GoogleAuth from '../../../../../components/api/GoogleAuth'
import { notifyError, notifySuccess } from '../../../../../utils/Notify'
import { getGoogleWarningData } from '../../../components/locationUtils'
import { userSelector } from '../../../../../store/selectors/selectors'
import utilsLocalizations from '../../../../../utils/utilsLocalizations'
import MyDefaultIntegrationBlockFooter from '../../../../../components/MyDefaultIntegrationBlock/MyDefaultIntegrationBlockFooter/MyDefaultIntegrationBlockFooter'
import MyDefaultButton from '../../../../../components/MyDefaultButton/MyDefaultButton'
import MyDefaultInputText from '../../../../../components/MyDefaultInputText/MyDefaultInputText'
import MyDefaultBlockSubtitle from '../../../../../components/MyDefaultBlock/MyDefaultBlockSubtitle'

const GoogleConnection = (props) => {
  const { location } = props
  const user = useSelector(userSelector)

  const [email, setEmail] = useState('')
  const [otherIntegrationsData, setOtherIntegrationsData] = useState([])
  const [secondEmailValue, setSecondEmailValue] = useState('')

  useEffect(() => {
    if (location.leadConnection && location.leadConnection.googleEmail) {
      setEmail(location.leadConnection.googleEmail)
    }
    if (location.leadConnection && location.leadConnection.secondaryForwardEmail) {
      setSecondEmailValue(location.leadConnection.secondaryForwardEmail)
    }
  }, [])

  const handleLogin = async (data) => {
    const resultConnect = await authActions.connectGmail(data.code, location.id)
    if (resultConnect.success) {
      await locationActions.checkLoginForInvitationBooking(location.id)
      location.featuresConnectingData.googleTouchpoint.isConnected = true
      location.featuresConnectingData.googleTouchpoint.userData.fullName =
        user.fullName
      location.leadConnection.googleEmail = resultConnect.data.googleEmail
      notifySuccess(getText('TEXT_TOUCHPOINTS_EMAIL_WAS_SUCCESSFULLY_CONNECTED'))
      setEmail(resultConnect.data.googleEmail)
    } else {
      notifyError(utilsLocalizations.localizedServerError(resultConnect.errMsg))
    }
  }

  const handleLogout = async (data) => {
    const result = await authActions.disconnectGmail(location.id)
    if (result.success) {
      const result = await locationActions.checkLoginForInvitationBooking(
        location.id
      )
      location.featuresConnectingData.googleTouchpoint.isConnected = false
      location.featuresConnectingData.googleTouchpoint.userData.fullName =
        user.fullName
      location.leadConnection.googleEmail = ''
      notifySuccess(getText('TEXT_TOUCHPOINTS_EMAIL_WAS_SUCCESSFULLY_DISCONNECTED'))
      setEmail(result.data.googleEmail)
    }
  }

  return (
    <React.Fragment>
      <MyDefaultIntegrationBlockFooter
        enabled={location?.featuresConnectingData?.googleTouchpoint?.isConnected}
        connectedBy={
          location?.featuresConnectingData?.googleTouchpoint?.userData?.fullName
        }
        account={email}
        accountType={getText('TEXT_LOGGED_ACCOUNT')}
        customButton={
          !Boolean(user.isSuperAdmin && user.isAdmin) && email ? (
            <MyDefaultButton
              buttonText={
                email ? getText('WORD_DISCONNECT') : getText('WORD_CONNECT')
              }
              htmlType='button'
              style={{ maxWidth: '190px', cursor: 'not-allowed' }}
              typeButton={'red'}
              tooltip
              tooltipTitle={getText('TEXT_PLEASE_CONTACT_YOUR_ADMINISTRATOR')}
            />
          ) : (
            <GoogleAuth
              isConnected={email}
              responseGoogle={handleLogin}
              responseGoogleLogout={handleLogout}
              title={email ? getText('WORD_DISCONNECT') : getText('WORD_CONNECT')}
              typeButton={'red'}
              warning={otherIntegrationsData.length}
              warningText={getGoogleWarningData(otherIntegrationsData)}
              onVisibleChange={async (value) => {
                if (value) {
                  const result = await authActions.connectGmailIntegrations(
                    location?._id
                  )
                  if (result.success) {
                    setOtherIntegrationsData(result.data.otherIntegrationsData)
                  } else {
                    notifyError(
                      utilsLocalizations.localizedServerError(result.errMsg)
                    )
                  }
                }
              }}
            />
          )
        }
      />
      {Boolean(user.isSuperAdmin) && (
        <React.Fragment>
          <Divider style={{ margin: '10px 0' }} />
          <Flex align='end' justify='space-between' gap={10}>
            <Flex vertical style={{ width: '100%' }}>
              <MyDefaultBlockSubtitle
                subtitle={getText('TEXT_FORWARD_ALL_LEADS_TO_EMAIL')}
              />
              <MyDefaultInputText
                value={secondEmailValue}
                placeholder={getText('WORD_EMAIL')}
                onChange={(e) => setSecondEmailValue(e.target.value)}
                onClear={() => setSecondEmailValue('')}
                label={'wqeqew'}
              />
            </Flex>
            <MyDefaultButton
              buttonText={getText('ACTION_UPDATE')}
              htmlType='button'
              style={{ width: '125px' }}
              onClick={async () => {
                const result = await authActions.connectGmailSecond(
                  secondEmailValue,
                  location?._id
                )
                if (result.success) {
                  setSecondEmailValue(result.data.secondaryForwardEmail)
                  notifySuccess(getText('MSG_YOUR_EMAIL_UPDATED_SUCCESSFULLY'))
                } else {
                  notifyError(utilsLocalizations.localizedServerError(result.errMsg))
                }
              }}
            />
          </Flex>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default GoogleConnection
