import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Flex } from 'antd'
import { useSelector } from 'react-redux'
import { userSelector } from '../../../../store/selectors/selectors'
import { getText } from '../../../../lang'
import LoadingSpinner from '../../../../components/LoadingSpinner'
import SVGIntegrationGmail from '../../../../icons/SVG/SVGIntegrationGmail'
import SVGIntegrationCRM from '../../../../icons/SVG/SVGIntegrationCRM'
import SVGIntegrationEmail from '../../../../icons/SVG/SVGIntegrationEmail'
import SVGIntegrationFacebook from '../../../../icons/SVG/SVGIntegrationFacebook'
import SVGIntegrationTouchpointsMail from '../../../../icons/SVG/SVGIntegrationTouchpointsMail'
import { useLayout } from '../../../../layout/LayoutProvider/LayoutProvider'
import MyDefaultPageLayout from '../../../../components/MyDefaultPageLayout/MyDefaultPageLayout'
import MyDefaultIntegrationBlock from '../../../../components/MyDefaultIntegrationBlock/MyDefaultIntegrationBlock'
import SVGIntegrationServiceScheduler from '../../../../icons/SVG/SVGIntegrationServiceScheduler'
import { useLocationLayout } from '../../locationProvider/LocationProvider'
import GoogleIntegration from './googleIntegration/GoogleIntegration'
import CrmTypeSelector from './crmIntegration/CrmTypeSelector'
import FacebookIntegration from './facebookIntegration'
import HandleNylasConnection from './nylasIntegration/HandleNylasConnection'
import ConnectGmailOrOffice365 from './gmailOfficeIntegration/ConnectGmailOrOffice365'
import XrmTypeSelector from './serviceSchedulerIntegration/XrmTypeSelector'

const LocationIntegration = () => {
  const { locationObj, isNewLocation, setLocationObj } = useLocationLayout()
  const user = useSelector(userSelector)
  const { setPageTitle } = useLayout()

  const navigate = useNavigate()
  const { _id } = useParams()

  useEffect(() => {
    setPageTitle(getText('WORD_INTEGRATIONS'))
  }, [])

  useEffect(() => {
    if (!isNewLocation && !locationObj) {
      return navigate('/location/list')
    }
  }, [_id])

  if (!locationObj._id && !isNewLocation) {
    return <LoadingSpinner />
  }

  return (
    <MyDefaultPageLayout disableHeader>
      <Flex gap={20} wrap={'wrap'}>
        {!isNewLocation && user.isAdmin && (
          <MyDefaultIntegrationBlock
            fullWidth
            title={getText('ACTION_INTEGRATE_TOUCHPOINTS')}
            icon={<SVGIntegrationGmail />}
            description={getText('TEXT_TRIGGERS_WHEN_YOU_RECEIVE_NEW_LEAD')}
            customFooter={<GoogleIntegration location={locationObj} />}
          />
        )}
        <MyDefaultIntegrationBlock
          fullWidth
          title={getText('WORD_CONNECT_TO_CRM')}
          icon={<SVGIntegrationCRM />}
          description={getText(
            'TEXT_INTEGRATES_YOUR_DATA_ENHANCING_YOUR_ABILITY_MANAGE_CUSTOMER_INTERACTIONS_RELATIONSHIPS_EFFECTIVELY'
          )}
          customFooter={
            <CrmTypeSelector
              location={locationObj}
              isNewLocation={isNewLocation}
              setLocationObj={setLocationObj}
            />
          }
        />
        <MyDefaultIntegrationBlock
          fullWidth
          title={getText('TEXT_CONNECT_YOUR_EMAIL')}
          icon={<SVGIntegrationEmail />}
          description={getText(
            'TEXT_GIVES_POSSIBILITY_HAVE_CONVERSATIONS_WITH_CUSTOMERS_BY_EMAIL'
          )}
          customFooter={<HandleNylasConnection location={locationObj} />}
        />
        {!isNewLocation && locationObj._id && (
          <React.Fragment>
            <MyDefaultIntegrationBlock
              fullWidth
              title={getText('ACTION_INTEGRATE_FB_INSTAGRAM_PAGE')}
              icon={<SVGIntegrationFacebook />}
              description={getText(
                'TEXT_MERGES_BOTH_PLATFORMS_INTO_UNIFIED_ONLINE_PRESENCE'
              )}
              customFooter={
                <FacebookIntegration
                  locationId={locationObj._id}
                  facebookConnection={locationObj.facebookConnection}
                  featuresConnectingData={locationObj.featuresConnectingData}
                />
              }
            />
            <MyDefaultIntegrationBlock
              fullWidth
              title={getText('TEXT_CONNECT_GMAIL_OR_OFFICE_365')}
              icon={<SVGIntegrationTouchpointsMail />}
              description={getText(
                'TEXT_CONNECT_YOUR_EMAIL_FOR_GETTING_APPOINTMENTS_THERE'
              )}
              customFooter={<ConnectGmailOrOffice365 locationObj={locationObj} />}
            />
          </React.Fragment>
        )}
        {Boolean(user.isSuperAdmin) && (
          <MyDefaultIntegrationBlock
            fullWidth
            title={getText('TEXT_CONNECT_TO_SERVICE_SCHEDULER')}
            icon={<SVGIntegrationServiceScheduler />}
            description={getText(
              'TEXT_INTEGRATES_YOUR_SCHEDULER_AND_ENHANCES_YOUR_ABILITY_TO_SCHEDULE_SERVICE_APPOINTMENTS'
            )}
            customFooter={
              <XrmTypeSelector
                location={locationObj}
                isNewLocation={isNewLocation}
                setLocationObj={setLocationObj}
              />
            }
          />
        )}
      </Flex>
    </MyDefaultPageLayout>
  )
}

export default LocationIntegration
