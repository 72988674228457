import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = () => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z'
      fill='#48AF80'
    />
    <path
      d='M14.8724 5.83105C14.6946 5.83105 14.5092 5.88939 14.3738 6.02272L8.32726 11.9908C8.14879 12.1665 7.96962 12.1338 7.82935 11.9269L5.74878 8.85744C5.53628 8.54425 5.09184 8.45675 4.77309 8.66578C4.45503 8.8748 4.36614 9.31162 4.57865 9.62481L6.65851 12.6942C7.2849 13.6172 8.50504 13.7345 9.30296 12.9498L15.371 7.00327C15.6412 6.7366 15.6412 6.28939 15.371 6.02272C15.2356 5.88939 15.0495 5.83105 14.8724 5.83105Z'
      fill='white'
    />
  </svg>
)

const SVGDashVoiceAiSuccess = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGDashVoiceAiSuccess
