import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = () => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M18.3327 8.75033V12.917C18.3327 15.8337 16.666 17.0837 14.166 17.0837H5.83268C3.33268 17.0837 1.66602 15.8337 1.66602 12.917V7.08366C1.66602 4.16699 3.33268 2.91699 5.83268 2.91699H11.666'
      stroke='#48AF80'
      strokeWidth='1.5'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.83398 7.5L8.44232 9.58333C9.30066 10.2667 10.709 10.2667 11.5673 9.58333L12.5506 8.8'
      stroke='#48AF80'
      strokeWidth='1.5'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M16.2493 6.66667C17.3999 6.66667 18.3327 5.73393 18.3327 4.58333C18.3327 3.43274 17.3999 2.5 16.2493 2.5C15.0988 2.5 14.166 3.43274 14.166 4.58333C14.166 5.73393 15.0988 6.66667 16.2493 6.66667Z'
      stroke='#48AF80'
      strokeWidth='1.5'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

const SVGDashVoiceAiActiveSMS = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGDashVoiceAiActiveSMS
