import React, { memo } from 'react'
import { Drawer } from 'antd'
import SVGCrossIcon from '../../../../../icons/SVG/SVGCrossIcon'
import SVGChatClose from '../../../../../icons/SVG/SVGChatClose'
import UploadFileMessage from '../MessageUploadFile/UploadFileMessage'
import MessageFooterActions from '../MessageFooterSomeActions/MessageFooterActions'
import MessageInvitation from '../MessageInvitation/MessageInvitation'
import SwipeableComponent from '../../../../../components/MatSwipeDown/MatSwipeDown'
import './MessageFooterMobileSize.scss'

const MessageFooterMobileSize = ({
  conversation,
  isLastMessageConsent,
  msgChangeEmailSMSType,
  isCustomerPhoneNumberValid,
  isCustomerRedStatus,
  isCustomerPhoneTemporaryBlocked,
  isCustomerYellowStatusAndRestricted,
  smsTemplatesList,
  emailTemplatesList,
  currentCursorPosition,
  setcurrentCursorPosition,
  currentCursorPositionSubject,
  setcurrentCursorPositionSubject,
  newMessageValue,
  setnewMessageValue,
  mentionItem,
  newEmailSubject,
  setnewEmailSubject,
  setIsTemplate,
  setuploadFiles,
  setFocusOverInput,
  visibleAppointment,
  setvisibleAppointment,
  unarchiveConversation,
  unAssignConversation,
  addToMessageList,
  refreshConversation,
  videoStatusUpload,
  uploadFiles,
  setVideoUpload,
  videoUpload,
  setVideoStatusUpload,
  disabled,
  showMobileFooterDrawer,
  setShowMobileFooterDrawer,
  isExtension,
  hideTemplate,
  uploadingStatus,
  setUploadingStatus,
}) => {
  return (
    <React.Fragment>
      <div
        className='message_footer_small_ui_button'
        onClick={() => setShowMobileFooterDrawer(true)}
      >
        <SVGCrossIcon color={'#3F8CFF'} width={10} height={10} />
      </div>
      <Drawer
        title={''}
        placement={'bottom'}
        height={'auto'}
        header={false}
        push={false}
        destroyOnClose={true}
        onClose={() => {
          setShowMobileFooterDrawer(false)
        }}
        open={showMobileFooterDrawer}
        rootClassName='conversation_page_center_footer_mobile'
      >
        <SwipeableComponent
          onSwipeDown={() => setShowMobileFooterDrawer(false)}
          disableClose={uploadingStatus === 'uploading'}
        >
          <div
            className={`${Boolean(isExtension) ? 'message_footer_small_ui_button' : 'message_footer_small_ui_button--mobile'}`}
            onClick={() => setShowMobileFooterDrawer(false)}
          >
            <SVGChatClose stroke={2} />
          </div>
          <UploadFileMessage
            conversation={conversation}
            videoStatusUpload={videoStatusUpload}
            setuploadFiles={setuploadFiles}
            uploadFiles={uploadFiles}
            setVideoUpload={setVideoUpload}
            videoUpload={videoUpload}
            setVideoStatusUpload={setVideoStatusUpload}
            msgChangeEmailSMSType={msgChangeEmailSMSType}
            isCustomerRedStatus={isCustomerRedStatus}
            isCustomerYellowStatusAndRestricted={isCustomerYellowStatusAndRestricted}
            isCustomerPhoneNumberValid={
              isCustomerPhoneNumberValid || Boolean(isCustomerPhoneTemporaryBlocked)
            }
            setFocusOverInput={setFocusOverInput}
            mobileDeskSize={true}
            onClose={() => {
              setShowMobileFooterDrawer(false)
            }}
            uploadingStatus={uploadingStatus}
            setUploadingStatus={setUploadingStatus}
          />
          <MessageInvitation
            conversation={conversation}
            isLastMessageConsent={isLastMessageConsent}
            msgChangeEmailSMSType={msgChangeEmailSMSType}
            isCustomerPhoneNumberValid={isCustomerPhoneNumberValid}
            disabled={disabled}
            isCustomerRedStatus={isCustomerRedStatus}
            isCustomerPhoneTemporaryBlocked={isCustomerPhoneTemporaryBlocked}
            isCustomerYellowStatusAndRestricted={isCustomerYellowStatusAndRestricted}
            mobileDeskSize={true}
            onCloseMobileDesk={() => setShowMobileFooterDrawer(false)}
          />
          <MessageFooterActions
            newMessageValue={newMessageValue}
            msgChangeEmailSMSType={msgChangeEmailSMSType}
            newEmailSubject={newEmailSubject}
            setnewEmailSubject={setnewEmailSubject}
            currentCursorPosition={currentCursorPosition}
            currentCursorPositionSubject={currentCursorPositionSubject}
            setnewMessageValue={setnewMessageValue}
            conversation={conversation}
            isCustomerRedStatus={isCustomerRedStatus}
            isCustomerYellowStatusAndRestricted={isCustomerYellowStatusAndRestricted}
            isCustomerPhoneNumberValid={
              isCustomerPhoneNumberValid || Boolean(isCustomerPhoneTemporaryBlocked)
            }
            visibleAppointment={visibleAppointment}
            setvisibleAppointment={setvisibleAppointment}
            smsTemplatesList={smsTemplatesList}
            emailTemplatesList={emailTemplatesList}
            setcurrentCursorPosition={setcurrentCursorPosition}
            setcurrentCursorPositionSubject={setcurrentCursorPositionSubject}
            mentionItem={mentionItem}
            setIsTemplate={setIsTemplate}
            setuploadFiles={setuploadFiles}
            setFocusOverInput={setFocusOverInput}
            isAwaitingConsent={
              isLastMessageConsent ||
              conversation?.receiver?.status?.isWaitingAnswerToConsent
            }
            unarchiveConversation={unarchiveConversation}
            unAssignConversation={unAssignConversation}
            addToMessageList={addToMessageList}
            isCustomerPhoneTemporaryBlocked={isCustomerPhoneTemporaryBlocked}
            isLastMessageConsent={isLastMessageConsent}
            refreshConversation={refreshConversation}
            mobileDeskSize={true}
            onCloseMobileDesk={() => setShowMobileFooterDrawer(false)}
            listItemsOfResponsiveMode={[]}
            isExtension={isExtension}
            hideTemplate={hideTemplate}
          />
        </SwipeableComponent>
      </Drawer>
    </React.Fragment>
  )
}

export default memo(MessageFooterMobileSize)
