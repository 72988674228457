import React from 'react'
import { Flex } from 'antd'
import { getText } from '../../../../lang'
import MyDefaultRow from '../../../../components/MyDefaultRow/MyDefaultRow'
import MyDefaultSwitch from '../../../../components/MyDefaultSwitch/MyDefaultSwitch'

const SuperhumanTagUsersSwitch = ({ disabled }) => {
  return (
    <MyDefaultRow
      className={'superhuman_tag_users_switch'}
      leftSide={getText('TEXT_WHEN_TAG_USERS')}
      rightSide={
        <Flex gap={20}>
          <MyDefaultSwitch
            isForm
            noText
            noBorder
            disablePadding
            titleStyle={{ fontSize: 14 }}
            name={['tagging_configuration', 'tag_within_hours']}
            title={getText('WORD_OPENING_HOURS')}
            disabled={disabled}
          />
          <MyDefaultSwitch
            isForm
            noText
            noBorder
            disablePadding
            titleStyle={{ fontSize: 14 }}
            name={['tagging_configuration', 'tag_outside_hours']}
            title={getText('WORD_AFTER_HOURS')}
            disabled={disabled}
          />
        </Flex>
      }
    />
  )
}

export default SuperhumanTagUsersSwitch
