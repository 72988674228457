import React, { useEffect, useReducer } from 'react'
import { useSelector } from 'react-redux'
import { Divider, Flex, Form } from 'antd'
import appearanceAction from '../../../../../store/modules/appearanceAction'
import { notifyError, notifySuccess } from '../../../../../utils/Notify'
import { getText } from '../../../../../lang'
import MatForm from '../../../../../components/Form/MatForm'
import ThemePreview from '../preview/ThemePreview'
import { organizationSelector } from '../../../../../store/selectors/selectors'
import { useLayout } from '../../../../../layout/LayoutProvider/LayoutProvider'
import { CANCEL_BTN, IMAGE, SUBMIT_BTN } from '../../../../../devOptions'
import { useLocationLayout } from '../../../locationProvider/LocationProvider'
import MyDefaultPageLayout from '../../../../../components/MyDefaultPageLayout/MyDefaultPageLayout'
import useDeviceCheck from '../../../../../utils/useDeviceCheck'
import MyDefaultUploadAvatar from '../../../../../components/MyDefaultUpload/MyDefaultUploadAvatar/MyDefaultUploadAvatar'
import MyDefaultBlockSubtitle from '../../../../../components/MyDefaultBlock/MyDefaultBlockSubtitle'
import MyDefaultBlock from '../../../../../components/MyDefaultBlock/MyDefaultBlock'
import MyDefaultRadioCircle from '../../../../../components/MyDefaultRadioButton/MyDefaultRadioCircle/MyDefaultRadioCircle'
import MyDefaultColorPicker from '../../../../../components/MyDefaultColorPicker/MyDefaultColorPicker'
import MyDefaultInputNumber from '../../../../../components/MyDefaultInputNumber/MyDefaultInputNumber'
import MyDefaultWidgetPosition from '../../../../../components/MyDefaultWidgetPosition/MyDefaultWidgetPosition'
import MyDefaultSwitch from '../../../../../components/MyDefaultSwitch/MyDefaultSwitch'

const reducerWidget = (state, action) => ({ ...state, ...action })

const Appearance = () => {
  const { locationObj, settings, setSettings } = useLocationLayout()

  const { isPageDrawerSize, isTabletPortraitOrMobile } = useDeviceCheck()

  const organization = useSelector(organizationSelector)
  const { setFooterButtons, setLoading, setPageTitle } = useLayout()

  const [widgetState, dispatch] = useReducer(reducerWidget, {
    color: settings.color,
    headerTextColor: settings.headerTextColor,
    marginBottom: settings.marginBottom,
    marginRight: settings.marginRight,
    height: settings.height,
    theme: settings.theme,
    position: settings.position,
    texts: settings.texts,
    image_url: settings.image_url,
    mobileHeight: settings.mobileHeight,
    mobileWidth: settings.mobileWidth,
    removeQuestionInput: settings.removeQuestionInput,
    textPosition: settings.textPosition === 'top' ? 'left' : 'right',
    bold: settings.bold,
    secondaryColor: settings.secondaryColor,
  })
  const [form] = Form.useForm()

  useEffect(() => {
    setFooterButtons([
      {
        type: CANCEL_BTN,
      },
      {
        type: SUBMIT_BTN,
      },
    ])
    setPageTitle(getText('WORD_APPEARANCE'))
  }, [])

  const onFinish = async () => {
    setLoading(true)
    const allValues = form.getFieldsValue(true)
    const payload = {
      theme: widgetState.theme,
      color: allValues.color,
      position: widgetState.position,
      marginBottom: allValues.marginBottom,
      marginRight: allValues.marginRight,
      mobileHeight: allValues.mobileHeight,
      mobileWidth: allValues.mobileWidth,
      removeQuestionInput: allValues.removeQuestionInput,
      textPosition: widgetState.textPosition === 'left' ? 'top' : 'middle',
      bold: allValues.bold,
      secondaryColor: allValues.secondaryColor,
    }
    if (allValues.image_url) {
      payload.image_url = allValues.image_url
    }
    if (widgetState.theme === 'default') {
      payload.height = allValues.height
    }
    if (widgetState.theme !== 'default') {
      payload.headerTextColor = allValues.headerTextColor
    }

    let result = await appearanceAction.save(payload, locationObj._id)

    if (result.success) {
      notifySuccess(getText('SUCCESS_APPEARANCE_SAVED'))
      setSettings(result.data.settings)
    } else {
      notifyError(result.errMsg)
    }
    setLoading(false)
  }

  const radioOptions = [
    { label: getText('WORD_DEFAULT'), value: 'default' },
    { label: getText('WORD_LIGHT'), value: 'light' },
  ]

  return (
    <MyDefaultPageLayout disableHeader>
      <Flex gap={20} align='start' vertical={isPageDrawerSize}>
        <MatForm
          fullWidth
          maxWidth
          form={form}
          onFinish={onFinish}
          initialValues={{
            image_url: settings.image_url || '',
            theme: settings.theme || 'default',
            position: settings.position || 'right',
            color: settings.color || '#421DFE',
            headerTextColor: settings.headerTextColor || '#D2D1C3',
            height: settings.height || 0,
            marginRight: settings.marginRight || 0,
            marginBottom: settings.marginBottom || 0,
            mobileHeight: settings.mobileHeight || '100%',
            mobileWidth: settings.mobileWidth || '100%',
            removeQuestionInput: settings.removeQuestionInput || false,
            textPosition: settings.textPosition || 'top',
            bold: settings.bold || false,
            secondaryColor: settings.secondaryColor || '#ffffff',
          }}
        >
          <MyDefaultBlock fullWidth>
            <MyDefaultBlockSubtitle subtitle={getText('WORD_AVATAR')} />
            <Flex gap={12} align='center'>
              <MyDefaultUploadAvatar
                disableMargins
                showUploadList={false}
                mediaCount={1}
                onStartUpload={() => dispatch({ ...widgetState, loading: true })}
                loading={widgetState.loading}
                onUpload={(file) => {
                  form.setFieldsValue({ image_url: file.url })
                  dispatch({ image_url: file.url, loading: false })
                }}
                beforeUploadProps
                errMsg={getText('ERROR_MESSAGE_ONLY_IMAGE_ALLOWED')}
                uploadedItemObj={
                  widgetState?.image_url
                    ? {
                        type: IMAGE,
                        url: widgetState?.image_url,
                      }
                    : null
                }
              />
              <Flex vertical>
                <MyDefaultBlockSubtitle
                  subtitle={getText('ACTION_REMOVE_IMAGE')}
                  disableMargin
                  color={'var(--red)'}
                  onClick={() => dispatch({ image_url: '' })}
                />
                <MyDefaultBlockSubtitle
                  subtitle={getText('TEXT_PROFILE_PICTURE_FOR_CLIENTS')}
                  disableMargin
                  color={'var(--thridyTextColor)'}
                />
              </Flex>
            </Flex>
            <Divider style={{ margin: '20px 0 12px 0' }} />
            <MyDefaultRadioCircle
              isForm
              disableMargins
              name={'theme'}
              radioOptions={radioOptions}
              onChange={(e) => dispatch({ theme: e.target.value })}
            />
            <Divider style={{ margin: '12px 0 20px 0' }} />
            <Flex gap={20} vertical={isTabletPortraitOrMobile}>
              <MyDefaultColorPicker
                label={getText('CONNECT_BACKGROUND_COLOR')}
                value={widgetState.color}
                onChangeColor={(newColor) => {
                  dispatch({ color: newColor })
                  form.setFieldsValue({
                    color: newColor,
                  })
                }}
              />
              {widgetState.theme === 'light' && (
                <MyDefaultColorPicker
                  label={getText('TEXT_OPEN_EDITOR_LIGHT')}
                  value={widgetState.headerTextColor}
                  onChangeColor={(newColor) => {
                    dispatch({ headerTextColor: newColor })
                    form.setFieldsValue({
                      headerTextColor: newColor,
                    })
                  }}
                />
              )}
              <MyDefaultColorPicker
                label={getText('FOREGROUND_COLOR')}
                value={widgetState.secondaryColor}
                onChangeColor={(newColor) => {
                  dispatch({ secondaryColor: newColor })
                  form.setFieldsValue({
                    secondaryColor: newColor,
                  })
                }}
              />
            </Flex>
            <Divider style={{ margin: '20px 0' }} />
            <Flex
              gap={isTabletPortraitOrMobile ? 0 : 20}
              vertical={isTabletPortraitOrMobile}
            >
              <MyDefaultInputNumber
                isForm
                fullWidth
                label={getText('WORD_BUTTON_MARGIN')}
                name='marginBottom'
                onChange={(e) => {
                  dispatch({ marginBottom: e.target.value })
                }}
              />
              <MyDefaultInputNumber
                isForm
                fullWidth
                label={getText('WORD_MARGIN_RIGHT')}
                name='marginRight'
                onChange={(e) => dispatch({ marginRight: e.target.value })}
              />
              {widgetState.theme === 'default' && (
                <MyDefaultInputNumber
                  isForm
                  fullWidth
                  label={getText('LABEL_HEIGHT')}
                  name='height'
                  onChange={(e) => dispatch({ height: e.target.value })}
                />
              )}
            </Flex>
            <Divider style={{ margin: '0 0 20px 0' }} />
            <MyDefaultBlockSubtitle subtitle={getText('MOBILE_DIMENSIONS')} />
            <Flex gap={20}>
              <MyDefaultInputNumber
                isForm
                fullWidth
                label={`${getText('LABEL_HEIGHT')} %`}
                name='mobileHeight'
                onChange={(e) => dispatch({ mobileHeight: e.target.value })}
                max={100}
              />
              <MyDefaultInputNumber
                isForm
                fullWidth
                label={`${getText('LABEL_WIDTH')} %`}
                name='mobileWidth'
                onChange={(e) => dispatch({ mobileWidth: e.target.value })}
                max={100}
              />
            </Flex>
            <Divider style={{ margin: '0 0 20px 0' }} />
            <MyDefaultBlockSubtitle subtitle={getText('WORD_WIDGET_POSITION')} />
            <MyDefaultWidgetPosition
              setWidgetPosition={(pos) => dispatch({ position: pos })}
              widgetPosition={widgetState.position}
              marginBottom={20}
            />
            <MyDefaultBlockSubtitle subtitle={getText('POSITION_TEXT')} />
            <MyDefaultWidgetPosition
              setWidgetPosition={(pos) => dispatch({ textPosition: pos })}
              widgetPosition={widgetState.textPosition}
              size='3'
            />

            <MyDefaultSwitch
              isForm
              title={getText('DISABLE_INPUT')}
              name='removeQuestionInput'
              titleStyle={{ fontSize: 14 }}
              noText
            />
            <MyDefaultSwitch
              isForm
              title={getText('ENABLE_BOLD_TEXT')}
              name='bold'
              titleStyle={{ fontSize: 14 }}
              noText
              isLast
            />
          </MyDefaultBlock>
        </MatForm>
        <MyDefaultBlock position={'sticky'} top={0} fullWidth height={890}>
          <ThemePreview
            settings={{ ...widgetState, _location_id: settings._token }}
            language={organization?.languages?.[0] || 'en'}
            locationId={settings._token}
            theme={widgetState.theme}
          />
        </MyDefaultBlock>
      </Flex>
    </MyDefaultPageLayout>
  )
}

export default Appearance
