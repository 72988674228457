import React from 'react'
import MyDefaultModal from '../MyDefaultModal'
import './MyDefaultPurpleModal.scss'

const MyDefaultPurpleModal = (props) => {
  return (
    <MyDefaultModal
      width={580}
      closable={false}
      noTrigger
      rootClassName='my_default_purple_modal'
      typeButton='white'
      {...props}
    />
  )
}

export default MyDefaultPurpleModal
