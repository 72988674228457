import React from 'react'
import Div from '../../Div/Div'
import './MyDefaultTableText.scss'

const MyDefaultTableText = ({ text, color, fontWeight, onClick, cursor }) => {
  return (
    <Div
      style={{ color: color, fontWeight: fontWeight, cursor: cursor }}
      className={'my_default_table_text'}
      onClick={onClick}
    >
      {text}
    </Div>
  )
}

export default MyDefaultTableText
