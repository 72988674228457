import React, { useEffect, useRef, useState } from 'react'
import { Flex } from 'antd'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { notifyError, notifySuccess } from '../../../../../utils/Notify'
import questionsActions from '../../../../../store/modules/questionsActions'
import { getText, getTextServerError } from '../../../../../lang'
import { organizationSelector } from '../../../../../store/selectors/selectors'
import SVGMyDefaultDeleteIcon from '../../../../../icons/SVG/SVGMyDefaultDeleteIcon'
import { BUTTON, TABLE_DEFAULT_ROW_NUMBER } from '../../../../../devOptions'
import { useLayout } from '../../../../../layout/LayoutProvider/LayoutProvider'
import MyDefaultPageLayout from '../../../../../components/MyDefaultPageLayout/MyDefaultPageLayout'
import MyDefaultTableHeader from '../../../../../components/MyDefaultTable/MyDefaultTableHeader/MyDefaultTableHeader'
import { useLocationLayout } from '../../../locationProvider/LocationProvider'
import MyDefaultTableList from '../../../../../components/MyDefaultTable/MyDefaultTableList/MyDefaultTableList'
import MyDefaultTableTitle from '../../../../../components/MyDefaultTable/MyDefaultTableTitle/MyDefaultTableTitle'
import MyDefaultTableText from '../../../../../components/MyDefaultTable/MyDefaultTableText/MyDefaultTableText'
import authActions from '../../../../../store/modules/authActions'
import SVGMyDefaultEditIcon from '../../../../../icons/SVG/SVGMyDefaultEditIcon'
import ConfirmPopup from '../../../../../components/ConfirmPopup'
import QuestionEditModal from './QuestionEditModal'

const Questions = () => {
  const { locationObj } = useLocationLayout()
  const { setPageTitle } = useLayout()
  const organization = useSelector(organizationSelector)

  const [loading, setLoading] = useState(true)
  const [visible, setVisible] = useState(false)
  const [page, setPage] = useState(0)
  const [data, setData] = useState({
    list: [],
    total: 0,
  })
  const [questionsDataItem, setQuestionsDataItem] = useState()

  const layoutRef = useRef(null)

  const { _id } = useParams()

  useEffect(() => {
    setPageTitle(getText('WORD_QUESTIONS'))
  }, [])

  const getQuestions = async () => {
    setLoading(true)
    const options = { _location_id: _id }
    const result = await questionsActions.getPageList(
      page,
      TABLE_DEFAULT_ROW_NUMBER,
      '',
      options
    )
    if (result.success) {
      const newData = page === 0 ? result.data : [...data.list, ...result.data]
      setData({
        list: newData,
        total: result.max,
      })
    }
    setLoading(false)
  }

  useEffect(() => {
    getQuestions()
  }, [page])

  return (
    <MyDefaultPageLayout
      ref={layoutRef}
      isAdvanced
      headerProps={{
        right: [
          {
            type: BUTTON,
            title: getText('ACTION_ADD_QUESTION'),
            onClick: () => {
              setVisible(true)
              setQuestionsDataItem('')
            },
          },
        ],
      }}
    >
      <MyDefaultTableHeader
        title={getText('WORD_TOTAL_QUESTIONS')}
        disableRight
        totalCount={data?.total}
      />
      <MyDefaultTableList
        pageStart={page}
        loading={loading}
        data={data.list || []}
        getScrollParent={() => layoutRef?.current}
        onLoadMore={() => {
          if (!loading && data?.list?.length < data?.total) {
            setLoading(true)
            setPage((ov) => ov + 1)
          }
        }}
        totalDataCount={data?.total || 0}
        columns={[
          {
            title: getText('LABEL_QUESTION_NAME'),
            dataIndex: 'name',
            render: (_, { question }) => {
              return (
                <MyDefaultTableTitle
                  title={question[organization?.defaultLanguage || 'en'] || ''}
                />
              )
            },
          },
          {
            title: getText('WORD_CREATED_AT'),
            dataIndex: 'createdAt',
            render: (_, { createdAt }) => {
              return (
                <MyDefaultTableText
                  text={dayjs(createdAt)
                    .locale(authActions.getLanguage())
                    .format('D MMM, YYYY hh:mm a')}
                />
              )
            },
          },
          {
            title: getText('WORD_UPDATED_AT'),
            dataIndex: 'updatedAt',
            render: (_, { updatedAt }) => {
              return (
                <MyDefaultTableText
                  text={dayjs(updatedAt)
                    .locale(authActions.getLanguage())
                    .format('D MMM, YYYY hh:mm a')}
                />
              )
            },
          },
          {
            title: '',
            dataIndex: 'actions',
            width: 60,
            render: (_, item) => {
              return (
                <Flex align='center' gap={20}>
                  <SVGMyDefaultEditIcon
                    onClick={() => {
                      setVisible(true)
                      setQuestionsDataItem(item)
                    }}
                  />
                  <ConfirmPopup
                    title={getText('CONFIRMATION_DELETE_QUESTION')}
                    placement={'topRight'}
                    onConfirm={async () => {
                      const result = await questionsActions.delete(item._id)
                      if (result.success) {
                        notifySuccess(getText('SUCCESS_QUESTION_DELETE'))
                        setData((ov) => {
                          const newData = ov.list.filter(
                            (temp) => item._id !== temp._id
                          )
                          return {
                            total: ov.total,
                            list: [...newData],
                          }
                        })
                      } else {
                        notifyError(getTextServerError(result.errMsg))
                      }
                    }}
                    okText={getText('WORD_YES')}
                    cancelText={getText('WORD_NO')}
                    trigger={<SVGMyDefaultDeleteIcon />}
                  />
                </Flex>
              )
            },
          },
        ]}
      />
      <QuestionEditModal
        visible={visible}
        setVisible={setVisible}
        locationObj={locationObj}
        questionData={questionsDataItem}
        refreshList={() => {
          if (page === 0) {
            getQuestions()
          } else {
            setPage(0)
          }
        }}
      />
    </MyDefaultPageLayout>
  )
}

export default Questions
