import React from 'react'
import MyDefaultModal from '../../../../../components/MyDefaultModal/MyDefaultModal'
import { getText } from '../../../../../lang'
import MyDefaultButton from '../../../../../components/MyDefaultButton/MyDefaultButton'
import MatForm from '../../../../../components/Form/MatForm'
import MyDefaultInputEmail from '../../../../../components/MyDefaultInputEmail/MyDefaultInputEmail'

const NylastIntegrationModal = ({
  isLoading,
  form,
  visible,
  setVisible,
  location,
  onFinish,
  onFinishFailed,
}) => {
  return (
    <MyDefaultModal
      title={getText('TEXT_CONNECT_YOUR_EMAIL')}
      formName='formSaveOrganization'
      trigger={
        <MyDefaultButton
          buttonText={getText('WORD_CONNECT')}
          htmlType='button'
          style={{ maxWidth: '190px' }}
          typeButton={'red'}
        />
      }
      confirmLoading={isLoading}
      visible={visible}
      onTrrigerClick={() => {
        setVisible(true)
        form.resetFields()
      }}
      onCancel={() => setVisible(false)}
      okText={getText('WORD_CONNECT')}
    >
      <MatForm
        form={form}
        name='formSaveOrganization'
        initialValues={{
          email: location.integrationInfo.nylas.email_address,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <MyDefaultInputEmail
          isForm
          name='email'
          label={getText('TEXT_PLEASE_ENTER_THE_EMAIL_YOU_WANT_TO_CONNECT')}
          required={false}
          placeholder={getText('WORD_EMAIL')}
        />
      </MatForm>
    </MyDefaultModal>
  )
}

export default NylastIntegrationModal
