import React, { useState, useEffect, useReducer } from 'react'
import { Divider, Flex, Form } from 'antd'
import { useSelector } from 'react-redux'
import { notifyError, notifySuccess } from '../../../../utils/Notify'
import locationActions from '../../../../store/modules/locationActions'
import { getText, getTextServerError } from '../../../../lang'
import { getLongName, MainColor } from '../../../../utils'
import MatForm from '../../../../components/Form/MatForm'
import LoadingSpinner from '../../../../components/LoadingSpinner'
import useDeviceCheck from '../../../../utils/useDeviceCheck'
import { useLayout } from '../../../../layout/LayoutProvider/LayoutProvider'
import { useLocationLayout } from '../../locationProvider/LocationProvider'
import MyDefaultPageLayout from '../../../../components/MyDefaultPageLayout/MyDefaultPageLayout'
import { CANCEL_BTN, SUBMIT_BTN } from '../../../../devOptions'
import { organizationSelector } from '../../../../store/selectors/selectors'
import MyDefaultBlock from '../../../../components/MyDefaultBlock/MyDefaultBlock'
import MyDefaultInsidePageTabs from '../../../../components/MyDefaultInsidePageTabs/MyDefaultInsidePageTabs'
import MyDefaultTextarea from '../../../../components/MyDefaultTextarea/MyDefaultTextarea'
import MyDefaultColorPicker from '../../../../components/MyDefaultColorPicker/MyDefaultColorPicker'
import SubscriberGrowthInstructions from './SubscriberGrowthInstructions'
import PreviewMode from './Preview'

const reducerGrowth = (state, action) => {
  if (!action.type) {
    return { ...state, ...action }
  }
  switch (action.type) {
    case 'INITIAL_STATE':
      return { ...action.payload }
    default:
      break
  }
}

const SubscriberGrowth = () => {
  const { locationObj } = useLocationLayout()
  const { setFooterButtons, setLoading, setPageTitle } = useLayout()

  const { isPageDrawerSize } = useDeviceCheck()
  const organization = useSelector(organizationSelector)

  const locationId = locationObj._id

  const defaultOrgLang = organization?.defaultLanguage || 'en'

  const [isLoading, setIsLoading] = useState(true)
  const [selectedLangTab, setSelectedLangTab] = useState(
    organization?.languages?.[0] || 'en'
  )
  const [dataState, dispatch] = useReducer(reducerGrowth, {})
  const [form] = Form.useForm()

  useEffect(() => {
    setFooterButtons([
      {
        type: CANCEL_BTN,
      },
      {
        type: SUBMIT_BTN,
      },
    ])
    setPageTitle(getText('SUBSCRIBER_GROWTH'))
    setSelectedLangTab(organization?.languages?.[0] || 'en')
    const getButtonData = async () => {
      setIsLoading(true)
      const result = await locationActions.getSubscriberGrowth(locationId)

      const buttonData = result.data

      const initialState = {
        title: buttonData.title,
        description: buttonData.description,
        active: buttonData.active,
        primaryColor: getHexColor(buttonData.primaryColor || MainColor),
      }
      dispatch({ type: 'INITIAL_STATE', payload: initialState })
      setIsLoading(false)
    }
    if (locationId) {
      getButtonData()
    }
  }, [locationId])

  const getHexColor = (color) => {
    if (!color) {
      return '#FFF'
    }
    if (color.length <= 7) {
      return color
    }
    const rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(',')
    const hex = `#${(
      (1 << 24) +
      (parseInt(rgba[0]) << 16) +
      (parseInt(rgba[1]) << 8) +
      parseInt(rgba[2])
    )
      .toString(16)
      .slice(1)}`
    return hex
  }

  const onFinish = async () => {
    setLoading(true)
    const allValues = form.getFieldsValue(true)

    if (!allValues.title || !allValues.title[defaultOrgLang]) {
      return notifyError('Title is required')
    }

    if (!allValues.description || !allValues.description[defaultOrgLang]) {
      return notifyError('Description is required')
    }

    const newStyle = {
      active: true,
      primaryColor: allValues.primaryColor,
      title: allValues.title,
      description: allValues.description,
    }
    const result = await locationActions.saveSubscriberGrowth(newStyle, locationId)
    if (result.success) {
      notifySuccess(getText('SUBSCRIBER_GROWTH_BUTTON'))
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
    setLoading(false)
  }

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields.length > 0) {
      notifyError(errorInfo.errorFields[0].errors[0])
    }
  }

  if (isLoading || !locationId) {
    return <LoadingSpinner />
  }
  return (
    <MyDefaultPageLayout disableHeader>
      <Flex gap={20} align='start' vertical={isPageDrawerSize}>
        <MyDefaultBlock fullWidth>
          <MatForm
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={dataState}
          >
            <MyDefaultInsidePageTabs
              isFirst
              onChange={(index) => {
                setSelectedLangTab(organization.languages[index])
              }}
              items={organization?.languages?.map((lng, index) => {
                return {
                  label: getLongName(lng),
                  key: index,
                  forceRender: true,
                  children: (
                    <React.Fragment>
                      <MyDefaultTextarea
                        isForm
                        label={getText('LABEL_TITLE')}
                        name={['title', lng]}
                        onChange={(e) => {
                          e.persist()
                          dispatch({
                            title: {
                              ...dataState.title,
                              [lng]: e.target.value,
                            },
                          })
                        }}
                        required={lng === defaultOrgLang}
                        message={getText('ERR_VALIDATION_REQUIRED')}
                      />
                      <MyDefaultTextarea
                        isForm
                        label={getText('LABEL_DESCRIPTION')}
                        name={['description', lng]}
                        onChange={(e) => {
                          e.persist()
                          dispatch({
                            description: {
                              ...dataState.description,
                              [lng]: e.target.value,
                            },
                          })
                        }}
                        required={lng === defaultOrgLang}
                        message={getText('ERR_VALIDATION_REQUIRED')}
                      />
                    </React.Fragment>
                  ),
                }
              })}
            />
            <Divider style={{ margin: '0 0 20px 0' }} />
            <MyDefaultColorPicker
              label={getText('WORD_PRIMARY_COLOR')}
              value={dataState.primaryColor}
              onChangeColor={(newColor) => {
                dispatch({
                  primaryColor: newColor,
                })
                form.setFieldsValue({
                  primaryColor: newColor,
                })
              }}
            />
            <Divider style={{ margin: '20px 0' }} />
            <SubscriberGrowthInstructions locationId={locationId} />
          </MatForm>
        </MyDefaultBlock>
        <MyDefaultBlock position={'sticky'} top={0} fullWidth>
          <PreviewMode
            locationId={locationId}
            settings={dataState}
            language={selectedLangTab}
          />
        </MyDefaultBlock>
      </Flex>
    </MyDefaultPageLayout>
  )
}

export default SubscriberGrowth
