import React from 'react'
import { Flex } from 'antd'
import MyDefaultTagBlock from '../MyDefaultTagBlock/MyDefaultTagBlock'
import './MyDefaultAddVariablesInMessage.scss'

const MyDefaultAddVariablesInMessage = ({
  onClick,
  data,
  disableMargin,
  marginTop,
  disabled,
}) => {
  return (
    <Flex
      className='my_default_list_variables'
      wrap={'wrap'}
      justify='center'
      gap={5}
      style={{ marginBottom: disableMargin ? 0 : 24, marginTop: marginTop }}
    >
      {data.map((msg, index) => (
        <MyDefaultTagBlock
          disableMargin
          key={index}
          disabled={disabled}
          onClick={(e) => {
            e.preventDefault()
            onClick(msg.value)
          }}
          title={`{{ ${msg.label} }}`}
        />
      ))}
    </Flex>
  )
}

export default MyDefaultAddVariablesInMessage
