const texts = {
  // Restrictions

  TEXT_ACCESS_CONTROL: "Contrôle d'accès",
  TEXT_FEATURE_VISIBILITY_AND_CONTROL: 'Visibilité et contrôle des fonctionnalités',
  TEXT_COMMUNICATION_AND_BROADCASTING: 'Communication et diffusion',
  TEXT_CALENDAR_INVITATIONS: 'Invitations au calendrier',

  TEXT_PLEASE_SELECT_AT_LEAST_ONE_USER_ROLE:
    "Veuillez sélectionner au moins un rôle d'utilisateur",

  allowAccessToAllLocations:
    'Autoriser l’accès à tous les emplacements au sein de l’organisation',
  allowAccessToAllLocations_roles_description:
    'Sélectionnez le(s) rôle(s) d’utilisateur qui seront affectés par cette restriction:',
  allowSupportAgentsAccessToMainInboxFolder:
    "Autoriser l'accès de l'agent de soutien au dossier « Boîte de réception principale »",
  allowSupportAgentsAccessToUnassignedFolder:
    "Autoriser l'accès de l'agent de soutien au dossier « Non attribué »",
  allowSupportAgentsAccessToArchivedFolder:
    "Autoriser l'accès de l'agent de soutien aux conversations dans le dossier « Archivé »",

  allowDisplayingPushToCRMButtonToSupportAgents:
    "Autoriser l'affichage du bouton « Envoyer au CRM » pour l'agent de soutien",
  restrictDisableAIBot:
    'Restreindre la désactivation de la séquence en cours au niveau de la conversation',
  restrictDisableAIBot_roles_description:
    'Sélectionnez le(s) rôle(s) d’utilisateur qui seront affectés par cette restriction:',
  allowParseLeadsWithoutPhone:
    "Autoriser l'analyse des leads sans numéro de téléphone",
  restrictEditSuperHuman:
    "Restreindre l'activation/la désactivation de Superhuman au niveau de la conversation",
  restrictEditSuperHuman_roles_description:
    'Sélectionnez le(s) rôle(s) d’utilisateur qui seront affectés par cette restriction:',
  allowNextScheduledSequenceMessage:
    'Autoriser les utilisateurs à voir et à ignorer la prochaine séquence planifiée',
  allowNextScheduledSequenceMessage_roles_description:
    'Sélectionnez le(s) rôle(s) d’utilisateur qui peuvent ignorer le prochain message programmé:',
  allowSuperhumanActionInConversation:
    'Autoriser les utilisateurs à sauter la réponse de Superhuman dans une conversation',
  allowSuperhumanActionInConversation_roles_description:
    'Sélectionnez le(s) rôle(s) d’utilisateur qui peuvent sauter la réponse de Superhuman:',
  allowFollowUp: "Activer le suivi de l'IA",

  allowTextingCustomersInPendingStatus:
    'Autoriser l’envoi de SMS aux clients en statut de conformité en attente',
  restrictSendingBroadcastsOverTheWeekend:
    "Limiter l'envoi de diffusions pendant le week-end",
  restrictSendingBroadcastsOverTheWeekend_TOOLTIP:
    "Les diffusions du week-end sont généralement limitées par les réglementations locales, même si votre magasin est ouvert. L'activation de cette option empêchera l'envoi de diffusions le samedi et le dimanche, garantissant ainsi le respect des règles de communication locales",
  restrictUserVoiceCallsFromUsingOrganizationNumbers:
    "Empêcher les appels vocaux des utilisateurs d'utiliser les numéros d'organisation",

  calendarInvitationsToUsers:
    "Autoriser l'envoi d'invitations de calendrier aux utilisateurs désignés",
  allowAppointmentsOutsideWorkingHours:
    'Autoriser la planification des invitations au calendrier en dehors des heures de bureau',
  allowAdminsToEditSuperhuman:
    'Autoriser l’Administrateur à contrôler la configuration de l’IA Superhuman',
  shouldSendStopMessage:
    'Inclure une option de désabonnement dans le message marketing',

  ACTION_UPDATE: 'Mettre à jour',
  ACTION_SAVE: 'Enregistrer',
  ACTION_SAVE_CHANGES: 'Enregistrer les modifications',
  ACTION_CANCEL: 'Annuler',
  ACTION_BACK: 'Retour',
  ACTION_CHAT: 'Discuter',
  ACTION_DELETE: 'Supprimer',
  ACTION_EDIT: 'Modifier',
  ACTION_SEND: 'Envoyer',
  ACTION_SHOW_LESS: 'Afficher moins',
  ACTION_SHOW_MORE: 'Afficher plus',
  ACTION_OPTIONS: 'Options',
  ACTION_PROFILE: 'Profil',
  ACTION_LOGIN: 'Connexion',
  ACTION_LOGIN_WITH_FB: 'Connexion avec Facebook',
  ACTION_INTEGRATE_FB_INSTAGRAM_PAGE: 'Intégrez vos pages Facebook et Instagram',
  ACTION_ENABLE_MESSENGER: 'Activer la messagerie',
  ACTION_LOGOUT: 'Déconnexion',
  ACTION_INVITE: "Envoyer l'invitation",
  ACTION_REVIEW_INVITE: "Invitation d'avis",
  ACTION_CREATE_USER: 'Créer un nouvel utilisateur',
  ACTION_ADD_USER: 'Créer un nouvel utilisateur',
  ACTION_MANAGERS_LIST: 'Modifier les utilisateurs',
  ACTION_ADD_MANAGERS: 'Ajouter des gestionnaires',
  ACTION_SEND_MAGIC_LINK: 'Envoyer un lien magique',
  ACTION_CHANGE_PASSWORD: 'Changer le mot de passe',
  ACTION_NEW_MESSAGE: 'Nouveau message',
  ACTION_FEEDBACK_SKIP: 'Utiliser un autre site',
  ACTION_ARCHIVE: 'Archivée',
  ACTION_MARK_AS_READ: 'Marquer comme lu',
  ACTION_UNARCHIVE: 'Désarchiver',
  ACTION_ASSIGN: 'Assigner',
  ACTION_ASSIGN_TO: 'Assigner à',
  ACTION_UNASSIGN: "Retirer l'affectation",
  ACTION_ASSIGN_SELF: 'Attribuer à moi',
  ACTION_ASSIGNED_SELF: 'Assigné à moi',
  ACTION_ADD_GREETING: 'Ajouter une salutation',
  ACTION_ADD_QUESTION: 'Ajouter une question',
  ACTION_ADD_PROMOTION: 'Ajouter une promotion',
  ACTION_ADD_SPECIAL: 'Ajouter un spécial',
  ACTION_ADD_ANNOUNCEMENT: 'Ajouter une annonce',
  ACTION_SAVE_QUESTION: 'Enregistrer la question',
  ACTION_SAVE_GREETING: 'Enregistrer le message',
  ACTION_REVIEW_UNSUBSCRIBED: 'Désabonné',
  ACTION_SEND_TO_CRM: 'Envoyer au CRM',
  ACTION_CREATE_LOCATION: 'Créer un emplacement',
  ACTION_EDIT_LOCATION: "Modifier l'emplacement",
  ACTION_INTEGRATE_TOUCHPOINTS: 'Intégrez vos points de contact',
  ACTION_REMOVE_IMAGE: "Supprimer l'image",
  ACTION_SELECT_USER: 'Sélectionner un utilisateur',
  ACTION_SELECT_A_USER: 'Sélectionner un utilisateur (s)',

  WORD_YES: 'Oui',
  WORD_NO: 'Non',
  WORD_ALL: 'Tous',
  WORD_SELF: 'Moi',
  WORD_NAME: 'Nom',
  WORD_FIRST_NAME: 'Prénom',
  WORD_LAST_NAME: 'Nom de famille',
  WORD_PHONE: 'Téléphone',
  WORD_CHAT_: 'Numéro de téléphone',
  WORD_PHONE_HELP: 'Téléphone (10 chiffres)',
  WORD_EMAIL: 'Courriel',
  WORD_PASSWORD: 'Mot de passe',
  WORD_ADDRESS: 'Adresse',
  WORD_MANAGERS: 'Gestionnaires',
  WORD_URL: 'URL',
  WORD_USA: 'États-Unis',
  WORD_COLOR: 'Couleur',
  WORD_ENABLED: 'Activé',
  WORD_ENABLE: 'Activer',
  WORD_DISABLE: 'Désactiver',
  WORD_ACTIVE: 'Actif',
  WORD_INACTIVE: 'Inactif',
  LOCATION: 'Emplacement',
  WORD_OR: 'Ou',
  WORD_ROLE: 'Rôle',
  WORD_LOCATION: 'Emplacement',
  WORD_STATUS: 'Statut',
  WORD_TYPE: 'Type',
  WORD_ASSIGNED: 'Assigné',
  WORD_UNASSIGNED: 'Non attribué',
  WORD_ASSIGNED_LOC: 'Attribué (Mon emplacement)',
  WORD_ASSIGNED_EXT: 'Attribué (Autres emplacements)',
  WORD_ASSIGNED_USERS_COUNT: "Nombre d'utilisateurs assignés",
  WORD_ASSIGNED_USERS: 'Utilisateurs assignés',
  WORD_SETTINGS: 'Paramètres',
  WORD_APPEARANCE: 'Apparence',

  WORD_ROLE_ADMIN: 'Administrateur',
  WORD_ROLE_MANAGER: 'Responsable',
  WORD_ROLE_SUPPORT: 'Agent de soutien',
  WORD_ROLE_SUPER_ADMIN: 'Super administrateur',

  WORD_TODAY: "Aujourd'hui",
  WORD_YESTERDAY: 'Hier',
  WORD_PAST_WEEK: 'Derniers 7 jours',
  WORD_CURRENT_MONTH: 'Ce mois-ci',
  WORD_LAST_MONTH: 'Le mois dernier',
  WORD_ALL_TIME: 'Tout le temps',
  WORD_MONTH_TO_DAY: 'Mois au jour',
  WORD_TOTAL_ALL_TIME: 'Total de tous les temps',

  WORD_SENT: 'Envoyé',
  WORD_NOT_SENT: 'Pas envoyé',
  WORD_RECOMMENDED: 'Recommandé',
  WORD_REVIEWED: 'Révisé',
  WORD_FOLLOWED: 'Suivi',
  WORD_INVITE_STATUS: "État d'invitation",

  WORD_CANCELLED: 'Annulé',
  WORD_EMAIL_SENT: 'Message envoyé',
  WORD_LANGUAGE: 'Langue',

  WORD_ALL_USERS: 'Tous les utilisateurs',

  WORD_MSG_PREVIEW_EN: 'Aperçu du message en anglais',
  WORD_MSG_PREVIEW_FR: 'Aperçu du message en français',
  WORD_MSG_PREVIEW_SP: 'Aperçu du message en espagnol',

  WORD_HOME_LOCATION: 'Lieu de résidence',
  WORD_ORGANIZATIONS: 'Organisations',

  TEXT_REVERT_AI_DECISION: `Annuler la décision I.A.`,
  TEXT_PROFILE_PICTURE_FOR_CLIENTS:
    'Les utilisateurs verront cette image près de vos réponses.',
  TEXT_ALLOW_USER_RECEIVE_NEWSLETTER: `Permettre à l'utilisateur de recevoir des newsletters par e-mail`,
  TEXT_EDIT_LOCATION_HELP:
    "Un langage est automatiquement désactivé s'il n'y a pas d'image ou de contenu textuel",
  WORD_CONVERSATION_CREATED_AT: 'Conversation créée le:',
  WORD_LOCATION_NAME: "Nom d'emplacement",
  WORD_LOCATION_ADDRESS: "Adresse de l'emplacement",
  WORD_LOCATION_CRM_EMAIL: 'Emplacement du courriel CRM',
  WORD_LOCATION_USA_HELP:
    'Localisation aux États-Unis ? (Enverra un SMS depuis un téléphone américain)',
  WORD_REVIEW_SMS_TEMPLATE_TITLE: "Modèles de texte SMS d'invitation d'évaluation",
  WORD_REVIEW_EMAIL_TEMPLATE_TITLE: "Modèles d'e-mails d'invitation à la révision",
  TEXT_REVIEW_TEMPLATE_HELP:
    'Vous pouvez utiliser {{ customer_name }} et {{ location_name }} dans les modèles ci-dessous',
  WORD_REVIEW_TEXT_EN: 'Contenu textuel (Français)',
  WORD_REVIEW_TEXT_FR: 'Contenu textuel (français)',
  WORD_REVIEW_TEXT_SP: 'Contenu textuel (espagnol)',
  WORD_CRM_TYPE: 'Type de CRM',
  WORD_DEALER_ID: 'Identifiant du concessionnaire',
  WORD_QUOTE_ID: 'Identifiant de devis',
  WORD_DEALER_NUMBER: 'Numéro de concessionnaire',
  WORD_DEALER_GROUP_ID: 'Identifiant du groupe de concessionnaires',
  WORD_SUBSCRIPTION_ID: "Identifiant d'abonnement",
  WORD_ACCOUNT_ID: 'Identifiant du compte',
  WORD_SERIAL_NUMBER: 'Numéro de série',
  WORD_RESPONSE_REQUIRED: 'Réponse Requise',

  WORD_REVIEW_IMAGE_TITLE: "Images de l'affiche d'invitation à l'avis",
  WORD_REVIEW_IMAGE_EN: "Image de l'invitation à l'avis (Anglais)",
  WORD_REVIEW_IMAGE_FR: "Image de l'invitation à l'avis (Français)",
  WORD_REVIEW_IMAGE_SP: "Image de l'invitation à l'avis (Espagnol)",

  LANG_EN: 'Anglais',
  LANG_FR: 'Français',
  LANG_SP: 'Espagnol',

  MONTH_JAN: 'janvier',
  MONTH_FEB: 'février',
  MONTH_MAR: 'mars',
  MONTH_APR: 'avril',
  MONTH_MAY: 'mai',
  MONTH_JUN: 'juin',
  MONTH_JUL: 'juillet',
  MONTH_AUG: 'août',
  MONTH_SEP: 'septembre',
  MONTH_OCT: 'octobre',
  MONTH_NOV: 'novembre',
  MONTH_DEC: 'décembre',
  MONTH_JAN_SHORT: 'Janv',
  MONTH_FEB_SHORT: 'févr',
  MONTH_MAR_SHORT: 'mars',
  MONTH_APR_SHORT: 'avr',
  MONTH_MAY_SHORT: 'mai',
  MONTH_JUN_SHORT: 'juin',
  MONTH_JUL_SHORT: 'juil.',
  MONTH_AUG_SHORT: 'août',
  MONTH_SEP_SHORT: 'sept',
  MONTH_OCT_SHORT: 'oct',
  MONTH_NOV_SHORT: 'nov',
  MONTH_DEC_SHORT: 'Déc',

  January: 'janvier',
  February: 'février',
  March: 'mars',
  April: 'avril',
  May: 'mai',
  June: 'juin',
  July: 'juillet',
  August: 'août',
  September: 'septembre',
  October: 'octobre',
  November: 'novembre',
  December: 'décembre',
  Jan: 'jan',
  Feb: 'févr',
  Mar: 'mars',
  Apr: 'avr',
  Jun: 'juin',
  Jul: 'juil',
  Aug: 'août',
  Sep: 'sept',
  Oct: 'oct',
  Nov: 'nov',
  Dec: 'déc',

  ERR_GENERAL: "Une erreur s'est produite. Veuillez contacter un administrateur.",
  ERR_SERVER_NOT_CONNECTED: 'Erreur de connexion.',
  ERR_MISSING_INFO:
    'Certaines informations sont manquantes ou invalides. Veuillez vérifier chaque champ.',
  ERR_VALIDATION_REQUIRED: 'Ce champ est obligatoire.',
  ERR_VALIDATION_MIN_LENGTH: (minLength) =>
    `Ce champ nécessite au moins ${minLength} caractères.`,
  ERR_VALIDATION_MAX_LENGTH: (maxLength) =>
    `Ce champ ne peut contenir que ${maxLength} caractères.`,
  ERR_VALIDATION_EMAIL: "Cette adresse e-mail n'est pas valide.",
  ERR_VALIDATION_CANADIAN_PHONE: "Le numéro de téléphone doit commencer par '1'.",

  ERR_SELECT_TOUCHPOINT: 'Veuillez sélectionner au moins un point de contact',

  ERR_INVALID_URLS: 'Une ou plusieurs urls ne sont pas valides.',
  ERR_MISSING_CUSTOM_URL_NAMES:
    'Les noms sont manquants pour les URLs personnalisées.',
  ERR_MISSING_CUSTOM_URL_NAME: "Nom manquant pour l'URL personnalisée.",
  ERR_NO_SELECTED_ROLE: 'Veuillez sélectionner un rôle.',
  ERR_NO_SELECTED_LOCATION: 'Veuillez sélectionner un emplacement.',
  ERR_NO_PHONE_OR_EMAIL: 'Veuillez saisir un téléphone ou un e-mail.',
  ERR_NO_NAME_OR_SURNAME: 'Veuillez saisir votre prénom et votre nom de famille.',
  ERR_NO_FEEDBACK: 'Veuillez remplir le champ de texte.',
  ERR_PASSWORD_MATCH: 'Les deux champs de mot de passe doivent correspondre.',
  ERR_PASSWORD_SHORT: (minLength) =>
    `Le mot de passe doit contenir au moins ${minLength} caractères.`,
  ERR_EMPTY_MESSAGE: 'Veuillez remplir le champ de texte du message.',
  ERR_NO_FEEDBACK_URLS: 'Aucune URL de commentaires',
  ERR_NO_ORGANIZATION_NAME_IS_REQUIRED: "Le nom de l'organisation est requis",
  ERR_NO_ORGANIZATION_LANGUAGE_REQUIRED: `Veuillez sélectionner 2 langues pour l'organisation.`,
  ERR_NO_ORGANIZATION_TAG_NAME_REQUIRED: "Le nom du tag d'organisation est requis",
  ERR_WRONG_OPENING_HOURS: "Quelque chose ne va pas avec les heures d'ouverture",
  ERR_CAMPAIGN_NAME_IS_REQUIRED: 'Le nom de la séquence est obligatoire',
  ERR_SEQUENCE_NAME_IS_REQUIRED: 'Sequence name is required',
  ERR_PLEASE_FILL_TIME_UNIT:
    "Veuillez remplir la valeur de l'unité dans laquelle le message doit être envoyé",
  ERR_SAME_TIME_AUTO_REPLY:
    'Vous ne pouvez pas définir des messages de réponse automatique pour la même heure',
  INPUT_AT_LEAST_ONE_OF_THE_LANGUAGES: 'Saisissez au moins une des langues',

  NTF_SUCCESS_INVITE: "L'invitation a été envoyée avec succès.",
  NTF_SUCCESS_INVITE_CANCEL: 'Invitation annulée.',
  NTF_SUCCESS_RESET_PASSWORD: 'Mot de passe modifié avec succès.',
  NTF_SUCCESS_MAGIC_SEND_EMAIL: 'Un lien vous a été envoyé par e-mail.',
  NTF_SUCCESS_MAGIC_SEND_SMS: 'Un lien vous a été envoyé par SMS.',
  NTF_SUCCESS_SAVE_CUSTOMER: 'Le client a été enregistré avec succès.',
  NTF_SUCCESS_DELETE_LOCATION: "L'emplacement a été supprimé avec succès.",
  NTF_SUCCESS_SAVE_PROFILE: 'Profil enregistré avec succès.',
  NTF_SUCCESS_SAVE_PASSWORD: 'Mot de passe enregistré avec succès.',
  NTF_SUCCESS_SEND_FEEDBACK: 'Commentaire envoyé avec succès.',
  NTF_SUCCESS_SEND_TO_CRM: 'La conversation a été envoyée avec succès au CRM.',
  NTF_MSG_ARCHIVED: 'La conversation a été archivée avec succès',
  NTF_MSG_UNARCHIVED: 'La conversation a été désarchivée avec succès',
  NTF_SUCCESS_MSG_ASSIGN: 'Affectation réussie à cette conversation.',
  NTF_SUCCESS_MSG_UNASSIGN:
    'Vous avez été retiré de cette conversation avec succès.',

  TOOLTIP_PHONE: '(10 chiffres avec indicatif régional)',

  TITLE_DASHBOARD: 'Tableau de bord',
  TITLE_USER_DASHBOARD: "Tableau de bord de l'utilisateur",
  TITLE_USER_PERFORMANCE_DATA: "Données de performance de l'utilisateur",
  TITLE_MATADOR_PERFORMANCE_DATA: 'Données de performance de Matador Connect',

  TITLE_MESSAGES: 'Conversations',
  TITLE_INVITATIONS: 'Avis',
  TITLE_NPS: 'NPS',
  TITLE_USER_LIST: 'Gérer les utilisateurs',
  TITLE_LOCATION_LIST: 'Gérer les emplacements',
  TITLE_LEADERBOARD: 'Classement',
  TITLE_ORGANIZATIONS: 'Gérer les organisations',
  TITLE_LOGOUT: 'Se déconnecter',

  WORD_DASHBOARD_STATS_INVITATION: "Statistiques d'invitations envoyées",
  WORD_DASHBOARD_STATS_RECOMMENDED: 'Statistiques recommandées',
  WORD_DASHBOARD_STATS_REVIEW: "Statistiques d'évaluation",
  WORD_DASHBOARD_STATS_FOLLOWED: 'Statistiques suivies',
  WORD_DASHBOARD_STATS_INCOMING_MSG: 'Messages entrants',
  WORD_DASHBOARD_STATS_OUTGOING_MSG: 'Messages sortants',

  WORD_INVITATIONS_SUBTITLE: 'Envoyées récemment',
  TEXT_INVITATIONS_DESC:
    'Affichage des dernières invitations que vous avez envoyées',
  TEXT_INVITATIONS_SEARCH: 'Rechercher toutes les invitations envoyées...',

  ADD_CONDITION: 'Ajouter une nouvelle condition',

  TEXT_NO_QUESTIONS: "Il n'y a pas encore de questions",
  ADD_QUESTION: '+ Ajouter une question',

  TEXT_LEADERBOARD_HELP:
    "En tant que Manager, vous voyez plus qu'un utilisateur régulier. Ils ne voient que les noms et les classements.",

  TITLE_LOGIN_PASSWORD: 'Connexion par mot de passe',
  TEXT_LOGIN_PASSWORD_HELP:
    'Si vous connaissez votre mot de passe, connectez-vous ici',
  TEXT_FORGOT_PASSWORD: 'Mot de passe oublié ?',
  TITLE_LOGIN_MAGIC: 'Connexion par lien magique',
  TEXT_LOGIN_MAGIC_HELP:
    'Recevez un lien par e-mail ou par téléphone qui vous connecte automatiquement sans mot de passe',

  TEXT_VIDEO_CALL_END: 'Appel vidéo terminé',
  TEXT_INCOMING_CALL: 'Appel entrant',
  TEXT_INCOMING_CALL_FROM: 'Appel entrant de',

  TITLE_FEEDBACK_NEG: 'Merci pour votre commentaire',
  TEXT_VARIABLE_REQUIRED_BOTH_LANGUAGE:
    '[missingVariable] est requis pour les deux langues. Veuillez remplir le champ [missingLanguage]',

  TEXT_FEEDBACK_NEG_HELP: 'Nous sommes vraiment désolés !',
  TEXT_FEEDBACK_NEG_INPUT_PLACEHOLDER: "Faites-nous savoir ce qui s'est passé",

  TITLE_FEEDBACK_NEG_CONFIRM: 'Merci pour votre retour',
  TEXT_FEEDBACK_NEG_HELP_CONFIRM:
    "Nous travaillons continuellement à l'amélioration de l'expérience client. Votre avis compte pour nous.",

  WORD_SOCIAL_FB: 'Facebook',
  WORD_SOCIAL_GOOGLE: 'Google',
  WORD_SOCIAL_INSTAGRAM: 'Instagram',
  WORD_SOCIAL_YELP: 'Yelp',

  TITLE_FB_MESSENGER: 'Messenger Facebook',
  TITLE_IG_MESSENGER: 'Messagerie Instagram',
  TITLE_GOOGLE_BUSINESS: 'Google Entreprise',

  TITLE_FEEDBACK_POS_LIST:
    'Pour terminer votre expérience, veuillez choisir où vous aimeriez laisser votre avis',

  TITLE_MESSAGE_INVITATION: 'Invitation envoyée pour examen',

  TITLE_EDIT_CUSTOMER: 'Modifier le client',

  TITLE_DEFAULT_COMMUNICATION_METHOD: 'Méthode de communication par défaut',

  TEXT_NO_RESULT: 'Aucun résultat.',

  TEXT_USERS_SEARCH: 'Rechercher des utilisateurs...',

  CONFIRM_SEND_INVITATION: 'Voulez-vous envoyer cette invitation ?',

  MSG_OPTION_MY_MESSAGES: 'Mes Messages',
  MSG_OPTION_UNASSIGNED: 'Non attribué',
  MSG_OPTION_FOLLOW_UPS: 'Suivis',
  MSG_OPTION_ARCHIVED: 'Archivé',
  MSG_OPTION_MAIN_INBOX: 'Boîte de réception principale',

  MSG_OPTION_SMART_INBOX: 'Intelligente',

  TITLE_CHAT_SETTINGS: 'Paramètres de chat',

  WORD_SPECIALS: 'Spéciaux',
  WORD_GREETING: 'Salutation',
  WORD_DISPLAYED: 'Affiché',
  WORD_CONVERSATIONS: 'Conversations',
  WORD_CONVERSATION: 'Conversation',
  WORD_CONVERSION_RATIO: 'Taux de conversion',
  WORD_GREETINGS: 'Salutations',
  WORD_QUESTIONS: 'Questions',
  WORD_TOTAL_GREETINGS: 'Total des salutations',
  WORD_TOTAL_QUESTIONS: 'Total des questions',
  SUCCESS_QUESTION_DELETE: 'La question a été supprimée avec succès.',
  SUCCESS_GREETING_DELETE: 'Salutation supprimée avec succès.',
  SUCCESS_PROMOTION_DELETE: 'La promotion a été supprimée avec succès.',
  SUCCESS_SPECIAL_DELETE: 'Spécial a été supprimé avec succès.',
  SUCCESS_ANNOUNCEMENT_DELETE: "L'annonce a été supprimée avec succès.",
  SUCCESS_PROMOTION_UPDATE: 'La promotion a été mise à jour avec succès.',
  SUCCESS_SPECIAL_UPDATE: 'Spécial a été mis à jour avec succès.',
  SUCCESS_ANNOUNCEMENT_UPDATE: "L'annonce a été mise à jour avec succès.",
  SUCCESS_GREETING_UPDATE: 'La salutation a été mise à jour avec succès.',
  SUCCESS_GREETING_SET_DEFAULT: 'Salutation définie par défaut avec succès.',
  CONFIRMATION_SET_DEFAULT_GREETING:
    "Êtes-vous sûr de vouloir définir ce message d'accueil par défaut ?",
  CONFIRMATION_DELETE_VARIABLE:
    'Êtes-vous sûr de vouloir supprimer ce(tte) [variableToDelete] ?',
  CONFIRMATION_DELETE_GREETING:
    "Êtes-vous sûr de vouloir supprimer ce message d'accueil ?",
  CONFIRMATION_DELETE_QUESTION:
    'Êtes-vous sûr de vouloir supprimer cette question ?',

  SUCCESS_TRIGGER_SAVED: 'Le déclencheur a été enregistré avec succès.',
  WORD_TOTAL_PROMOTIONS: 'Promotion totale',
  WORD_TOTAL_SPECIALS: 'Total des spéciaux',
  WORD_TOTAL_ANNOUNCEMENTS: 'Total des annonces',
  ERROR_END_DATE_AFTER_START_DATE:
    'La date de fin doit être postérieure à la date de début',
  SUCCESS_APPEARANCE_SAVED: 'Apparence enregistrée avec succès.',
  SUCCESS_ANNOUNCEMENT_SAVED: 'Annonce enregistrée avec succès.',
  SUCCESS_PROMOTION_SAVED: 'Promotion enregistrée avec succès.',
  SUCCESS_SPECIAL_SAVED: 'Spécial enregistré avec succès.',
  WORD_MOBILE_COUPONS: 'Coupons mobiles',
  WORD_EXIT_INTENT: 'Intention de sortie',

  WORD_TOTAL_CONVERSATIONS: 'Total des conversations',
  TEXT_FOR_LAST_MONTH: 'Pour le mois dernier',
  TEXT_CONVERSATIONS_FOR_LAST_MONTH: 'Conversations des 30 derniers jours',
  TEXT_COMPARED_FROM_LAST_MONTH: 'Par rapport aux 30 jours précédents',
  WORD_CLICKS: 'Clics',

  WORD_USER: 'Utilisateur',
  WORD_CLIENTS: 'Clients',
  WORD_APPOINTMENTS: 'Rendez-vous',

  WORD_SITE_URL: 'URL du site',
  WORD_PRIMARY: 'Principal',
  WORD_HEADER: 'En-tête',
  WORD_WIDGET_TAG: 'Étiquette de widget',
  WORD_WIDGET_CODE: 'Code de widget',
  WORD_WIDGET_POSITION: 'Position du widget',
  WORD_OPEN_WIDGET_CODE:
    "Ouvrir le code du widget (ajouter la classe `matador-open-button` à n'importe quel élément HTML)",
  WORD_CHAT_SETTINGS_WELCOME_MESSAGE: 'Message de bienvenue',
  WORD_CHAT_SETTINGS_AUTO_REPLY_LABEL: 'Réponse automatique',
  WORD_CHAT_SETTINGS_AUTO_REPLY_PLACEHOLDER: 'Premier message de discussion',

  TITLE_LIVE_PREVIEW: 'Aperçu en direct',

  TEXT_LABEL_CREATE_OPPORTUNITIES_IN_VIN_SOLUTIONS:
    "Créer des opportunités dans VinSolutions UNIQUEMENT lorsqu'un client répond avec un sentiment positif.",

  WORD_SELECT_ORGANIZATIONS: 'Sélectionnez des organisations',
  WORD_SELECT_LOCATIONS: 'Sélectionnez des emplacements',
  WORD_SELECT_LANGUAGE: 'Sélectionner la langue',
  WORD_WELCOME_MESSAGE: 'Message de bienvenue',
  WORD_VISIBILTY: 'Visibilité',
  WORD_AUTO_REPLY: 'Réponse automatique',
  WORD_AUTO_REPLY_FIRST: 'Réponse automatique (premier message)',
  WORD_AUTO_REPLY_SECOND: 'Réponse automatique (deuxième message)',
  WORD_DISPLAY_GREETING_WHEN: 'Afficher la salutation quand',

  WORD_CURRENT_PAGE_ADDRESS: 'Adresse de la page actuelle',
  WORD_URL_STARTS_WITH: 'URL commence par',
  WORD_URL_CONTAINS: 'URL contient',

  WORD_CURRENT_PAGE_TIME: 'Temps sur la page actuelle',
  TEXT_GREETING_TIME_TRIGGER_START: 'Est plus de',
  TEXT_GREETING_TIME_TRIGGER_END: 'secondes',
  WORD_SELECT_QUESTIONS: 'Sélectionnez des questions',

  WORD_QUESTION: 'Question',
  TEXT_HELP_TYPE_QUESTION: 'Tapez votre question ici...',
  NTF_SUCCESS_SAVE_CHAT_SETTINGS: 'Paramètres de discussion enregistrés avec succès',
  NTF_SUCCESS_SAVE_GREETIONG: 'Déclencheur de chat enregistré avec succès',
  NTF_NEED_FILL_MORE_FIELDS: 'Besoin de remplir plus de champs',

  WORD_CA_PHONE: 'Téléphone CA',
  WORD_US_PHONE: 'Téléphone US',

  WORD_EDIT_ORGANIZATON: "Modifier l'organisation",
  WORD_CREATE_ORGANIZATON: 'Créer une organisation',

  NTF_CHAT__REQUIRED: 'Le champ Téléphone doit contenir 11 chiffres.',
  NTF_CHAT__REQUIRED_10: 'Le champ Téléphone doit contenir 10 chiffres.',
  WORD_ID: 'IDENTIFIANT',
  TEXT_CONNECT_YOUR_EMAIL: 'Connectez votre e-mail',
  TEXT_DISCONNECT_YOUR_EMAIL: 'Déconnectez votre e-mail',

  TEXT_CONNECT_YOUR_GMAIL_ACCOUNT: 'Connectez votre compte Gmail',
  TEXT_CONNECT_YOUR_EMAIL_ACCOUNT:
    "Vous devez connecter votre compte de messagerie et donner l'autorisation de lecture et d'envoi à l'application Matador. Nous l'utiliserons pour envoyer des invitations par e-mail aux clients.",
  TEXT_DISCONNECT_YOUR_GMAIL_ACCOUNT: 'Déconnectez votre compte Gmail',
  TEXT_CONNECT_GMAIL: 'Connecter Gmail',
  TEXT_CONNECT_YOUR_OFFICE_365_ACCOUNT: 'Connectez votre compte Office 365',
  TEXT_CONNECT_OFFICE_365: 'Connecter Office 365',
  TEXT_DISCONNECT_OFFICE_365: 'Déconnecter Office 365',
  WORD_INFO: 'Info',
  WORD_BOOKING: 'Réservation',
  WORD_SOURCE: 'Source',
  WORD_TAGS: 'Étiquettes',
  TEXT_APPOINMENT_DATE: 'Date de rendez-vous',
  TEXT_DATE_AND_TIME: 'Date et heure',

  NTF_SUCCESS_SEND_BOOKING_INVITATION:
    'La date de rendez-vous avec [Customer] a été modifiée au [date] à [time]',
  NTF_SUCCESS_SEND_BOOKING_INVITATION_NEW:
    'Rendez-vous avec [Customer] réservé le [date] à [time]',
  NTF_SUCCESS_DELETED_BOOKING_INVITATION:
    'Rendez-vous [date] avec [Customer] a été annulé',

  MSG_DELETE_BOOKING_INVITATION: 'Êtes-vous sûr de vouloir annuler le rendez-vous ?',
  MSG_UPDATE_BOOKING_INVITATION:
    'Êtes-vous sûr de vouloir modifier le rendez-vous ?',
  TEXT_BOOKED_AN_APPOINTMENT: 'Rendez-vous réservé',
  TEXT_DRIP_SEQUENCE_AT_WORK: "Séquence d'égouttage au travail",
  TEXT_THIS_MONTH: 'Ce mois-ci',
  WORD_MTD: 'MTM',
  TEXT_PROFILES_MESSAGED: 'Profils contactés',
  TEXT_MESSAGES_SENT: 'Messages envoyés',
  TEXT_INVITATIONS_SENT: 'Invitations envoyées',
  WORD_ENGAGMENT: 'Fiançailles',
  TEXT_ENGAGMENT_PERCENTAGE: "Pourcentage d'engagement",
  TEXT_INVITES_SENT: 'Invitations envoyées',
  TEXT_OPEN_RATE: "Taux d'ouverture",
  WORD_REVIEWS: 'Avis',
  TEXT_MARKETING_SOURCE: 'Source Marketing',
  TEXT_TOTAL_ACTIVITY: 'Activité totale',
  TEXT_INBOUND_AND_OUTBOUND: 'Entrant et sortant',
  WORD_APPOINTMENT_TEXT: 'Rendez-vous',
  TEXT_USE_ASSISTANT_TO_BOOK_XTIME_SERVICE:
    "Utilisez l'assistant pour réserver le service X-Time",
  TEXT_TAG_USER_WHEN_APPOINTMENT_CREATED_OR_FAILED_DURING_CALL:
    "Identifier l'utilisateur lorsque le rendez-vous est créé ou échoue pendant l'appel",
  TEXT_BOOKED_APPOINTMENTS: 'Rendez-vous réservés',
  WORD_ORGANIC: 'Biologique',
  WORD_INBOUND: 'Entrant',
  WORD_CALLS: 'Appels',
  TEXT_INBOUND_VALUME: 'volume entrant',
  WORD_MESSAGES_RECEIVED: 'Messages reçus',
  WORD_APPOINMENTS_SET: 'Rendez-vous fixés',
  WORD_REVIEW_INVITES_SENT: "Invitations d'avis envoyées",
  WORD_NOTES: 'Notes',
  TEXT_ADD_NOTES: 'Ajouter des notes',
  TEXT_NEW_NOTE: 'Nouvelle note',
  TEXT_EDIT_NOTE: 'Modifier la note',
  TEXT_ADD_NEW_NOTES: 'Ajouter une nouvelle note',
  TEXT_CALENDAR_INVITE: 'Invitation rendez-vous',
  TEXT_CALENDAR_INVITES_REMINDER: "Rappel d'invitations du calendrier",
  TEXT_TEXT_REMINDERS: 'Rappels texte',
  TEXT_TEXT_ME: 'Envoyez-moi un message',
  TEXT_MATADOR_CONNECT: 'Connecter Matador',

  ERR_NOTE_NAME_IS_REQUIRED: 'Le contenu de la note est obligatoire',
  ERR_PHONE_IS_REQUIRED: 'Le téléphone est requis',
  UPDATE_APP:
    "Bonjour, nous avons apporté d'excellentes améliorations à Matador. Pour vous assurer de bénéficier de toutes les améliorations de la plateforme, veuillez vous déconnecter et vous reconnecter.",

  MSG_TEXT_CONTENT_FIELD_IS_REQUIRED: 'Le champ Contenu du texte est obligatoire',
  MSG_FIRST_AND_LAST_NAMES_ARE_REQUIRED: 'Le prénom et le nom sont obligatoires.',

  DAILY: 'Quotidien',
  MONTHLY: 'Mensuel',
  WEEKLY: 'Hebdomadaires',

  WEEKLY_STATS: 'Hebdomadaires',
  MONTHLY_STATS: 'Mensuelles',

  TEXT_AUTO_DETECTED_LANGUAGE: 'Langue détectée automatiquement',
  TEXT_AUTO_DETECTED: 'Détecté automatiquement',

  TEXT_SEND_SMS_BUTTON_SETTINGS: 'Paramètres du bouton Envoyer un SMS',
  LABEL_TITLE: 'Titre',
  LABEL_DESCRIPTION: 'Description',
  LABEL_URL_OPTIONAL: 'Url (facultatif)',
  LABEL_IMAGE_URL: "URL de l'image",
  LABEL_START_DATE: 'Date de début',
  LABEL_END_DATE: 'Date de fin',
  LABEL_TEXT_ON_BUTTON: 'Texte sur le bouton',
  LABEL_FONT_SIZE: 'Taille de police',
  LABEL_BORDER_RADIUS: 'Rayon de frontière',
  LABEL_HEIGHT: 'Hauteur',
  LABEL_WIDTH: 'Largeur',
  WORD_HEX: 'Hexadécimal',
  LABEL_BACKGROUND_COLOR: 'Couleur de fond',
  LABEL_TEXT_COLOR: 'Couleur du texte',
  WORD_COPIED: 'Copié !',
  WORD_BUTTON_POSITION: 'Position du bouton',
  WORD_FIXED: 'Fixé',
  WORD_FLOAT: 'Flotter',
  WORD_BUTTON_MARGIN: 'Marge du bouton',
  WORD_MARGIN_RIGHT: 'Marge droite',
  WORD_LIGHT: 'Léger',
  LABEL_SHOW_WIDGET_DESKTOP: 'Afficher le widget sur le bureau',
  LABEL_SHOW_ORGANIZATION_ADDRESS: "Afficher toutes les adresses de l'organisation",
  LABEL_TRIGGER_NEW_SESSION:
    "Déclencher la salutation uniquement lors d'une nouvelle session",
  LABEL_TEXT_TEMPLATE: 'Modèle de texte',
  TEXT_ONE_CLICK_CONVERT: 'Conversion en un clic',
  LABEL_BORDER_WIDTH: 'Largeur de bordure',
  LABEL_BORDER_COLOR: 'Couleur de la bordure',
  LABEL_OFFSET_X: 'Décalage X',
  LABEL_OFFSET_Y: 'Décalage Y',
  LABEL_BLUE: 'Flou',
  LABEL_SHADOW_COLOR: "Couleur de l'ombre",
  LABEL_BUTTON_SHADOW: 'Ombre du bouton',

  MSG_CONFIRM_DELETE: 'Êtes-vous sûr de vouloir supprimer ?',

  TEXT_UNREAD_MSG: 'Non lu',

  WORD_GENERAL: 'Général',
  TEXT_UPDATE_ORGANIZATION_DETAILS: "Mettre à jour les détails de l'organisation",
  TEXT_UPDATE_ORGANIZATION_TAGS: "Mettre à jour les étiquettes de l'organisation",
  LABEL_ORGANIZATION_NAME: "Nom de l'organisation",
  LABEL_TWILIO_MESSAGING_SID: 'Messaging SID',
  TEXT_SET_AS_DEFAULT: 'Définir par défaut',
  WORD_TIME_ZONE: 'Fuseau horaire',
  WORD_TOTAL_SEQUENCES: 'Total des Séquences',
  WORD_ADD_SEQUESNCE: 'Horaire après les heures de travail',
  WORD_CREATE_WORKING_HOURS_SEQUENCE: "Créer une séquence d'heures de travail",
  WORD_CREATE_AFTER_HOURS_SEQUENCE: 'Créer une séquence après les heures de travail',
  TEXT_AFTER_HOURS: 'Horaire après les heures de travail',
  TEXT_WORKING_HOURS: 'Heures de travail',
  TEXT_NAME_OF_SEQUENCE: 'Nom de séquence',
  WORD_PROFILES: 'Profils',
  WORD_RESPONSE: 'Réponse',
  TEXT_SEQUENCE_NAME_GOES_HERE: 'Nom de séquence va ici',
  TEXT_SEQUENCE_STATUS: 'Statut de la séquence',
  TEXT_SEQUENCE_NAME: 'Nom de la séquence',
  WORD_SEQUENCE: 'Séquence',
  TEXT_SEND_FOLLOWUP: 'Envoyer un suivi',
  WORD_DAYS_AFTER: 'jours après',
  WORD_FIRST_AUTO_MESSAGE: 'Premier message automatique',
  TEXT_SHOULD_BE_SENT_IMMEDIATELY_AFTER_RECEIVING_A_LEAD:
    'doit être envoyé immédiatement après avoir reçu un lead.',
  WORD_ENGLISH_MESSAGE: 'Message en anglais',
  WORD_FRENCH_MESSAGE: 'Message en français',
  WORD_ADD_TAG: 'Ajouter une étiquette',
  WORD_NEW_AUTO_MESSAGE: 'Nouveau message automatique',
  WORD_DAYS: 'Jours',
  TEXT_FIRST_AUTO_MESSAGE_EN: 'Premier message automatique EN',
  TEXT_FIRST_AUTO_MESSAGE_FR: 'Premier message automatique FR',
  MSG_DELETE_AI_BOT_MESSAGE_ITEM: 'Voulez-vous vraiment supprimer le message ?',
  WORD_TIME_ZONE_IS_REQUIRED: 'Veuillez sélectionner le fuseau horaire',
  WORD_TRIGGERED_ON: 'Déclenché le ...',
  WORD_TOUCHPOINTS: 'Points de contact',
  WORD_ACTIVITY: 'Activité',
  TEXT_UPDATE_ORGANIZATION_TOUCHPOINTS:
    "Mettre à jour les points de contact de l'organisation",
  TEXT_DATE_OF_CREATION: 'Date de création',
  WORD_CONSENT: 'Consentement',
  WORD_USE_ICON: "Utiliser l'icône",
  WORD_USE_ANIMATION: "Utiliser l'animation",
  WORD_ADD_LINK_TO_BODY: 'Ajouter un lien au corps',
  WORD_NEW: 'Nouveau',
  WORD_OLD: 'Ancien',
  TEXT_ORGANIZATION_RESTRICTIONS_SETTINGS:
    "Paramètres des restrictions d'organisation",
  TEXT_ORGANIZATION_SETTINGS_AND_PERMISSIONS:
    "Paramètres et autorisations de l'organisation",
  WORD_RESTRICTIONS: 'Restrictions',
  WORD_SETTINGS_AND_PERMISSIONS: 'Paramètres et autorisations',
  ERROR_USE_ONLY_DYNAMIC_TAGS_FROM_THE_LIST_OF_SUGGESTED_ONES:
    'Utilisez uniquement les balises dynamiques de la liste de celles suggérées',
  WORD_USER_TEMPLATES: 'Modèles utilisateur',
  WORD_EMAIL_TEMPLATES: `Modèles de Courriel`,
  WORD_SMS_TEMPLATES: 'Modèles de SMS',
  WORD_USER_SMS_TEMPLATES: 'Modèles de SMS utilisateur',
  WORD_ADD_SMS_TEMPLATE: 'Ajouter un modèle SMS',
  TEXT_UPDATE_ORGANIZATION_USER_SMS_TEMPLATES: `Mettre à jour les modèles SMS d'utilisateur de l'organisation`,
  WORD_USER_EMAIL_TEMPLATES: 'Modèles de courrier électronique utilisateur',
  TEXT_UPDATE_ORGANIZATION_USER_EMAIL_TEMPLATES: `Mettre à jour les modèles de courrier électronique des utilisateurs de l'organisation`,
  LABEL_TEMPLATE_SUBJECT: `MODÈLE`,
  LABEL_TEMPLATE_BODY: `CORPS DU MODÈLE`,
  ERROR_TEMPLATE_TEXTAREA_BODY: `Veuillez remplir le corps du modèle [lang]`,
  WORD_ADD_EMAIL_TEMPLATE: `Ajouter un modèle Courriel`,
  WORD_OPENING_HOURS: "Heures d'ouvertures",
  TEXT_ORGANIZATION_TEMPLATES: "Modèles d'organisation",
  LABEL_TEMPLATE_NAME: 'Nom du modèle',
  TEXT_SIGN_IN_WITH_GOOGLE: 'Se connecter avec Google',
  TEXT_SIGN_OUT_WITH_GOOGLE: 'Se déconnecter de Google',
  TITLE_CREATE_NEW_QUESTION: 'Créer une nouvelle question',
  TITLE_EDIT_QUESTION: 'Modifier la question',
  WORD_NEW_QUESTION: 'Nouvelle question',
  LABEL_QUESTION_NAME: 'Nom de la question',
  TITLE_CREATE_NEW_GREETING: 'Créer une nouvelle salutation',
  TITLE_EDIT_GREETING: 'Modifier la salutation',
  LABEL_GREETING_NAME: 'Nom de la salutation',
  TITLE_EDIT_PROMOTION: 'Modifier la promotion',
  TITLE_EDIT_SPECIAL: 'Modifier le spécial',
  TITLE_EDIT_ANNOUNCEMENT: "Modifier l'annonce",
  TITLE_CREATE_PROMOTION: 'Créer une nouvelle promotion',
  TITLE_CREATE_SPECIAL: 'Créer une nouvelle promotion',
  TITLE_CREATE_ANNOUNCEMENT: 'Créer une nouvelle annonce',
  WORD_NEW_GREETING: 'Nouvelle salutation',
  LABEL_QUESTION_MESSAGE: 'Message de question',
  LABEL_ANSWER_MESSAGE: 'Répondre au message',
  TEXT_ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_QUESTION:
    'Êtes-vous sûr de vouloir supprimer cette question ?',
  WORD_BROADCASTING: 'Radiodiffusion',
  WORD_AUTOMATIONS: 'Automatisations',
  WORD_TOTAL_CAMPAIGNS: 'Total de campagnes',
  WORD_SEARCH: 'Rechercher ...',
  WORD_SORT: 'Trier par',
  WORD_CREATE_CAMPAIGN: 'Créer une campagne',
  WORD_CREATED_BY: 'Créé par',
  WORD_DATE_TIME: 'Date/heure',
  WORD_AUDIENCE: 'Audience',
  WORD_REPLY: 'Répondu',
  WORD_OPPORTUNITIES: 'Opportunités',
  WORD_SELECT: 'Sélectionner',
  WORD_SELECT_TIME: `Veuillez sélectionner l'heure !`,
  WORD_PLEASE_SELECT_DATE: 'Veuillez sélectionner une date !',
  WORD_PLEASE_SELECT_USER: 'Veuillez sélectionner utilisateur',
  WORD_PLEASE_SELECT_ORGANIZATION: 'Veuillez sélectionner organisation',
  WORD_PLEASE_SELECT: 'Veuillez sélectionner',
  TEXT_BROADCAST_WILL_BE_SENT: 'La diffusion sera envoyée selon l’horaire spécifié',
  TEXT_UPLOAD_WITH_EXCEL_FILE: 'Télécharger un fichier CSV ou Excel',

  TEXT_UPLOAD_WITH_EXCEL_FILE_INFO:
    'Téléchargez votre liste de clients au format CSV ou Excel',
  TEXT_CREATE_A_SEGMENT_INFO: 'Vous créez un segment basé sur tous les clients',
  TEXT_UPLOAD_WITH_EXCEL: 'Télécharger un fichier CSV ou Excel',
  WORD_CREATE: 'Créer',
  TEXT_UPLOAD_EXCEL_FILE_NOTE:
    "Téléchargez votre liste de clients dans un fichier Excel avec les colonnes 'prénom', 'nom', 'numéro de téléphone', 'courriel', 'langue'.",
  UPLOAD_FILE_XLSX: 'Charger un fichier (XLSX)',
  ACTION_APPLY: 'Appliquer',
  ACTION_SCHEDULE: 'Envoyer',
  UPLOAD_IMAGE: 'Télécharger une image',

  WORD_REPLACE: 'Remplacer',

  WORD_SELECTED_USERS: 'Utilisateurs sélectionnés',
  WORD_SELECTED: 'Sélectionné',
  WORD_LIST: 'Liste',
  LABEL_DO_WE_SHOW_IT_ON_MOBILE: 'Affichons-nous cela sur mobile ?',
  ACTION_SHOW_ON_MOBILES: 'Afficher sur les mobiles',
  ACTION_SHOW_ON_DESKTOPS: 'Afficher sur les ordinateurs de bureau',
  WORD_PHONE_NUMBER: 'Numéro de téléphone',

  UPLOAD_EXCEL_FILE: 'Choisissez votre audience',
  TEXT_CREATE_CAMPING_TYPE_DESCRIPTION:
    'Commencez par télécharger une liste de prospects ou segmentez à partir de la base de données existante',

  BUILD_CONTENT: 'Créer le contenu',

  TEXT_CREATE_A_SEGMENT: 'Créer un segment',
  TEXT_CREATE_SEGMENT_DESCRIPTION:
    'Soyez aussi précis que nécessaire pour cibler le prospect idéal',
  WORD_LANGUAGES: 'Langues',
  WORD_ASSIGNED_TO: 'Assigné à',

  TEXT_UPLOAD_FILE: 'Télécharger un fichier',

  TEXT_FILE_UPLOAD_ERROR:
    'Le format du fichier est incorrect. Veuillez télécharger un fichier au format .xls',

  WORD_NEXT: 'Suivant',
  WORD_PREVIOUS: 'Précédent',

  TEXT_START_YOUR_CAMPAIGN: 'Lancez votre campagne',
  TEXT_NAME_OF_BROADCAST: 'Nom de la diffusion',
  TEXT_LOCATION_DO_THESE_USERS_BELONG_TO:
    'Dans quel emplacement ces utilisateurs appartiennent-ils ?',
  TEXT_CONSENT_DESCRIPTION:
    'Ces clients sont déjà inscrits à nos services de messagerie',
  TEXT_INCLUDE_OPT_OUT_LANGUAGE_IN_MARKETING_MESSAGE:
    'Inclure une clause de désinscription dans le message marketing',

  TEXT_LETS_USE_SOME_MACHINE_LEARNING:
    "Utilisons un peu d'apprentissage automatique",
  TEXT_DESCRIPTION_OF_USE_SOME_MACHINE_LEARNING:
    "L'analyse de sentiment est disponible uniquement pour VinSolutions CRM. Si l'analyse de sentiment est inactive, veuillez connecter un compte VinSolutions sur au moins un emplacement de cette organisation.",
  TEXT_LABEL_OF_USE_SOME_MACHINE_LEARNING:
    "Souhaitez-vous utiliser l'A.I. pour définir des règles basées sur les réponses ?",

  WORD_SCHEDULER: 'Planificateur',
  TEXT_ASSIGN_CAMPAIGN_TO: 'Assigner une campagne à',
  WORD_DATE: 'Date',
  WORD_TIME: 'Heure',
  TEXT_ADD_A_TAG_FOR_BROADCASTED_USERS:
    'Ajouter une étiquette pour les utilisateurs diffusés',

  TEXT_TERMS_AND_CONDITIONS: 'Termes et Conditions',
  TEXT_TITLE_OF_TERMS_AND_CONDITIONS:
    "Je confirme avoir obtenu le consentement de toutes les personnes figurant sur la liste que je m'apprête à diffuser, et que ce consentement m'autorise à les contacter par SMS aux fins prévues par cette communication.",

  TOOLTIP_TEXT:
    "Cette action n'est pas encore prise en charge pour les conversations [messanger] messenger",
  TEXT_ENTER_YOUR_QUESTION_HERE: 'Entrez votre question ici.',
  TEXT_CHAT_HELP_START_CONVERSATION: 'Commencez votre conversation ci-dessous.',
  TEXT_CHAT_HELP_CHOOSE_QUESTION: 'OU choisissez une question ci-dessous :',
  POWERED_BY: 'Propulsé par',
  USE_AS_SUBJECT_TO: 'Utiliser comme objet pour',
  TERMS: 'conditions',
  TEXT_MESSAGE_FEES_MAY_APPLY: "Des frais de messagerie texte peuvent s'appliquer.",
  TEXT_RECEIVED: 'Reçu',
  WE_GOT_YOUR_MESSAGE: 'Nous avons reçu votre message',
  CHAT_TEXT_ME: 'Envoyez-moi un message',
  MOBILE_PHONE: 'Téléphone portable',
  NUMBER_PROVIDED: 'numéro fourni.',
  MESSAGE_DATA_RATES_APPLY: "Des tarifs peuvent s'appliquer aux messages et données",

  MESSAGE_CONSENT_CHANGED:
    '[user] a modifié le statut de consentement de ce client de [previousStatus] à [newStatus]',

  MATADOR_AI: 'Matador.ai',

  TEXT_ORGANIZATION_BROADCASTING_SETTINGS:
    "Paramètres de diffusion de l'organisation",
  FORWARD_NEW_LEADS_TO_CRM: 'Transférer les nouveaux leads au CRM',
  TEXT_LOGGED_ACCOUNT: 'Compte connecté',

  WORD_PROFILE_INFORMATION: 'Informations de profil',
  WORD_NOTIFICATION_SETTINGS: 'Paramètres de notification',
  WORD_PROFILE_SETTINGS: 'Paramètres du profil',
  WORD_NOTIFICATIONS: 'Notifications',
  WORD_ADDITIONAL_CONFIGS: 'Configurations supplémentaires',
  TEXT_NOTIFICATIONS_BEING_ASSIGNED: 'Assigné à une conversation',
  TEXT_SELECTED_CONVERSATIONS_ONLY: 'Sur les organisations sélectionnées uniquement',
  TEXT_ASSIGNED_TO_CONVERSATIONS: 'Assigné à une conversation',
  LABEL_HOW_DO_YOU_WANT_TO_RECEIVE_NOTIFICATIONS:
    'Comment voulez-vous recevoir les notifications?',
  ACTION_DO_YOU_WANT_TO_RECEIVE_NOTIFICATIONS:
    'Voulez-vous recevoir des notifications ?',

  NTF_PHONE_NUMBER_REQUIRED: 'Veuillez entrer votre numéro de téléphone',
  EMPTY_PHONE_NUMBER_REQUIRED: 'Le numéro de téléphone doit contenir 10 chiffres',

  WORD_LAST_SEEN_AT: 'Vu pour la dernière fois à :',

  TEXT_HIDE_ADDITIONAL_VISUALS: 'Masquer les visuels supplémentaires',
  TEXT_SHOW_ADDITIONAL_VISUALS: 'Afficher des visuels supplémentaires',

  TEXT_CREATE_NEW_COUPON: 'Créer un nouveau coupon',
  TEXT_CREATE_NEW_PROMOTION: 'Créer une nouvelle promotion',
  TEXT_CREATE_NEW_EXIT_INTENT: 'Créer une nouvelle intention de sortie',
  TEXT_GROW_SUBSCRIBERS_WITH_MATADOR: "Augmentez le nombre d'abonnés avec Matador",
  LABEL_POPUP_DELAY: 'Délai de la fenêtre contextuelle',
  LABEL_POPUP_TRIGGER: 'Déclencheur de popup',
  LABEL_THEME: 'Thème',
  TEXT_SHOW_ONCE_PER_DAY_TO_EACH_USER:
    'Afficher une fois par jour à chaque utilisateur.',
  TEXT_DRAG_LOGO_HERE_TO_UPLOAD:
    'Ajoutez une photo à ajouter à la fenêtre contextuelle intelligente',
  WORD_ATTACHMENT: 'Pièce jointe',
  TEXT_VIEW_THIS_PAGE: 'Voir cette page',
  ARE_YOU_SURE_YOU_WANT_TO_CLOSE_THE_NOTE_THE_NOTE_WILL_NOT_BE_SAVED:
    'Êtes-vous sûr de vouloir fermer la note ? La note ne sera pas sauvegardée',
  TEXT_GET_10_OFF_YOUR_ORDER: 'Obtenez 10 % de réduction sur votre commande',
  TEXT_BE_THE_FIRST_TO_KNOW_ABOUT_NEW_PRODUCTS_AND_SPECIAL_DISCOUNTS:
    'Soyez le premier à être informé des nouveaux produits et des remises spéciales.',
  TEXT_SWIPE_TO_START: 'Balayez pour commencer',
  WORD_TERMS_LINK: "Lien vers les conditions d'utilisation",
  TEXT_TERMS_LINK_INVALID: 'Le lien des conditions est invalide',
  WORD_DEFAULT_IMAGE_URL: "URL de l'image par défaut",
  WORD_GA_TRACKING_ID: 'GA ID de suivi',
  TEXT_DRAG_A_FILE_HERE: 'Glissez un fichier ici',
  WORD_OR_LOW: 'ou',
  WORD_BROWSE: 'parcourir',
  TEXT_FOR_A_FILE_TO_UPLOAD: 'pour un fichier à télécharger',

  TEXT_WOULD_YOU_WANT_TO_UTILIZE_AI_TO_SET_RULES_BASED_ON_REPLIES:
    "Voudriez-vous utiliser l'AI pour définir des règles basées sur les réponses ?",
  TEXT_ADD_AN_APPOINTMENT_TO_THE_BROADCAST: 'Ajouter un rendez-vous à la diffusion',
  WORD_LAST_MESSAGE: 'Dernier message',
  WORD_LAST_MESSAGE_RECEIVED: 'Dernier message envoyé/reçu',
  ERROR_PLEASE_FILL_OUT_THE_MESSAGE_TEXT_FIELD:
    'Veuillez remplir le champ de texte du message',
  TEXT_THE_CONVERSATION_MOVED_TO_MY_MESSAGES_SUCCESSFULLY:
    'La conversation a été déplacée vers Mes messages avec succès',
  TEXT_ANSWERED_QUESTION: 'Question répondue',
  TEXT_HOURS_SAVED: 'Heures économisées',
  WORD_THE_BRAIN: 'Le Cerveau',
  WORD_DISCUSSED_TOPICS: 'Sujets abordés',

  WORD_PRICE: 'Détails des prix',
  WORD_PAYMENT: 'Paiements',
  WORD_TRADES: 'Demande de reprise',
  WORD_SCHEDULE: 'horaire',
  WORD_APPROVAL: 'Approbation des finances',
  WORD_AVAILABILITY: 'Disponibilité',
  WORD_GENERAL_AVAILABILITY: 'Disponibilité générale',
  WORD_DEALER_FEE: 'Frais de concessionnaire',
  WORD_DOWN_PAYMENT: 'Acompte',
  WORD_FINANCE: 'Financement',
  WORD_GENERAL_FINANCE: 'Finances générales',
  WORD_HISTORY_REPORT: "Rapport d'historique",
  WORD_NEGOTIATIONS: 'Négociation',
  WORD_SHIPPING: 'Expédition',
  WORD_VEHICLE_OPTIONS: 'Options du véhicule',
  WORD_CONFIRMED: 'Confirmation',
  WORD_APPOINTMENT_CONFIRMATION: 'Confirmation de rendez-vous',
  WORD_HOLD_TRANSFER: 'Retenir le véhicule',
  WORD_HOURS_LOCATION: 'heures emplacement',
  WORD_PROCESS: 'Processus',
  WORD_REAPPOINTMENT: 'Reprendre rendez-vous',
  WORD_VEHICLE_RECOMMENDATIONS: 'Recommandations de véhicules',
  WORD_VEHICLE_QUESTIONS: 'questions sur le véhicule',
  WORD_APPOINTMENT: 'Demande de rendez-vous',
  WORD_GENERAL_APPOINTMENT_QUERIES: 'Questions générales sur les rendez-vous',
  WORD_RESCHEDULE: 'reprogrammer',
  'WORD_PROCESS.CALLBACK': 'Le client souhaite être rappelé',
  'WORD_FINANCE.ONLINE_PROCESSING': 'Processus de financement',
  'WORD_HOURS_AND_LOCATION.LOCATION': 'Emplacement du concessionnaire',
  'WORD_PROCESS.NOT_INTERESTED': 'Pas intéressé',
  'WORD_HOURS_AND_LOCATION.WORKING_HOURS': "Heures d'ouverture",
  'WORD_FINANCE.BAD_CREDIT': 'Mauvais crédit',
  'WORD_PROCESS.CUSTOMER_HAS_QUERIES': 'Client a des questions',
  'WORD_VEHICLE_OPTIONS.PICTURES': 'Photo de voiture demandée',
  'WORD_PROCESS.ALREADY_DISCUSSED': 'Déjà parlé',
  'WORD_PROCESS.WHO_IS_THIS': 'Qui est-ce',
  'WORD_APPOINTMENT.ALREADY_BOUGHT': 'Je ne suis plus intéressé',
  'WORD_AVAILABILITY.UPDATE_ON_VEHICLE': 'Mise à jour du véhicule',
  'WORD_APPOINTMENT.ALREADY_SCHEDULED': 'Rendez-vous déjà programmé',
  'WORD_FINANCE.REQUIRED_DOCUMENTS': 'Documents requis',
  'WORD_FINANCE.INSURANCE': "Requête d'assurance",
  'WORD_APPOINTMENT.CANCEL_APPOINTMENT': 'Annulation de rendez-vous',
  'WORD_APPOINTMENT.SERVICE_APPOINTMENT': 'Réservation de rendez-vous',
  'WORD_AVAILABILITY.NOT_SURE_OF_VEHICLE': 'Je ne suis pas certain du véhicule',
  'WORD_PROCESS.WHO_DO_I_SPEAK_TO': "À qui s'adresser",
  'WORD_PROCESS.IN_PLACE_WITH_REPRESENTATIVE': 'En magasin',
  'WORD_APPOINTMENT.ALREADY_CANCELLED_APPOINTMENT': 'Le rendez-vous est déjà annulé',
  WORD_UNSUBSCRIBE: 'Se désabonner',
  'WORD_FINANCE.CREDIT_SCORE':
    "L'utilisateur pose une question sur la cote de crédit",
  'WORD_FINANCE.ALREADY_SUBMITTED_APPLICATION':
    "L'utilisateur a déjà soumis une demande précédente",
  WORD_TIRE_ROTATION: 'Rotation des pneus',
  WORD_PARTS_AVAILABILITY: 'Disponibilité des pièces',
  WORD_PART_PRICE: 'Partie du prix',
  WORD_CAR_WASH: 'Lave-auto',
  WORD_OIL_CHANGE: "Vidange d'huile",
  WORD_FIRST_SEEN: 'Aperçu pour la première fois',
  WORD_LAST_SEEN: 'Vu la dernière fois',
  WORD_DAYS_BROWSING: 'Jours de navigation',
  WORD_PAGES_VIEWED: 'Pages vues',
  TEXT_CONNECT_CRM_EMAIL_IN_LOCATION_SETTINGS:
    "Connecter l'e-mail CRM dans les paramètres de localisation",
  TEXT_ORGANIZATION_IS_ACTIVE: "L'organisation est active",
  TEXT_ACCESS_TO_BROADCASTING_FEATURE: 'Accès à la fonctionnalité de diffusion',
  TEXT_INTEGRATE_YOUR_PAYMENT_ACCOUNT: 'Intégrez votre compte de paiement',
  WORD_CONNECT_PAYMENTS: 'Connecter les paiements',
  TEXT_ARE_YOU_SURE_YOU_WANT_TO_DISCONNECT_PAYMENTS:
    'Êtes-vous sûr de vouloir déconnecter les paiements ?',
  TEXT_ARE_YOU_SURE_YOU_WANT_TO_DISCONNECT_EMAIL:
    'Êtes-vous sûr de vouloir déconnecter votre e-mail',
  WORD_CONNECT: 'Connecter',
  WORD_DISCONNECT: 'Déconnecter',
  WORD_DISCONNECT_PAYMENTS: 'Déconnecter les paiements',
  TEXT_ORG_POPUP_MESSAGE: `Désactiver une organisation rendra la messagerie impossible et désactivera temporairement le compte.
Êtes-vous sûr de vouloir continuer ?`,
  TEXT_ORGANIZATION_WAS_CREATED_SUCCESSFULLY:
    "L'organisation a été créée avec succès.",
  TEXT_ORGANIZATION_WAS_SAVED_SUCCESSFULLY:
    "L'organisation a été enregistrée avec succès.",
  TEXT_MATADOR_CONNECT_SAVED_SUCCESSFULLY:
    'La connexion Matador a été enregistrée avec succès.',
  WORD_TAG_NAME_HERE: 'Nom de balise ici',
  WORD_DEFAULT: 'Défaut',
  WORD_WORKFLOW: 'Workflow',
  WORD_WORKFLOWS: 'Workflows',
  TEXT_CAN_WE_TEXT_CUSTOMERS_IN_THE_PENDING_STATUS:
    'Restreindre les textos aux clients en attente de conformité',
  TEXT_CAN_WE_SEND_BROADCASTS_ON_WEEKENDS:
    "Restreindre l'envoi de diffusions pendant le week-end",
  RESTRICT_SUPPORTS_ACCESS_TO_ALL_LOCATIONS:
    "Restreindre l'accès des agents de support à tous les emplacements de l'organisation",
  RESTRICT_MANAGERS_ACCESS_TO_ALL_LOCATIONS:
    "Restreindre l'accès des gestionnaires à tous les emplacements au sein de l'organisation",
  TEXT_ACCESS_SUPPORT_AGENT_TO_UNASSIGNED:
    'Restrict Support Agents access to "Non attribué" folder',
  TEXT_ACCESS_SUPPORT_AGENT_TO_ARCHIVE:
    'Restrict Support Agents access to conversations in "Archivé" folder',
  TEXT_ACCESS_SUPPORT_AGENT_TO_MAIN_INBOX:
    'Restrict Support Agent access to "Boîte de réception principale" folder',
  TEXT_RESTRICTION_WAS_CHANGED_SUCCESSFULLY:
    'La restriction a été modifiée avec succès.',
  TEXT_RESTRICT_DISPLAYING_PUSH_CRM_BUTTON_SUPPORT_AGENTS:
    'Restrict displaying "Envoyer au CRM" button to Support Agents',

  WORD_HOUR: 'Heure',
  WORD_OPEN: 'Ouvrir',
  WORD_CLOSED: 'Fermé',
  WORD_FROM: 'De',
  WORD_TO: 'À',
  WORD_REFUSED: 'Refusé',
  WORD_PENDING: 'En attente',
  WORD_COMPLIANT: 'Conforme',
  TEXT_CONSENT_WAS_SENT_SUCCESSFULLY: 'Le consentement a été envoyé avec succès.',
  WORD_APPROVED: 'Approuvé',
  TEXT_WE_ARE_UNSURE_IF_THIS_CUSTOMER_IS_COMPLIANT_AWAITING_CONSENT:
    'Nous ne sommes pas sûrs que ce client soit conforme. En attente de consentement.',
  TEXT_WE_ARE_UNSURE_IF_THIS_CUSTOMER_IS_COMPLIANT_PLEASE_ASK_FOR_CONSENT:
    'Nous ne sommes pas certains que ce client soit conforme. Veuillez demander son consentement.',
  TEXT_WE_HAVE_RECEIVED_CONSENT_FROM_THIS_CUSTOMER:
    'Nous avons reçu le consentement de ce client.',
  TEXT_CUSTOMER_HAS_DECIDED_TO_OPT_OUT_FROM_UPCOMING_COMMUNICATIONS:
    'Le client a décidé de se désabonner des communications à venir.',
  TEXT_THE_CUSTOMER_DOES_NOT_WANT_TO_RECEIVE_BROADCASTED_MESSAGES:
    'Le client ne souhaite pas recevoir de messages diffusés.',
  TEXT_ARE_YOU_SURE_YOU_WANT_TO_OPT_OUT_THIS_CONVERSATION:
    'Êtes-vous sûr de vouloir vous désinscrire de cette conversation ?',
  WORD_OPT_OUT: 'Se désabonner',
  WORD_AT: 'à',
  WORD_ASK_CONSENT: 'Demander le consentement',
  TEXT_ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_SEQUENCE:
    'Êtes-vous sûr de vouloir supprimer cette séquence ?',
  WORD_LABEL_TRIGGERED_ON: 'Déclenché le',
  WORD_LABEL_TRIGGERED_ON_TOUCHPOINTS: 'Déclenché sur le point de contact',
  WORD_AFTER_HOUR_MESSAGE: "Message après les heures d'ouverture",
  WORD_FIRST_AUTO_MESSAGE_WL: 'Premier message automatique',
  WORD_ADD_NEW_DRIP: 'Ajouter un nouveau rappel',
  WORD_THE_AFTER_HOUR_MESSAGE: "Le message après les heures d'ouverture",
  WORD_AFTER: 'après',
  TEXT_PLEASE_SELECT_TRIGGERED_ON_OPTION_FIRST:
    'Please, select "Déclenché le" option first.',
  TEXT_CUSTOMER_LAST_SESSION: 'dernière session du client',
  WORD_MESSAGES_LC: 'messages',
  TEXT_YOU_CAN_USE: 'Vous pouvez utiliser',
  WORD_CUSTOMER_FIRST_NAME_LC: 'prénom_du_client',
  WORD_CUSTOMER_LAST_NAME_LC: 'nom_de_famille_du_client',
  WORD_ORGANIZATION_NAME_LC: 'nom_organisation',
  WORD_LOCATION_NAME_LC: 'nom_emplacement',
  WORD_TOUCHPOINT_NAME_LC: 'point de contact',
  WORD_USER_FIRST_NAME_LC: 'prénom_utilisateur',
  WORD_USER_LAST_NAME_LC: 'nom_utilisateur',
  WORD_VEHICLE: 'Véhicule',
  TEXT_CUSTOMER_ORG_LOC_TOUCH_NAMES:
    "dans les messages ci-dessous pour insérer le client, le nom de l'organisation de l'emplacement et/ou le point de contact.",
  ERROR_MESSAGE_PLEASE_INPUT: 'Veuillez saisir',
  TEXT_UPDATE_ORGANIZATION_DRIP_SEQUENCE_ENABLED:
    'Fonctionnalité de séquence activée avec succès',
  TEXT_UPDATE_ORGANIZATION_DRIP_SEQUENCE_DISABLED:
    'Fonctionnalité de séquence désactivée avec succès',
  TEXT_SEQUENCE_WAS_DELETED_SUCCESSFULLY: 'La séquence a été supprimée avec succès',
  TEXT_SEQUENCE_WAS_SAVED_SUCCESSFULLY: 'La séquence a été enregistrée avec succès',
  TEXT_UPDATE_ORGANIZATION_USER_TEMPLATES:
    "Mettre à jour les modèles d'utilisateur de l'organisation",
  WORD_ADD_TEMPLATE: 'Ajouter un modèle',
  WORD_NAME_OF_TEMPLATE: 'Nom du modèle',
  TEXT_TEMPLATE_WAS_DELETED_SUCCESSFULLY: 'Le modèle a été supprimé avec succès',
  TEXT_ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_TEMPLATE:
    'Êtes-vous sûr de vouloir supprimer ce modèle ?',
  WORD_TURN_ON_TOGGLE_WHEN:
    'Activez le bouton à bascule lorsque cette source spécifique a déjà demandé le consentement du client.',
  WORD_TURN_ON_TOGGLE_IF:
    'Activez le bouton bascule si vous souhaitez que toutes les nouvelles conversations soient automatiquement transférées vers votre CRM.',
  WORD_NO_DATA: 'Pas de données',
  WORD_TOUCHPOINT_NAME: 'Nom du point de contact',
  TEXT_TOUCHPOINT_CHANGED_SUCCESSFULLY: 'Point de contact modifié avec succès',
  ERROR_MESSAGE_YOU_DO_NOT_HAVE_ACCESS_TO_ENABLE_THIS_FEATURE:
    "Vous n'avez pas accès pour activer/désactiver cette fonctionnalité.",
  NTF_SUCCESS_DRIP_SEQUENCE_WAS_CHANGED_SUCCESSFULLY:
    'La séquence de goutte à goutte a été modifiée avec succès',
  TEXT_DRIP_SEQUENCE_WAS_CANCELLED_BY: 'La séquence a été annulée par',
  NTF_SUCCESS_AI_BOT_WAS_CHANGED_SUCCESSFULLY:
    'La séquence de goutte à goutte a été modifiée avec succès',
  NTF_SUCCESS_BROADCASTING_WAS_CHANGED_SUCCESSFULLY:
    'La diffusion a été modifiée avec succès',
  NTF_SUCCESS_PAYMENT_WAS_CHANGED_SUCCESSFULLY:
    'Le paiement a été modifié avec succès.',
  WORD_PAYMENTS: 'Paiements',
  WORD_PAYMENT_UPF: 'Paiement',
  WORD_USERS: 'Utilisateurs',
  WORD_LOCATIONS: 'Emplacements',
  WORD_ACCOUNT_SETTINGS: 'Paramètres du compte',
  WORD_MESSAGE: 'Message',
  WORD_SUBJECT: 'Sujet',
  WORD_TYPE_HERE: 'Tapez ici...',
  TEXT_YOU_SUCCESSFULLY_DISCONNECTED_YOUR_EMAIL:
    'Vous avez déconnecté votre e-mail avec succès.',
  TEXT_EMAIL_DISCONNECT_ERROR: "Erreur de déconnexion de l'e-mail",
  WORD_DISCONNECT_EMAIL: "Déconnecter l'e-mail",
  TEXT_PLEASE_ENTER_THE_EMAIL_YOU_WANT_TO_CONNECT:
    "Veuillez saisir l'adresse e-mail que vous souhaitez connecter",
  TEXT_PLEASE_USE_AM_OR_PM_FORMAT: 'Veuillez utiliser le format AM ou PM',
  TEXT_PLEASE_INPUT_VALID_NUMBER: 'Veuillez saisir un nombre valide',
  TEXT_YOU_CANNOT_SET_THE_OPENING_HOUR_BEFORE_SEVEN_AM:
    "Vous ne pouvez pas définir l'heure d'ouverture avant 7h du matin. Veuillez saisir une valeur entre 7h et 22h.",
  TEXT_YOU_CANNOT_SET_THE_CLOSING_HOUR_AFTER_TEN_PM:
    "Vous ne pouvez pas définir l'heure de fermeture après 22h. Veuillez saisir une valeur entre 7h et 22h.",
  TEXT_PLEASE_ENTER_VALID_MINUTES_FORMAT:
    'Veuillez saisir un format de minutes valide',
  WORD_APPOINTMENTS_DATE: 'Date de rendez-vous',
  WORD_CANCELED: 'Annulé',
  WORD_CANCELED_TWO_L: 'Annulé',
  WORD_BY: 'par',
  TEXT_TYPE_SMS_TEMPLATE: 'Modèle de SMS de type',
  TEXT_SMS_TEMPLATE: 'Modèle de SMS',
  TEXT_YOUR_COUPON_SAVED_SUCCESSFULLY: 'Votre coupon a été enregistré avec succès',
  TEXT_YOUR_POPUP_SAVED_SUCCESSFULLY: 'Votre popup a été enregistrée avec succès',
  WORD_STORIES: 'Stories',
  WORD_PROMOTIONS: 'Promotions',
  TEXT_TYPE_A_MESSAGE_TO_SMS_TO_THE_CUSTOMER:
    'Saisissez un message à envoyer par SMS au client',
  TEXT_THE_CLIENT_HAS_NOT_GIVEN_SMS_CONSENT:
    "Le client a refusé de recevoir des SMS. Essayez d'envoyer un e-mail",
  TEXT_RESTRICTED_FROM_SENDING_MSG_TO_YELLOW_STATUS:
    "Vous ne pouvez pas envoyer de SMS aux clients non consentants en raison des restrictions de l'organisation. Essayez de demander le consentement ou d'envoyer un e-mail au client",
  TEXT_SEND_THIS_MESSAGE_LATER: 'Envoyer ce message plus tard',
  TEXT_REMIND_ME_TO_FOLLOW_UP: 'Rappelle-moi de faire un suivi',
  TEXT_SCHEDULED_MESSAGE_DELETED_SUCCESSFULLY:
    'Message planifié supprimé avec succès',
  TEXT_SCHEDULED_MESSAGE_SKIPPED_SUCCESSFULLY:
    'Message planifié supprimé avec succès',
  TEXT_MESSAGE_SCHEDULED_BY: 'Message programmé par',
  TEXT_EMAIL_SCHEDULED_BY: 'E-mail planifié par',
  TEXT_ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_SCHEDULED_MESSAGE:
    'Êtes-vous sûr de vouloir supprimer ce message programmé?',
  TEXT_TYPE_EMAIL_BODY: "Saisissez le corps de l'e-mail",
  TEXT_FAILED_MESSAGE: "Échec de l'envoi du message. Veuillez réessayer",
  TEXT_REMINDER_HAS_BEEN_TRIGGERED: 'Veuillez faire un suivi avec votre client.',
  TEXT_ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_REMINDED_MESSAGE:
    'Êtes-vous sûr de vouloir supprimer ce message de rappel',
  TEXT_MESSAGE_REMINDED_SUCCESSFULLY: 'Rappel créé avec succès.',
  TEXT_REMINDED_MESSAGE_DELETED_SUCCESSFULLY:
    'Message de rappel supprimé avec succès',
  TEXT_NOTIFICATION_FOR_UNREAD_CONVERSATION: 'Conversation non lue',
  TEXT_UNREAD_CONVERSATIONS: 'Conversations non lues',
  TEXT_NOTIFICATION_UNREAD_CONVERSATION_ALL_ORG:
    'Notification de conversation non lue de toutes les organisations accessibles',
  TEXT_PARSE_ALL_EMAILS_ONLY_WHEN_ASSIGN:
    "Analyser l'e-mail uniquement si vous êtes affecté à la conversation",
  TEXT_DO_YOU_WANT_TO_RECEIVE_NOTIFICATIONS_WHEN_ASSIGNED_TO_A_CONVERSATION:
    'Voulez-vous recevoir des notifications lorsque vous êtes affecté à une conversation ?',
  TEXT_RECEIVE_NOTIFICATION_IF_THE_CONVERSATION_IS_UNREAD_FOR:
    "Recevoir une notification si la conversation n'est pas lue pendant",
  WORD_SMS: 'sms',
  WORD_EMAIL_LC: 'email',
  TEXT_SELECTS_AT_LEAST_ONE_FILTER: 'Sélectionnez au moins un filtre',
  WORD_PLUS_ADD_IMAGE: '+ Ajouter une image',
  TEXT_IN_TEMPLATES_TO_INSERT_THE_CUSTOMER__ORG_INVITE:
    "dans les modèles pour insérer le nom du client, le nom de l'organisation ou/et l'URL d'invitation à l'évaluation",
  TEXT_IN_TEMPLATES_TO_INSERT_THE_CUSTOMER__ORG_INVITE_APP_VEHICLE_MAKE:
    "dans les modèles pour insérer le nom du client, le nom de l'organisation, l'URL d'invitation à l'évaluation, la date du rendez-vous, l'heure du rendez-vous, l'année du véhicule, la marque du véhicule et le modèle du véhicule",
  WORD_APPOINTMENT_BOOKING: 'Réservation de rendez-vous',
  TEXT_IF_THE_BROADCAST_MESSAGE_CONTAINS_MORE_THAN_SYMBOLS:
    'si le message de diffusion contient plus de 160 symboles, il sera envoyé en plusieurs SMS aux clients',
  WORD_FILTER: 'Filtre',
  WORD_BROADCAST_LIST: 'liste de diffusion',
  WORD_ASSIGNED_USER: 'Utilisateur assigné',
  REMINDER_WAS_SET_TO_FOLLOW_UP_WITH_THIS_CUSTOMER:
    'Rappel défini pour effectuer un suivi avec ce client',
  WORD_SMS_UC: 'SMS',
  WORD_MESSENGER_ID: 'Identifiant Messenger',
  TEXT_ARE_YOU_SURE_YOU_WANT_TO_CANCEL_THIS_APPOINTMENT:
    'Êtes-vous sûr de vouloir annuler ce rendez-vous ?',
  TEXT_LOCATION_SELECTED_SUCCESSFULLY: 'Emplacement sélectionné avec succès',
  TEXT_EXCLUDE_CUSTOMERS_WHO_HAVE_BEEN_MESSAGED_IN_THE_LAST:
    'Exclure les clients qui ont déjà reçu une diffusion au cours des derniers',
  WORD_TEMPLATE_LC: 'modèle',
  TEXT_FOR_CREATING_A_BROADCAST: 'pour créer une diffusion',
  TEXT_YOU_CAN_USE_THIS: 'Vous pouvez utiliser ceci',
  WORD_FOLLOWED_LINK: 'Lien suivi',
  WORD_FOLLOWED_TIMES: 'Suivi # fois',
  TEXT_THIS_MESSAGE_WILL_NOT_BE_VISIBLE:
    "Ce message ne sera pas visible dans votre boîte de réception jusqu'à l'heure de livraison prévue",
  TEXT_ARE_YOU_SURE_THIS_APPOINTMENT_IS_FOR:
    'Êtes-vous sûr que ce rendez-vous est pour',
  WORD_PLUS_ADD_NOTE: '+ Ajouter une note',
  WORD_CUSTOMER: 'Client',
  TEXT_TAG_WAS_REMOVED_SUCCESSFULLY: 'Le tag a été supprimé avec succès',
  TEXT_TAG_SAVED_SUCCESSFULLY: 'Balise enregistrée avec succès',
  WORD_REVIEW_INVITE_LINK_LC: "lien d'invitation de révision",
  WORD_BROADCAST: 'diffusion',
  WORD_BROADCAST_UPPER: 'Diffusion',
  WORD_GROUP: 'Groupe',
  WORD_SEARCH_USER: 'Rechercher un utilisateur...',
  WORD_ADD_NEW: 'Ajouter nouveau',
  TEXT_DO_YOU_WANT_TO_RECEIVE_NOTIFICATIONS_ON_UNASSIGNED_CONVERSATIONS:
    'Voulez-vous recevoir des notifications sur les conversations non attribuées ?',
  WORD_PROFILE_NOTIFICATION: 'Notification de profil',
  TEXT_NOTIFICATIONS_FOR_UNASSIGNED_CONVERSATION: 'Conversation non attribuée',
  TEXT_UNASSIGNED_CONVERSATIONS: 'Conversations non attribuées',
  TEXT_RECEIVE_NOTIFICATION_IF_THE_CONVERSATION_IS_UNASSIGNED_FOR:
    "Recevoir une notification si la conversation n'est pas attribuée pendant",
  TEXT_IMAGE_MUST_SMALLER_THAN:
    'Veuillez essayer de télécharger le fichier de moins de',
  TEXT_YOU_CAN_ONLY_UPLOAD: 'Vous ne pouvez que téléverser',
  WORD_FILE: 'fichier',
  TEXT_SHOULD_BE_SENT: 'devrait être envoyé',
  TEXT_THE_AFTER_HOUR_MESSAGE: "Le message aprs les heures d'ouverture",
  TEXT_RECEIVING_A_LEAD: "réception d'un lead.",
  TEXT_DELIVERABILITY_SCORE: 'Indice de délivrabilité :',
  TEXT_MESSAGE_LIKELY_CARRIER_VIOLATION_AND_WILL_HAVE_LOW_DELIVERY_RATE:
    'Ce message texte est probablement une violation du transporteur et aura un faible taux de livraison.',
  WORD_SEND_ANYWAY: 'Envoyer quand même',
  WORD_REVIEW_COPY: 'Réviser la copie',
  TEXT_CREATE_NEW_DRIP_CAMPAIGN: 'Créer une nouvelle campagne Drip',
  WORD_CAMPAIGN_NAME: 'Nom de la campagne',
  WORD_CHOOSE_TRIGGER: 'Choisir le déclencheur',
  WORD_TYPE_OF_MESSAGE: 'Type de message',
  WORD_DISMISS: 'Ignorer',
  WORD_HOURS: 'heures',
  WORD_MINUTES: 'minutes',
  WORD_SECONDS: 'Secondes',
  WORD_IMMEDIATELY: 'immédiatement',
  WORD_YEAR: 'année',
  WORD_MAKE: 'faire',
  WORD_MODEL: 'modèle',
  WORD_REVIEW_TITLE: "Modèles de texte d'e-mail d'invitation à l'évaluation",
  WORD_IN_THE_BELOW_TEMPLATES: 'dans les modèles ci-dessous.',
  TEXT_CUSTOMER_ORG_LOC_NAMES:
    "dans les messages ci-dessous pour insérer le client, l'emplacement, le nom de l'organisation.",
  WORD_LANDING_PAGE_CONTENT: 'Contenu de la page de destination',
  WORD_APPOINTMENT_DATE_DT: 'date_de_rendez_vous',
  WORD_APPOINTMENT_TIME_DT: 'heure du rendez-vous',
  WORD_LOCATION_NAME_DT: 'emplacement_nom',
  TEXT_CONNECT_YOUR_EMAIL_FOR_GETTING_APPOINTMENTS_THERE:
    'Connectez votre e-mail pour recevoir vos rendez-vous ici',
  TEXT_PROVIDE_AN_OPPORTUNITY_TO_INTERACT_WITH_CUSTOMERS_THROUGH_AN_INSTANT_MESSAGING_SERVICE:
    "Offrir la possibilité d'interagir avec les clients via un service de messagerie instantanée",
  TEXT_LOCATION_WAS_CREATED_SUCCESSFULLY: "L'emplacement a été créé avec succès",
  TEXT_LOCATION_WAS_UPDATED_SUCCESSFULLY:
    "L'emplacement a été mis à jour avec succès",
  TEXT_ARE_YOU_SURE_YOU_WANT_TO_DISCONNECT_YOUR_GOOGLE_ACCOUNT:
    'Êtes-vous sûr de vouloir déconnecter votre compte Google ?',
  TEXT_ARE_YOU_SURE_YOU_WANT_TO_DISCONNECT_YOUR_OUTLOOK_ACCOUNT:
    'Êtes-vous sûr de vouloir déconnecter votre compte Outlook ?',
  TEXT_CUSTOMER_APP_DATE_TIME_LOC_NAMES:
    "dans les messages ci-dessous pour insérer le nom du client, la date, l'heure et/ou le nom du lieu du rendez-vous",
  WORD_API_KEY: 'Clé API',
  WORD_MATADOR_USER_ID: `Identifiant d'utilisateur de Matador`,
  WORD_CLIENT_ID: 'Identifiant Client',
  LABEL_AUTO_ASSIGN_NEW_TOUCHPOINT:
    'Affecter tous les nouveaux points de contact créés aux séquences suivantes :',
  ERROR_SEQUENCES_OF_SAME_TYPE:
    'Vous avez déjà choisi une séquence active. La seule autre séquence que vous pouvez choisir est après les heures normales',
  ERROR_AUTO_ASSIGNEMENT_SEQUENCES_LIMIT:
    'Vous ne pouvez pas définir plus de 2 séquences.',
  TEXT_SUCCESS_SEQUENCE_REMOVED:
    "La séquence n'a pas été sélectionnée. Les nouveaux points de contact ne seront pas automatiquement associés, sauf si vous en sélectionnez un à nouveau",
  TEXT_SUCCESS_SEQUENCE_ADDED:
    'La séquence a été sélectionnée avec succès. De nouveaux points de contact y seront automatiquement attachés',
  TEXT_SUCCESS_TEXT_ME_BUTTON: 'Bouton Envoyez-moi un SMS mis à jour avec succès.',
  TEXT_TEXT_ME_DEFAULT_LANGUAGE_MISSING:
    "Le texte du bouton ou le texte du modèle pour la langue par défaut de l'organisation est manquant",
  LABEL_SELECTED_SEQUENCES: 'Séquences sélectionnées',
  LABEL_ALL_SEQUENCES: 'Toutes les séquences',
  WORD_NONE: 'Aucun',
  WORD_COPY: 'Copie',
  WORD_REMINDED_MESSAGE: 'Message de rappel',
  TEXT_FOR_MESSAGING_SID_CONFIRM:
    'Cela pourrait avoir un impact sur votre flux de messagerie, êtes-vous sûr de vouloir procéder à ce changement ?',
  ERROR_WE_COULDNT_FIND_WHAT_YOURE_LOOKING_FOR:
    "Nous sommes généralement un coffre au trésor de connaissances, mais nous n'avons pas pu trouver ce que vous cherchez.",
  WORD_OH_NO: 'Oh non !',
  TEXT_WRITE_YOUR_MESSAGE: 'Écrivez votre message',
  ACTION_CONNECTED_BY: 'Connecté par :',

  WORD_NOT_LIKELY: 'Peu probable',
  WORD_VERY_LIKELY: 'Très probable',
  WORD_CSI_SCORE: 'Indice CSI',
  WORD_PROMOTERS: 'Promoteurs',
  WORD_PASSIVE: 'Passif',
  WORD_DETRACTORS: 'Détracteurs',
  WORD_RESP: 'réponse',
  ERROR_MESSAGE_ONLY_IMAGE_ALLOWED: 'Seules les images sont autorisées',
  LABEL_PHONE_OR_EMAIL: 'Téléphone ou e-mail',
  TEXT_CONVERSATION_SYNCED_WITH_CRM: 'Conversation synchronisée avec le CRM',
  ERROR_MESSAGE_IT_IS_IMPOSSIBLE_TO_CHANGE_NUMBER_TO_AUSTRALIAN:
    'Il est impossible de changer le numéro en australien',
  TEXT_DONT_SEND_IF_LAST_MESSAGE_WAS:
    "Ne pas envoyer s'il y a eu un message entrant/sortant dans le dernier",
  TEXT_VIDEO_DURATION_MUST_SMALLER_THAN:
    "Veuillez essayer de télécharger la vidéo de moins d'une minute",
  TEXT_PROMOTION_TITLE: 'Nous voulons votre voiture !',
  TEXT_PROMOTION_TEXT:
    "Obtenez une évaluation gratuite aujourd'hui. Nous paierons toujours plus pour votre véhicule.",
  TEXT_PROMOTION_BUTTON: 'Vendre ma voiture',
  TEXT_PROMOTION_TEMPLATE: 'Bonjour, je suis intéressé par la vente de ma voiture.',
  ERROR_PLEASE_INPUT_THE_ORGANIZATION_NAME:
    "Veuillez saisir le nom de l'Organisation",
  ERROR_WRONG_PHONE_FORMAT: 'Format de téléphone incorrect',
  TOOLTIP_FOR_CHANGE_THE_ORGANIZATION_PLEASE_GO_TO_DASHBOARD_PAGE:
    "Pour changer l'organisation, veuillez vous rendre sur la page du tableau de bord",
  WORD_LANGUGE: 'Langue',
  WORD_SCORE: 'Score',
  WORD_VINSOLUTION_EMAIL: 'E-mail VinSolution',
  ERROR_PLEASE_CHOOSE_TYPE_OF_NOTIFICATION:
    'Veuillez choisir le type de notification',
  WORD_DEALERSOCKET_USERNAME: "Nom d'utilisateur DealerSocket",
  WORD_CONNECT_EMAIL: "Connecter l'e-mail",
  WORD_PROFILE_EDIT: 'Modification du profil',
  ERROR_PLEASE_SELECT_LOCATION_IN_THE_FIELD_WHICH_LOCATION:
    'Veuillez sélectionner un emplacement dans le champ "À QUEL EMPLACEMENT CES UTILISATEURS APPARTIENNENT-ILS ?"',
  WORD_AUTO_DETECTED_LANGUAGE_IS: 'Langue détectée automatiquement',
  WORD_SENT_CLIENT_VIDEO: 'Vidéo client envoyée',
  WORD_SHOW_VIDEO: 'Montrer la vidéo',
  WORD_WATCHED_TIMES: 'Regardé # Fois',
  WORD_INVOICE: 'Facture',
  WORD_REFERENCE_NUMBER: 'Numéro de référence',
  WORD_LINK_OPENED: 'Lien ouvert',
  WARNING_MESSAGE_GOING_BROKEN_UP_SENT_ONE:
    'Ce texte pourrait être envoyé en plusieurs messages',
  WORD_TURN_ON: 'Activer',
  WORD_REMOVE: 'Supprimer',
  TEXT_AFTER_REMOVING_LOGGED_ACCOUNT_LEADS_MIGHT_NO_LONGER_GO_TO_PLATFORM:
    'Après la suppression du compte connecté, les prospects pourraient ne plus accéder à la plateforme',
  TEXT_ARE_YOU_SURE_YOU_WANT_TO_REMOVE_EMAIL_FROM_TOUCHPOINTS:
    "ÊTES-VOUS SÛR DE VOULOIR SUPPRIMER L'E-MAIL DES POINTS DE CONTACT ?",
  WORD_TOTAL_INVOICES: 'Total des factures',
  WORD_REJECTED: 'Rejeté',
  WORD_NOT_PAID: 'Non Payé',
  WORD_PAID: 'Payé',
  WORD_REFUNDED: 'Remboursé',
  WORD_AMOUNT: 'Montant',
  WORD_REFERENCE: 'Référence',
  WORD_DESCRIPTION: 'Description',
  WORD_NET: 'Montant net',
  WORD_FEE: 'Frais',
  WORD_REFUND: 'Remboursement',
  MSG_ARE_YOU_SURE_WANT_TO_REFUND: 'Êtes-vous sûr de vouloir rembourser',
  ERROR_YOU_DO_NOT_HAVE_ACCESS_TO_SEND_PAYMENT:
    "Vous n'avez pas accès pour envoyer le paiement. Veuillez contacter votre responsable.",
  MSG_PAYMENT_REFUND_SUCCESSFULLY: 'Paiement remboursé avec succès',
  WORD_PAYMENT_LIST: 'Liste des paiements',
  WORD_RECEIPT: 'Reçu',
  WORD_INTERESTS: 'Intérêts',
  MSG_ONLY_IMAGE_OR_PDF_FORMATS_CAN_BE_ADDED:
    "Seuls les formats d'image ou PDF peuvent être ajoutés",
  WORD_PLUS_ADD_IMAGE_OR_PDF: '+ Ajouter une image ou un PDF',
  WORD_NEW_STATUS: 'Nouveau statut',
  ERROR_MSG_MESSAGE_NAME_LENGTH:
    'La longueur du message doit être inférieure ou égale à 1400 caractères',
  TEXT_SYNCHRONIZED_CRM_INFO: 'Informations CRM synchronisées',
  WORD_USER_ID: "Identifiant d'utilisateur",
  WORD_USER_NAME: "Nom d'utilisateur",
  WORD_USER_ROLE: "Rôle d'utilisateur",
  WORD_VEHICLE_OF_INTEREST: "Véhicule d'intérêt",
  ERROR_TEMPLATE_TEXTAREA: 'Veuillez remplir le champ de messages [lang]',
  FINGERPRINTING_AUTOMATION: 'Automatisation des empreintes digitales',
  WORD_NOTE: 'Note',
  MSG_NOTE_WAS_CREATED_SUCCESSFULLY: 'La note a été créée avec succès.',
  MSG_YOU_DONT_HAVE_ACCESS_TO_GET_PAYMENTS:
    "Vous n'avez pas accès aux paiements. S'il vous plaît, contactez votre responsable",
  THIS_MOUTH: 'Cette bouche',
  THIS_DAY: 'Ce jour',

  SMS_HEALTH_SCORE: 'Score de santé SMS',
  AUTO_OPTIMIZING: 'Optimisation automatique',
  AVG_INDUSTRY_HEALTHSCORE: "Score de santé moyen de l'industrie",
  CONVERSATIONS_PER_MONTH: 'Conversations par mois',
  AUTOMATIONS: 'Automatisations',
  ACTIVE: 'Actif',
  CONVERSATION_BRAIN: 'Cerveau de Conversation',
  TITLE_MATADOR_CONNECT: 'Matador Connect',
  FINGERPRINTING: "Prise d'empreintes digitales",
  ENGAGING_ANONYMOUS_USERS:
    'Identification et engagement actif des utilisateurs anonymes',
  PROFILES_IDENTIFIED: 'Profils identifiés',
  EQUIVALENT_OF: 'Équivalent de',
  IN_MARKETING_SPEND: 'Dépenses de marketing entrant',
  NEW_PROFILE_ENGAGED: 'Nouveau profil engagé',
  WOO_HOO: 'Youpi',
  CONVERSATION_GENERATOR: 'Générateur de conversation',
  NEW_CONVERSATIONS_GENERATED_VIA_INTENT_BASED_WIDGETS:
    "Nouvelles conversations générées via des widgets basés sur l'intention",
  TEXT_ANSWERED_QUESTIONS: 'Questions répondues par IA',
  WORD_REPUTATION_MANAGEMENT: 'Gestion de la réputation',
  WORD_WOO_HOO: 'Youpi !',
  WORD_LEAD_VOLUME: 'Volume de prospects',
  WORD_ENGAGED_CLIENTS: 'Clients engagés',
  TEXT_AVG_MESSAGE_SENT_PER_CUSTOMER: 'Moyenne de messages envoyés par client',
  WORD_REPLY_SUCCSESS_RATE: "Taux d'engagement",
  TEXT_THIS_VIDEO_IS_PROCESSED_BY_THE_SYSTEM:
    'Cette vidéo est traitée par le système.',
  TEXT_WE_WILL_SEND_MESSAGE_AUTOMATICALLY_ONCE_PROCESSING_IS_FINISHED:
    'Nous enverrons automatiquement un message au client une fois le traitement terminé',
  MSG_VIDEO_DURATION_MUST_BE_LESS_THAT_3_MINUES:
    'La durée de la vidéo doit être inférieure à 3 minutes',
  ERROR_EMAIL_CONNECTION_REDIRECT_LINK_ERROR:
    'Erreur de lien de redirection de connexion par e-mail',
  LABEL_PLEASE_ENTER_THE_EMAIL_YOU_WANT_TO_CONNECT:
    "Veuillez saisir l'adresse e-mail que vous souhaitez connecter",
  WORD_UNKNOWN_ERROR: 'Erreur inconnue',
  TEXT_CUSTOMER_TYPE_CHANGE_TO_NAME: 'Client [type] changé en [name]',
  TEXT_UPDATE_CUSTOMER_TYPE_CHANGE: 'Mettre à jour le client [type] vers [name]',
  WORD_APPROVED_BY: 'Approuvé par',
  MSG_VIDEO_PROCESSING_FAILED_IN_ORDER_TO_RETRY_UPLOADING_IT:
    'Le traitement de la vidéo a échoué pour réessayer de la télécharger',
  WORD_SELECT_LOCATION: "Sélectionnez l'emplacement",
  WORD_SELECT_ORGANIZATION: 'Sélectionnez une organisation',
  WORD_LOADING: 'Chargement ...',
  TEXT_DRAG_VIDEO_OR_UPLOAD:
    'Glissez un fichier multimédia (image ou vidéo) dans la zone suivante ou téléchargez-le',
  WORD_EMPTY: 'Vide',
  WORD_DELIVERED: 'Livré',
  WORD_OPTED_OUT: 'Désinscrit',
  MSG_ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_BROADCAST_CAMPAIGN:
    'Êtes-vous sûr de vouloir supprimer cette campagne de diffusion ?',
  WORD_QUICK_RESPONSE: 'Réponse rapide',
  WORD_JOIN_A_VIDEO_CALL: 'Rejoindre un appel vidéo',
  WORD_EXCEL_FILE: 'Fichier Excel',
  WORD_CSV_FILE: 'Fichier CSV',
  WORD_BROADCAST_MESSAGE: 'Message de diffusion',
  TEXT_YOU_ONLY_ABLE_UPLOAD_VIDEO_ONCE_THE_SEQUENCE_HAS_BEEN_SAVED:
    "Vous ne pourrez télécharger une vidéo ou une image qu'une fois la séquence enregistrée",
  WORD_MONTH: 'Mois',
  WORD_DAY: 'Jour',
  WORD_TRANSACTION: 'Transaction',
  WORD_TOTAL_AMOUNT: 'Montant Total',
  WORD_TOTAL_NET_AMOUNT: 'Montant net total',
  WORD_TOTAL_FEES: 'Total des frais',
  TEXT_WE_ALREADY_DISPLAY_STATUS_PAID_OR_REFUND:
    'Nous affichons déjà le statut payé ou remboursé, donc implémentons le même comportement',
  MSG_ARE_YOU_SURE_WANT_TO_CANCEL_PAYMENT:
    'Êtes-vous sûr de vouloir annuler le paiement ?',
  TEXT_THE_PAYMNET_CANCELED_SUCCESSFULLY: 'Paiement annulé avec succès',
  MSG_PAYMENT_SENT_SUCCESSFULLY: 'Paiement envoyé avec succès',
  WORD_TITLE: 'Titre',
  MSG_ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_STORIES_TYPE:
    'Êtes-vous sûr de vouloir supprimer cette story?',
  WORD_ADD_NEW_STORY: 'Ajouter une nouvelle story',
  WORD_EDIT_STORY: 'Modifier la story',
  LABEL_STORY_TITLE: 'Le titre de la story',
  MSG_STORY_WAS_DELETED_SUCCESSFULLY: 'La story a été supprimée avec succès',
  MSG_STORY_WAS_EDITED_SUCCESSFULLY:
    'Le statut de la story a été modifié avec succès',
  WORD_THUMBNAIL: 'Miniature',
  TEXT_ENTER_STORY_TITLE: 'Saisir le titre de la story',
  TEXT_FOR_OPTIMAL_RESULTS_ENSURE_MEDIA_IS_IN_PORTRAIT_FORMAT:
    'Pour des résultats optimaux, assurez-vous que le média est au format portrait',
  WORD_IMAGE: 'Image',
  WORD_VIDEO: 'Vidéo',
  ERROR_PLEASE_INPUT_STORY_LANG:
    "Veuillez saisir le titre de l'histoire en [language]",
  ERROR_THUMBNAIL_URL_IS_REQUIRED: "L'URL de la vignette est requise",
  ERROR_YOU_NEED_TO_PUT_AT_LEAST_IMAGE_OR_VIDEO_URL:
    "Vous devez mettre au moins une URL d'image ou de vidéo",
  ERROR_YOU_CANT_UPLOAD_BOTH_VIDEO_AND_IMAGE_REMOVE_ONE_THEM:
    "Vous ne pouvez pas télécharger à la fois une vidéo et une image, supprimez l'une d'entre elles",
  ERROR_SORRY_YOUR_BROWSER_DOESNT_SUPPORT_EMBEDDED_VIDEOS:
    'Désolé, votre navigateur ne prend pas en charge les vidéos intégrées.',
  MSG_STORY_ITEM_SAVED_SUCCESSFULLY:
    "L'élément de la story a été enregistré avec succès",
  TEXT_YOU_CAN_CREATE_ONLY_4_STORIES: 'Vous ne pouvez créer que 4 stories',
  MSG_QUESTION_SAVED_SUCCESSFULLY: 'Question enregistrée avec succès',
  WORD_CTA_ADD_HTML_CODE:
    "Ajoutez du code HTML à l'endroit où vous voulez que le widget soit affiché",
  WORD_CTA_ADD_SCRIPT: 'Ajouter un lien de script au site Web',
  WORD_CTA_ADD_STYLE: 'Ajouter un lien de style au site Web',
  WORD_CTA_INITIAL_MESSAGE: 'Message initial',
  TEXT_OPEN_EDITOR: "Ouvrir l'éditeur",
  TEXT_OPEN_EDITOR_LIGHT: 'Ouvrir Éditeur Léger',
  WORD_AVATAR: 'Avatar',
  WORD_CONSENT_ASKED: 'Consentement demandé',
  INTEGRATED_CTA: 'CTA intégré',
  CTA_SAVED_SUCCESSFULLY: 'Intégration CTA enregistrée avec succès.',
  WORD_OPTED_IN: 'Souscrit',
  WORD_SELECT_ALL: 'Tout sélectionner',
  WORD_ARCHIVE_ALL: 'Archiver tout',
  WORD_MARK_ALL_READ: 'Tout marquer comme lu',
  WORD_INTEGRATIONS: 'Intégrations',
  TEXT_CRM_SYCHRONIZATION_FLOW: 'Flux de synchronisation CRM',
  CONVERSATION_ARCHIVED_NOTE:
    "Vous ne pouvez pas écrire de message dans le dossier archivé. Veuillez d'abord désarchiver la conversation.",
  WORD_PLEASE_CONTACT_CLIENTS_SUCCESS_TO_ACTIVATE_AUTOMATIONS:
    "Vous n'avez pas encore configuré d'automatisations. Contactez votre agent de réussite client pour commencer à automatiser votre entreprise ! Nous voulons vous faire gagner du temps et de l'argent !",
  WORD_PLEASE_CONTACT_CLIENTS_SUCCESS_TO_ACTIVATE_FINGERPRINTING:
    "Veuillez contacter l'équipe de succès client pour activer Fingerprinting",
  WORD_MOBILE_CTA: "Appel à l'action mobile",
  WORD_CTA: "Appel à l'action",
  WORD_POST_PURCHASE: "Après l'achat",
  WORD_DAY_S: 'Jour(s)',
  WORD_INTEREST: 'Intérêt',
  WORD_CONTACT_MADE: 'Contact établi',
  WORD_SALE: 'Solde',
  WORD_SERVICE: 'Service',
  WORD_SALES: 'Ventes',
  WORD_SALES_AND_SERVICE: 'Ventes et Service',
  WORD_RECALL_AGENT: 'Agent de rappel',
  WORD_MISSED_APPOINTMENT_AGENT: 'Agent de rendez-vous manqué',
  WORD_APPOINTMENT_CONFIRMATION_AGENT: 'Agent de confirmation de rendez-vous',
  WORD_UNSOLD_VISIT_AGENT: 'Agent de visite non vendu',
  WORD_NEW_LEAD_AGENT: 'Agent de nouvel agent principal',
  WORD_INQUIRY: 'Demande de renseignements',
  LABEL_SHOW_WIDGET_MOBILE: 'Afficher le widget sur mobile',
  WORD_INDUSTRY_AVERAGE_ENGAGEMENT: "Engagement moyen de l'industrie",
  TEXT_THIS_PHONE_NUMBER_IS_LANDLINE_AND_CURRENTLY_NOT_ACCEPTING_SMS_MESSAGES:
    "Le numéro de destination ne peut pas recevoir ce message. Les raisons possibles peuvent inclure la tentative d'atteindre une ligne fixe.",
  WORD_COMING_SOON: 'Bientôt disponible',
  WORD_RESET_PASSWORD: 'Réinitialiser le mot de passe',
  WORD_FORGOT_PASSWORD: 'Mot de passe oublié',
  TEXT_ENTER_YOUR_EMAIL_OR_PHONE_BELOW_AND_WE_WILL_SEND_YOU_PASSWORD_RESET_INSTRUCTIONS:
    'Saisissez votre adresse e-mail ou votre numéro de téléphone ci-dessous et nous vous enverrons les instructions de réinitialisation de votre mot de passe',
  WORD_CONFIRMATION: 'Confirmation',
  TEXT_MESSAGE_HAS_BEEN_SENT_PLEASE_CHECK_YOUR_INBOX:
    'Le message a été envoyé. Veuillez vérifier votre boîte de réception',
  WORD_NEW_PASSWORD: 'Nouveau mot de passe',
  WORD_OLD_PASSWORD: 'Ancien mot de passe',
  WORD_CONFIRM_PASSWORD: 'Confirmer le mot de passe',
  TEXT_ARE_YOU_SURE_YOU_WANT_TO_CANCEL_THIS_INVITATION:
    'Êtes-vous sûr de vouloir annuler cette invitation ?',
  WORD_SENT_EMAIL: 'E-mail envoyé',
  WORD_UNIQUE_SENT: 'Envoyé unique (Tous)',
  WORD_RECOMMENDED_YES: 'Recommandé (Oui)',
  WORD_CLICKED_TO_REVIEW: 'Cliqué pour examiner',
  WORD_SUCCESS: 'Réussite',
  WORD_FAILURE: 'Échec',
  TEXT_LOCATION_IS_ACTIVE: "L'emplacement est actif",
  TEXT_LOCATION_DISABLING_WARNING:
    "Désactiver un emplacement modifiera l'emplacement des messages qui lui sont attribués et désactivera tous les responsables et agents de support qui lui sont associés. Êtes-vous sûr de vouloir continuer ?",
  WORD_PURCHASE_DATE: "Date d'achat",
  WORD_LAST_R_O: 'Dernier R.O',
  WORD_NEXT_APPOINTMENT: 'Prochain rendez-vous',
  WORD_ADD_NEW_TEMPLATES: 'Ajouter de nouveaux modèles',
  TEXT_SEQUENCE_TEMPLATE_WAS_SAVED_SUCCESSFULLY:
    'Modèle de séquence enregistré avec succès',
  TEXT_LOCATIONS_SEARCH: 'Rechercher des emplacements...',
  TEXT_VIDEO_SENT: 'Vidéos envoyées',
  TEXT_VIDEO_WATCHED: 'Vidéos regardées',
  TEXT_HOURS_WATCHED: 'Heures regardées',
  WORD_SUMMARY: 'Résumé',
  WORD_SALES_TODAY: "Ventes aujourd'hui",
  WORD_SALES_MONTH_TO_DATE: 'Ventes du mois à ce jour',
  WORD_SALES_LAST_MONTH: 'Ventes du mois dernier',
  WORD_DEPOSITS_YESTERDAY: "Dépôts d'hier",
  WORD_DEPOSITS: 'Dépôts',
  WORD_REPORTS: 'Rapports',
  WORD_CARD: 'Carte',
  WORD_TIMELINE: 'Chronologie',
  WORD_SCHEDULED_DEPOSITS: 'Dépôts planifiés',
  WORD_COMPLETED_DEPOSITS: 'Dépôts complétés',
  WORD_DEPOSIT_DETAILS: 'Détails du dépôt',
  WORD_EXPORT: 'Exporter',
  WORD_DEPOSIT_ON: 'Acompte sur',
  WORD_DEPOSIT_TO: 'Dépôt à',
  WORD_ATB_FINANCIAL: 'ATB Financial',
  WORD_DEPOSIT_SUMMARY: 'Résumé des dépôts',
  WORD_COUNT: 'Compter',
  WORD_GROSS: 'Brut',
  WORD_TOTAL: 'Total',
  WORD_DEPOSIT_BREAKDOWN: 'Répartition du dépôt',
  WORD_DATE_CREATED: 'Date de création',
  WORD_UPDATED_BY: 'Mis à jour par',
  WORD_UPDATED_AT: 'Mis à jour à',
  WORD_CREATED_AT: 'Créé à',
  WORD_DOWNLOAD: 'Télécharger',
  TEXT_INBOUND_CALLS: 'Appels entrants',
  TEXT_YOU_CANT_WRITE_MESSAGE_IN_THE_ARCHIVED_FOLDER:
    "Vous ne pouvez pas écrire un message dans le dossier archivé. Veuillez d'abord désarchiver la conversation.",
  WORD_VIDEO_STATS: 'Statistiques vidéo',
  TEXT_INCOMING_CALLS: 'Appels entrants',
  WORD_SEARCH_ORGANIZATIONS: 'Rechercher des organisations...',
  WORD_CONVERSATION_NOT_FOUND: 'Conversation introuvable !',
  WORD_HIGH_RISK: 'Risque Élevé',
  WORD_PHONE_CALL: 'Appel Téléphonique',
  WORD_VIDEO_CALL: 'Appel vidéo',
  TEXT_DOWNLOAD_USER_XLS: "Télécharger l'utilisateur XLS",
  WORD_EDIT_USER: "Modifier l'utilisateur",
  WORD_MANAGE_USERS: 'Gérer les utilisateurs',
  WORD_LOGINS: 'Identifiants',
  WORD_MAGIC_SMS: 'Magic SMS',
  WORD_MAGIC_EMAIL: 'Magic Courriel',
  NTF_USER_WAS_CREATED_SUCCESSFULLY: 'Utilisateur créé avec succès',
  NTF_USER_WAS_SAVED_SUCCESSFULLY: "L'utilisateur a été enregistré avec succès",
  TEXT_GOOGLE_BUSINESS_MESSAGES: 'Messages Google Business',
  TEXT_CONNECT_GOOGLE_MY_BUSINESS: 'Connecter Google My Business',
  TEXT_DISCONNECT_GOOGLE_MY_BUSINESS: 'Déconnecter Google My Business',
  TEXT_GOOGLE_MY_BUSINESS_LIST: 'Liste Google My Business',
  WORD_SUBMIT: 'Soumettre',
  TEXT_LOCATION_WAS_UPDATED_SUCCESSFULLY_PLEASE_NOTE:
    'Location was updated successfully. \n Please note, GBM settings changes could take a few hours to update',
  TEXT_BUSINESS_DISCONNECTED_SUCCESSFULLY:
    'Google My Business déconnecté avec succès',
  MSG_THIS_ACTION_IS_NOT_SUPPORTED_FOR_GOOGLE_BUSINESS_CONVERSATIONS_JUST_YET:
    "Cette action n'est pas encore prise en charge pour les conversations d'affaires Google",
  ARE_YOU_SURE_YOU_WANT_TO_CANCEL_CONNECT_GOOGLE_MY_BUSINESS:
    'Êtes-vous sûr de vouloir annuler le processus de connexion à Google My Business ?',
  WORD_AND: 'Et',
  WORD_ADD_MORE_CONDITIONS: 'Ajouter plus de conditions',
  WORD_HIDE_MORE_CONDITIONS: 'Masquer plus de conditions',
  POWERED_BY_AI_TEXT_TEMPERATURE:
    "Alimenté par l'AI, notre modèle analyse les interactions avec les clients et les taux de conversion historiques pour attribuer un score allant de très froid à très chaud. Utilisez-le pour prioriser les actions de sensibilisation et conclure des affaires avec les prospects les plus prometteurs.",
  WORD_FRIENDLY_LOCATION_NAME: "Nom d'emplacement convivial",
  LABEL_SHOW_ANSWER_CHAT: 'Afficher cette réponse dans le chat',
  WORD_PUSH_NOTIFICATIONS: 'Notifications push',
  WORD_ADD_NEW_WORKFLOW: 'Ajouter un nouveau workflow',
  WORD_CHOSE_YOUR_DIFFERENT_TRIGGERS: 'Choisissez vos différents déclencheurs',
  WORD_BUILD_YOUR_WORKFLOW: 'Créez votre workflow',
  WORD_BUILD_A_WORKFLOW_BASED_ON_TYPE_OF_RESPONSE_YOU_RECEIVE_FROM_YOUR_BROADCAST_CAMPAIGN:
    'Construisez un workflow en fonction du type de réponse que vous recevez de votre campagne de diffusion',
  WORD_ADD_NAME_FOR_YOUR_WORKFLOW: 'Ajoutez un nom pour votre workflow',
  WORD_WORKFLOW_NAME: 'Nom du workflow',
  WORD_DETECT_INTENT: "Détecter l'intention",
  WORD_WAIT_FOR_REPLY: 'Attendre une réponse',
  WORD_POSITIVE_REPLY: 'Positif',
  WORD_NEGATIVE_REPLY: 'Négatif',
  WORD_ANY_REPLY: 'Toute réponse',
  WORD_NO_REPLY: 'Pas de réponse',
  WORD_ASSIGN_USER: 'Attribuer un utilisateur',
  WORD_ADD_A_TAG: 'Ajouter une balise',
  WORD_CREATE_A_NOTE: 'Créer une note',
  WORD_TIMES_TRIGGERED: 'Fois déclenché',
  TEXT_ARCHIVE_CONVERSATION_YOU_CAN_FIND_ALL_ARCHIVED_EMAILS_HERE:
    'Archive de la conversation dans la plateforme de Matador',
  WORD_YOUR_NOTE: 'Votre note',
  WORD_YOUR_SMS: 'Votre SMS',
  WORD_PEOPLE_ASSIGNED: 'Personnes assignées',
  WORD_SELECT_PEOPLE: 'Sélectionner des personnes',
  WORD_TAGS_SELECTED: 'Balises sélectionnées',
  WORD_SELECT_TAGS: 'Sélectionner les balises',
  WORD_TAG: 'Étiquette',
  WORD_PAYMENT_NOTIFICATION: 'Notification de paiement',
  TEXT_WORKFLOW_WAS_SAVED_SUCCESSFULLY: 'Le workflow a été enregistré avec succès',
  TEXT_WORKFLOW_WAS_DELETED_SUCCESSFULLY: 'Le workflow a été supprimé avec succès',
  TEXT_ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_WORKFLOW:
    'Êtes-vous sûr de vouloir supprimer ce Workflow ?',
  ERROR_MSG_WORKFLOW: 'Veuillez sélectionner au moins une intention',
  TEXT_RECEIVER_EMAIL_SWITCH: "Notifier lorsque l'intégration a été déconnectée",
  WORD_BUILD_NEW_WORKFLOW: 'Créer un nouveau Workflow',
  WORD_CHOOSE_EXISTING_WORKFLOW: 'Choisir un workflow existant',
  TEXT_NAME_OF_WORKFLOW: 'Nom du workflow',
  ERR_MSG_ASSIGN_WORKFLOW: 'Veuillez affecter au moins 1 utilisateur',
  ERR_MSG_TAG_WORKFLOW: 'Veuillez choisir au moins 1 étiquette',
  ERR_MSG_NO_REPLY_INTENT:
    'Veuillez choisir une action pour l\'intention "Aucune réponse"',
  ERR_MSG_INPUT_NUMBER: 'Le nombre minimum doit être 1',
  PH_SEND_TO: 'Envoyer à...',
  ERR_MSG_INPUT_NUMBER_MAX: 'Maximum le nombre doit être 1',
  TEXT_TEMPLATE_WAS_SAVED_SUCCESSFULLY: 'Le modèle a été enregistré avec succès',
  WORD_PUSH_TO_CRM: 'Envoyer au CRM',
  TEXT_CREATE_OPPORTUNITY_IN_CRM: 'Créer une opportunité dans le CRM',
  WORD_BUILD_YOUR_OWN: 'Composez le vôtre',
  WORD_AI_GENERATED: 'IA Généré',
  TEXT_GENERATE_MOST_POWERFUL_CAMPAIGN_WITH_AI:
    "Générez la campagne la plus puissante avec l'AI",
  TEXT_CHOOSE_DYNAMIC_CONTENT_TO_BE_APPLIED_TEXT:
    'Choisissez le contenu dynamique à appliquer au texte :',
  WORD_GENERATE: 'Générer',
  TEXT_PLEASE_GENERATE_AI_CONTENT: `Veuillez remplir le champ du message avec une description d'au moins 10 caractères`,
  WORD_REGENERATE: 'Régénérer',
  TEXT_WRITE_YOUR_IDEA: 'Écrivez votre idée...',
  WORD_SKIP: 'Passer',
  WORD_AI_REPLY: 'Réponse I.A.',
  WORD_AI_REPLYING: 'IA écrit',
  WORD_AI: 'IA',
  TEXT_THERE_IS_NO_GENERATED_MESSAGE: "Il n'y a pas de message généré",
  TEXT_AI_MESSAGE_GENERATION_IS_NOT_AVAILABLE:
    "La génération de messages par AI n'est pas disponible",
  TEXT_EMAIL_IS_NOT_VALID: "L'adresse e-mail n'est pas valide",
  TEXT_CLICK_HERE_TO_UPLOAD_YOUR_CSV_EXCEL_FILE:
    'Cliquez ici pour télécharger votre fichier CSV ou Excel',
  TEXT_EDIT_BROADCAST_MESSAGE: 'Modifier le message de diffusion',
  TEXT_YOU_CAN_EDIT_CAMPAIGN_ONLY_MINUTES_BEFORE_START:
    'Vous pouvez modifier la campagne seulement 5 minutes avant le début',
  WORD_CONVERSATION_AI: 'Conversation A.I.',
  TEXT_UPDATE_ORGANIZATION_CONVERSATION_AI:
    'Mettre à jour la configuration de Superhuman',
  LABEL_MONTHLY_SPECIALS: 'Spéciaux du mois',
  LABEL_PROVIDE_OUR_AI_AN_OVERVIEW_OF_THE_PROMOTIONS_YOUR_ORGANIZATION_IS_RUNNING_THIS_MONTH:
    'Donnez à notre AI un aperçu des promotions que votre organisation propose ce mois-ci',
  LABEL_EXCLUDE_THIS_INTENT_FROM_BEING_AUTOREPLIED:
    "Exclure cette intention d'être répondue automatiquement",
  LABEL_TAG_ONLY_WHEN_USER_INTERVENTION_IS_NEEDED:
    "N'étiquetez que lorsque l'intervention de l'utilisateur est nécessaire",
  LABEL_SELECT_INTENTS: 'Sélectionner les intentions',
  STORY_THUMBNAIL_NOTE:
    "Ce champ est utilisé pour télécharger une petite image qui représente votre story. Il est recommandé d'utiliser une image pertinente à votre story dans les formats jpeg jpg png ou gif. De plus veuillez vous assurer que l'image est optimisée/compressée avant le téléchargement pour garantir des temps de chargement rapides pour les visiteurs du site web.",
  STORY_IMAGE_NOTE:
    "Ce champ est utilisé pour télécharger l'image réelle qui sera affichée lorsque quelqu'un ouvrira votre story. Formats pris en charge : jpeg jpg png ou gif. Veuillez garder à l'esprit qu'il est fortement recommandé que l'image et la vidéo soient en mode portrait pour un affichage optimal. De plus veuillez vous assurer que l'image est optimisée/compressée avant le téléchargement pour garantir des temps de chargement rapides pour les visiteurs du site Web.",
  STORY_VIDEO_NOTE:
    "Ce champ est utilisé pour télécharger une vidéo qui sera affichée lorsque quelqu'un ouvre votre story. Formats pris en charge : mp4 mov avi webm ou quicktime. Veuillez garder à l'esprit que la limite de taille de fichier pour la vidéo est de 20 Mo et que la limite de durée de la vidéo est de 3 minutes. Il est fortement recommandé que la vidéo soit en mode portrait pour un affichage optimal. De plus veuillez vous assurer que la vidéo est optimisée/compressée avant le téléchargement pour garantir des temps de chargement rapides pour les visiteurs du site Web.",
  TEXT_APPOINTMENTS_EMAIL_WAS_SUCCESSFULLY_CONNECTED:
    'Le courriel de rendez-vous a été connecté avec succès',
  TEXT_APPOINTMENTS_EMAIL_WAS_SUCCESSFULLY_DISCONNECTED:
    'Le courriel de prise de rendez-vous a été déconnecté avec succès',
  TEXT_TOUCHPOINTS_EMAIL_WAS_SUCCESSFULLY_CONNECTED:
    "Connexion réussie de l'e-mail Touchpoints",
  TEXT_TOUCHPOINTS_EMAIL_WAS_SUCCESSFULLY_DISCONNECTED:
    "L'e-mail Touchpoints a été déconnecté avec succès",
  TEXT_RECEIVE_EMAILS_FROM_UNHAPPY_CUSTOMERS:
    'Recevoir des e-mails de "clients mécontents"',
  TEXT_RECEIVE_REPORTS_BY_EMAIL: 'Recevoir des e-mails de newsletter',
  TEXT_RECEIVE_REPORTS_DESCRIPTION: `Un post distribué régulièrement qui fournit des statistiques sur des fonctionnalités clés importantes telles que les Rendez-vous Assistés, l'Impact Superhumain, les Soumissions Vidéo, l'Engagement des Widgets et les Statistiques des Meilleures Pistes.<br />
  Surveillez votre email:<br />
  Newsletter hebdomadaire: envoyée le lundi<br />
  Newsletter mensuelle : envoyée les premiers jours du mois suivant`,
  TEXT_EMAILS_FROM_UNHAPPY_CUSTOMERS_CAN_ONLY_BE_RECEIVED_BY_EMAIL:
    'Les e-mails des « clients mécontents » ne peuvent être reçus que par e-mail',
  NTF_SUCCESS_BROADCAST_WAS_CHANGED_SUCCESSFULLY:
    'la diffusion a été modifiée avec succès',
  WORD_CRM: 'CRM',
  NTF_YOU_CANT_EDIT_CANCELED_CAMPAIGN:
    'Vous ne pouvez pas modifier la campagne annulée',
  WORD_VERIFICATION: 'Vérification',
  TEXT_RECEIVE_VERIFICATION_CODE_VIA: 'Recevoir le code de vérification par',
  WORD_VERIFICATION_CODE: 'Code de vérification',
  TEXT_VERIFICATION_CODE_FOR_PHONE:
    'Votre code de vérification est envoyé au numéro de téléphone [phone]',
  TEXT_VERIFICATION_CODE_FOR_EMAIL:
    "Votre code de vérification est envoyé à l'adresse e-mail [email]",
  WORD_RESEND_CODE: 'Renvoyer le code ?',
  TEXT_DURING_INTEGRATION_SETUP_NO_FB_PAGES_WERE_SELECTED:
    "Pendant la configuration de l'intégration, aucune page FB n'a été sélectionnée",
  TEXT_SELECT_FACEBOOK_PAGE:
    "Sélectionnez la page Facebook pour terminer la configuration de l'intégration",
  TEXT_SELECT_LEADS: 'Sélectionner des pistes (facultatif)',
  UPDATE_CUSTOMERS_DATA_BROADCAST_PAGE:
    'Mettre à jour les données des clients (prénom, nom, email) dans Matador à partir des données du fichier téléchargé.',
  WORD_LEAD_GEN_WIZARD: 'Assistant: Génération de pistes',
  WORD_BEFORE_APPOINTMENT: 'avant le rendez-vous',
  TEXT_DOWNLOAD_DAILY_TOTAL_TRANSACTION_REPORT:
    'Télécharger le rapport quotidien des transactions totales',
  WORD_CUSTOM: 'Personnalisé',
  WORD_BUILD_YOUR_OWN_BROADCAST: 'BYOB = Créez votre propre diffusion. 😍',
  WORD_INSTANTLY_CREATE_YOUR_CAMPAIGN_WITH_AI:
    'Créez instantanément votre campagne avec lA. 🤯',
  WORD_THIS_TAG_IS_AUTOMATICALLY_SELECTED_ITS_BEST_PRACTICE_IDENTIFY_YOURSELF:
    'Choisissez le contenu dynamique à appliquer au texte :',
  WORD_REFRESH: 'Rafraîchir',
  WORD_REVIEW_RATING_PAGE_TITLE: "Invitation à noter la page d'évaluation",
  WORD_REVIEW_POSITIVE_FEEDBACK_PAGE_TITLE:
    "Avis d'invitation Page de rétroaction positive",
  WORD_REVIEW_POSITIVE_NEGATIVE_PAGE_TITLE:
    "Page d'invitation d'avis de rétroaction négative",
  ACTION_OPEN_IN_CRM: 'Ouvrir dans le CRM',
  WORD_CAN_DISABLE_SUPER_ADMINS: 'Désactivé',
  TEXT_WITH_CALENDAR_INVITE: 'avec invitation au calendrier',
  TEXT_MESSAGES_SENT_VIA_SUPER_HUMAN: 'Messages envoyés via Superhuman',
  TEXT_MATADOR_ASSISTED_APPOINTMENTS: 'Total de rendez-vous assistés par Matador',
  TEXT_MATADOR_IMPACT: 'Impact Matador',
  TEXT_HOW_MATADOR_IS_IMPACT_IN_YOUR_BUSINESS:
    "Automatisation et R.O.I de l'A.I pour votre entreprise",
  TEXT_PLEASE_CONTACT_CLEINTS_SUCCESS_TO_ACTIVATE_SUPERHUMAN:
    "Veuillez contacter l'équipe de support client pour activer Superhuman",
  TEXT_INVALID_TEMPORARY_VERIFICATION_TOKEN_PROVIDED:
    'Jeton de vérification temporaire fourni non valide',
  SUBSCRIBER_GROWTH: 'Croissance des abonnés',
  WORD_PRIMARY_COLOR: 'Couleur principale',
  WORD_VARIANT: 'Variante',
  SUBSCRIBER_GROWTH_BUTTON: 'Mise à jour réussie de la Croissance des abonnés.',
  WORD_CREATED: 'Créé',
  WORD_UPDATED: 'Mis à jour',
  HOW_WOULD_YOU_LIKE_TO_SEND_THIS_MESSAGE:
    'Comment souhaitez-vous envoyer ce message ?',
  ACTION_DISCONNECTED_BY: 'Déconnecté par :',
  TEXT_ALLOW_SUPERHUMAN_AI_TO_RESPOND_WITHIN_ORGANIZATIONS_WORKING_HOURS:
    "Autoriser l'A.I. surhumaine à répondre pendant les heures de travail de l'organisation",
  TEXT_ALLOW_SUPERHUMAN_AI_TO_RESPOND_OUTSIDE_ORGANIZATIONS_WORKING_HOURS:
    "Autoriser l'A.I. Superhuman à répondre en dehors des heures de travail de l'organisation",
  TEXT_SUPERHUMAN_ASSISTED_APPOINTMENTS: 'Rendez-vous assistés',
  WORD_JOIN_A_CALL: 'Rejoindre un appel',
  TEXT_UPDATE_ORGANIZATION_OPENING_HOURS:
    "Mettre à jour les heures d'ouverture de l'organisation",
  TEXT_DO_NOT_SEND_REMINDER_IF_APPOINTMENT_IS_CREATED_ON_SAME_DAY:
    'Ne pas envoyer de rappel si le rendez-vous est créé le jour même',
  TEXT_SUPERCHARGE_YOUR_WORKFLOW_AND_CONNECT_TOOLS_YOU_AND_YOUR_TEAM_USES_EVERY_DAY:
    'Boostez votre workflow et connectez les outils que vous et votre équipe utilisez tous les jours.',
  WORD_CONNECT_TO_CRM: 'Connectez-vous au CRM',
  TEXT_CONNECT_TO_SERVICE_SCHEDULER: 'Se connecter au planificateur de service',
  WORD_CONNECTED_EMAIL: 'E-mail connecté',
  WORD_CONNECTED_CRM: 'CRM connecté',
  TEXT_TRIGGERS_WHEN_YOU_RECEIVE_NEW_LEAD:
    'Déclenche lorsque vous recevez un nouveau lead.',
  TEXT_INTEGRATES_YOUR_DATA_ENHANCING_YOUR_ABILITY_MANAGE_CUSTOMER_INTERACTIONS_RELATIONSHIPS_EFFECTIVELY:
    'Intègre vos données et améliore votre capacité à gérer efficacement les interactions et les relations avec les clients.',
  TEXT_INTEGRATES_YOUR_SCHEDULER_AND_ENHANCES_YOUR_ABILITY_TO_SCHEDULE_SERVICE_APPOINTMENTS:
    'Intègre votre planificateur et améliore votre capacité à planifier des rendez-vous de service',
  TEXT_GIVES_POSSIBILITY_HAVE_CONVERSATIONS_WITH_CUSTOMERS_BY_EMAIL:
    "Donne la possibilité d'avoir des conversations avec les clients par EMAIL.",
  TEXT_MERGES_BOTH_PLATFORMS_INTO_UNIFIED_ONLINE_PRESENCE:
    'Fusionne les deux plateformes en une présence en ligne unifiée.',
  TEXT_STREAMLINES_MESSAGE_MANAGEMENT_ACROSS_PLATFORMS:
    'Rationalise la gestion des messages sur toutes les plateformes.',
  WORD_PRESS_SENT_BUTTON: 'Utilisez le bouton Envoyer pour envoyer',
  WORD_PRESS_ENTER: 'Utilisez le bouton Entrée pour envoyer',
  DISPLAY_BACKGROUND: "Afficher l'arrière-plan",
  TEXT_MINUTES_WATCHED: 'Minutes regardées',
  MOBILE_DIMENSIONS: 'Dimensions mobiles',
  WORD_FLOW_DESIGNER: 'Connexion personnalisée',
  TEXT_AUTOMATICALLY_USED_BY_AI: 'Automatiquement utilisé par IA',
  TEXT_PLEASE_CONTACT_YOUR_ADMINISTRATOR: 'Veuillez contacter votre administrateur.',
  TEXT_DO_NOT_DISTURB_ME_DURING_NON_WORKING_HOURS:
    'Ne me dérange pas en dehors des heures de travail',
  TEXT_PLEASE_FINISH_INTEGRATION_SETUP:
    "Veuillez terminer la configuration d'intégration",
  TEXT_THIS_PHONE_NUMBER_IS_CURRENTLY_NOT_ACCEPTING_SMS_MESSAGES:
    'Ce numéro est temporairement indisponible. Veuillez réessayer plus tard',
  WORD_COMPLIANCE: 'Conformité',
  WORD_IG_MESSENGER: 'Messenger IG',
  WORD_FB_MESSENGER: 'Messenger FB',
  WORD_GOOGLE_BUSINESS_UC: 'Google Business',
  TEXT_WE_ARE_UNSURE_IF_THIS_CUSTOMER_IS_COMPLIANT:
    'Nous ne sommes pas certains que ce client soit conforme',
  TEXT_THE_CUSTOMER_UNSUBSCRIBED_FROM_EMAIL_CONVERSATION:
    "Le client s'est désabonné de la conversation par e-mail",
  WORD_REFUSED_AT: 'Refusé à',
  TEXT_FORWARD_ALL_LEADS_TO_EMAIL:
    'Partagez une copie des prospects avec un autre service',
  AI_BOT_SUPERHUMAN: 'Superhuman',
  TEXT_AI_BOT_SUPERHUMAN: 'Superhuman IA',
  TEXT_THE_CLIENT_HAS_NOT_GIVEN_EMAIL_CONSENT:
    "Ce client s'est désabonné des e-mails. Veuillez essayer un autre canal de communication",
  WORD_CONNECTED_PAGE_FB: 'Page Facebook connectée',
  WORD_CONNECTED_PAGE_IG: 'Page Instagram connectée',
  TEXT_TEXT_TEMPLATE_IS_REQUIRED_FOR_BOTH_LANGUAGES:
    'Modèle de texte requis pour les deux langues',
  WORD_WEEK_TO_DAY: 'Semaine À Jour',
  WORD_WEEK: 'Semaine',
  WORD_SELECT_DATE_PERIOD: 'Sélectionner la période',
  MSG_SKIP_SCHEDULED_MESSAGE:
    'Êtes-vous sûr de vouloir passer le message programmé?',
  TEXT_RESTRICT_THE_CREATION_OF_A_CALENDAR_INVITE_FOR_AFTER_WORKING_HOURS:
    'Limiter la planification des invitations du calendrier en dehors des heures de travail',
  WORD_ARE_YOU_SURE_YOU_WANT_TO_DO_ACTION_CONVERSATION:
    'Êtes-vous sûr de vouloir [action]',
  WORD_ARE_YOU_SURE_YOU_WANT_TO_MARK_US_READ_CONVERSATION:
    'Êtes-vous sûr de vouloir marquer comme lu',
  WORD_SETTINGS_TRANSFER: 'Transfert de paramètres',
  WORD_PLEASE_SELECT_DIFFERENT_ORGANIZATIONS:
    "Veuillez sélectionner d'autres organisations.",
  WORD_SETTINGS_TRANSFERED_SUCCESSFULLY: 'Paramètres transférés avec succès',
  CONFIRMATION_COPY_ORG_SETTINGS_TITLE:
    'Êtes-vous sûr de vouloir copier [settingName] de [fromOrg]',
  CONFIRMATION_COPY_ORG_SETTINGS_DESCRIPTION:
    'Tous les paramètres existants de [settingName] dans [toOrg] seront effacés et mis à jour.',
  CONFIRMATION_COPY_ORG_TEMPLATES_DESCRIPTION:
    'Tous les modèles sélectionnés seront copiés dans [toOrg] et ne supprimeront pas les séquences existantes.',
  WORD_ORGANIZATION_RESTRICTION_SETTINGS:
    "Paramètres de restriction de l'organisation",
  WORD_SELECT_SOURCE_ORGANIZATION_COPY_FROM:
    "Sélectionnez l'organisation source à partir de laquelle vous souhaitez copier",
  WORD_SELECT_DESTINATION_ORGANIZATION_COPY_TO:
    "Sélectionnez l'organisation de destination vers laquelle vous souhaitez copier",
  WORD_SELECT_SETTINGS_TO_COPY:
    'Sélectionnez les paramètres que vous souhaitez copier',
  WORD_SELECT_SEQUENCE_TO_COPY:
    'Sélectionnez les séquences que vous souhaitez copier',
  WORD_SELECT_SETTING: 'Sélectionnez le paramètre',
  WORD_PLEASE_SELECT_SETTING: 'Veuillez sélectionner un paramètre',
  WORD_CONVERSATIONS_QUESTION: 'conversation',
  WORD_DOWNLOAD_IN_APPSTORE: "Télécharger dans l'App Store",
  WORD_DOWNLOAD_IN_GOOGLE_CHROME: "Télécharger l'extension pour le navigateur",
  WORD_DOWNLOAD_IN_GOOGLE_PLAY: 'Télécharger dans Google Play',
  WORD_THE_ONLY_MARKETING_AUTOMATION_PLATFORM:
    "La seule plateforme d'automatisation du marketing conversationnel pour les concessionnaires",
  WORD_AI_POWERED_CONVERSATIONAL_MARKETING_AUTOMATION:
    "Révolutionnez la stratégie de vente, l'expérience client et l'efficacité de votre concession avec l'automatisation du marketing conversationnel propulsée par l'AI.",
  WORD_PLEASE_ENTER_YOUR_PHONE_OR_EMAIL:
    'Veuillez saisir votre téléphone ou votre e-mail',
  WORD_PLEASE_ENTER_YOUR_PASSWORD: 'Veuillez saisir votre mot de passe',
  WORD_PLEASE_ENTER_YOUR_EMAIL: 'Veuillez saisir votre adresse e-mail',
  WORD_SUPERHUMAN_MESSAGES: 'Messages superhuman',
  TEXT_RESTRICT_TO_DISABLE_MANUALLY_DRIP_SEQUENCE_TOGGLES_FOR_MANAGERS:
    "Restreindre la désactivation de la séquence d'e-mails automatisés au niveau de la conversation pour les gestionnaires",
  TEXT_RESTRICT_TO_DISABLE_MANUALLY_DRIP_SEQUENCE_TOGGLES_FOR_SUPPORT_AGENTS:
    "Restreindre la désactivation de la séquence d'e-mails automatisés au niveau de la conversation pour les agents de support",
  TEXT_ALLOW_PARSE_LEADS_WITHOUT_PHONE:
    "Autoriser l'analyse des leads sans numéro de téléphone",
  WORD_CREATE_REPORT: 'Créer un rapport',
  WORD_REPORT_NAME: 'Nom du rapport',
  TEXT_TOUCHPOINTS_SEARCH: 'Rechercher des points de contact...',
  TEXT_LAST_LOGIN_DATE: 'Dernière connexion',
  WORD_PERSONAL_INFORMATION: 'Informations personnelles',
  WORD_ROLE_ACCESS: 'Rôle/Accès',
  WORD_TYPE_A_MESSAGE_TO_SEND_TO_CUSTOMER:
    'Saisissez un message à envoyer au client',
  MSG_PLEASE_INPUT_PHONE_OR_EMAIL:
    'Veuillez saisir un numéro de téléphone ou une adresse e-mail',
  MSG_TURNSTILE_VERIFICATION_REQUIRED: 'Veuillez vérifier le captcha',
  TEXT_CONNECT_GMAIL_OR_OFFICE_365: 'Connectez Gmail ou Office 365',
  TEXT_LINK_YOUR_WHATSAPP_BUSINESS_ACCOUNT:
    'Associez votre compte WhatsApp Business',
  WORD_REMINDERS: 'Rappels',
  MSG_ENTERED_PHONE_EMAIL_IS_NOT_VALID: "Le téléphone/e-mail saisi n'est pas valide",
  MSG_BROADCAST_MESSAGE_LENGTH_MUST_BE_LESS_THAN_OR_EQUAL_TO_CHARACTERS:
    'la longueur du message de diffusion doit être inférieure ou égale à 1400 caractères',
  TEXT_ORGANIZATION_OPENING_HOURS: "Heures d'ouverture de l'organisation",
  TEXT_CUSTOM_OPENING_HOURS: "Horaires d'ouverture personnalisés",
  MSG_DELETE_CUSTOM_OPENING_HOURS:
    "Êtes-vous sûr de vouloir supprimer les heures d'ouverture personnalisées ?",
  TEXT_CALLS_FORWARDED_TO: 'Transférer les appels vers',
  MSG_OPENING_HOURS_ERROR: 'Veuillez suivre le format H:MM AM|PM (9:30 AM)',
  WORD_IP_WHITELIST: "Liste blanche d'IP",
  TEXT_ONLY_SPECIFIC_IP_ADDRESSES_GET_ACCESS_WORKING_THIS_ORGANIZATION_MATADOR:
    'Seuls les appareils utilisant ces adresses IP autorisées pourront accéder à Matador',
  TEXT_PLEASE_INSERT_WHITELIST_IP_ADDRESS:
    "Veuillez insérer l'adresse IP de la liste blanche",
  MSG_DELETE_IP_WHITE_LIST:
    "Êtes-vous sûr de vouloir supprimer l'élément de liste blanche d'IP ?",
  MSG_PLEASE_INSERT_WHITELIST_IP_ADDRESS:
    "Veuillez insérer l'adresse IP de la liste blanche",
  MSG_THE_NUMBER_OF_IP_WHITELIST_CANNOT_BE_MORE_THAN:
    "Le nombre de liste blanche d'IP ne peut pas dépasser 10",
  WORD_LOAD_MORE: 'Charger plus',
  TEXT_TAGGED_IN_A_CONVERSATION: 'Identifié dans une conversation',
  WORD_ADD_NEW_IP: 'Ajouter une nouvelle IP',
  WORD_CURRENT_LOCATION: 'Emplacement actuel',
  WORD_FILTERS: 'Filtres',
  WORD_IN_PROGRESS: 'En cours',
  WORD_EXECUTION_DATE_AND_TIME: "Date et heure d'exécution",
  WORD_WORKFLOW_REPLY: 'Réponse de flux de travail',
  WORD_WORKFLOW_RESPONSE: 'Réponse du workflow',
  WORD_WORKFLOW_AUTOMATION: "Workflow d'automatisation",
  WORD_UPLOADED_FILE: 'Fichier téléchargé',
  WORD_SHOW_DETAILS: 'Afficher les détails',
  WORD_HIDE_DETAILS: 'Masquer les détails',
  TEXT_USE_QUESTION_AS_TOUCHPOINT: 'Utiliser la question comme point de contact',
  TEXT_FILTER_BY_STATUS: 'Filtrer par statut',
  WORD_ALL_CAMPAIGNS: 'Toutes les campagnes',
  WORD_CREATED_BY_AUTOMATION: 'Créé par Automation',
  WORD_CREATED_BY_USER: "Créé par l'utilisateur",
  TITLE_BROADCASTING_LIST: 'Liste de diffusion',
  TEXT_DISCONNECTED_DUE_TO_TOKEN_EXPIRATION_OR_PASSWORD_CHANGE:
    "Déconnecté en raison de l'expiration du jeton ou du changement de mot de passe",
  TEXT_YOU_DO_NOT_HAVE_ACCESS_TO_THIS_FEATURE_BROADCAST:
    "Vous n'avez pas accès à cette fonctionnalité. Veuillez contacter votre responsable",
  TEXT_CAMPAIGN_WAS_CANCELED_SUCCESSFULLY: 'La campagne a été annulée avec succès',
  WORD_AFTER_HOURS: `Après les heures d'ouvertures`,
  WORD_AGENT_NAME: "Nom de l'agent",
  ERRORS_INPUT_CONVERSATIONAI: '[name] ne peut pas être vide',
  TEXT_IF_THE_INVENTORY_INTEGRATIONS_IS_ENABLED:
    "Si l'intégration de l'inventaire est activée ?",
  WORD_AGENT_ROLE: "Rôle de l'agent",
  AGENT_ROLE_CANT_BE_EMPTY: `Rôle de l'agent ne peut pas être vide`,
  WORD_COMPANY_TYPE: "Type d'entreprise",
  TEXT_GENERAL_TAGGING_CONFIGURATION: "Configuration Générale d'Étiquetage",
  TEXT_ALLOW_TAGGING_USERS_FOR_EVERY_SUPERHUMAN_MESSAGE:
    'Autoriser le marquage des utilisateurs pour chaque message Superhuman',
  TEXT_INTENT_CONFIGURATION: "Configuration d'intention",
  TEXT_PROVIDE_INSTRUCTIONS_CLARIFY_HOW_YOU_WANT_RESPONSES_INTENT_GENERATED:
    'Fournissez des instructions pour clarifier la façon dont vous souhaitez que les réponses pour cette intention soient générées',
  WORD_GENERAL_INSTRUCTIONS: 'Instructions générales',
  WORD_HUMAN: 'Humain',
  WORD_TEXT: 'Texte',
  TEXT_WHEN_TAG_USERS: 'Quand taguer les utilisateurs',
  TEXT_PLEASE_CHOOSE_AT_LEAST_ONE_USER: 'Veuillez choisir au moins un utilisateur',
  MSG_REMOVE_INTENT_ITEM: 'Êtes-vous sûr de vouloir supprimer cet élément ?',
  TEXT_ADD_AT_LEAST_ONE_USER_WHO_WILL_TAGGING:
    "Ajoutez au moins un utilisateur qui s'occupera du balisage",
  TEXT_USERS_TO_TAG_FOR_UNASSIGNED_CONVERSATIONS:
    'Utilisateurs à étiqueter pour les conversations non attribuées',
  TEXT_USERS_TO_TAG_FOR_CHAT_SESSIONS:
    'Utilisateurs à taguer pour les sessions de chat',
  TEXT_USERS_TAG_WHEN_APPOINTMENTS_ARE_CREATED_EDITED_CANCELLED:
    'Utilisateurs à marquer lors de la création de rendez-vous',
  TEXT_TAG_ONLY_ASSIGNED_USERS: 'Marquer uniquement les utilisateurs assignés',
  TEXT_TAG_BOTH: 'Étiqueter les deux',
  TEXT_TAG_ONLY_USERS_FROM_LIST:
    'Identifier uniquement les utilisateurs de la liste',
  TEXT_UPDATE_ORGANIZATION_SUPERHUMAN:
    "Mise à jour de l'organisation Superhuman [case] réussie",
  WORD_FILTERED_BY: 'Filtré par',
  WORD_BROADCAST_NAME: 'Nom de diffusion',
  TEXT_RESTRICT_USER_VOICE_CALLS_FROM_USING_ORGANIZATION_NUMBERS:
    "Empêcher les appels vocaux des utilisateurs d'utiliser les numéros de l'organisation",
  LABEL_SELECT_THE_USERS_TO_TAG: 'Sélectionnez les utilisateurs à étiqueter',
  LABEL_CAR_DEALERSHIP: 'concessionnaire automobile',
  LABEL_SOLAR_PANEL_COMPANY: 'société de panneaux solaires',
  ERR_MSG_PLEASE_PROVIDE_DELAY: 'Veuillez fournir un délai',
  TEXT_RESTRICT_TO_DISABLE_SUPERHUMAN_TOGGLES_FOR_MANAGERS:
    "Restreindre la possibilité pour les responsables d'activer ou de désactiver le mode Superhuman au niveau de la conversation",
  TEXT_RESTRICT_TO_DISABLE_SUPERHUMAN_TOGGLES_FOR_SUPPORT_AGENTS:
    'Restreindre la modification de la bascule Superhuman au niveau de la conversation pour les agents de support',
  TEXT_TYPE_YOUR_INSTRUCTIONS: 'Saisissez vos instructions',
  TEXT_TYPE_YOUR_CURRENT_PROMOTIONS: 'Saisissez vos promotions actuelles',
  TITLE_DRIP_SEQUENCE: 'Séquences',
  WORD_DRIP_SEQUENCE_SMS: 'Séquence SMS',
  WORD_DRIP_SEQUENCE_EMAIL: 'Séquence Email',
  WORD_DRIP_SEQUENCE_TEMPLATES: 'Modèles de séquence couriel automatisés',
  TEXT_UPDATE_ORGANIZATION_DRIP_SEQUENCE:
    "Mettre à jour les séquences de l'organisation",
  WORD_OFFER_AMOUNT: "Montant de l'offre",
  TEXT_YOU_CANT_DISABLE_THIS_NOTIFICATION:
    'Vous ne pouvez pas désactiver cette notification',
  TEXT_SUPER_HUMAN_ASSISTED_APPOINTMENTS: 'Rendez-vous assistés par un super-humain',
  TEXT_SUPER_HUMAN_REPLIES: 'Réponses surhumaines',
  TEXT_ASSISTED_APPOINTMENTS: 'Rendez-vous assistés',
  TEXT_COMMUNICATION_CHANNELS: 'Canaux de communication',
  TEXT_ARCHIVE_MULTIPLE_CONVERSATIONS: 'Archiver plusieurs conversations',
  TEXT_MARK_AS_READ_MULTIPLE_CONVERSATIONS:
    'Marquer comme lu plusieurs conversations',
  SELECT_CONVERSATION_TO_MARK_AS_READ:
    'Sélectionnez la ou les conversations à marquer comme lues',
  TEXT_UNARCHIVE_MULTIPLE_CONVERSATIONS:
    'Sélectionnez la ou les conversations à désarchiver',
  TEXT_SELECT_AN_ACTION_BEFORE_CHOOSING_CONVERSATIONS:
    'Sélectionnez une action avant de choisir les conversations',
  NTF_MSG_MULTIPLE_UNARCHIVED: 'Les conversations ont été désarchivées avec succès',
  SELECT_CONVERSATION_ARCHIVE: 'Sélectionnez la ou les conversations à archiver',
  NTF_MSG_MULTIPLE_ARCHIVED: 'Les conversations ont été archivées avec succès',
  filter_by_location: 'Filtrer par lieu',
  TEXT_SELECT_ROLES_THAT_WILL_HAVE_OPTION_SKIP_NEXT_SCHEDULED_MESSAGE:
    'Sélectionnez le(s) rôle(s) utilisateur pouvant ignorer le prochain message programmé :',
  WORD_WELCOME_TITLE: 'Titre de bienvenue',
  WORD_FIRST_MESSAGE: 'Premier message',
  WORD_SECOND_MESSAGE: 'Deuxième message',
  WORD_CLEAR_ALL: 'Tout effacer',
  WORD_SELECT_ITEM: 'Sélectionner un élément',
  WORD_PLEASE_SELECT_ITEM: 'Veuillez sélectionner un article',
  WORD_ORGANIZATION: 'Organisation',
  TEXT_SEND_CALENDAR_INVITATION_ASSIGNED_USERS:
    'Envoyer une invitation du calendrier aux utilisateurs assignés',
  TEXT_AUTO_REPLY_AFTER: 'Réponse automatique après',
  TEXT_MINUTES_OF_MESSAGE_BEING_UNREAD: "minutes d'un message non lu",
  WORD_SUPERHUMAN_TAGGED: 'Superhuman étiqueté',
  WORD_YOU: 'Vous',
  WORD_CLEAR_CONVERSATION: 'Effacer la conversation',
  TEXT_GIVE_YOUR_SUPERHUMAN_CONFIGURATION_TRY:
    'Essayez votre configuration Superhuman',
  TEXT_SUPERHUMAN_IS_THINKING: 'Superhuman est en train de réfléchir',
  TEXT_TYPE_MESSAGE_TO_SMS_TO_USER:
    "Tapez un message à envoyer par SMS à l'utilisateur",
  WORD_LATER_TODAY: "Plus tard aujourd'hui",
  WORD_TOMORROW: 'Demain',
  WORD_NEXT_WEEK: 'La semaine prochaine',
  WORD_PICK_TIME_AND_DAY: "Choisir l'heure et le jour",
  WORD_SUNDAY: 'Dimanche',
  WORD_MONDAY: 'Lundi',
  WORD_TUESDAY: 'Mardi',
  WORD_WEDNESDAY: 'Mercredi',
  WORD_THURSDAY: 'Jeudi',
  WORD_FRIDAY: 'Vendredi',
  WORD_SATURDAY: 'Samedi',
  TEXT_RECEIVE_NOTIFICATIONS_FROM_ALL_ORGANIZATIONS:
    'Recevoir des notifications de toutes les organisations',
  TEXT_WHAT_LOCATIONS_DO_YOU_WANT_RECEIVE_NOTIFICATIONS_FROM:
    'Quels sont les lieux pour lesquels vous souhaitez recevoir des notifications ?',
  TEXT_PLEASE_ENTER_THE_PERIOD_IN_MINUTES:
    'Veuillez saisir la période (en minutes) après laquelle vous souhaitez recevoir des notifications pour les messages non lus',
  TEXT_PLEASE_ENTER_THE_PERIOD_IN_MINUTES_BEING_UNASSIGNED_FROM_CONVERSATION:
    'Veuillez saisir la période (en minutes) après laquelle vous souhaitez recevoir des notifications de désaffectation de la conversation',
  THE_TWO_PASSWORDS_THAT_YOU_ENTERED_DO_NOT_MATCH:
    'Les deux mots de passe que vous avez saisis ne correspondent pas !',
  TEXT_AT_LEAST_ONE_LOCATION_MUST_BE_SELECTED:
    'Au moins un emplacement doit être sélectionné',
  WORD_SELECT_TYPE: 'Sélectionnez le type',
  TEXT_WHAT_ORGANIZATIONS_DO_YOU_WANT_RECEIVE_NOTIFICATIONS_FROM:
    'Quelles organisations souhaitez-vous recevoir des notifications de ?',
  TEXT_AT_LEAST_ONE_ORGANIZATION_MUST_BE_SELECTED:
    'Au moins une organisation doit être sélectionnée',
  TEXT_PROFILE_CHANGE_PASSWORD: 'Changer le mot de passe du profil',
  TEXT_WHICH_LOCATION_WOULD_YOU_LIKE_CHOOSE_DISPLAYING_QUESTION:
    'Quel emplacement souhaitez-vous choisir pour afficher les questions ?',
  TEXT_CHOOSE_SEVERAL_LOCATIONS_AND_AFTERWARD:
    'Choisissez plusieurs emplacements, et ensuite, vous avez la possibilité de les réorganiser.',
  WORD_ANY: "N'importe quel",
  TEXT_BROADCAST_SEGMENT_TAGS_DETAILS: `"ET" est similaire à dire "Je veux des clients qui ont les deux de ces étiquettes". Par exemple, "Je veux voir les clients qui ont l'étiquette 'SUV' et l'étiquette 'Acheté'". Dans ce cas, vous recherchez des clients qui ont les deux étiquettes, comme ceux qui sont étiquetés avec 'Acheté' et 'SUV'.
"OU" est semblable à dire "Je veux des clients qui ont l'une ou l'autre de ces étiquettes". Par exemple, "Je veux voir les clients qui ont l'étiquette 'Propriétaire de SUV' OU l'étiquette 'Propriétaire de camion'". Ici, vous avez le choix entre deux options, et vous êtes d'accord avec les clients ayant l'une ou l'autre.`,
  TEXT_PLEASE_WRITE_ONLY_POSITIVE_NUMBER: "Veuillez n'écrire qu'un nombre positif",
  TEXT_THE_MAXIMUM_NUMBER_CAN_BE: 'Le nombre maximum peut être 999',
  TEXT_PLEASE_WRITE_NUMBER_OF_DAYS: 'Veuillez indiquer le nombre de jours',
  WORD_CAMPAIGN: 'Campagne',
  WORD_CONTENT: 'Contenu',
  WORD_CONDITIONS: 'Conditions',
  WORD_AVAILABLE_STATUSES: 'Statuts disponibles',
  WORD_STATUSES_FOR_INTEGRATES: 'Statuts pour intégrer',
  WORD_NEXT_SCHEDULED_MESSAGE: 'Prochain message programmé',
  WORD_BODY: 'Corps',
  TEXT_EXCLUDE_ARCHIVED_CUSTOMERS_FROM_BROADCAST:
    'EXCLURE LES CLIENTS DU DOSSIER "ARCHIVÉ" DE LA DIFFUSION',
  MSG_PLEASE_CONFIRM_YOUR_PASSWORD: 'Veuillez confirmer votre mot de passe !',
  WORD_ESTIMATE_PRICE: 'Prix Estimé',
  WORD_ADD_WORKING_HOURS: 'Ajouter des heures de travail personnalisées',
  WORD_ADD_CUSTOM_HOURS: 'Ajouter des heures',
  WORD_PLEASE_INPUT: 'Veuillez saisir',
  MSG_PLEASE_INPUT_PASSWORD: 'Veuillez saisir le mot de passe!',
  MSG_PLEASE_SELECT_DEFAULT_LANGUAGE: 'Veuillez sélectionner une langue par défaut',
  WORD_CHARACTERS: 'characters',
  TEXT_MUST_BE_MAXIMUM: 'must be maximum',
  TEXT_MUST_BE_MINIMUM: 'must be minimum',
  TEXT_BROADCAST_TIME_ZONE: `Fuseau horaire de diffusion: Sélectionné dans le fuseau horaire de l'organisation`,
  TEXT_APPOINTMENT_TIME_ZONE: `Fuseau horaire du rendez-vous : Sélectionné dans le fuseau horaire de l'organisation`,
  TEXT_CLICK_SORT_ASCENDING: 'cliquez pour trier par ordre croissant',
  TEXT_CLICK_SORT_DESCENDING: 'cliquez pour trier par ordre décroissant',
  TEXT_CLICK_SORT_CANCEL: 'cliquez pour annuler le tri',
  matador_payment_request_created: 'Demande de Paiement Créée',
  payment_request_failed: 'Payment Request Failed',
  payment_request_canceled: 'Demande de Paiement Annulée',
  payment_request_completed: 'Demande de Paiement Complétée',
  matador_refund_request_created: 'Demande de Remboursement de Paiement Créée',
  SKIPPED_SCHEDULED_MESSAGE_CREATED_DRIP_SEQUENCE:
    'a ignoré le message programmé créé par la Séquence',
  ACTION_CONNECTION_DATE: 'Date de connexion:',
  TEXT_PLEASE_NOTE_THIS_EMAIL_ATTACHED_SEVERAL_OTHER_INTEGRATIONS:
    'Veuillez noter ! Cet e-mail est attaché à plusieurs autres intégrations.',
  TEXT_IF_YOU_DISCONNECT_THIS_INTEGRATION_ALL_INTEGRATIONS_ABOVE_WILL_BE_DISCONNECTED_TOO:
    'Si vous déconnectez cette intégration. toutes les intégrations ci-dessus seront également déconnectées.',
  WORD_SELECT_DELAY: 'Sélectionnez le délai',
  WORD_SELECT_TRIGGER: 'Sélectionnez le déclencheur',
  WORD_MINUTE: 'Minute',
  TEXT_RECOMMENDED_FORMATS_JPEG:
    'Formats recommandés: JPEG, JPG, PNG ou GIF. Utilisez un rapport hauteur/largeur de 2,8:1.',
  MSG_ANSWER_IS_REQUIRED:
    'Une réponse est requise pour les deux langues. Veuillez insérer la réponse dans le champ [lang]',
  MSG_QUESTION_IS_REQUIRED:
    'La question est obligatoire pour les deux langues. Veuillez insérer la réponse dans le champ [lang]',
  PLEASE_USE_CORRECT_DYNAMIC_TAGS:
    'Veuillez utiliser les balises dynamiques correctes',
  WORD_TEXT_US_BUTTON: 'Bouton Envoyez-nous un SMS',
  WORD_MARGIN_BOTTOM: 'Marge inférieure',
  WORD_SMALL: 'Petit',
  WORD_MEDIUM: 'Moyen',
  WORD_LARGE: 'Grand',
  WORD_CALL_ME_BUTTON: 'Bouton Appelez-moi',
  WORD_PRIMARY_BACKGROUND_COLOR: "Couleur d'arrière-plan principale",
  TEXT_RECOMMENDED_FOR_RESPONSIVENESS:
    '100% largeur (recommandé pour la réactivité)',
  MSG_YOUR_EMAIL_UPDATED_SUCCESSFULLY: 'Votre email a été mis à jour avec succès',
  WORD_SNOOZED: 'Mis en veille',
  TEXT_SUPERHUMAN_WILL_BE_REENGAGED_CONVERSATION_AT:
    'Superhuman sera réengagé dans la conversation le',
  WORD_REVERT_DECISION: 'Revenir sur la décision',
  TEXT_TAKE_PART_CONVERSATION: 'a programmé une suite',
  TEXT_SNOOZE_FOR_HOURS: 'Mettre en veille pendant {{hours}} heures',
  TEXT_SKIP_NEXT_SCHEDULED_SUPER_HUMAN_MESSAGE: 'Passer le message IA suivant',
  superhumanActionInConversation:
    "Permettre aux utilisateurs de voir si Superhuman a l'intention de répondre dans une conversation",
  TEXT_REACTIVATED_SUPERHUMAN_PARTICIPATION_CONVERSATION:
    'a réactivé la participation de Superhuman à la conversation',
  STORIES_REORDER_DESCRIPTION: 'Glissez-déposez pour réorganiser les stories',
  WORD_QUEUED: "En file d'attente",
  WORD_FAILED: 'Échoué',
  WORD_SELL: 'Vendre',
  WORD_BUY: 'Acheter',
  TEXT_YOU_CANT_TEXT_CUSTOMERS_WHILE_AWAITING_CONSENT:
    'Vous ne pouvez pas envoyer de SMS aux clients en attendant leur consentement',
  TEXT_IT_IS_NOT_POSSIBLE_TO_CREATE_REMINDER:
    "Il n'est pas possible de créer un rappel pour une heure passée. Veuillez sélectionner une heure correcte",
  TEXT_SELECT_USER_ROLE_THAT_CAN_SNOOZE_SUPERHUMAN_REPLY:
    'Sélectionner le(s) rôle(s) utilisateur qui peuvent reporter la réponse de Superhuman:',
  TEXT_SNOOZED_SUPERHUMAN_FOR_HOURS: 'a mis en veille Superhuman pendant 12 heures',
  MSG_SNOOZE_SUPERHUMAN: `Êtes-vous sûr de vouloir ignorer l’action Super Human?`,
  TEXT_THIS_CUSTOMER_SUBSCRIBED_EMAIL_COMMUNICATION:
    "Ce client s'est abonné aux notifications par e-mail",
  TEXT_THIS_CUSTOMER_UNSUBSCRIBED_EMAIL_COMMUNICATION:
    "Ce client s'est désabonné des communications par e-mail",
  TEXT_AUTOMATIONS_CHANGED_CONSENT_STATUS_THIS_CUSTOMER_PENDING_REFUSED:
    'Les automatisations ont modifié le statut de consentement de ce client de En attente à Refusé',
  WORD_PLEASE_SELECT_TIME: "Veuillez sélectionner l'heure !",
  TEXT_AT_LEAST_ONE_OPTION_MUST_BE_SELECTED:
    'Au moins un emplacement doit être sélectionné',
  TEXT_ADD_DRIP_SEQUENCE: 'Ajouter un message de séquence',
  TEXT_WHAT_REPORTS_DO_YOU_WANT_TO_RECEIVE:
    'Quelles statistiques souhaitez-vous recevoir?',
  TEXT_SCHEDULED_TIME_ZONE: `Fuseau horaire du message programmé: Sélectionné dans le fuseau horaire de l'organisation`,
  TEXT_REMINDER_TIME_ZONE: `Rappel du fuseau horaire: Sélectionné dans le fuseau horaire de l'organisation`,
  WORD_DRIP_SEQUENCE_INSTAGRAM: 'Drip Sequence Instagram',
  WORD_DRIP_SEQUENCE_GOOGLE_BUSINESS: 'Drip Sequence Google Business',
  WORD_DRIP_SEQUENCE_FACEBOOK: 'Drip Sequence Facebook',
  WORD_DISABLED: 'désactivée',
  TEXT_SUBJECT_REQUIRED_PLEASE_ENTER_SUBJECT_FOR_YOUR_EMAIL:
    'Objet obligatoire: Veuillez saisir un objet pour votre e-mail',
  WORD_ENGAGED: 'Engagé',
  TEXT_AUTOMATIONS_CHANGED_CONSENT_STATUS_THIS_CUSTOMER_COMPLIANT_OPTED_OUT:
    'Les automatisations ont changé le statut de consentement du client de Conforme à Désabonné',
  TEXT_AUTOMATIONS_CHANGED_CONSENT_STATUS_THIS_CUSTOMER_COMPLIANT_REFUSED:
    'Les automatisations ont modifié le statut de consentement de ce client de Conforme à Refusé',
  TEXT_USERNAME_CHANGED_CONSENT_STATUS_THIS_CUSTOMER_COMPLIANT_REFUSED:
    'ont modifié le statut de consentement de ce client de Conforme à Refusé',
  TEXT_SUPERHUMAN_ENABLED: 'Superhuman activé',
  TEXT_SUPERHUMAN_DISABLED: 'Superhuman désactivé',
  TEXT_FOLLOW_UP_ENABLED: 'Suivi IA activé',
  TEXT_FOLLOW_UP_DISABLED: 'Suivi IA désactivé ',
  TEXT_NEW_TOUCHPOINTS: 'Nouveaux points de contact',
  TEXT_AUTO_ASSIGN_TO_THE_FOLLOWING_REGULAR_SEQUENCE:
    'Attribution automatique à la prochaine séquence régulière',
  TEXT_AUTO_ASSIGN_TO_FOLLOWING_AFTER_HOUR_SEQUENCE:
    "Attribution automatique à la séquence suivante en dehors des heures d'ouverture",
  TEXT_ENGLISH: 'Anglais',
  TEXT_FRENCH: 'Français',
  TEXT_MESSAGE_WAS_DELETED_BY_USER: 'Message supprimé par le client',
  TEXT_PLEASE_TRY_TO_UPLOAD_THE_FILE_LESS_THAN_1_MB:
    'Veuillez essayer de télécharger le fichier de moins de 20 MB',
  WORD_AI_FOLLOW_UP: 'Suivi IA',
  TEXT_APPOINTMENT_IS_BOOKED: 'Les rendez-vous sont pris',
  TEXT_APPOINTMENT_WAS_UPDATED: 'Le rendez-vous a été modifié',
  TEXT_APPOINTMENT_WAS_CANCELED: 'Le rendez-vous a été annulé',
  WORD_SEQUENCES: 'Séquences',
  TEXT_STORE_IS_OPEN: 'Le magasin est ouvert',
  TEXT_STORE_IS_CLOSED: 'Le magasin est fermé',
  WORD_AM: 'du matin',
  WORD_PM: 'du soir',
  TEXT_FOLLOW_UP_TOOLTIP: `Suivi IA relance automatiquement les conversations à froid. Si un client ne répond pas à votre dernier message dans les 12 heures, Suivi IA programmera un message de suivi à la date/heure idéale. Utilisez ce bouton pour désactiver la fonctionnalité pour l'ensemble de l'organisation. Les utilisateurs peuvent également toujours se désinscrire du Suivi IA directement dans la conversation.`,
  TEXT_ENABLED_BY: 'Connecté par:',
  TEXT_DISABLED_BY: 'Déconnecté par:',
  TEXT_TYPE_OF_SEQUENCE: 'Type de séquence',
  TEXT_DISABLE_ALL_SEQUENCES: 'Désactiver toutes les séquences',
  TEXT_ENABLE_ALL_SEQUENCES: 'Activer toutes les séquences ',
  TEXT_ARE_YOU_SURE_YOU_WANT_TO_ENABLE_ALL_SEQUENCES_IN_THIS_ORGANIZATION:
    'Êtes-vous sûr de vouloir activer toutes les séquences de cette organisation?',
  TEXT_ARE_YOU_SURE_YOU_WANT_TO_DISABLE_ALL_SEQUENCES_IN_THIS_ORGANIZATION:
    'Êtes-vous sûr de vouloir désactiver toutes les séquences de cette organisation?',
  TEXT_THIS_ACTION_CANNOT_BE_UNDONE: 'Cette action ne peut pas être annulée',
  TEXT_ALL_SEQUENCES_WERE_ENABLED_SUCCESSFULLY:
    'Toutes les séquences ont été activées avec succès',
  TEXT_ALL_SEQUENCES_WERE_DISABLED_SUCCESSFULLY:
    'Toutes les séquences ont été désactivées avec succès',
  TEXT_GOOGLE_DISCONTINUED:
    "Les Messages d'Affaires Google ont été interrompus le 31 juillet 2024. Veuillez nous contacter pour obtenir de l'aide.",
  TEXT_SKIPPED_SCHEDULED_MESSAGE_CREATED_AI_FOLLOW_UP:
    'a ignoré le prochain message de suivi AI programmé',
  TEXT_SKIPPED_SCHEDULED_MESSAGE_CREATED_DRIP_SEQUENCE:
    'a sauté le prochain message de la Séquence planifiée',
  TEXT_SKIPPED_SCHEDULED_MESSAGE_CREATED_BY_SUPERHUMAN:
    ', a ignoré le message programmé créé par Superhuman',
  WORD_DRIP_SEQUENCE: "Séquence d'égouttage",
  DISABLE_INPUT: 'Ne pas afficher le champ de saisie «Tapez votre message»',
  REDIRECT_URL: 'URL de redirection',
  REDIRECT_URL_TOOLTIP:
    "L'URL de redirection est destinée à naviguer le client directement vers une URL spécifiée, en contournant l'affichage du formulaire de lead. Bien que cette option ne soit généralement pas recommandée, elle peut s'avérer bénéfique dans certaines circonstances.",
  INVALID_URL: 'URL invalide',
  TEXT_THERE_WAS_AN_ISSUE_CONNECTING_YOUR_ACCOUNT_PLEASE_TRY_AGAIN:
    'Un problème est survenu lors de la connexion de votre compte, veuillez réessayer',
  GA_TRACK_PAGE_VIEWS_TITLE:
    'Suivez les pages vues pour chaque widget et les soumissions de leads',
  GA_TRACK_PAGE_VIEWS_PARAGRAPH_1:
    "Notre plateforme vous permet de surveiller sans effort les pages vues pour chaque widget. De plus, lorsqu'un nouveau lead est soumis, nous envoyons un événement personnalisé avec les détails suivants:",
  GA_CATEGORY: 'Catégorie',
  GA_MATADOR: '« Matador »',
  GA_ACTION: 'Action',
  GA_ACTION_DETAILS:
    '« connect » (ou mobile_coupon, desktop_coupon, mobile_cta, integrated_cta, exit_intent)',
  GA_LABEL: 'Libellé',
  GA_SUBMISSION: '« Soumission »',
  GA_TRACK_PAGE_VIEWS_PARAGRAPH_2:
    "En suivant ces événements, vous obtiendrez des informations précieuses sur l'engagement et les conversions des utilisateurs via les widgets Matador.",
  GA_INFO_ABOUT_GA: 'Infos sur GA',
  GA_GOOGLE_ANALYTICS_4_TRACKING: 'Suivi Google Analytics 4',
  POSITION_TEXT: 'Position du texte',
  ENABLE_BOLD_TEXT: 'Activer la police en gras',
  TEXT_REVIEW_INVITE_TYPE: `Type d'invitation d’avis`,
  TEXT_REVIEW_POSTING_THRESHOLD: 'Seuil de publication des avis',
  TEXT_FIVE_STARS: '5 étoiles',
  TEXT_NPS_SCORE_10_POINTS: 'Score NPS (10 points)',
  TEXT_SETS_THE_MINIMUM_RATING_REQUIRED_BEFORE_CUSTOMERS_CAN_POST_THEIR_REVIEW_ON_EXTERNAL_PLATFORMS:
    'Définit la note minimale requise avant que les clients puissent publier leur avis sur des plateformes externes',
  TEXT_TAG_NAMES_SHOULD_NOT_BE_DUPLICATED:
    'Les étiquettes ne doivent pas être dupliqués',
  GA_TRACK_PAGE_VIEWS_PARAGRAPH_3:
    "En suivant ces événements, vous obtiendrez des informations précieuses sur l'engagement et les conversions des utilisateurs via les widgets Matador.",
  GA_TRACK_PAGE_VIEWS_PARAGRAPH_4:
    "De plus, notre plateforme prend en charge l'utilisation de plusieurs identifiants Google Analytics 4 (GA4). Vous pouvez ajouter plusieurs identifiants GA au format G-YYYYYYYYYY, G-XXXXXXXXXX, séparés par des virgules. Cela vous permet de suivre les mêmes événements sur différentes propriétés GA pour une analyse plus détaillée.",
  WORD_PHONE_COPIED: 'Téléphone copié',
  WORD_EMAIL_COPIED: 'Courriel copié',
  WORD_TEMPLATES: 'Modèles',
  WORD_REMINDER: 'Rappel',
  INVALID_GA_FORMAT:
    "Format d'ID GA4 invalide. Chaque ID doit être au format G-XXXXXXXXXX avec des lettres majuscules et des chiffres.",
  INVALID_GA_FORMAT_COMMA:
    'Il semble que vous ayez terminé par une virgule ou un point-virgule. Veuillez ajouter un autre code GA ou supprimer le dernier caractère.',
  INVALID_GA_FORMAT_SPACE:
    "Veuillez vous assurer qu'il y a un espace après chaque virgule ou point-virgule lors de la saisie de plusieurs ID GA4.",
  TEXT_PERSONAL_FACEBOOK_ACCOUNT: 'Compte Facebook personnel',
  WORD_VOICE_AI: 'Appeler l’IA',
  WORD_INBOUND_VOICE_AI: 'IA d’Appel Entrant',
  WORD_OUTBOUND_VOICE_AI: 'IA d’Appel Sortant',
  WORD_RINGING: 'Sonnerie',
  WORD_IN_LINE_PROGRESS: 'En cours',
  WORD_COMPLETED: 'Terminé',
  WORD_BUSY: 'Occupé',
  WORD_NO_ANSWER: 'Pas de réponse',
  TEXT_THE_CALL_REQUEST_HAS_BEEN_INITIATED_IS_WAITING_BE_PROCESSED:
    'La demande d’appel a été initiée et est en attente de traitement.',
  TEXT_THE_CALL_IS_IN_QUEUE_WILL_BE_ATTEMPTED_SHORTLY:
    'L’appel est en file d’attente et sera tenté sous peu.',
  TEXT_THE_RECIPIENTS_PHONE_IS_RINGING_WAITING_FOR_THEM_TO_ANSWER:
    'Le téléphone du destinataire sonne, en attente de sa réponse.',
  TEXT_THE_CALL_IS_CURRENTLY_ACTIVE_ONGOING_YOU_CAN_JOIN_TAKE_PART_IN_CALL:
    'L’appel est actuellement actif et en cours. Vous pouvez rejoindre et participer à l’appel.',
  TEXT_THE_CALL_HAS_BEEN_SUCCESSFULLY_FINISHED: 'L’appel a été terminé avec succès',
  TEXT_THE_CALL_ATTEMPT_WAS_UNSUCCESSFUL_DUE_TO_TECHNICAL_ISSUES_CONNECTION_PROBLEMS:
    'La tentative d’appel a échoué en raison de problèmes techniques ou de connexion.',
  TEXT_THE_CALL_WAS_CANCELED_BY_USER_OR_SYSTEM_BEFORE_IT_COULD_CONNECT:
    'L’appel a été annulé par l’utilisateur ou le système avant d’être connecté.',
  TEXT_THE_RECIPIENTS_PHONE_LINE_IS_BUSY_THE_CALL_CANNOT_CONNECT:
    'La ligne téléphonique du destinataire est occupée et l’appel ne peut pas se connecter.',
  TEXT_THE_RECIPIENT_DID_NOT_ANSWER_CALL_WITHIN_ALLOWED_TIME:
    'Le destinataire n’a pas répondu à l’appel dans le délai imparti.',
  WORD_CALL_TYPE: 'Appels Type',
  TEXT_NO_TRANSCRIPT_YET: 'Pas encore de transcription',
  WORD_UNMUTE: 'Réactiver le son',
  WORD_MUTE: 'Muette',
  WORD_JAMP_ON_CALL: 'Sauter sur appel',
  WORD_DISCONNECT_AUDIO: "Déconnecter l'audio",
  TEXT_LIVE_SYNC: 'Synchronisation en direct',
  TEXT_SMART_SYNC: 'Synchronisation intelligente',
  TEXT_COMPREHENSIVE_SYNC: 'Synchronisation complète',
  TEXT_LIVE_SYNC_DESCRIPTION:
    'Crée automatiquement des notes dans le CRM pour chaque action et vérifie si les bons utilisateurs sont affectés. Si les utilisateurs affectés au CRM ne sont pas dans Matador, il les ajoute automatiquement.',
  TEXT_SMART_SYNC_DESCRIPTION:
    "Comprend une synchronisation en temps réel ainsi qu'une vérification toutes les 5 minutes. Crée des notes, vérifie les utilisateurs et ajoute de nouveaux affectations à Matador.",
  TEXT_COMPREHENSIVE_SYNC_DESCRIPTION:
    "Offre une synchronisation en temps réel, des vérifications périodiques, la création automatique de notes et la gestion des utilisateurs. Ajoute ou supprime des affectations d'utilisateurs toutes les 5 minutes selon les besoins.",
  TEXT_YOU_CAN_CONTINUE_TO_MODIFY_SEQUENCE_CONFIGURATIONS:
    "Vous pouvez continuer à modifier les configurations de séquence. Les modifications prendront effet une fois que vous aurez activé l'option Séquence principale.",
  TEXT_BY_CLICKING_YOU_ENABLE_ALL_SEQUENCES:
    'En cliquant sur ce bouton, vous activez toutes les séquences existantes actives',
  TEXT_BY_CLICKING_YOU_DISABLE_ALL_SEQUENCES:
    'En cliquant sur ce bouton, vous désactivez toutes les séquences actives existantes',
  TEXT_PLEASE_INPUT_DEALER_ID: 'Veuillez saisir Identifiant du concessionnaire',
  WORD_PUSH: 'Envoyer',
  FOREGROUND_COLOR: 'Couleur de premier plan',
  CONNECT_BACKGROUND_COLOR: "Couleur d'arrière-plan",
  INTEGRATION_INSTRUCTIONS_TITLE: "Instructions d'intégration",
  INTEGRATION_INSTRUCTIONS_CONTENT:
    "Pour intégrer le widget CTA Intégré Matador sur votre site web, suivez ces étapes simples. Tout d'abord, localisez la section de votre site web où vous souhaitez que le widget soit affiché. Cela peut être sur votre page d'accueil, une page d'inventaire spécifique, ou toute autre section pertinente.",
  STEP1_TITLE: 'Étape 1 : Ajouter le code HTML',
  STEP1_CONTENT:
    "Ajoutez le code HTML ci-dessus à l'endroit où vous souhaitez que le widget soit affiché sur votre site web.",
  STEP2_TITLE: 'Étape 2 : Ajouter le lien de style',
  STEP2_CONTENT:
    'Incluez le lien de style dans la section <head> de votre site web pour assurer un style approprié du widget Matador.',
  STEP3_TITLE: 'Étape 3 : Ajouter le lien du script',
  STEP3_CONTENT:
    'Ajoutez le lien du script dans la section <head> de votre site web pour activer la fonctionnalité du widget CTA intégré Matador.',
  INTEGRATION_CONGRATULATIONS:
    "Félicitations ! Vous avez intégré avec succès le widget CTA Intégré Matador sur votre site web. Les visiteurs peuvent maintenant profiter de la commodité d'interagir avec les widgets Matador.",
  INTEGRATION_SUPPORT:
    "Si vous avez des questions ou rencontrez des problèmes lors du processus d'intégration, veuillez contacter notre équipe de support à support@matador.ai. Nous sommes là pour vous aider !",
  ADDITIONAL_INSTRUCTIONS: 'Instructions supplémentaires',
  VEHICLE_PROPERTIES_EXPLANATION:
    "Vous pouvez passer des propriétés de véhicule au CTA intégré. Un véhicule d'intérêt sera créé dans la conversation en fonction de ces propriétés.",
  MILEAGE_EXPLANATION: 'Le kilométrage du véhicule.',
  STOCK_NUMBER_EXPLANATION: 'Le numéro de stock du véhicule.',
  MODEL_EXPLANATION: 'Le modèle du véhicule.',
  YEAR_EXPLANATION: "L'année du véhicule.",
  MAKE_EXPLANATION: 'La marque du véhicule.',
  VIN_EXPLANATION: "Le VIN (Numéro d'Identification du Véhicule) du véhicule.",
  EXAMPLE: 'Exemple',
  TEXT_CONNECT_STRIPE_ACCOUNT: 'Connecter le compte Stripe',
  WORD_CONNECT_STRIPE: 'Connecte Stripe',
  WORD_DISCONNECT_STRIPE: 'Déconnecter Stripe',
  TEXT_YOU_CURRENTLY_HAVE_NO_CREATED_ORGANIZATIONS:
    "Vous n'avez actuellement aucune organisation créée.",
  TEXT_NO_RESULTS_FOUND: 'Aucun résultat trouvé',
  WORD_NEEDS_YOU: 'Besoin de Vous',
  WORD_OUTGOING_CALLS: 'Appels sortants',
  TEXT_CREATE_NEW_ASSISTANT: 'Créer un nouvel assistant',
  TEXT_ASSISTANT_IS_ACTIVE: "L'assistant est actif",
  TEXT_ASSISTANT_NAME: "Nom de l'assistant",
  TEXT_CONNECTED_LOCATION: 'Emplacement connecté',
  TEXT_ASSISTANT_TYPE: "Type d'assistant",
  TEXT_INTRODUCTION__FIRST_MESSAGE: 'Introduction (premier message)',
  TEXT_TAG_USERS_IF_THE_CUSTOMER_NEEDS_A_CALLBACK:
    "Identifiez les utilisateurs si le client a besoin d'un rappel",
  TEXT_CREATE_A_LINK_TO_LANDING_PAGE_TO_ACTIVATE_VOICE_AI:
    'Créez un lien vers la page de destination pour activer Voice AI',
  WORD_AI_NUMBER: 'Numéro IA',
  WORD_CALL_TRANSFERS: "Transferts d'appels",
  TEXT_USE_ORGANIZATION_WORKING_HOURS:
    "Utiliser les heures de travail de l'organisation",
  TEXT_NO_CALLS: "*pas d'appels",
  TEXT_ADD_DEPARTMENT: 'Ajouter un département',
  TEXT_YOU_CAN_CONTINUE_TO_MODIFY_THE_CALL_TRANSFERS:
    "Vous pouvez continuer à modifier les transferts d'appels. Les modifications prendront effet une fois que vous les aurez activées.",
  TEXT_DEPARTMENT_NAME: 'Nom du département',
  TEXT_VOICE_AI_CALL_WITH: 'Appel vocal IA avec',
  TEXT_MORE_DETAILS: 'Plus de détails',
  TEXT_LESS_DETAILS: 'Moins de détails',
  WORD_CALLBACKS_SCHEDULED: 'Rappels programmés',
  WORD_CALL_DETAILS: "Détails de l'appel",
  WORD_CALLER_INFO: "Informations sur l'appelant",
  WORD_CALL: 'Call',
  WORD_ESTIMATED_VALUE: 'Valeur estimée',
  TEXT_OF_TIMES_CALLED: 'Nombre de fois appelé',
  WORD_DURATION: 'Durée',
  TEXT_YOU_CAN_CONTINUE_TO_MODIFY_ASSISTANT:
    "Vous pouvez continuer à modifier l'Assistant. Les modifications prendront effet une fois que vous l'aurez activé.",
  TEXT_ASSIGN_USERS_TO_A_CONVERSATIONS:
    'Affecter des utilisateurs à des conversations',
  TEXT_ASSISTANT_WAS_UPDATED: "L'assistant a été mis à jour",
  TEXT_ASSISTANT_WAS_CREATED_SUCCESSFULLY: "L'assistant a été créé avec succès",
  TEXT_THE_NUMBER_WILL_BE_PROVISIONED_AUTOMATICALLY_ONCE_THE_ASSISTANT_ISCREATED:
    "Le numéro sera attribué automatiquement une fois l'assistant créé",
  TEXT_YOU_CAN_CHANGE_THE_SETTINGS_ON_THE_ASSISTANT_PAGE:
    "Vous pouvez modifier les paramètres sur la page de l'assistant",
  TEXT_EXCLUDE_DNC_NUMBERS_FROM_THE_BROADCAST_LIST:
    'Exclure les numéros NPA de la liste de diffusion',
  TEXT_EXCLUDE_DNC_NUMBERS_FROM_THE_BROADCAST_LIST_TOOLTIP:
    'Le nettoyage NPA est le processus consistant à éliminer les numéros de téléphone inscrits sur le Registre National des Numéros Ne Pas Appeler (RNNPA) de vos listes d’appels. Cela garantit que votre entreprise respecte les réglementations en matière de télémarketing en filtrant les numéros qui ne doivent pas être contactés.',
  WORD_IP_ADDRESS: "l'adresse IP",
  TEXT_TOTAL_SMS_TEMPLATES: 'Total des modèles SMS',
  TEXT_TOTAL_EMAIL_TEMPLATES: 'Total des modèles de courriel',
  TEXT_SEQUENCES_ARE_ACTIVE: 'Les séquences sont actives',
  TEXT_SEQUENCES_ARE_INACTIVE: 'Les séquences sont inactives',
  TEXT_FIRST_AUTO_MESSAGE_FULL: 'Premier message automatique devrait être envoyé',
  TEXT_AFTER_RECEIVING_LEAD_FULL: "après réception d'un lead.",
  TEXT_AFTER_CUSTOMER_LAST_SESSION_FULL: 'après dernière session du client.',
  TEXT_DRAG_A_FILE_HERE_OR_UPLOAD_FULL:
    'Glissez un fichier ici ou parcourir pour un fichier à télécharger',
  WORD_AI_REPLY_IN: 'Réponse IA',
  WORD_OVERVIEW_SUPERHUMAN: 'Aperçu et Superhumam',
  WORD_TOTAL_MESSAGES_SENT: 'Nombre total de messages envoyés',
  WORD_UNIQUE_LEADS: 'Prospects uniques',
  WORD_LEADS_ENGAGED: 'De prospects engagés',
  WORD_LEADS_ENGAGED_PERCENTAGE: '% de prospects engagés',
  WORD_TOTAL_CREATED_APPOINTMENTS: 'Total des rendez-vous créés',
  WORD_TOTAL_TIME_SAVED: 'Temps total économisé',
  WORD_SALES_HANDOFFS: 'Remise des ventes',
  WORD_CONTENT_CONSUMPTION: 'Consommation de contenu',
  WORD_WATCH_RATE: 'Taux de visionnement',
  WORD_PERCENTAGE_OF_PEOPLE_THAT_HAVE_VIEWED_YOUR_VIDEO:
    'Pourcentage de personnes ayant visionné votre vidéo',
  WORD_LEADS_WHERE_VIDEO_SENT:
    '% de prospects pour lesquels une vidéo a été envoyée',
  WORD_TOTAL_VIDEOS_SENT: 'Nombre total de vidéos envoyées',
  WORD_USER_TYPE: "Type d'utilisateur",
  WORD_APPOINTMENTS_CREATED: 'Rendez-vous créés',
  WORD_SENT_MESSAGES: 'Messages envoyés',
  TEXT_INFLUENCED_VISITS_BY_SUPERHUMAN: 'Visites influencées par Superhuman',
  TOOLTIP_TOTAL_MESSAGES_SENT_EMAIL:
    'Le nombre total de courriels envoyés aux prospects et clients pendant la période sélectionnée',
  TOOLTIP_TOTAL_MESSAGES_SENT_FACEBOOK:
    'Le nombre total de messages envoyés via Facebook Messenger aux prospects et clients.',
  TOOLTIP_TOTAL_MESSAGES_SENT_INSTAGRAM:
    'Le nombre total de messages directs envoyés via Instagram pour engager les prospects et clients.',
  TOOLTIP_TOTAL_MESSAGES_SENT_SMS:
    'Le nombre total de messages SMS envoyés aux contacts pour l’engagement et la communication.',
  TOOLTIP_UNIQUE_LEADS:
    'Le nombre total de prospects individuels contactés, “numéros de téléphone uniques”.',
  TOOLTIP_LEADS_ENGAGED: '% de leads que vous avez convertis en conversations.',
  TOOLTIP_INFLUENCED_WALK_INS:
    'Le nombre de clients ayant visité la concession après avoir reçu une communication via Matador AI.',
  TOOLTIP_TOTAL_CREATED_APPOINTMENTS:
    'Le nombre total de rendez-vous planifiés avec la plateforme Matador.',
  TOOLTIP_TOTAL_CREATED_APPOINTMENTS_SUPERHUMAN_AI:
    'Le nombre de rendez-vous planifiés automatiquement par Superhuman AI sans intervention manuelle.',
  TOOLTIP_TOTAL_CREATED_APPOINTMENTS_MANUAL_APPOINTMENTS:
    'Le nombre de rendez-vous planifiés manuellement par les utilisateurs.',
  TOOLTIP_SUPERHUMAN_AI_TOTAL_MESSAGES_SENT:
    'Le nombre total de messages envoyés par Superhuman AI sur tous les canaux.',
  TOOLTIP_SUPERHUMAN_AI_TOTAL_CREATED_APPOINTMENTS_AFTER_HOURS:
    'Rendez-vous planifiés par Superhuman AI en dehors des heures normales d’ouverture, captant des opportunités 24/7.',
  TOOLTIP_SUPERHUMAN_AI_TOTAL_CREATED_APPOINTMENTS_WORKING_HOURS:
    'Rendez-vous planifiés par Superhuman AI pendant les heures normales d’ouverture de votre concession.',
  TOOLTIP_SUPERHUMAN_AI_TOTAL_TIME_SAVED:
    'Le temps estimé que votre équipe a économisé en utilisant Superhuman AI pour les communications et la planification.',
  TOOLTIP_SUPERHUMAN_AI_INFLUENCED_WALK_INS:
    'Visites sans rendez-vous influencées par les interactions avec Superhuman AI.',
  TOOLTIP_SUPERHUMAN_AI_SALES_HANDOFFS:
    'Le nombre de fois où Superhuman AI a transféré une conversation à un agent humain pour un engagement plus poussé.',
  WORD_CAMPAIGNS: 'Campaigns',
  WORD_MARKETING: 'Marketing',
  TOOLTIP_VIDEO_CONTENT_CONSUPTION:
    'Durée totale du contenu vidéo visionné par les prospects et les clients.',
  TOOLTIP_VIDEO_WATCH_RATE:
    'Pourcentage de vidéos envoyées qui ont été visionnées par les destinataires.',
  TOOLTIP_VIDEO_OF_LEADS_WHERE_VIDEO_SENT:
    'Pourcentage total de prospects ayant reçu un message vidéo.',
  TOOLTIP_VIDEO_TOTAL_VIDEOS_SENT:
    'Nombre total de messages vidéo envoyés aux contacts.',
  TOOLTIP_USER_USER_NAME:
    'Le nom de l’utilisateur ou de l’agent au sein de la plateforme Matador AI.',
  TOOLTIP_USER_USER_TYPE:
    "Indique le rôle de l'utilisateur: Administrateur, Responsable ou Agent de soutien.",
  TOOLTIP_USER_LAST_LOGIN:
    'La date et l’heure les plus récentes auxquelles l’utilisateur a accédé à la plateforme Matador AI.',
  TOOLTIP_USER_APPOINTMENTS_CREATED:
    'Nombre total de rendez-vous planifiés par l’utilisateur.',
  TOOLTIP_USER_VIDEOS_SENT:
    'Nombre de messages vidéo personnalisés envoyés par l’utilisateur.',
  TOOLTIP_USER_SENT_MESSAGES:
    'Total des messages envoyés par l’utilisateur sur tous les canaux de communication.',
  TOOLTIP_SEQUENCE_LEAD_VOLUME:
    'Nombre de prospects inscrits dans des séquences de messages automatisées',
  TOOLTIP_SEQUENCE_MESSAGES_SENT:
    'Nombre total de messages envoyés dans le cadre des séquences sur tous les canaux de communication',
  TOOLTIP_SEQUENCE_AVG_MESSAGES_SENT_PER_USER:
    'Nombre moyen de messages reçus par chaque prospect dans une séquence',
  TOOLTIP_SEQUENCE_ENGAGED_CLIENTS:
    "Nombre de prospects ayant répondu aux messages d'une séquence",
  VEHICLE_PROPERTIES_EXPLANATION_AUTOMATIC:
    'Le widget CTA intégré de Matador peut détecter automatiquement les détails du véhicule sur votre site. Il lit des attributs tels que VIN, marque, modèle, année, stock et kilométrage depuis les éléments parent de votre page via des balises de données standard. Cela permet au widget de transmettre les informations du véhicule directement dans les conversations sans saisie manuelle.',
  TEXT_NAME_IS_REQUIRED_IN_WIDGET_FORM:
    'Le nom est requis dans le formulaire de widget',
  TEXT_DISPLAY_THE_FIRST_AND_LAST_NAMES_SEPARATELY_IN_WIDGET_FORM:
    'Afficher le prénom et le nom séparément dans le formulaire de widget',
  TEXT_FORM_SETTINGS: 'Paramètres du formulaire',
  WORD_VALUE_GENERATED: 'Valeur générée',
  WORD_PARTS: 'Parties',
  WORD_TIMESAVED: 'Temps économisé',
  WORD_VOICE_AI_CREATED_APPOINTMENTS: 'Rendez-vous créés par l’IA d’appel',
  WORD_CALLS_TRANSFERRED: 'Appels transférés',
  WORD_TOTAL_BILLED_MINUTES: 'Total des minutes facturées',
  WORD_OUTCOME: 'Résultat',
  WORD_CALLER: 'Appelant',
  WORD_ESTIMATED_GROSS: 'Estimation du montant brut',
  WORD_DETAILS: 'Détails',
  WORD_MISSED_CALLS_SAVED: 'Appels manqués enregistrés',
  WORD_SMS_CONVERSATIONS_STARTED: 'Conversations SMS démarrées',
  WORD_SELECT_ROLE: 'Sélectionner un rôle',
  WORD_SALES_IMPACT_REPORT: "Rapport d'impact sur les ventes",
  TEXT_SALES_IMPACT_REPORT:
    "Vous souhaitez approfondir vos statistiques ? Demandez un rapport d'impact sur les ventes pour voir combien de ventes Matador AI a influencées - livré dans votre boîte de réception 📬",
  WORD_REQUEST_REPORT: 'Demander un rapport',
  WORD_APPOINTMENT_SET: 'Prise de rendez-vous',
  WORD_CALL_TRANSFERRED: 'Appel transféré',
  WORD_CALLBACK_REQUESTED: 'Demande de rappel',
  WORD_INFORMATION_REQUEST: "Demande d'informations",
  WORD_UNRESOLVED: 'Non résolu',
  TEXT_BOOK_SALES_AND_SERVICE_APPOINTMENTS:
    'Réservez des rendez-vous de vente et de service.',
  TEXT_TRANSFER_CALLS_TO_RELEVANT_DEPARTMENTS:
    'Transférez les appels aux services concernés.',
  TEXT_HANDLE_ROUTINE_CONVERSATIONS_WITH_EXPERT_KNOWLEDGE_OF_YOUR_BUSINESS:
    'Gérez les conversations de routine avec une connaissance approfondie de votre entreprise.',
  TEXT_INTELLIGENTLY_SEND_SMS_FOLLOW_UPS_WHEN_HANDLING_CALLS:
    'Envoyez intelligemment des SMS de suivi lors du traitement des appels.',
  TEXT_ANSWER_ALL_YOUR_MISSED_CALLS_ENSURING_NO_CUSTOMER_IS_LEFT_BEHIND:
    "Répondez à tous vos appels manqués, en veillant à ce qu'aucun client ne soit laissé de côté.",
  TEXT_NEVER_MISS_A_CALL_AGAIN: 'Ne manquez plus jamais un appel...',
  TEXT_FROM_MISSED_CALLS_TO_BUSINESS_OPPORTUNITIES:
    'Des appels manqués aux opportunités commerciales',
  TEXT_ACTIVATE_A_VOICE_AI_AGENT_THAT_WILL_AUTOMATICALLY:
    'Activez un agent Voice AI qui effectuera automatiquement les actions suivantes:',
  TEXT_DEMO_VOICE_AI: 'Démo Voice AI',
  TEXT_LAST_SIX_MONTH: '6 Derniers Mois',
  TEXT_YOUR_MATADOR_REPRESENTATIVE_WILL_BE_IN_TOUCH_SHORTLY_WITH_YOUR_REPORT:
    'Votre représentant Matador vous contactera sous peu avec votre rapport',
  TOOLTIP_APPOINTMENTS_SCHEDULED_BY_SUPERHUMAN:
    'Rendez-vous programmé par Superhuman AI',
  WORD_USER_ROLES: 'Rôles des utilisateurs',
  TEXT_PLESE_UPLOAD_YOUR_CSV_OR_EXCEL_FILE:
    'Veuillez télécharger votre fichier CSV ou Excel',
  LETTER_D: 'j',
  VEHICLE_DETAILS_INTEGRATE_VEHICLE_DETAILS_WITH_MATADOR_CONNECT:
    'Intégrer les détails du véhicule avec Matador Connect',
  VEHICLE_DETAILS_MATADOR_CONNECT_ENABLES_AUTOMATIC_INCLUSION_OF_KEY_VEHICLE_INFORMATION:
    "Matador Connect permet l'inclusion automatique des informations clés du véhicule, telles que le VIN, la marque, le modèle, l'année, le numéro de stock et le kilométrage, dans les messages envoyés par les utilisateurs depuis votre site web. Cela signifie que chaque fois qu'un client montre de l'intérêt pour un véhicule, Matador Connect incorporera ces détails dans le message, les rendant accessibles directement depuis la boîte de réception pour une référence et un suivi faciles.",
  VEHICLE_DETAILS_USING_STRUCTURED_DATA_FOR_SEAMLESS_INTEGRATION:
    'Utilisation de données structurées pour une intégration transparente',
  VEHICLE_DETAILS_TO_MAKE_THE_MOST_OF_MATADOR_CONNECT:
    'Pour tirer le meilleur parti de Matador Connect, assurez-vous que les données structurées du véhicule sont présentes sur vos pages de détails du véhicule. En suivant le',
  VEHICLE_DETAILS_SCHEMA_ORG_CAR_SCHEMA: 'schéma de voiture Schema.org',
  VEHICLE_DETAILS_MATADOR_CONNECT_WILL_AUTOMATICALLY_DETECT:
    ', Matador Connect détectera et incorporera automatiquement ces informations de manière transparente.',
  VEHICLE_DETAILS_FOR_FURTHER_ASSISTANCE:
    "Pour toute assistance supplémentaire, n'hésitez pas à contacter notre équipe de support à",
  VEHICLE_DETAILS_SUPPORT_EMAIL: 'support@matador.ai',
  VEHICLE_DETAILS_INTEGRATING_MATADOR_CONNECT_WITH_VEHICLE_DETAILS:
    'Intégration de Matador Connect avec les détails du véhicule',
  LABEL_ENABLE_BOLD_TEXT: 'Activer le texte en gras',
  TEXT_PLEASE_TAG_A_USER_TO_WHOM_THIS_MESSAGE_IS_ADDRESSED:
    'Veuillez mentionner un utilisateur à qui ce message est destiné',
  TOOLTIP_REVIEW_INVITES_SENT:
    "Nombre total d'invitations à l'avis envoyées par l'utilisateur au cours de cette période.",
  WORD_CLIENTS_MESSAGED: 'Messages des clients',
  WORD_ASSIGNED_CLIENTS: 'Clients assignés',
  TOOLTIP_CLIENTS_MESSAGED:
    "# de proscpects et clients uniques avec lesquels l'utilisateur a interagi au cours de cette période",
  TOOLTIP_ASSIGNED_CLIENTS:
    'Number of assigned clients in the selected time period + currently assigned clients',
  WORD_REVIEW_INVITES_COMPLETED: "Invitations d'avis terminées",
  WORD_PERCENT_REVIEW_INVITES_COMPLETED: "% Invitations d'avis terminées",
  WORD_REVIEW_INVITES_COMPLETED_BY_SOURCE: 'Avis par source',
  WORD_MARKETING_PERFORMANCE: 'Performances marketing',
  WORD_INFLUENCED_VISITS: 'Rendez-vous influencés',
  WORD_TOUCHPOINT: 'Points de contact',
  WORD_ENGAGED_LEADS: 'Prospects engagés',
  WORD_USER_APPOINTMENTS: 'Rendez-vous des utilisateurs',
  WORD_AI_APPOINTMENTS: 'Rendez-vous Superhuman',
  WORD_AI_HANDOFFS: "Transfert de l'",
  WORD_LEADS: 'Prospects',
  TOOLTIP_ADJUST_THIS_VALUE_TO_SET_THE_DISTANCE_IN_PIXELS_FROM_BOTTOM_OF_PAGE_TO_MOBILE_CTA:
    'Ajustez cette valeur pour définir la distance (en pixels) entre le bas de la page et le CTA mobile.',
  WORD_ADD_STEP: 'Ajouter une étape',
  TEXT_ENABLE_CUSTOM_MATADOR_CONNECT: 'Activer Matador Connect personnalisé',
  TEXT_THE_DATA_DOESNT_MATCH: 'Les données ne correspondent pas',
  TEXT_LEAVE_AS_IS: 'Laisser tel quel',
  TEXT_UPDATE_INFO: 'Remplacer les données',
  TEXT_CONTENT_VISIBILITY_IS_DYNAMICALLY_CONTROLLED:
    "La visibilité du contenu est contrôlée dynamiquement en fonction de l'adresse de la page. Par défaut, le contenu est visible. Utilisez les conditions « afficher » et « masquer » pour personnaliser la visibilité.",
  TEXT_SHOW: 'Afficher',
  TEXT_OVERRIDES_HIDE_CONDITIONS_ENSURING_CONTENT_IS_VISIBLE_WHEN:
    "Remplace les conditions de masquage, garantissant que le contenu est visible lorsqu'il correspond.",
  TEXT_HIDE: 'Masquer',
  TEXT_MAKES_CONTENT_INVISIBLE_WHEN_ITS_CONDITIONS_MATCH:
    'Rend le contenu invisible lorsque ses conditions correspondent, sauf si une condition « afficher » le surpasse.',
  TEXT_PRIORITIZATION: 'Priorité',
  TEXT_WHEN_BOTH_SHOW_AND_HIDE_CONDITIONS_MATCH_SHOW:
    'Lorsque les conditions « afficher » et « masquer » correspondent toutes les deux, « afficher » prévaut, rendant le contenu visible.',
  TEXT_CONTAINS: 'Contient',
  TEXT_INCLUDES_SPECIFIC_TEXT: 'Inclut un texte spécifique.',
  TEXT_STARTS_WITH: 'Commence par',
  TEXT_BEGINS_WITH_SPECIFIC_TEXT: 'Commence par un texte spécifique.',
  TEXT_MATCHES: 'Correspond exactement',
  TEXT_EXACTLY_MATCHES_SPECIFIC_TEXT: 'Correspond exactement à un texte spécifique.',
  TEXT_ENDS_WITH: 'Se termine par',
  TEXT_ENDS_WITH_SPECIFIC_TEXT: 'Se termine par un texte spécifique.',
  TEXT_PROTOCOL_ARE_IGNORED_IN_COMPARISONS:
    'Le protocole (« http:// », « https:// ») et « www. » sont ignorés dans les comparaisons.',
  TEXT_ADD_CONDITION: 'Ajouter la condition',
  TEXT_URL_TARGETING: 'Ciblage par URL',
  WORD_LINK: 'Lien',
  WORD_SET: 'Appliquer',
  TEXT_INSTRUCTIONS_TRIGGER_SUBSCRIBER_GROWTH_MODAL:
    'Instructions - Déclencher la fenêtre modale de croissance des abonnés',
  TEXT_INCLUDE_THE_FOLLOWING_CODE_ON_THE_WEBSITE:
    'Inclure le code suivant sur le site web',
  TEXT_TO_ENABLE_SUBSCRIBER_GROWTH_MODAL_ON_YOUR_WEBSITE_INCLUDE_THE_FOLLOWING_CODE_SNIPPET:
    'Pour activer la fenêtre modale de croissance des abonnés sur votre site, incluez le fragment de code suivant',
  TEXT_THIS_CODE_SNIPPET_WILL_LOAD_THE_NECESSARY_SCRIPT_FOR_SUBSCRIBER_GROWTH:
    "Ce fragment de code chargera le script nécessaire pour la fenêtre modale de Croissance des abonnés et l'initialisera sur votre site web.",
  TEXT_TRIGGER_SUBSCRIBER_GROWTH_MODAL:
    'Déclencher la fenêtre modale de Croissance des abonnés',
  TEXT_SUBSCRIBER_GROWTH: 'subscriber-growth',
  TEXT_TO_TRIGGER_THE_SUBSCRIBER_GROWTH_MODAL_ADD_THE_CLASS:
    'Pour déclencher la fenêtre modale de croissance des abonnés, ajoutez la classe',
  TEXT_TO_ANY_HTML_ELEMENT_FOR_EXAMPLE:
    "à n'importe quel élément HTML. Par exemple:",
  TEXT_IM_INTERESTED: 'Je suis intéressé',
  TEXT_BY_ADDING_THE: 'En ajoutant la classe',
  TEXT_CLASS_TO_AN_HTML_ELEMENT_YOU_MAKE_IT_A_TRIGGER_FOR_OPENING_THE_SUBSCRIBER_GROWTH_MODAL:
    "à un élément HTML, vous en ferez un déclencheur pour ouvrir la fenêtre modale de croissance des abonnés. Vous pouvez personnaliser l'élément et l'apparence en fonction de vos besoins de conception.",
  TEXT_WE_RE_HERE_TO_HELP: 'Nous sommes là pour vous aider!',
  TEXT_IF_YOU_ENCOUNTER_ANY_ISSUES_OR_HAVE_QUESTIONS_ABOUT_THE_IMPLEMENTATION_CONTACT_OUR_SUPPORT_TEAM_AT:
    "Si vous avez des questions ou rencontrez des problèmes lors du processus d'intégration, veuillez contacter notre équipe de support à",
  TEXT_EACH_ORGANIZATION_SHOULD_HAVE_2_ENABLED_LANGUAGES:
    'Chaque organisation devrait avoir 2 langues activées',
  TEXT_1_OF_THE_SELECTED_LANGUAGES_SHOULD_BE_THE_DEFAULT:
    "L'une des langues devrait être la langue par défaut",
  WORD_AI_GENERAL_SETTINGS: "Paramètres généraux de l'IA",
  WORD_SUPERUMAN_AI_IS: 'Superhuman AI is active',
  WORD_INPUT_PLACEHOLDER: "Texte d'espace réservé",
  TEXT_THIS_EMAIL_ALREADY_IN_USE_FOR_INTEGRATE_YOUR_TOUCHPOINT_FEATURE_PLEASE_TRY_DIFFERENT_EMAIL:
    'Cette adresse e-mail est déjà utilisée pour la fonctionnalité « Intégrer votre point de contact ». Veuillez essayer une autre adresse e-mail',
  TEXT_THIS_EMAIL_ALREADY_IN_USE_FOR_SHARE_COPY_LEADS_ANOTHER_SERVICE_FEATURE_PLEASE_TRY_DIFFERENT_EMAIL:
    'Cette adresse e-mail est déjà utilisée pour la fonctionnalité « Partager une copie des leads avec un autre service ». Veuillez essayer une autre adresse e-mail.',
  TEXT_THIS_EMAIL_ALREADY_CONNECTED_ANOTHER_LOCATION_PLEASE_USE_ANOTHER_EMAIL_INTEGRATE_TOUCHPOINTS:
    'Cet email est déjà connecté à un autre emplacement. Veuillez utiliser un autre email pour intégrer les points de contact.',
  AI_CHAT: 'AI Chat',
  ENABLE_AI_CHAT: 'Activer le Chat AI',
  SUPERHUMAN_CHAT: 'Superhuman Chat',
  CHAT_CONVERSATION: 'Conversation de chat',
  TRANSCRIPT: 'Transcription',
  CHAT_SESSION_WITH: 'Session de chat avec',
  WORD_SOLD: 'Vendu',
  WORD_SERVICING: 'Entretien',
  NEW_FINANCE: 'Nouveau financement',
  EXISTING_FINANCE: 'Financement existant',
  APPOINTMENT_SET: 'Rendez-vous fixé',
  CONVERTED_SHOPPER: 'Acheteur converti',
  COMPLETED_CONVERSATION: 'Conversation terminée',
  APPOINTMENT_BOOKED: 'Rendez-vous réservé',
  TEXT_PLEASE_SEND_A_FIRST_TEXT_MESSAGE_BEFORE_INCLUDING_ANY_ATTACHMENTS:
    "Veuillez envoyer un premier message texte avant d'inclure des pièces jointes",
  TEXT_UNSUPPORTED_FILE_FORMAT: 'Format de fichier non pris en charge',
  TEXT_PASSWORD_MUST_CONTAIN_AT_LEAST:
    'Le mot de passe doit comporter au moins 8 caractères et contenir au moins : 1 lettre majuscule, 1 lettre minuscule, 1 chiffre et 1 caractère spécial',
  TEXT_VOICE_AI_APPOINTMENT_CONFIRMATION:
    'Confirmation de rendez-vous par l’IA d’appel',
  TEXT_COPY_SEVERAL_SEQUENCES: 'Copier plusieurs Séquences.',
  TEXT_CUSTOM_ASK_CONSENT_MESSAGES:
    'Messages de demande de consentement personnalisés',
  TEXT_USERA_WAS_ASSIGNED_FROM_THE_CONVERSATION_BY_USERB:
    '[userA] a été assigné à la conversation par [userB]',
  TEXT_USERA_WAS_UNASSIGNED_FROM_THE_CONVERSATION_BY_USERB:
    '[userA] a été désassigné de la conversation par [userB]',
  TEXT_USERA_WAS_UNASSIGNED_FROM_THE_CONVERSATION:
    '[userA] a été désassigné de la conversation',
  TEXT_USERA_WAS_ASSIGNED_FROM_THE_CONVERSATION:
    '[userA] a été assigné à la conversation',
  TEXT_PLEASE_PROVIDE_CONSENT_MESSAGES_FOR_EACH_LANGUAGE:
    'Veuillez fournir 3 messages de consentement pour chaque langue',
  TEXT_INVENTORY_INTEGRATION: "Intégration de l'inventaire",
  WORD_OWNED_VEHICLE: 'Véhicule possédé',
  WORD_IN_USE: 'Utilisé',
  WORD_ADD_NEW_VEHICLE: 'Ajouter un nouveau véhicule',
  WORD_VIN: 'NIV',
  TEXT_ENTER_A_CHARACTER_VIN_CODE: 'Saisissez un NIV de 17 caractères',
  WORD_CHOOSE_MAKE: 'Choisissez la marque',
  WORD_ENTER_MODEL: 'Entrez le modèle',
  WORD_CHOOSE_YEAR: "Choisissez l'année",
  WORD_MILEAGE: 'Kilométrage',
  WORD_SELECT_STATUS: 'Sélectionner le statut',
  TEXT_OWNED_VEHICLE_WAS_SUCCESSFULLY_ADDED:
    'Le véhicule possédé a été ajouté avec succès',
  TEXT_OWNED_VEHICLE_WAS_SUCCESSFULLY_UPDATED:
    'Le véhicule possédé a été mis à jour avec succès',
  TEXT_CUSTOM_INSTRUCTIONS: 'Instructions personnalisées',
  TEXT_ADD_SPECIAL_INSTRUCTIONS_FOR_THIS_INCOMING_CALL_AI_ASSISTANT:
    "Ajoutez des instructions spéciales pour cet assistant IA d'appel entrant",
  TEXT_POST_CALL_SMS_INSTRUCTIONS: "Instructions pour le texto après l'appel",
  TEXT_ADD_CUSTOM_INSTRUCTIONS_FOR_GENERATING_THE_FOLLOW_UP_SMS_FOR_YOUR_AI_AGENT:
    'Ajoutez des instructions personnalisées pour générer les SMS de suivi pour votre agent IA',
  TEXT_YOU_CURRENTLY_HAVE_NO_CREATED_LOCATIONS:
    "Vous n'avez actuellement aucune emplacements créée.",
  TEXT_ADD_VIN_CODE_OR_MAKE_AND_MODEL:
    'Ajouter le code NIV ou la marque et le modèle',
  TEXT_VIN_OR_MAKE_MODEL_AND_YEAR_ARE_REQUIRED:
    "Le NIV ou la marque, le modèle et l'année sont obligatoires",
  TEXT_EDIT_OWNED_VEHICLE: 'Modifier le véhicule possédé',
  WORD_SELECT_A_VEHICLE: 'Sélectionnez un véhicule',
  WORD_AVAILABLE: 'Accessible',
  TEXT_PLEASE_SELECT_STATUS: 'Veuillez sélectionner le statut',
  TEXT_MILEAGE_MUST_BE_MAXIMUM_CHARACTERS:
    'Le kilométrage doit être au maximum de [number]',
  TEXT_WE_HAVE_UPDATED_OUR_TERMS_CONDITIONS:
    'Nous avons mis à jour nos Termes et Conditions',
  TEXT_REMIND_ME_LATER: 'Rappelle-moi plus tard',
  TEXT_WE_HAVE_MADE_SOME_CHANGES_TO_OUR_TERMS_CONDITIONS:
    'Nous avons apporté quelques modifications à nos Termes et Conditions.',
  TEXT_PLEASE_REVIEW_THEM_STAY_INFORMED_THANK_YOU_FOR_BEING_MATADOR_CUSTOMER:
    'Veuillez les consulter pour rester informé. Merci d’être un client de Matador!',
  TEXT_PLEASE_SCROLL_DOWN_TO_ACCEPT_TERMS_CONDITIONS:
    'Veuillez faire défiler vers le bas pour accepter les termes et conditions',
  TEXT_I_ACCEPT_THE_TERMS_AND_CONDITIONS: "J'accepte les termes et conditions.",
  TEXT_PROFILE_CHANGES_SAVED_SUCCESSFULLY: 'Profile changes saved successfully.',
  TEXT_TERSM_AND_CONDITIONS_REMIND_ME_LATER:
    "Vous pouvez ignorer cela pour le moment, mais nous vous rappellerons dans une semaine. Veuillez noter qu'après une semaine, l'accomplissement de cette action sera requis. Le système affichera la même fenêtre contextuelle dans une semaine.",
  TEXT_SKIP_FOR_NOW: "Passer pour l'instant",
  TEXT_ACCEPT_LOGIN: 'Accepter et se connecter',
  WORD_ADD_NEW_GREETING: 'Ajouter un nouvel écran de bienvenue',
  WORD_EDIT_GREETING: "Modifier l'écran de bienvenue",
  WORD_GREETING_TEXT: 'Texte de salutation',
  WORD_INITIAL_BUTTON_MESSAGE: 'Message initial du bouton',
  WORD_ADDITIONAL_POPUP_MESSAGE: 'Message contextuel supplémentaire',
  WORD_IMAGE_OR_VIDEO: 'Image ou vidéo',
  TEXT_GREETING_PLACEHOLDER: "Bonjour! Comment puis-je vous aider aujourd'hui?",
  TEXT_INITIAL_BUTTON_PLACEHOLDER: 'Discutez avec nous',
  TEXT_ADDITIONAL_POPUP_PLACEHOLDER: 'Tapez votre message',
  GREETING_IMAGE_VIDEO_NOTE:
    "Faites glisser un fichier ici ou parcourez pour télécharger un fichier.\nTéléchargez une image ou une vidéo à afficher dans la fenêtre contextuelle.\nFormats d'image pris en charge : JPEG, JPG, PNG, GIF.\nFormats vidéo pris en charge : MP4, MOV, AVI, WEBM, QuickTime.\nLimites vidéo : Taille maximale : 20 Mo | Durée maximale : 7 secondes.\nVeuillez optimiser les images avant de les télécharger pour garantir des temps de chargement rapides.",
  ERROR_FILE_TOO_LARGE_20MB:
    'Veuillez essayer de télécharger le fichier de moins de 20 Mo',
  ERROR_YOU_NEED_TO_UPLOAD_IMAGE_OR_VIDEO:
    'Vous devez télécharger une image ou une vidéo',
  ERROR_PLEASE_INPUT_GREETING:
    'Veuillez saisir le texte de salutation en [language]',
  ERROR_PLEASE_INPUT_INITIAL_BUTTON_MSG:
    'Veuillez saisir le message initial du bouton en [language]',
  ERROR_PLEASE_INPUT_ADDITIONAL_POPUP_MSG:
    'Veuillez saisir le message contextuel supplémentaire en [language]',
  ACTION_EDIT_MEDIA: 'Modifier le média',
  MSG_ITEM_SAVED_SUCCESSFULLY: 'Élément enregistré avec succès',
  WELCOME_GREETINGS_VIEW: 'Écran de bienvenue',
  GREETINGS_CONFIGURATION: 'Configuration des salutations',
  SHOW_POPUP_ON_MOBILE: 'Afficher la fenêtre contextuelle sur mobile',
  LABEL_DESKTOP: 'Bureau',
  LABEL_MOBILE: 'Mobile',
  SHOW_MEDIA_ON_MOBILE: 'Afficher le média sur mobile',
  HI: 'Bonjour!',
  SHOW_INITIAL_BUTTON_TEXT_ON_MOBILE:
    'Afficher le texte du bouton initial sur mobile',
  AUTO_ASSIGNED_TO_NEW_GREETINGS:
    'Attribué automatiquement aux nouvelles salutations',
  WORD_DATE_OF_CREATE_UPDATE: 'Date de création / mise à jour',
  ERROR_FILE_VIDEO_TOO_LONG:
    'La durée de la vidéo dépasse la limite maximale autorisée',
  WORD_ACTIVE_SMS: 'SMS actifs',
  WORD_OUTBOUND: 'Sortant',
  MSG_ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_GREETING:
    'Êtes-vous sûr de vouloir supprimer cette salutation ?',
  MSG_GREETING_WAS_DELETED_SUCCESSFULLY:
    'La salutation a été supprimée avec succès.',
  TEXT_TIME_TO_UPDATE_YOUR_PASSWORD:
    'Il est temps de mettre à jour votre mot de passe',
  TEXT_THIS_ROUTINE_SECURITY_UPDATE_YOU_CAN_SKIP_NOW_ONE_WEEK_UPDATING_WILL_BE_REQUIRED:
    "Il s'agit d'une mise à jour de sécurité routine. Vous pouvez l'ignorer pour l'instant, mais dans une semaine, la mise à jour sera obligatoire.",
  TEXT_NOTHING_WORRY_ABOUT_STANDARD_SECURITY_PRACTICE_CHANGE_YOUR_PASSWORD_PLEASE_LOGOUT_FIRST:
    "Rien à craindre - c'est une pratique de sécurité standard. Pour changer votre mot de passe, veuillez d'abord vous déconnecter.",
  WORD_SCROLL_DOWN: 'Descendez',
  TEXT_USE_XTIME: 'Utiliser Xtime',
  TEXT_TYPE_OF_SERVICE: 'Type de service',
  TEXT_NO_AVAILABLE_SLOTS_FOR_THE_DAY_YOU_PICKED_PLEASE_TRY_ANOTHER_DATE:
    'Aucun créneau disponible pour la date que vous avez choisie. Veuillez essayer une autre date.',
}

export default texts
