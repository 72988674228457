import React from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { Flex } from 'antd'
import SVGDragDrop from '../../icons/SVG/SVGDragDrop'
import './MyDefaultDraggable.scss'

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

const MyDefaultDraggable = ({
  data,
  onChange,
  renderItem,
  customOnChange,
  getCustomKey,
}) => {
  const onDragEnd = (result) => {
    if (customOnChange) {
      customOnChange(result)
      return
    }
    if (!result.destination) {
      return
    }
    const items = reorder(data, result.source.index, result.destination.index)
    onChange?.(items)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='droppable' draggableId='draggableId'>
        {(provided, snapshot) => (
          <Flex
            vertical
            className='my_default_draggable_wrapper'
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {data.map((item, index) => {
              return (
                <Draggable
                  key={`${getCustomKey?.(item) || item._id || item.fieldKey}`}
                  draggableId={`${getCustomKey?.(item) || item._id || item.fieldKey}`}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <Flex
                      align='center'
                      gap={20}
                      className='my_default_draggable_item'
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <SVGDragDrop />
                      {renderItem(item, index)}
                    </Flex>
                  )}
                </Draggable>
              )
            })}
            {provided.placeholder}
          </Flex>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default MyDefaultDraggable
