import React, { forwardRef, useEffect, useState } from 'react'
import { getText } from '../../../../lang'
import MyDefaultSelect from '../../../../components/MyDefaultSelect/MyDefaultSelect'

const getDelayOptions = () => {
  const list = [
    { value: 'small', label: getText('WORD_SMALL') },
    { value: 'medium', label: getText('WORD_MEDIUM') },
    { value: 'large', label: getText('WORD_LARGE') },
  ]

  return list
}

const SelectVariant = forwardRef((props, ref) => {
  const [listData, setListData] = useState([])
  const [loadingData, setLoadingData] = useState(true)

  useEffect(() => {
    setLoadingData(true)
    setListData(
      getDelayOptions().map((oo) => {
        return { value: oo.value, label: oo.label }
      })
    )
    setLoadingData(false)
  }, [props.location])

  return (
    <MyDefaultSelect ref={ref} {...props} options={listData} loading={loadingData} />
  )
})

export default SelectVariant
