import React, { useCallback, useEffect, useMemo } from 'react'
import { CONNECT_URL } from '../../../../../config'
import useDeviceCheck from '../../../../../utils/useDeviceCheck'

const encodeCurrentUrl = encodeURI(window.location.href)

const FlowPreview = ({
  language = 'en',
  token,
  theme = 'default',
  position = 'relative',
  steps,
}) => {
  const { isPageDrawerSize } = useDeviceCheck()

  const getLanguage = useMemo(
    () => (language === 'es' ? 'sp' : language),
    [language]
  )

  const sendPreviewSettings = useCallback(() => {
    const iframe = document.getElementById(`iframe-preview-${theme}`)
    if (!iframe) {
      return
    }
    const data = {
      type: 'MATADOR-FLOW-DESIGNER-UPDATE',
      steps,
    }

    iframe.contentWindow.postMessage(data, '*')
  }, [theme, steps])

  useEffect(() => {
    sendPreviewSettings()
  }, [sendPreviewSettings])

  useEffect(() => {
    sendPreviewSettings()
  }, [sendPreviewSettings])

  if (!token) {
    return null
  }

  const onIframeLoad = () => {
    sendPreviewSettings()
  }

  return (
    <iframe
      title='Matador Connect Preview'
      id={`iframe-preview-default`}
      height={`600px`}
      width={isPageDrawerSize ? '100%' : '400px'}
      frameBorder='none'
      onLoad={onIframeLoad}
      src={`${CONNECT_URL}/wiz_widget/theme=default/${token}/${getLanguage}?url=${encodeCurrentUrl}`}
      style={{ position }}
      allow='geolocation'
    ></iframe>
  )
}

export default FlowPreview
