import React, { useEffect, useState } from 'react'
import { Flex } from 'antd'
import { useSelector } from 'react-redux'
import authActions from '../../../../../store/modules/authActions'
import locationActions from '../../../../../store/modules/locationActions'
import { notifyError } from '../../../../../utils/Notify'
import { getText } from '../../../../../lang'
import { userSelector } from '../../../../../store/selectors/selectors'
import MyDefaultIntegrationBlockFooter from '../../../../../components/MyDefaultIntegrationBlock/MyDefaultIntegrationBlockFooter/MyDefaultIntegrationBlockFooter'
import MyDefaultSwitch from '../../../../../components/MyDefaultSwitch/MyDefaultSwitch'
import utilObject from '../../../../../utils/utilObject'
import Div from '../../../../../components/Div/Div'
import AuthButtons from './FacebookAuth'
import FacebookIntegrationModal from './FacebookIntegrationModal'

const FacebookIntegration = ({
  locationId,
  facebookConnection = {},
  featuresConnectingData,
}) => {
  const user = useSelector(userSelector)

  const [isLoading, setIsLoading] = useState(false)
  const [account, setAccount] = useState({})
  const [fbData, setFbData] = useState({ pages: [], cursor: '', next: false })
  const [selectedPage, setSelectedPage] = useState({})
  const [selectedAccountType, setselectedAccountType] = useState()
  const [visible, setVisible] = useState(false)
  const [accountTypeOptions, setaccountTypeOptions] = useState([])

  useEffect(() => {
    const fillAccountTypes = async () => {
      setIsLoading(true)
      const result = await locationActions.getFacebookBusinessAccounts(locationId)
      if (result.success) {
        setaccountTypeOptions([
          {
            label: getText('TEXT_PERSONAL_FACEBOOK_ACCOUNT'),
            value: 'personal_accounts',
          },
          ...(result.data.accounts || []).map((acc) => {
            acc.value = acc.id
            acc.label = acc.name
            return { ...acc }
          }),
        ])
      }
      setIsLoading(false)
    }
    if (account?.isConnected) {
      fillAccountTypes()
    }
  }, [account?.isConnected])

  useEffect(() => {
    if (facebookConnection) {
      let selectedAccType = 'personal_accounts'
      if (
        facebookConnection?.facebookPage?.isBusinessPage &&
        facebookConnection.facebookPage?.facebook_business_account
      ) {
        selectedAccType =
          facebookConnection.facebookPage.facebook_business_account.id
      }
      setselectedAccountType(selectedAccType)
      setAccount(facebookConnection.facebookAccount || {})
      setSelectedPage(facebookConnection.facebookPage || {})
    }
  }, [])

  useEffect(() => {
    const getFacebookPages = async () => {
      setIsLoading(true)
      let result

      if (selectedAccountType === 'personal_accounts') {
        result = await locationActions.getFacebookAccountPages(locationId)
      } else {
        result = await locationActions.getFacebookBusinessAccountPages(
          locationId,
          selectedAccountType
        )
      }

      if (result.success) {
        const { pages = [], cursor = '', next = false } = result.data
        setFbData({
          pages,
          cursor,
          next,
        })
      }

      setIsLoading(false)
    }

    if (selectedAccountType && account?.isConnected) {
      getFacebookPages()
    }
  }, [selectedAccountType, account?.isConnected])

  const handleLogout = async () => {
    window.FB.logout()
    if (account.isConnected) {
      let result = await authActions.disconnectFacebook(locationId)
      if (result.success) {
        featuresConnectingData.FB.isConnected =
          result.data.facebookAccount.isConnected
        featuresConnectingData.FB.userData.fullName = user.fullName
        setSelectedPage({})
        setAccount(result.data.facebookAccount)
      }
    }
  }

  const handleLoginResponse = async (response) => {
    if (response) {
      if (response.status && response.status === 'unknown') {
        setTimeout(() => {
          notifyError(
            getText(
              'TEXT_THERE_WAS_AN_ISSUE_CONNECTING_YOUR_ACCOUNT_PLEASE_TRY_AGAIN'
            )
          )
        }, 30000)
        return
      }
      let result = await authActions.connectFacebook(response, locationId)
      if (result.success) {
        if (featuresConnectingData && featuresConnectingData.FB) {
          featuresConnectingData.FB.isConnected =
            result.data.facebookAccount.isConnected
          featuresConnectingData.FB.userData.fullName = user.fullName
        }
        setAccount(result.data.facebookAccount)
        setVisible(true)
      }
    }
  }

  const handleSelectPage = async (pageId) => {
    const pageObj = fbData.pages.find((page) => page.id === pageId)
    if (selectedAccountType !== 'personal_accounts') {
      const selectedBusinessAcc = accountTypeOptions.find(
        (acc) => acc.id === selectedAccountType
      )
      pageObj.isBusinessPage = true
      pageObj.facebookBusinessAccount = {
        id: selectedBusinessAcc.id,
        name: selectedBusinessAcc.name,
      }
    }
    let result = await locationActions.setFacebookPages(pageObj, locationId)
    if (result.success) {
      setSelectedPage(
        Object.assign(result.data.facebookPage, {
          instagram_business_account:
            result.data.facebookPage.instagramBusinessAccount,
        }) || {}
      )
    } else {
      notifyError(result.errMsg)
    }
  }

  return (
    <React.Fragment>
      <MyDefaultIntegrationBlockFooter
        enabled={featuresConnectingData?.FB?.isConnected}
        connectedBy={featuresConnectingData?.FB?.userData?.fullName}
        customLeft={
          selectedPage.id &&
          fbData.pages.length > 0 && (
            <MyDefaultSwitch
              defaultValue={facebookConnection.isMessengerEnabled}
              textAfterSwitch={getText('ACTION_ENABLE_MESSENGER')}
              noText
              isLast
              isFirst
              onChangeSwitch={async (value) =>
                await locationActions.switchFacebookMessenger(locationId, value)
              }
            />
          )
        }
        customButton={
          <Flex align='center' gap={10}>
            {account.isConnected && (
              <FacebookIntegrationModal
                selectedPage={selectedPage}
                visible={visible}
                setVisible={setVisible}
                selectedAccountType={selectedAccountType}
                isLoading={isLoading}
                accountTypeOptions={accountTypeOptions}
                setselectedAccountType={setselectedAccountType}
                account={account}
                fbData={fbData}
                handleSelectPage={handleSelectPage}
                setSelectedPage={setSelectedPage}
                facebookConnection={facebookConnection}
                locationId={locationId}
              />
            )}
            <AuthButtons
              handleLogout={handleLogout}
              handleLoginResult={handleLoginResponse}
              isConnected={account.isConnected}
            />
          </Flex>
        }
      />
      {!utilObject.isEmpty(selectedPage) && selectedPage?.id && (
        <Flex
          className='my_default_integration_block--footer'
          align='center'
          gap={10}
          style={{ marginTop: 10 }}
        >
          {selectedPage?.name && (
            <Div className={'my_default_integration_block--footer-left'}>
              <span>{getText('WORD_CONNECTED_PAGE_FB')}</span>
              <span>{selectedPage.name}</span>
            </Div>
          )}
          <br />
          {selectedPage?.instagram_business_account?.name && (
            <Div className={'my_default_integration_block--footer-left'}>
              <span>{getText('WORD_CONNECTED_PAGE_IG')}</span>
              <span>{selectedPage.instagram_business_account.name}</span>
            </Div>
          )}
        </Flex>
      )}
    </React.Fragment>
  )
}

export default FacebookIntegration
