import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  isAdminSelector,
  isSuperAdminSelector,
} from '../../../store/selectors/selectors'
import { getText } from '../../../lang'
import SVGLocLivechat from '../../../icons/SVG/SVGLocLivechat'
import SVGLocPromotions from '../../../icons/SVG/SVGLocPromotions'
import SVGLocReview from '../../../icons/SVG/SVGLocReview'
import SVGAppointmentDateIcon from '../../../icons/SVG/SVGAppointmentDateIcon'
import SVGLocTextMe from '../../../icons/SVG/SVGLocTextMe'
import SVGLocationEditIcon from '../../../icons/SVG/SVGLocationEditIcon'
import SVGLocationIntegrationIcon from '../../../icons/SVG/SVGLocationIntegrationIcon'
import useDeviceCheck from '../../../utils/useDeviceCheck'
import MyDefaultInsidePageLeftPanel from '../../../components/MyDefaultInsidePagePanelLayout/MyDefaultInsidePageLeftPanel/MyDefaultInsidePageLeftPanel'
import MyDefaultDropdown from '../../../components/MyDefaultDropdown/MyDefaultDropdown'
import Div from '../../../components/Div/Div'

const baseUrl = '/location/settings'

const LocationMenu = ({ isNewLocation }) => {
  const { _id } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  const { isTabletPortraitOrMobile } = useDeviceCheck()

  const isAdmin = useSelector(isAdminSelector)
  const isSuperAdmin = useSelector(isSuperAdminSelector)

  const [selectedRoute, setSelectedRoute] = useState([])

  useEffect(() => {
    if (location.pathname) {
      let newUrlPath = location.pathname
      if (location.pathname.includes('/livechat-greetings')) {
        newUrlPath = `/location/settings/${_id}/livechat-greetings`
      }
      if (location.pathname.includes('/livechat-stories')) {
        newUrlPath = `/location/settings/${_id}/livechat-stories`
      }
      setSelectedRoute(newUrlPath)
    }
  }, [location.pathname])

  const subMenuMatadorConnect = useMemo(() => {
    return [
      {
        key: `${baseUrl}/${_id}/livechat-main`,
        label: getText('WORD_SETTINGS'),
      },
      {
        key: `${baseUrl}/${_id}/livechat-appearance`,
        label: getText('WORD_APPEARANCE'),
      },
      {
        key: `${baseUrl}/${_id}/livechat-questions`,
        label: getText('WORD_QUESTIONS'),
      },
      {
        key: `${baseUrl}/${_id}/livechat-greetings`,
        label: getText('WORD_GREETINGS'),
        children: [
          {
            key: `/location/settings/${_id}/livechat-greetings`,
            label: getText('GREETINGS_CONFIGURATION'),
          },
          {
            key: `/location/settings/${_id}/welcome-greetings`,
            label: getText('WELCOME_GREETINGS_VIEW'),
          },
        ],
        icon: <SVGLocTextMe width='20' heigth='22' color='transparent' />,
      },
      {
        key: `${baseUrl}/${_id}/livechat-stories`,
        label: getText('WORD_STORIES'),
      },
      ...(isSuperAdmin
        ? [
            {
              key: `${baseUrl}/${_id}/flow-designer`,
              label: getText('WORD_FLOW_DESIGNER'),
            },
          ]
        : []),
    ]
  }, [_id, isSuperAdmin])

  const isMatadorConnectSelected = useCallback(() => {
    return !!subMenuMatadorConnect.find((x) => x.key === selectedRoute)
  }, [selectedRoute])

  const isLeadGenSelected = useCallback(() => {
    return !!subMenuCta.find((x) => x.key === selectedRoute)
  }, [selectedRoute])

  const isPromotionsSelected = useCallback(() => {
    return !!subMenuPromotions.find((x) => x.key === selectedRoute)
  }, [selectedRoute])

  const subMenuCta = useMemo(() => {
    return [
      {
        key: `${baseUrl}/${_id}/cta/mobile-cta`,
        label: getText('WORD_MOBILE_CTA'),
      },
      {
        key: `${baseUrl}/${_id}/cta/integrated-cta`,
        label: getText('INTEGRATED_CTA'),
      },
      {
        key: `${baseUrl}/${_id}/subsrciber-growth`,
        label: getText('SUBSCRIBER_GROWTH'),
      },
    ]
  }, [_id])

  const subMenuPromotions = useMemo(() => {
    return [
      {
        key: `${baseUrl}/${_id}/promotions/mobileCoupon`,
        label: 'Coupon',
      },
      {
        key: `${baseUrl}/${_id}/promotions/exitIntent`,
        label: getText('WORD_EXIT_INTENT'),
      },
    ]
  }, [_id])

  const RespSubMenuMatadorConnect = () => {
    const items = [
      {
        key: `${baseUrl}/${_id}/livechat-main`,
        label: (
          <Div
            // className={`drop-item ${
            //   selectedRoute.includes('livechat-main') ? 'active' : ''
            // }`}
            onClick={() => {
              navigate(`${baseUrl}/${_id}/livechat-main`)
              setSelectedRoute(`${baseUrl}/${_id}/livechat-main`)
            }}
          >
            {getText('WORD_SETTINGS')}
          </Div>
        ),
      },
      {
        key: `${baseUrl}/${_id}/livechat-appearance`,
        label: (
          <Div
            // className={`drop-item ${
            //   selectedRoute.includes('livechat-appearance') ? 'active' : ''
            // }`}
            onClick={() => {
              navigate(`${baseUrl}/${_id}/livechat-appearance`)
              setSelectedRoute(`${baseUrl}/${_id}/livechat-appearance`)
            }}
          >
            {getText('WORD_APPEARANCE')}
          </Div>
        ),
      },
      {
        key: `${baseUrl}/${_id}/livechat-questions`,
        label: (
          <Div
            // className={`drop-item ${
            //   selectedRoute.includes('livechat-questions') ? 'active' : ''
            // }`}
            onClick={() => {
              navigate(`${baseUrl}/${_id}/livechat-questions`)
              setSelectedRoute(`${baseUrl}/${_id}/livechat-questions`)
            }}
          >
            {getText('WORD_QUESTIONS')}
          </Div>
        ),
      },
      {
        key: `${baseUrl}/${_id}/livechat-greetings`,
        label: (
          <Div
            // className={`drop-item ${
            //   selectedRoute.includes('livechat-greetings') ? 'active' : ''
            // }`}
            onClick={() => {
              navigate(`${baseUrl}/${_id}/livechat-greetings`)
              setSelectedRoute(`${baseUrl}/${_id}/livechat-greetings`)
            }}
          >
            {getText('GREETINGS_CONFIGURATION')}
          </Div>
        ),
      },
      {
        key: `${baseUrl}/${_id}/welcome-greetings`,
        label: (
          <Div
            onClick={() => {
              navigate(`${baseUrl}/${_id}/welcome-greetings`)
              setSelectedRoute(`${baseUrl}/${_id}/welcome-greetings`)
            }}
          >
            {getText('WELCOME_GREETINGS_VIEW')}
          </Div>
        ),
      },
      {
        key: `${baseUrl}/${_id}/livechat-stories`,
        label: (
          <Div
            // className={`drop-item ${
            //   selectedRoute.includes('livechat-stories') ? 'active' : ''
            // }`}
            onClick={() => {
              navigate(`${baseUrl}/${_id}/livechat-stories`)
              setSelectedRoute(`${baseUrl}/${_id}/livechat-stories`)
            }}
          >
            {getText('WORD_STORIES')}
          </Div>
        ),
      },
      {
        key: `${baseUrl}/${_id}/flow-designer`,
        label: (
          <Div
            // className={`drop-item ${
            //   selectedRoute.includes('flow-designer') ? 'active' : ''
            // }`}
            onClick={() => {
              navigate(`${baseUrl}/${_id}/flow-designer`)
              setSelectedRoute(`${baseUrl}/${_id}/flow-designer`)
            }}
          >
            {getText('WORD_FLOW_DESIGNER')}
          </Div>
        ),
      },
    ]
    return (
      <MyDefaultDropdown items={items} placement={'bottom'}>
        <SVGLocLivechat
          // color={isMatadorConnectSelected() ? '#7854F6' : '#292D32'}
          width='22'
          heigth='22'
        />
      </MyDefaultDropdown>
    )
  }

  const RespSubMenuCta = () => {
    const items = [
      {
        key: `${baseUrl}/${_id}/cta/mobile-cta`,
        label: (
          <Div
            // className={`drop-item ${
            //   selectedRoute.includes('mobile-cta') ? 'active' : ''
            // }`}
            onClick={() => {
              navigate(`${baseUrl}/${_id}/cta/mobile-cta`)
              setSelectedRoute(`${baseUrl}/${_id}/cta/mobile-cta`)
            }}
          >
            {getText('WORD_MOBILE_CTA')}
          </Div>
        ),
      },
      {
        key: `${baseUrl}/${_id}/cta/integrated-cta`,
        label: (
          <Div
            // className={`drop-item ${
            //   selectedRoute.includes('integrated-cta') ? 'active' : ''
            // }`}
            onClick={() => {
              navigate(`${baseUrl}/${_id}/cta/integrated-cta`)
              setSelectedRoute(`${baseUrl}/${_id}/cta/integrated-cta`)
            }}
          >
            {getText('INTEGRATED_CTA')}
          </Div>
        ),
      },
      {
        key: `${baseUrl}/${_id}/subsrciber-growth`,
        label: (
          <Div
            // className={`drop-item ${
            //   selectedRoute.includes('subsrciber-growth') ? 'active' : ''
            // }`}
            onClick={() => {
              navigate(`${baseUrl}/${_id}/subsrciber-growth`)
              setSelectedRoute(`${baseUrl}/${_id}/subsrciber-growth`)
            }}
          >
            {getText('SUBSCRIBER_GROWTH')}
          </Div>
        ),
      },
    ]
    return (
      <MyDefaultDropdown items={items} placement={'bottom'}>
        <SVGLocTextMe width='22' heigth='22' color='#292D32' />
      </MyDefaultDropdown>
    )
  }

  const RespSubMenuPromotions = () => {
    const items = [
      {
        key: `${baseUrl}/${_id}/promotions/mobileCoupon`,
        label: (
          <Div
            // className={`drop-item ${
            //   selectedRoute.includes('mobileCoupon') ? 'active' : ''
            // }`}
            onClick={() => {
              navigate(`${baseUrl}/${_id}/promotions/mobileCoupon`)
              setSelectedRoute(`${baseUrl}/${_id}/promotions/mobileCoupon`)
            }}
          >
            Coupon
          </Div>
        ),
      },
      {
        key: `${baseUrl}/${_id}/promotions/exitIntent`,
        label: (
          <Div
            // className={`drop-item ${
            //   selectedRoute.includes('exitIntent') ? 'active' : ''
            // }`}
            onClick={() => {
              navigate(`${baseUrl}/${_id}/promotions/exitIntent`)
              setSelectedRoute(`${baseUrl}/${_id}/promotions/exitIntent`)
            }}
          >
            {getText('WORD_EXIT_INTENT')}
          </Div>
        ),
      },
    ]
    return (
      <MyDefaultDropdown items={items} placement={'bottom'}>
        <SVGLocPromotions width='22' heigth='22' color='#292D32' />
      </MyDefaultDropdown>
    )
  }

  const items = useMemo(() => {
    return isNewLocation
      ? [
          {
            key: `${baseUrl}/new`,
            label: getText('ACTION_CREATE_LOCATION'),
            icon: <SVGLocationEditIcon />,
          },
        ]
      : [
          {
            key: `${baseUrl}/${_id}/edit`,
            label: getText('ACTION_EDIT_LOCATION'),
            icon: <SVGLocationEditIcon />,
          },
          isAdmin && {
            key: `${baseUrl}/${_id}/integration`,
            label: getText('WORD_INTEGRATIONS'),
            icon: <SVGLocationIntegrationIcon />,
          },
          {
            key: `${baseUrl}/${_id}/review`,
            label: getText('ACTION_REVIEW_INVITE'),
            icon: <SVGLocReview color='#292D32' />,
          },
          {
            key: `${baseUrl}/${_id}/calendar`,
            label: getText('TEXT_CALENDAR_INVITE'),
            icon: <SVGAppointmentDateIcon width='22' heigth='22' color='#292D32' />,
          },
          isTabletPortraitOrMobile
            ? {
                icon: <RespSubMenuCta />,
                label: getText('WORD_LEAD_GEN_WIZARD'),
                key: 'cta',
                className: isLeadGenSelected() ? 'active' : '',
              }
            : {
                key: `cta`,
                label: getText('WORD_LEAD_GEN_WIZARD'),
                icon: <SVGLocTextMe width='22' heigth='22' color='#292D32' />,
                children: subMenuCta,
              },
          isTabletPortraitOrMobile
            ? {
                icon: <RespSubMenuMatadorConnect />,
                label: getText('TEXT_MATADOR_CONNECT'),
                key: 'matador-connect',
                className: isMatadorConnectSelected() ? 'active' : '',
              }
            : {
                key: `matador-connect`,
                label: getText('TEXT_MATADOR_CONNECT'),
                icon: <SVGLocLivechat width='22' heigth='22' />,
                children: subMenuMatadorConnect,
              },
          isTabletPortraitOrMobile
            ? {
                icon: <RespSubMenuPromotions />,
                label: getText('WORD_PROMOTIONS'),
                key: 'promotions',
                className: isPromotionsSelected() ? 'active' : '',
              }
            : {
                key: `promotions`,
                label: getText('WORD_PROMOTIONS'),
                icon: <SVGLocPromotions width='22' heigth='22' color='#292D32' />,
                children: subMenuPromotions,
              },
        ]
  }, [
    isNewLocation,
    isTabletPortraitOrMobile,
    subMenuMatadorConnect,
    subMenuPromotions,
    isMatadorConnectSelected,
    isLeadGenSelected,
    isPromotionsSelected,
  ])

  const handleClick = (e) => {
    if (e.key === 'cta' || e.key === 'matador-connect' || e.key === 'promotions') {
      return
    }
    navigate(e.key)
    setSelectedRoute(e.key)
  }

  return (
    <MyDefaultInsidePageLeftPanel
      onClick={handleClick}
      selectedRoute={selectedRoute}
      navigationMenuIsOnTop={isTabletPortraitOrMobile}
      items={items}
      forceSubMenuRender
      defaultOpenKeys={['matador-connect', 'promotions', 'cta']}
    />
  )
}

export default LocationMenu
