import React, { useRef, useState } from 'react'
import { Divider, Flex } from 'antd'
import SVGMyDefaultDeleteIcon from '../../../../icons/SVG/SVGMyDefaultDeleteIcon'
import ConfirmPopup from '../../../../components/ConfirmPopup'
import MyDefaultInsidePageTabs from '../../../../components/MyDefaultInsidePageTabs/MyDefaultInsidePageTabs'
import { getLongName } from '../../../../utils'
import MyDefaultTextarea from '../../../../components/MyDefaultTextarea/MyDefaultTextarea'
import { getText } from '../../../../lang'
import MyDefaultAddVariablesInMessage from '../../../../components/MyDefaultAddVariablesInMessage/MyDefaultAddVariablesInMessage'

const CustomAskConsentMessagesItem = ({
  consentMessageList,
  remove,
  index,
  field,
  languages,
  listVariables,
  fields,
  form,
}) => {
  const [selectedLang, setSelectedLang] = useState(languages?.[0])
  const [currentCursorPosition, setCurrentCursorPosition] = useState(0)

  const textAreaInputRef = useRef(null)

  const handleClickVariable = (variable, selectedIndex) => {
    const currentText = consentMessageList[selectedIndex][selectedLang]
    const newText = [
      currentText && currentText.slice(0, currentCursorPosition),
      variable,
      currentText && currentText.slice(currentCursorPosition),
    ].join('')

    consentMessageList[selectedIndex][selectedLang] = newText
    form.setFieldsValue({ consentMessage: consentMessageList })
    setCurrentCursorPosition(currentCursorPosition + variable.length)

    const inputRef = textAreaInputRef
    inputRef.current &&
      inputRef.current.autoFocus(currentCursorPosition + variable.length)
  }

  return (
    <React.Fragment key={field.key}>
      {consentMessageList?.length > 3 && (
        <ConfirmPopup
          title={getText('MSG_DELETE_AI_BOT_MESSAGE_ITEM')}
          placement={'topRight'}
          onConfirm={(e) => {
            e.stopPropagation()
            remove(index)
          }}
          okText={getText('WORD_YES')}
          cancelText={getText('WORD_NO')}
          trigger={
            <Flex justify='flex-end'>
              <SVGMyDefaultDeleteIcon style={{ cursor: 'pointer' }} />
            </Flex>
          }
        />
      )}
      <MyDefaultInsidePageTabs
        key={field.key}
        onChange={(key) => {
          setSelectedLang(languages[key])
        }}
        items={languages.map((lang, index) => {
          return {
            key: index,
            forceRender: true,
            label: getLongName(lang),
            children: (
              <React.Fragment key={index}>
                <MyDefaultTextarea
                  isForm
                  ref={textAreaInputRef}
                  name={[field.name, selectedLang]}
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  placeholder={getText('WORD_MESSAGE')}
                  onClick={(e) => setCurrentCursorPosition(e.target.selectionStart)}
                  onKeyUp={(e) => setCurrentCursorPosition(e.target.selectionStart)}
                  onChange={(e) => setCurrentCursorPosition(e.target.selectionStart)}
                />
                <MyDefaultAddVariablesInMessage
                  disableMargin
                  onClick={(variable) => handleClickVariable(variable, field.name)}
                  data={listVariables}
                />
              </React.Fragment>
            ),
          }
        })}
      />
      {field.key !== fields.length - 1 && <Divider />}
    </React.Fragment>
  )
}

export default CustomAskConsentMessagesItem
