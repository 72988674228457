import React from 'react'
import { useSelector } from 'react-redux'
import { userSelector } from '../../../../../store/selectors/selectors'
import MyDefaultButton from '../../../../../components/MyDefaultButton/MyDefaultButton'
import { getText } from '../../../../../lang'
import ConfirmPopup from '../../../../../components/ConfirmPopup'

const NylastConnection = ({ location, disconnectNylasAccount }) => {
  const user = useSelector(userSelector)

  return !Boolean(user.isSuperAdmin && user.isAdmin) ? (
    <MyDefaultButton
      buttonText={getText('WORD_DISCONNECT')}
      htmlType='button'
      style={{ maxWidth: '190px', cursor: 'not-allowed' }}
      typeButton={'red'}
      tooltip
      tooltipTitle={getText('TEXT_PLEASE_CONTACT_YOUR_ADMINISTRATOR')}
    />
  ) : (
    <ConfirmPopup
      title={`${getText(
        'TEXT_ARE_YOU_SURE_YOU_WANT_TO_DISCONNECT_EMAIL'
      )}: ${location.integrationInfo.nylas.email_address}?`}
      okText={getText('WORD_DISCONNECT')}
      onConfirm={disconnectNylasAccount}
      cancelText={getText('WORD_NO')}
      trigger={
        <MyDefaultButton
          buttonText={getText('WORD_DISCONNECT')}
          htmlType='button'
          style={{ maxWidth: '190px' }}
          typeButton={'red'}
        />
      }
    />
  )
}

export default NylastConnection
