import {
  fetchFromUrlDELETEAsync,
  fetchFromUrlGETAsync,
  fetchFromUrlPATCHAsync,
  fetchFromUrlPOSTAsync,
  fetchFromUrlPUTAsync,
} from '../../utils/UrlHelper'

const urlOrganizations = '/organizations'
const urlSequences = '/sequences'
const urlSequencesByGroupAll = '/sequences/group'
const urlSequencesTemplate = '/sequence-templates'
const urlPayments = '/payments'
const urlOrgLoc = '/organizations/locations'
const urlTransferSettings = '/organizations/copy-settings'

const fs = {
  getPageList: async (start, range, searchKeyword = '', paginationOptions = {}) => {
    if (
      paginationOptions.filters &&
      paginationOptions.filters.hasOwnProperty('enabled')
    ) {
      paginationOptions.enabled = paginationOptions.filters.enabled
    }

    delete paginationOptions.filters
    delete paginationOptions.filterSelectors

    const urlParams = Object.keys(paginationOptions).map((key) => {
      return { key, value: paginationOptions[key] }
    })

    urlParams.push({ key: 'page', value: Math.ceil(start + 1) })
    urlParams.push({ key: 'perPage', value: range })
    if (searchKeyword) {
      urlParams.push({ key: 'search', value: searchKeyword })
    }
    const result = await fetchFromUrlGETAsync(urlOrganizations, urlParams)
    if (result.success) {
      result.max = result.data.total_items
      result.data = result.data.organizations
    }
    return result
  },
  getSingle: async (itemId) =>
    fetchFromUrlGETAsync(urlOrganizations + '/' + itemId, []),
  getSingleSequence: (sequence_id) =>
    fetchFromUrlGETAsync(urlSequences + '/' + sequence_id),
  saveNew: async (obj) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    return fetchFromUrlPOSTAsync(urlOrganizations, urlParams)
  },
  saveUpdate: async (obj, id) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    return fetchFromUrlPUTAsync(urlOrganizations + '/' + id, urlParams)
  },
  setAutoReply: async (obj, id) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    return fetchFromUrlPATCHAsync(
      urlOrganizations + '/' + id + '/auto-reply',
      urlParams
    )
  },
  setBroadcasting: async (obj, id) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    return fetchFromUrlPATCHAsync(
      urlOrganizations + '/' + id + '/broadcast',
      urlParams
    )
  },
  delete: async (id) => fetchFromUrlDELETEAsync(urlOrganizations + '/' + id, []),

  saveNewSequence: async (obj) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    return fetchFromUrlPOSTAsync(urlSequences, urlParams)
  },
  saveUpdateSequence: async (obj, id) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    return fetchFromUrlPUTAsync(urlSequences + '/' + id, urlParams)
  },
  transferSettings: async (obj) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    return fetchFromUrlPOSTAsync(urlTransferSettings, urlParams)
  },
  saveNewTemplet: async (obj, organization_id) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    return fetchFromUrlPOSTAsync(
      urlOrganizations + '/' + organization_id + '/templates',
      urlParams
    )
  },
  saveUpdateTemplet: async (obj, id, organization_id) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    const url = urlOrganizations + '/' + organization_id + '/templates/' + id
    return fetchFromUrlPATCHAsync(url, urlParams)
  },
  deleteTemplet: async (id) =>
    fetchFromUrlDELETEAsync(urlOrganizations + '/templates/' + id, []),

  getPageListTemplets: async (
    start,
    range,
    organization_id,
    search = '',
    templateType
  ) => {
    const urlParams = [
      { key: 'page', value: start + 1 },
      { key: 'perPage', value: range },
      { key: 'type', value: templateType },
    ]
    if (search) {
      urlParams.push({ key: 'search', value: search })
    }

    const result = await fetchFromUrlGETAsync(
      urlOrganizations + '/' + organization_id + '/templates',
      urlParams
    )
    if (result.success) {
      result.max = result.data.total_items
      result.data = result.data.templates
    }
    return result
  },
  saveUpdateSequenceAutoReplayMessege: async (obj, id) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    return fetchFromUrlPUTAsync(urlSequences + '/auto-reply/' + id, urlParams)
  },
  getPageListSequence: async (start, range, organization_id) => {
    const urlParams = [
      { key: 'page', value: start + 1 },
      { key: 'perPage', value: range },
      { key: '_organization_id', value: organization_id },
    ]

    const result = await fetchFromUrlGETAsync(urlSequences, urlParams)
    if (result.success) {
      result.max = result.data.total_items
      result.data = result.data.sequences
    }

    return result
  },
  getPageListSequenceByGroupAll: async (organization_id) => {
    const urlParams = [{ key: '_organization_id', value: organization_id }]

    const result = await fetchFromUrlGETAsync(urlSequencesByGroupAll, urlParams)
    // if (result.success) {
    //   result.max = result.data.total_items
    //   result.data = result.data.sequences
    // }

    return result
  },
  saveUpdateAutoAssignSequencesForNewTouchpoint: async (
    organization_id,
    key,
    sequence_id
  ) => {
    const body = [{ key: key, value: [sequence_id] }]
    const result = fetchFromUrlPUTAsync(
      urlOrganizations + '/' + organization_id + '/lead-forms/auto-assign',
      body
    )
    return result
  },
  getSequenceSourceList: async (
    organization_id,
    existing_sources = true,
    is_broadcast_segment_creation = false
  ) => {
    const urlParams = [
      { key: '_organization_id', value: organization_id },
      { key: 'existing_sources', value: existing_sources },
    ]
    if (is_broadcast_segment_creation) {
      urlParams.push({
        key: 'is_broadcast_segment_creation',
        value: is_broadcast_segment_creation,
      })
    }
    return await fetchFromUrlGETAsync(urlSequences + '/sources/list', urlParams)
  },
  getSequenceSourceListForUpdate: async (sequence_id) => {
    return await fetchFromUrlGETAsync(urlSequences + '/sources/' + sequence_id, [])
  },
  getSequenceNewSourceList: async (type) => {
    const urlParams = [{ key: 'type', value: type }]

    return await fetchFromUrlGETAsync(urlSequences + '/sources/available', urlParams)
  },
  getSequenceTemplateNewSourceList: async (type) => {
    const urlParams = [{ key: 'type', value: type }]

    return await fetchFromUrlGETAsync(
      urlSequencesTemplate + '/sources/available',
      urlParams
    )
  },
  deleteSequence: async (id) => fetchFromUrlDELETEAsync(urlSequences + '/' + id, []),

  getListTouchpoint: async (start, range, organization_id, searchKeyword) => {
    const urlParams = [
      { key: 'page', value: start + 1 },
      { key: 'perPage', value: range },
    ]

    if (searchKeyword) {
      urlParams.push({ key: 'search', value: searchKeyword })
    }

    const result = await fetchFromUrlGETAsync(
      urlOrganizations + '/' + organization_id + '/lead-forms',
      urlParams
    )
    return result
  },
  updateForwardLead: async (obj, id) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    const result = await fetchFromUrlPATCHAsync(
      urlOrganizations + '/lead-forms/' + id + '/forward',
      urlParams
    )
    return result
  },
  saveUpdateTouchpoint_is_consented: async (obj, id) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    const result = await fetchFromUrlPATCHAsync(
      urlOrganizations + '/lead-forms/' + id,
      urlParams
    )
    return result
  },
  getRestrictions: async (itemId) => {
    const result = await fetchFromUrlGETAsync(
      urlOrganizations + '/' + itemId + '/restrictions',
      []
    )
    return result
  },
  setRestrictions: async (obj, id) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    const result = await fetchFromUrlPATCHAsync(
      urlOrganizations + '/' + id + '/restrictions',
      urlParams
    )
    return result
  },
  getStripeData: async (itemId) => {
    const response = await fetchFromUrlGETAsync(
      `${urlOrganizations}/${itemId}/stripe-data`,
      []
    )
    return response.data
  },
  authPayment: async (itemId) => {
    const response = await fetchFromUrlPOSTAsync(`${urlPayments}/auth/${itemId}`, [])
    return response.data
  },
  createStripeAccount: async (itemId) => {
    const response = await fetchFromUrlPOSTAsync(
      `${urlPayments}/create-account/${itemId}`,
      []
    )
    return response.data
  },
  refreshStripeAuth: async (itemId) => {
    const response = await fetchFromUrlPOSTAsync(
      `${urlPayments}/refresh-links/${itemId}`,
      []
    )
    return response.data
  },
  disconnectPayment: async (id) => {
    const response = await fetchFromUrlPOSTAsync(`${urlPayments}/logout/${id}`, [])
    return response.data
  },
  setSwitchPaymentsStatus: async (obj, id) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    const result = await fetchFromUrlPATCHAsync(
      `${urlPayments}/switch-status/${id}`,
      urlParams
    )
    return result
  },
  getPageListTemplateSequence: async (start, range, organization_id) => {
    const urlParams = [
      { key: 'page', value: start + 1 },
      { key: 'perPage', value: range },
    ]

    const result = await fetchFromUrlGETAsync(urlSequencesTemplate, urlParams)
    if (result.success) {
      result.max = result.data.total_items
      result.data = result.data.sequences
    }

    return result
  },
  saveUpdateTemplateSequence: async (obj, id) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    return fetchFromUrlPUTAsync(urlSequencesTemplate + '/' + id, urlParams)
  },
  saveNewTemplateSequence: async (obj) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    return fetchFromUrlPOSTAsync(urlSequencesTemplate, urlParams)
  },
  deleteSequenceTemplate: async (id) =>
    fetchFromUrlDELETEAsync(urlSequencesTemplate + '/' + id, []),
  getOrganizationsLocationsList: async (
    start,
    range,
    searchKeyword = '',
    paginationOptions = {},
    accessedOrg,
    userRole,
    _default_location_id,
    withQuestions
  ) => {
    const urlParams = []
    urlParams.push({
      key: 'organizationsIds',
      value: accessedOrg ? accessedOrg : [],
    })
    if (userRole) {
      urlParams.push({ key: 'userRole', value: userRole })
    }
    if (_default_location_id) {
      urlParams.push({ key: '_default_location_id', value: _default_location_id })
    }
    if (withQuestions) {
      urlParams.push({ key: 'withQuestions', value: withQuestions })
    }
    const result = await fetchFromUrlPOSTAsync(
      `${urlOrgLoc}?page=${start + 1}&perPage=${range}&enabled=${
        paginationOptions.enabled
      }`,
      urlParams
    )
    if (result.success) {
      result.max = result.data.total_items
      result.data = result.data.organizationsWithLocations
    }
    return result
  },
  updateSuperhumanConfig: async (id, obj) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    return fetchFromUrlPUTAsync(
      urlOrganizations + '/' + id + '/superhuman-config',
      urlParams
    )
  },
  getSuperhumanConfig: async (id) => {
    const urlParams = []
    return fetchFromUrlGETAsync(
      urlOrganizations + '/' + id + '/superhuman-config',
      urlParams
    )
  },
  update2fa: async (status, id) => {
    const urlParams = []
    urlParams.push({
      key: 'enabled',
      value: status,
    })
    const result = await fetchFromUrlPATCHAsync(
      urlOrganizations + '/' + id + '/mfa',
      urlParams
    )
    return result
  },
  saveUpdateOfCustomWorkingHours: async (obj, id, recordId) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    return fetchFromUrlPUTAsync(
      urlOrganizations + '/' + id + '/custom-working-hours/' + recordId,
      urlParams
    )
  },
  saveCustomWorkingHours: async (obj, id) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    return fetchFromUrlPOSTAsync(
      urlOrganizations + '/' + id + '/custom-working-hours',
      urlParams
    )
  },
  deleteCustomWorkingHoursItem: async (id, recordId) =>
    fetchFromUrlDELETEAsync(
      urlOrganizations + '/' + id + '/custom-working-hours/' + recordId,
      []
    ),
  setIPWhiteList: async (obj, id) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    return fetchFromUrlPATCHAsync(
      urlOrganizations + '/' + id + '/ip-whitelist',
      urlParams
    )
  },
  sendMessage: async (obj, id) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    return fetchFromUrlPOSTAsync(
      `/superhuman-playground/${id}/generate`,
      urlParams,
      180000
    )
  },
  changeAllSequencesStatus: async (obj) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    return fetchFromUrlPUTAsync(urlSequences, urlParams)
  },
  getCustomMessageConfigs: async (id) => {
    const urlParams = []
    return fetchFromUrlGETAsync(
      `${urlOrganizations}/${id}/messages-configs`,
      urlParams
    )
  },
  saveCustomMessageConfigs: async (obj, _id) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    return fetchFromUrlPUTAsync(
      `${urlOrganizations}/${_id}/messages-configs`,
      urlParams
    )
  },
}

const loc = {}

const organizationActions = Object.assign(fs, loc)

export default organizationActions
