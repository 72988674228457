import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width='40'
    height='40'
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect x='0.5' y='0.5' width='39' height='39' rx='7.5' fill='white' />
    <rect x='0.5' y='0.5' width='39' height='39' rx='7.5' stroke='#E5E9F2' />
    <path
      d='M29.6602 12.2395V15.0753H9V12.2395C9 11.3463 9.72716 10.6191 10.6204 10.6191H12.4434V12.6447C12.4434 13.3151 12.9882 13.86 13.6587 13.86H14.874C15.5444 13.86 16.0893 13.3151 16.0893 12.6447V10.6191H22.5709V12.6447C22.5709 13.3151 23.1158 13.86 23.7862 13.86H25.0015C25.672 13.86 26.2168 13.3151 26.2168 12.6447V10.6191H28.0398C28.9331 10.6191 29.6602 11.3463 29.6602 12.2395Z'
      fill='#5B66EA'
    />
    <path
      d='M13.6585 13.051C13.4351 13.051 13.2534 12.8693 13.2534 12.6459V9.4051C13.2534 9.18168 13.4351 9 13.6585 9H14.8738C15.0972 9 15.2789 9.18168 15.2789 9.4051V12.6459C15.2789 12.8693 15.0972 13.051 14.8738 13.051H13.6585Z'
      fill='#5B66EA'
    />
    <path
      d='M23.786 13.051C23.5625 13.051 23.3809 12.8693 23.3809 12.6459V9.4051C23.3809 9.18168 23.5626 9 23.786 9H25.0013C25.2247 9 25.4064 9.18168 25.4064 9.4051V12.6459C25.4064 12.8693 25.2247 13.051 25.0013 13.051H23.786Z'
      fill='#5B66EA'
    />
    <path
      d='M9 15.8848V26.4174C9 27.3107 9.72716 28.0378 10.6204 28.0378H17.5294C17.6489 27.8393 17.7948 27.653 17.9649 27.4828L23.1441 22.3016C23.2961 22.1497 23.3811 21.9471 23.3811 21.7304V20.3551C23.3811 20.1444 23.3953 19.9378 23.4237 19.7332H23.1786C22.9558 19.7332 22.7735 19.5509 22.7735 19.3281C22.7735 19.1053 22.9558 18.923 23.1786 18.923H23.6039C23.8267 18.2242 24.2177 17.5842 24.7524 17.0515C25.2993 16.5005 25.9616 16.1055 26.6868 15.8848H9ZM15.4816 23.7843H12.6459C12.4231 23.7843 12.2408 23.602 12.2408 23.3792C12.2408 23.1564 12.4231 22.9741 12.6459 22.9741H15.4816C15.7044 22.9741 15.8867 23.1564 15.8867 23.3792C15.8867 23.602 15.7044 23.7843 15.4816 23.7843ZM15.4816 19.7332H12.6459C12.4231 19.7332 12.2408 19.5509 12.2408 19.3281C12.2408 19.1053 12.4231 18.923 12.6459 18.923H15.4816C15.7044 18.923 15.8867 19.1053 15.8867 19.3281C15.8867 19.5509 15.7044 19.7332 15.4816 19.7332ZM20.748 23.7843H17.9123C17.6895 23.7843 17.5072 23.602 17.5072 23.3792C17.5072 23.1564 17.6895 22.9741 17.9123 22.9741H20.748C20.9708 22.9741 21.1531 23.1564 21.1531 23.3792C21.1531 23.602 20.9708 23.7843 20.748 23.7843ZM20.748 19.7332H17.9123C17.6895 19.7332 17.5072 19.5509 17.5072 19.3281C17.5072 19.1053 17.6895 18.923 17.9123 18.923H20.748C20.9708 18.923 21.1531 19.1053 21.1531 19.3281C21.1531 19.5509 20.9708 19.7332 20.748 19.7332Z'
      fill='#5B66EA'
    />
    <path
      d='M33 20.6336V22.1178C33 23.1224 32.6092 24.0678 31.8967 24.7783C31.1862 25.4908 30.2408 25.8816 29.2362 25.8816H27.8961C27.4757 25.8816 27.0789 26.0454 26.7809 26.3434L21.7322 31.3901C21.3395 31.7829 20.8184 32 20.2618 32H20.0803C19.5237 32 19.0026 31.7829 18.6099 31.3901C18.2171 30.9974 18 30.4763 18 29.9197V29.7382C18 29.1816 18.2171 28.6605 18.6099 28.2678L23.6566 23.2191C23.9546 22.9211 24.1184 22.5243 24.1184 22.104V20.7638C24.1184 19.7592 24.5092 18.8138 25.2217 18.1033C25.9322 17.3908 26.8776 17 27.8822 17H29.3665C29.7375 17 30.0395 17.302 30.0395 17.673C30.0395 17.8546 29.9704 18.0224 29.8421 18.1507L27.8349 20.1579L29.8421 22.1651L31.8493 20.1579C31.9776 20.0316 32.1454 19.9605 32.327 19.9605C32.698 19.9605 33 20.2625 33 20.6336Z'
      fill='#5B66EA'
    />
  </svg>
)

const SVGIntegrationServiceScheduler = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGIntegrationServiceScheduler
