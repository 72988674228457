import React, { useState, useEffect } from 'react'
import { Divider, Flex, Form } from 'antd'
import { useSelector } from 'react-redux'
import { getLongName, replaceWords } from '../../../../../utils'
import { getText, getTextServerError } from '../../../../../lang'
import { APP_URL } from '../../../../../config'
import MatForm from '../../../../../components/Form/MatForm'
import {
  organizationSelector,
  userSelector,
} from '../../../../../store/selectors/selectors'
import { notifyError, notifySuccess } from '../../../../../utils/Notify'
import chatSettingsMainActions from '../../../../../store/modules/chatSettingsMainActions'
import {
  CANCEL_BTN,
  SUBMIT_BTN,
  SUPER_ADMIN_FAVORITE_EMAILS,
} from '../../../../../devOptions'
import { useLocationLayout } from '../../../locationProvider/LocationProvider'
import { useLayout } from '../../../../../layout/LayoutProvider/LayoutProvider'
import MyDefaultPageLayout from '../../../../../components/MyDefaultPageLayout/MyDefaultPageLayout'
import useDeviceCheck from '../../../../../utils/useDeviceCheck'
import MyDefaultBlock from '../../../../../components/MyDefaultBlock/MyDefaultBlock'
import MyDefaultInsidePageTabs from '../../../../../components/MyDefaultInsidePageTabs/MyDefaultInsidePageTabs'
import MyDefaultInputText from '../../../../../components/MyDefaultInputText/MyDefaultInputText'
import MyDefaultTextarea from '../../../../../components/MyDefaultTextarea/MyDefaultTextarea'
import MyDefaultSwitch from '../../../../../components/MyDefaultSwitch/MyDefaultSwitch'
import MyDefaultBlockSubtitle from '../../../../../components/MyDefaultBlock/MyDefaultBlockSubtitle'
import MyDefaultCopyTextarea from '../../../../../components/MyDefaultCopyTextarea/MyDefaultCopyTextarea'
import ThemePreview from '../preview/ThemePreview'
import MyDefaultUrlConditions from '../../../../../components/MyDefaultUrlConditions/MyDefaultUrlConditions'
import MatadorConnectVehiclePropertiesInstructions from './MatadorConnectVehiclePropertiesInstructions'
import OpenMatadorConnectInstructions from './OpenMatadorConnectInstructions'

const Settings = () => {
  const { locationObj, settings, setSettings } = useLocationLayout()
  const { setFooterButtons, setLoading, setPageTitle } = useLayout()
  const { isPageDrawerSize, isMobile } = useDeviceCheck()

  const organization = useSelector(organizationSelector)
  const user = useSelector(userSelector)

  const languages = organization?.languages || []

  const [selectedLangTab, setSelectedLangTab] = useState(languages?.[0] || 'en')
  const [settingTextCopy, setSettingTextCopy] = useState(settings.texts)
  const [urls, setUrls] = useState([])

  const [form] = Form.useForm()

  const isCSTeamEmail = SUPER_ADMIN_FAVORITE_EMAILS.includes(user.email)

  useEffect(() => {
    setFooterButtons([
      {
        type: CANCEL_BTN,
      },
      {
        type: SUBMIT_BTN,
      },
    ])
    setPageTitle(getText('WORD_SETTINGS'))
    setSelectedLangTab(languages[0])
    setSettingTextCopy(settings.texts)
    setUrls(settings.conditions.url)
  }, [])

  const isTermLinksValid = (str) => {
    try {
      new URL(str)
    } catch (_) {
      return false
    }

    let pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ) // fragment locator

    return !pattern.test(str)
  }

  const onFinish = async () => {
    setLoading(true)
    const allValues = form.getFieldsValue(true)

    if (allValues.termsLink && isTermLinksValid(allValues.termsLink)) {
      return notifyError(getText('TEXT_TERMS_LINK_INVALID'))
    }

    let deviceSetting
    if (allValues.showMobile && allValues.showDesktop) {
      deviceSetting = 'all'
    } else if (allValues.showDesktop) {
      deviceSetting = 'desktop'
    } else if (allValues.showMobile) {
      deviceSetting = 'mobile'
    } else {
      deviceSetting = 'none'
    }

    const payload = {
      device: deviceSetting,
      isTriggerOnNewSession: allValues.isTriggerOnNewSession,
      termsLink: allValues.termsLink,
      texts: allValues.texts,
      displayAllOrganizationAddresses: allValues.displayAllOrganizationAddresses,
      firstAndLastNameSeparately: allValues.firstAndLastNameSeparately,
      isCustomerNameRequired: allValues.isCustomerNameRequired,
      aiChatEnabled: allValues.aiChatEnabled,
      conditions: {
        url: urls,
      },
    }

    // the BE set on texts an object with 3 languages all being empty strings
    // We should remove the language not used by organization as it leads to an error
    for (const lng in payload.texts) {
      if (!languages.includes(lng)) {
        delete payload.texts[lng]
      }
    }
    let result
    if (settings._id) {
      result = await chatSettingsMainActions.update(payload, locationObj._id)
    } else {
      // TODO not sure in which case this is used
      result = await chatSettingsMainActions.insert(payload, locationObj._id)
    }
    if (result.success) {
      setSettings(result.data.settings)
      notifySuccess(getText('TEXT_MATADOR_CONNECT_SAVED_SUCCESSFULLY'))
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
    setLoading(false)
  }

  const onFinishFailed = (errorInfo) => {
    for (const lng of languages) {
      if (!settingTextCopy[lng] || !settingTextCopy[lng].welcome_title) {
        const errorText = replaceWords(
          getText('TEXT_VARIABLE_REQUIRED_BOTH_LANGUAGE'),
          {
            missingVariable: getText('WORD_WELCOME_TITLE'),
            missingLanguage: getLongName(lng),
          }
        )
        return notifyError(errorText)
      } else if (!settingTextCopy[lng] || !settingTextCopy[lng].first_message) {
        const errorText = replaceWords(
          getText('TEXT_VARIABLE_REQUIRED_BOTH_LANGUAGE'),
          {
            missingVariable: getText('WORD_FIRST_MESSAGE'),
            missingLanguage: getLongName(lng),
          }
        )
        return notifyError(errorText)
      } else if (!settingTextCopy[lng] || !settingTextCopy[lng].second_message) {
        const errorText = replaceWords(
          getText('TEXT_VARIABLE_REQUIRED_BOTH_LANGUAGE'),
          {
            missingVariable: getText('WORD_SECOND_MESSAGE'),
            missingLanguage: getLongName(lng),
          }
        )
        return notifyError(errorText)
      }
    }
    if (errorInfo.errorFields.length > 0) {
      notifyError(errorInfo.errorFields[0].errors[0])
    }
  }

  const widgetBodyCode = `<script>
    (function(d,t) {
      var g=d.createElement(t),s=d.getElementsByTagName(t)[0];
      g.src=src="${APP_URL}/_livechat.js";
      g.async = true;
      s.parentNode.insertBefore(g,s);
      g.onload=function(){
        matadorLiveChatWidget("${settings._token}");
      }
    })(document,"script");
  </script>`

  const getInitialText = () => {
    return {
      welcome_title: '',
      first_message: '',
      second_message: '',
      input_placeholder: '',
    }
  }

  const settingsCopy = {
    ...settings,
    texts: settingTextCopy,
  }

  return (
    <MyDefaultPageLayout disableHeader>
      <Flex gap={20} align='start' vertical={isPageDrawerSize}>
        <MatForm
          maxWidth
          fullWidth
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{
            texts: {
              en: settings.texts.en ? { ...settings.texts.en } : getInitialText(),
              fr: settings.texts.fr ? { ...settings.texts.fr } : getInitialText(),
              sp: settings.texts.sp ? { ...settings.texts.sp } : getInitialText(),
            },
            showMobile: settings.device === 'all' || settings.device === 'mobile',
            showDesktop: settings.device === 'all' || settings.device === 'desktop',
            isTriggerOnNewSession: settings.isTriggerOnNewSession,
            firstAndLastNameSeparately: settings.firstAndLastNameSeparately,
            isCustomerNameRequired: settings.isCustomerNameRequired,
            aiChatEnabled: settings.aiChatEnabled,
            termsLink: settings.termsLink,
            displayAllOrganizationAddresses:
              settings.displayAllOrganizationAddresses,
          }}
        >
          <MyDefaultBlock fullWidth>
            <MyDefaultInsidePageTabs
              isFirst
              onChange={(index) => {
                setSelectedLangTab(organization.languages[index])
              }}
              items={organization?.languages?.map((lng, index) => {
                return {
                  label: getLongName(lng),
                  key: index,
                  forceRender: true,
                  children: (
                    <React.Fragment>
                      <MyDefaultInputText
                        isForm
                        label={getText('WORD_CHAT_SETTINGS_WELCOME_MESSAGE')}
                        name={['texts', lng, 'welcome_title']}
                        required
                        errorMessage={getText('ERR_VALIDATION_REQUIRED')}
                        onChange={(e) => {
                          e.persist()
                          setSettingTextCopy((oldText) => ({
                            ...oldText,
                            [lng]: {
                              ...oldText[lng],
                              welcome_title: e.target.value,
                            },
                          }))
                        }}
                      />
                      <MyDefaultTextarea
                        isForm
                        label={getText('WORD_AUTO_REPLY_FIRST')}
                        name={['texts', lng, 'first_message']}
                        required
                        message={getText('ERR_VALIDATION_REQUIRED')}
                        onChange={(e) => {
                          e.persist()
                          setSettingTextCopy((oldText) => ({
                            ...oldText,
                            [lng]: {
                              ...oldText[lng],
                              first_message: e.target.value,
                            },
                          }))
                        }}
                      />
                      <MyDefaultTextarea
                        isForm
                        label={getText('WORD_AUTO_REPLY_SECOND')}
                        name={['texts', lng, 'second_message']}
                        required
                        message={getText('ERR_VALIDATION_REQUIRED')}
                        onChange={(e) => {
                          e.persist()
                          setSettingTextCopy((oldText) => ({
                            ...oldText,
                            [lng]: {
                              ...oldText[lng],
                              second_message: e.target.value,
                            },
                          }))
                        }}
                      />
                      <MyDefaultInputText
                        isForm
                        label={getText('WORD_INPUT_PLACEHOLDER')}
                        name={['texts', lng, 'input_placeholder']}
                        maxLength={30}
                        onChange={(e) => {
                          e.persist()
                          setSettingTextCopy((oldText) => ({
                            ...oldText,
                            [lng]: {
                              ...oldText[lng],
                              input_placeholder: e.target.value,
                            },
                          }))
                        }}
                      />
                    </React.Fragment>
                  ),
                }
              })}
            />
            <Divider style={{ margin: '0 0 20px 0' }} />
            <MyDefaultBlockSubtitle subtitle={getText('WORD_VISIBILTY')} />
            <MyDefaultSwitch
              noText
              isFirst
              isForm
              name='showMobile'
              titleStyle={{ fontSize: 14 }}
              fullWidth={isMobile}
              title={getText('LABEL_SHOW_WIDGET_MOBILE')}
            />
            <MyDefaultSwitch
              noText
              isForm
              name='showDesktop'
              titleStyle={{ fontSize: 14 }}
              fullWidth={isMobile}
              title={getText('LABEL_SHOW_WIDGET_DESKTOP')}
            />
            <MyDefaultSwitch
              noText
              isLast
              isForm
              name='isTriggerOnNewSession'
              titleStyle={{ fontSize: 14 }}
              fullWidth={isMobile}
              title={getText('LABEL_TRIGGER_NEW_SESSION')}
            />
            <Divider style={{ margin: '12px 0 20px 0' }} />
            <MyDefaultBlockSubtitle subtitle={getText('TEXT_FORM_SETTINGS')} />
            <MyDefaultSwitch
              noText
              isFirst
              isForm
              name='firstAndLastNameSeparately'
              titleStyle={{ fontSize: 14 }}
              fullWidth={isMobile}
              title={getText(
                'TEXT_DISPLAY_THE_FIRST_AND_LAST_NAMES_SEPARATELY_IN_WIDGET_FORM'
              )}
            />
            <MyDefaultSwitch
              noText
              isLast
              isForm
              name='isCustomerNameRequired'
              titleStyle={{ fontSize: 14 }}
              fullWidth={isMobile}
              title={getText('TEXT_NAME_IS_REQUIRED_IN_WIDGET_FORM')}
            />
            <Divider style={{ margin: '12px 0 20px 0' }} />
            {user.isSuperAdmin && isCSTeamEmail && (
              <React.Fragment>
                <MyDefaultBlockSubtitle subtitle={getText('AI_CHAT')} />
                <MyDefaultSwitch
                  noText
                  isFirst
                  isLast
                  isForm
                  noBorder
                  name='aiChatEnabled'
                  titleStyle={{ fontSize: 14 }}
                  fullWidth={isMobile}
                  title={getText('ENABLE_AI_CHAT')}
                />
                <Divider style={{ margin: '20px 0' }} />
              </React.Fragment>
            )}
            <MyDefaultInputText
              isForm
              label={getText('WORD_TERMS_LINK')}
              name={'termsLink'}
            />
          </MyDefaultBlock>
          <MyDefaultBlock enableMarginTop fullWidth>
            <MyDefaultCopyTextarea
              copiedText={widgetBodyCode}
              label={getText('WORD_WIDGET_CODE')}
            />
          </MyDefaultBlock>
          <MyDefaultBlock enableMarginTop fullWidth>
            <MyDefaultUrlConditions urls={urls} setUrls={setUrls} />
            <Divider style={{ margin: '20px 0' }} />
            <Flex vertical gap={20}>
              <OpenMatadorConnectInstructions />
              <MatadorConnectVehiclePropertiesInstructions />
            </Flex>
          </MyDefaultBlock>
        </MatForm>
        <MyDefaultBlock position={'sticky'} top={0} fullWidth height={890}>
          <ThemePreview
            settings={settingsCopy}
            language={selectedLangTab}
            locationId={settings._token}
            theme={settings.theme}
          />
        </MyDefaultBlock>
      </Flex>
    </MyDefaultPageLayout>
  )
}

export default Settings
