import React from 'react'
import { Popover, Typography } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { getText } from '../../../lang'
import Div from '../../../components/Div/Div'

const { Title, Paragraph } = Typography

const GAInfo = () => {
  const content = () => {
    return (
      <Div>
        <Title level={5}>{getText('GA_TRACK_PAGE_VIEWS_TITLE')}</Title>
        <Paragraph>{getText('GA_TRACK_PAGE_VIEWS_PARAGRAPH_1')}</Paragraph>
        <ul className='pl-4'>
          <li>
            <strong>{getText('GA_CATEGORY')}:</strong> {getText('GA_MATADOR')}
          </li>
          <li>
            <strong>{getText('GA_ACTION')}:</strong> {getText('GA_ACTION_DETAILS')}
          </li>
          <li>
            <strong>{getText('GA_LABEL')}:</strong> {getText('GA_SUBMISSION')}
          </li>
        </ul>
        <Paragraph>{getText('GA_TRACK_PAGE_VIEWS_PARAGRAPH_2')}</Paragraph>
        <Paragraph>{getText('GA_TRACK_PAGE_VIEWS_PARAGRAPH_3')}</Paragraph>
        <Paragraph>{getText('GA_TRACK_PAGE_VIEWS_PARAGRAPH_4')}</Paragraph>
      </Div>
    )
  }

  return (
    <Popover
      content={content}
      title={getText('GA_GOOGLE_ANALYTICS_4_TRACKING')}
      style={{ padding: 16 }}
    >
      <InfoCircleOutlined />
    </Popover>
  )
}

export default GAInfo
