import React from 'react'
import { Flex } from 'antd'
import MyDefaultBlock from '../MyDefaultBlock/MyDefaultBlock'
import Div from '../Div/Div'
import MyDefaultIntegrationBlockFooter from './MyDefaultIntegrationBlockFooter/MyDefaultIntegrationBlockFooter'
import './MyDefaultIntegrationBlock.scss'

const MyDefaultIntegrationBlock = ({
  enableMarginTop,
  icon,
  title,
  description,
  enabled,
  connectedBy,
  customButton,
  customFooter,
  fullWidth,
}) => {
  return (
    <MyDefaultBlock enableMarginTop={enableMarginTop} fullWidth={fullWidth}>
      <Div className={'my_default_integration_block'}>
        <Flex className='my_default_integration_block--header'>
          {icon}
          <Div className={'my_default_integration_block--header-title'}>
            <p>{title}</p>
            <span>{description}</span>
          </Div>
        </Flex>
        {customFooter || (
          <MyDefaultIntegrationBlockFooter
            connectedBy={connectedBy}
            customButton={customButton}
            enabled={enabled}
          />
        )}
      </Div>
    </MyDefaultBlock>
  )
}

export default MyDefaultIntegrationBlock
