import React, { useEffect, useRef, useState } from 'react'
import { getText } from '../../../../lang'
import { getLongName } from '../../../../utils'
import MyDefaultInsidePageTabs from '../../../../components/MyDefaultInsidePageTabs/MyDefaultInsidePageTabs'
import MyDefaultTextarea from '../../../../components/MyDefaultTextarea/MyDefaultTextarea'
import MyDefaultAddVariablesInMessage from '../../../../components/MyDefaultAddVariablesInMessage/MyDefaultAddVariablesInMessage'

const ReviewInvitePanel = ({
  variables,
  languages,
  form,
  formName,
  formNameSubject,
}) => {
  const [currentCursorPosition, setCurrentCursorPosition] = useState(0)
  const [currentCursorPositionSubject, setCurrentCursorPositionSubject] = useState(0)
  const [selectedLangTab, setSelectedLangTab] = useState(languages?.[0] || 'en')
  const textAreaRef = useRef()
  const inputRef = useRef()

  useEffect(() => {
    setSelectedLangTab(languages?.[0] || 'en')
  }, [])

  useEffect(() => {
    const messageContent = form.getFieldValue(formName)[selectedLangTab]
    const messageLength = messageContent ? messageContent.length : 0
    setCurrentCursorPosition(messageLength)
    if (formNameSubject) {
      const subjectContent = form.getFieldValue(formNameSubject)[selectedLangTab]
      const subjectLength = subjectContent ? subjectContent.length : 0
      setCurrentCursorPositionSubject(subjectLength)
    }
  }, [selectedLangTab])

  const handleClickVariable = (variable) => {
    const currentContent = form.getFieldValue(formName)[selectedLangTab]
    const newContent = [
      currentContent && currentContent.slice(0, currentCursorPosition),
      variable,
      currentContent && currentContent.slice(currentCursorPosition),
    ].join('')

    form.setFieldsValue({
      [formName]: {
        [selectedLangTab]: newContent,
      },
    })
    setCurrentCursorPosition(currentCursorPosition + variable.length)
    textAreaRef.current &&
      textAreaRef.current.autoFocus(currentCursorPosition + variable.length)
  }
  const handleClickVariableSubject = (variable) => {
    const currentContent = form.getFieldValue(formNameSubject)[selectedLangTab]
    const newContent = [
      currentContent && currentContent.slice(0, currentCursorPositionSubject),
      variable,
      currentContent && currentContent.slice(currentCursorPositionSubject),
    ].join('')

    form.setFieldsValue({
      [formNameSubject]: {
        [selectedLangTab]: newContent,
      },
    })
    setCurrentCursorPositionSubject(currentCursorPositionSubject + variable.length)
    inputRef.current &&
      inputRef.current.autoFocus(currentCursorPositionSubject + variable.length)
  }

  return (
    <MyDefaultInsidePageTabs
      onChange={(index) => {
        setSelectedLangTab(languages[index])
      }}
      items={languages?.map((lng, index) => {
        return {
          label: getLongName(lng),
          key: index,
          forceRender: true,
          children: (
            <React.Fragment>
              {formNameSubject && (
                <React.Fragment>
                  <MyDefaultTextarea
                    isForm
                    ref={inputRef}
                    name={[formNameSubject, selectedLangTab]}
                    onChange={(e) =>
                      setCurrentCursorPositionSubject(e.target.selectionStart)
                    }
                    onKeyUp={(e) =>
                      setCurrentCursorPositionSubject(e.target.selectionStart)
                    }
                    onClick={(e) =>
                      setCurrentCursorPositionSubject(e.target.selectionStart)
                    }
                    minLength={3}
                    maxLength={1000}
                    placeholder={getText('WORD_SUBJECT')}
                  />
                  <MyDefaultAddVariablesInMessage
                    onClick={(variable) =>
                      handleClickVariableSubject(variable, true)
                    }
                    data={variables}
                  />
                </React.Fragment>
              )}
              <MyDefaultTextarea
                isForm
                ref={textAreaRef}
                maxLength={1000}
                minLength={20}
                name={[formName, selectedLangTab]}
                placeholder={getText('WORD_BODY')}
                onChange={(e) => setCurrentCursorPosition(e.target.selectionStart)}
                onKeyUp={(e) => setCurrentCursorPosition(e.target.selectionStart)}
                onClick={(e) => setCurrentCursorPosition(e.target.selectionStart)}
              />
              <MyDefaultAddVariablesInMessage
                onClick={handleClickVariable}
                data={variables}
                disableMargin
              />
            </React.Fragment>
          ),
        }
      })}
    />
  )
}

export default ReviewInvitePanel
