import React, { useEffect, useState } from 'react'
import MyDefaultDropdownAssignedUser from '../../../../../components/MyDefaultDropdown/MyDefaultDropdownAssignedUser/MyDefaultDropdownAssignedUser'

const SelectUsersAssigned = ({ questionData, form }) => {
  const [assignedUsers, setAssignedUsers] = useState([])
  const [usersToUnassign, setUsersToUnassign] = useState([])

  useEffect(() => {
    setAssignedUsers(questionData?.users || [])
  }, [questionData])

  const handleAssignUser = (newUser) => {
    const user = { _id: newUser._id, fullName: newUser.fullName }

    if (usersToUnassign.includes(user?._id)) {
      const newUnAssignedUsers = usersToUnassign.filter(
        (userId) => userId !== user?._id
      )
      setUsersToUnassign(newUnAssignedUsers)
      form.setFieldsValue({ removeUsers: newUnAssignedUsers })
    }

    const newAssignedUsers = [...assignedUsers, user]
    setAssignedUsers(newAssignedUsers)
    form.setFieldsValue({ newUsers: newAssignedUsers.map((user) => user?._id) })
  }

  const handleUnassignUser = (user) => {
    const newAssignedUsers = assignedUsers.filter((u) => u._id !== user?._id)
    setAssignedUsers(newAssignedUsers)
    form.setFieldsValue({ newUsers: newAssignedUsers.map((u) => u._id) })

    const newRemoveUsers = [...usersToUnassign, user?._id]
    // check if its in questionData.users before doing this
    form.setFieldsValue({ removeUsers: newRemoveUsers })
    setUsersToUnassign(newRemoveUsers)
  }

  return (
    <MyDefaultDropdownAssignedUser
      assignedUsers={assignedUsers}
      handleUnassignUser={handleUnassignUser}
      handleAssignUser={handleAssignUser}
    />
  )
}

export default SelectUsersAssigned
