import React from 'react'
import { Flex } from 'antd'
import useAutoResizeText from '../../utils/useAutoResizeText'

const MyDefaultAutoResizingText = ({ text, max, min, height = '48px' }) => {
  const textRef = useAutoResizeText(max, min)

  return (
    <Flex
      align='center'
      ref={textRef}
      style={{
        height: height,
        overflow: 'hidden',
        whiteSpace: 'normal',
      }}
    >
      {text}
    </Flex>
  )
}

export default MyDefaultAutoResizingText
