import React from 'react'
import { useSelector } from 'react-redux'
import MyDefaultButton from '../../../../../components/MyDefaultButton/MyDefaultButton'
import { getText } from '../../../../../lang'
import MicrosoftAuthAPI from '../../../../../components/api/MicrosoftAuth'
import { userSelector } from '../../../../../store/selectors/selectors'

const OfficeIntegration = ({
  bookingLoginType,
  locationObj,
  checkConectedAccount,
}) => {
  const user = useSelector(userSelector)

  return !Boolean(user.isSuperAdmin && user.isAdmin) &&
    bookingLoginType.microsoftLogged ? (
    <MyDefaultButton
      htmlType={'button'}
      buttonText={getText('TEXT_DISCONNECT_OFFICE_365')}
      style={{ cursor: 'not-allowed' }}
      tooltipText={getText('TEXT_PLEASE_CONTACT_YOUR_ADMINISTRATOR')}
      tooltip
    />
  ) : (
    <MicrosoftAuthAPI
      idLocation={locationObj._id}
      disabled={bookingLoginType.googleLogged}
      isConnected={bookingLoginType.microsoftLogged}
      onSuccess={(value) => {
        locationObj.featuresConnectingData.calendarInvite.userData.fullName =
          user.fullName
        if (value === 'login') {
          locationObj.featuresConnectingData.calendarInvite.isConnected = true
        } else {
          locationObj.featuresConnectingData.calendarInvite.isConnected = false
        }
        checkConectedAccount?.()
      }}
      typeButton={'primary'}
    />
  )
}

export default OfficeIntegration
