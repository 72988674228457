import React from 'react'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Flex, Tooltip } from 'antd'
import './MyDefaultLabelWithTooltip.scss'

const MyDefaultLabelWithTooltip = ({ label, tooltipText, required }) => {
  return (
    <Flex
      align='center'
      gap={required ? 20 : 5}
      className='my_default_label_with_tooltip'
    >
      {label}
      {Boolean(tooltipText) && (
        <Tooltip title={tooltipText}>
          <InfoCircleOutlined />
        </Tooltip>
      )}
    </Flex>
  )
}

export default MyDefaultLabelWithTooltip
