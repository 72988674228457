import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Form } from 'antd'
import { capitalize } from 'lodash'
import MyDefaultModal from '../../../../../../components/MyDefaultModal/MyDefaultModal'
import { getText } from '../../../../../../lang'
import MatForm from '../../../../../../components/Form/MatForm'
import { notifyError, notifySuccess } from '../../../../../../utils/Notify'
import MyDefaultInputText from '../../../../../../components/MyDefaultInputText/MyDefaultInputText'
import MyDefaultSelect from '../../../../../../components/MyDefaultSelect/MyDefaultSelect'
import customerActions from '../../../../../../store/modules/customerActions'
import utilDate from '../../../../../../utils/utilsDate'
import { SERVICE } from '../../../../utils/constants'
import utilsLocalizations from '../../../../../../utils/utilsLocalizations'
import MyDefaultInputNumber from '../../../../../../components/MyDefaultInputNumber/MyDefaultInputNumber'
import { replaceWords } from '../../../../../../utils'

const VIN_REGEXP = /^[A-HJ-NPR-Z0-9]{17}$/
const MAX_MILEAGE_NUMBER = 10000000

const MessageTabInfoOwnedCarsModal = ({
  visible,
  setVisible,
  _receiver_id,
  item,
  onSave,
  isAvailable,
  convIsNew,
}) => {
  const [makeList, setMakeList] = useState([])
  const [loading, setLoading] = useState(false)

  const [form] = Form.useForm()

  const initialValues = useMemo(() => {
    return {
      vin: '',
      make: undefined,
      model: '',
      year: undefined,
      mileage: '',
      salesStep: isAvailable ? 'Available' : undefined,
    }
  }, [isAvailable])

  const fill = useCallback(async () => {
    const result = await customerActions.getVehiclesConfigs()
    if (result.success) {
      const newMakeList = result.data.VEHICLE_MAKES.map((item) => ({
        label: item,
        value: item,
      }))
      setMakeList(newMakeList)
    } else {
      notifyError(result.message)
    }
  }, [])

  useEffect(() => {
    fill()
  }, [fill])

  const onFinish = async (values) => {
    setLoading(true)
    let result

    const obj = {
      vin: values.vin || '',
      make: values.make || '',
      model: values.model || '',
      year: values.year || '',
      salesStep: values.salesStep || '',
      mileage: values.mileage || '',
    }

    if (convIsNew) {
      obj.isNew = true
    }

    if (item) {
      result = await customerActions.updateOwnedCars(
        { ...obj, type: SERVICE },
        _receiver_id,
        item.id
      )
    } else {
      result = await customerActions.saveOwnedCars(
        { ...obj, type: SERVICE },
        _receiver_id
      )
    }
    if (result.success) {
      notifySuccess(
        getText(
          item
            ? 'TEXT_OWNED_VEHICLE_WAS_SUCCESSFULLY_UPDATED'
            : 'TEXT_OWNED_VEHICLE_WAS_SUCCESSFULLY_ADDED'
        )
      )
      onSave?.(result.data, Boolean(item))
      setVisible(false)
    } else {
      notifyError(utilsLocalizations.localizedServerError(result.errMsg))
    }
    setLoading(false)
  }

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields.length > 0) {
      notifyError(errorInfo.errorFields[0].errors[0])
    }
  }

  useEffect(() => {
    if (visible) {
      if (Boolean(item)) {
        form.setFieldsValue({
          vin: item.vin || '',
          make: item.make || undefined,
          model: item.model || '',
          year: item.year || undefined,
          mileage: item.mileage || '',
          salesStep: item.salesStep || undefined,
        })
      } else {
        form.setFieldsValue(initialValues)
      }
    }
  }, [_receiver_id, item, visible])

  return (
    <MyDefaultModal
      noTrigger
      confirmLoading={loading}
      formName={'formSaveOwnedCars'}
      title={
        item ? getText('TEXT_EDIT_OWNED_VEHICLE') : getText('WORD_ADD_NEW_VEHICLE')
      }
      visible={visible}
      onTrrigerClick={() => {
        setVisible(true)
        form.resetFields()
      }}
      onCancel={() => {
        setVisible(false)
      }}
      okText={item ? getText('ACTION_SAVE_CHANGES') : getText('WORD_CREATE')}
      hideCancel
      submitButtonFullWidth
    >
      <MatForm
        form={form}
        name={'formSaveOwnedCars'}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <MyDefaultInputText
          isForm
          name={'vin'}
          label={getText('WORD_VIN')}
          placeholder={getText('TEXT_ENTER_A_CHARACTER_VIN_CODE')}
          errorMessage={getText('TEXT_ENTER_A_CHARACTER_VIN_CODE')}
          validator={(_, value) => {
            if (value && !VIN_REGEXP.test(value)) {
              return Promise.reject()
            }
            return Promise.resolve()
          }}
          onChange={(e) => {
            form.setFieldsValue({ vin: e.target.value.toUpperCase() })
          }}
          onClear={() => {
            form.setFieldsValue({ vin: '' })
          }}
          maxLength={17}
        />
        <MyDefaultSelect
          isForm
          name={'make'}
          label={capitalize(getText('WORD_MAKE'))}
          placeholder={getText('WORD_CHOOSE_MAKE')}
          options={makeList}
          showSearch
          getPopupContainer={() => document.body}
          onClear={() => {
            form.setFieldsValue({ make: '' })
          }}
        />
        <MyDefaultInputText
          isForm
          name={'model'}
          label={capitalize(getText('WORD_MODEL'))}
          placeholder={getText('WORD_ENTER_MODEL')}
          maxLength={128}
        />
        <MyDefaultSelect
          isForm
          name={'year'}
          label={capitalize(getText('WORD_YEAR'))}
          placeholder={getText('WORD_CHOOSE_YEAR')}
          options={utilDate.getYearList()}
          getPopupContainer={() => document.body}
          showSearch
          onClear={() => {
            form.setFieldsValue({ year: '' })
          }}
        />
        <MyDefaultInputNumber
          isForm
          fullWidth
          name={'mileage'}
          label={getText('WORD_MILEAGE')}
          placeholder={'XXX XXX'}
          max={MAX_MILEAGE_NUMBER}
          preventNegative
          preventDot
          maxMessage={replaceWords(
            getText('TEXT_MILEAGE_MUST_BE_MAXIMUM_CHARACTERS'),
            {
              number: MAX_MILEAGE_NUMBER,
            }
          )}
        />
        <MyDefaultSelect
          isForm
          name={'salesStep'}
          label={getText('WORD_STATUS')}
          required
          disabled={isAvailable}
          placeholder={getText('WORD_SELECT_STATUS')}
          message={getText('TEXT_PLEASE_SELECT_STATUS')}
          options={[
            {
              label: getText('WORD_IN_USE'),
              value: 'Available',
            },
            {
              label: getText('ACTION_ARCHIVE'),
              value: 'Sold',
            },
          ]}
        />
      </MatForm>
    </MyDefaultModal>
  )
}

export default memo(MessageTabInfoOwnedCarsModal)
