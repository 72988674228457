import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = () => (
  <svg
    width='22'
    height='22'
    viewBox='0 0 22 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g id='Iconly/Light/Chat'>
      <g id='svgexport-23 1' clipPath='url(#clip0_25428_4693)'>
        <path
          id='Path 5912'
          d='M20.9202 3.8301L20.8618 3.35364L11.7719 0.0476457L11.6368 0H11.3634L2.14753 3.35364L2.08917 3.8301C2.07227 3.96074 0.568551 17.0295 11.176 21.9262L11.3296 21.9985H11.6706L11.8242 21.9262C22.4331 17.0295 20.9356 3.96074 20.9202 3.8301ZM11.5001 20.3447C3.24575 16.2917 3.43007 6.76261 3.59596 4.49714L11.5001 1.62149L19.4042 4.49714C19.5716 6.76261 19.7513 16.2917 11.5001 20.3447ZM15.423 9.4323H14.6396V7.85692C14.6519 7.44082 14.5791 7.02659 14.4256 6.63968C14.2721 6.25277 14.0412 5.90134 13.747 5.60698C13.4529 5.31263 13.1017 5.08156 12.715 4.92797C12.3283 4.77438 11.9144 4.70151 11.4985 4.71385C11.0827 4.70151 10.6687 4.77438 10.2821 4.92797C9.89541 5.08156 9.54421 5.31263 9.25004 5.60698C8.95587 5.90134 8.72495 6.25277 8.57146 6.63968C8.41797 7.02659 8.34515 7.44082 8.35748 7.85692V9.42769H7.57259C7.36443 9.42769 7.16479 9.51044 7.0176 9.65773C6.8704 9.80501 6.78771 10.0048 6.78771 10.2131V14.9269C6.78771 15.1352 6.8704 15.335 7.0176 15.4823C7.16479 15.6296 7.36443 15.7123 7.57259 15.7123H15.4245C15.6327 15.7123 15.8323 15.6296 15.9795 15.4823C16.1267 15.335 16.2094 15.1352 16.2094 14.9269V10.2146C16.2096 10.1113 16.1894 10.0091 16.1501 9.91359C16.1107 9.81812 16.0529 9.73136 15.98 9.65827C15.9071 9.58518 15.8205 9.52719 15.7252 9.48762C15.6299 9.44806 15.5277 9.42769 15.4245 9.42769L15.423 9.4323ZM9.92724 7.85692C9.91388 7.64678 9.94549 7.43621 10.0199 7.23926C10.0944 7.04232 10.21 6.86354 10.359 6.71486C10.508 6.56619 10.687 6.45104 10.8841 6.37711C11.0811 6.30318 11.2916 6.27217 11.5016 6.28615C11.7111 6.27283 11.9211 6.30435 12.1175 6.37859C12.3139 6.45284 12.4923 6.56812 12.6406 6.71675C12.789 6.86537 12.9041 7.04394 12.9781 7.24054C13.0521 7.43714 13.0834 7.64727 13.0698 7.85692V9.42769H9.92724V7.85692ZM14.6381 14.1431H8.35748V11H14.6396L14.6381 14.1431Z'
          fill='#0E0E0E'
        />
      </g>
    </g>
    <defs>
      <clipPath id='clip0_25428_4693'>
        <rect width='19' height='22' fill='white' transform='translate(2)' />
      </clipPath>
    </defs>
  </svg>
)

const SVGSideNavigationChangePassword = (props) => (
  <Icon component={SVGIcon} {...props} />
)

export default SVGSideNavigationChangePassword
