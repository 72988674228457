import React, { useEffect, useState, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getText } from '../../lang'
import SVGSideNavigationChangePassword from '../../icons/SVG/SVGSideNavigationChangePassword'
import SVGSideNavigationNotafSettings from '../../icons/SVG/SVGSideNavigationNotafSettings'
import SVGSideNavigationSettingsIcon from '../../icons/SVG/SVGSideNavigationSettingsIcon'
import useDeviceCheck from '../../utils/useDeviceCheck'
import MyDefaultInsidePagePanel from '../../components/MyDefaultInsidePagePanelLayout/MyDefaultInsidePagePanel/MyDefaultInsidePagePanel'
import MyDefaultInsidePageLeftPanel from '../../components/MyDefaultInsidePagePanelLayout/MyDefaultInsidePageLeftPanel/MyDefaultInsidePageLeftPanel'

const ProfileMain = ({ children }) => {
  const { isTabletPortraitOrMobile } = useDeviceCheck()
  const location = useLocation()
  const navigate = useNavigate()
  const [current, setCurrent] = useState(location.pathname)

  useEffect(() => {
    if (location.pathname !== current) {
      setCurrent(location.pathname)
    }
  }, [location.pathname])

  const menuItems = useMemo(() => {
    return [
      {
        key: '/profile/edit',
        label: getText('WORD_PROFILE_INFORMATION', location.state),
        icon: <SVGSideNavigationSettingsIcon />,
      },
      {
        key: '/profile/change-password',
        label: getText('ACTION_CHANGE_PASSWORD', location.state),
        icon: <SVGSideNavigationChangePassword />,
      },
      {
        key: '/profile/notification',
        label: getText('WORD_NOTIFICATION_SETTINGS', location.state),
        icon: <SVGSideNavigationNotafSettings />,
      },
    ]
  }, [location.state])
  return (
    <MyDefaultInsidePagePanel>
      <MyDefaultInsidePageLeftPanel
        onClick={(e) => {
          setCurrent(e.key)
          navigate(e.key)
        }}
        selectedRoute={current}
        items={menuItems}
        forceSubMenuRender
        navigationMenuIsOnTop={isTabletPortraitOrMobile}
      />
      {children}
    </MyDefaultInsidePagePanel>
  )
}

export default ProfileMain
