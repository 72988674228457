import React, { useEffect, useRef, useState } from 'react'
import { Flex, Tag, Tooltip } from 'antd'
import dayjs from 'dayjs'
import { useNavigate, useParams } from 'react-router-dom'
import { getText, getTextServerError } from '../../../../../lang'
import { notifyError, notifySuccess } from '../../../../../utils/Notify'
import welcomeActions from '../../../../../store/modules/welcomeActions'
import { TABLE_DEFAULT_ROW_NUMBER } from '../../../../../devOptions'
import { useLocationLayout } from '../../../locationProvider/LocationProvider'
import MyDefaultPageLayout from '../../../../../components/MyDefaultPageLayout/MyDefaultPageLayout'
import ConfirmPopup from '../../../../../components/ConfirmPopup'
import SVGMyDefaultEditIcon from '../../../../../icons/SVG/SVGMyDefaultEditIcon'
import SVGMyDefaultDeleteIcon from '../../../../../icons/SVG/SVGMyDefaultDeleteIcon'
import MyDefaultTableText from '../../../../../components/MyDefaultTable/MyDefaultTableText/MyDefaultTableText'
import authActions from '../../../../../store/modules/authActions'
import MyDefaultTableTitle from '../../../../../components/MyDefaultTable/MyDefaultTableTitle/MyDefaultTableTitle'
import MyDefaultImage from '../../../../../components/MyDefaultImage/MyDefaultImage'
import { useLayout } from '../../../../../layout/LayoutProvider/LayoutProvider'
import './WelcomeGreetingsView.scss'
import MyDefaultButton from '../../../../../components/MyDefaultButton/MyDefaultButton'
import MyDefaultTableList from '../../../../../components/MyDefaultTable/MyDefaultTableList/MyDefaultTableList'

const WelcomeGreetingsView = () => {
  const { locationObj } = useLocationLayout()
  const { setPageTitle } = useLayout()
  const { _id } = useParams()
  const navigate = useNavigate()

  const [page, setPage] = useState(0)
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({
    list: [],
    total: 0,
  })

  const layoutRef = useRef(null)

  const getData = async () => {
    setLoading(true)
    let result = await welcomeActions.getAllList(
      page + 1,
      TABLE_DEFAULT_ROW_NUMBER,
      locationObj._id
    )

    if (result.success) {
      setData({
        list: result.data,
        total: result.total_items,
      })
    }
    setLoading(false)
  }

  useEffect(() => {
    setPageTitle(getText('WORD_WELCOME_GREETINGS'))
  }, [])

  useEffect(() => {
    if (locationObj._id) {
      getData()
    }
  }, [page, locationObj._id])

  return (
    <MyDefaultPageLayout ref={layoutRef} isAdvanced>
      <Flex
        justify='space-between'
        align='center'
        className='my_default_page_header'
      >
        <Flex align='center'>
          <p className='welcome_title'>{getText('WELCOME_GREETINGS_VIEW')} </p>
          {data.total && <div className='badge-count '>{data.total}</div>}
        </Flex>
        <MyDefaultButton
          buttonText={getText('WORD_ADD_NEW_GREETING')}
          onClick={() =>
            navigate(`/location/settings/${_id}/welcome-greetings/create`)
          }
          className={'my_default_page_header_item'}
        />
      </Flex>
      <MyDefaultTableList
        pageStart={page}
        loading={loading}
        dataWithKeys={data.list || []}
        getScrollParent={() => layoutRef?.current}
        onLoadMore={() => {
          if (!loading && data?.list?.length < data?.total) {
            setLoading(true)
            setPage((ov) => ov + 1)
          }
        }}
        totalDataCount={data?.total || 0}
        columns={[
          {
            title: getText('LABEL_TITLE'),
            dataIndex: 'title',
            render: (_, { title, imageUrl, videoUrl }) => {
              return (
                <Flex align='center' gap={10}>
                  {videoUrl ? (
                    <video
                      width={74}
                      height={50}
                      src={videoUrl}
                      controls={false}
                      muted
                      style={{ objectFit: 'cover' }}
                    >
                      <source src={videoUrl} type='video/mp4' />
                    </video>
                  ) : (
                    imageUrl && (
                      <MyDefaultImage
                        src={imageUrl}
                        width={74}
                        height={50}
                        disableMargin
                      />
                    )
                  )}
                  <MyDefaultTableTitle
                    title={title || 'Default Welcome Greetings view'}
                  />
                </Flex>
              )
            },
          },
          {
            title: getText('WORD_DATE_OF_CREATE_UPDATE'),
            dataIndex: 'updatedAt',
            render: (_, { updatedAt }) => {
              return (
                <MyDefaultTableText
                  text={dayjs(updatedAt)
                    .locale(authActions.getLanguage())
                    .format('D MMM, YYYY')}
                />
              )
            },
          },
          {
            title: getText('WORD_GREETINGS'),
            dataIndex: 'triggers',
            width: 200,
            render: (_, { triggers = [] }) => {
              return (
                <Flex gap={8} wrap='wrap'>
                  {triggers.slice(0, 2).map((trigger, index) =>
                    trigger.name.length > 20 ? (
                      <Tooltip key={index} title={trigger.name} placement='top'>
                        <Tag className='trigger-tag break-word'>
                          {`${trigger.name.substring(0, 20)}...`}
                        </Tag>
                      </Tooltip>
                    ) : (
                      <Tag key={index} className='trigger-tag break-word'>
                        {trigger.name}
                      </Tag>
                    )
                  )}
                  {triggers.length > 2 && (
                    <Tooltip
                      title={
                        <div className='triggers-tooltip'>
                          {triggers.slice(2).map((trigger, index) => (
                            <div key={index} className='trigger-item'>
                              {trigger.name}
                            </div>
                          ))}
                        </div>
                      }
                      placement='bottom'
                    >
                      <Tag className='trigger-tag'>+{triggers.length - 2}</Tag>
                    </Tooltip>
                  )}
                </Flex>
              )
            },
          },
          {
            title: '',
            dataIndex: 'actions',
            render: (_, item) => {
              return (
                <Flex align='center' gap={20} justify='end'>
                  {item.isDefault ? (
                    <>
                      <MyDefaultTableText
                        text={getText('AUTO_ASSIGNED_TO_NEW_GREETINGS')}
                      />
                      <SVGMyDefaultEditIcon
                        onClick={() =>
                          navigate(
                            `/location/settings/${_id}/welcome-greetings/edit`,
                            {
                              state: item,
                            }
                          )
                        }
                      />
                    </>
                  ) : (
                    <>
                      <SVGMyDefaultEditIcon
                        onClick={() =>
                          navigate(
                            `/location/settings/${_id}/welcome-greetings/edit`,
                            {
                              state: item,
                            }
                          )
                        }
                      />
                      <ConfirmPopup
                        title={getText(
                          'MSG_ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_GREETING'
                        )}
                        placement={'topRight'}
                        onConfirm={async () => {
                          const result = await welcomeActions.delete(item._id)
                          if (result.success) {
                            notifySuccess(
                              getText('MSG_GREETING_WAS_DELETED_SUCCESSFULLY')
                            )
                            setData((ov) => {
                              const newData = ov.list.filter(
                                (temp) => item._id !== temp._id
                              )
                              return {
                                total: ov.total,
                                list: [...newData],
                              }
                            })
                          } else {
                            notifyError(getTextServerError(result.errMsg))
                          }
                        }}
                        okText={getText('WORD_YES')}
                        cancelText={getText('WORD_NO')}
                        trigger={<SVGMyDefaultDeleteIcon />}
                      />
                    </>
                  )}
                </Flex>
              )
            },
          },
        ]}
      />
    </MyDefaultPageLayout>
  )
}

export default WelcomeGreetingsView
