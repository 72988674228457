import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import { LockOutlined } from '@ant-design/icons'
import { Input } from 'antd'
import { getText } from '../../lang'
import MyDefaultFormItem from '../MyDefaultFormItem/MyDefaultFormItem'

const MyDefaultInputPassword = forwardRef(
  (
    {
      isForm,
      formClassName,
      formStyle,
      label,
      name,
      hasFeedback = false,
      initialValue,
      validateStatus,
      hidden,
      required = false,
      errorMessage = `${getText('MSG_PLEASE_INPUT_PASSWORD')}!`,
      placeholder,
      maxLength,
      id,
      color,
      inputClassName,
      allowClear = true,
      disabled,
      onPressEnter,
      autoComplete = 'on',
      size,
      onBlur,
      onFocus,
      autoFocus,
      addonBefore,
      onClear,
      onChange,
      onClick,
      onKeyDown,
      onKeyUp,
      isFirst,
      isLast,
      type,
      passwordToConfirm,
      additionalRules,
    },
    ref
  ) => {
    const inputRef = useRef()

    useImperativeHandle(ref, () => ({
      autoFocus(position) {
        setTimeout(() => {
          inputRef.current.focus()
          if (position) {
            inputRef.current.input.selectionStart = position
            inputRef.current.input.selectionEnd = position
          }
        }, 10)
      },
    }))

    return isForm ? (
      <MyDefaultFormItem
        formClassName={formClassName}
        style={formStyle}
        label={label}
        name={name}
        hasFeedback={hasFeedback}
        initialValue={initialValue}
        validateStatus={validateStatus}
        hidden={hidden}
        isFirst={isFirst}
        isLast={isLast}
        rules={[
          { required: required, message: errorMessage },
          ...(passwordToConfirm
            ? [
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue(passwordToConfirm) === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject(
                      getText('THE_TWO_PASSWORDS_THAT_YOU_ENTERED_DO_NOT_MATCH')
                    )
                  },
                }),
              ]
            : []),
          ...(additionalRules || []),
        ]}
      >
        <Input.Password
          id={id}
          className={`my_default_input ${color || 'grey'} ${inputClassName || ''}`}
          placeholder={placeholder ? placeholder : label}
          allowClear={allowClear}
          disabled={disabled}
          prefix={<LockOutlined />}
          ref={inputRef}
          maxLength={maxLength}
          onPressEnter={onPressEnter}
          autoComplete={autoComplete}
          size={size}
          onBlur={onBlur}
          onFocus={onFocus}
          autoFocus={autoFocus}
          addonBefore={addonBefore}
          onChange={(e) => {
            if (e.target.value === '') {
              onClear && onClear()
            }
            onChange && onChange(e)
          }}
          onClick={onClick}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          type={type}
        />
      </MyDefaultFormItem>
    ) : (
      <Input.Password
        id={id}
        className={`my_default_input ${color || 'grey'} ${inputClassName || ''}`}
        placeholder={placeholder ? placeholder : label}
        allowClear={allowClear}
        disabled={disabled}
        prefix={<LockOutlined />}
        ref={inputRef}
        maxLength={maxLength}
        onPressEnter={onPressEnter}
        autoComplete={autoComplete}
        size={size}
        onBlur={onBlur}
        onFocus={onFocus}
        autoFocus={autoFocus}
        addonBefore={addonBefore}
        onChange={(e) => {
          if (e.target.value === '') {
            onClear && onClear()
          }
          onChange && onChange(e)
        }}
        onClick={onClick}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        type={type}
      />
    )
  }
)

export default MyDefaultInputPassword
