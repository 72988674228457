import authActions from '../store/modules/authActions'
import { redirect, axiosInstance } from './UrlHelper'
import utilObject from './utilObject'
import eventBus from './eventBus'
import { moveToLoginPage } from '.'

let isRefreshing = false
let failedQueue = []
let refreshTokenRequest = null

export const getRefreshToken = () => {
  if (!refreshTokenRequest) {
    refreshTokenRequest = authActions.refreshToken().finally(() => {
      refreshTokenRequest = null
    })
  }
  return refreshTokenRequest
}

const processQueue = (error, token = null) => {
  failedQueue.forEach((promise) => {
    if (error) {
      promise.reject(error)
    } else {
      promise.resolve(token)
    }
  })

  failedQueue = []
}

const refreshToken = {
  onFulfilled: (response) => response,
  onRejected: async (error) => {
    const status = error.response ? error.response.status : null
    const originalRequest = error.config

    if (status === 400) {
      return Promise.reject(error.response.data)
    } else if (status !== 401) {
      return Promise.reject(error.response.data)
    }

    if (error.response.config.url.includes('refresh-token')) {
      processQueue(error.response.data.message, null)
      moveToLoginPage(redirect, true)
      return Promise.reject(error.response.data.message)
    }

    if (isRefreshing) {
      return new Promise((resolve, reject) => failedQueue.push({ resolve, reject }))
        .then((token) => {
          originalRequest.headers.Authorization = `Bearer ${token}`
          return axiosInstance.request(originalRequest)
        })
        .catch((error) => {
          console.log(error)
        })
    }

    isRefreshing = true

    try {
      const response = await getRefreshToken()
      if (response.success) {
        processQueue(null, response.data.accessToken)
        originalRequest.headers.Authorization = `Bearer ${response.data.accessToken}`
        if (!utilObject.isEmpty(response.data.popupPasswordUpdatingData)) {
          eventBus.emit(
            'SHOW_UPDATE_PASSWORD_MODAL',
            response.data.popupPasswordUpdatingData
          )
        }
        return axiosInstance.request(originalRequest)
      } else {
        moveToLoginPage(redirect, true)
        processQueue(response.errMsg, null)
      }
    } finally {
      isRefreshing = false
    }
  },
}

export default refreshToken
