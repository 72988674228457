import React from 'react'
import { Popconfirm } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { getText } from '../../lang'
import './ConfirmPopup.scss'

const ConfirmPopup = ({
  className,
  title,
  visible,
  onVisibleChange,
  onConfirm,
  onCancel,
  placement = 'top',
  okText = getText('WORD_YES'),
  cancelText = getText('WORD_NO'),
  getPopupContainer,
  icon = <ExclamationCircleOutlined />,
  trigger,
  disabled,
}) => {
  return (
    <Popconfirm
      overlayClassName={`mat-confirm-popup ${className}`}
      title={title}
      open={visible}
      onOpenChange={onVisibleChange}
      onConfirm={onConfirm}
      onCancel={onCancel}
      placement={placement}
      okText={okText}
      disabled={disabled}
      cancelText={cancelText}
      getPopupContainer={getPopupContainer}
      icon={icon}
      okButtonProps={{
        type: 'primary',
        className: 'my_default_button my_default_button_primary',
        size: 'middle',
      }}
      cancelButtonProps={{
        type: 'cancel',
        className: 'my_default_button my_default_button_cancel',
        size: 'middle',
      }}
    >
      {trigger}
    </Popconfirm>
  )
}

export default ConfirmPopup
