import React, { memo, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { organizationSelector } from '../../store/selectors/selectors'
import organizationActions from '../../store/modules/organizationActions'
import { getText } from '../../lang'
import { GET_DATA_FETCH_SIZE } from '../../utils'
import MyDefaultSelect from './MyDefaultSelect'

const MyDefaultSelectSequences = ({
  isForm,
  color = 'grey',
  label,
  dropdownClassName,
  dropdownStyle,
  style,
  onSelectItem,
  value,
  onDropdownVisibleChange,
  placeholder,
  mode = 'multiple',
  required,
  name,
  labelInValue,
  message,
  isLast,
  organization_id,
}) => {
  const [data, setdata] = useState({ total: 0, list: [] })
  const [page, setPage] = useState(0)

  const organization = useSelector(organizationSelector)

  const fill = useCallback(async () => {
    const result = await organizationActions.getPageListSequence(
      page,
      GET_DATA_FETCH_SIZE,
      organization_id || organization._id
    )
    if (result.success) {
      const organizationsList = result.data.map((org) => ({
        value: org._id,
        label: org.name,
        ...org,
      }))

      const newListOrganizations =
        page > 0 ? [...data.list, ...organizationsList] : organizationsList

      setdata({
        list: newListOrganizations,
        total: result.max,
      })
    }
  }, [page, organization_id])

  useEffect(() => {
    fill()
  }, [fill])

  return (
    <MyDefaultSelect
      isForm={isForm}
      color={color}
      label={label}
      dropdownClassName={dropdownClassName}
      dropdownStyle={dropdownStyle}
      style={style}
      mode={mode}
      required={required}
      labelInValue={labelInValue}
      name={name}
      options={data.list}
      isLast={isLast}
      onLoadMore={() => {
        if (data.list.length < data.total) {
          setPage(page + 1)
        }
      }}
      message={message}
      showSearch={false}
      placeholder={placeholder || getText('LABEL_SELECTED_SEQUENCES')}
      onSelectItem={onSelectItem}
      value={value}
      onDropdownVisibleChange={(e) => {
        onDropdownVisibleChange && onDropdownVisibleChange(e)
      }}
      onClear={() => {
        setPage(0)
      }}
    />
  )
}

export default memo(MyDefaultSelectSequences)
