import React from 'react'
import { Flex } from 'antd'
import MyDefaultTagBlock from '../../MyDefaultTagBlock/MyDefaultTagBlock'
import './MyDefaultTableTags.scss'

const MyDefaultTableTags = ({ list }) => {
  const finalList = list.length > 3 ? list.slice(0, 3) : list

  return (
    <Flex align='center' gap={4} wrap={true}>
      {finalList.map((item, index) => (
        <MyDefaultTagBlock disableMargin key={index} title={item?.name || ''} />
      ))}
      {finalList.length < list.length && (
        <MyDefaultTagBlock
          disableMargin
          title={`+${list.length - finalList.length}`}
        />
      )}
    </Flex>
  )
}

export default MyDefaultTableTags
