import React from 'react'
import { Flex } from 'antd'
import { getText } from '../../../lang'
import Div from '../../Div/Div'

const MyDefaultIntegrationBlockFooter = ({
  connectedBy,
  enabled,
  customButton,
  account,
  accountType,
  customLeft,
}) => {
  return (
    <Flex
      className='my_default_integration_block--footer'
      align='center'
      justify='space-between'
      wrap={'wrap'}
      gap={10}
    >
      {customLeft && (
        <Div className={'my_default_integration_block--footer-left'}>
          {customLeft}
        </Div>
      )}
      {account ? (
        <Div className={'my_default_integration_block--footer-left'}>
          <React.Fragment>
            <span>{accountType}</span>
            <span>{account}</span>
          </React.Fragment>
        </Div>
      ) : null}
      <Div className={'my_default_integration_block--footer-left'}>
        {connectedBy ? (
          <React.Fragment>
            <span>
              {enabled
                ? getText('ACTION_CONNECTED_BY')
                : getText('ACTION_DISCONNECTED_BY')}
            </span>
            <span>
              {connectedBy === 'Automations'
                ? getText(
                    'TEXT_DISCONNECTED_DUE_TO_TOKEN_EXPIRATION_OR_PASSWORD_CHANGE'
                  )
                : connectedBy}
            </span>
          </React.Fragment>
        ) : (
          <Div />
        )}
      </Div>
      <Div className={'my_default_integration_block--footer-right'}>
        {customButton}
      </Div>
    </Flex>
  )
}

export default MyDefaultIntegrationBlockFooter
