import React, { memo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Divider } from 'antd'
import { getText } from '../../../../lang'
import Div from '../../../../components/Div/Div'
import MatToggle from '../../../../components/MatToggle'
import { ARCHIVED, MY_MESSAGES, ALL } from '../../utils/constants'
import { MULTIPLE_CONVERSATIONS_TYPE_OBJECT } from '../../utils/messageUtils'
import SVGResponseRequiredIcon from '../../../../icons/SVG/SVGResponseRequiredIcon'
import utilNumber from '../../../../utils/utilNumber'
import MatButton from '../../../../components/MatButton'
import SVGChatClose from '../../../../icons/SVG/SVGChatClose'
import SVGMessageSmartIcon from '../../../../icons/SVG/SVGMessageSmartIcon'
import SVGMessageUnreadIcon from '../../../../icons/SVG/SVGMessageUnreadIcon'
import './MessageConversationsEdit.scss'
import { needsAttentionMessagesSelector } from '../../../../store/selectors/selectors'

const MessageLeftEditConversation = ({
  setselectMultipleConversationsType,
  selectMultipleConversationsType,
  listConversation,
  unreadOnly,
  setUnreadOnly,
  smartBox,
  setSmartBox,
  messageType,
  totalCount,
  isLoading,
  onNeedsAttentionClick,
  isNeedsAttentionEnabled,
  organization,
}) => {
  const needsAttentionMessages = useSelector(needsAttentionMessagesSelector)

  const disableSelectAllButton =
    (!Boolean(selectMultipleConversationsType.selectButtonActionsType) &&
      !Boolean(
        messageType === ARCHIVED && selectMultipleConversationsType.convEdit
      )) ||
    isLoading

  const showActionRequiredFilter = Boolean(
    !selectMultipleConversationsType?.convEdit &&
      organization?.superhumanConfig?.enabled &&
      ((messageType === ALL && needsAttentionMessages.mainInboxCount) ||
        (messageType === MY_MESSAGES && needsAttentionMessages.myMessagesInbox))
  )

  useEffect(() => {
    if (isNeedsAttentionEnabled && !showActionRequiredFilter) {
      onNeedsAttentionClick()
    }
  }, [isNeedsAttentionEnabled, showActionRequiredFilter])

  return (
    <React.Fragment>
      <Div
        className='conversation_slider_with_edit_button'
        style={
          selectMultipleConversationsType.convEdit
            ? { flexDirection: 'row-reverse' }
            : {}
        }
      >
        {!selectMultipleConversationsType.convEdit && (
          <React.Fragment>
            <MatToggle
              label={getText('MSG_OPTION_SMART_INBOX')}
              value={smartBox}
              onChange={() => setSmartBox(!smartBox)}
              className={'message_page_toggle'}
              icon={<SVGMessageSmartIcon />}
            />
            <MatToggle
              label={getText('TEXT_UNREAD_MSG')}
              value={unreadOnly}
              onChange={() => setUnreadOnly(!unreadOnly)}
              className={'message_page_toggle'}
              icon={<SVGMessageUnreadIcon />}
            />
            {Boolean(showActionRequiredFilter) && (
              <MatToggle
                label={getText('WORD_RESPONSE_REQUIRED')}
                value={isNeedsAttentionEnabled}
                onChange={onNeedsAttentionClick}
                className={'message_page_toggle action_required'}
                icon={<SVGResponseRequiredIcon />}
                rightIcon={
                  <div className='counter'>
                    {messageType === ALL
                      ? utilNumber.getNumberForAbove99(
                          needsAttentionMessages.mainInboxCount
                        )
                      : messageType === MY_MESSAGES
                        ? utilNumber.getNumberForAbove99(
                            needsAttentionMessages.myMessagesInbox
                          )
                        : 0}
                  </div>
                }
              />
            )}
          </React.Fragment>
        )}
        {selectMultipleConversationsType.convEdit && (
          <React.Fragment>
            <MatButton
              className={'conversation_edit_button'}
              typeButton={'white'}
              icon={<SVGChatClose color={'#5B66EA'} />}
              onClick={() => {
                setselectMultipleConversationsType(
                  MULTIPLE_CONVERSATIONS_TYPE_OBJECT
                )
                setUnreadOnly(false)
              }}
            />
            <MatToggle
              label={getText('WORD_SELECT_ALL')}
              className='select_all_toggle'
              disabled={disableSelectAllButton}
              style={{
                backgroundColor: selectMultipleConversationsType.selectAll
                  ? '#5B66EA'
                  : '',
                color: selectMultipleConversationsType.selectAll ? '#fff' : '',
                opacity: disableSelectAllButton ? '.5' : '1',
              }}
              value={selectMultipleConversationsType.selectAll}
              onChange={() => {
                if (selectMultipleConversationsType.selectAll) {
                  setselectMultipleConversationsType({
                    ...MULTIPLE_CONVERSATIONS_TYPE_OBJECT,
                    convEdit: selectMultipleConversationsType.convEdit,
                    selectButtonActionsType:
                      selectMultipleConversationsType.selectButtonActionsType,
                    selectAll: false,
                  })
                } else {
                  setselectMultipleConversationsType({
                    ...selectMultipleConversationsType,
                    archiveConversationsIds: listConversation.map(
                      (item) => item._id
                    ),
                    unreadIds: listConversation.map((item) => item._id),
                    unArchiveConversationIds: listConversation.map(
                      (item) => item._id
                    ),
                    exceptConversationsIds: [],
                    archivedConversationsTotalCount: totalCount,
                    unreadConversationsTotalCount: totalCount,
                    unArchivedConversationsTotalCount: totalCount,
                    convEdit: selectMultipleConversationsType.convEdit,
                    selectButtonActionsType:
                      selectMultipleConversationsType.selectButtonActionsType,
                    selectAll: true,
                  })
                }
              }}
            />
          </React.Fragment>
        )}
      </Div>
      <Divider style={{ margin: '10px auto 0', minWidth: '90%', width: '90%' }} />
    </React.Fragment>
  )
}

export default memo(MessageLeftEditConversation)
