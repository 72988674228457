import React, { useEffect, useState } from 'react'
import { Flex, Form } from 'antd'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getText, getTextServerError } from '../../../../../lang'
import {
  getLongName,
  MEDIATYPES_ONLY_IMAGE,
  MEDIATYPES_ONLY_VIDEO,
} from '../../../../../utils'
import { notifyError, notifySuccess } from '../../../../../utils/Notify'
import greetingActions from '../../../../../store/modules/welcomeActions'
import { organizationSelector } from '../../../../../store/selectors/selectors'
import { useLayout } from '../../../../../layout/LayoutProvider/LayoutProvider'
import { CANCEL_BTN, IMAGE, SUBMIT_BTN, VIDEO } from '../../../../../devOptions'
import MyDefaultPageLayout from '../../../../../components/MyDefaultPageLayout/MyDefaultPageLayout'
import MyDefaultBlock from '../../../../../components/MyDefaultBlock/MyDefaultBlock'
import MyDefaultInsidePageTabs from '../../../../../components/MyDefaultInsidePageTabs/MyDefaultInsidePageTabs'
import MyDefaultInputText from '../../../../../components/MyDefaultInputText/MyDefaultInputText'
import MatForm from '../../../../../components/Form/MatForm'
import MyDefaultUploadWrapperBig from '../../../../../components/MyDefaultUpload/MyDefaultUploadWrapperBig/MyDefaultUploadWrapperBig'
import MyDefaultBlockSubtitle from '../../../../../components/MyDefaultBlock/MyDefaultBlockSubtitle'
import useDeviceCheck from '../../../../../utils/useDeviceCheck'
import MyDefaultSwitch from '../../../../../components/MyDefaultSwitch/MyDefaultSwitch'
import { useLocationLayout } from '../../../locationProvider/LocationProvider'
import WelcomeGreetingPreview from './WelcomeGreetingPreview'

const defaultValues = {
  popupMessage: {
    en: 'How can I help you today?',
    fr: 'Comment puis-je vous aider aujourd’hui?',
    sp: '¿Cómo puedo ayudarte hoy?',
  },
  buttonMessage: {
    en: 'Chat with us',
    fr: 'Discutez avec nous',
    sp: 'Chatea con nosotros',
  },
  showButtonOnMobile: true,
  showPopupOnMobile: true,
  showMediaOnMobile: true,
}

const AddEditWelcomeGreeting = ({ isNew }) => {
  const { setFooterButtons, setLoading } = useLayout()
  const organization = useSelector(organizationSelector)
  const { _id } = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const { isPageDrawerSize, isMobile } = useDeviceCheck()
  const languages = organization?.languages || []
  const [selectedLangTab, setSelectedLangTab] = useState(languages[0])
  const [settings, setSettings] = useState({})
  const { settings: matadorConnectSettings } = useLocationLayout()

  const item = location && location.state ? location.state : {}
  const greetingEditItem = !isNew && item ? item : {}

  const [form] = Form.useForm()

  const [mediaUpload, setMediaUpload] = useState({
    file: greetingEditItem.imageUrl || greetingEditItem.videoUrl || '',
    loading: false,
    type: greetingEditItem.imageUrl ? IMAGE : VIDEO,
  })

  useEffect(() => {
    setFooterButtons([{ type: CANCEL_BTN }, { type: SUBMIT_BTN }])
  }, [])

  useEffect(() => {
    if (!item && location.pathname.includes('edit')) {
      navigate(`/location/settings/${_id}/welcome-greetings`)
    }
  }, [location.pathname])

  const handleStartUpload = () => {
    setMediaUpload((prev) => ({ ...prev, loading: true }))
  }

  const handleChange = (file) => {
    if (file.status !== 'done') return

    const fileType = file.mediaContentType
    setMediaUpload({
      file: file.mediaUrl,
      loading: false,
      type: fileType?.includes('video') ? 'video' : 'image',
    })
  }

  const handleDelete = () => {
    setMediaUpload({
      file: '',
      loading: false,
      type: '',
    })
  }

  const getTextValue = (fieldName) => {
    const value = form.getFieldValue(fieldName)

    const en = value?.find((x) => x.language === 'en')?.text || ''
    const fr = value?.find((x) => x.language === 'fr')?.text || ''
    const sp = value?.find((x) => x.language === 'sp')?.text || ''
    return {
      ...(en && { en }),
      ...(fr && { fr }),
      ...(sp && { sp }),
    }
  }

  const onFinish = async (values) => {
    setLoading(true)

    const media = mediaUpload?.file
      ? {
          imageUrl: mediaUpload.type === 'image' ? mediaUpload.file : '',
          videoUrl: mediaUpload.type === 'video' ? mediaUpload.file : '',
        }
      : {
          imageUrl: '',
          videoUrl: '',
        }

    const obj = {
      locationId: _id,
      title: values.title,
      buttonMessage: getTextValue('buttonMessage'),
      popupMessage: getTextValue('popupMessage'),
      showPopupOnMobile: values.showPopupOnMobile,
      showMediaOnMobile: values.showMediaOnMobile,
      showButtonOnMobile: values.showButtonOnMobile,
      ...media,
    }

    let result
    if (isNew) {
      result = await greetingActions.insert(obj)
    } else {
      delete obj.locationId
      result = await greetingActions.update(obj, greetingEditItem._id)
    }

    if (result.success) {
      notifySuccess(getText('MSG_ITEM_SAVED_SUCCESSFULLY'))
      navigate(`/location/settings/${_id}/welcome-greetings`)
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
    setLoading(false)
  }

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields.length > 0) {
      notifyError(errorInfo.errorFields[0].errors[0])
    }
  }

  const handleFormValuesChange = (_, allValues) => {
    setSettings(allValues)
  }

  return (
    <MyDefaultPageLayout
      disableHeader
      title={getText(isNew ? 'WORD_ADD_NEW_GREETING' : 'WORD_EDIT_GREETING')}
    >
      <Flex gap={20} align='start' vertical={isPageDrawerSize}>
        <MatForm
          form={form}
          onFinish={onFinish}
          fullWidth
          maxWidth
          onFinishFailed={onFinishFailed}
          onValuesChange={handleFormValuesChange}
          initialValues={{
            title: greetingEditItem.title || '',
            buttonMessage: organization.languages.map((lng) => {
              return {
                text: item?._id
                  ? greetingEditItem.buttonMessage[lng]
                  : defaultValues.buttonMessage[lng],
                language: lng,
              }
            }),
            popupMessage: organization.languages.map((lng) => {
              return {
                text: item?._id
                  ? greetingEditItem.popupMessage[lng]
                  : defaultValues.popupMessage[lng],
                language: lng,
              }
            }),
            showPopupOnMobile: greetingEditItem?._id
              ? greetingEditItem.showPopupOnMobile
              : true,
            showMediaOnMobile: greetingEditItem._id
              ? greetingEditItem.showMediaOnMobile
              : true,
            showButtonOnMobile: greetingEditItem._id
              ? greetingEditItem.showButtonOnMobile
              : true,
            imageUrl: greetingEditItem.imageUrl || '',
            videoUrl: greetingEditItem.videoUrl || '',
          }}
        >
          <MyDefaultBlock>
            <MyDefaultInputText
              isForm
              placeholder={getText('WORD_NAME')}
              label={getText('WORD_NAME')}
              name='title'
              required
            />

            <MyDefaultInsidePageTabs
              isFirst
              onChange={(index) => {
                setSelectedLangTab(organization.languages[index])
              }}
              items={organization?.languages?.map((lng, index) => {
                return {
                  label: getLongName(lng),
                  key: index,
                  forceRender: true,
                  children: (
                    <>
                      <MyDefaultInputText
                        hidden
                        isForm
                        name={['buttonMessage', index, 'language']}
                      />
                      <MyDefaultInputText
                        isForm
                        placeholder={getText('TEXT_INITIAL_BUTTON_PLACEHOLDER')}
                        label={getText('WORD_INITIAL_BUTTON_MESSAGE')}
                        name={['buttonMessage', index, 'text']}
                        errorMessage={getText(
                          'ERROR_PLEASE_INPUT_INITIAL_BUTTON_MSG'
                        ).replace('[language]', getLongName(lng))}
                        maxLength={40}
                      />
                      <MyDefaultSwitch
                        noText
                        isForm
                        name='showButtonOnMobile'
                        title={getText('SHOW_INITIAL_BUTTON_TEXT_ON_MOBILE')}
                        titleStyle={{ fontSize: 14 }}
                        className='mb-4'
                      />

                      <MyDefaultInputText
                        hidden
                        isForm
                        name={['popupMessage', index, 'language']}
                      />
                      <MyDefaultInputText
                        isForm
                        placeholder={getText('TEXT_ADDITIONAL_POPUP_PLACEHOLDER')}
                        label={getText('WORD_ADDITIONAL_POPUP_MESSAGE')}
                        name={['popupMessage', index, 'text']}
                        errorMessage={getText(
                          'ERROR_PLEASE_INPUT_ADDITIONAL_POPUP_MSG'
                        ).replace('[language]', getLongName(lng))}
                        maxLength={100}
                      />
                      <MyDefaultSwitch
                        noText
                        isFirst
                        isForm
                        name='showPopupOnMobile'
                        titleStyle={{ fontSize: 14 }}
                        fullWidth={isMobile}
                        title={getText('SHOW_POPUP_ON_MOBILE')}
                        className='mb-4'
                      />
                      <MyDefaultBlockSubtitle
                        subtitle={getText('WORD_IMAGE_OR_VIDEO')}
                      />
                      <MyDefaultUploadWrapperBig
                        listType={'text'}
                        mediaCount={1}
                        videoSize={20}
                        storiesVideos
                        onStartUpload={() => handleStartUpload('video')}
                        showUploadList={false}
                        loading={mediaUpload.loading}
                        onUpload={(file) => handleChange(file, 'video')}
                        beforeUploadProps
                        mediaTypes={
                          MEDIATYPES_ONLY_VIDEO + ', ' + MEDIATYPES_ONLY_IMAGE
                        }
                        subtitle={getText('GREETING_IMAGE_VIDEO_NOTE')}
                        onDelete={() => handleDelete('video')}
                        uploadedItemObj={
                          mediaUpload?.file
                            ? {
                                showPreview: true,
                                type: mediaUpload.type,
                                url: mediaUpload.file,
                              }
                            : null
                        }
                        modalTitle={`${getText('ACTION_EDIT')} ${getText('WORD_VIDEO')}`}
                        disableMargins
                        autoPlay={false}
                        videoDurationLimit={7}
                        isPreview
                      />
                      <MyDefaultSwitch
                        noText
                        isForm
                        name='showMediaOnMobile'
                        title={getText('SHOW_MEDIA_ON_MOBILE')}
                        className={`mb-4 ${mediaUpload.file ? '' : 'd-none'}`}
                      />
                    </>
                  ),
                }
              })}
            />
          </MyDefaultBlock>
        </MatForm>

        <div
          style={{
            minWidth: 'auto',
            width: '100%',
          }}
        >
          <WelcomeGreetingPreview
            settings={{
              mediaType: mediaUpload.type,
              mediaUrl: mediaUpload.file,
              ...settings,
              ...form.getFieldsValue(),
            }}
            selectedLanguage={selectedLangTab}
            primaryColor={matadorConnectSettings?.color}
            secondaryColor={matadorConnectSettings?.secondaryColor}
          />
        </div>
      </Flex>
    </MyDefaultPageLayout>
  )
}

export default AddEditWelcomeGreeting
