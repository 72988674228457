import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width='22'
    height='22'
    viewBox='0 0 22 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M11.0007 1.83203C5.94982 1.83203 1.83398 5.94786 1.83398 10.9987C1.83398 16.0495 5.94982 20.1654 11.0007 20.1654C16.0515 20.1654 20.1673 16.0495 20.1673 10.9987C20.1673 5.94786 16.0515 1.83203 11.0007 1.83203ZM14.0807 13.107C14.3465 13.3729 14.3465 13.8129 14.0807 14.0787C13.9432 14.2162 13.769 14.2804 13.5948 14.2804C13.4207 14.2804 13.2465 14.2162 13.109 14.0787L11.0007 11.9704L8.89232 14.0787C8.75482 14.2162 8.58065 14.2804 8.40648 14.2804C8.23232 14.2804 8.05815 14.2162 7.92065 14.0787C7.65482 13.8129 7.65482 13.3729 7.92065 13.107L10.029 10.9987L7.92065 8.89036C7.65482 8.62453 7.65482 8.18453 7.92065 7.9187C8.18648 7.65286 8.62648 7.65286 8.89232 7.9187L11.0007 10.027L13.109 7.9187C13.3748 7.65286 13.8148 7.65286 14.0807 7.9187C14.3465 8.18453 14.3465 8.62453 14.0807 8.89036L11.9723 10.9987L14.0807 13.107Z'
      fill={props.color || '#A3A1B7'}
    />
  </svg>
)

const SVGCloseRound = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGCloseRound
