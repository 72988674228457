import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Divider, Flex, Form } from 'antd'
import { useParams } from 'react-router-dom'
import { organizationSelector } from '../../../../store/selectors/selectors'
import { useLayout } from '../../../../layout/LayoutProvider/LayoutProvider'
import { getLongName, replaceWords } from '../../../../utils'
import { getText, getTextServerError } from '../../../../lang'
import { CONNECT_URL } from '../../../../config'
import MatForm from '../../../../components/Form/MatForm'
import { notifyError, notifySuccess } from '../../../../utils/Notify'
import locationActions from '../../../../store/modules/locationActions'
import { CANCEL_BTN, SUBMIT_BTN } from '../../../../devOptions'
import LoadingSpinner from '../../../../components/LoadingSpinner'
import MyDefaultPageLayout from '../../../../components/MyDefaultPageLayout/MyDefaultPageLayout'
import useDeviceCheck from '../../../../utils/useDeviceCheck'
import MyDefaultBlock from '../../../../components/MyDefaultBlock/MyDefaultBlock'
import MyDefaultInsidePageTabs from '../../../../components/MyDefaultInsidePageTabs/MyDefaultInsidePageTabs'
import MyDefaultInputText from '../../../../components/MyDefaultInputText/MyDefaultInputText'
import MyDefaultBlockSubtitle from '../../../../components/MyDefaultBlock/MyDefaultBlockSubtitle'
import MyDefaultSwitch from '../../../../components/MyDefaultSwitch/MyDefaultSwitch'
import MyDefaultColorPicker from '../../../../components/MyDefaultColorPicker/MyDefaultColorPicker'
import MyDefaultInputNumber from '../../../../components/MyDefaultInputNumber/MyDefaultInputNumber'
import MyDefaultCopyTextarea from '../../../../components/MyDefaultCopyTextarea/MyDefaultCopyTextarea'
import MyDefaultUrlConditions from '../../../../components/MyDefaultUrlConditions/MyDefaultUrlConditions'
import IntegratedCtaInstructions from './integratedCtaInstructions'
import CtaPreview from './Preview'

const IntegratedCta = () => {
  const { _id } = useParams()
  const organization = useSelector(organizationSelector)
  const languages = organization?.languages || []
  const { setFooterButtons, setLoading, setPageTitle } = useLayout()
  const { isPageDrawerSize, isMobile } = useDeviceCheck()

  const [selectedLangTab, setSelectedLangTab] = useState(languages[0])
  const [settingTextCopy, setSettingTextCopy] = useState('')
  const [primaryColor, setPrimaryColor] = useState('#5CBF00')
  const [textColor, setTextColor] = useState('white')
  const [isInitialTextMessageBold, setIsInitialTextMessageBold] = useState(false)
  const [width, setWidth] = useState(300)
  const [borderRadius, setBorderRadius] = useState(8)
  const [height, setHeight] = useState(55)
  const [fontSize, setFontSize] = useState(12)
  const [disabledWidth, setDisabledWidth] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [settings, setSettings] = useState()
  const [urls, setUrls] = useState([])

  const [form] = Form.useForm()

  const getData = async () => {
    setIsLoading(true)
    const result = await locationActions.getIntegratedCta(_id)
    if (result.success) {
      setSettings(result.data)
      setFooterButtons([
        {
          type: CANCEL_BTN,
        },
        {
          type: SUBMIT_BTN,
        },
      ])
      setPageTitle(getText('INTEGRATED_CTA'))
      setSelectedLangTab(languages[0])
      setSettingTextCopy(result.data.initialTextMessage)
      setTextColor(result.data.textColor)
      setPrimaryColor(result.data.primaryColor)
      setDisabledWidth(result.data.fullWidth)
      setHeight(result.data.height)
      setWidth(result.data.width)
      setBorderRadius(result.data.borderRadius)
      setFontSize(result.data.fontSize)
      setIsInitialTextMessageBold(result.data.isInitialTextMessageBold)
      setUrls(result.data.conditions.url)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    getData()
  }, [])

  const onFinish = async () => {
    setLoading(true)
    const allValues = form.getFieldsValue(true)
    const {
      borderRadius,
      fontSize,
      fullWidth,
      height,
      width,
      textColor,
      primaryColor,
      termsLink,
      isInitialTextMessageBold,
    } = allValues

    let deviceSetting
    if (allValues.showMobile && allValues.showDesktop) {
      deviceSetting = 'ALL'
    } else if (allValues.showDesktop) {
      deviceSetting = 'DESKTOP'
    } else if (allValues.showMobile) {
      deviceSetting = 'MOBILE'
    } else {
      deviceSetting = 'NONE'
    }

    const payload = {
      device: deviceSetting,
      initialTextMessage: settingTextCopy,
      isInitialTextMessageBold,
      borderRadius,
      fontSize,
      fullWidth,
      height,
      width,
      textColor,
      primaryColor,
      status: 'ACTIVE',
      conditions: {
        url: urls,
      },
      termsLink,
    }

    const result = await locationActions.saveIntegratedCta(payload, _id)
    if (result.success) {
      notifySuccess(getText('CTA_SAVED_SUCCESSFULLY'))
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
    setLoading(false)
  }

  const onFinishFailed = (errorInfo) => {
    for (const lng of languages) {
      if (!settingTextCopy[lng].initialTextMessage) {
        const errorText = replaceWords(
          getText('TEXT_VARIABLE_REQUIRED_BOTH_LANGUAGE'),
          {
            missingVariable: getText('WORD_CTA_INITIAL_MESSAGE'),
            missingLanguage: getLongName(lng),
          }
        )
        return notifyError(errorText)
      }
    }
    if (errorInfo.errorFields.length > 0) {
      notifyError(errorInfo.errorFields[0].errors[0])
    }
  }

  const getHtmlCode = () => {
    return `<div class="matador-widget" data-id="${_id}"></div>`
  }

  const getStyleCode = () => {
    return `<link href="${CONNECT_URL}/widget/index.css" rel="stylesheet"></link>`
  }

  const getScriptCode = () => {
    return `<script src="${CONNECT_URL}/widget/index.js"></script>`
  }

  const getInitialTextMessage = (lang) => {
    const data = settingTextCopy.find((x) => x.language === lang)

    if (data) {
      return data.text
    }
    return ''
  }

  if (isLoading || !_id) {
    return <LoadingSpinner />
  }

  const setSettingTextCopyValue = (lang, text) => {
    const index = settingTextCopy.findIndex((x) => x.language === lang)
    const currentData = settingTextCopy

    if (index || index === 0) {
      currentData[index].text = text
      setSettingTextCopy([...currentData])
    }
  }

  return (
    <MyDefaultPageLayout disableHeader>
      <Flex gap={20} align='start' vertical={isPageDrawerSize}>
        <MatForm
          fullWidth
          maxWidth
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{
            initialTextMessage: {
              en: getInitialTextMessage('en'),
              fr: getInitialTextMessage('fr'),
              sp: getInitialTextMessage('sp'),
            },
            isInitialTextMessageBold: isInitialTextMessageBold,
            showMobile: settings.device === 'ALL' || settings.device === 'MOBILE',
            showDesktop: settings.device === 'ALL' || settings.device === 'DESKTOP',
            height: settings.height,
            width: settings.width,
            borderRadius: settings.borderRadius,
            fontSize: settings.fontSize,
            fullWidth: settings.fullWidth,
            textColor: settings.textColor,
            primaryColor: settings.primaryColor,
            termsLink: settings.termsLink,
          }}
        >
          <MyDefaultBlock fullWidth>
            <MyDefaultInsidePageTabs
              isFirst
              onChange={(index) => {
                setSelectedLangTab(organization.languages[index])
              }}
              items={organization?.languages?.map((lng, index) => {
                return {
                  label: getLongName(lng),
                  key: index,
                  forceRender: true,
                  children: (
                    <MyDefaultInputText
                      isForm
                      label={getText('WORD_CTA_INITIAL_MESSAGE')}
                      name={['initialTextMessage', lng]}
                      onChange={(e) => {
                        e.persist()
                        setSettingTextCopyValue(lng, e.target.value)
                      }}
                      required
                      errorMessage={getText('ERR_VALIDATION_REQUIRED')}
                    />
                  ),
                }
              })}
            />
            <Divider style={{ margin: '0 0 20px 0' }} />
            <MyDefaultInputText
              isForm
              label={getText('WORD_TERMS_LINK')}
              name='termsLink'
            />
            <MyDefaultBlockSubtitle subtitle={getText('WORD_VISIBILTY')} />
            <MyDefaultSwitch
              noText
              isFirst
              isForm
              name='showMobile'
              titleStyle={{ fontSize: 14 }}
              fullWidth={isMobile}
              title={getText('LABEL_SHOW_WIDGET_MOBILE')}
            />
            <MyDefaultSwitch
              noText
              isForm
              isLast
              name='showDesktop'
              titleStyle={{ fontSize: 14 }}
              fullWidth={isMobile}
              title={getText('LABEL_SHOW_WIDGET_DESKTOP')}
            />
            <Divider style={{ margin: '12px 0 20px 0' }} />
            <Flex align='center' gap={50}>
              <MyDefaultColorPicker
                label={getText('WORD_PRIMARY_COLOR')}
                value={primaryColor}
                onChangeColor={(newColor) => {
                  setPrimaryColor(newColor)
                  form.setFieldsValue({
                    primaryColor: newColor,
                  })
                }}
              />
              <MyDefaultColorPicker
                label={getText('LABEL_TEXT_COLOR')}
                value={textColor}
                onChangeColor={(newColor) => {
                  setTextColor(newColor)
                  form.setFieldsValue({
                    textColor: newColor,
                  })
                }}
              />
            </Flex>
            <Divider style={{ margin: '20px 0' }} />
            <Flex gap={isMobile ? 0 : 20} vertical={isMobile}>
              <MyDefaultInputNumber
                isForm
                name='width'
                fullWidth
                label={getText('LABEL_WIDTH')}
                onChange={(e) => setWidth(e.target.value)}
                disabled={disabledWidth}
              />
              <MyDefaultInputNumber
                isForm
                name='height'
                fullWidth
                label={getText('LABEL_HEIGHT')}
                onChange={(e) => setHeight(e.target.value)}
              />
              <MyDefaultInputNumber
                isForm
                name='fontSize'
                fullWidth
                label={getText('LABEL_FONT_SIZE')}
                onChange={(e) => setFontSize(e.target.value)}
              />
              <MyDefaultInputNumber
                isForm
                name='borderRadius'
                fullWidth
                label={getText('LABEL_BORDER_RADIUS')}
                onChange={(e) => setBorderRadius(e.target.value)}
              />
            </Flex>
            <MyDefaultSwitch
              noText
              isForm
              isFirst
              name='isInitialTextMessageBold'
              titleStyle={{ fontSize: 14 }}
              title={getText('LABEL_ENABLE_BOLD_TEXT')}
              onChangeSwitch={(value) => {
                setIsInitialTextMessageBold(value)
              }}
            />
            <MyDefaultSwitch
              noText
              isForm
              isLast
              name='fullWidth'
              titleStyle={{ fontSize: 14 }}
              title={getText('TEXT_RECOMMENDED_FOR_RESPONSIVENESS')}
              onChangeSwitch={(value) => {
                setDisabledWidth(value)
              }}
            />
          </MyDefaultBlock>
          <MyDefaultBlock fullWidth enableMarginTop>
            <MyDefaultCopyTextarea
              enableMargin
              copiedText={getHtmlCode()}
              label={getText('WORD_CTA_ADD_HTML_CODE')}
              autoSize={{ minRows: 3 }}
            />
            <MyDefaultCopyTextarea
              enableMargin
              copiedText={getStyleCode()}
              label={getText('WORD_CTA_ADD_STYLE')}
              autoSize={{ minRows: 3 }}
            />
            <MyDefaultCopyTextarea
              copiedText={getScriptCode()}
              label={getText('WORD_CTA_ADD_SCRIPT')}
              autoSize={{ minRows: 3 }}
            />
          </MyDefaultBlock>
          <MyDefaultBlock fullWidth enableMarginTop>
            <MyDefaultUrlConditions urls={urls} setUrls={setUrls} />
            <Divider style={{ margin: '20px 0' }} />
            <IntegratedCtaInstructions id={_id} />
          </MyDefaultBlock>
        </MatForm>
        <MyDefaultBlock position={'sticky'} top={0} fullWidth>
          <CtaPreview
            title={getInitialTextMessage(selectedLangTab)}
            color={primaryColor}
            textColor={textColor}
            height={height}
            width={disabledWidth ? `350px` : `${width}px`}
            borderRadius={borderRadius}
            fontSize={fontSize}
            isInitialTextMessageBold={isInitialTextMessageBold}
          />
        </MyDefaultBlock>
      </Flex>
    </MyDefaultPageLayout>
  )
}

export default IntegratedCta
