module.exports = {
  DEALER_SOCKET: 'dealerSocket',
  DEALER_PEAK: 'dealerPeak',
  ELEADS: 'eLeads',
  VIN_SOLUTION: 'vinSolution',
  ONE_EIGHTY: 'oneEighty',
  ACTIVIX: 'activix',
  PBS: 'pbs',
  TEKION: 'tekion',
  MOMENTUM: 'momentum',
  XTIME: 'xTime',
}
