import React, { useState } from 'react'
import { Modal, Upload } from 'antd'
import axios from 'axios'
import { FilePdfOutlined } from '@ant-design/icons'
import { getText, getTextServerError } from '../../lang'
import { getPDFFile } from '../../utils'
import { uploadAndFetchGETFile, uploadAndFetchPOStFile } from '../../utils/UrlHelper'
import { notifyError } from '../../utils/Notify'
import './UploadFiles.scss'

const UploadFiles = (props) => {
  const {
    mediaCount,
    mediaData,
    onUpload,
    onStartUpload,
    mediaTypes,
    onRemoveItem,
    size,
    beforeUploadProps,
    conversation,
    onChangeStatus,
    fromMessagePage,
    targerType,
    targetId,
    acceptImage,
    customType,
  } = props

  const [showModal, setShowModal] = useState(false)
  const [previewImage, setPreviewImage] = useState('')

  const uploadMedia = (media) => {
    const { file } = media
    let NewFile = {
      url: '',
      mediaUrl: '',
      name: file.name,
      id: new Date().getTime(),
      uid: new Date().getTime(),
      _id: new Date().getTime() + '_' + file.name,
      key: new Date().getTime(),
      mediaContentType: file.type,
      status: 'uploading',
      percent: 68,
    }
    if (!file.type.includes('video')) {
      if (NewFile.name.includes('.heic')) {
        NewFile.mediaContentType = 'image/heic'
      }
      if (NewFile.name.includes('.heif')) {
        NewFile.mediaContentType = 'image/heif'
      }
      onStartUpload(NewFile)
    }
    const reader = new FileReader()

    // reader.onabort = () => console.log('file reading was aborted')
    // reader.onerror = () => console.log('file reading has failed')
    // eslint-disable-next-line no-loop-func

    if (file.type.includes('video')) {
      reader.onload = async () => {
        window.URL = window.URL || window.webkitURL
        var video = document.createElement('video')
        video.preload = 'metadata'
        video.onloadedmetadata = async function () {
          window.URL.revokeObjectURL(video.src)
          if (video.duration > 180) {
            notifyError(
              getTextServerError(
                getText('MSG_VIDEO_DURATION_MUST_BE_LESS_THAT_3_MINUES')
              )
            )
            return
          } else {
            let obj = {
              fileName: file.name,
              fileType: file.type,
              targetType: targerType,
              targetId: targetId,
            }
            let result = await uploadAndFetchGETFile('/videos/signed-url', obj)
            if (result.success) {
              onChangeStatus && onChangeStatus('uploading')
              const data = new FormData()
              Object.keys(NewFile).forEach((key) => {
                data.append(key, NewFile[key])
              })
              const uploadS3 = await axios.put(result.data.signedRequest, file)
              if (uploadS3.status === 200) {
                NewFile.status = 'done'
                NewFile.url = uploadS3.config.url
                NewFile.mediaUrl = uploadS3.config.url
                NewFile.file = uploadS3.config.data
                NewFile._id = result.data.videoUploadId
                onUpload(NewFile)
                onChangeStatus && onChangeStatus('done')
              } else {
                let newObj = {
                  videoUploadId: result.data.videoUploadId,
                  status: 'failed',
                }
                let resultPost = await uploadAndFetchPOStFile(
                  '/videos/process-video',
                  newObj
                )
                if (!resultPost.success) {
                  notifyError(getTextServerError(resultPost.errMsg))
                  return
                }
              }
            } else {
              NewFile.status = 'error'
              notifyError(getTextServerError(result.errMsg))
            }
          }
        }
        video.src = window.URL.createObjectURL(
          new Blob([file], { type: 'video/mp4' })
        )
      }
    } else {
      if (fromMessagePage) {
        reader.onload = () => {
          uploadAndFetchPOStFile('/s3/upload-private-file', { file }).then(
            async (res) => {
              if (res.success) {
                NewFile.status = 'done'
                NewFile.url = res.data.url
                NewFile.mediaUrl = res.data.signedUrl
                NewFile.file = file
                onUpload(NewFile)
              } else {
                NewFile.status = 'error'
                notifyError(getTextServerError(res.errMsg))
              }
            }
          )
        }
      } else {
        reader.onload = () => {
          uploadAndFetchPOStFile('/s3/upload-image', { file }).then((res) => {
            if (res.success) {
              NewFile.status = 'done'
              NewFile.url = res.data.url
              NewFile.mediaUrl = res.data.url
              NewFile.file = file
              onUpload(NewFile)
            } else {
              NewFile.status = 'error'
              notifyError(getTextServerError(res.errMsg))
            }
          })
        }
      }
    }

    reader.readAsArrayBuffer(file)
  }

  const beforeUpload = (file) => {
    let fileSize = size
    let isVideo = false
    const isCorrectType = mediaTypes.includes(file.type)
    if (isCorrectType) {
      if (file.type.includes('video/')) {
        isVideo = true
      }
    }

    if (customType && customType.length) {
      customType.map((item) => {
        if (item.type === file.type) {
          fileSize = item.size
        }
        return item
      })
    }

    if (!isCorrectType) {
      if (props.errMsg) {
        notifyError(props.errMsg)
      } else {
        notifyError(
          `${getText('TEXT_YOU_CAN_ONLY_UPLOAD')} ${props.mediaTypes
            .replaceAll('image/', '')
            .replaceAll('video/', '')
            .toUpperCase()} ${getText('WORD_FILE')}!`
        )
      }
    }

    const isCorrectSize = isVideo
      ? file.size / 1024 < 500000.0
      : file.size / 1024 < fileSize * 1024
    const isMB = file.size / 1024 < 1024 ? 'KB' : 'MB'
    const isSizeKB = isMB === 'MB' ? fileSize : fileSize * 1000
    if (fileSize) {
      if (!isCorrectSize && isCorrectType) {
        notifyError(
          `${getText('TEXT_IMAGE_MUST_SMALLER_THAN')} ${
            isVideo ? 500 : isSizeKB
          } ${isMB}`
        )
      }
    }

    return props.size ? isCorrectSize && isCorrectType : isCorrectType
  }

  return (
    <>
      <Upload
        accept={
          (conversation &&
            conversation.kind !== 'gbm' &&
            conversation &&
            !conversation.isNew) ||
          acceptImage
            ? props.accept
            : props.forMessanger
        }
        mediaTypes={mediaTypes}
        customRequest={uploadMedia}
        multiple={props.multiple}
        listType={props.listype || 'picture-card'}
        fileList={mediaData}
        showUploadList={props.showUploadList}
        isImageUrl={(img) => {
          if (!img.mediaContentType.includes('application/pdf')) {
            return true
          }
        }}
        iconRender={(img) => {
          if (img.mediaContentType.includes('application/pdf')) {
            return <FilePdfOutlined style={{ fontSize: 50, color: '#ff0000' }} />
          }
        }}
        className={`mat-upload ${props.className || ''}`}
        id={props.id}
        disabled={props.disabled}
        beforeUpload={beforeUploadProps && beforeUpload}
        onChange={(e) => {
          if (mediaData.length !== e.fileList.length) {
            if (e.file.status && e.file.status === 'removed') {
              onRemoveItem(e.fileList)
            }
          }
        }}
        onPreview={(file) => {
          if (file.mediaContentType.includes('application/pdf')) {
            getPDFFile(file.mediaUrl)
          } else {
            setShowModal(true)
            setPreviewImage(file.mediaUrl)
          }
        }}
      >
        {mediaData.length < mediaCount &&
          (props.children || getText('WORD_PLUS_ADD_IMAGE'))}
      </Upload>
      <Modal
        open={showModal}
        footer={null}
        onCancel={() => {
          setShowModal(false)
        }}
      >
        <img
          alt='example'
          style={{
            width: '100%',
          }}
          src={previewImage}
        />
      </Modal>
    </>
  )
}

UploadFiles.defaultProps = {
  mediaData: [],
  mediaCount: 10,
  multiple: true,
  accept:
    '.jpeg,.jpg,.png,.pdf,.gif,.HEIC,.heic,.HEIF,.heif,.mp4,.mov,.avi,.webm,.xls, .xlsx',
  forMessanger: '.jpeg,.jpg,.png,.pdf,.gif,.HEIC,.heic,.HEIF,.heif',
  mediaTypes:
    'application/pdf, image/jpeg, image/heic, image/heif, image/HEIC, image/jpg, image/png, image/gif, video/mp4, video/mov, video/avi, video/webm, video/quicktime, application/vnd.ms-excel, text/vcard, text/x-vcard, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  size: 20,
  media: [
    {
      uid: '-1',
      name: 'image.png',
      status: 'done',
      url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    },
  ],
  onUpload: (file) => {},
  onStartUpload: (file) => {},
  onRemoveItem: (file) => {},
}

export default React.memo(UploadFiles)
