import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { userSelector } from '../../../store/selectors/selectors'
import organizationActions from '../../../store/modules/organizationActions'
import MyDefaultTreeSelect from '../MyDefaultTreeSelect'

export function transformData(data, enabledOnlyOrganizations) {
  return data.map((item) => {
    const newItem = {}
    Object.keys(item).forEach((key) => {
      if (enabledOnlyOrganizations) {
        newItem.disabled = true
      }
      if (key === 'name') {
        newItem['title'] = item[key]
        newItem['label'] = item[key]
      } else if (key === '_id') {
        newItem['value'] = item[key]
      } else if (key === 'locations') {
        newItem['children'] = transformData(item[key])
      } else {
        newItem[key] = item[key]
      }
    })
    return newItem
  })
}

const MyDefaultTreeSelectLocationOrganization = ({
  message,
  placeholder,
  label,
  name,
  isForm,
  onChange,
  value,
  required,
  labelInValue,
  onSelect,
  onDeselect,
  customOnChange,
  isLast,
}) => {
  const user = useSelector(userSelector)

  const hasAccessToMultipleOrganizations =
    user.accessibleOrganizations && user.accessibleOrganizations.length > 0

  const [isDataTree, setIsDataTree] = useState(true)
  const [treeData, setTreeData] = useState({
    list: [],
    total: 0,
  })

  useEffect(() => {
    if (user.isSuperAdmin || hasAccessToMultipleOrganizations) {
      const fill = async () => {
        let arr = []
        let total = 0
        let page = 0

        const getData = async () => {
          const result = await organizationActions.getOrganizationsLocationsList(
            page,
            100,
            '',
            {
              enabled: true,
            }
          )

          if (result.success) {
            const list = transformData(result.data)
            arr = page === 0 ? list : [...arr, ...list]
            total = result.max
            if (arr.length < result.max) {
              page++
              await getData()
            }
          }
          setIsDataTree(false)
        }
        await getData()
        setTreeData({
          list: arr,
          total: total,
        })
      }
      fill()
    }
  }, [])

  return (
    <MyDefaultTreeSelect
      isForm={isForm}
      fullWidth
      isLast={isLast}
      loading={isDataTree}
      name={name}
      label={label}
      treeData={treeData.list || []}
      treeCheckable={true}
      placeholder={placeholder}
      message={message}
      required={required}
      onChange={(e) => {
        if (customOnChange) {
          customOnChange(e, treeData.list)
        } else {
          onChange?.(e)
        }
      }}
      onSelect={onSelect}
      onDeselect={onDeselect}
      value={value}
      labelInValue={labelInValue}
    />
  )
}

export default MyDefaultTreeSelectLocationOrganization
