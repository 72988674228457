import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = () => {
  return (
    <svg
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.5835 8.2487C15.5835 11.7962 12.5035 14.6654 8.7085 14.6654L7.856 15.692L7.35183 16.2971C6.921 16.8104 6.09599 16.7004 5.81183 16.0862L4.5835 13.382C2.91516 12.2087 1.8335 10.3479 1.8335 8.2487C1.8335 4.7012 4.9135 1.83203 8.7085 1.83203C11.4768 1.83203 13.8693 3.36287 14.9418 5.56287C15.3543 6.37871 15.5835 7.2862 15.5835 8.2487Z'
        stroke='#0E0E0E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.1668 11.7866C20.1668 13.8858 19.0851 15.7467 17.4168 16.92L16.1885 19.6241C15.9043 20.2383 15.0793 20.3575 14.6484 19.835L13.2918 18.2033C11.0734 18.2033 9.09346 17.2225 7.85596 15.6917L8.70845 14.665C12.5034 14.665 15.5834 11.7958 15.5834 8.24833C15.5834 7.28583 15.3543 6.37833 14.9418 5.5625C17.9393 6.25 20.1668 8.77998 20.1668 11.7866Z'
        stroke='#0E0E0E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.4165 8.25H10.9998'
        stroke='#0E0E0E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

const SVGOrgCustomAskConsMessage = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGOrgCustomAskConsMessage
