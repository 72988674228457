import React, { useEffect, useState } from 'react'
import { Flex } from 'antd'
import { getText } from '../../../../../lang'
import locationActions from '../../../../../store/modules/locationActions'
import { getGoogleWarningData } from '../../../components/locationUtils'
import MyDefaultIntegrationBlockFooter from '../../../../../components/MyDefaultIntegrationBlock/MyDefaultIntegrationBlockFooter/MyDefaultIntegrationBlockFooter'
import GmailIntegration from './GmailIntegration'
import OfficeIntegration from './OfficeIntegration'

const ConnectGmailOrOffice365 = ({ locationObj }) => {
  const [otherIntegrationsData, setOtherIntegrationsData] = useState([])
  const [bookingLoginType, setBookingLoginType] = useState({
    googleLogged: false,
    microsoftLogged: false,
  })

  const checkConectedAccount = async () => {
    let resultLoginType = await locationActions.checkLoginForInvitationBooking(
      locationObj._id
    )
    if (resultLoginType.success) {
      setBookingLoginType(resultLoginType.data)
    }
  }

  useEffect(() => {
    if (locationObj._id) {
      checkConectedAccount()
    }
  }, [locationObj._id])

  return (
    <React.Fragment>
      <MyDefaultIntegrationBlockFooter
        enabled={locationObj.featuresConnectingData?.calendarInvite?.isConnected}
        connectedBy={
          locationObj.featuresConnectingData?.calendarInvite?.userData?.fullName
        }
        account={bookingLoginType?.appointmentEmail}
        accountType={getText('TEXT_LOGGED_ACCOUNT')}
      />
      <Flex align='center' gap={10} style={{ marginTop: 10 }} wrap={'wrap'}>
        <GmailIntegration
          bookingLoginType={bookingLoginType}
          locationObj={locationObj}
          checkConectedAccount={checkConectedAccount}
          otherIntegrationsData={otherIntegrationsData}
          getGoogleWarningData={getGoogleWarningData}
          setOtherIntegrationsData={setOtherIntegrationsData}
        />
        <OfficeIntegration
          bookingLoginType={bookingLoginType}
          locationObj={locationObj}
          checkConectedAccount={checkConectedAccount}
        />
      </Flex>
    </React.Fragment>
  )
}

export default ConnectGmailOrOffice365
