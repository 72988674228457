import React, { forwardRef, memo, useCallback, useState } from 'react'
import { debounce } from 'lodash'
import { CloseCircleFilled, CloseOutlined } from '@ant-design/icons'
import { Empty, Select } from 'antd'
import { getText } from '../../lang'
import SVGSelectArrow from '../../icons/SVG/SVGSelectArrow'
import LoadingSpinner from '../LoadingSpinner'
import MyDefaultFormItem from '../MyDefaultFormItem/MyDefaultFormItem'
import MyDefaultBlockSubtitle from '../MyDefaultBlock/MyDefaultBlockSubtitle'
import MyDefaultLabelWithTooltip from '../MyDefaultFormItem/MyDefaultLabelWithTooltip/MyDefaultLabelWithTooltip'
import Div from '../Div/Div'
import './MyDefaultSelect.scss'

const MyDefaultSelect = forwardRef(
  (
    {
      onSearch,
      className,
      defaultValue,
      loading,
      labelInValue,
      value,
      dropdownClassName,
      mode,
      placement,
      disabled,
      showSearch,
      maxTagCount,
      suffixIcon,
      dropdownMatchSelectWidth,
      dropdownStyle,
      menuItemSelectedIcon,
      onDropdownVisibleChange,
      getPopupContainer,
      onSelect,
      options,
      onSelectItem,
      onDeselect,
      onPopupScroll,
      onLoadMore,
      customOption,
      children,
      onClick,
      onBlur,
      onFocus,
      onChange,
      isForm,
      required = false,
      message,
      name,
      label,
      hasFeedback,
      formStyle,
      initialValue,
      isLast,
      isFirst,
      filterOption,
      color = 'grey',
      placeholder = getText('WORD_SELECT_ITEM'),
      onChangeItem,
      usePreviousValueOnChange,
      onClear,
      enableMargin,
      fullWidth,
      selectStyle,
      customizedColorsObj,
      hidden,
      tooltipText,
      dropdownRender,
    },
    ref
  ) => {
    const [isOpen, setisOpen] = useState(false)

    const debouncedChange = useCallback(
      debounce((options) => onSearch && onSearch(options), 700),
      []
    )

    const rules = useCallback(() => {
      const rules = []
      if (required && !disabled) {
        rules.push({
          required: required,
          message: message
            ? message
            : label || placeholder
              ? `${getText('WORD_PLEASE_SELECT')} ${label || placeholder}`
              : message || `${getText('WORD_PLEASE_SELECT_ITEM')}!`,
        })
      }
      return rules
    }, [required, disabled])

    const onChangeSelect = (e) => {
      onChange && onChange(e)
      if (onChangeItem && options) {
        if (mode === 'multiple') {
          let find = []
          if (usePreviousValueOnChange) {
            const duplicateIds = {}

            for (const oo of options) {
              if (!duplicateIds[oo.value]) {
                if (labelInValue) {
                  let includes = false
                  for (const element of e) {
                    if (element.value === oo.value) {
                      includes = true
                      break
                    }
                  }
                  if (includes) {
                    find.push(oo)
                    duplicateIds[oo.value] = true
                  }
                } else {
                  if (e.includes(oo.value)) {
                    find.push(oo)
                    duplicateIds[oo.value] = true
                  }
                }
              }
            }
            for (const oo of value) {
              if (!duplicateIds[oo.value]) {
                if (labelInValue) {
                  let includes = false
                  for (const element of e) {
                    if (element.value === oo.value) {
                      includes = true
                      break
                    }
                  }
                  if (includes) {
                    find.push(oo)
                    duplicateIds[oo.value] = true
                  }
                } else {
                  if (e.includes(oo.value)) {
                    find.push(oo)
                    duplicateIds[oo.value] = true
                  }
                }
              }
            }
          } else {
            find = options.filter((oo) => {
              return e.includes(oo.value)
            })
          }
          if (find) {
            onChangeItem(e, find)
          }
        } else {
          let find = options.find((oo) => {
            return oo.value === e
          })
          if (find) {
            onChangeItem(e, find)
          }
        }
      }
    }

    const tagRender = (propsTag) => {
      const { label, value, onClose } = propsTag
      const onPreventMouseDown = (event) => {
        event.preventDefault()
        event.stopPropagation()
      }
      let backgroundColor = 'var(--mainColorLighter)'
      let color = 'var(--mainColor)'
      if (customizedColorsObj?.[value]) {
        backgroundColor = customizedColorsObj?.[value]?.backgroundColor
        color = customizedColorsObj?.[value]?.textColor
      }
      return (
        <span
          className='ant-select-selection-item'
          style={{
            backgroundColor: backgroundColor,
            color: color,
          }}
        >
          <span className='ant-select-selection-item-content'>{label}</span>
          <span
            className='ant-select-selection-item-remove'
            onClick={onClose}
            onMouseDown={onPreventMouseDown}
          >
            <CloseOutlined style={{ color: color }} />
          </span>
        </span>
      )
    }

    return isForm ? (
      <MyDefaultFormItem
        label={
          tooltipText ? (
            <MyDefaultLabelWithTooltip
              label={label}
              tooltipText={tooltipText}
              required={required}
            />
          ) : (
            label
          )
        }
        name={name}
        hasFeedback={hasFeedback}
        rules={rules()}
        formStyle={formStyle}
        initialValue={initialValue}
        isLast={isLast}
        isFirst={isFirst}
        fullWidth={fullWidth}
        hidden={hidden}
      >
        <Select
          className={`my_default_select ${fullWidth ? 'full_width' : ''} ${suffixIcon ? 'custom_icon' : ''} ${color} ${className || ''}`}
          ref={ref}
          style={selectStyle}
          defaultValue={defaultValue}
          value={loading ? getText('WORD_LOADING') : value}
          labelInValue={labelInValue}
          popupClassName={`my_default_select--dropdown ${dropdownClassName || ''}`}
          loading={loading}
          mode={mode}
          tagRender={tagRender}
          placement={placement}
          dropdownRender={dropdownRender}
          placeholder={placeholder}
          disabled={disabled}
          showSearch={showSearch}
          onSearch={showSearch && debouncedChange}
          maxTagCount={maxTagCount}
          filterOption={
            Boolean(filterOption)
              ? filterOption
              : (input, option) => {
                  return (
                    option.children
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toString().toLowerCase()) > -1
                  )
                }
          }
          suffixIcon={
            suffixIcon || (
              <SVGSelectArrow
                style={{
                  pointerEvents: 'none',
                  transform: isOpen ? 'rotate(-180deg)' : 'rotate(0deg)',
                }}
              />
            )
          }
          allowClear={
            onClear
              ? {
                  clearIcon: <CloseCircleFilled onClick={onClear} />,
                }
              : false
          }
          onClear={onClear}
          popupMatchSelectWidth={dropdownMatchSelectWidth}
          dropdownStyle={dropdownStyle}
          menuItemSelectedIcon={menuItemSelectedIcon}
          onDropdownVisibleChange={(e) => {
            setisOpen(e)
            onDropdownVisibleChange && onDropdownVisibleChange(e)
          }}
          getPopupContainer={
            getPopupContainer ? getPopupContainer : (trigger) => trigger.parentNode
          }
          onSelect={(value) => {
            onSelect && onSelect(value)
            let found = options?.find((option) => option.value === value)
            if (found) {
              onSelectItem && onSelectItem(found)
            }
          }}
          onDeselect={onDeselect}
          notFoundContent={
            loading ? (
              <LoadingSpinner />
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )
          }
          onPopupScroll={(e) => {
            e.persist()
            onPopupScroll && onPopupScroll(e)
            if (
              Math.ceil(e.target.scrollTop + e.target.offsetHeight) ===
              e.target.scrollHeight
            ) {
              onLoadMore && onLoadMore()
            }
          }}
          options={customOption && options}
          onChange={onChangeSelect}
          onFocus={onFocus}
          onBlur={onBlur}
          onClick={onClick}
        >
          {customOption
            ? children
            : (options || []).map((option, index) => (
                <Select.Option
                  key={option.value + '_' + index}
                  value={option.value}
                  className={
                    option.label === value ? 'ant-select-item-option-selected' : ''
                  }
                  disabled={option.label === value}
                >
                  {option.label}
                </Select.Option>
              ))}
        </Select>
      </MyDefaultFormItem>
    ) : (
      <Div style={{ width: fullWidth ? '100%' : 'auto' }}>
        {label && <MyDefaultBlockSubtitle subtitle={label} required={required} />}
        <Select
          className={`my_default_select ${fullWidth ? 'full_width' : ''} ${enableMargin ? 'enable_margin' : ''} ${suffixIcon ? 'custom_icon' : ''} ${color} ${className || ''}`}
          ref={ref}
          style={selectStyle}
          defaultValue={defaultValue}
          value={loading ? getText('WORD_LOADING') : value}
          labelInValue={labelInValue}
          popupClassName={`my_default_select--dropdown ${dropdownClassName || ''}`}
          loading={loading}
          mode={mode}
          tagRender={tagRender}
          placement={placement}
          dropdownRender={dropdownRender}
          placeholder={placeholder}
          disabled={disabled}
          showSearch={showSearch}
          onSearch={showSearch && debouncedChange}
          maxTagCount={maxTagCount}
          filterOption={
            Boolean(filterOption)
              ? filterOption
              : (input, option) => {
                  return (
                    option.children
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toString().toLowerCase()) > -1
                  )
                }
          }
          suffixIcon={
            suffixIcon || (
              <SVGSelectArrow
                style={{
                  pointerEvents: 'none',
                  transform: isOpen ? 'rotate(-180deg)' : 'rotate(0deg)',
                }}
              />
            )
          }
          allowClear={
            onClear
              ? {
                  clearIcon: <CloseCircleFilled onClick={onClear} />,
                }
              : false
          }
          onClear={onClear}
          popupMatchSelectWidth={dropdownMatchSelectWidth}
          dropdownStyle={dropdownStyle}
          menuItemSelectedIcon={menuItemSelectedIcon}
          onDropdownVisibleChange={(e) => {
            setisOpen(e)
            onDropdownVisibleChange && onDropdownVisibleChange(e)
          }}
          getPopupContainer={
            getPopupContainer ? getPopupContainer : (trigger) => trigger.parentNode
          }
          onSelect={(value) => {
            onSelect && onSelect(value)
            let found = options.find((option) => option.value === value)
            if (found) {
              onSelectItem && onSelectItem(found)
            }
          }}
          onDeselect={onDeselect}
          notFoundContent={
            loading ? (
              <LoadingSpinner />
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )
          }
          onPopupScroll={(e) => {
            e.persist()
            onPopupScroll && onPopupScroll(e)
            if (
              Math.ceil(e.target.scrollTop + e.target.offsetHeight) ===
              e.target.scrollHeight
            ) {
              onLoadMore && onLoadMore()
            }
          }}
          options={customOption && options}
          onChange={onChangeSelect}
          onFocus={onFocus}
          onBlur={onBlur}
          onClick={onClick}
        >
          {customOption
            ? children
            : options.map((option, index) => (
                <Select.Option
                  key={option.value + '_' + index}
                  value={option.value}
                  title={''}
                  className={
                    option.label === value ? 'ant-select-item-option-selected' : ''
                  }
                  disabled={option.label === value}
                >
                  {option.label}
                </Select.Option>
              ))}
        </Select>
      </Div>
    )
  }
)

export default memo(MyDefaultSelect)
