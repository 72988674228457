import React, { useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { organizationSelector } from '../../../../store/selectors/selectors'
import { getText } from '../../../../lang'
import MyDefaultSendFollowUp from '../../../../components/MyDefaultSendFollowUp/MyDefaultSendFollowUp'
import useDeviceCheck from '../../../../utils/useDeviceCheck'
import useDynamicWatch from '../../../../utils/useDynamicWatch'
import { IMAGE, IMMEDIATELY } from '../../../../devOptions'
import MyDefaultBlockSubtitle from '../../../../components/MyDefaultBlock/MyDefaultBlockSubtitle'
import MyDefaultRadioTabs from '../../../../components/MyDefaultRadioButton/MyDefaultRadioTabs/MyDefaultRadioTabs'
import { getLongName, MEDIATYPES_ONLY_IMAGE } from '../../../../utils'
import Div from '../../../../components/Div/Div'
import MyDefaultTextarea from '../../../../components/MyDefaultTextarea/MyDefaultTextarea'
import MyDefaultAddVariablesInMessage from '../../../../components/MyDefaultAddVariablesInMessage/MyDefaultAddVariablesInMessage'
import MyDefaultUploadWrapperBig from '../../../../components/MyDefaultUpload/MyDefaultUploadWrapperBig/MyDefaultUploadWrapperBig'

const CalendarInvitePanel = ({
  selectedCalendarSequence,
  selectedIndex,
  form,
  field,
  setSelectedCalendarSequence,
}) => {
  const organization = useSelector(organizationSelector)
  const { isTabletPortraitOrMobile } = useDeviceCheck()

  const [selectedLangTab, setSelectedLangTab] = useState(organization?.languages[0])
  const [currentCursorPosition, setCurrentCursorPosition] = useState(0)

  const textAreaInputRef = useRef()

  const selectedUnit = useDynamicWatch(
    ['calendar_invite_sequence', field, 'delay', 'unit'],
    form
  )

  const isImmediately = useMemo(() => selectedUnit === IMMEDIATELY, [selectedUnit])

  const listVariables = [
    {
      label: 'customer_first_name',
      value: '{{customer_first_name}}',
    },
    {
      label: 'customer_last_name',
      value: '{{customer_last_name}}',
    },
    {
      label: 'appointment_date',
      value: '{{appointment_date}}',
    },
    {
      label: 'location_name',
      value: '{{location_name}}',
    },
    {
      label: 'appointment_time',
      value: '{{appointment_time}}',
    },
  ]

  const timeUnitsOptions = [
    {
      label: getText('WORD_DAYS').toLowerCase(),
      value: 'days',
    },
    {
      label: getText('WORD_HOURS'),
      value: 'hours',
    },
    {
      label: getText('WORD_MINUTES'),
      value: 'minutes',
    },
    ...(selectedCalendarSequence.isFirstItem
      ? [
          {
            label: getText('WORD_IMMEDIATELY'),
            value: 'immediately',
          },
        ]
      : []),
  ]

  const langOptions = organization?.languages.map((lang) => {
    return {
      label: getLongName(lang),
      value: lang,
    }
  })

  const handleClickVariable = (variable) => {
    const formSelectedName = [
      'calendar_invite_sequence',
      field,
      'text',
      selectedLangTab,
    ]
    const currentContent = form.getFieldValue(formSelectedName)
    const newContent = [
      currentContent && currentContent.slice(0, currentCursorPosition),
      variable,
      currentContent && currentContent.slice(currentCursorPosition),
    ].join('')

    form.setFieldValue(formSelectedName, newContent)
    setCurrentCursorPosition(currentCursorPosition + variable.length)
    textAreaInputRef.current &&
      textAreaInputRef.current.autoFocus(currentCursorPosition + variable.length)
  }

  const getUploadData = (obj) => {
    let calendar_invite_sequence = form.getFieldValue('calendar_invite_sequence')
    let find = calendar_invite_sequence.find((_, index) => index === selectedIndex)
    const images = { loading: {}, image: {} }
    organization?.languages.forEach((lang) => {
      if (!selectedCalendarSequence.image[lang]) {
        images.image[lang] = obj[lang]
        images.loading[lang] = obj.loading[lang]
      } else if (obj[lang] === '') {
        images.image[lang] = ''
        images.loading[lang] = selectedCalendarSequence.loading
          ? selectedCalendarSequence.loading[lang]
          : false
      } else {
        images.image[lang] = selectedCalendarSequence.image[lang]
        images.loading[lang] = selectedCalendarSequence.loading
          ? selectedCalendarSequence.loading[lang]
          : false
      }
    })
    if (find) {
      Object.assign(find, { ...images })
      form.setFieldsValue({
        calendar_invite_sequence: calendar_invite_sequence,
      })
      setSelectedCalendarSequence(find)
    }
  }

  const getUploadedItemObj = () => {
    const obj = { type: '', url: '' }

    if (selectedCalendarSequence?.image?.[selectedLangTab]) {
      obj.type = IMAGE
      obj.url = selectedCalendarSequence?.image[selectedLangTab]
    }

    return obj
  }

  const uploadedItemObj = getUploadedItemObj()

  return (
    <React.Fragment>
      <MyDefaultSendFollowUp
        vertical={isTabletPortraitOrMobile}
        disableMargin={false}
        beforeText={getText(
          selectedCalendarSequence.isFirstItem
            ? 'TEXT_FIRST_AUTO_MESSAGE_FULL'
            : 'TEXT_SEND_FOLLOWUP'
        )}
        afterText={getText(
          selectedCalendarSequence.isFirstItem
            ? 'TEXT_WITH_CALENDAR_INVITE'
            : 'WORD_BEFORE_APPOINTMENT'
        )}
        options={timeUnitsOptions || []}
        inputName={['calendar_invite_sequence', field, 'delay', 'value']}
        selectName={['calendar_invite_sequence', field, 'delay', 'unit']}
        hideNumber={isImmediately}
        inputInitialValue={selectedCalendarSequence?.delay?.value}
        selectInitialValue={{
          unit: isImmediately ? IMMEDIATELY : selectedCalendarSequence?.delay?.unit,
        }}
        disableSelect={selectedCalendarSequence.isFirstItem}
        min={!isImmediately ? 1 : 0}
        errorMessage={getText('ERR_PLEASE_FILL_TIME_UNIT')}
      />
      <MyDefaultBlockSubtitle
        subtitle={<Div />}
        rightContent={
          <MyDefaultRadioTabs
            radioOptions={langOptions}
            value={selectedLangTab}
            onChange={(lang) => {
              setSelectedLangTab(lang)
            }}
          />
        }
      />
      <MyDefaultTextarea
        isForm
        ref={textAreaInputRef}
        name={['calendar_invite_sequence', field, 'text', selectedLangTab]}
        initialValue={{
          [selectedLangTab]: selectedCalendarSequence.text[selectedLangTab],
        }}
        placeholder={getText('WORD_MESSAGE')}
        onClick={(e) => setCurrentCursorPosition(e.target.selectionStart)}
        onKeyUp={(e) => setCurrentCursorPosition(e.target.selectionStart)}
        onChange={(e) => setCurrentCursorPosition(e.target.selectionStart)}
      />
      <MyDefaultAddVariablesInMessage
        onClick={(variable) => handleClickVariable(variable)}
        data={listVariables}
      />
      <MyDefaultUploadWrapperBig
        disableMargins
        size={20}
        onStartUpload={() => getUploadData({ loading: { [selectedLangTab]: true } })}
        showUploadList={false}
        loading={selectedCalendarSequence?.loading?.[selectedLangTab]}
        mediaCount={1}
        onUpload={(file) =>
          getUploadData({
            loading: { [selectedLangTab]: false },
            [selectedLangTab]: file.url,
          })
        }
        beforeUploadProps
        onChangeStatus={() =>
          getUploadData({ loading: { [selectedLangTab]: true } })
        }
        listType={'text'}
        mediaTypes={MEDIATYPES_ONLY_IMAGE}
        disabled={!selectedCalendarSequence.id}
        onDelete={() =>
          getUploadData({
            loading: { [selectedLangTab]: false },
            [selectedLangTab]: '',
          })
        }
        uploadedItemObj={uploadedItemObj}
      />
    </React.Fragment>
  )
}

export default CalendarInvitePanel
