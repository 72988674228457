import React from 'react'
import { Flex, Form } from 'antd'
import { getText } from '../../lang'
import MyDefaultTransparentButton from '../MyDefaultButton/MyDefaultTransparentButton/MyDefaultTransparentButton'
import MyDefaultAddAutoReplyMessageItem from './MyDefaultAddAutoReplyMessageItem'
import './MyDefaultAddAutoReplyMessage.scss'

const MyDefaultAddAutoReplyMessage = ({
  name,
  form,
  isAddButtonVisible,
  onAdd,
  onDelete,
  onClick,
  itemTitle,
  itemSubTitle,
  itemIcon,
  getSelectedIndex,
  deleteTitle,
  itemPercentageDetails,
  itemAdditionalIcon,
  beforeIndex,
  afterIndex,
  addTitle,
}) => {
  return (
    <Form.List name={name}>
      {(fields, { add, remove }) => {
        return (
          <Flex gap={8} vertical>
            {fields.map((field, index) => {
              if (beforeIndex && index >= beforeIndex) return null
              if (afterIndex && index < afterIndex) return null
              const item = form.getFieldValue(name)[field.name]
              return (
                <MyDefaultAddAutoReplyMessageItem
                  key={index}
                  item={item}
                  onDelete={(e) => {
                    onDelete && onDelete(e, remove, index, item)
                  }}
                  onClick={(e) => {
                    onClick && onClick(e, item)
                  }}
                  title={itemTitle}
                  subTitle={itemSubTitle}
                  icon={itemIcon}
                  isActive={index === getSelectedIndex}
                  deleteTitle={deleteTitle}
                  percentageDetails={itemPercentageDetails}
                  additionalIcon={itemAdditionalIcon}
                />
              )
            })}

            {isAddButtonVisible && (
              <MyDefaultTransparentButton
                onClick={(e) => {
                  onAdd && onAdd(e, add)
                }}
                buttonText={
                  <Flex align='center' gap={6}>
                    + {addTitle || getText('TEXT_ADD_DRIP_SEQUENCE')}
                  </Flex>
                }
              />
            )}
          </Flex>
        )
      }}
    </Form.List>
  )
}

export default MyDefaultAddAutoReplyMessage
