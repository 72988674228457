import React, { useState } from 'react'
import { Form } from 'antd'
import { notifyError, notifySuccess } from '../../../../../utils/Notify'
import { urlServer } from '../../../../../utils/UrlHelper'
import nylasActions from '../../../../../store/modules/nylasActions'
import authActions from '../../../../../store/modules/authActions'
import { getText } from '../../../../../lang'
import MyDefaultIntegrationBlockFooter from '../../../../../components/MyDefaultIntegrationBlock/MyDefaultIntegrationBlockFooter/MyDefaultIntegrationBlockFooter'
import NylastIntegrationModal from './NylastIntegrationModal'
import NylastConnection from './NylastConnection'

const HandleNylasConnection = (props) => {
  const { location } = props

  const [visible, setVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [form] = Form.useForm()
  const hasNylasAccount =
    location.integrationInfo &&
    location.integrationInfo.nylas.email_address &&
    location.integrationInfo.nylas.provider

  const onFinish = async (emailObj) => {
    setIsLoading(true)

    const response = await nylasActions.connectNylasAccountForOrganization(
      location?._id,
      emailObj
    )
    if (response.success) {
      const redirectUrl = response.data.replace('{{api_url}}', urlServer())
      await authActions.refreshOrganization()
      // setTimeout for safari
      setTimeout(() => {
        window.open(redirectUrl, '_blank')
      }, 100)
    } else {
      notifyError(response.errMsg || 'Email connection redirect link error')
    }
    setIsLoading(false)
    setVisible(false)
  }

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields.length > 0) {
      notifyError(errorInfo.errorFields[0].errors[0])
    }
  }

  const disconnectNylasAccount = async () => {
    try {
      const response = await nylasActions.disconnectNylasAccountForOrganization(
        location?._id
      )
      if (response) {
        notifySuccess(getText('TEXT_YOU_SUCCESSFULLY_DISCONNECTED_YOUR_EMAIL'))
        location.featuresConnectingData.nylas = response.featuresConnectingData.nylas
        location.integrationInfo.nylas = {}
        await authActions.refreshOrganization()
      }
    } catch (e) {
      notifyError(
        `${getText('TEXT_EMAIL_DISCONNECT_ERROR')} ${JSON.stringify(e, null, 2)}`
      )
    }
  }

  return (
    <MyDefaultIntegrationBlockFooter
      enabled={location?.featuresConnectingData?.nylas?.isConnected}
      connectedBy={location?.featuresConnectingData?.nylas?.userData?.fullName}
      account={location.integrationInfo.nylas.email_address}
      accountType={getText('WORD_CONNECTED_EMAIL')}
      customButton={
        hasNylasAccount ? (
          <NylastConnection
            location={location}
            disconnectNylasAccount={disconnectNylasAccount}
          />
        ) : (
          <NylastIntegrationModal
            isLoading={isLoading}
            form={form}
            visible={visible}
            setVisible={setVisible}
            location={location}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          />
        )
      }
    />
  )
}

export default HandleNylasConnection
