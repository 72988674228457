import React, { useMemo, useState, useEffect, memo } from 'react'
import { Form, Tooltip } from 'antd'
import { getText } from '../../../lang'
import InputFormNumberSecondary from '../../../components/Form/InputFormNumber/InputFormNumberSecondary'
import SelectCheckedSecondary from '../../../components/Form/SelectChecked/SelectCheckedSecondary'
import MatCheckboxSecondary from '../../../components/MatCheckbox/MatCheckboxSecondary'
import SelectFromOrganizations from '../../../components/api/SelectFromOrganizations'
import TreeSelectFormSecondary from '../../../components/Form/TreeSelectForm/TreeSelectFormSecondary'
import SwitchForm from '../../../components/Form/SwitchForm'
import MatFormCheckboxGroup from '../../../components/Form/MatFormCheckBoxGroup'

const NotificationItem = (props) => {
  const {
    form,
    notification,
    switchText,
    isPhone,
    isEmail,
    receiverNotifications,
    isSuperAdmin,
    accessibleOrganizations,
    treeData,
    isNew,
  } = props
  const userEnabled = Form.useWatch('enabled', form)

  const enabledField = ['notifications', notification, 'enabled']
  const methodField = ['notifications', notification, 'method']
  const periodField = ['notifications', notification, 'period']
  const organizationsField = ['notifications', notification, 'organizations']

  const [isEnabled, setIsEnabled] = useState(form.getFieldValue(enabledField))
  const [enabledAllOrgs, setEnabledAllOrgs] = useState(
    form.getFieldValue(organizationsField)
  )

  useEffect(() => {
    setIsEnabled(form.getFieldValue(enabledField))
    if (
      form.getFieldValue(organizationsField) &&
      form.getFieldValue(organizationsField).length
    ) {
      setEnabledAllOrgs(false)
    } else {
      setEnabledAllOrgs(true)
    }
  }, [])

  useEffect(() => {
    if (userEnabled) {
      form.setFieldValue(['notifications', 'taggedInConversation', 'enabled'], true)
      setIsEnabled(form.getFieldValue(enabledField))
    }
  }, [userEnabled])

  const methodOptions = useMemo(() => {
    let options = [
      {
        label: getText('WORD_PUSH_NOTIFICATIONS'),
        value: 'push',
        disabled: false,
      },
      {
        label: getText('WORD_PHONE'),
        value: 'phone',
        disabled: !Boolean(isPhone),
      },
      {
        label: getText('WORD_EMAIL'),
        value: 'email',
        disabled: !Boolean(isEmail),
      },
    ]
    const newMethod = form.getFieldValue('notifications')
    if (newMethod[notification].method) {
      let index = -1
      if (isPhone === '') {
        index = newMethod[notification].method.indexOf('phone')
      } else if (isEmail === '') {
        index = newMethod[notification].method.indexOf('email')
      }
      if (index !== -1) {
        newMethod[notification].method.splice(index, 1)
        form.setFieldsValue({
          notifications: newMethod,
        })
      }
    }
    return isEnabled ? options : []
  }, [isPhone, isEmail, isEnabled])

  const handleAllOrgsToggle = (e) => {
    const value = e.target.checked
    setEnabledAllOrgs(value)
    if (value) {
      const newNotifications = form.getFieldValue('notifications')
      newNotifications[notification].organizations = []
      form.setFieldsValue({
        notifications: newNotifications,
      })
    }
  }

  const handleSwitchChange = (e) => {
    setIsEnabled(e)
    setEnabledAllOrgs(false)
    const newMethod = form.getFieldValue('notifications')
    if (newMethod[notification].method) {
      if (e) {
        newMethod[notification].method.push('push')
      } else {
        let index = newMethod[notification].method.indexOf('push')
        if (index !== -1) {
          newMethod[notification].method.splice(index, 1)
        }
      }
    }
    form.setFieldsValue({
      notifications: newMethod,
    })
  }

  return (
    <div className='notification-item'>
      {notification === 'taggedInConversation' ? (
        <Tooltip
          placement='topLeft'
          overlayStyle={{ zIndex: userEnabled || isNew ? 10000 : -10000 }}
          title={getText('TEXT_YOU_CANT_DISABLE_THIS_NOTIFICATION')}
        >
          <div />
          <SwitchForm
            name={enabledField}
            text={switchText}
            disabled={userEnabled || isNew}
            onChange={handleSwitchChange}
          />
        </Tooltip>
      ) : (
        <SwitchForm
          name={enabledField}
          text={switchText}
          onChange={handleSwitchChange}
        />
      )}
      {isEnabled && (
        <>
          {receiverNotifications && (
            <>
              {((treeData && treeData.length > 1) ||
                (accessibleOrganizations && accessibleOrganizations.length > 1)) && (
                <MatCheckboxSecondary
                  label={getText(
                    'TEXT_RECEIVE_NOTIFICATIONS_FROM_ALL_ORGANIZATIONS'
                  )}
                  className='all-orgs-checkbox'
                  onChange={handleAllOrgsToggle}
                  value={enabledAllOrgs}
                />
              )}
              {isSuperAdmin ? (
                <>
                  {[
                    'unreadMessage',
                    'unassignFromConversation',
                    'unhappyCustomer',
                  ].includes(notification) ? (
                    <TreeSelectFormSecondary
                      name={organizationsField}
                      label={getText(
                        'TEXT_WHAT_LOCATIONS_DO_YOU_WANT_RECEIVE_NOTIFICATIONS_FROM'
                      )}
                      treeData={treeData}
                      treeCheckable={true}
                      multipleNewStyle
                      placeholder={getText('WORD_SELECT_LOCATIONS')}
                    />
                  ) : (
                    <SelectFromOrganizations
                      secondarySelect
                      name={organizationsField}
                      label={getText(
                        'TEXT_WHAT_ORGANIZATIONS_DO_YOU_WANT_RECEIVE_NOTIFICATIONS_FROM'
                      )}
                      placeholder={getText('WORD_SELECT_ORGANIZATIONS')}
                      multipleNewStyle
                      mode={'multiple'}
                      dropdownStyle={{ zIndex: 10000 }}
                      showArrow
                      allowClear={false}
                    />
                  )}
                </>
              ) : (
                <>
                  {[
                    'unreadMessage',
                    'unassignFromConversation',
                    'unhappyCustomer',
                  ].includes(notification) ? (
                    <TreeSelectFormSecondary
                      name={organizationsField}
                      label={getText(
                        'TEXT_WHAT_LOCATIONS_DO_YOU_WANT_RECEIVE_NOTIFICATIONS_FROM'
                      )}
                      treeData={treeData}
                      treeCheckable={true}
                      multipleNewStyle
                      placeholder={getText('WORD_SELECT_LOCATIONS')}
                      message={getText(
                        'TEXT_AT_LEAST_ONE_LOCATION_MUST_BE_SELECTED'
                      )}
                      required={!enabledAllOrgs}
                      formClassName='select-orgs-dropdown'
                    />
                  ) : (
                    accessibleOrganizations &&
                    accessibleOrganizations.length > 1 && (
                      <SelectCheckedSecondary
                        name={organizationsField}
                        label={getText(
                          'TEXT_WHAT_ORGANIZATIONS_DO_YOU_WANT_RECEIVE_NOTIFICATIONS_FROM'
                        )}
                        placeholder={getText('WORD_SELECT_ORGANIZATIONS')}
                        option={
                          accessibleOrganizations && accessibleOrganizations.length
                            ? accessibleOrganizations
                            : []
                        }
                        message={getText(
                          'TEXT_AT_LEAST_ONE_ORGANIZATION_MUST_BE_SELECTED'
                        )}
                        mode='multiple'
                        multipleNewStyle
                        required={!enabledAllOrgs}
                        dropdownStyle={{ zIndex: 10000 }}
                        formClassName='select-orgs-dropdown'
                      />
                    )
                  )}
                </>
              )}
            </>
          )}
          {notification !== 'unhappyCustomer' && (
            <MatFormCheckboxGroup
              className='method-checkbox'
              required={isEnabled}
              errorMessage={getText('ERROR_PLEASE_CHOOSE_TYPE_OF_NOTIFICATION')}
              name={methodField}
              options={methodOptions}
            />
          )}
          {['unreadMessage', 'unassignFromConversation'].includes(notification) && (
            <div className='delay-notification'>
              <p>
                {getText(
                  'TEXT_RECEIVE_NOTIFICATION_IF_THE_CONVERSATION_IS_UNREAD_FOR'
                )}
              </p>
              <InputFormNumberSecondary
                name={periodField}
                placeholder={''}
                inputClassName='delay-input-notification'
                required
                min={['unreadMessage'].includes(notification) ? 0 : 1}
                hasFeedback={false}
                errorMessage={getText(
                  notification === 'unreadMessage'
                    ? 'TEXT_PLEASE_ENTER_THE_PERIOD_IN_MINUTES'
                    : 'TEXT_PLEASE_ENTER_THE_PERIOD_IN_MINUTES_BEING_UNASSIGNED_FROM_CONVERSATION'
                )}
              />
              <p>{getText('WORD_MINUTES')}</p>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default memo(NotificationItem)
