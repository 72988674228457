import React from 'react'
import { Upload } from 'antd'
import axios from 'axios'
import ImgCrop from 'antd-img-crop'
import { getText, getTextServerError } from '../../lang'
import { notifyError } from '../../utils/Notify'
import { uploadAndFetchGETFile, uploadAndFetchPOStFile } from '../../utils/UrlHelper'
import MyDefaultFormItem from '../MyDefaultFormItem/MyDefaultFormItem'
import './MyDefaultUpload.scss'

const MyDefaultUpload = (props) => {
  const {
    onStartUpload,
    targetType,
    targetId,
    onChangeStatus,
    onUpload,
    mediaTypes = 'application/pdf, image/jpeg, image/jpg, image/png, image/gif, video/mp4, video/mov, video/avi, video/webm, video/quicktime, image/heif',
    size = 20,
    beforeUploadProps,
    isPreview,
    modalTitle,
    storiesVideos,
    videoSize,
    customType,
    maxCount = 10,
    multiple = true,
    listType = 'picture-card',
    children,
    onRemoveItem,
    showUploadList,
    disabled,
    className,
    defaultFileList,
    accept = '.jpeg,.jpg,.png,.pdf,.gif,.HEIC,.heic,.HEIF,.heif,.mp4,.mov,.avi,.webm,.xls, .xlsx',
    getValueFromEvent,
    initialValue,
    hasFeedback,
    valuePropName,
    name,
    label,
    formStyle,
    formClassName,
    errMsg,
    disableMargins,
    fileList,
    itemRender,
    videoDurationLimit,
  } = props

  const uploadMedia = (media) => {
    const { file } = media
    let NewFile = {
      url: '',
      mediaUrl: '',
      name: file.name,
      id: targetId || new Date().getTime(),
      uid: new Date().getTime(),
      _id: targetId || new Date().getTime() + '_' + file.name,
      key: new Date().getTime(),
      mediaContentType: file.type,
      status: 'uploading',
      percent: 0,
    }
    if (!file.type.includes('video')) {
      if (NewFile.name.includes('.heic')) {
        NewFile.mediaContentType = 'image/heic'
      }
      if (NewFile.name.includes('.heif')) {
        NewFile.mediaContentType = 'image/heif'
      }
    }
    onStartUpload?.(NewFile)
    const reader = new FileReader()

    if (file.type.includes('video')) {
      reader.onload = async () => {
        window.URL = window.URL || window.webkitURL
        var video = document.createElement('video')
        video.preload = 'metadata'
        video.onloadedmetadata = async function () {
          window.URL.revokeObjectURL(video.src)
          if (video.duration > 180) {
            notifyError(
              getTextServerError(
                getText('MSG_VIDEO_DURATION_MUST_BE_LESS_THAT_3_MINUES')
              )
            )
            return
          } else {
            if (storiesVideos) {
              uploadAndFetchPOStFile('/s3/upload-image', { file }).then((res) => {
                if (res.success) {
                  NewFile.status = 'done'
                  NewFile.url = res.data.url
                  NewFile.mediaUrl = res.data.url
                  NewFile.file = file
                  onUpload(NewFile)
                } else {
                  NewFile.status = 'error'
                  notifyError(getTextServerError(res.errMsg))
                }
              })
            } else {
              let obj = {
                fileName: file.name,
                fileType: file.type,
                targetType: targetType,
                targetId: targetId,
              }
              let result = await uploadAndFetchGETFile('/videos/signed-url', obj)
              if (result.success) {
                onChangeStatus && onChangeStatus('uploading', obj)
                const data = new FormData()
                Object.keys(NewFile).forEach((key) => {
                  data.append(key, NewFile[key])
                })
                const uploadS3 = await axios.put(result.data.signedRequest, file)
                if (uploadS3.status === 200) {
                  NewFile.status = 'done'
                  NewFile.url = uploadS3.config.url
                  NewFile.mediaUrl = uploadS3.config.url
                  NewFile.file = uploadS3.config.data
                  NewFile._id = result.data.videoUploadId
                  onUpload(NewFile)
                  onChangeStatus && onChangeStatus('done', obj)
                } else {
                  let newObj = {
                    videoUploadId: result.data.videoUploadId,
                    status: 'failed',
                  }
                  let resultPost = await uploadAndFetchPOStFile(
                    '/videos/process-video',
                    newObj
                  )
                  if (!resultPost.success) {
                    notifyError(getTextServerError(resultPost.errMsg))
                    return
                  }
                }
              } else {
                NewFile.status = 'error'
                notifyError(getTextServerError(result.errMsg))
              }
            }
          }
        }
        video.src = window.URL.createObjectURL(
          new Blob([file], { type: 'video/mp4' })
        )
      }
    } else {
      reader.onload = () => {
        uploadAndFetchPOStFile('/s3/upload-image', { file }).then((res) => {
          if (res.success) {
            NewFile.status = 'done'
            NewFile.url = res.data.url
            NewFile.mediaUrl = res.data.url
            NewFile.file = file
            onUpload(NewFile)
          } else {
            NewFile.status = 'error'
            notifyError(getTextServerError(res.errMsg))
          }
        })
      }
    }

    reader.readAsArrayBuffer(file)
  }

  const beforeUpload = (file) => {
    let fileSize = size
    let isVideo = false
    const isCorrectType = mediaTypes.includes(file.type)
    if (isCorrectType) {
      if (file.type.includes('video/')) {
        isVideo = true
      }
    }

    if (customType?.length) {
      customType.map((item) => {
        if (item.type === file.type) {
          fileSize = item.size
        }
        return item
      })
    }

    if (!isCorrectType) {
      if (errMsg) {
        notifyError(errMsg)
      } else {
        notifyError(
          `${getText('TEXT_YOU_CAN_ONLY_UPLOAD')} ${mediaTypes
            .replaceAll('image/', '')
            .replaceAll('video/', '')
            .replaceAll('application/', '')
            .toUpperCase()} ${getText('WORD_FILE')}!`
        )
      }
    }

    const isCorrectSize = isVideo
      ? videoSize
        ? file.size / 1024 < videoSize * 1000
        : file.size / 1024 < 500000.0
      : file.size / 1024 < fileSize * 1024
    const isMB = file.size / 1024 < 1024 ? 'KB' : 'MB'
    const isSizeKB = isMB === 'MB' ? fileSize : fileSize * 1000
    if (fileSize) {
      if (!isCorrectSize && isCorrectType) {
        notifyError(
          `${getText('TEXT_IMAGE_MUST_SMALLER_THAN')} ${
            isVideo ? (storiesVideos ? videoSize : 300) : isSizeKB
          } ${isMB}`
        )
      }
    }

    // video duration validation
    if (file.type.includes('video/') && videoDurationLimit && isCorrectSize) {
      return new Promise((resolve, reject) => {
        const video = document.createElement('video')
        video.preload = 'metadata'

        video.onloadedmetadata = function () {
          window.URL.revokeObjectURL(video.src)
          if (videoDurationLimit && video.duration > videoDurationLimit) {
            notifyError(getText('ERROR_FILE_VIDEO_TOO_LONG'))
            reject(false)
          } else if (!isCorrectType) {
            reject(false)
          } else {
            resolve(true)
          }
        }

        video.src = URL.createObjectURL(file)
      })
    }

    let checkUpload = size ? isCorrectSize && isCorrectType : isCorrectType
    return checkUpload || Upload.LIST_IGNORE
  }

  const onPreview = async (file) => {
    let src = file.url

    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(file.originFileObj)

        reader.onload = () => resolve(reader.result)
      })
    }

    const image = new Image()
    image.src = src
    const imgWindow = window.open(src)
    imgWindow && document.write(image.outerHTML)
  }

  return (
    <MyDefaultFormItem
      name={name}
      className={formClassName}
      style={formStyle}
      label={label}
      valuePropName={valuePropName}
      initialValue={initialValue}
      hasFeedback={hasFeedback}
      getValueFromEvent={getValueFromEvent}
      disableMargins
    >
      {isPreview ? (
        <ImgCrop
          rotationSlider
          showGrid
          quality={1}
          aspect={3 / 4}
          modalTitle={modalTitle}
          beforeCrop={(file) => {
            // if the file is a video, skip cropping
            if (file.type.includes('video')) {
              return false
            }
            return true
          }}
          cropperProps={{ zoomWithScroll: 'false' }}
          modalCancel={getText('ACTION_CANCEL')}
          modalProps={{
            className: 'my_default_modal',
            okButtonProps: {
              type: 'primary',
              style: { minWidth: 90, height: 50 },
              className: 'my_default_button my_default_button_primary',
            },
            cancelButtonProps: {
              type: 'cancel',
              style: { minWidth: 90, height: 50 },
              className: 'my_default_button my_default_button_cancel',
            },
          }}
        >
          <Upload
            accept={accept}
            mediaTypes={mediaTypes}
            customRequest={uploadMedia}
            multiple={multiple}
            listType={listType}
            itemRender={itemRender}
            defaultFileList={defaultFileList}
            className={`my_default_upload ${disableMargins ? 'disableMargins' : ''} ${className || ''}`}
            disabled={disabled}
            beforeUpload={beforeUploadProps && beforeUpload}
            maxCount={maxCount}
            fileList={fileList}
            showUploadList={showUploadList}
            size={size}
            onPreview={onPreview}
            onChange={(e) => {
              if (e.file.status && e.file.status === 'removed') {
                onRemoveItem(e.fileList)
              }
            }}
          >
            {children || getText('WORD_PLUS_ADD_IMAGE')}
          </Upload>
        </ImgCrop>
      ) : (
        <Upload
          accept={accept}
          mediaTypes={mediaTypes}
          customRequest={uploadMedia}
          fileList={fileList}
          multiple={multiple}
          listType={listType}
          itemRender={itemRender}
          defaultFileList={defaultFileList}
          className={`my_default_upload ${disableMargins ? 'disableMargins' : ''} ${className || ''}`}
          disabled={disabled}
          beforeUpload={beforeUploadProps && beforeUpload}
          maxCount={maxCount}
          showUploadList={showUploadList}
          size={size}
          onChange={(e) => {
            if (e.file.status && e.file.status === 'removed') {
              onRemoveItem(e.fileList)
            }
          }}
        >
          {children || getText('WORD_PLUS_ADD_IMAGE')}
        </Upload>
      )}
    </MyDefaultFormItem>
  )
}

export default React.memo(MyDefaultUpload)
