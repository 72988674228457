import React, { useMemo } from 'react'
import { getText } from '../../../../../lang'
import MyDefaultSelect from '../../../../../components/MyDefaultSelect/MyDefaultSelect'

const FinalViewOptions = ({ field }) => {
  const getFinalViewTypes = useMemo(() => {
    return [
      { value: null, label: getText('WORD_SELECT_TYPE') },
      { value: 'Default Reply', label: 'Default Reply' },
      { value: 'Form', label: 'Form' },
    ]
  }, [])

  return (
    <MyDefaultSelect
      isForm
      name={[field.name, 'finalView']}
      label='Final View'
      options={getFinalViewTypes}
      required
      message={`Step ${field.name + 1}: Please select View type`}
    />
  )
}

export default FinalViewOptions
