import React, { useEffect, useState, useRef, useMemo } from 'react'
import { Form } from 'antd/es'
import { getText } from '../../../../lang'
import { getLongName } from '../../../../utils'
import SVGOrgSMSIcon from '../../../../icons/SVG/SVGOrgSMSIcon'
import SVGOrgEmailIcon from '../../../../icons/SVG/SVGOrgEmailIcon'
import {
  DAYS,
  EMAIL,
  HOURS,
  IMMEDIATELY,
  MINUTES,
  NIGHT,
  SMS,
  TOUCHPOINT,
} from '../../../../devOptions'
import MyDefaultBlockSubtitle from '../../../../components/MyDefaultBlock/MyDefaultBlockSubtitle'
import MyDefaultRadioButton from '../../../../components/MyDefaultRadioButton/MyDefaultRadioButton'
import MyDefaultSendFollowUp from '../../../../components/MyDefaultSendFollowUp/MyDefaultSendFollowUp'
import MyDefaultRadioTabs from '../../../../components/MyDefaultRadioButton/MyDefaultRadioTabs/MyDefaultRadioTabs'
import MyDefaultInputText from '../../../../components/MyDefaultInputText/MyDefaultInputText'
import MyDefaultAddVariablesInMessage from '../../../../components/MyDefaultAddVariablesInMessage/MyDefaultAddVariablesInMessage'
import MyDefaultTextarea from '../../../../components/MyDefaultTextarea/MyDefaultTextarea'
import useDeviceCheck from '../../../../utils/useDeviceCheck'

const AutoReplyPanel = ({
  selectedAiBotMessage,
  sequenceData,
  languages,
  triggeredOn,
  selectedIndex,
  form,
  field,
  type,
  selectedLangTab,
  setSelectedLangTab,
}) => {
  const { isTabletPortraitOrMobile } = useDeviceCheck()

  const [currentCursorPosition, setCurrentCursorPosition] = useState(0)
  const [communicationMethod, setCommunicationMethod] = useState('')
  const textAreaInputRef = useRef()
  const subjectInputRef = useRef()

  const getSequenceList = Form.useWatch('auto_reply_messages', form)

  const selectedUnit = Form.useWatch(
    ['auto_reply_messages', field, 'time_unit', 'unit'],
    form
  )

  const isImmediately = useMemo(() => selectedUnit === IMMEDIATELY, [selectedUnit])

  useEffect(() => {
    setCommunicationMethod(selectedAiBotMessage.send_via)
  }, [selectedAiBotMessage.id])

  useEffect(() => {
    const messageContent = selectedAiBotMessage
      ? selectedAiBotMessage.text[selectedLangTab] ||
        (selectedAiBotMessage.subject &&
          selectedAiBotMessage.subject[selectedLangTab])
      : ''
    const messageLength = messageContent ? messageContent.length : 0
    setCurrentCursorPosition(messageLength)
  }, [selectedAiBotMessage.id, selectedLangTab])

  const handleClickVariable = (variable, isSubject = false) => {
    const autoMessagesList = form.getFieldValue('auto_reply_messages')
    const fieldKey = isSubject ? 'subject' : 'text'
    const currentText = autoMessagesList[selectedIndex][fieldKey][selectedLangTab]
    const newText = [
      currentText && currentText.slice(0, currentCursorPosition),
      variable,
      currentText && currentText.slice(currentCursorPosition),
    ].join('')

    autoMessagesList[selectedIndex][fieldKey][selectedLangTab] = newText
    form.setFieldsValue({ auto_reply_messages: autoMessagesList })
    setCurrentCursorPosition(currentCursorPosition + variable.length)

    const inputRef = isSubject ? subjectInputRef : textAreaInputRef
    inputRef.current &&
      inputRef.current.autoFocus(currentCursorPosition + variable.length)
  }

  const radioOptions = [
    {
      label: getText('WORD_SMS_UC'),
      value: SMS,
      icon: (
        <SVGOrgSMSIcon color={communicationMethod === SMS ? '#FFFFFF' : '#5B66EA'} />
      ),
      disabled:
        (sequenceData?.type === NIGHT || type === NIGHT) && selectedIndex === 1,
    },
    {
      label: getText('WORD_EMAIL'),
      value: EMAIL,
      icon: (
        <SVGOrgEmailIcon
          color={communicationMethod === EMAIL ? '#FFFFFF' : '#5B66EA'}
        />
      ),
      disabled:
        (sequenceData?.type === NIGHT || type === NIGHT) && selectedIndex === 1,
    },
  ]

  const listVariables = [
    {
      label: 'customer_first_name',
      value: '{{customer_first_name}}',
    },
    {
      label: 'customer_last_name',
      value: '{{customer_last_name}}',
    },
    {
      label: 'organization_name',
      value: '{{organization_name}}',
    },
    {
      label: 'location_name',
      value: '{{location_name}}',
    },
    {
      label: 'touchpoint',
      value: '{{touchpoint}}',
    },
    {
      label: 'year',
      value: '{{year}}',
    },
    {
      label: 'make',
      value: '{{make}}',
    },
    {
      label: 'model',
      value: '{{model}}',
    },
    {
      label: 'VIN',
      value: '{{VIN}}',
    },
    {
      label: 'trade_year',
      value: '{{trade_year}}',
    },
    {
      label: 'trade_make',
      value: '{{trade_make}}',
    },
    {
      label: 'trade_model',
      value: '{{trade_model}}',
    },
    {
      label: 'trade_VIN',
      value: '{{trade_VIN}}',
    },
    {
      label: 'offer_amount',
      value: '{{offer_amount}}',
    },
    {
      label: 'price_estimate',
      value: '{{price_estimate}}',
    },
  ]

  const langOptions = languages.map((lang) => {
    return {
      label: getLongName(lang),
      value: lang,
    }
  })

  const timeUnitsOptions = [
    ...(sequenceData?.type !== NIGHT && type !== NIGHT
      ? [
          {
            label: getText('WORD_DAYS').toLowerCase(),
            value: DAYS,
          },
          {
            label: getText('WORD_HOURS'),
            value: HOURS,
          },
        ]
      : []),
    {
      label: getText('WORD_MINUTES'),
      value: MINUTES,
    },
    ...(selectedAiBotMessage.isFirstItem || selectedAiBotMessage.isSecondMsg
      ? [
          {
            label: getText('WORD_IMMEDIATELY'),
            value: IMMEDIATELY,
          },
        ]
      : []),
  ]

  return (
    <React.Fragment>
      <MyDefaultBlockSubtitle
        subtitle={getText('HOW_WOULD_YOU_LIKE_TO_SEND_THIS_MESSAGE')}
      />
      <MyDefaultRadioButton
        isForm
        name={['auto_reply_messages', field, 'send_via']}
        radioOptions={radioOptions}
        onChange={(e) => {
          if (
            getSequenceList?.length === 2 &&
            (sequenceData?.type === NIGHT || type === NIGHT) &&
            selectedIndex === 0
          ) {
            form.setFieldValue(
              ['auto_reply_messages', 1, 'send_via'],
              e.target.value === SMS ? EMAIL : SMS
            )
          }
          setCommunicationMethod(e.target.value)
        }}
      />
      <MyDefaultSendFollowUp
        vertical={isTabletPortraitOrMobile}
        disableMargin={false}
        beforeText={
          selectedAiBotMessage.isFirstItem
            ? getText('TEXT_FIRST_AUTO_MESSAGE_FULL')
            : getText('TEXT_SEND_FOLLOWUP')
        }
        afterText={getText(
          triggeredOn === TOUCHPOINT
            ? 'TEXT_AFTER_RECEIVING_LEAD_FULL'
            : 'TEXT_AFTER_CUSTOMER_LAST_SESSION_FULL'
        )}
        options={timeUnitsOptions || []}
        inputName={['auto_reply_messages', field, 'time_unit', 'value']}
        selectName={['auto_reply_messages', field, 'time_unit', 'unit']}
        hideNumber={isImmediately}
        inputInitialValue={selectedAiBotMessage?.time_unit?.value}
        selectInitialValue={{
          unit: isImmediately ? IMMEDIATELY : selectedAiBotMessage?.time_unit?.unit,
        }}
        min={!isImmediately && 1}
        max={
          !isImmediately && (sequenceData?.type === NIGHT || type === NIGHT) && 15
        }
        maxMessage={
          (sequenceData?.type === NIGHT || type === NIGHT) &&
          getText('MSG_MAXIMUM_DELAY_FOR_AFTER_HOUR_SEQUENCE')
        }
        minMessage={
          (sequenceData?.type === NIGHT || type === NIGHT) &&
          getText('MSG_MINIMUM_DELAY_FOR_AFTER_HOUR_SEQUENCE')
        }
        errorMessage={
          sequenceData?.type === NIGHT || type === NIGHT
            ? getText('MSG_MINIMUM_DELAY_FOR_AFTER_HOUR_SEQUENCE')
            : getText('ERR_PLEASE_FILL_TIME_UNIT')
        }
      />
      <MyDefaultBlockSubtitle
        subtitle={
          communicationMethod === EMAIL
            ? getText('WORD_EMAIL')
            : getText('WORD_SMS_UC')
        }
        rightContent={
          <MyDefaultRadioTabs
            radioOptions={langOptions}
            value={selectedLangTab}
            onChange={(lang) => {
              setSelectedLangTab(lang)
            }}
          />
        }
      />
      {communicationMethod === EMAIL && (
        <React.Fragment>
          <MyDefaultInputText
            isForm
            placeholder={getText('WORD_SUBJECT')}
            initialValue={
              selectedLangTab && selectedAiBotMessage?.subject?.[selectedLangTab]
                ? {
                    [selectedLangTab]: selectedAiBotMessage.subject
                      ? selectedAiBotMessage.subject[selectedLangTab]
                      : '',
                  }
                : ''
            }
            name={['auto_reply_messages', field, 'subject', selectedLangTab]}
            onClick={(e) => setCurrentCursorPosition(e.target.selectionStart)}
            onKeyUp={(e) => setCurrentCursorPosition(e.target.selectionStart)}
            onChange={(e) => setCurrentCursorPosition(e.target.selectionStart)}
          />
          <MyDefaultAddVariablesInMessage
            onClick={(variable) => handleClickVariable(variable, true)}
            data={listVariables}
          />
        </React.Fragment>
      )}
      <MyDefaultTextarea
        isForm
        ref={textAreaInputRef}
        name={['auto_reply_messages', field, 'text', selectedLangTab]}
        initialValue={{
          [selectedLangTab]: selectedAiBotMessage.text[selectedLangTab],
        }}
        placeholder={getText('WORD_MESSAGE')}
        onClick={(e) => setCurrentCursorPosition(e.target.selectionStart)}
        onKeyUp={(e) => setCurrentCursorPosition(e.target.selectionStart)}
        onChange={(e) => setCurrentCursorPosition(e.target.selectionStart)}
      />
      <MyDefaultAddVariablesInMessage
        onClick={(variable) => handleClickVariable(variable, false)}
        data={listVariables}
      />
    </React.Fragment>
  )
}

export default AutoReplyPanel
