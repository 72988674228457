import React from 'react'
import { Flex } from 'antd'

const MyDefaultTitleModal = ({ icon, title }) => {
  return icon ? (
    <Flex align='center'>
      {icon}
      {title}
    </Flex>
  ) : (
    title
  )
}

export default MyDefaultTitleModal
