import React, { forwardRef, memo, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { organizationSelector } from '../../store/selectors/selectors'
import locationActions from '../../store/modules/locationActions'
import { getText } from '../../lang'
import MyDefaultSelect from './MyDefaultSelect'

const MyDefaultSelectLocationByLocation = forwardRef(
  (
    { idLocation, disabled, onGetItem, label, onSelectItem, getPopupContainer },
    ref
  ) => {
    let organization = useSelector(organizationSelector)

    const [selectedLocation, setSelectedLocation] = useState()
    const [listData, setListData] = useState([])
    const [loadingData, setLoadingData] = useState(false)
    const [totalRows, setTotalRows] = useState(0)
    const [page, setPage] = useState(0)
    const [perPage] = useState(10)

    const loadSelecedLocation = useCallback(async () => {
      if (idLocation) {
        setLoadingData(true)
        let result =
          await locationActions.getOneLocationByConv_location_id(idLocation)
        setLoadingData(false)
        if (result.success) {
          setSelectedLocation(result.data)
          onGetItem && onGetItem(result.data)
        }
      } else {
        setSelectedLocation(null)
      }
    }, [idLocation])

    useEffect(() => {
      loadSelecedLocation()
    }, [loadSelecedLocation])

    useEffect(() => {
      const fill = async () => {
        setLoadingData(true)
        let result = await locationActions.getPageList(page, perPage, '', {}, true)
        setLoadingData(false)
        if (result.success && result.data && result.data.length > 0) {
          let list = result.data.map((oo) => {
            return { value: oo._id, label: oo.name, ...oo }
          })
          if (page > 0) {
            list = listData.concat(list)
          }
          setListData(list)
          setTotalRows(result.max)
        }
      }
      !disabled && fill()
    }, [page, perPage, organization])

    return (
      <MyDefaultSelect
        fullWidth
        label={label}
        options={listData}
        loading={loadingData}
        onLoadMore={() => {
          if (listData.length !== totalRows) {
            setPage(page + 1)
          }
        }}
        disabled={disabled}
        onSelectItem={onSelectItem}
        value={selectedLocation ? selectedLocation.name : getText('WORD_LOCATION')}
        getPopupContainer={getPopupContainer}
      />
    )
  }
)

export default memo(MyDefaultSelectLocationByLocation)
