import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 21 21'>
    <g id='Group_1810' dataName='Group 1810' transform='translate(-456 -546)'>
      <circle
        id='Ellipse_373'
        dataName='Ellipse 373'
        cx='10.5'
        cy='10.5'
        r='10.5'
        transform='translate(456 546)'
        fill='#f4f7fc'
      />
      <g id='Group_1065' dataName='Group 1065' transform='translate(460 550)'>
        <path
          id='Path_950'
          dataName='Path 950'
          d='M14.641,10.047a7.151,7.151,0,0,0-3.926,3.429,5.778,5.778,0,0,0-.587,1.76,6.942,6.942,0,0,0,.587,4.467,6.656,6.656,0,0,0,1.9,2.3,6.357,6.357,0,0,0,2.527,1.263,7.157,7.157,0,0,0,3.52.045,6.166,6.166,0,0,0,4.6-4.377,7.7,7.7,0,0,0,.18-3.655h-6.5v2.707h3.745a3.15,3.15,0,0,1-1.4,2.121,3.747,3.747,0,0,1-1.534.587,5.182,5.182,0,0,1-1.67,0,3.905,3.905,0,0,1-1.534-.677A4.144,4.144,0,0,1,12.972,17.9a3.986,3.986,0,0,1,0-2.707,4.641,4.641,0,0,1,.993-1.624,3.637,3.637,0,0,1,2.076-1.128,3.886,3.886,0,0,1,2.031.09,4.435,4.435,0,0,1,1.489.857l1.263-1.263.677-.677a7.075,7.075,0,0,0-2.211-1.4A7.439,7.439,0,0,0,14.641,10.047Z'
          transform='translate(-9.989 -9.675)'
          fill='#f4f7fc'
        />
        <g id='Group_1061' dataName='Group 1061' transform='translate(0.727 0.005)'>
          <path
            id='Path_951'
            dataName='Path 951'
            d='M15.526,10.054a7.133,7.133,0,0,1,4.648.045,6.346,6.346,0,0,1,2.211,1.4l-.677.677-1.263,1.263a3.313,3.313,0,0,0-1.489-.857,4.4,4.4,0,0,0-2.031-.09,4.688,4.688,0,0,0-2.076,1.128,4.062,4.062,0,0,0-.993,1.624c-.767-.587-1.489-1.173-2.256-1.76A7.151,7.151,0,0,1,15.526,10.054Z'
            transform='translate(-11.6 -9.687)'
            fill='#ea4335'
          />
        </g>
        <g id='Group_1062' dataName='Group 1062' transform='translate(0 3.802)'>
          <path
            id='Path_952'
            dataName='Path 952'
            d='M10.129,19.86a8.953,8.953,0,0,1,.587-1.76c.767.587,1.489,1.173,2.256,1.76a3.986,3.986,0,0,0,0,2.707c-.767.587-1.489,1.173-2.256,1.76A6.98,6.98,0,0,1,10.129,19.86Z'
            transform='translate(-9.989 -18.1)'
            fill='#fbbc05'
          />
        </g>
        <g id='Group_1063' dataName='Group 1063' transform='translate(6.909 5.606)'>
          <path
            id='Path_953'
            dataName='Path 953'
            d='M25.3,22.1h6.5a7.7,7.7,0,0,1-.18,3.655,6.432,6.432,0,0,1-1.76,2.888c-.722-.587-1.444-1.128-2.211-1.715a3.428,3.428,0,0,0,1.4-2.121H25.3Q25.3,23.454,25.3,22.1Z'
            transform='translate(-25.3 -22.1)'
            fill='#4285f4'
          />
        </g>
        <g id='Group_1064' dataName='Group 1064' transform='translate(0.727 8.269)'>
          <path
            id='Path_954'
            dataName='Path 954'
            d='M11.6,29.76c.767-.587,1.489-1.173,2.256-1.76a4.144,4.144,0,0,0,1.579,2.121,4.688,4.688,0,0,0,1.534.677,5.179,5.179,0,0,0,1.67,0,4.515,4.515,0,0,0,1.534-.587c.722.587,1.444,1.128,2.211,1.715a6.633,6.633,0,0,1-2.843,1.489,7.144,7.144,0,0,1-3.52-.045A7.01,7.01,0,0,1,13.5,32.106,8.509,8.509,0,0,1,11.6,29.76Z'
            transform='translate(-11.6 -28)'
            fill='#34a853'
          />
        </g>
      </g>
    </g>
  </svg>
)

const SVGLocationGoogleIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGLocationGoogleIcon
