import React, { useState, useRef, useImperativeHandle, forwardRef } from 'react'
import { Turnstile } from '@marsidev/react-turnstile'

const TURNSTILE_SITE_KEY = process.env.REACT_APP_TURNSTILE_SITE_KEY

const TurnstileCheck = forwardRef((props, ref) => {
  const { setToken, handleError } = props
  const [retryCount, setRetryCount] = useState(0)
  const localRef = useRef(null)

  useImperativeHandle(ref, () => ({
    reset: () => {
      localRef.current?.reset()
    },
  }))

  const onError = () => {
    if (retryCount < 3) {
      setRetryCount(retryCount + 1)
      localRef.current?.reset()
    } else {
      handleError('Verification error')
    }
  }

  return (
    <div className='turnstile-container'>
      <Turnstile
        ref={localRef}
        siteKey={TURNSTILE_SITE_KEY}
        onSuccess={(token) => {
          setToken(token)
          setRetryCount(0)
        }}
        onError={onError}
        onExpire={() => {
          localRef.current?.reset()
        }}
        options={{
          theme: 'light',
          size: 'flexible',
        }}
      />
    </div>
  )
})

export default TurnstileCheck
