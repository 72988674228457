import React, { useEffect, useState, useCallback, memo } from 'react'
import { Form } from 'antd'
import { debounce } from 'lodash'
import { useSelector } from 'react-redux'
import userActions from '../../../../store/modules/userActions'
import touchpointsAction from '../../../../store/modules/touchpointsAction'
import { GET_DATA_FETCH_SIZE } from '../../../../utils'
import MatForm from '../../../../components/Form/MatForm'
import Div from '../../../../components/Div/Div'
import { getText, getTextServerError } from '../../../../lang'
import ListLoadMore from '../../../../components/ListLoadMore'
import { CHAT_AI } from '../../../../devOptions'
import MyDefaultSearchInput from '../../../../components/MyDefaultSearchInput/MyDefaultSearchInput'
import { userSelector } from '../../../../store/selectors/selectors'
import MyDefaultModal from '../../../../components/MyDefaultModal/MyDefaultModal'
import { notifyError, notifySuccess } from '../../../../utils/Notify'
import MyDefaultInputText from '../../../../components/MyDefaultInputText/MyDefaultInputText'
import TouchpointAssignUserItem from './TouchpointAssignUserItem'

const TouchpointsEditModal = ({ item, onSave, visible, setVisible }) => {
  const user = useSelector(userSelector)

  const [selectedAssignedUsersList, setSelectedAssignedUsersList] = useState(
    item.users
  )
  const [assignedUsersList, setAssignedUsersList] = useState([])
  const [recentlyRemovedUsersId, setRecentlyRemovedUsersId] = useState([])
  const [recentlyAddedUsersId, setRecentlyAddedUsersId] = useState([])
  const [saving, setSaving] = useState(false)
  const [touchpointName, setTouchpointName] = useState(item.name)
  const [searchInputValue, setSearchInputValue] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [page, setPage] = useState(0)
  const [maxManagers, setMaxManagers] = useState('')
  const [loading, setLoading] = useState(false)

  const [form] = Form.useForm()
  const scrollParentRef = React.useRef(null)

  useEffect(() => {
    const handleFetchUsers = async () => {
      setLoading(true)
      if (user.isAdmin || user.isManager) {
        const result = await userActions.getUsersForAssignUnassign(
          page,
          GET_DATA_FETCH_SIZE,
          user._organization_id,
          searchValue
        )
        if (result.success) {
          const find = selectedAssignedUsersList.find(
            (item) => item._id === user?._id
          )
          if (find) {
            find.fullName = getText('ACTION_ASSIGN_SELF')
            setSelectedAssignedUsersList([
              find,
              ...item.users.filter((item) => item._id !== find._id),
            ])
            result.data = result.data.filter(
              (assigned) => assigned._id !== user?._id
            )
          } else {
            result.data = result.data.filter(
              (assigned) => assigned._id !== user?._id
            )
            if (page === 0 && result.data.length) {
              let fisrtObj = {
                ...user,
                fullName: getText('ACTION_ASSIGN_SELF'),
              }
              result.data.unshift(fisrtObj)
            }
          }
          const newList =
            page === 0 ? result.data : [...assignedUsersList, ...result.data]

          const listAssignedUsersIds = selectedAssignedUsersList.map((el) => el._id)

          const newListAssigned = newList.filter(
            (el) => !listAssignedUsersIds.includes(el._id)
          )
          setAssignedUsersList(newListAssigned)
          setMaxManagers(result.max)
        }
      }
      setLoading(false)
    }
    if (visible) {
      handleFetchUsers()
    }
  }, [searchValue, page, visible])

  useEffect(() => {
    if (!visible) {
      setSearchValue('')
    }
  }, [visible])

  const handleAssignUser = async (user) => {
    const userParams = { _id: user?._id, fullName: user.fullName }
    let isInclude = false
    for (const assignedUser of selectedAssignedUsersList) {
      if (assignedUser._id === user?._id) {
        isInclude = true
        break
      }
    }
    if (!isInclude) {
      setSelectedAssignedUsersList((oldList) => {
        oldList.push(userParams)
        return oldList
      })
      let result = assignedUsersList.filter((el) => el._id !== user?._id)
      setAssignedUsersList(result)
      if (!recentlyAddedUsersId.includes(user?._id)) {
        setRecentlyAddedUsersId((oldList) => {
          oldList.push(user?._id)
          return oldList
        })
      }
      setRecentlyRemovedUsersId((arr) => {
        return arr.filter((el) => el !== user?._id)
      })
    }
  }

  const handleUnAssignUser = async (user) => {
    let isInclude = false
    for (const assignedUser of assignedUsersList) {
      if (assignedUser._id === user?._id) {
        isInclude = true
        break
      }
    }
    if (!isInclude) {
      let result = selectedAssignedUsersList.filter((el) => el._id !== user?._id)
      setSelectedAssignedUsersList(result)
      setAssignedUsersList((oldList) => {
        oldList.unshift(user)
        return oldList
      })
      setRecentlyAddedUsersId((arr) => {
        return arr.filter((el) => el !== user?._id)
      })
      if (selectedAssignedUsersList.length) {
        setRecentlyRemovedUsersId((removedArr) => {
          removedArr.push(user?._id)
          return removedArr
        })
      }
    }
  }

  const onFinish = async (values) => {
    setSaving(true)
    let result = await touchpointsAction.EditTouchpointUsers(
      item._id,
      recentlyRemovedUsersId,
      recentlyAddedUsersId,
      values.nameTouchpoint
    )
    if (result.success) {
      setTimeout(() => {
        setSaving(false)
        setVisible(false)
      }, 500)
      notifySuccess(getText('TEXT_TOUCHPOINT_CHANGED_SUCCESSFULLY'))
      onSave && onSave(result.data)
    } else {
      notifyError(getTextServerError(result.errMsg))
      return
    }
    setSearchInputValue('')
    setSearchValue('')
  }

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields.length > 0) {
      notifyError(errorInfo.errorFields[0].errors[0])
    }
  }

  const debouncedChange = useCallback(
    debounce((options) => {
      setPage(0)
      setSearchValue(options)
    }, 500),
    []
  )

  return (
    <MyDefaultModal
      noTrigger
      title={getText('ACTION_EDIT') + ' ' + item.name}
      formName={'formSaveTouchpont_' + item._id}
      confirmLoading={saving}
      visible={visible}
      forceRender={false}
      onTrrigerClick={() => {
        form.resetFields()
      }}
      onCancel={() => {
        setVisible(false)
        setSearchInputValue('')
        setPage(0)
      }}
      okText={getText('ACTION_SAVE')}
    >
      <MatForm
        className='touchponts_edit_modal'
        form={form}
        name={'formSaveTouchpont_' + item._id}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{ nameTouchpoint: touchpointName || '' }}
      >
        <MyDefaultInputText
          isForm
          name='nameTouchpoint'
          label={getText('WORD_TOUCHPOINT_NAME')}
          onChange={(e) => setTouchpointName(e.target.value)}
          required
          disabled={item?.source === CHAT_AI}
        />
        <MyDefaultSearchInput
          color={'grey'}
          fullWidth
          placeholder={getText('TEXT_USERS_SEARCH')}
          value={searchInputValue}
          onChange={(value) => {
            setSearchInputValue(value)
            debouncedChange(value)
          }}
        />
        <Div className='touchponts_edit_modal--users-wrapper' ref={scrollParentRef}>
          {selectedAssignedUsersList && selectedAssignedUsersList.length > 0 ? (
            <React.Fragment>
              <Div className='touchponts_edit_modal--users-wrapper-field-title'>
                {getText('WORD_SELECTED_USERS')}
              </Div>
              <Div className='touchponts_edit_modal--users-wrapper-field-users'>
                {selectedAssignedUsersList.map((item, index) => {
                  return (
                    <TouchpointAssignUserItem
                      key={index}
                      className={'active_user_field'}
                      lastItem={index === selectedAssignedUsersList.length - 1}
                      onClick={() => handleUnAssignUser(item)}
                      name={item.fullName}
                      checked
                      style={item._id === user?._id ? { color: '#00cb85' } : {}}
                    />
                  )
                })}
              </Div>
            </React.Fragment>
          ) : undefined}
          <Div className='touchponts_edit_modal--users-wrapper-field-title'>
            {getText('WORD_LIST')}{' '}
          </Div>
          <Div className='touchponts_edit_modal--users-wrapper-field-users'>
            <ListLoadMore
              initialLoad={false}
              loading={loading}
              data={assignedUsersList}
              totalDataCount={maxManagers}
              onLoadMore={() => {
                setLoading(true)
                setPage((page) => page + 1)
              }}
              pageStart={0}
              getScrollParent={() => scrollParentRef.current}
              renderItem={(item, index) => {
                return (
                  <TouchpointAssignUserItem
                    key={index}
                    lastItem={index === assignedUsersList.length - 1}
                    onClick={() => handleAssignUser(item)}
                    name={item.fullName}
                    style={item._id === user?._id ? { color: '#00cb85' } : {}}
                  />
                )
              }}
            />
          </Div>
        </Div>
      </MatForm>
    </MyDefaultModal>
  )
}

export default memo(TouchpointsEditModal)
