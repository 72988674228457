import React, { useState } from 'react'
import { ColorPicker } from 'antd'
import { getText } from '../../lang'
import Div from '../Div/Div'
import MyDefaultBlockSubtitle from '../MyDefaultBlock/MyDefaultBlockSubtitle'
import './MyDefaultColorPicker.scss'

const MyDefaultColorPicker = ({
  label,
  value,
  size = 'large',
  onChangeColor,
  showText = true,
  disabledAlpha = true,
}) => {
  const [format, setFormat] = useState('hex')
  return (
    <Div className={'my_default_color_picker'}>
      {label && <MyDefaultBlockSubtitle subtitle={label} />}
      <ColorPicker
        format={format}
        value={value}
        showText={showText}
        size={size}
        disabledAlpha={disabledAlpha}
        onChangeComplete={(e) => {
          onChangeColor?.(e.toHexString())
        }}
        onFormatChange={(format) => {
          setFormat(format)
        }}
        presets={[
          {
            label: getText('WORD_RECOMMENDED'),
            colors: [
              '#D0021B',
              '#F5A623',
              '#F8E71C',
              '#8B572A',
              '#7ED321',
              '#417505',
              '#BD10E0',
              '#9013FE',
              '#4A90E2',
              '#50E3C2',
              '#B8E986',
              '#000000',
              '#4A4A4A',
              '#9B9B9B',
              '#FFFFFF',
              '#5B66EA',
            ],
          },
        ]}
      />
    </Div>
  )
}

export default MyDefaultColorPicker
