const utilVehicle = {
  getVehicleLabel: (vehicle) => {
    let title =
      vehicle?.vin || vehicle?.model || vehicle?.make || vehicle?.year || ''

    if (vehicle.make && vehicle.model) {
      title = `${vehicle.make} ${vehicle.model}`
    }

    if (vehicle.make && vehicle.model && vehicle.year) {
      title = `${vehicle.make} ${vehicle.model} ${vehicle.year}`
    }

    return title
  },
}

export default utilVehicle
