import React, { useRef, forwardRef, useImperativeHandle, useEffect } from 'react'
import { getText } from '../../lang'
import MyDefaultFormItem from '../MyDefaultFormItem/MyDefaultFormItem'
import useDeviceCheck from '../../utils/useDeviceCheck'
import MyDefaultLabelWithTooltip from '../MyDefaultFormItem/MyDefaultLabelWithTooltip/MyDefaultLabelWithTooltip'
import './MyDefaultInputNumber.scss'

const MyDefaultInputNumber = forwardRef(
  (
    {
      inputClassName,
      onChange,
      placeholder = '',
      disabled = false,
      value,
      label,
      maxLength = 255,
      minLength = 0,
      style,
      step = 1,
      size = 'middle',
      controls = false,
      isForm,
      required = false,
      hasFeedback = true,
      name,
      formClassName,
      initialValue,
      errorMessage,
      min,
      max,
      noStyle,
      validateStatus,
      color = 'grey',
      maxMessage,
      minMessage,
      fullWidth,
      tooltipText,
      isEnterDisabled,
      onPressEnter,
      preventNegative,
      preventDot,
    },
    ref
  ) => {
    const inputAreaRef = useRef()
    const inputRef = useRef(null)
    const { isMobileOrTabletOrTabletPortrait } = useDeviceCheck()

    useImperativeHandle(ref, () => ({
      autoFocus() {
        if (!isMobileOrTabletOrTabletPortrait) {
          inputAreaRef.current && inputAreaRef.current.focus()
        }
      },
    }))

    useEffect(() => {
      const handleWheel = (event) => {
        if (document.activeElement === inputRef.current) {
          event.preventDefault()
        }
      }

      inputRef.current?.addEventListener('wheel', handleWheel, { passive: false })

      return () => inputRef.current?.removeEventListener('wheel', handleWheel)
    }, [])

    return isForm ? (
      <MyDefaultFormItem
        className={formClassName}
        label={
          tooltipText ? (
            <MyDefaultLabelWithTooltip
              label={label}
              tooltipText={tooltipText}
              required={required}
            />
          ) : (
            label
          )
        }
        name={name}
        initialValue={initialValue}
        hasFeedback={hasFeedback}
        rules={[
          {
            required: required,
            message: errorMessage,
            // type: 'number',
          },
          {
            min: min,
            validator:
              typeof min !== 'undefined'
                ? (item, value) => {
                    if (value >= min) {
                      return Promise.resolve()
                    }
                    return Promise.reject(
                      minMessage || new Error(getText('ERR_MSG_INPUT_NUMBER'))
                    )
                  }
                : () => Promise.resolve(),
          },
          {
            max: max,
            validator: max
              ? (item, value) => {
                  if (value < max + 1) {
                    return Promise.resolve()
                  }
                  return Promise.reject(
                    maxMessage ||
                      new Error(
                        `${
                          label ? label : placeholder
                        } ${getText('TEXT_MUST_BE_MAXIMUM')} ${max} ${getText('WORD_CHARACTERS')}.`
                      )
                  )
                }
              : () => Promise.resolve(),
          },
        ]}
        style={style}
        noStyle={noStyle}
        validateStatus={validateStatus}
        fullWidth={fullWidth}
      >
        <input
          ref={inputRef}
          className={`my_default_input_number ${color} ${inputClassName || ''} ${fullWidth ? 'full_width' : ''}`}
          onChange={onChange}
          disabled={disabled}
          placeholder={placeholder ? placeholder : label}
          step={step}
          size={size}
          type='number'
          controls={controls}
          maxLength={maxLength}
          minLength={minLength}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              if (isEnterDisabled) {
                e.preventDefault()
              }
              onPressEnter && onPressEnter(e)
            }
            if (preventNegative && (e.key === 'ArrowDown' || e.key === '-')) {
              e.preventDefault()
            }
            if (preventDot && e.key === '.') {
              e.preventDefault()
            }
          }}
        />
      </MyDefaultFormItem>
    ) : (
      <input
        ref={inputRef}
        className={`my_default_input_number ${color} ${inputClassName || ''}`}
        onChange={onChange}
        disabled={disabled}
        placeholder={placeholder ? placeholder : label}
        step={step}
        value={value}
        size={size}
        type='number'
        controls={controls}
        maxLength={maxLength}
        minLength={minLength}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            if (isEnterDisabled) {
              e.preventDefault()
            }
            onPressEnter && onPressEnter(e)
          }
          if (preventNegative && (e.key === 'ArrowDown' || e.key === '-')) {
            e.preventDefault()
          }
          if (preventDot && e.key === '.') {
            e.preventDefault()
          }
        }}
      />
    )
  }
)

export default MyDefaultInputNumber
