import React, { useEffect, useMemo } from 'react'
import { CONNECT_URL } from '../../../../config'

const ThemePreview = ({ locationId, settings, language = 'en' }) => {
  const getLanguage = useMemo(
    () => (language === 'es' ? 'sp' : language),
    [language]
  )

  useEffect(() => {
    sendPreviewSettings()
  }, [settings])

  const sendPreviewSettings = () => {
    const iframe = document.getElementById(`iframe-preview-subscriber-growth`)
    if (!iframe) {
      return
    }
    iframe.contentWindow.postMessage(settings, '*')
  }

  const onIframeLoad = () => {
    sendPreviewSettings()
  }

  return (
    <iframe
      title='Matador Connect Preview'
      id={`iframe-preview-subscriber-growth`}
      height='700rem'
      width='100%'
      frameBorder='none'
      onLoad={onIframeLoad}
      src={`${CONNECT_URL}/subscriber-growth/${locationId}/${getLanguage}`}
      style={{ display: 'block' }}
      allow='geolocation'
    ></iframe>
  )
}

export default ThemePreview
