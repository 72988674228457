import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Flex, Modal } from 'antd'
import {
  DAYS,
  DEFAULT,
  DROPDOWN_BUTTON,
  NEGATIVE_BUTTON,
  NIGHT,
  POSITIVE_BUTTON,
  TABLE_DEFAULT_ROW_HEIGHT,
} from '../../../../devOptions'
import { getText, getTextServerError } from '../../../../lang'
import organizationActions from '../../../../store/modules/organizationActions'
import { notifyError, notifySuccess } from '../../../../utils/Notify'
import MyDefaultTableList from '../../../../components/MyDefaultTable/MyDefaultTableList/MyDefaultTableList'
import SVGMyDefaultEditIcon from '../../../../icons/SVG/SVGMyDefaultEditIcon'
import SVGMyDefaultDeleteIcon from '../../../../icons/SVG/SVGMyDefaultDeleteIcon'
import MyDefaultTableTitle from '../../../../components/MyDefaultTable/MyDefaultTableTitle/MyDefaultTableTitle'
import MyDefaultTableText from '../../../../components/MyDefaultTable/MyDefaultTableText/MyDefaultTableText'
import MyDefaultTableSwitch from '../../../../components/MyDefaultTable/MyDefaultTableSwitch/MyDefaultTableSwitch'
import MyDefaultTableTags from '../../../../components/MyDefaultTable/MyDefaultTableTags/MyDefaultTableTags'
import ConfirmPopup from '../../../../components/ConfirmPopup'
import MyDefaultPageLayout from '../../../../components/MyDefaultPageLayout/MyDefaultPageLayout'
import MyDefaultTagBlock from '../../../../components/MyDefaultTagBlock/MyDefaultTagBlock'
import MyDefaultTableHeader from '../../../../components/MyDefaultTable/MyDefaultTableHeader/MyDefaultTableHeader'
import useDeviceCheck from '../../../../utils/useDeviceCheck'
import { useLayout } from '../../../../layout/LayoutProvider/LayoutProvider'
import { useOrganizationLayout } from '../../organizationProvider/OrganizationProvider'
import './DripSequenceListItem.scss'
import { useSocket } from '../../../../utils/socket'

const DripSequence = () => {
  const {
    selectedOrganization: organization,
    setSelectedOrganization: setOrganization,
  } = useOrganizationLayout()
  const { isMobileOrTabletOrTabletPortrait } = useDeviceCheck()
  const { setPageTitle } = useLayout()

  const layoutRef = useRef(null)

  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [sequenceData, setSequenceData] = useState({
    list: [],
    total: 0,
  })
  const [enableAutoReply, setEnableAutoReply] = useState(
    organization.enable_auto_reply
  )

  const navigate = useNavigate()

  useSocket(
    `private-organization=${organization?._id}`,
    'UPDATE_ORGANIZATION',
    (data) => {
      setOrganization(
        Object.assign({}, organization, {
          featuresConnectingData: data.organization.featuresConnectingData,
          enable_auto_reply: data.organization.enable_auto_reply,
        })
      )
    },
    !!organization?._id
  )

  const isActivitySequencePresent = sequenceData.list.some(
    (seq) => seq.triggeredOn === 'activity'
  )
  const getSequencesData = async () => {
    setLoading(true)
    const result = await organizationActions.getPageListSequence(
      page,
      TABLE_DEFAULT_ROW_HEIGHT,
      organization?._id
    )
    if (result.success) {
      let data = []
      if (page === 0) {
        data = result.data
      } else {
        data = [...sequenceData.list, ...result.data]
      }
      setSequenceData({
        list: data,
        total: result.max,
      })
      setTimeout(() => {
        setLoading(false)
      }, 150)
    }
  }

  useEffect(() => {
    setPageTitle(getText('WORD_SEQUENCES'))
  }, [])

  useEffect(() => {
    if (organization && organization?._id) {
      getSequencesData()
    }
  }, [organization?._id, page])

  useEffect(() => {
    setEnableAutoReply(organization.enable_auto_reply)
  }, [organization?.enable_auto_reply])

  const handleEnableChange = async (newValue) => {
    setEnableAutoReply((ov) => !ov)
    const enableObject = { enable_auto_reply: newValue }
    const result = await organizationActions.setAutoReply(
      enableObject,
      organization?._id
    )
    if (result.success) {
      setOrganization(result.data)
      if (newValue) {
        notifySuccess(getText('TEXT_UPDATE_ORGANIZATION_DRIP_SEQUENCE_ENABLED'))
      } else {
        notifySuccess(getText('TEXT_UPDATE_ORGANIZATION_DRIP_SEQUENCE_DISABLED'))
      }
    } else {
      setEnableAutoReply((ov) => !ov)
      notifyError(getTextServerError(result.errMsg))
    }
  }

  const handleChangeAllSequencesStatus = async (bool) => {
    const result = await organizationActions.changeAllSequencesStatus({
      isActive: bool,
      _organization_id: organization?._id,
    })
    if (result.success) {
      setSequenceData((ov) => {
        ov.list.map((seq) => (seq.isActive = bool))
        return { list: [...ov.list], total: ov.total }
      })
      notifySuccess(
        getText(
          bool
            ? 'TEXT_ALL_SEQUENCES_WERE_ENABLED_SUCCESSFULLY'
            : 'TEXT_ALL_SEQUENCES_WERE_DISABLED_SUCCESSFULLY'
        )
      )
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
  }

  const handleChangeToggle = async (item) => {
    setSequenceData((ov) => {
      ov.list.map((seq) => {
        if (seq._id === item._id) {
          seq.isActive = !seq.isActive
        }
        return seq
      })
      return { list: [...ov.list], total: ov.total }
    })
    const objSend = {
      name: item.name,
      isActive: !item.isActive,
      sources: item.sources || [],
      _organization_id: item._organization_id,
      triggeredOn: item.triggeredOn,
      first_message: item.first_message,
      firstMessageDelay: item.firstMessageDelay,
      firstMessageCommunicationChannel: item.firstMessageCommunicationChannel,
      ...(item.firstMessageCommunicationChannel === 'email' && {
        firstMessageSubject: item.firstMessageSubject,
      }),
    }
    if (item.remove_video) {
      objSend.remove_video = item.remove_video
    }
    if (item._video_upload_id) {
      objSend._video_upload_id = item._video_upload_id
    }
    if (item.first_message_media?.length) {
      objSend.first_message_media = item.first_message_media
    }
    // for (const lng in objSend.firstMessageSubject) {
    //   if (!languages.includes(lng)) {
    //     delete objSend.firstMessageSubject[lng]
    //   }
    // }

    if (item.triggeredOn === 'activity') {
      objSend.minimumTimeBetweenMessages = {
        unit: DAYS,
        value: item.minimumTimeBetweenMessages.value,
      }
    }

    const result = await organizationActions.saveUpdateSequence(objSend, item._id)
    if (result.success) {
      notifySuccess(getText('TEXT_SEQUENCE_WAS_SAVED_SUCCESSFULLY'))
    } else {
      setSequenceData((ov) => {
        ov.list.map((seq) => {
          if (seq._id === item._id) {
            seq.isActive = !seq.isActive
          }
          return seq
        })
        return { list: [...ov.list], total: ov.total }
      })
      notifyError(getTextServerError(result.errMsg, objSend.name))
    }
  }

  return (
    <MyDefaultPageLayout
      ref={layoutRef}
      isAdvanced
      headerProps={{
        right: [
          {
            type: POSITIVE_BUTTON,
            title: getText('TEXT_ENABLE_ALL_SEQUENCES'),
            onClick: () => {
              Modal.confirm({
                title: getText('TEXT_ENABLE_ALL_SEQUENCES'),
                content: (
                  <>
                    {getText(
                      'TEXT_ARE_YOU_SURE_YOU_WANT_TO_ENABLE_ALL_SEQUENCES_IN_THIS_ORGANIZATION'
                    )}
                    <br />
                    <br />
                    {getText('TEXT_THIS_ACTION_CANNOT_BE_UNDONE')}
                  </>
                ),
                icon: null,
                okText: getText('WORD_YES'),
                cancelText: getText('WORD_NO'),
                onOk: async () => {
                  handleChangeAllSequencesStatus(true)
                },
                centered: true,
                okButtonProps: {
                  type: 'primary',
                  className: 'mat-btn mat-btn-primary',
                },
                className: 'confirm-alert-wrapper',
                cancelButtonProps: {
                  type: 'white',
                  className: 'mat-btn mat-btn-white',
                },
              })
            },
            disabled: !enableAutoReply,
          },
          {
            type: NEGATIVE_BUTTON,
            title: getText('TEXT_DISABLE_ALL_SEQUENCES'),
            onClick: () => {
              Modal.confirm({
                title: getText('TEXT_DISABLE_ALL_SEQUENCES'),
                content: (
                  <>
                    {getText(
                      'TEXT_ARE_YOU_SURE_YOU_WANT_TO_DISABLE_ALL_SEQUENCES_IN_THIS_ORGANIZATION'
                    )}
                    <br />
                    <br />
                    {getText('TEXT_THIS_ACTION_CANNOT_BE_UNDONE')}
                  </>
                ),
                icon: null,
                okText: getText('WORD_YES'),
                cancelText: getText('WORD_NO'),
                onOk: async () => {
                  handleChangeAllSequencesStatus(false)
                },
                centered: true,
                okButtonProps: {
                  type: 'primary',
                  className: 'mat-btn mat-btn-primary',
                },
                className: 'confirm-alert-wrapper',
                cancelButtonProps: {
                  type: 'white',
                  className: 'mat-btn mat-btn-white',
                },
              })
            },
            disabled: !enableAutoReply,
          },
          {
            type: DROPDOWN_BUTTON,
            title: getText('WORD_CREATE_WORKING_HOURS_SEQUENCE'),
            onClick: () => {
              navigate(
                `/organization/settings/${organization?._id}/dripsequence/create`,
                { state: { isActivitySequencePresent, type: DEFAULT } }
              )
            },
            onItemClick: (item) => {
              navigate(
                `/organization/settings/${organization?._id}/dripsequence/create`,
                { state: { isActivitySequencePresent, type: item.key } }
              )
            },
            items: [
              {
                key: DEFAULT,
                label: (
                  <Flex align='center' gap={20} style={{ lineHeight: 'normal' }}>
                    {getText('WORD_CREATE_WORKING_HOURS_SEQUENCE')}
                    <MyDefaultTagBlock
                      title={getText('WORD_DEFAULT')}
                      backgroundColor={'var(--greenLight)'}
                      titleColor={'var(--green)'}
                    />
                  </Flex>
                ),
              },
              {
                key: NIGHT,
                label: getText('WORD_CREATE_AFTER_HOURS_SEQUENCE'),
              },
            ],
          },
        ],
        isResponsiveSize: isMobileOrTabletOrTabletPortrait,
      }}
    >
      <MyDefaultTableHeader
        title={getText('WORD_TOTAL_SEQUENCES')}
        onChangeSwitch={handleEnableChange}
        switchValue={enableAutoReply}
        actionBy={organization?.featuresConnectingData?.AIBot?.userData?.fullName}
        actionTitle={
          Boolean(organization?.featuresConnectingData?.AIBot) &&
          organization?.featuresConnectingData?.AIBot?.isConnected
            ? getText('TEXT_ENABLED_BY')
            : getText('TEXT_DISABLED_BY')
        }
        textAfterSwitch={getText(
          enableAutoReply
            ? 'TEXT_SEQUENCES_ARE_ACTIVE'
            : 'TEXT_SEQUENCES_ARE_INACTIVE'
        )}
        totalCount={sequenceData.total}
      />
      <MyDefaultTableList
        pageStart={page}
        loading={loading}
        data={sequenceData.list || []}
        getScrollParent={() => layoutRef?.current}
        onLoadMore={() => {
          if (!loading && sequenceData?.list?.length < sequenceData?.total) {
            setLoading(true)
            setPage((ov) => ov + 1)
          }
        }}
        totalDataCount={sequenceData?.total || 0}
        columns={[
          {
            title: getText('TEXT_SEQUENCE_NAME'),
            dataIndex: 'name',
            render: (_, { name }) => {
              return <MyDefaultTableTitle title={name || ''} />
            },
          },
          {
            title: getText('WORD_TYPE'),
            dataIndex: 'type',
            render: (_, { type }) => {
              return (
                <MyDefaultTableText
                  text={getText(
                    Boolean(type === NIGHT)
                      ? 'TEXT_AFTER_HOURS'
                      : 'TEXT_WORKING_HOURS'
                  )}
                />
              )
            },
          },
          {
            title: getText('WORD_TOUCHPOINTS'),
            dataIndex: 'sources_names',
            width: 420,
            render: (_, { sources_names }) => {
              return <MyDefaultTableTags list={sources_names} />
            },
          },
          {
            title: getText('WORD_STATUS'),
            dataIndex: 'status',
            render: (_, item) => {
              return (
                <MyDefaultTableSwitch
                  value={item.isActive}
                  text={getText(item.isActive ? 'WORD_ACTIVE' : 'WORD_INACTIVE')}
                  onChange={() => handleChangeToggle(item)}
                />
              )
            },
          },
          {
            title: '',
            dataIndex: 'actions',
            width: 60,
            render: (_, item) => {
              return (
                <Flex align='center' gap={20}>
                  <SVGMyDefaultEditIcon
                    onClick={() => {
                      navigate(
                        `/organization/settings/${organization?._id}/dripsequence/edit`,
                        {
                          state: {
                            sequenceData: item,
                            isActivitySequencePresent,
                          },
                        }
                      )
                    }}
                  />
                  <ConfirmPopup
                    title={getText(
                      'TEXT_ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_SEQUENCE'
                    )}
                    placement={'topRight'}
                    onConfirm={async () => {
                      const result = await organizationActions.deleteSequence(
                        item._id
                      )
                      if (result.success) {
                        setSequenceData((ov) => {
                          const newList = ov.list.filter(
                            (seq) => seq._id !== item._id
                          )
                          return { list: [...newList], total: ov.total }
                        })
                        notifySuccess(
                          getText('TEXT_SEQUENCE_WAS_DELETED_SUCCESSFULLY')
                        )
                      } else {
                        notifyError(getTextServerError(result.errMsg))
                      }
                    }}
                    okText={getText('WORD_YES')}
                    cancelText={getText('WORD_NO')}
                    trigger={<SVGMyDefaultDeleteIcon />}
                  />
                </Flex>
              )
            },
          },
        ]}
      />
    </MyDefaultPageLayout>
  )
}

export default DripSequence
