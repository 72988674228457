import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Form } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { notifyError, notifySuccess } from '../../../../utils/Notify'
import { getText, getTextServerError } from '../../../../lang'
import MatForm from '../../../../components/Form/MatForm'
import LoadingSpinner from '../../../../components/LoadingSpinner'
import { CANCEL_BTN, IMMEDIATELY, SMS, SUBMIT_BTN } from '../../../../devOptions'
import locationActions from '../../../../store/modules/locationActions'
import { getLongName } from '../../../../utils'
import { useLocationLayout } from '../../locationProvider/LocationProvider'
import { useLayout } from '../../../../layout/LayoutProvider/LayoutProvider'
import { organizationSelector } from '../../../../store/selectors/selectors'
import MyDefaultPageLayout from '../../../../components/MyDefaultPageLayout/MyDefaultPageLayout'
import MyDefaultBlock from '../../../../components/MyDefaultBlock/MyDefaultBlock'
import MyDefaultAddAutoReplyMessage from '../../../../components/MyDefaultAddAutoReplyMessage/MyDefaultAddAutoReplyMessage'
import SVGRemindMe from '../../../../icons/SVG/SVGRemindMe'
import useDeviceCheck from '../../../../utils/useDeviceCheck'
import SVGOrgEmailIcon from '../../../../icons/SVG/SVGOrgEmailIcon'
import Div from '../../../../components/Div/Div'
import MyDefaultSwitch from '../../../../components/MyDefaultSwitch/MyDefaultSwitch'
import CalendarInvitePanel from './CalendarInvitePanel'
import './CalendarInvite.scss'

function removeEmptyStrings(obj) {
  for (let prop in obj) {
    if (typeof obj[prop] === 'object') {
      removeEmptyStrings(obj[prop])
    } else if (obj[prop] === '') {
      delete obj[prop]
    }
  }
}

const CalendarInvite = () => {
  const { locationObj, isNewLocation, setLocationObj } = useLocationLayout()
  const { isPageDrawerSize } = useDeviceCheck()

  const navigate = useNavigate()
  const { _id } = useParams()
  const { setFooterButtons, setPageTitle } = useLayout()
  const organization = useSelector(organizationSelector)

  const [selectedCalendarSequence, setSelectedCalendarSequence] = useState(null)
  const [sequenceId, setSequenceId] = useState('')
  const [doNotSendReminderOnSameDay, setDoNotSendReminderOnSameDay] = useState(true)

  const [form] = Form.useForm()
  const getRemindersList = Form.useWatch('calendar_invite_sequence', form)
  const languages = organization.languages

  const getSelectedIndex = !selectedCalendarSequence
    ? 0
    : form
        .getFieldValue('calendar_invite_sequence')
        .findIndex((calendar) => calendar.id === selectedCalendarSequence.id)

  const deviceCheck = useMemo(() => isPageDrawerSize, [isPageDrawerSize])

  useEffect(() => {
    if (!isNewLocation && !locationObj) {
      return navigate('/location/list')
    }
  }, [_id])

  useEffect(() => {
    setFooterButtons([
      {
        type: CANCEL_BTN,
      },
      {
        type: SUBMIT_BTN,
      },
    ])
    setPageTitle(getText('TEXT_CALENDAR_INVITE'))
  }, [])

  useEffect(() => {
    const fill = async () => {
      let firstAutoMessage = { text: {}, image: {} }
      let otherAutoMessages = []
      languages.forEach((lang) => {
        firstAutoMessage.text[lang] = locationObj
          ? locationObj.calendarMsg[lang]
          : ''
        firstAutoMessage.image[lang] = locationObj
          ? locationObj.calendarMsgPic[lang]
          : ''
      })

      const completeFirstAutoMessage = {
        ...firstAutoMessage,
        isFirstItem: true,
        delay: firstAutoMessage.time_unit || {
          value: 0,
          unit: 'immediately',
          minutes: 0,
        },
        send_via: 'sms',
        id: new Date().getTime(),
      }
      const result = await locationActions.getCalendarReminderSequence(_id)
      if (result.success) {
        result.data.messages.map((item) => {
          removeEmptyStrings(item.image)
          return (item.id = item._id || new Date().getTime())
        })
        result.data.messages.sort((a, b) => b.delay.minutes - a.delay.minutes)
        setSequenceId(result.data._id)
        setDoNotSendReminderOnSameDay(result.data.doNotSendReminderOnSameDay)
        otherAutoMessages = [...result.data.messages]
        locationObj.calendar_invite_sequence = [
          completeFirstAutoMessage,
          ...otherAutoMessages,
        ]
        form.setFieldsValue({
          calendar_invite_sequence: [completeFirstAutoMessage, ...otherAutoMessages],
        })
        setSelectedCalendarSequence(completeFirstAutoMessage)
      }
    }
    if (locationObj && locationObj._id) {
      fill()
    }
  }, [locationObj._id])

  const isAnyEmptyField = (autoReplies) => {
    const getMessageNumberError = (index) => {
      const number = index + 1
      switch (number) {
        case 1:
          return 'first'
        case 2:
          return 'second'
        case 3:
          return 'third'
        default:
          return number + 'th'
      }
    }
    let isEmptyField = false

    // checking for empty body message (for fields that are not currently showing on the UI)
    autoReplies.forEach((autoReply, index) => {
      for (const lang in autoReply.text) {
        const cleanContent = autoReply.text[lang].replaceAll(/\s/g, '')
        if (!cleanContent && organization.languages.includes(lang)) {
          notifyError(
            `${getLongName(lang)} field is required for ${getMessageNumberError(
              index
            )} auto-reply message.`
          )
          isEmptyField = true
        }
      }
    })

    // checking for messages set at the same time
    const allTimeUnits = autoReplies.map((autoReply) => {
      return autoReply.delay.value + autoReply.delay.unit
    })
    const uniqueTimeUnits = [...new Set(allTimeUnits)]

    if (allTimeUnits.length !== uniqueTimeUnits.length) {
      notifyError(getText('ERR_SAME_TIME_AUTO_REPLY'))
      isEmptyField = true
    }

    return isEmptyField
  }

  const onFinish = async (values) => {
    let result
    if (isAnyEmptyField(values.calendar_invite_sequence)) return

    const [firstMessage = {}, ...otherMessages] = values.calendar_invite_sequence

    const { text, image } = firstMessage

    const objSend = {
      calendarMsg: text,
      calendarMsgPic: image,
    }

    const arMessages = otherMessages.map((message) => {
      const obj = { ...message }

      delete obj.isNewMessage
      delete obj.id
      delete obj._id
      delete obj.loading

      return {
        ...obj,
        send_via: obj.send_via,
      }
    })

    if (locationObj._id) {
      result = await locationActions.saveCalendarInvite(objSend, locationObj._id)
      const objText = {
        messages: arMessages,
        doNotSendReminderOnSameDay: doNotSendReminderOnSameDay,
        _organization_id: locationObj._organization_id,
        _location_id: locationObj._id,
      }
      await locationActions.saveUpdateSequenceCalendarInviteMessege(
        objText,
        sequenceId
      )

      if (result && result.success) {
        notifySuccess(getText('TEXT_LOCATION_WAS_UPDATED_SUCCESSFULLY'))
        setLocationObj(result.data)
      } else {
        notifyError(getTextServerError(result.errMsg))
      }
    }
  }

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields.length > 0) {
      if (errorInfo.errorFields[0].name[0] === 'name') {
        notifyError(getText('ERR_CAMPAIGN_NAME_IS_REQUIRED'))
      } else {
        notifyError(getTextServerError(errorInfo.errorFields[0].errors[0]))
      }
    }
  }

  function renderSelectedAutoReplyPanel() {
    if (getSelectedIndex || getSelectedIndex === 0) {
      return !selectedCalendarSequence ? (
        <LoadingSpinner style={{ width: '100%' }} />
      ) : (
        <CalendarInvitePanel
          form={form}
          field={getSelectedIndex}
          selectedCalendarSequence={selectedCalendarSequence}
          setSelectedCalendarSequence={setSelectedCalendarSequence}
          selectedIndex={getSelectedIndex}
          languages={languages}
        />
      )
    }
  }

  const getAutoReplyItemTitle = (item) => {
    if (!item || !item?.delay || !item?.delay?.unit) return null

    if (item.delay?.unit === IMMEDIATELY) return getText('WORD_IMMEDIATELY')

    const unit = getText(`WORD_${item.delay?.unit.toUpperCase()}`).toLowerCase()

    if (item.delay?.value)
      return `${item.delay?.value} ${unit} ${getText('WORD_BEFORE_APPOINTMENT')}`

    return unit
  }

  const getAutoReplyItemSubTitle = (item) => {
    if (!item) return null

    if (item?.isFirstItem) return getText('WORD_FIRST_AUTO_MESSAGE_WL')

    let title = ''
    const { text } = item
    if (!text) return title

    if (!title) {
      if (text?.sp) title = text.sp
      if (text?.fr) title = text.fr
      if (text?.en) title = text.en
    }

    return title.replace(new RegExp('{{source}}', 'g'), '{{touchpoint}}')
  }

  const getAutoReplyItemIcon = (item) => {
    if (!item) return null

    if (item.send_via === SMS)
      return <SVGRemindMe color={'#808191'} width={18} height={18} />

    return <SVGOrgEmailIcon color={'#A3A1B7'} width={18} height={18} />
  }

  const handleDeleteAutoReplyItem = (_, remove, index) => {
    remove(index)
    const autoMessagesList = form.getFieldValue('calendar_invite_sequence')
    setSelectedCalendarSequence(autoMessagesList[0])
  }

  const handleAddAutoReplyItem = (e, add) => {
    e.preventDefault()
    const newAutoReply = {
      delay:
        getRemindersList.length < 2
          ? { unit: 'hours', value: 2 }
          : { unit: 'days', value: 1 },
      send_via: 'sms',
      text: Object.assign({}, ...languages.map((lng) => ({ [lng]: '' }))),
      image: Object.assign({}, ...languages.map((lng) => ({ [lng]: '' }))),
      isNewMessage: true,
      id: new Date().getTime(),
    }
    add(newAutoReply)
    setSelectedCalendarSequence(newAutoReply)
  }

  return !selectedCalendarSequence ? (
    <LoadingSpinner />
  ) : (
    <MyDefaultPageLayout disableHeader>
      <MatForm
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{}}
      >
        <Div className={'calendar_invite_blocks_wrapper'}>
          <MyDefaultBlock fullWidth>
            <MyDefaultSwitch
              noText
              isFirst
              isLast
              title={getText(
                'TEXT_DO_NOT_SEND_REMINDER_IF_APPOINTMENT_IS_CREATED_ON_SAME_DAY'
              )}
              value={doNotSendReminderOnSameDay}
              onChangeSwitch={(value) => {
                setDoNotSendReminderOnSameDay(value)
              }}
            />
          </MyDefaultBlock>
          <MyDefaultBlock
            title={getText('WORD_CONFIRMED')}
            minWidth={deviceCheck ? 'auto' : 325}
            maxWidth={deviceCheck ? 625 : 325}
          >
            <MyDefaultAddAutoReplyMessage
              name='calendar_invite_sequence'
              form={form}
              beforeIndex={1}
              isAddButtonVisible={false}
              onClick={(_, item) => setSelectedCalendarSequence(item)}
              itemTitle={getAutoReplyItemTitle}
              itemSubTitle={getAutoReplyItemSubTitle}
              itemIcon={getAutoReplyItemIcon}
              getSelectedIndex={getSelectedIndex}
              deleteTitle={getText('MSG_DELETE_AI_BOT_MESSAGE_ITEM')}
            />
          </MyDefaultBlock>
          <MyDefaultBlock
            title={getText('WORD_REMINDERS')}
            minWidth={deviceCheck ? 'auto' : 325}
            maxWidth={deviceCheck ? 625 : 325}
          >
            <MyDefaultAddAutoReplyMessage
              name='calendar_invite_sequence'
              form={form}
              afterIndex={1}
              isAddButtonVisible={getRemindersList?.length < 6}
              onAdd={handleAddAutoReplyItem}
              onDelete={handleDeleteAutoReplyItem}
              onClick={(_, item) => setSelectedCalendarSequence(item)}
              itemTitle={getAutoReplyItemTitle}
              itemSubTitle={getAutoReplyItemSubTitle}
              itemIcon={getAutoReplyItemIcon}
              getSelectedIndex={getSelectedIndex}
              deleteTitle={getText('MSG_DELETE_AI_BOT_MESSAGE_ITEM')}
              addTitle={getText('WORD_ADD_NEW_DRIP')}
            />
          </MyDefaultBlock>
          <MyDefaultBlock fullWidth>{renderSelectedAutoReplyPanel()}</MyDefaultBlock>
        </Div>
      </MatForm>
    </MyDefaultPageLayout>
  )
}

export default CalendarInvite
