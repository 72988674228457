import React, { useEffect, useState } from 'react'
import { Flex, Form } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getText, getTextServerError } from '../../../../lang'
import { notifyError, notifySuccess } from '../../../../utils/Notify'
import authActions from '../../../../store/modules/authActions'
import locationActions from '../../../../store/modules/locationActions'
import LoadingSpinner from '../../../../components/LoadingSpinner'
import UserModal from '../../../users/UserModal'
import MatForm from '../../../../components/Form/MatForm'
import ConfirmPopup from '../../../../components/ConfirmPopup'
import GAInfo from '../../ga/GAInfo'
import { organizationSelector } from '../../../../store/selectors/selectors'
import { useLayout } from '../../../../layout/LayoutProvider/LayoutProvider'
import { CANCEL_BTN, SUBMIT_BTN } from '../../../../devOptions'
import { useLocationLayout } from '../../locationProvider/LocationProvider'
import MyDefaultPageLayout from '../../../../components/MyDefaultPageLayout/MyDefaultPageLayout'
import MyDefaultBlock from '../../../../components/MyDefaultBlock/MyDefaultBlock'
import MyDefaultSwitch from '../../../../components/MyDefaultSwitch/MyDefaultSwitch'
import MyDefaultInputText from '../../../../components/MyDefaultInputText/MyDefaultInputText'
import utilObject from '../../../../utils/utilObject'
import MyDefaultTextarea from '../../../../components/MyDefaultTextarea/MyDefaultTextarea'
import MyDefaultButton from '../../../../components/MyDefaultButton/MyDefaultButton'

const EditLocation = () => {
  const { locationObj, isNewLocation, setLocationObj } = useLocationLayout()

  const navigate = useNavigate()
  const { _id } = useParams()
  const organization = useSelector(organizationSelector)
  const { setFooterButtons, setLoading, setPageTitle } = useLayout()

  const [form] = Form.useForm()

  const [visible, setVisible] = useState(false)
  const [enabled, setEnabled] = useState(locationObj.enabled)
  const [userModalVisible, setUserModalVisible] = useState(false)
  const user = authActions.getUserData()
  const [GATrackingNumber, setGATrackingNumber] = useState('')

  useEffect(() => {
    setFooterButtons([
      {
        type: CANCEL_BTN,
      },
      {
        type: SUBMIT_BTN,
      },
    ])
    setPageTitle(
      getText(isNewLocation ? 'ACTION_CREATE_LOCATION' : 'ACTION_EDIT_LOCATION')
    )
  }, [])

  useEffect(() => {
    setEnabled(locationObj.enabled)
  }, [locationObj])

  useEffect(() => {
    if (!isNewLocation && !locationObj) {
      return navigate('/location/list')
    }
  }, [_id])

  const onFinish = async (values) => {
    const gaValidationMessage = validateGATrackingNumber(values.GATrackingNumber)
    if (gaValidationMessage) {
      notifyError(gaValidationMessage)
      return
    }
    setLoading(true)
    const allValues = form.getFieldsValue(true)

    const ga4IdValue =
      allValues.GATrackingNumber && allValues.GATrackingNumber.trim()

    const obj = {
      name: allValues.name,
      friendlyName: allValues.friendlyName,
      address: allValues.address,
      email: allValues.email,
      enabled: enabled,
      GATrackingNumber: ga4IdValue,
    }
    if (!isNewLocation) {
      obj.enabled = enabled
    }

    const result = isNewLocation
      ? await locationActions.saveNew(obj)
      : await locationActions.saveUpdate(obj, locationObj._id)
    if (result.success) {
      notifySuccess(
        isNewLocation
          ? getText('TEXT_LOCATION_WAS_CREATED_SUCCESSFULLY')
          : getText('TEXT_LOCATION_WAS_UPDATED_SUCCESSFULLY')
      )
      // TODO need testing here
      let loc = authActions.getLocation()

      if (result.data._id === (loc && loc._id) || !loc || loc._id === ' ') {
        authActions.setLocation(result.data)
      }
      setLocationObj(result.data)
      if (isNewLocation) {
        navigate(`/location/settings/${result.data._id}/edit`, {
          state: {
            locationObj: result.data,
          },
        })
      }
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
    setLoading(false)
  }

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields.length > 0) {
      notifyError(errorInfo.errorFields[0].errors[0])
    }
  }

  const validateGATrackingNumber = (gaTrackingNumber) => {
    if (!gaTrackingNumber) {
      return ''
    }
    let gaIds = gaTrackingNumber.split(/[,;]\s*/)
    for (let gaId of gaIds) {
      if (!gaId.match(/^G-[A-Z0-9]{10}$/)) {
        return getText('INVALID_GA_FORMAT')
      }
    }
    if (
      gaTrackingNumber.trim().endsWith(',') ||
      gaTrackingNumber.trim().endsWith(';')
    ) {
      return getText('INVALID_GA_FORMAT_COMMA')
    }
    if (
      (gaTrackingNumber.includes(',') && !gaTrackingNumber.includes(', ')) ||
      (gaTrackingNumber.includes(';') && !gaTrackingNumber.includes('; '))
    ) {
      return getText('INVALID_GA_FORMAT_SPACE')
    }
    return ''
  }

  if (!locationObj._id && !isNewLocation) {
    return <LoadingSpinner />
  }

  return (
    <MyDefaultPageLayout disableHeader>
      <MatForm
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{
          name: locationObj.name,
          friendlyName: locationObj.friendlyName,
          address: locationObj.address,
          email: locationObj.email,
          crmType: locationObj.crmType ? locationObj.crmType : 'none',
          GATrackingNumber: locationObj.GATrackingNumber,
        }}
      >
        <MyDefaultBlock>
          {(user.isSuperAdmin || user.isAdmin) && !isNewLocation && (
            <ConfirmPopup
              title={getText('TEXT_LOCATION_DISABLING_WARNING')}
              visible={visible}
              placement={'topRight'}
              onVisibleChange={() => {
                setVisible(!visible)
              }}
              okText={getText('WORD_YES')}
              cancelText={getText('WORD_NO')}
              onCancel={() => {
                if (enabled) {
                  setEnabled(true)
                }
              }}
              onConfirm={() => {
                setEnabled(false)
              }}
              trigger={
                <MyDefaultSwitch
                  title={getText('TEXT_LOCATION_IS_ACTIVE')}
                  isFirst
                  titleStyle={{ fontSize: 14 }}
                  noText
                  value={enabled}
                  onChangeSwitch={() => {
                    setEnabled(!enabled)
                    if (!enabled) {
                      form.validateFields()
                    }
                    if (enabled) {
                      setVisible(!visible)
                      setEnabled(true)
                    }
                  }}
                />
              }
            />
          )}
          <MyDefaultInputText
            isForm
            name='name'
            label={getText('WORD_LOCATION_NAME')}
            required
            isFirst={(user.isSuperAdmin || user.isAdmin) && !isNewLocation}
          />
          {!utilObject.isEmpty(locationObj) ? (
            <MyDefaultInputText
              isForm
              name='friendlyName'
              label={getText('WORD_FRIENDLY_LOCATION_NAME')}
            />
          ) : null}
          <MyDefaultTextarea
            isForm
            name='address'
            label={getText('WORD_LOCATION_ADDRESS')}
            required
            errorMessage={getText('ERR_VALIDATION_REQUIRED')}
          />
          <MyDefaultInputText
            isForm
            name='email'
            placeholder={getText('WORD_EMAIL')}
            label={getText('WORD_LOCATION_CRM_EMAIL')}
          />
          <MyDefaultInputText
            isForm
            name='GATrackingNumber'
            placeholder={'G-XXXXXXXXXX'}
            label={
              <Flex align='center' gap={5}>
                {getText('WORD_GA_TRACKING_ID')}
                <GAInfo />
              </Flex>
            }
            value={GATrackingNumber}
            onChange={(e) => setGATrackingNumber(e.target.value)}
            errorMessage='Please enter a valid Google Analytics 4 ID'
            isLast
          />
        </MyDefaultBlock>
        {!isNewLocation && (
          <MyDefaultBlock enableMarginTop title={getText('TITLE_USER_LIST')}>
            <Flex gap={12}>
              <MyDefaultButton
                onClick={async () => {
                  await authActions.setLocation(locationObj)
                  navigate('/users')
                }}
                htmlType='button'
                buttonText={getText('ACTION_MANAGERS_LIST')}
              />
              <MyDefaultButton
                buttonText={getText('ACTION_CREATE_USER')}
                htmlType='button'
                onClick={() => {
                  setUserModalVisible(!userModalVisible)
                }}
              />
            </Flex>
            {userModalVisible && (
              <UserModal
                userItem={false}
                user={user}
                visible={userModalVisible}
                organization={organization}
                setVisible={setUserModalVisible}
              />
            )}
          </MyDefaultBlock>
        )}
      </MatForm>
    </MyDefaultPageLayout>
  )
}

export default EditLocation
