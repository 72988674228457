import React from 'react'
import { Flex } from 'antd'
import { useNavigate } from 'react-router-dom'
import CopyToClipboard from 'react-copy-to-clipboard'
import { getText } from '../../lang'
import { getLongName, MainColor, voiceAIStatus } from '../../utils'
import SVGDrawerMessages from '../../icons/SVG/SVGDrawerMessages'
import SVGAiBotSmsIcon from '../../icons/SVG/SVGAiBotSmsIcon'
import SVGLanguage from '../../icons/SVG/SVGLanguage'
import SVGVehicleInterestIcon from '../../icons/SVG/SVGVehicleInterestIcon'
import SVGCallsIcon from '../../icons/SVG/SVGCallsIcon'
import SVGCopyIcon from '../../icons/SVG/SVGCopyIcon'
import Div from '../Div/Div'
import ProfileLetters from '../ProfileLetters'
import MyDefaultRow from '../MyDefaultRow/MyDefaultRow'
import MyDefaultTagBlock from '../MyDefaultTagBlock/MyDefaultTagBlock'
import MatBlockWithLabel from '../MatBlockWithLabel'
import utilNumber from '../../utils/utilNumber'
import { OUTBOUND } from '../../devOptions'
import InputTextarea from '../InputTextarea'
import { notifySuccess } from '../../utils/Notify'
import utilDate from '../../utils/utilsDate'
import VoiceAIAudio from './VoiceAIAudio'
import './VoiceAIDetails.scss'

const VoiceAIDetails = ({ item, isCallerInfoClickable, type }) => {
  const navigate = useNavigate()

  const status = voiceAIStatus(item)

  const customerName =
    item?.customer?.fullName ||
    item?.nameSpecifiedByCustomer ||
    item?.customer?.phone ||
    ''

  const getCustomerStatus = () => {
    switch (item?.customer?.status?.state) {
      case 'green':
        return {
          backgroundColor: 'var(--green)',
          title: getText('WORD_APPROVED'),
        }
      case 'yellow':
        return {
          backgroundColor: 'var(--yellow)',
          title: getText('WORD_PENDING'),
        }
      case 'red':
        return {
          backgroundColor: 'var(--red)',
          title: getText('WORD_OPTED_OUT'),
        }
      default:
        break
    }
  }

  const getAssistantType = () => {
    switch (item?.callType) {
      case 'recall_outreach':
        return 'WORD_RECALL_AGENT'
      case 'missed_sales_appointment':
        return getText('WORD_MISSED_APPOINTMENT_AGENT')
      case 'new_lead':
        return getText('WORD_NEW_LEAD_AGENT')
      case 'unsold_showroom_visit':
        return getText('WORD_UNSOLD_VISIT_AGENT')
      case 'appointment_confirmation':
        return getText('WORD_APPOINTMENT_CONFIRMATION_AGENT')
      default:
        break
    }
  }

  const customerStatus = getCustomerStatus()
  const callType = getAssistantType()

  return (
    <Flex className='voice_ai_list_item'>
      <Div className='voice_ai_list_item--left'>
        <MyDefaultRow
          leftSide={getText('WORD_STATUS')}
          rightSide={
            <MyDefaultTagBlock
              title={status?.title}
              backgroundColor={status?.color}
              titleColor={'var(--white)'}
            />
          }
        />
        <MyDefaultRow
          leftSide={getText('WORD_CALLBACKS_SCHEDULED')}
          rightSide={getText(item?.isCallbackNeed ? 'WORD_YES' : 'WORD_NO')}
        />
        <MatBlockWithLabel black label={getText('WORD_CALLER_INFO')}>
          <Flex align='center'>
            <ProfileLetters className='medium' name={customerName} withoutIcon />
            <Div className='voice_ai_list_item--left-caller-info'>
              <Flex align='center' gap={8}>
                <span>{customerName}</span>
                <SVGDrawerMessages
                  color={MainColor}
                  width={20}
                  height={20}
                  onClick={
                    isCallerInfoClickable
                      ? () => {
                          navigate('/messages', {
                            state: {
                              conv: {
                                _id: item?._conversation_id || '',
                              },
                            },
                          })
                        }
                      : null
                  }
                />
              </Flex>
              <p>{item?.customer?.phone || item?.callFromPhone}</p>
            </Div>
          </Flex>
        </MatBlockWithLabel>
        {item?.customer?.status && (
          <MatBlockWithLabel
            black
            label={getText('WORD_CONSENT')}
            className='voice_ai_list_item--left-consent-info'
          >
            <MyDefaultRow
              leftSide={
                <Flex gap={8} align='center'>
                  <SVGAiBotSmsIcon width={16} height={16} /> {getText('WORD_SMS_UC')}
                </Flex>
              }
              rightSide={
                <MyDefaultTagBlock
                  title={customerStatus?.title}
                  backgroundColor={customerStatus?.backgroundColor}
                  titleColor='var(--white)'
                />
              }
            />
            <MyDefaultRow
              leftSide={
                <Flex gap={8} align='center'>
                  <SVGCallsIcon /> {getText('WORD_CALL')}
                </Flex>
              }
              rightSide={
                <MyDefaultTagBlock
                  title={customerStatus?.title}
                  backgroundColor={customerStatus?.backgroundColor}
                  titleColor='var(--white)'
                />
              }
            />
          </MatBlockWithLabel>
        )}
        <MatBlockWithLabel black className='voice_ai_list_item--left-vehicle-info'>
          <SVGVehicleInterestIcon backgroundcolor={'rgba(120, 84, 246, 0.20)'} />
          <Div className='voice_ai_list_item--left-vehicle-info-inner'>
            <p>{getText('WORD_VEHICLE_OF_INTEREST')}</p>
            <p>{`${item?.vehice?.year || ''} ${item?.vehicle?.make || ''} ${item?.vehicle?.model || ''}`}</p>
            <p>{item?.vehice?.vin || ''}</p>
          </Div>
        </MatBlockWithLabel>
        <MatBlockWithLabel black className='voice_ai_list_item--left-consent-info'>
          <MyDefaultRow
            leftSide={
              <Flex gap={8} align='center'>
                <SVGLanguage backgroundcolor={'rgba(234, 153, 87, 0.2)'} />{' '}
                {getText('WORD_LANGUAGE')}
              </Flex>
            }
            rightSide={
              <MyDefaultTagBlock
                title={getLongName(item?.language || 'en')}
                backgroundColor={'transparent'}
                titleColor='var(--green)'
                border={'1px solid var(--borderLineColor)'}
              />
            }
          />
        </MatBlockWithLabel>
      </Div>
      <Div className='voice_ai_list_item--right'>
        {Boolean(item?.recording?.playback_id) && (
          <VoiceAIAudio idPlayBack={item?.recording?.playback_id} />
        )}
        <Div className={'voice_ai_list_item--right-list-info'}>
          <MyDefaultRow
            leftSide={getText('WORD_DATE')}
            rightSide={utilDate.getLocalizedFullDate(item?.createdAt, 'MMM D, YYYY')}
          />
          <MyDefaultRow
            leftSide={getText('WORD_DURATION')}
            rightSide={
              item?.durationSeconds
                ? `${Math.floor(item?.durationSeconds / 60)}:${String(Math.floor(item?.durationSeconds % 60)).padStart(2, '0')} min`
                : 'N/A'
            }
          />
          {type !== OUTBOUND && (
            <MyDefaultRow
              leftSide={getText('WORD_ESTIMATED_VALUE')}
              rightSide={utilNumber.getNumberWithDollars(item?.value || 0)}
            />
          )}
          {Boolean(item?.isAppointment) && (
            <MyDefaultRow
              leftSide={getText('WORD_APPOINTMENT_TEXT')}
              rightSide={
                item?.appointmentDate
                  ? utilDate.getLocalizedFullDate(
                      item?.appointmentDate,
                      'MMM D, YYYY, hh:mm A'
                    )
                  : getText('WORD_NO')
              }
            />
          )}
          <MyDefaultRow
            leftSide={
              type === OUTBOUND ? getText('WORD_CALL_TYPE') : getText('WORD_TYPE')
            }
            rightSide={type === OUTBOUND ? callType : item?.reason || 'N/A'}
          />
          {type !== OUTBOUND && (
            <MyDefaultRow
              leftSide={getText('TEXT_OF_TIMES_CALLED')}
              rightSide={item?.numberOfTimesCalled || 0}
            />
          )}
        </Div>
        <MatBlockWithLabel
          black
          label={getText('WORD_SUMMARY')}
          className={'voice_ai_list_item--right-copy-part'}
        >
          <InputTextarea value={item?.summary || ''} autoSize={false} disabled />
          {Boolean(item?.summary) && (
            <CopyToClipboard
              text={item.summary}
              onCopy={() => notifySuccess(getText('WORD_COPIED'))}
            >
              <SVGCopyIcon className={'copy_icon'} />
            </CopyToClipboard>
          )}
        </MatBlockWithLabel>
      </Div>
    </Flex>
  )
}

export default VoiceAIDetails
