import React, { useEffect, useState } from 'react'
import { Divider, Flex, Form } from 'antd'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import questionsActions from '../../../../../store/modules/questionsActions'
import greetingsActions from '../../../../../store/modules/greetingsActions'
import welcomeActions from '../../../../../store/modules/welcomeActions'
import { getText } from '../../../../../lang'
import { organizationSelector } from '../../../../../store/selectors/selectors'
import MatForm from '../../../../../components/Form/MatForm'
import { notifyError, notifySuccess } from '../../../../../utils/Notify'
import { CANCEL_BTN, SUBMIT_BTN } from '../../../../../devOptions'
import { useLocationLayout } from '../../../locationProvider/LocationProvider'
import { useLayout } from '../../../../../layout/LayoutProvider/LayoutProvider'
import ThemePreview from '../preview/ThemePreview'
import MyDefaultPageLayout from '../../../../../components/MyDefaultPageLayout/MyDefaultPageLayout'
import useDeviceCheck from '../../../../../utils/useDeviceCheck'
import MyDefaultInputText from '../../../../../components/MyDefaultInputText/MyDefaultInputText'
import MyDefaultBlock from '../../../../../components/MyDefaultBlock/MyDefaultBlock'
import MyDefaultUrlConditions from '../../../../../components/MyDefaultUrlConditions/MyDefaultUrlConditions'
import MyDefaultSendFollowUp from '../../../../../components/MyDefaultSendFollowUp/MyDefaultSendFollowUp'
import MyDefaultDraggable from '../../../../../components/MyDefaultDraggable/MyDefaultDraggable'
import MyDefaultCheckbox from '../../../../../components/MyDefaultCheckbox/MyDefaultCheckbox'
import MyDefaultSelect from '../../../../../components/MyDefaultSelect/MyDefaultSelect'

const GreetingEdit = () => {
  const { locationObj, settings } = useLocationLayout()

  const location = useLocation()
  const navigate = useNavigate()
  const { setFooterButtons, setLoading, setPageTitle } = useLayout()
  const organization = useSelector(organizationSelector)
  const { isPageDrawerSize } = useDeviceCheck()

  const locationId = locationObj._id
  const languages = organization?.languages || []
  const defaultLanguage = organization?.defaultLanguage || 'en'
  const greetingData = location.state && location.state.greetingData

  const [urls, setUrls] = useState(greetingData ? greetingData.conditions.url : [])
  const [listQuestions, setListQuestions] = useState([])
  const [welcomeViews, setWelcomeViews] = useState([])

  const [form] = Form.useForm()
  const isNewGreeting = !greetingData

  useEffect(() => {
    setFooterButtons([
      {
        type: CANCEL_BTN,
      },
      {
        type: SUBMIT_BTN,
      },
    ])
    setPageTitle(
      isNewGreeting
        ? getText('TITLE_CREATE_NEW_GREETING')
        : getText('TITLE_EDIT_GREETING')
    )
    refreshListQuestions()
  }, [])

  useEffect(() => {
    if (locationId) {
      fetchWelcomeViews()
    }
  }, [locationId])

  const refreshListQuestions = async () => {
    const options = { _location_id: locationId }
    const result = await questionsActions.getPageList(0, 100, '', options)
    if (result.success) {
      const newListQuestions = result.data
        .map((questionItem, index) => {
          let selected = false
          let weight = index + 100
          if (greetingData) {
            const selectedQuestion = greetingData.questions.find(
              (question) => question._question_id === questionItem._id
            )
            if (selectedQuestion) {
              selected = true
              weight = selectedQuestion.weight
            }
          }
          questionItem.weight = weight
          questionItem.selected = selected
          return questionItem
        })
        .sort((a, b) => a.weight - b.weight)

      setListQuestions(newListQuestions)
    }
  }

  const fetchWelcomeViews = async () => {
    const result = await welcomeActions.getAllList(1, 10, locationId)
    if (result.success) {
      const options = result?.data?.map((view) => ({
        label: view.title,
        value: view._id,
      }))
      setWelcomeViews(options)
    }
  }

  const onFinish = async (values) => {
    setLoading(true)
    const allValues = form.getFieldsValue(true)

    const payload = {
      name: allValues.name,
      welcomeViewId: allValues.welcomeViewId,
      time_on_page: allValues.time_on_page,
      enabled: greetingData ? greetingData.enabled : false,
      questions: listQuestions
        .filter((question) => question.selected)
        .map((questionSelected) => ({
          _question_id: questionSelected._id,
          weight: questionSelected.weight,
        })),
      conditions: {
        url: urls,
      },
      ...(isNewGreeting && { _location_id: locationId }),
    }

    const result = isNewGreeting
      ? await greetingsActions.saveNew(payload)
      : await greetingsActions.saveUpdate(payload, greetingData._id)

    if (result.success) {
      notifySuccess(getText('SUCCESS_TRIGGER_SAVED'))
      navigate(`/location/settings/${locationId}/livechat-greetings`)
    } else {
      notifyError(result.errMsg)
    }
    setLoading(false)
  }

  const handleGreetingsChange = (list) => {
    const newList = list.map((question, index) => {
      question.weight = index + 1
      return question
    })
    setListQuestions(newList)
  }

  return (
    <MyDefaultPageLayout disableHeader>
      <Flex gap={20} vertical={isPageDrawerSize}>
        <MyDefaultBlock fullWidth>
          <MatForm
            onFinish={onFinish}
            form={form}
            initialValues={{
              name: (greetingData && greetingData.name) || '',
              welcomeViewId:
                (greetingData && greetingData._chat_welcome_view_id) || undefined,
              conditions: (greetingData && greetingData.conditions) || [],
              time_on_page: (greetingData && greetingData.time_on_page) || 2,
            }}
          >
            <MyDefaultInputText
              isForm
              name='name'
              label={getText('WORD_NAME')}
              required
              errorMessage={getText('ERR_VALIDATION_REQUIRED')}
            />
            <MyDefaultSelect
              isForm
              name='welcomeViewId'
              label={getText('WELCOME_GREETINGS_VIEW')}
              required
              errorMessage={getText('ERR_VALIDATION_REQUIRED')}
              options={welcomeViews}
            />
            <Divider style={{ margin: '0 0 20px 0' }} />
            <MyDefaultUrlConditions urls={urls} setUrls={setUrls} hideAction />
            <Divider style={{ margin: '20px 0' }} />
            <MyDefaultSendFollowUp
              title={getText('WORD_CURRENT_PAGE_TIME')}
              beforeText={getText('TEXT_GREETING_TIME_TRIGGER_START')}
              hideSelect
              errorMessage={getText('ERR_VALIDATION_REQUIRED')}
              inputName={'time_on_page'}
              afterText={getText('TEXT_GREETING_TIME_TRIGGER_END')}
            />
            <Divider style={{ margin: '20px 0' }} />
            <MyDefaultDraggable
              data={listQuestions}
              onChange={(list) => {
                handleGreetingsChange(list)
              }}
              renderItem={(item, index) => {
                return (
                  <MyDefaultCheckbox
                    value={item.selected}
                    title={item.question[defaultLanguage]}
                    onChange={() => {
                      const questions = listQuestions
                      questions[index].selected = !questions[index].selected
                      handleGreetingsChange(questions)
                    }}
                  />
                )
              }}
            />
          </MatForm>
        </MyDefaultBlock>
        <MyDefaultBlock position={'sticky'} top={0} fullWidth height={890}>
          <ThemePreview
            settings={{ ...settings, _location_id: settings._token }}
            language={languages[0]}
            locationId={settings._token}
            theme={settings.theme}
          />
        </MyDefaultBlock>
      </Flex>
    </MyDefaultPageLayout>
  )
}

export default GreetingEdit
