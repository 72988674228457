import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Form } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import MatForm from '../../../../../components/Form/MatForm'
import MatBlockWithLabel from '../../../../../components/MatBlockWithLabel'
import { getLongName } from '../../../../../utils'
import { notifyError, notifySuccess } from '../../../../../utils/Notify'
import questionsActions from '../../../../../store/modules/questionsActions'
import MyDefaultModal from '../../../../../components/MyDefaultModal/MyDefaultModal'
import { organizationSelector } from '../../../../../store/selectors/selectors'
import { getText, getTextServerError } from '../../../../../lang'
import utilObject from '../../../../../utils/utilObject'
import MyDefaultInsidePageTabs from '../../../../../components/MyDefaultInsidePageTabs/MyDefaultInsidePageTabs'
import MyDefaultTextarea from '../../../../../components/MyDefaultTextarea/MyDefaultTextarea'
import MyDefaultSwitch from '../../../../../components/MyDefaultSwitch/MyDefaultSwitch'
import MyDefaultInputText from '../../../../../components/MyDefaultInputText/MyDefaultInputText'
import MyDefaultAddVariablesInMessage from '../../../../../components/MyDefaultAddVariablesInMessage/MyDefaultAddVariablesInMessage'
import MyDefaultSelect from '../../../../../components/MyDefaultSelect/MyDefaultSelect'
import SelectUsersAssigned from './SelectUsersAssigned'

const QuestionEditModal = ({
  locationObj,
  refreshList,
  questionData,
  visible,
  setVisible,
}) => {
  const location = useLocation()
  const organization = useSelector(organizationSelector)

  const navigate = useNavigate()

  const locationId = locationObj._id
  const languages = organization?.languages || []

  const [loading, setLoading] = useState(false)
  const [selectedLangTab, setSelectedLangTab] = useState(languages?.[0] || 'en')
  const [currentCursorPosition, setCurrentCursorPosition] = useState(0)
  const [type, setType] = useState()
  const [status, setStatus] = useState()
  const [useAsRedirect, setUseAsRedirect] = useState(false)

  const textAreaRef = useRef()
  const [form] = Form.useForm()
  const isNewQuestion = utilObject.isEmpty(questionData)

  useEffect(() => {
    setSelectedLangTab(languages[0])
    if (!isNewQuestion) {
      form.setFieldsValue({
        ...questionData,
        answer: questionData?.answer?.answer,
        enabled_forward: isNewQuestion ? true : questionData?.enabled_forward,
      })
      setType(questionData.type)
      setStatus(questionData.status)
      setUseAsRedirect(questionData.useRedirect)
    } else {
      form.setFieldsValue({
        enabled_forward: isNewQuestion ? true : questionData?.enabled_forward,
        question: {
          en: '',
          fr: '',
          sp: '',
        },
        answer: {
          en: '',
          fr: '',
          sp: '',
        },
        newUsers: [],
        type: null,
        redirectedURL: '',
        useRedirect: false,
      })
      setUseAsRedirect(false)
    }
  }, [questionData, visible])

  useEffect(() => {
    if (isNewQuestion && location.pathname.includes('edit')) {
      navigate(`/location/settings/${locationId}/livechat-questions`)
    }
  }, [location.pathname])

  const answerDataIsValid = (answer) => {
    const answerLanguages = Object.keys(answer)
    if (answerLanguages.length !== 0) {
      if (answerLanguages.length !== languages.length) {
        return false
      }

      let isValidData = 0
      for (const lng of languages) {
        if (answer[lng]) {
          isValidData += 1
        } else {
          isValidData -= 1
        }
      }
      return answerLanguages.length === Math.abs(isValidData)
    }
    return true
  }

  const onFinish = async (values) => {
    setLoading(true)
    const allValues = form.getFieldsValue(true)
    const {
      question,
      answer,
      newUsers,
      removeUsers,
      enabled_forward,
      useAsTouchpoint,
      redirectedURL,
      useRedirect,
    } = allValues
    let { type } = allValues

    // the BE set answer and question property, an object with 3 languages all being empty strings
    // We shouold remove the language not used by organization as it leads to an error
    for (const lng in question) {
      if (!languages.includes(lng)) {
        delete question[lng]
        if (answer) {
          delete answer[lng]
        }
      }
    }
    for (const lng of languages) {
      if (!question[lng]) {
        return notifyError(
          getTextServerError(
            `The ( ${getLongName(lng)} ) field is required in question message`
          )
        )
      }
    }

    if (redirectedURL && !isValidUrl(redirectedURL)) {
      setLoading(false)
      return notifyError(getText('INVALID_URL'))
    }

    // checking if one answer is present, the other is also required
    if (answer && !answerDataIsValid(answer)) {
      for (const lng of languages) {
        if (type && answer[lng] && answer[lng].includes('{{')) {
          notifyError(
            getTextServerError(
              `You can't use any dynamic tags with a smart answer. Check ${getLongName(
                lng
              )} language`
            )
          )
        }

        if (!answer[lng] && !useAsRedirect) {
          notifyError(
            getTextServerError(getText('MSG_ANSWER_IS_REQUIRED')).replace(
              '[lang]',
              getLongName(lng)
            )
          )
          setLoading(false)
          return
        }
      }
    }
    const payload = {
      question,
      enabled_forward: enabled_forward,
      useAsTouchpoint: useAsTouchpoint,
      status: status || '',
      redirectedURL: redirectedURL || '',
      useRedirect: useRedirect || false,
    }
    if (newUsers && newUsers.length > 0) {
      payload.newUsers = newUsers
    }
    if (answer) {
      for (let value of Object.values(answer)) {
        if (value !== '' && !useAsRedirect) {
          payload.answer = answer
        }
      }
    }
    if (!isNewQuestion) {
      if (removeUsers && removeUsers.length > 0) {
        payload.removeUsers = removeUsers
      }
    }

    // Type
    type = type ? 'SMART_ANSWER' : null
    payload.type = type

    // return
    let result
    if (!isNewQuestion) {
      result = await questionsActions.update(payload, questionData._id)
    } else {
      payload._location_id = locationId
      result = await questionsActions.insert(payload)
    }

    if (result.success) {
      notifySuccess(getText('MSG_QUESTION_SAVED_SUCCESSFULLY'))
      navigate(`/location/settings/${locationId}/livechat-questions`)
      setVisible(false)
      refreshList?.()
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
    setLoading(false)
  }

  const onFinishFailed = (errorInfo) => {
    const newQuestion = errorInfo.values
    for (const lng of languages) {
      if (!newQuestion.question[lng]) {
        return notifyError(
          getText('MSG_QUESTION_IS_REQUIRED').replace('[lang]', getLongName(lng))
        )
      }
    }
    if (errorInfo.errorFields.length > 0) {
      notifyError(errorInfo.errorFields[0].errors[0])
    }
  }

  const handleClickVariable = (variable) => {
    const currentContent = form.getFieldValue(['answer', selectedLangTab])
    const newContent = [
      currentContent && currentContent.slice(0, currentCursorPosition),
      variable,
      currentContent && currentContent.slice(currentCursorPosition),
    ].join('')

    form.setFieldsValue({
      answer: {
        [selectedLangTab]: newContent,
      },
    })
    setCurrentCursorPosition(currentCursorPosition + variable.length)
    textAreaRef.current &&
      textAreaRef.current.autoFocus(currentCursorPosition + variable.length)
  }

  const listVariablesQuestion = [
    {
      label: 'customer_first_name',
      value: '{{customer_first_name}}',
    },
    {
      label: 'customer_last_name',
      value: '{{customer_last_name}}',
    },
    {
      label: 'location_name',
      value: '{{location_name}}',
    },
    {
      label: 'organization_name',
      value: '{{organization_name}}',
    },
  ]

  const isValidUrl = (url) => {
    return url.startsWith('http://') || url.startsWith('https://')
  }

  const formName = isNewQuestion
    ? 'formSaveNewQuestion'
    : `formSaveEditQuestion_${questionData._id}`

  return (
    <MyDefaultModal
      noTrigger
      title={getText('TITLE_CREATE_NEW_QUESTION')}
      confirmLoading={loading}
      visible={visible}
      forceRender={false}
      formName={formName}
      onCancel={() => {
        setVisible(false)
        form.resetFields()
      }}
      okText={getText('ACTION_SAVE')}
    >
      <MatForm
        form={form}
        name={formName}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <MatBlockWithLabel black label={getText('ACTION_ASSIGN_TO')}>
          <SelectUsersAssigned
            form={form}
            questionData={questionData}
            isNewQuestion={isNewQuestion}
            visible={visible}
          />
        </MatBlockWithLabel>
        <MyDefaultInsidePageTabs
          isFirst
          onChange={(index) => {
            setSelectedLangTab(languages[index])
          }}
          items={languages.map((lng, index) => {
            return {
              label: getLongName(lng),
              key: index,
              forceRender: true,
              children: (
                <React.Fragment>
                  <MyDefaultTextarea
                    isForm
                    label={getText('LABEL_QUESTION_MESSAGE')}
                    name={['question', lng]}
                    required
                    message={getText('ERR_VALIDATION_REQUIRED')}
                  />
                  <MyDefaultSwitch
                    isForm
                    name='useRedirect'
                    noText
                    isFirst
                    noBorder
                    title={getText('REDIRECT_URL')}
                    titleTooltip={getText('REDIRECT_URL_TOOLTIP')}
                    onChangeSwitch={(value) => setUseAsRedirect(value)}
                  />
                  <MyDefaultInputText
                    isForm
                    name='redirectedURL'
                    required={useAsRedirect}
                    placeholder={getText('REDIRECT_URL')}
                  />

                  {!useAsRedirect && (
                    <React.Fragment>
                      <MyDefaultTextarea
                        ref={textAreaRef}
                        isForm
                        label={getText('LABEL_ANSWER_MESSAGE')}
                        name={['answer', lng]}
                        message={getText('ERR_VALIDATION_REQUIRED')}
                        onChange={(e) =>
                          setCurrentCursorPosition(e.target.selectionStart)
                        }
                        onKeyUp={(e) =>
                          setCurrentCursorPosition(e.target.selectionStart)
                        }
                        onClick={(e) =>
                          setCurrentCursorPosition(e.target.selectionStart)
                        }
                        rules={[
                          {
                            required: !!type,
                            message: `Please input answer for ${getLongName(
                              lng
                            )} language`,
                            whitespace: true,
                          },
                          {
                            message:
                              "You can't use any dynamic tags with a smart answer",
                            validator: (_, value) => {
                              if (!value || !value.includes('{{') || !type) {
                                return Promise.resolve()
                              } else {
                                return Promise.reject(
                                  "You can't use any dynamic tags with a smart answer"
                                )
                              }
                            },
                          },
                        ]}
                      />
                      <MyDefaultAddVariablesInMessage
                        onClick={handleClickVariable}
                        data={listVariablesQuestion}
                        disabled={!!type}
                      />
                    </React.Fragment>
                  )}
                </React.Fragment>
              ),
            }
          })}
        />
        {!useAsRedirect && (
          <React.Fragment>
            <MyDefaultSelect
              isForm
              name='status'
              label={getText('WORD_STATUS')}
              options={[
                { value: 'new', label: 'New' },
                { value: 'used', label: 'Used' },
                { value: 'service', label: 'Service' },
                { value: 'parts', label: 'Parts' },
              ]}
              onClear={() => {
                setStatus(null)
              }}
              onChange={(e) => {
                setStatus(e)
              }}
            />
            <MyDefaultSwitch
              isForm
              name='type'
              noText
              isFirst
              titleStyle={{ fontSize: 14 }}
              title={getText('LABEL_SHOW_ANSWER_CHAT')}
              onChangeSwitch={(value) => setType(value)}
            />
            <MyDefaultSwitch
              isForm
              name='enabled_forward'
              title={getText('FORWARD_NEW_LEADS_TO_CRM')}
              noText
              titleStyle={{ fontSize: 14 }}
            />
            <MyDefaultSwitch
              isForm
              name='useAsTouchpoint'
              title={getText('TEXT_USE_QUESTION_AS_TOUCHPOINT')}
              noText
              isLast
              titleStyle={{ fontSize: 14 }}
            />
          </React.Fragment>
        )}
      </MatForm>
    </MyDefaultModal>
  )
}
export default QuestionEditModal
