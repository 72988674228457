import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = () => (
  <svg
    width='40'
    height='40'
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect width='40' height='40' rx='8' fill='#F4F0FF' />
    <path
      d='M13.3335 20.5863V19.413C13.3335 18.7196 13.9002 18.1463 14.6002 18.1463C15.8068 18.1463 16.3002 17.293 15.6935 16.2463C15.3468 15.6463 15.5535 14.8663 16.1602 14.5196L17.3135 13.8596C17.8402 13.5463 18.5202 13.733 18.8335 14.2596L18.9068 14.3863C19.5068 15.433 20.4935 15.433 21.1002 14.3863L21.1735 14.2596C21.4868 13.733 22.1668 13.5463 22.6935 13.8596L23.8468 14.5196C24.4535 14.8663 24.6602 15.6463 24.3135 16.2463C23.7068 17.293 24.2002 18.1463 25.4068 18.1463C26.1002 18.1463 26.6735 18.713 26.6735 19.413V20.5863C26.6735 21.2796 26.1068 21.853 25.4068 21.853C24.2002 21.853 23.7068 22.7063 24.3135 23.753C24.6602 24.3596 24.4535 25.133 23.8468 25.4796L22.6935 26.1396C22.1668 26.453 21.4868 26.2663 21.1735 25.7396L21.1002 25.613C20.5002 24.5663 19.5135 24.5663 18.9068 25.613L18.8335 25.7396C18.5202 26.2663 17.8402 26.453 17.3135 26.1396L16.1602 25.4796C15.5535 25.133 15.3468 24.353 15.6935 23.753C16.3002 22.7063 15.8068 21.853 14.6002 21.853C13.9002 21.853 13.3335 21.2796 13.3335 20.5863Z'
      stroke='#5B66EA'
      strokeWidth='1.5'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M20 22C21.1046 22 22 21.1046 22 20C22 18.8954 21.1046 18 20 18C18.8954 18 18 18.8954 18 20C18 21.1046 18.8954 22 20 22Z'
      stroke='#5B66EA'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

const SVGIntegrationModalSettings = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGIntegrationModalSettings
