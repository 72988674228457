import React, { useState } from 'react'
import './WelcomeGreetingPreview.scss'
import MyDefaultBlock from '../../../../../components/MyDefaultBlock/MyDefaultBlock'
import { getText } from '../../../../../lang'

const HI_LOCALIZATION = {
  en: 'Hi!',
  fr: 'Bonjour!',
  sp: 'Hola!',
}

const MediaPreview = ({ mediaType, mediaUrl, isMobile }) => {
  if (!mediaUrl) return null
  return (
    <div className={`media-preview ${isMobile ? 'mobile' : 'desktop'}`}>
      {mediaType === 'video' ? (
        <video src={mediaUrl} autoPlay muted loop className='video-preview' />
      ) : (
        <img src={mediaUrl} alt=' ' />
      )}
    </div>
  )
}

const Header = ({ title, isMobile, language }) => {
  const [showHeader, setShowHeader] = useState(true)

  if (!showHeader || !title) return null

  return (
    <div className='header-container'>
      <div
        className='close-button'
        onClick={() => setShowHeader(false)}
        style={{
          top: isMobile ? -5 : 4,
          right: isMobile ? -2 : 5,
        }}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='16'
          height='16'
          viewBox='0 0 16 16'
          fill='none'
        >
          <path
            d='M12.2 3.80682C12.1384 3.74501 12.0651 3.69598 11.9844 3.66253C11.9038 3.62907 11.8173 3.61185 11.73 3.61185C11.6427 3.61185 11.5563 3.62907 11.4756 3.66253C11.395 3.69598 11.3217 3.74501 11.26 3.80682L8.00003 7.06015L4.74003 3.80015C4.67831 3.73843 4.60503 3.68947 4.52439 3.65606C4.44375 3.62266 4.35731 3.60547 4.27003 3.60547C4.18274 3.60547 4.09631 3.62266 4.01566 3.65606C3.93502 3.68947 3.86175 3.73843 3.80003 3.80015C3.73831 3.86187 3.68935 3.93514 3.65594 4.01579C3.62254 4.09643 3.60535 4.18286 3.60535 4.27015C3.60535 4.35744 3.62254 4.44387 3.65594 4.52451C3.68935 4.60515 3.73831 4.67843 3.80003 4.74015L7.06003 8.00015L3.80003 11.2601C3.73831 11.3219 3.68935 11.3951 3.65594 11.4758C3.62254 11.5564 3.60535 11.6429 3.60535 11.7301C3.60535 11.8174 3.62254 11.9039 3.65594 11.9845C3.68935 12.0652 3.73831 12.1384 3.80003 12.2001C3.86175 12.2619 3.93502 12.3108 4.01566 12.3442C4.09631 12.3776 4.18274 12.3948 4.27003 12.3948C4.35731 12.3948 4.44375 12.3776 4.52439 12.3442C4.60503 12.3108 4.67831 12.2619 4.74003 12.2001L8.00003 8.94015L11.26 12.2001C11.3217 12.2619 11.395 12.3108 11.4757 12.3442C11.5563 12.3776 11.6427 12.3948 11.73 12.3948C11.8173 12.3948 11.9037 12.3776 11.9844 12.3442C12.065 12.3108 12.1383 12.2619 12.2 12.2001C12.2617 12.1384 12.3107 12.0652 12.3441 11.9845C12.3775 11.9039 12.3947 11.8174 12.3947 11.7301C12.3947 11.6429 12.3775 11.5564 12.3441 11.4758C12.3107 11.3951 12.2617 11.3219 12.2 11.2601L8.94003 8.00015L12.2 4.74015C12.4534 4.48682 12.4534 4.06015 12.2 3.80682Z'
            fill='white'
            fillOpacity='0.8'
          />
        </svg>
      </div>
      {isMobile ? (
        <p className='header-container--text'>
          {HI_LOCALIZATION[language] || HI_LOCALIZATION['en']} 👋🏼 {title}
        </p>
      ) : (
        <>
          <p className='header-container--title'>
            {HI_LOCALIZATION[language] || HI_LOCALIZATION['en']} 👋🏼{' '}
          </p>
          <p className='header-container--text'>{title}</p>
        </>
      )}
    </div>
  )
}

const ChatButton = ({ isMobile, text, primaryColor, secondaryColor }) => (
  <div
    className='chat-button'
    style={{
      minWidth: text && !isMobile ? 209 : 0,
      paddingRight: text ? 16 : 5,
      backgroundColor: primaryColor,
      ...(!text && { padding: 5 }),
    }}
  >
    <span
      className='chat-button--icon'
      style={{
        backgroundColor: secondaryColor,
        marginRight: text ? 10 : 0,
      }}
    >
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='18'
        height='18'
        viewBox='0 0 22 22'
        fill='blue'
      >
        <path
          d='M16.513 3.66504C18.5379 3.66504 20.1796 5.30679 20.1796 7.33171L20.1796 18.3317C20.1796 19.087 19.3235 19.5298 18.7185 19.0761L15.281 16.4984L5.51298 16.4984C3.48797 16.4984 1.84631 14.8566 1.84631 12.8317L1.84631 7.33171C1.84631 5.30679 3.48797 3.66504 5.51298 3.66504L16.513 3.66504ZM16.513 5.49837L5.51298 5.49837C4.50043 5.49837 3.67965 6.31879 3.67965 7.33171L3.67965 12.8317C3.67965 13.8446 4.50043 14.665 5.51298 14.665L15.5963 14.665C15.7943 14.665 15.9822 14.7182 16.1408 14.8374L18.3463 16.4984L18.3463 7.33171C18.3463 6.31879 17.5259 5.49837 16.513 5.49837ZM14.6796 9.16504C15.1856 9.16504 15.5963 9.57571 15.5963 10.0817C15.5963 10.5877 15.1856 10.9984 14.6796 10.9984C14.1736 10.9984 13.763 10.5877 13.763 10.0817C13.763 9.57571 14.1736 9.16504 14.6796 9.16504ZM11.013 9.16504C11.519 9.16504 11.9296 9.57571 11.9296 10.0817C11.9296 10.5877 11.519 10.9984 11.013 10.9984C10.507 10.9984 10.0963 10.5877 10.0963 10.0817C10.0963 9.57571 10.507 9.16504 11.013 9.16504ZM7.34631 9.16504C7.85259 9.16504 8.26298 9.57571 8.26298 10.0817C8.26298 10.5877 7.85259 10.9984 7.34631 10.9984C6.84004 10.9984 6.42965 10.5877 6.42965 10.0817C6.42965 9.57571 6.84004 9.16504 7.34631 9.16504Z'
          fill={primaryColor}
        />
      </svg>
    </span>
    {text && (
      <span
        className='chat-button--text'
        style={{
          color: secondaryColor,
        }}
      >
        {text}
      </span>
    )}
    <div className='chat-button__badge'>2</div>
  </div>
)

const WelcomeGreetingPreview = ({
  settings,
  selectedLanguage,
  primaryColor,
  secondaryColor,
}) => {
  const {
    showButtonOnMobile,
    showPopupOnMobile,
    showMediaOnMobile,
    mediaUrl,
    mediaType,
  } = settings

  const getPopupText = () => {
    return (
      settings?.popupMessage?.find((x) => x.language === selectedLanguage)?.text ||
      ''
    )
  }

  const getButtonText = () => {
    return (
      settings?.buttonMessage?.find((x) => x.language === selectedLanguage)?.text ||
      ''
    )
  }

  return (
    <div className='welcome-greeting-preview-wrapper'>
      <MyDefaultBlock title={getText('LABEL_DESKTOP')}>
        <div className='preview-box'>
          <Header title={getPopupText()} language={selectedLanguage} />
          <MediaPreview mediaUrl={mediaUrl} isMobile={false} mediaType={mediaType} />
          <ChatButton
            text={getButtonText()}
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
          />
        </div>
      </MyDefaultBlock>
      <MyDefaultBlock enableMarginTop title={getText('LABEL_MOBILE')}>
        <div className='preview-box'>
          {showPopupOnMobile && (
            <Header language={selectedLanguage} title={getPopupText()} isMobile />
          )}
          {showMediaOnMobile && (
            <MediaPreview mediaUrl={mediaUrl} isMobile mediaType={mediaType} />
          )}
          <ChatButton
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            isMobile
            text={showButtonOnMobile ? getButtonText() : ''}
          />
        </div>
      </MyDefaultBlock>
    </div>
  )
}

export default WelcomeGreetingPreview
