import React from 'react'
import { Avatar, Flex, Image } from 'antd'
import { useNavigate } from 'react-router-dom'
import { PictureOutlined } from '@ant-design/icons'
import { getText } from '../../../lang'
import SVGLocationFacebookIcon from '../../../icons/SVG/SVGLocationFacebookIcon.js'
import SVGLocationYelpIcon from '../../../icons/SVG/SVGLocationYelpIcon.js'
import SVGLocationGoogleIcon from '../../../icons/SVG/SVGLocationGoogleIcon.js'
import MyDefaultBlockHeader from '../../../components/MyDefaultBlock/MyDefaultBlockHeader.js'
import MyDefaultBlockRow from '../../../components/MyDefaultBlock/MyDefaultBlockRow.js'
import { urlImgServer } from '../../../utils/UrlHelper.js'
import Div from '../../../components/Div/Div.js'

const LocationListItem = ({ item, languages }) => {
  const navigate = useNavigate()

  const getRightIcon = (link) => {
    switch (link.name) {
      case 'Facebook':
        return <SVGLocationFacebookIcon />
      case 'Google':
        return <SVGLocationGoogleIcon />
      case 'Yelp':
        return <SVGLocationYelpIcon />
      default:
        return link.color ? (
          <Avatar size={24} style={{ backgroundColor: link.color }}>
            {link.name.toUpperCase().substring(0, 1)}
          </Avatar>
        ) : null
    }
  }

  return (
    <React.Fragment>
      <MyDefaultBlockHeader
        label={item.name}
        onClick={() => {
          navigate(`/location/settings/${item._id}/edit`)
        }}
      />
      <Div className='location_block_images'>
        {languages.map((lang, index) => {
          return item[`pic_${lang}`] ? (
            <Image
              key={lang + index}
              src={
                item[`pic_${lang}`].startsWith('http')
                  ? item[`pic_${lang}`]
                  : urlImgServer(item[`pic_${lang}`])
              }
            />
          ) : (
            <Div className='no-image' key={lang + index}>
              <PictureOutlined style={{ fontSize: '42px', color: '#a3a6be' }} />
            </Div>
          )
        })}
      </Div>
      <MyDefaultBlockRow label={getText('WORD_ID')} value={item.id} />
      <MyDefaultBlockRow
        label={getText('WORD_ADDRESS')}
        isTooltip
        value={item.address}
      />
      <MyDefaultBlockRow
        isLast
        label={getText('WORD_URL')}
        value={
          <Flex align='center' gap={4}>
            {item.urls.map((link, index) => {
              return (
                <a
                  key={index}
                  style={{ display: 'flex' }}
                  href={link.url}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {getRightIcon(link)}
                </a>
              )
            })}
          </Flex>
        }
      />
    </React.Fragment>
  )
}

export default LocationListItem
