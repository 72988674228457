import React from 'react'
import { useSelector } from 'react-redux'
import MyDefaultButton from '../../../../../components/MyDefaultButton/MyDefaultButton'
import { getText } from '../../../../../lang'
import GoogleAuth from '../../../../../components/api/GoogleAuth'
import authActions from '../../../../../store/modules/authActions'
import { userSelector } from '../../../../../store/selectors/selectors'
import { notifySuccess } from '../../../../../utils/Notify'

const GmailIntegration = ({
  bookingLoginType,
  locationObj,
  checkConectedAccount,
  otherIntegrationsData,
  getGoogleWarningData,
  setOtherIntegrationsData,
}) => {
  const user = useSelector(userSelector)

  return !Boolean(user.isSuperAdmin && user.isAdmin) &&
    bookingLoginType.googleLogged ? (
    <MyDefaultButton
      htmlType={'button'}
      buttonText={getText('TEXT_DISCONNECT_YOUR_GMAIL_ACCOUNT')}
      style={{ cursor: 'not-allowed' }}
      tooltip
      tooltipText={getText('TEXT_PLEASE_CONTACT_YOUR_ADMINISTRATOR')}
    />
  ) : (
    <GoogleAuth
      responseGoogle={async (data) => {
        let result = await authActions.authGoogle(data.code, locationObj._id)
        if (result.success) {
          locationObj.featuresConnectingData.calendarInvite.isConnected = true
          locationObj.featuresConnectingData.calendarInvite.userData.fullName =
            user.fullName
          checkConectedAccount?.()
          notifySuccess(
            getText('TEXT_APPOINTMENTS_EMAIL_WAS_SUCCESSFULLY_CONNECTED')
          )
        }
      }}
      responseGoogleLogout={async (data) => {
        let result = await authActions.authLogOutGoogle(locationObj._id)
        locationObj.featuresConnectingData.calendarInvite.isConnected = false
        locationObj.featuresConnectingData.calendarInvite.userData.fullName =
          user.fullName
        if (result.success) {
          checkConectedAccount()
          notifySuccess(
            getText('TEXT_APPOINTMENTS_EMAIL_WAS_SUCCESSFULLY_DISCONNECTED')
          )
        }
      }}
      disabled={bookingLoginType.microsoftLogged}
      isConnected={bookingLoginType.googleLogged}
      warning={otherIntegrationsData.length}
      warningText={getGoogleWarningData(otherIntegrationsData)}
      onVisibleChange={async (value) => {
        if (value) {
          const result = await authActions.authGetGoogleIntegrations(locationObj._id)
          if (result.success) {
            setOtherIntegrationsData(result.data.otherIntegrationsData)
          }
        }
      }}
      typeButton={'primary'}
    />
  )
}

export default GmailIntegration
