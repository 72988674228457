/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react'
import { Select, Form, Flex, Divider } from 'antd'
import { useSelector } from 'react-redux'
import { getText } from '../../../../../lang'
import { getLongName, getUserRole } from '../../../../../utils'
import MyDefaultSelect from '../../../../../components/MyDefaultSelect/MyDefaultSelect'
import organizationActions from '../../../../../store/modules/organizationActions'
import { transformData } from '../../../../../components/MyDefaultTreeSelect/MyDefaultTreeSelectLocationOrganization/MyDefaultTreeSelectLocationOrganization'
import {
  organizationSelector,
  userSelector,
} from '../../../../../store/selectors/selectors'
import MyDefaultBlockTitle from '../../../../../components/MyDefaultBlock/MyDefaultBlockTitle'
import MyDefaultInsidePageTabs from '../../../../../components/MyDefaultInsidePageTabs/MyDefaultInsidePageTabs'
import MyDefaultDraggable from '../../../../../components/MyDefaultDraggable/MyDefaultDraggable'
import MyDefaultTextarea from '../../../../../components/MyDefaultTextarea/MyDefaultTextarea'
import locationActions from '../../../../../store/modules/locationActions'

const QuestionsLocationsSelector = ({ field, form }) => {
  const user = useSelector(userSelector)
  const organization = useSelector(organizationSelector)

  const [data, setdata] = useState({ total: 0, list: [] })
  const [isLoading, setisLoading] = useState(true)

  const questionsAndLocations = Form.useWatch(
    ['steps', field.name, 'questionsAndLocations'],
    form
  )

  useEffect(() => {
    const fill = async () => {
      let arr = []
      let total = 0
      let page = 0

      const getData = async () => {
        const result = await organizationActions.getOrganizationsLocationsList(
          page,
          100,
          '',
          {
            enabled: true,
          },
          false,
          getUserRole(
            user.isSuperAdmin,
            user.isAdmin,
            user.isManager,
            user.isSupportAgent
          ),
          user._location_id,
          true
        )

        if (result.success) {
          const list = transformData(result.data)
          arr = page === 0 ? list : [...arr, ...list]
          total = result.max
          if (arr.length < result.max) {
            page++
            await getData()
          }
        }
        setisLoading(false)
      }
      await getData()
      setdata({
        list: arr,
        total: total,
      })
    }
    fill()
  }, [])

  const getTitle = (question) => {
    return question?.question?.question?.en || question?.question || ''
  }

  const getSelectedLocationIndex = (list, id) => {
    if (id) return list?.findIndex((loc) => loc.locationId === id)
  }

  return (
    <React.Fragment>
      <MyDefaultSelect
        isForm
        name={[field.name, 'questionsAndLocations']}
        customOption
        mode={'multiple'}
        loading={isLoading}
        required
        message={`Step ${field.name + 1}: Please select at least 1 question`}
        label='Select questions that will be displayed on the initial screen.'
        onChange={async (e) => {
          let finalResult = []

          const oldLocationsAndSelectedLocations = {}

          for (const questionAndLocation of questionsAndLocations || []) {
            const parsedItem =
              typeof questionAndLocation === 'string'
                ? JSON.parse(questionAndLocation)
                : questionAndLocation
            oldLocationsAndSelectedLocations[questionAndLocation.questionId] = {
              selectedLocations: parsedItem.selectedLocations,
              locations: parsedItem.locations,
            }
          }

          for (const item of e) {
            const parsedItem = typeof item === 'string' ? JSON.parse(item) : item

            if (!oldLocationsAndSelectedLocations[parsedItem?.questionId]) {
              const result = await locationActions.getLocationsByQuestion({
                text: parsedItem.question,
                language: 'en',
              })

              if (result.success) {
                parsedItem.locations = result.data.questions
                parsedItem.selectedLocations = []
              }
            } else {
              parsedItem.locations =
                oldLocationsAndSelectedLocations[parsedItem?.questionId]?.locations
              parsedItem.selectedLocations =
                oldLocationsAndSelectedLocations[
                  parsedItem?.questionId
                ]?.selectedLocations
            }
            parsedItem.value = JSON.stringify({
              organizationId: parsedItem?.organizationId,
              locationId: parsedItem?.locationId,
              questionId: parsedItem.questionId,
              organizationName: parsedItem.organizationName,
              locationName: parsedItem.locationName,
              question: parsedItem.question,
            })
            finalResult.push(parsedItem)
          }

          form.setFieldValue(
            ['steps', field.name, 'questionsAndLocations'],
            finalResult
          )
        }}
      >
        {data.list.map((organization) =>
          organization.children.map((location) =>
            location.questions.map((question) => {
              return (
                <Select.Option
                  key={question._id}
                  value={JSON.stringify({
                    organizationId: question?._organization_id,
                    locationId: question?._location_id,
                    questionId: question._id,
                    organizationName: organization.title,
                    locationName: location.title,
                    question: question.question.en,
                  })}
                >
                  {`${organization.title} -  ${location.title} - ${question.question.en}`}
                </Select.Option>
              )
            })
          )
        )}
      </MyDefaultSelect>
      <Form.List name={[field.name, 'questionsAndLocations']}>
        {(fields) => {
          return (
            <Flex key={field.key} vertical>
              {fields.map((fieldItem, index) => {
                const getCurrentField = form.getFieldValue([
                  'steps',
                  field.name,
                  'questionsAndLocations',
                  fieldItem.name,
                ])
                const parsedValue =
                  typeof getCurrentField === 'string'
                    ? JSON.parse(getCurrentField)
                    : getCurrentField

                const title = getTitle(parsedValue)

                return (
                  <React.Fragment key={fieldItem.key}>
                    {Boolean(index !== 0) && (
                      <Divider style={{ margin: '20px 0' }} />
                    )}
                    <MyDefaultBlockTitle
                      title={`${index + 1}. QUESTION: ${title}`}
                    />
                    <MyDefaultSelect
                      isForm
                      customOption
                      mode={'multiple'}
                      name={[fieldItem.name, 'selectedLocations']}
                      required
                      message={`Step ${field.name + 1}: Please select at least 1 location for each question`}
                      onChange={(e) => {
                        if (e?.[0] && typeof e[0] === 'string') {
                          form.setFieldValue(
                            [
                              'steps',
                              field.name,
                              'questionsAndLocations',
                              fieldItem.name,
                              'selectedLocations',
                            ],
                            e.map((item, index) => {
                              const parsedItem = JSON.parse(item)

                              parsedItem.locationCustomName =
                                questionsAndLocations[
                                  fieldItem.name
                                ]?.selectedLocations?.[index]?.locationCustomName

                              return {
                                ...parsedItem,
                                value: JSON.stringify({
                                  questionId: parsedItem.questionId,
                                  organizationId: parsedItem.organizationId,
                                  locationId: parsedItem.locationId,
                                  name: parsedItem.name,
                                }),
                              }
                            })
                          )
                        }
                      }}
                    >
                      {(parsedValue?.locations || []).map((item) => {
                        if (item.location) {
                          return (
                            <Select.Option
                              key={`${item._location_id}-${parsedValue.questionId}`}
                              value={JSON.stringify({
                                questionId: parsedValue.questionId,
                                organizationId: item._organization_id,
                                locationId: item._location_id,
                                name: item.location.name,
                              })}
                            >
                              {item.organization.name} - {item.location.name}
                            </Select.Option>
                          )
                        }
                      })}
                    </MyDefaultSelect>
                    {Boolean(parsedValue?.selectedLocations?.length) && (
                      <MyDefaultInsidePageTabs
                        items={organization.languages.map((lng, yIndex) => {
                          return {
                            label: getLongName(lng),
                            key: yIndex,
                            forceRender: true,
                            children: (
                              <MyDefaultDraggable
                                data={parsedValue?.selectedLocations || []}
                                getCustomKey={(item) => {
                                  if (item)
                                    return `${item?.locationId}_${item.questionId}`
                                }}
                                onChange={(result) => {
                                  form.setFieldValue(
                                    [
                                      'steps',
                                      field.name,
                                      'questionsAndLocations',
                                      fieldItem.name,
                                      'selectedLocations',
                                    ],
                                    result
                                  )
                                }}
                                renderItem={(item, index) => {
                                  return (
                                    <Flex
                                      style={{ width: '100%' }}
                                      vertical
                                      key={index}
                                      gap={10}
                                    >
                                      <Flex vertical>
                                        <MyDefaultBlockTitle
                                          title={
                                            item?.name || item?.location?.name || ''
                                          }
                                        />
                                      </Flex>
                                      <MyDefaultTextarea
                                        isForm
                                        label='Custom Name'
                                        color={'white'}
                                        isLast
                                        name={[
                                          fieldItem.name,
                                          'selectedLocations',
                                          getSelectedLocationIndex(
                                            parsedValue.selectedLocations,
                                            item.locationId
                                          ),
                                          'locationCustomName',
                                          lng,
                                        ]}
                                        message={getText('ERR_VALIDATION_REQUIRED')}
                                      />
                                    </Flex>
                                  )
                                }}
                              />
                            ),
                          }
                        })}
                      />
                    )}
                  </React.Fragment>
                )
              })}
            </Flex>
          )
        }}
      </Form.List>
    </React.Fragment>
  )
}

export default QuestionsLocationsSelector
